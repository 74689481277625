import {Outlet} from 'react-router-dom';
import Footer from "./common/Footer";
import TopBanner from "./common/Header/TopBanner";
import TopHeaderMenu from "./common/Header/TopHeaderMenu";
import Menu from "./common/Menu";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import useFetchUserInformation from "./common/Auth/UseFetchUserInformation";
import {useEffect, useRef, useState} from "react";
import SkyScraper from "./common/SkyScraper";
import SearchBar from "./kyb/app/view/container/product-panel";
import usePriviaUrl, {PRIVIA_URL} from "./kyb/infra/hooks/usePriviaUrl";
import useScrollVisible from "./kyb/infra/hooks/useScrollVisible";
import axios from 'axios';
import { setCalendarHoliday } from './redux/module/common/CalendarReducer';
// import headerReducer from "./redux/module/Header/HeaderReducer";

const Layout = () => {
    const frontUrl = process.env.REACT_APP_FRONT_URL;

    useFetchUserInformation();
    const oChoInnerRef = useRef(null);
    const commonHeaderObjectRef = useRef(null);
    const [bannerButton , setBannerButton] = useState(false)
    const dispatch = useDispatch();

    const headerType = useSelector((state)=>state.headerReducer.headerType , shallowEqual);
    const headerSkyperTypeState = useSelector((state)=>state.headerReducer.isSkyperTypeState , shallowEqual);

    const {isUrl, path} = usePriviaUrl()
    const {isVisible} = useScrollVisible(commonHeaderObjectRef)
    const [visibleBanner, setVisibleBanner] = useState(true);
    const [visibleTopMenu, setVisibleTopMenu] = useState(true);
    const [visibleMenu, setVisibleMenu] = useState(true);
    const holidaySetRef = useRef(false);

    useEffect(() => {
        if(!holidaySetRef.current){
            holidaySetRef.current = true;
            const holidayApi = `${frontUrl}/front/apollo/rest/service-info/holidays`;
            axios.post(`${holidayApi}`, {method: 'get'}).then((res) => {
                dispatch(setCalendarHoliday((res.data.holidayInfoList || []).map((item) => item.solarDate)));
            });
        }
    }, []);
    

    useEffect(() => {
        if (isUrl(PRIVIA_URL.PRODUCT_LIST) || isUrl(PRIVIA_URL.PRODUCT_DETAIL)) {
            setVisibleBanner(isVisible);
            setVisibleTopMenu(isVisible);
            setVisibleMenu(isVisible);
        }
    }, [isVisible, path]);


    const HeaderObjClassName = headerType => {
        switch (headerType) {
            case "quick":
                return {className : "o-search-result o-CHO-quick"}
            default:
                return {className: "o-CHO-section"}
        }
    }

    const bannerButtonClickHandle = () =>{
        setBannerButton(!bannerButton)
    }


    return (
        <>
            {/* .commonHeaderObject : 헤더 타입 클래스 change(o-CHO- : full(기본), section(섹션), quick(퀵버전), header-sec-fixed(헤더 fixed) */}
            <div className="commonHeaderObject" style={{height: 'auto'}} ref={commonHeaderObjectRef}>
            {/* .o-CHO-inner : 헤더 inner (header-sec-fixed(헤더 fixed), no-header-fixed(fixed 모드 사용안함) */}
                <div className={`o-CHO-inner ${isVisible === false ? "header-sec-fixed" : ''}`} ref={oChoInnerRef}>
                    {isVisible && visibleBanner && <TopBanner bannerState={bannerButton}/>}
                    <div className={"o-search-package"} id="header-sec">
                        <div className={"header-sec o-bg-package"}>
                            {visibleTopMenu && <TopHeaderMenu bannerButtonClickHandle={bannerButtonClickHandle}
                                                              bannerButtonState={bannerButton}/>}
                            {visibleMenu && <Menu/>}
                            <SearchBar visible={isVisible} />
                        </div>
                    </div>
                </div>
                {/*<Header />*/}
            </div>
            <div id="content-sec">
                <Outlet />
                {headerSkyperTypeState &&
                    <SkyScraper/>
                }
            </div>
            <div id="footer-sec">
                <Footer/>
            </div>
        </>
    );
};

export default Layout;