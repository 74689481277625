import {useEffect, useState} from "react";
import {useRecoilState} from "recoil";
import {getTravelerList} from "../../domain/repository/TravelerRepository";
import {travelerListErrorState, travelerListLoadingState, travelerListState} from "../state/user/TravelerInfoState";
import {userLoadingState, userState} from "../state/user/UserState";

export default function useLoadTravelerList() {
    const [loading, setLoading] = useRecoilState(travelerListLoadingState)
    const [error, setError] = useRecoilState(travelerListErrorState)
    const [travelerListRecoil, setTravelerListRecoil] = useRecoilState(travelerListState)
    const [userRecoil, setUserRecoil] = useRecoilState(userState)
    const [userLoading, setUserLoading] = useRecoilState(userLoadingState)
    const memberId = userRecoil.memberId

    function getLoadTravelerList() {
        setLoading(true);
        setError(false)
        getTravelerList().then((res => {
            setLoading(false)
            let tobeError = true
            if (res && res.data) {
                setTravelerListRecoil(res.data)
                tobeError = false
            }
            setError(tobeError)
        })).catch((err) => {
            setLoading(false)
            setError(true)
        })
    }

    useEffect(() => {
        if (!userLoading) getLoadTravelerList();
    }, [userLoading]);

    return {
        getLoadTravelerList,
        loading,
        error,
        travelerListRecoil,
    }
}