import {combineReducers} from "redux";
import headerReducer from "../module/common/HeaderReducer";
import reservationReducer from "../module/reservation/ReservationReducer";
import productReducer from "../module/common/ProductReducer";
import detailReducer from "../module/detail/DetailReducer";
import mainReducer from '../module/mainModule/MainReducer'
import searchReducer from "../module/search/SearchReducer";
import myPageReducer from "../module/mypage/MyPageReducer";
import calendarState from "../module/common/CalendarReducer";

const rootReducer = combineReducers({
    headerReducer,
    mainReducer,
    reservationReducer,
    productReducer,
    detailReducer,
    searchReducer,
    myPageReducer,
    calendarState
})

export default rootReducer;