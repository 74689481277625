import React, {useEffect} from "react";
import {shallowEqual, useSelector} from "react-redux";

const ProductDetailTab = () => {

    let saleProduct = useSelector((state) => state.productReducer.saleProduct, shallowEqual);

    useEffect(() => {
        window.addEventListener('scroll', handleTabScroll);
        return () => {
            window.removeEventListener('scroll', handleTabScroll);
        };
    }, []);

    const scrollBookMark = (id) => {
        const offset = document.getElementById(id).getBoundingClientRect();
        // console.log(id + " offset.top : " + offset.top)
        window.scrollTo(0, window.pageYOffset + offset.top - 100);
    }

    const clickTab = (e, id) => {
        e.preventDefault();

        let tabItem = document.querySelector(".pd-tab-item.on");
        tabItem.classList.remove("on");
        e.target.closest(".pd-tab-item").classList.add("on");
        scrollBookMark(id);
    }

    const handleTabScroll = () => {
        let scroll = document.documentElement.scrollTop;

        // 탭메뉴
        const tabDetails = document.querySelectorAll('.pkg-article.tab-on');
        const tabs = document.querySelectorAll('.pd-tab-menu .pd-tab-item');
        for (let i = 0 ; i < tabDetails.length ; i++) {
            let articleTop = window.pageYOffset + tabDetails[i].getBoundingClientRect().top;
            let articleBottom = window.pageYOffset + articleTop + tabDetails[i].clientHeight;
            // console.log("scroll : " + scroll + " / articleTop : " + articleTop + " / articleBottom : " + articleBottom);
            if (scroll >= articleTop - 120 && scroll < articleBottom) {
                tabs.forEach((tab) => {
                    tab.classList.remove("on");
                });
                tabs[i].classList.add("on");
            }
        }
    };

    return (
        <div className="pd-detail-tab-sec">
            <div className="ly-inner">
                <ul className="pd-tab-menu">
                    <li className="on pd-tab-item"><a className="pd_cate_color" href="#pkgConList1" onClick={(e) => { clickTab(e, "pkgConList1") }}>일정표</a></li>
                    { saleProduct && saleProduct.productDetail && (
                        <li className=" pd-tab-item"><a className="pd_cate_color" href="#pkgConList2" onClick={(e) => { clickTab(e, "pkgConList2") }}>상품상세</a></li>
                    )}
                    { saleProduct && saleProduct.optionalTour && (
                        <li className=" pd-tab-item"><a className="pd_cate_color" href="#pkgConList3" onClick={(e) => { clickTab(e, "pkgConList3") }}>선택관광</a></li>
                    )}
                    { saleProduct && saleProduct.refundPolicy && (
                        <li className=" pd-tab-item"><a className="pd_cate_color" href="#pkgConList4" onClick={(e) => { clickTab(e, "pkgConList4") }}>취소환불규정</a></li>
                    )}
                    { saleProduct && saleProduct.majorDocument && (
                        <li className=" pd-tab-item"><a className="pd_cate_color" href="#pkgConList5" onClick={(e) => { clickTab(e, "pkgConList5") }}>여권/비자정보</a></li>
                    )}
                    { saleProduct && saleProduct.shoppingInfo && (
                        <li className=" pd-tab-item"><a className="pd_cate_color" href="#pkgConList6" onClick={(e) => { clickTab(e, "pkgConList6") }}>쇼핑정보</a></li>
                    )}
                    <li className=" pd-tab-item"><a className="pd_cate_color" href="#pkgConList7" onClick={(e) => { clickTab(e, "pkgConList7") }}>상품 Q&A</a></li>
                </ul>
            </div>
        </div>
    );
};

export default ProductDetailTab;