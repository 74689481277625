import useEvent from "../../../../infra/event/useEvent";
import {EVENT_NAMES} from "../event/events";
import {useRecoilValue, useSetRecoilState} from "recoil";

import usePaymentPrice from "../usePaymentPrice";
import {
    getHcc3DiscountSelector,
    getHcc3OptionSelector,
    setHcc3EnableByCommissionRankSelector,
    setHcc3OptionSelector,
    setInvalidHcc3Selector
} from "../../../state/reservation/benefit/Hcc3Selectors";
import {getDiscountValueDictSelector} from "../../../state/reservation/DiscountSelector";
import {DiscountType} from "../../../../domain/service/payment/PaymentEnum";
import {useEffect, useState} from "react";
import {PRICE_TYPE} from "../../../state/reservation/PriceSelectors";

export default function useHcc3(eventManager) {
    const {
        removeDiscountV2,
        setDiscountV2,
        priceDict,
        paymentPrice
    } = usePaymentPrice()

    const hcc3Option = useRecoilValue(getHcc3OptionSelector)
    const hcc3Discount = useRecoilValue(getHcc3DiscountSelector)
    const setHcc3Option = useSetRecoilState(setHcc3OptionSelector)
    const discountValueDict = useRecoilValue(getDiscountValueDictSelector)
    const currentDiscountValue = discountValueDict[DiscountType.HCC3Discount]
    const rootDiscountTargetValue = priceDict[PRICE_TYPE.ROOT_TARGET_PRICE]
    const setHcc3EnableByCommissionRank = useSetRecoilState(setHcc3EnableByCommissionRankSelector)
    const setInvalidRecoil = useSetRecoilState(setInvalidHcc3Selector)

    const [commissionRank, setCommissionRank] = useState(null)
    useEvent(eventManager, EVENT_NAMES.LOAD_SALE_PRODUCT, (evt) => {
        const saleProduct = evt.getPayload()
        setCommissionRank(saleProduct.getCommissionRank())
    })

    function onCheck() {
        const nextCheck = !hcc3Option.checked
        if (nextCheck) {
            setDiscountV2(hcc3Discount)
        }
        if (!nextCheck) {
            removeDiscountV2(hcc3Discount)
        }
        setHcc3Option({
            ...hcc3Option,
            checked: nextCheck,
        })
    }

    useEffect(() => {
        if (commissionRank === null) return
        if (rootDiscountTargetValue <= 0) {
            setHcc3Option({
                ...hcc3Option,
                checked: false
            })
            removeDiscountV2(hcc3Discount)
            return;
        }
        setHcc3EnableByCommissionRank(commissionRank)
    }, [rootDiscountTargetValue, commissionRank]);

    function setInvalid() {
        /*수기 결제에서 이미 적용된 경우가 있다. 이떄 비활성화 해줘야함.*/
        setInvalidRecoil()
    }

    return {

        enable: hcc3Option.enable,
        option: {
            ...hcc3Option,
            discountValue: hcc3Option.checked ? currentDiscountValue : 0
        },
        onCheck,
        setInvalid
    }


}