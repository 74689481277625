import React from "react";

export default function ContinentPanel({continentList, onClickContinentPanel}) {
    return (
        <table className="uis-mainsel-table">
            <caption>주요도시 선택 리스트</caption>
            <colgroup>
                <col width="14.28571428571429%"/>
                <col width="14.28571428571429%"/>
                <col width="14.28571428571429%"/>
                <col width="14.28571428571429%"/>
                <col width="14.28571428571429%"/>
                <col width="14.28571428571429%"/>
                <col width="14.28571428571429%"/>
            </colgroup>
            <tbody>
            <AreaContent continentList={continentList}/>
            <NationContent continentList={continentList} handleClickNation={onClickContinentPanel}/>
            </tbody>
        </table>
    )
}


function NationContent({continentList, handleClickNation}) {

    function onClickNation(e, continent, nation) {
        e.stopPropagation()
        handleClickNation && handleClickNation(continent, nation)
    }

    return (
        <tr>
            {
                continentList.map((continent, index) => {
                    return (
                        <td key={`continent${index}`}>
                            {continent.nationList.map((nation, i) => {
                                return (
                                    <ul className="list" key={`nation${i}`}>
                                        {i === 0 &&
                                            <li>
                                                <a className="ellipsis" style={{cursor: "pointer"}}
                                                   onMouseDown={(e) => e.preventDefault()}
                                                   onClick={(e) => onClickNation(e, continent, null)}>전체</a>
                                            </li>
                                        }
                                        <li>
                                            <a className="ellipsis" style={{cursor: "pointer"}}
                                               onMouseDown={(e) => e.preventDefault()}
                                               onClick={(e) => onClickNation(e, continent, nation)}>{nation.nationName}</a>
                                        </li>
                                    </ul>
                                )
                            })}
                            {continent.nationList.length === 0 &&
                                <ul className="list" key={`nation0`}>
                                    <li>
                                        <a className="ellipsis" style={{cursor: "pointer"}}
                                           onMouseDown={(e) => e.preventDefault()}
                                           onClick={e => onClickNation(e, continent, null)}>
                                            전체
                                        </a>
                                    </li>
                                </ul>
                            }
                        </td>
                    )
                })
            }
        </tr>

    )


}

function AreaContent({continentList, handleClickContinent}) {
    function onClickContinent(e, continent) {
        e.stopPropagation()
        handleClickContinent && handleClickContinent(continent)
    }

    return (
        <tr>
            {
                continentList.map((continent, index) => {
                    return (
                        <th key={`SearchPanel${index}`} className={"ellipsis"}
                            onClick={(e) => onClickContinent(e, continent)}>
                            {continent.name}
                        </th>
                    )
                })
            }
        </tr>
    )
}
