import React from "react";

const Footer = () =>{


    return(
        <>
            <div id="footer-sec">
                <div className="w-footer-sec ly-inner">
                    <p className="fts-call"><a style={{cursor:"pointer"}} href={process.env.REACT_APP_FOOTER_CUSTOMER_LINK}>고객센터
                        대표번호 <em>1588-0360</em> (해외 82-2-6911-8300)</a></p>
                    <ul className="nav-fnb">
                        <li><a style={{cursor:"pointer"}} href={process.env.REACT_APP_FOOTER_ABOUT_LINK}>ABOUT PRIVIA 여행</a></li>
                        <li><a style={{cursor:"pointer"}} href={process.env.REACT_APP_FOOTER_SITE_LINK}>사이트맵</a></li>
                        <li><a style={{cursor:"pointer"}} href={process.env.REACT_APP_FOOTER_TERMS_LINK}>이용약관</a></li>
                        <li><a style={{cursor:"pointer"}} href={process.env.REACT_APP_FOOTER_OVERSEAS_TRAVEL_LINK}>국외여행약관</a></li>
                        <li><a style={{cursor:"pointer"}} href={process.env.REACT_APP_FOOTER_DOMESTIC_TRAVEL_LINK}>국내여행약관</a></li>
                        <li><a style={{cursor:"pointer"}} href={process.env.REACT_APP_FOOTER_PRIVACY_LINK}><strong>개인정보처리방침</strong></a></li>
                        {/*DT-27427<li><a style={{cursor:"pointer"}} href={process.env.REACT_APP_FOOTER_BUSINESS_LINK}>업무위탁현황</a></li>*/}
                    </ul>
                    <ul className="fts-info">
                        <li>서울특별시 중구 남대문로 78, 8층 9층 (명동1가, 타임워크명동빌딩)</li>
                        <li>㈜타이드스퀘어 대표이사 윤민</li>
                        <li>사업자등록번호 124-86-86370
                            <a href="http://www.ftc.go.kr/info/bizinfo/communicationView.jsp?apv_perm_no=2015301013030200788&amp;area1=&amp;area2=&amp;currpage=1&amp;searchKey=04&amp;searchVal=1248686370&amp;stdate=&amp;enddate=" target="_blank">
                                <em>사업자정보확인</em>
                            </a>
                        </li>
                        <li>관광사업자번호 제 2015-000033호</li>
                        <li className="line-down">통신판매업신고번호 제 2015-서울중구-0788호</li>
                        <li className="line-down"><small>COPYRIGHT (c) TIDESQUARE CO. LTD. ALL RIGHTS RESERVED.SPONSORED BY HYUNDAI CARD</small></li>
                    </ul>
                    <p className="fts-partnership">
                        PRIVIA 여행 운영사인 ㈜타이드스퀘어는 현대카드와 파트너십을 맺고 있습니다.<br/>
                        ㈜타이드스퀘어는 통신판매 중개자로서 통신판매 당사자가 아니며, 여행 상품의 거래정보 및 거래 등에 대한 책임은 상품 공급사에 있습니다.
                    </p>
                </div>
            </div>
        </>
    )
}

export default Footer;