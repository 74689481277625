import {useRecoilState} from "recoil";
import {setChildProductAtDateFilterAtom, setChildProductOrderFilterAtom,} from "../../../state/ChildProductFilterState";
import moment from "moment/moment";

import useProductFilter from "../parent/userProductFilter";
import {useEffect} from "react";
import {displayAmountWithCommission} from "../../../../../utils/formatNumber";


export default function useChildProductFilter() {
    const {filter: {startDateFilter}} = useProductFilter()

    const [orderFilter, setOrderFilter] = useRecoilState(setChildProductOrderFilterAtom)
    const [atDateFilter, setAtDateFilter] = useRecoilState(setChildProductAtDateFilterAtom)


    useEffect(() => {
        if (startDateFilter === atDateFilter) return
        setAtDateFilter(moment(startDateFilter).format('YYYY-MM-DD'))
    }, []);

    function setUpOrderFilter(orderTarget, orderMethod) {
        setOrderFilter({
            orderTarget: orderTarget,
            orderMethod: orderMethod
        })
    }

    function setUpAtDateFilter(atDate) {
        setAtDateFilter(atDate)
    }

    function extractPrice(childProduct) {
        return displayAmountWithCommission(childProduct)
    }

    function sortPrice(orderMethod) {
        return (aChildProduct, bChildProduct) => {
            const aPrice = extractPrice(aChildProduct)
            const bPrice = extractPrice(bChildProduct)
            if (orderMethod === 'desc') {
                return bPrice - aPrice
            }
            return aPrice - bPrice
        }
    }

    function sortDepartureDate(orderMethod) {

        return (aChildProduct, bChildProduct) => {
            function extractDepartureDate(childProduct) {
                return childProduct['departureTime']
            }

            const aDepartureDate = extractDepartureDate(aChildProduct)
            const bDepartureDate = extractDepartureDate(bChildProduct)
            const aDate = new Date(aDepartureDate)
            const bDate = new Date(bDepartureDate)
            if (orderMethod === 'desc') {
                return bDate - aDate
            }
            return aDate - bDate
        }
    }

    function sortReservationConfirm(aChildProduct, bChildProduct) {
        function extractReservationConfirm(childProduct) {
            return childProduct['reservationDivision']
        }

        const aReservationConfirm = extractReservationConfirm(aChildProduct)
        const bReservationConfirm = extractReservationConfirm(bChildProduct)
        if (aReservationConfirm === bReservationConfirm) {
            return 0
        }
        if (aReservationConfirm === 'RESERVATION_CONFIRM') {
            return -1
        }
        if (bReservationConfirm === 'RESERVATION_CONFIRM') {
            return 1
        }
    }

    function doSorting(childProductList) {
        const method = orderFilter.orderMethod
        switch (orderFilter.orderTarget) {
            case "price":
                return childProductList.sort(sortPrice(method))
            case "departureDate":
                return childProductList.sort(sortDepartureDate(method))
            case "reservationConfirm":
                return childProductList.sort(sortReservationConfirm)
            default:
                return childProductList
        }
    }

    function atDateFiltering(yyyymmdd) {
        return (childProduct) => {
            const departureDay = moment(childProduct['departureDay']).format('YYYY-MM-DD')
            return departureDay === yyyymmdd;
        }
    }

    function afterDateFiltering(yyyymmdd) {
        return (childProduct) => {
            const departureDay = moment(childProduct['departureDay']).format('YYYY-MM-DD')
            return departureDay >= yyyymmdd;
        }
    }

    function doFiltering(childProductList) {
        return childProductList
            .filter(atDateFiltering(atDateFilter))
    }
    function doFilterAndSort(childProductList) {
        return doSorting(doFiltering(childProductList))
    }

    return {
        orderFilter,
        atDateFilter,
        setUpAtDateFilter,
        setUpOrderFilter,
        doFilterAndSort,
        extractPrice,
        filtering: {
            atDateFiltering,
            afterDateFiltering,
        },
        sorting: {
            sortPrice,
            sortDepartureDate,
        }
    }
}