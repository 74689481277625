import {selector} from "recoil";
import {HccOption, paymentPriceStateAtom} from "../PaymentPriceState";
import {getDiscountValueDict, getTargetPrice} from "../DiscountSelector";
import {DiscountType} from "../../../../domain/service/payment/PaymentEnum";
import {cloneDeep} from "lodash";


export function getFamilyDiscountTargetValue(paymentPrice, acc) {
    const targetPrice = getTargetPrice(paymentPrice.amountDict)
    const reservationPayCard = paymentPrice.reservationPayCard
    let ret = targetPrice - acc
    if (!reservationPayCard.isHcc) {
        ret -= reservationPayCard.paid
    }
    if (ret < 0) {
        ret = 0
    }
    return ret
}

export function getFamilyDiscountValue(paymentPrice, targetValue) {
    return Math.floor(targetValue * 0.02)
}

export const setFamilyOptionSelector = selector({
    key: 'setFamilyOptionSelector',
    get: ({get}) => {
        const paymentPrice = get(paymentPriceStateAtom);
        return paymentPrice.family.option;
    },
    set: ({set, get}, newOption) => {
        const paymentPrice = get(paymentPriceStateAtom);
        const newFamily = cloneDeep(paymentPrice.family)

        set(paymentPriceStateAtom, {
            ...paymentPrice,
            creditCard: {...HccOption},
            family: {
                ...newFamily,
                option: newOption
            }
        })
    }
})

/*
* 패밀리 할인 처음 설정 하는 셀렉터
* */
export const setInitFamilySelector = selector({
    key: 'setInitFamilySelector',
    get: ({get}) => {
        const paymentPrice = get(paymentPriceStateAtom);
        return paymentPrice.family
    },
    set: ({set, get}, newFamily) => {
        const paymentPrice = get(paymentPriceStateAtom);
        const oldFamily = cloneDeep(paymentPrice.family)
        const newDiscountDict = {...paymentPrice.discountDict}
        if (!newFamily) {
            return
        }
        if (!oldFamily.enable) {
            return
        }

        const enable = true
        newDiscountDict[paymentPrice.family.discount.step] = paymentPrice.family.discount.kind

        set(paymentPriceStateAtom, {
            ...paymentPrice,
            discountDict: newDiscountDict,
            family: {
                ...paymentPrice.family,
                option: {
                    ...paymentPrice.family.option,
                    enable: enable,
                    checked: enable
                }
            }
        })
    }
})


export function getFamilySubmitData(paymentPrice) {
    const family = paymentPrice.family
    let usingPoint = 0
    const isUse = family.option.enable && family.option.checked
    if (isUse) {
        const discountValueDict = getDiscountValueDict(paymentPrice)
        usingPoint = discountValueDict[DiscountType.FamilyDiscount]
    }
    return {
        hccFamilySale: usingPoint,
        isHccFamilyUse: isUse
    }
}

export const setFamilyDiscountSelector = selector({
    key: 'setFamilyDiscountSelector',
    get: ({get}) => {
        const paymentPrice = get(paymentPriceStateAtom);
        return paymentPrice.family.discount;
    },
    set: ({set, get}, newValue) => {
        const paymentPrice = get(paymentPriceStateAtom);
        set(paymentPriceStateAtom, {
            ...paymentPrice,
            family: {
                ...paymentPrice.family,
                discount: {
                    ...paymentPrice.family.discount,
                    ...newValue
                }
            }
        })
    }
})

export const setInvalidFamilySelector = selector({
    key: 'setInvalidFamilySelector',
    get: ({get}) => {
        const paymentPrice = get(paymentPriceStateAtom);
        return paymentPrice.family;
    },
    set: ({set, get}, newValue) => {
        const paymentPrice = get(paymentPriceStateAtom);
        const newFamily = cloneDeep(paymentPrice.family)
        newFamily.enable = false
        newFamily.option.enable = false
        newFamily.option.checked = false

        set(paymentPriceStateAtom, {
            ...paymentPrice,
            family: newFamily
        })
    }
})