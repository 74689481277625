import axios from "axios";
import { getAxiosHeadersWithToken } from "../../../common/util/Axios";

const API_URL = process.env.REACT_APP_FRONT_EDGE_URL;



export async function getTravelerTotalCount() {
    const url = API_URL + '/api/v1/fe/traveler/travelerInfo/totCnt';

    return await axios.get(url, {
        headers: getAxiosHeadersWithToken(),
    });
}



export async function getTravelerList() {
    const result = await getTravelerTotalCount();
    const count = result.data.totCnt
    const url = API_URL + '/api/v1/fe/traveler/travelerInfo/list';
    return await axios.post(url, {
        "endRow": count,
        "startRow": 1
    }, {
        headers: getAxiosHeadersWithToken(),
    });
}

export async function addTravelerInfo(addTravelerRequest) {
    const url = API_URL + '/api/v1/fe/traveler/travelerInfo/insert';
    return await axios.post(url, addTravelerRequest, { headers: getAxiosHeadersWithToken() });
}


// ITEM FE 프론트에서 안씀
export async function deleteTravelerInfo(profileId) {
    const url = API_URL + '/api/v1/travelerInformation/delete';

    return await axios.post(url, [profileId], { headers: getAxiosHeadersWithToken() });
}