import {useParams, useSearchParams} from "react-router-dom";


const Result = () => {
    const params = useParams();
    const searchParams = useSearchParams();
    console.log(params);
    console.log(searchParams);

    return (
        <div>
            <h1>결제완료</h1>
        </div>
    );
}

export default Result;