import {atom, selector} from "recoil";
import {getToday} from "../../infra/time/Kmoment";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


export const childProductFilterCountAtom = atom({
    key: 'childProductFilterCount',
    default: 0
})

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const orderFilterAtom = atom({
    key: 'childProductOrderFilter',
    default: {
        orderTarget: "reservationConfirm", // price, departureDate,
        orderMethod: "none" // asc, desc
    }
})
export const setChildProductOrderFilterAtom = selector({
    key: 'setChildProductOrderFilter',
    get: ({get}) => {
        return get(orderFilterAtom)
    },
    set: ({get, set}, value) => {
        set(orderFilterAtom, value)
        const count = get(childProductFilterCountAtom);
        set(childProductFilterCountAtom, count + 1)
    }
})


////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const atDateFilterAtom = atom({
    key: 'childProductAtDateFilter',
    default: getToday()
})

export const setChildProductAtDateFilterAtom = selector({
    key: 'setChildProductAtDateFilter',
    get: ({get}) => {
        return get(atDateFilterAtom)
    },
    set: ({get, set}, value) => {
        set(atDateFilterAtom, value)
        const count = get(childProductFilterCountAtom);
        set(childProductFilterCountAtom, count + 1)
    }
})
