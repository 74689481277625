import { useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from "react";
import axios from "axios";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Loading from "../common/Loading";
import { getAxiosHeaders } from "../common/util/Axios";
import MyPageError from "./MyPageError";
import { setInicisData, setPaymentData, setRepresentativeProduct, setReservationInfo, setSaleProduct } from "../redux/module/mypage/MyPageReducer";
import 'moment/locale/ko';
import moment from "moment/moment";
import { setInquireList } from "../redux/module/detail/DetailReducer";
import { formatNumber } from "../utils/formatNumber";
import ReservationPersonInfo from "./component/ReservationPersonInfo";
import ProductInfo from "./component/ProductInfo";
import TravelerInfo from "./component/TravelerInfo";
import PaymentInfo from "./component/PaymentInfo";
import PaymentList from "./component/PaymentList";
import InquireList from "./component/InquireList";
import queryString from "query-string";
import { deleteTravelerInfo } from "../../src/kyb/domain/repository/TravelerRepository";

const MyPageNoMember = () => {

    const location = useLocation();
    const params = queryString.parse(location.search);     //검색조건
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const serverUrl = process.env.REACT_APP_SERVER_URL;
    const frontUrl = process.env.REACT_APP_FRONT_URL;
    const priviaUrl = process.env.REACT_APP_PRIVIA_BASE_URL;

    let error = useSelector((state) => state.myPageReducer.errorMessage, shallowEqual);
    let reservation = useSelector((state) => state.myPageReducer.reservation);
    let representativeProduct = useSelector((state) => state.myPageReducer.representativeProduct, shallowEqual);
    let saleProduct = useSelector((state) => state.myPageReducer.saleProduct, shallowEqual);
    let paymentData = useSelector((state) => state.myPageReducer.paymentData, shallowEqual);
    // eslint-disable-next-line no-undef
    const [inquireLists, setInquireLists] = useState(null)

    useEffect(() => {
        getReservationData();
    }, []);

    const getReservationData = () => {
        if (params.t_pass === "") {
            alert("입력하신 정보와 일치하는 예약을 찾을 수 없습니다.");
            window.history.back();
            return;
        }
        axios.get(serverUrl + '/api/v1/reservation/getData/' + params.t_code + '?searchKeyword=' + params.t_pass, {
            headers: getAxiosHeaders()
        })
            .catch(function (error) {
                console.log(error.toJSON());
            })
            .then(function (response) {
                if (response) {
                    if (!response.data.reservationNumber) {
                        alert("입력하신 정보와 일치하는 예약을 찾을 수 없습니다.");
                        window.history.back();
                        return;
                    }
                    dispatch(setReservationInfo(response.data));
                    dispatch(setRepresentativeProduct(response.data.representativeProduct ? response.data.representativeProduct.representativeProducts[0] : null));
                    dispatch(setSaleProduct(response.data.saleProduct ? response.data.saleProduct.saleProducts[0] : null));
                    dispatch(setPaymentData(response.data.paymentData));
                    dispatch(setInicisData(response.data.inicisData));
                    dispatch(setInquireList(response.data.inquireList));
                    setInquireLists(response.data.inquireList)
                } else {
                    alert("존재하지 않는 예약입니다.");
                    navigate("/myPage/noReservation", { replace: true });
                    return false;
                }
            });
    }

    const removeReservation = () => {
        if (!window.confirm("예약 삭제하시겠습니까? 삭제한 내역은 복구할 수 없습니다.")) {
            return;
        }
        deleteTravelerInfo(this.props.reservationNumber).catch(function (error) {
            console.log(error.toJSON());
        }).then(function (response) {
            if (response.data.data === 'OK') {
                alert('예약이 삭제되었습니다.');
                window.location.href = priviaUrl + '/mypage/bookingRecord?pcdSvcType=package';
            } else {
                alert('예약을 삭제할 수 없습니다.');
            }
        });
    }

    const goDetailPage = () => {
        window.location.href = frontUrl + "/representative/" + representativeProduct.representProductCode + "/sale/" + saleProduct.saleProductCode + "/detail";
    }
    const goRemainPayment = () => {
        navigate("/myPage/" + reservation.reservationNumber + "/payment");
    }
    const cancelRequest = () => {
        if (window.confirm("취소는 프리비아 온라인 문의로 접수해 주세요. 온라인 문의 페이지로 이동하시겠습니까?")) {
            window.location.href = priviaUrl + "/customer/inquire/form?svcDivnCd=TRV0010300&catDivnCd=TRV0010301" +
                "&rservCd=" + reservation.reservationNumber +
                "&rservNm=" + saleProduct.saleProductName;
        }
    }

    const adultQuantity = paymentData && paymentData.priceList.filter(p => p.ageDivision.includes("ADULT")).at(0).quantity;
    const childrenQuantity = paymentData && paymentData.priceList.filter(p => p.ageDivision.includes("CHILDREN")).at(0).quantity;
    const infantQuantity = paymentData && paymentData.priceList.filter(p => p.ageDivision.includes("INFANT")).at(0).quantity;

    return (error ? <MyPageError /> : (
        <React.Fragment>
            {
                reservation && saleProduct ? (
                    <div className="w-content-sec">
                        <div className="pkg-tit-page ly-inner">
                            <h2 className="title">해외패키지 예약상세</h2>
                        </div>

                        <div className="pkg-resv-sec ly-inner">

                            {reservation.reservationStep !== 'CANCEL' && reservation.isRemainPaymentButton && (
                                <div className="payment-warn-box a-mt50">
                                    <div className="warn-wrap between-box fc">
                                        <div className="warn-text">
                                            <span className="c-point13 a-bold a-vam">잔액이 있습니다. 결제시한까지 잔액을 결제해 주세요.</span> <span className="btn-base btn-st-uline">
                                                {/*<a style={{cursor: "pointer"}} onClick={goDetailPage}>자세히 보기</a>*/}
                                            </span>
                                            <br />결제시한 : {reservation && reservation.paymentDeadLine && moment(reservation.paymentDeadLine).format("YYYY/MM/DD HH:mm")}
                                        </div>

                                        <div className="btn-wrap">
                                            <span className="btn-base btn-st-sfull btn-bg-bl">
                                                <button type="button" onClick={goRemainPayment}><span className="a-bold">{paymentData && formatNumber(paymentData.outstandingAmount)}원</span> 결제하기</button>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            )}

                            <ProductInfo />

                            <ReservationPersonInfo />

                            <TravelerInfo />

                            <section className="book-detail-area">
                                <div className="pkg-tit-page-cont">
                                    <h3 className="title">요청사항</h3>
                                </div>

                                <div className="book-article">
                                    <textarea className="textarea-basic" disabled>{paymentData.memo}</textarea>
                                </div>
                            </section>

                            <PaymentInfo />

                            <PaymentList />

                            <InquireList reservation={reservation} inquireList={inquireLists} />

                            {//출발일이 지났거나 취소완료된 예약건에만 노출
                                reservation &&
                                reservation.isCanDelete &&
                                <div className="resv-ex-bottom">
                                    <i className="icon-ex-gr"></i>예약을 삭제하시려면 <a style={{ cursor: "pointer" }} onClick={removeReservation}>여기</a>를 눌러주세요. 삭제한 주문내역은 다시 복구할 수 없습니다.
                                </div>
                            }
                        </div>
                    </div>
                ) : (
                    <Loading />
                )
            }
        </React.Fragment>
    ));
};

export default MyPageNoMember;