import moment from "moment";

/**
 *
 * // const weekdays = ['월요일', '화요일', '수요일', '목요일', '금요일', '토요일', '일요일'] as const
 * //type Weekday = typeof weekdays[number];
 * type Weekday = '월요일' | '화요일' | '수요일' | '목요일' | '금요일' | '토요일' | '일요일'
 *
 * interface Day {
 *     year: number
 *     month: number
 *     day: number
 *     weekday: Weekday
 * }
 *
 * type Week = [Day, Day, Day, Day, Day, Day, Day];
 *
 */
export class MomentWrapper {
    mappingKrWeekDay(weekDay) {
        switch (weekDay) {
            case 'Monday':
                return '월요일';
            case 'Tuesday':
                return '화요일';
            case 'Wednesday':
                return '수요일';
            case 'Thursday':
                return '목요일';
            case 'Friday':
                return '금요일';
            case 'Saturday':
                return '토요일';
            case 'Sunday':
                return '일요일';
            default:
                return weekDay;
            // throw new Error('Invalid day of week' + weekDay);
        }
    }

    dayToMoment(day) {
        return moment(`${day.year}-${day.month}-${day.day}`, "YYYY-MM-DD");
    }

    matchMomentToDay(momentInstance) {
        return {
            year: momentInstance.year(),
            month: momentInstance.month() + 1,
            day: momentInstance.date(),
            weekday: this.mappingKrWeekDay(momentInstance.format('dddd'))
        };
    }

    addDayCount(day, count) {
        const dayMoment = moment(`${day.year}-${day.month}-${day.day}`, "YYYY-MM-DD");
        const nextDayMoment = dayMoment.clone().add(count, 'days');
        return this.matchMomentToDay(nextDayMoment);
    }

    yyyymmddToMoment(yyyymmdd) {
        if (!yyyymmdd) return null
        const m = moment(yyyymmdd, "YYYY-MM-DD");
        return this.matchMomentToDay(m);
    }


    getWeekDayNumber(w) {
        switch (w) {
            case '일요일':
                return 0;
            case '월요일':
                return 1;
            case '화요일':
                return 2;
            case '수요일':
                return 3;
            case '목요일':
                return 4;
            case '금요일':
                return 5;
            case '토요일':
                return 6;
            default:
                throw new Error('Invalid day of week');
        }
    }

    makeDayByIso8601(iso8601) {
        const dayMoment = moment(iso8601);
        return this.matchMomentToDay(dayMoment);
    }


    getFirstDay(year, month) {
        const startDay = 1;
        const dayMoment = moment(`${year}-${month}-${startDay}`, "YYYY-MM-DD");
        return this.matchMomentToDay(dayMoment);
    }

    getEndDay(year, month) {
        const date = moment(`${year}-${month}`, "YYYY-MM");
        const daysInMonth = date.daysInMonth();
        const dayMoment = moment(`${year}-${month}-${daysInMonth}`, "YYYY-MM-DD");
        return this.matchMomentToDay(dayMoment);
    }

    equalDay(a, b) {
        if (!a || !b) return false;
        if (a.day !== b.day) return false;
        if (a.month !== b.month) return false;
        return a.year === b.year;
    }

    getToday() {
        const today = moment();
        return this.matchMomentToDay(today);
    }

    getCalendarByWeeks(year, month, weekDay = "일요일") {
        const startOfMonth = this.dayToMoment(this.getFirstDay(year, month));
        const endOfMonth = moment(startOfMonth).endOf('month');
        const startOfWeekDay = this.getWeekDayNumber(weekDay);
        let startDay = startOfMonth.startOf('week').add(startOfWeekDay, 'days');
        if (startOfMonth.day() !== startOfWeekDay) {
            startDay = startOfMonth.subtract(startOfMonth.day() - startOfWeekDay, 'days');
        }
        let calendar = [];
        let date = startDay;
        while (date.isBefore(endOfMonth) || date.weekday() !== startOfWeekDay) {
            calendar.push(this.matchMomentToDay(moment(date)));
            date = date.clone().add(1, 'days');
        }
        let weeks = [];
        for (let i = 0; i < calendar.length; i += 7) {
            weeks.push(calendar.slice(i, i + 7));
        }
        return weeks;
    }

    isBetween(day, startDay, endDay) {
        const dayMoment = this.dayToMoment(day);
        const startDayMoment = this.dayToMoment(startDay);
        const endDayMoment = this.dayToMoment(endDay);
        return dayMoment.isBetween(startDayMoment, endDayMoment, undefined, '[]');
    }

    isPrev(day, base) {
        const dayMoment = moment(`${day.year}-${day.month}-${day.day}`, "YYYY-MM-DD");
        const baseDayMoment = moment(`${base.year}-${base.month}-${base.day}`, "YYYY-MM-DD");
        return dayMoment.isBefore(baseDayMoment);
    }

    isNext(day, base) {
        const dayMoment = moment(`${day.year}-${day.month}-${day.day}`, "YYYY-MM-DD");
        const baseDayMoment = moment(`${base.year}-${base.month}-${base.day}`, "YYYY-MM-DD");
        return dayMoment.isAfter(baseDayMoment);
    }

    getPrevMonth(day, month) {
        const dayMoment = moment(`${day.year}-${day.month}-${day.day}`, "YYYY-MM-DD");
        const prevMonthMoment = dayMoment.clone().subtract(month, 'month');
        return this.matchMomentToDay(prevMonthMoment);
    }

    getNextMonth(day, month) {
        const dayMoment = moment(`${day.year}-${day.month}-${day.day}`, "YYYY-MM-DD");
        const nextMonthMoment = dayMoment.clone().add(month, 'month');
        return this.matchMomentToDay(nextMonthMoment);
    }

    sortDays(days) {
        return days.sort((a, b) => {
            if (a.year < b.year) return -1;
            if (a.year > b.year) return 1;
            if (a.month < b.month) return -1;
            if (a.month > b.month) return 1;
            if (a.day < b.day) return -1;
            if (a.day > b.day) return 1;
            return 0;
        });
    }

    getNextDay(day) {
        const dayMoment = moment(`${day.year}-${day.month}-${day.day}`, "YYYY-MM-DD");
        const nextDayMoment = dayMoment.clone().add(1, 'days');
        return this.matchMomentToDay(nextDayMoment);
    }

    rangeDays(start, end) {
        if (this.equalDay(start, end)) return [start];
        if (this.isPrev(end, start)) return [];
        const days = [];
        let day = this.getNextDay(start);
        while (!this.equalDay(day, end)) {
            days.push(day);
            day = this.getNextDay(day);
        }
        return days;
    }

    makeViewString(day) {
        if (!day) return null
        return `${day.month}월 ${day.day}일 (${day.weekday[0]})`
    }

    makeStringToYYYYMMDD(day, separator = '-') {
        if (!day) return null
        const month = day.month < 10 ? `0${day.month}` : day.month
        const date = day.day < 10 ? `0${day.day}` : day.day
        return `${day.year}${separator}${month}${separator}${date}`
    }

    makeStringToYYYYMMDDVer2(day, separator = '-') {
        if (!day) return null
        const month = day.month < 10 ? `0${day.month}` : day.month
        const date = day.day < 10 ? `0${day.day}` : day.day
        return `${day.year}${separator}${month}${separator}${date}`
    }
}
