import moment from "moment/moment";
import {displayAmountWithCommission, formatNumber} from "../../../../../utils/formatNumber";
import React from "react";


export default function ChildProductCard({parentProduct, childProduct, gotoDetail}) {
    const {
        saleProductName,
        departureDay,
        departureTime,
        arrivalDay,
        arrivalTime,
        travelNightCount,
        travelDayCount,
        remainSeatCount,
        reservationDivision,
        airlineName,
        isWaypoint,
        saleProductDivision,
    } = childProduct


    function onClickDetailBtn(e) {
        e.stopPropagation()
        gotoDetail && gotoDetail(childProduct)
    }

    return (
        <div className={"product between-box fb"} key={`searchChildList${0}`}>
            <div className="pd-info-wrap">
                {
                    saleProductDivision &&
                    <div className="badge-wrap">
                        <span className="badge-base c-point13">{saleProductDivision}</span>
                    </div>
                }

                <div className="pd-h2 ellipsis2">{saleProductName}</div>
                <div className="pkg-info">
                                    <span className="info">
                                        {moment(departureDay).format("M월/D일(dd) ")}
                                        {moment(departureTime).format("HH:mm")} ~ {moment(arrivalDay).format("M월/D일(dd) ")}
                                        {moment(arrivalTime).format("HH:mm")}
                                    </span>
                    <span
                        className="info">{travelNightCount}박{travelDayCount}일</span>
                </div>
                <div className="pkg-status">
                    <span className="status">
                        <span className="st1 a-bold">잔여{remainSeatCount}석</span></span>
                    <span className="status">
                        <span className="st2 a-bold">
                            {(reservationDivision === "RESERVATION_CONFIRM") ? "출발확정" :
                                (reservationDivision === "RESERVATION_NORMAL" ? "예약가능" : "예약대기")}
                        </span>
                    </span>
                    <span className="status">
                        <span className="airline">{airlineName}</span></span>
                    <span className="status">
                        <span className="st4">{isWaypoint > 2 ? '경유' : '직항'}</span></span>
                </div>
            </div>
            <div className="pkg-price">
            <span className="price">
                <em>{formatNumber(displayAmountWithCommission(childProduct))}
                </em>원~
            </span>
                <span className="btn-base btn-st-full btn-line-grd btn-color-bl wfull">
                            <a style={{cursor: "pointer"}} onClick={onClickDetailBtn}>상세일정보기</a>
                </span>
            </div>
        </div>
    )

}

// function EmptyChildProductCardList() {
//
//     return (
//         <div className="pkg-list-nodata">
//             <i className="icon res-nodata"></i>
//             <p className="tit">해당 날짜에는 상품이 존재하지 않습니다.</p>
//             <p className="desc">다른 일정에서 상품을 검색해 보세요.</p>
//         </div>
//     )
// }