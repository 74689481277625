import {Route, Routes} from 'react-router-dom';
import Main from './common/Main';
import Profile from "./1_pages/Profile";
import Articles from "./1_pages/Articles";
import Article from "./1_pages/Article";
import NotFound from "./1_pages/NotFound";
import Layout from "./Layout";
import Detail from './detail/Detail';
import ReservationLayout from "./common/Layout/ReservationLayout";
import Result from "./payment/Result";
import Fail from "./reservation/component/Fail";
import Close from "./payment/Close";
import MyPage from "./mypage/MyPage";
import MyPageNoMember from "./mypage/MyPageNoMember";
import CantFindReservation from "./reservation/page/CantFindReservation";
import PaymentError from "./reservation/page/PaymentError";
import ParentProductCardList from "./kyb/app/view/container/product/ParentProductCardList";
import {RecoilRoot} from "recoil";
import CompleteReservationContainer from "./kyb/app/view/container/reservation/CompleteReservationContainer";
import CreateReservationContainer from "./kyb/app/view/container/reservation/CreateReservationContainer";
import RemindReservationContainer from "./kyb/app/view/container/reservation/RemindReservationContainer";
import TwoPartInicisContainer from "./kyb/app/view/container/reservation/TwoPartInicisContainer";

const Root = () => {
    return (
        <RecoilRoot>
        <Routes>
            <Route path="/" element={<Layout/>}>
                <Route index element={<Main/>}/>
                {/*<Route path="/search/:searchCode/:startDate/:endDate/" element={<SearchList /> } />*/}
                <Route path="/representative" element={<ParentProductCardList/>}/>
                <Route path="/representative/:representativeCode/sale" element={<ParentProductCardList/>}/>

                <Route path="/representative/:representativeCode/sale/:saleCode/detail" element={<Detail/>}/>

                <Route path="/representative/:representativeCode/sale/:saleCode/reservation/result"
                       element={<Result/>}/>
                <Route path="/inquire/:representativeCode/:saleCode" element={<Profile/>}/>

                <Route path="/articles" element={<Articles/>}>
                    <Route path=":id" element={<Article/>}/>
                </Route>
            </Route>


            <Route path="/representative/:representativeCode/sale/:saleCode/reservation" element={<ReservationLayout/>}>
                <Route index element={<CreateReservationContainer />}/>
            </Route>

            <Route path="/reservation" element={<ReservationLayout/>}>
                <Route path="/reservation/complete/:reservationNumber" element={<CompleteReservationContainer/>}/>
                <Route path="/reservation/payment/fail" element={<PaymentError/>}/>
            </Route>

            <Route path={"/reservation/payment/two-part/:reservationNumber"} element={<TwoPartInicisContainer/>}/>

            <Route path="/reservation/close" element={<Close/>}/>

            <Route path="/reservation/fail/:reservationNumber" element={<Fail/>}/>

            <Route path="/myPage" element={<ReservationLayout/>}>
                <Route path="/myPage/:reservationNumber/detail" element={<MyPage/>}/>
                <Route path="/myPage/nomember" element={<MyPageNoMember/>}/>
                {/*<Route path="/myPage/:reservationNumber/payment" element={<RemainPayment/>}/>*/}
                <Route path="/myPage/:reservationNumber/payment" element={<RemindReservationContainer/>}/>
                <Route path="/myPage/:reservationNumber/payment/result" element={<CompleteReservationContainer/>}/>
                <Route path="/myPage/noReservation" element={<CantFindReservation/>}/>
            </Route>

            <Route path="*" element={<NotFound/>}/>


        </Routes>
        </RecoilRoot>
    );
};

export default Root;