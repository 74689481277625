import Select from "react-select";
import React, {useEffect, useState} from "react"
import ParentProductCard from "./ParentProductCard";
import ParentProductFilter from "./ParentProductFilter";
import {goBackPage, goMainPage} from "../../../../../common/util/RedirectLogin";
import KMiddleBanner from "../KMiddleBanner";
import {Swiper, SwiperSlide} from "swiper/react";
import {useLocation} from "react-router-dom";
import {useListProduct} from "../../../usecase/product-list/useListProduct";
import PriviaLoadingBox from "../../component/product/PriviaLoadingBox";
import useProductFilter from "../../../usecase/product-filter/parent/userProductFilter";

// SearchList 였었음..
export default function ParentProductCardList(props) {
    const {
        isEmpty,
        loading,
        loadingPercent,
        visitCityName,
        productList,
        productListTotalCount
    } = useListProduct()

    const {isFiltering, getFilterChips, set: {setSetOrderFilter}} = useProductFilter()
    if (isEmpty && isFiltering !== true) {
        return (
            <EmptyProductList>
                <KMiddleBanner/>
            </EmptyProductList>
        )
    }
    // 일단 스켈레톤 빼버림..
    return (
        <>
            {/*<SearchBar/>*/}
            {/*<ProgressLoading*/}
            {/*    isLoading={loading}*/}
            {/*    isEmpty={isEmpty}*/}
            {/*    isFiltering={isFiltering}*/}
            {/*    empty={*/}
            {/*        <EmptyProductList>*/}
            {/*            <KMiddleBanner/>*/}
            {/*        </EmptyProductList>*/}
            {/*    }*/}
            {/*    loading={<KProductLoading progress={loadingPercent}/>}*/}
            {/*>*/}
            {/*    <SearchBody*/}
            {/*        progress={loadingPercent}*/}
            {/*        chips={getFilterChips()}*/}
            {/*        products={productList}*/}
            {/*        handleChangeSort={(option) => {*/}
            {/*            setSetOrderFilter(option)*/}
            {/*        }}*/}
            {/*        visitCityCount={productListTotalCount}*/}
            {/*        visitCityName={visitCityName}*/}
            {/*    />*/}
            {/*</ProgressLoading>*/}

            <SearchBody
                progress={loadingPercent}
                chips={getFilterChips()}
                products={productList}
                handleChangeSort={(option) => {
                    setSetOrderFilter(option)
                }}
                visitCityCount={productListTotalCount}
                visitCityName={visitCityName}
            />
        </>
    )
}


function EmptyProductList({children}) {
    const {resetFilterAll} = useProductFilter()
    useEffect(() => {
        resetFilterAll(false)
    }, []);
    return (
        <section className="pkg-nodata-sec ly-inner">
            <div className="pkg-list-nodata">
                <i className="icon res-nodata"/>
                <p className="con">검색결과가 없습니다. <br/>출발지를 변경하거나, 다른 날짜를 검색해보세요.</p>
                <span className="btn-base btn-st-full btn-line-bl btn-color-bl">
                                <a style={{cursor: "pointer"}} onClick={goBackPage}
                                   className="btn-init reset">이전 페이지로</a>
                            </span>
                <span className="btn-base btn-st-full btn-bg-bl">
                                <a style={{cursor: "pointer"}} onClick={goMainPage}
                                   className="btn-init reset">메인 페이지로</a>
                            </span>
            </div>
            <div className="btm-promotion-cont ly-inner">
                <ul className="list-item-pakage">
                    {children}
                </ul>
            </div>
        </section>
    )
}

function SearchBodyResultCount({visitCityCount, products}) {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const continentCode = params.get('continentCode') || null;
    const nationCode = params.get('nationCode') || null;
    const cityCode = params.get('cityCode') || null;
    const searchKeyword = params.get('searchKeyword') || ''


    // 픽토그램과 단건(모사품단건)으로 온 경우
    if (cityCode === null && continentCode === null && nationCode === null) {
        return (
            <>
                검색결과 <span>{visitCityCount ?? 0}개</span>
            </>
        )
    }
    if (searchKeyword) {
        return (
            <>
                {searchKeyword} 검색결과 <span>{visitCityCount ?? 0}개</span>
            </>
        )
    }

}

function SearchBody({
                        progress,
                        products,
                        chips,
                        visitCityName,
                        visitCityCount,
                        handleChangeSort
                    }) {

    const [viewChildProductCode, setViewChildProductCode] = useState(null)

    const options = [
        {value: `popularity`, label: `인기순`},
        {value: `shortestDuration`, label: `여행기간 짧은순`},
        {value: `longestDuration`, label: `여행기간 긴순`},
        {value: `lowestPrice`, label: `낮은 가격순`},
        {value: `highestPrice`, label: `높은 가격순`},
    ]

    const selectStyles = {
        control: (baseStyles, state) => ({
            ...baseStyles,
            width: "160px",
            minHeight: '40.8px',
            borderRadius: 3,
            boxShadow: "none",
            fontSize: 14,
            fontWeight: 400,
        })
    }

    const [selectedOption, setSelectOption] = useState(options[0])

    function onChangeSortFilter(option) {
        setSelectOption(option)
        handleChangeSort && handleChangeSort(option.value)
    }
    function onDeleteChip(chip) {
        chip && chip.onDelete && chip.onDelete()
    }


    return (
        <div className="htp-list-sec">
            <div className="ly-inner">
                <ParentProductFilter progress={progress}/>

                <div className="pkg-listr">
                    <div className="pkg-res-wrap">
                        {/* 리스트 헤더 */}
                        <div className="pkg-listr-header">
                            <div className="pkg-listr-noti">

                                <SearchBodyResultCount products={products} visitCityCount={visitCityCount}/>

                                <p className="noti-info">현대카드 최대 <span className="c-point1">3%</span> 할인 및 최대
                                    <span className="c-prvblue">10%</span> M포인트 차감된 할인요금 입니다. 정확한 상품 금액은 예약 시 확인해주세요.
                                </p>
                            </div>
                            <div className="res-sort-filter" style={{zIndex: 100}}>
                                <Select options={options} value={selectedOption}
                                        onChange={onChangeSortFilter}
                                        styles={selectStyles}
                                        components={{IndicatorSeparator: () => null,}}
                                        isSearchable={false}
                                />
                            </div>
                            <FilterChipList chips={chips} onDeleteChip={onDeleteChip}/>
                        </div>
                        {/* //리스트 헤더 */}
                        {/* skeleton loading */}
                        {/* //skeleton loading */}
                        {/* 상품 리스트 */}
                        <div className="res-pd-list htp-res-list origin">
                            <>
                                {
                                    progress < 100 && <PriviaLoadingBox key={0}/>
                                }
                            </>

                            <>
                                {
                                    products.map((product, key) => <ParentProductCard
                                                                product={product}
                                                                key={key + "_parent_card"}
                                                                viewChildProductCode={viewChildProductCode}
                                                                setViewChildProductCode={setViewChildProductCode}

                                    />)
                                }
                            </>

                            <>
                                {
                                    products.length === 0 && <NoProductByFilter/>
                                }
                            </>

                        </div>
                        {/* //상품 리스트 */}
                        {/* 하단 리스트 로딩 */}
                        <div className="loading-list" style={{marginTop: 30, display: 'none'}}>
                            <img
                                src="https://static.priviatravel.com/images/front/travel/svg/tna_new_loading_icon.svg"/>
                        </div>
                        {/* //하단 리스트 로딩 */}
                    </div>
                    {/* //pkg-res-wrap */}
                </div>
            </div>
        </div>
    )
}


function FilterChipList({
                            chips,
                            onDeleteChip
                        }) {
    if (!chips) return null
    if (chips.length === 0) return null

    return (
        <Swiper
            className={"res-selected-badge swiper-container-initialized swiper-container-horizontal"}
            spaceBetween={5}
            slidesPerView={'auto'}
            scrollbar={{draggable: true}}
        >
            <div className={"swiper-wrapper"}>
                {chips.map((chip, index) => {
                    return <SwiperSlide key={index}>
                        <span className="selected-item">{chip.label}</span>
                        <button className="del-btn" onClick={(e) => {
                            e.stopPropagation()
                            onDeleteChip(chip)
                        }} aria-label="필터 삭제"/>
                    </SwiperSlide>
                })
                }
            </div>
        </Swiper>
    )
}

function NoProductByFilter() {
    const {resetFilterAll} = useProductFilter()

    function onClickReset(e) {
        e.preventDefault();
        e.stopPropagation();
        resetFilterAll()
    }

    return (
        <div className="pkg-list-nodata">
            <i className="icon res-nodata"></i>
            <p className="con">등록된 상품이 없습니다.<br/>선택한 필터를 변경해 보세요.</p>
            <span className="btn-base btn-st-full btn-bg-bl">
                    <a style={{cursor: "pointer"}} onClick={onClickReset} className="btn-init reset">필터 초기화</a>
                </span>
        </div>
    )
}