import React, {useState} from "react";
import styles from "../../../../../../reservation/page/Reservation.module.css";
import {formatNumber} from "../../../../../../utils/formatNumber";

export default function BenefitDiscountLayout({sumBenefitValue, children}) {
    const [open, setOpen] = useState(true);
    const toggleBenefit = (e) => {
        if (open) {
            setOpen(false);
        } else {
            setOpen(true);
        }
    }
    return (
        <section className="book-detail-area o-acdi-one pay-type-cont js-pt-all">
            <div className="pkg-tit-page-cont bb-line" onClick={toggleBenefit}>
                <h3 className="title">
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a className={`o-acdi-click between ${open ? 'o-ac-on' : ''}`}>혜택 및 할인</a>
                </h3>
            </div>

            <div className={`book-article ${styles.hidden} ${open ? styles.open : ''}`} id="fc-2">
                <ul className="f-field-box">
                    <li className="wfull">
                        <div className="pay-bnf-wrap">
                            {children}
                        </div>
                        <div className="pay-bnf-wrap">
                            <div className="between-box fc inner-price-li">
                                <div className="price_tt a-bold">혜택/할인 금액</div>
                                <div className="">
                                    <span className="price"><em>- {formatNumber(sumBenefitValue)} </em>원</span>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>

                <ul className="list-dot stPkg tpBook a-mt15">
                    <li>PRIVIA 현대카드 혜택/할인은 유아 요금, 제세공과금, 유류할증금액, 추가요금을 제외한 상품 금액에만 적용됩니다.</li>
                </ul>
            </div>

        </section>
    )
}