import {getVoucherTitleViewString} from "../kyb/app/usecase/reservation/benefit/useVoucherV2";
import {formatNumber} from "../utils/formatNumber";
import React from "react";

export default function VoucherSpan({voucherList}) {
    if (!voucherList) {
        return null
    }
    if (voucherList.length === 0) {
        return null
    }
    const voucher = voucherList.at(0)
    const label = getVoucherTitleViewString(voucher.hccTicketUseDivision, voucherList.length)
    const price = voucherList.map(c => c.hccTicketAmount).reduce((prev, current) => prev + current, 0)

    return (
        <div className="between-box fc inner-price-li">
            <p className="price_tt">{label}</p>
            <span className="price">
                <em>-{formatNumber(price)}</em>원
            </span>
        </div>
    )
}