export const autoRemoveSpace = (e) => {
    e.target.value = e.target.value.replaceAll(" ","");
}
export const removeNotNumber = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, '').replace(/(^0+)/,'');
}

export const autoHyphen = (e) => {
    e.target.value = e.target.value.replaceAll(" ","")
        .replace(/[^0-9]/g, '')
        .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3").replace(/-{1,2}$/g, "");
}

export const removeNonKorean = (e) => {
    e.target.value = e.target.value.replace(/[^\sㄱ-ㅎ가-힣]/g, '');
}

export const autoUpperCase = (e) => {
    e.target.value = e.target.value.toUpperCase().replace(/[^A-Z]/g, '');
}