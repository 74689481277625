import {useLocation, useNavigate} from "react-router-dom";
import useMoment from "../../infra/hooks/moment/useMoment";

export default function useHref() {
    let nav = useNavigate();
    let location = useLocation();
    const {today, moment} = useMoment();

    function gotoProductList(params, opt) {
        if (params === undefined) return nav('/representative?startAt=' + moment.makeStringToYYYYMMDD(today))
        nav(`/representative?${params}`)
    }

    function gotoProductDetail(path, params) {
        let gotoUrl = `/representative/${path.representativeCode}/sale/${path.saleCode}/detail`
        if (params) {
            gotoUrl += '?' + params
        }
        nav(gotoUrl)
    }

    function gotoProductListByRepresentative(path, params) {
        let gotoUrl = `/representative/${path.representativeCode}/sale`
        if (params) {
            gotoUrl += '?' + params
        }
        nav(gotoUrl)
    }

    function isUrlProductList() {
        return location.pathname === '/representative'
    }


    return {
        gotoProductDetail,
        gotoProductList,
        gotoProductListByRepresentative,
        isUrlProductList,
    }
}