const priviaServerUrl = process.env.REACT_APP_PRIVIA_BASE_URL;

export const goLoginPage = (returnUrl) => {
    if (returnUrl) {
        window.location.href = priviaServerUrl + `/common/sso/login?redirectMethod=GET&returnUrl=` + returnUrl;
    } else {
        window.location.href = priviaServerUrl + `/common/sso/login`;
    }
};

export const goReservationLoginPage = (returnUrl) => {
    window.location.href = priviaServerUrl + `/common/sso/loginOrder?redirectMethod=GET&returnUrl=` + returnUrl;
};

export const goMainPage = () => {
    window.location.href = '/';
};

export const goBackPage = () => {
    window.history.back();
}
