import React, {useEffect, useState} from "react";
import TravelTerms from "./terms/TravelTerms";
import CancellationRefundPolicy from "./terms/CancellationRefundPolicy";
import ThirdPartyInfo from "./terms/ThirdPartyInfo";
import PersonalInfo from "./terms/PersonalInfo";
import LiabilityNotice from "./terms/LiabilityNotice";
import UsageTerms from "./terms/UsageTerms";
import axios from "axios";
import EFinanceUsageTerms from "./terms/EFinanceUsageTerms";


export default function TermsOfUse({
                                       termsCtx,
                                   }) {

    const terms = termsCtx.agreement; // 약관에 관한 변수
    const supplierName = termsCtx.supplierName;
    const [usageTermsContent, setUsageTermsContent] = useState('');
    const [eFinanceUsageTermsContent, setEFinanceUsageTermsContent] = useState('');
    const [personalInfoContent, setPersonalInfoContent] = useState('');
    const [thirdPartyInfoContent, setThirdPartyInfoContent] = useState('');

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_PRIVIA_BASE_URL}/etc/agmtAgree.ajax`)
            .catch(function (error) {
                console.log(error.toJSON());
            }).then(function (response) {
            setUsageTermsContent(response.data);
        });

        axios.get(`${process.env.REACT_APP_PRIVIA_BASE_URL}/etc/commerceAgree.ajax`)
            .catch(function (error) {
                console.log(error.toJSON());
            }).then(function (response) {
            setEFinanceUsageTermsContent(response.data);
        });

        axios.get(`${process.env.REACT_APP_PRIVIA_BASE_URL}/etc/indinfoAgree.ajax?pageType=Y`)
            .catch(function (error) {
                console.log(error.toJSON());
            }).then(function (response) {
            setPersonalInfoContent(response.data);
        });

        axios.get(`${process.env.REACT_APP_PRIVIA_BASE_URL}/etc/indivInfoThirdJoin.ajax`)
            .catch(function (error) {
                console.log(error.toJSON());
            }).then(function (response) {
            setThirdPartyInfoContent(response.data);
        });
    }, []);

    const [isTermsCollapsed, setIsTermsCollapsed] = useState({
        travelTermsToggle : false,
        cancellationRefundPolicyToggle : false,
        usageTermsToggle : false,
        eFinanceUsageTermsToggle : false,
        personalInfoToggle : false,
        thirdPartyInfoToggle : false,
        liabilityNoticeToggle : false,
    });

    const handleOpenContents = (e) => {
        setIsTermsCollapsed({
            ...isTermsCollapsed,
            [e.target.id] : !isTermsCollapsed[e.target.id]
        })
    };

    return (
        <section className="book-detail-area bk-agreement-sec">
            <div className="pkg-tit-page-cont bb-line between-box fc">
                <h3 className="title">약관 및 이용동의</h3>
                <span className="check-group">
                        <input type="checkbox" className="hidden_check" id="termsAllAgree" name="allAgreed"
                               checked={termsCtx.allAgreed} onChange={termsCtx.onCheck}/>
                        <label htmlFor="termsAllAgree" className="label-check">전체 약관동의</label>
                    </span>
            </div>
            <div className="book-article border-bottom o-acdi-one">
                <TravelTerms open={isTermsCollapsed.travelTermsToggle} terms={terms} termsCtx={termsCtx}
                             handleOpenContents={handleOpenContents} supplierName={supplierName}/>
                <CancellationRefundPolicy open={isTermsCollapsed.cancellationRefundPolicyToggle} terms={terms}
                                          termsCtx={termsCtx} handleOpenContents={handleOpenContents}/>
                {!termsCtx.hasUserInfo &&
                    <>
                        <UsageTerms open={isTermsCollapsed.usageTermsToggle} terms={terms} termsCtx={termsCtx}
                                    handleOpenContents={handleOpenContents} content={usageTermsContent}/>
                        <EFinanceUsageTerms open={isTermsCollapsed.eFinanceUsageTermsToggle} terms={terms}
                                            termsCtx={termsCtx} handleOpenContents={handleOpenContents}
                                            content={eFinanceUsageTermsContent}/>
                    </>
                }
                <PersonalInfo open={isTermsCollapsed.personalInfoToggle} terms={terms} termsCtx={termsCtx}
                              handleOpenContents={handleOpenContents} content={personalInfoContent}/>
                <ThirdPartyInfo open={isTermsCollapsed.thirdPartyInfoToggle} terms={terms} termsCtx={termsCtx}
                                handleOpenContents={handleOpenContents} contents={thirdPartyInfoContent}/>
                <LiabilityNotice terms={terms} termsCtx={termsCtx}/>
            </div>
        </section>
    )
}
