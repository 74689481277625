import React from "react";
import useMoment from "../../../../../infra/hooks/moment/useMoment";
import {toggleArray} from "../../../../../infra/utils/ArrayUtils";
import moment from "moment";
import { shallowEqual, useSelector } from "react-redux";


/**
 export interface Props {
 day: Day,
 rangeDay: Day[],
 onChangeRange?: (range: Day[]) => void
 children?: React.ReactNode
 }
 */


export default function SinglePartCalendar(props) {
    const {moment} = useMoment();
    const holiday = useSelector((state)=>state.calendarState.holiday , shallowEqual);
    const {day, rangeDay} = props;
    const calendarId = `calendar${day.year}-${day.month}`;
    const weeks = moment.getCalendarByWeeks(day.year, day.month);

    function getStartDay() {
        let startDay = moment.getFirstDay(day.year, day.month);
        if (moment.isPrev(startDay, moment.getToday())) {
            startDay = moment.getToday();
        }
        return startDay;
    }

    function isSelectAll() {
        if (rangeDay.length < 2) return false;
        if (!moment.equalDay(rangeDay[0], getStartDay())) return false;
        const lastDay = moment.getEndDay(day.year, day.month);
        return moment.equalDay(lastDay, rangeDay[1]);
    }

    function onCheckAll(e) {
        e.stopPropagation();
        if (isSelectAll()) {
            onChangeRange([]);
            return;
        }
        let startDay = moment.getFirstDay(day.year, day.month);
        if (moment.isPrev(startDay, moment.getToday())) {
            startDay = moment.getToday();
        }
        const endDay = moment.getEndDay(day.year, day.month);
        onChangeRange([startDay, endDay]);
    }

    function onChangeRange(days) {
        props.onChangeRange && props.onChangeRange(days);
    }

    function onClickDay(day, weekIndex) {
        const toggled = toggleArray(rangeDay, day, moment.equalDay);
        const sorted = moment.sortDays(toggled);
        if (toggled.length <= 2) {
            onChangeRange(sorted);
            return;
        }
        onChangeRange([day])
        // if (moment.isBetween(day, rangeDay[0], rangeDay[1])) {
        //     const case1Days = moment.rangeDays(day, rangeDay[1]);
        //     const case2Days = moment.rangeDays(rangeDay[0], day);
        //     let nextRange = [rangeDay[0], day];
        //     if (case1Days.length > case2Days.length) {
        //         nextRange = [day, rangeDay[1]];
        //     }
        //     onChangeRange(nextRange);
        //     return;
        // }
        // let first = moment.isPrev(rangeDay[0], day) ? rangeDay[0] : day;
        // let last = moment.isNext(rangeDay[1], day) ? rangeDay[1] : day;
        // onChangeRange([first, last]);
    }

    return (
        <div className="ui-datepicker-group ui-datepicker-group-first">
            <div className="ui-datepicker-header ui-widget-header ui-helper-clearfix ui-corner-left">
                {props.children}
                <div className="ui-datepicker-title">
                    <span className="ui-datepicker-year">{`${day.year}년`}</span>&nbsp;
                    <span className="ui-datepicker-month ui-datepicker-m6">{`${day.month}월`}</span>
                </div>
                <span className="checkAll chk-base chk-st2">
                    <input type="checkbox" name="checkedAllDays" className="a11y"
                           id={calendarId}
                           checked={isSelectAll()}
                           onChange={onCheckAll}/>
                    <label htmlFor={calendarId} className="label-base">월 전체</label>
                </span>
            </div>
            <table className="ui-datepicker-calendar">
                <thead>
                <tr>
                    <th className="ui-datepicker-week-end">
                        <span title="Sunday" className="Sunday">일</span>
                    </th>
                    <th>
                        <span title="Monday" className="Monday">월</span>
                    </th>
                    <th>
                        <span title="Tuesday" className="Tuesday">화</span>
                    </th>
                    <th>
                        <span title="Wednesday" className="Wednesday">수</span>
                    </th>
                    <th>
                        <span title="Thursday" className="Thursday">목</span>
                    </th>
                    <th>
                        <span title="Friday" className="Friday">금</span>
                    </th>
                    <th className="ui-datepicker-week-end">
                        <span title="Saturday" className="Saturday">토</span>
                    </th>
                </tr>
                </thead>
                <tbody>
                {weeks.map((week, i) =>
                    <CalendarWeek key={week[0].day + '' + i}
                                  week={week}
                                  weekIndex={i}
                                  rangeDay={rangeDay}
                                  onClickDay={onClickDay}
                                  calendarYear={day.year}
                                  calendarMonth={day.month}
                                  holiday={holiday} />
                )}
                </tbody>
            </table>
        </div>
    );
}

function CalendarWeek(props) {
    const {moment} = useMoment();
    const today = moment.getToday();
    const {week, weekIndex, calendarYear, calendarMonth, holiday} = props;

    function disableDay(day) {
        return moment.isPrev(day, today);
    }

    function emptyDay(day) {
        return calendarYear !== day.year || calendarMonth !== day.month;
    }

    function onClickDay(day) {
        if (disableDay(day)) return;
        props.onClickDay && props.onClickDay(day, weekIndex);
    }

    function isToday(day) {
        return moment.equalDay(day, today);
    }

    function isInRange(day) {
        if (props.rangeDay.length < 2) return false;
        const isBetween = moment.isBetween(day, props.rangeDay[0], props.rangeDay[1]);
        if (!isBetween) return false
        return !moment.equalDay(day, props.rangeDay[0]) && !moment.equalDay(day, props.rangeDay[1]);
    }

    function isSelected(day) {
        return props.rangeDay.some(d => moment.equalDay(d, day));
    }

    function isStartRange(day) {
        if (props.rangeDay.length < 2) return false;
        return moment.equalDay(day, props.rangeDay[0]);
    }

    function isEndRange(day) {
        if (props.rangeDay.length < 2) return false;
        return moment.equalDay(day, props.rangeDay[1]);
    }

    return (
        <tr key={week[0].day}>
            {week.map((day, i) => {
                return <CalendarDay key={i + day.weekday}
                                    day={day}
                                    isSelected={isSelected(day)}
                                    isToday={isToday(day)}
                                    onClickDay={onClickDay}
                                    isEmpty={emptyDay(day)}
                                    isInRange={isInRange(day)}
                                    isDisable={disableDay(day)}
                                    isStartRange={isStartRange(day)}
                                    isEndRange={isEndRange(day)}
                                    holiday={holiday}

                />
            })}
        </tr>
    );
}

function CalendarDay(props) {
    if (props.isEmpty) {
        return <td
            className="ui-datepicker-week-end ui-datepicker-other-month ui-datepicker-unselectable ui-state-disabled">&nbsp;</td>;
    }

    const {day, isStartRange, isEndRange, isDisable, isToday, isInRange, isSelected, onClickDay, holiday} = props;
    const dt = moment().set({year: day.year, month: day.month - 1, date: day.day});

    function getTdClsName() {
        const cls = ["ui-state-default"];
        if (day) {
            cls.push("ui-state-highlight");
        }
        if (isDisable) {
            cls.push("ui-datepicker-week-end ui-datepicker-other-month ui-datepicker-unselectable ui-state-disabled");
        }
        if (isToday) {
            cls.push("ui-state-highlight");
        }
        if (day.weekday === "토요일") {
            cls.push("ui-datepicker-week-end date-saturday");
        }
        if (day.weekday === "일요일") {
            cls.push("ui-datepicker-week-end date-sunday");
        }
        if (isSelected) {
            cls.push('dp-highlight dp-start ui-datepicker-current-day');
        }
        if (isStartRange) {
            cls.push('dp-first');
        }
        if (isEndRange) {
            cls.push('dp-end');
        }
        if(holiday.includes(dt.format('YYYY-MM-DD'))){
            cls.push('date-holiday');
        }

        // if (!isSelected) {
        //     cls.push("ui-datepicker-unselectable")
        // }

        if (isInRange) {
            cls.push("dp-highlight pd-between");
        }

        return cls.join(" ");
    }

    function getATagCls() {
        const cls = ["ui-state-default"];
        if (isSelected) {
            cls.push("ui-state-active");
        }
        return cls.join(" ");
    }

    const tdCls = getTdClsName();
    const aCls = getATagCls();
    return (
        <td key={day.day} style={{
            cursor: isDisable ? "not-allowed" : "pointer"
        }} className={tdCls} onClick={() => onClickDay(day)}>
            <a className={aCls}>{day.day}</a>
        </td>
    );
}
