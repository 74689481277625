//@flow
export const AirlineCodeType = {
    "0B":"블루 에어",
    "11":"롯데특판가",
    "12":"부산-아시아나 (국내)",
    "1A":"AMADEUS CRS",
    "1B":"ABACUS CRS",
    "1C":"GEMINI CRS",
    "1D":"ADI DOMESTIC A L",
    "1E":"CHINA CRS",
    "1F":"INFINI CRS",
    "1G":"GALILEO CRS",
    "1J":"AXESS",
    "1K":"SOUTHERNCROSS CRS",
    "1N":"VIA WORLD",
    "1P":"WORLDSPAN CRS",
    "1S":"SYSTEMONE CRS",
    "1T":"이엔엠항공(주)",
    "1U":"ITAPEMIRM T AERE",
    "1V":"COVIA PARTNERSHIP",
    "1W":"SABRE CRS",
    "1X":"GETS CRS",
    "1Z":"FANTASIA CRS",
    "2A":"독일철도",
    "2B":"ATA AEROCONDOR",
    "2C":"SNCF",
    "2D":"다이나믹에어",
    "2F":"Frontier Flying Service",
    "2G":"DEBONAIR",
    "2J":"Air Burkina",
    "2K":"KITTY HAWK A W",
    "2L":"Laer S.E. Lineas Aereas Entre Rios",
    "2M":"Moldavian Airlines",
    "2P":"PAL EXPRESS",
    "2Q":"Rusavia",
    "2R":"VIA RAIL",
    "2S":"Island Express",
    "2T":"CANADA 3000",
    "2U":"AIRCOMPANY KARAT",
    "2V":"AMTRAK",
    "2W":"Welcome Air",
    "2Z":"Chang-An Airlines",
    "3A":"Mafira Air",
    "3B":"AVIOR",
    "3C":"AIR CHATHAMS",
    "3D":"DENIM AIR",
    "3E":"Air Bretagne",
    "3G":"Atlant-Soyuz Airlines",
    "3H":"AIR INUIT",
    "3J":"Air Alliance",
    "3K":"Tatonduk Flying Service",
    "3L":"WEST ISLE AIR INC",
    "3M":"실버에어",
    "3N":"Air Urga",
    "3P":"Inter Tropical Aviation",
    "3Q":"China Yunnan Airlines",
    "3R":"DIVI DIVI AIR",
    "3S":"SHUSWAP AIR",
    "3T":"Turan Air",
    "3U":"사천항공",
    "3W":"Wanair",
    "3X":"Japan Air Commuter",
    "3Z":"Necon Air",
    "4A":"ARARAT AVIA",
    "4B":"부티크항공",
    "4C":"Aires S.A.",
    "4D":"Air Sinai",
    "4E":"Tanana Air Service",
    "4G":"Shenzhen Airlines",
    "4K":"Kenn Borek Air",
    "4M":"란 아르헨티나",
    "4N":"Air North",
    "4O":"Interjet Airlines",
    "4Q":"Aerolyon",
    "4R":"SPURWING AIR",
    "4S":"EAST WEST TRAVEL",
    "4T":"Tch Of Russian Airlines",
    "4U":"Tavaj-Transportes Regulares S.A",
    "4V":"Voyageur Airways",
    "4W":"Warbelow's Air Ventures Inc.",
    "4X":"F AIRLINES",
    "4Y":"YUTE AIR ALASKA",
    "4Z":"SA AIRLINK",
    "55":"5J STOCK - CEBU PACIFIC AIR",
    "5A":"ALPINE AV",
    "5B":"Tie Aviation Inc.",
    "5D":"AEROLITORAL",
    "5E":"BASE AIRLINES",
    "5F":"Arctic Circle Air Service",
    "5G":"QUEEN AIR",
    "5H":"KATO AIRLINES",
    "5J":"세부퍼시픽항공",
    "5K":"Odessa Airlines",
    "5L":"AeroSur",
    "5N":"Arkhangelsk Airlines",
    "5P":"Pena Transportes Aereos S/A",
    "5S":"L AEROPOSTALE",
    "5T":"AIR NEGRIL",
    "5U":"SKAGWAY A S",
    "5V":"Lviv Airlines",
    "5W":"SPEED ALLIANCE WESTBAHN",
    "5X":"UPS",
    "5Y":"Isles Of Scilly Skybus",
    "5Z":"셈에어",
    "6A":"Aviacsa",
    "6B":"BAXTER AV",
    "6C":"Cape Smythe Air Service Inc",
    "6D":"Pelita Air",
    "6E":"INDIGO",
    "6F":"LAKER AIRWAYS",
    "6G":"LAS VEGAS A L",
    "6H":"Israir",
    "6J":"솔라시드에어",
    "6L":"Aklak Air",
    "6M":"MAVERICK AW",
    "6N":"Trans Travel Airlines",
    "6Q":"Slovak Airlines",
    "6R":"BRITISH RAIL",
    "6S":"PROTEUS HELICOPT",
    "6T":"Air Mandalay Ltd",
    "6U":"Air UKraine",
    "6V":"Lignes Aeriennes Congolaises",
    "6Y":"Nicaraguense de Aviacion",
    "7A":"Haines Airways",
    "7B":"크라스노야르스크항공",
    "7C":"제주항공",
    "7D":"Donbass - Eastern Ukrainian Airlines",
    "7F":"First Air",
    "7G":"스타플라이어",
    "7H":"ERA AVIATION",
    "7K":"Kogalymavia",
    "7L":"AEROCARIBBEA",
    "7M":"Tyumen Airlines",
    "7P":"APA INT AIR",
    "7Q":"Air Venezuela L.T.A.C.A.",
    "7R":"Red Sea Air",
    "7T":"Air-Glaciers",
    "7U":"Aviaenergo",
    "7V":"FEDERAL AIRLINES PTY LTD",
    "7W":"Air Sask Aviation 1991",
    "7X":"THOMSON HOLIDAYS",
    "7Y":"MANN YADANARPON AIRLINES",
    "7Z":"L.B. Limited",
    "8A":"Arctic Air",
    "8B":"비지니스에어",
    "8C":"Shanxi Airlines",
    "8D":"Expo Aviation Ltd",
    "8E":"Bering Air",
    "8F":"Fischer Air",
    "8G":"Angel Airlines",
    "8H":"Equaflight",
    "8J":"JET 4 U",
    "8K":"Air Ostrava",
    "8M":"MYANMAR AIRWAYS INTL",
    "8N":"Nordkalottflyg",
    "8O":"West Coast Air",
    "8P":"Pacific Coastal Airlines Limited",
    "8Q":"Baker Aviation",
    "8R":"Sol Lineas Aereas",
    "8S":"AIR ALASKA CGO",
    "8T":"Air Tindi Ltd",
    "8V":"Wright Air Service",
    "8W":"Air Team",
    "8Y":"아스트로 에어",
    "99":"IATA CHARGE",
    "9A":"AIR ATLANTIC LTD",
    "9B":"엑세스레일",
    "9C":"스프링 항공",
    "9D":"TOUMAI AIR TCHAD",
    "9E":"Northwest Airlink",
    "9F":"Eurostar",
    "9H":"Ecoair",
    "9I":"방콕에어",
    "9J":"Dana Airlines Limited ",
    "9K":"케이프항공",
    "9L":"COLGAN AIR",
    "9M":"CENTRAL MOUNTAIN",
    "9N":"TRANS STATE A L",
    "9P":"Pelangi Airways",
    "9Q":"Pb Air",
    "9R":"SATENA",
    "9S":"SOUTHERN AIR",
    "9T":"Athabaska Airways Ltd.",
    "9U":"Air Moldova",
    "9V":"AVIOR AIRLINES",
    "9W":"제트에어웨이즈",
    "9X":"DIAMOND INTL AL",
    "9Y":"카자흐스탄항공",
    "A0":"CAMAIR AIR",
    "A2":"CIELOS DEL PERU",
    "A3":"에게안항공",
    "A4":"JSC AZIMUTH AIRLINES",
    "A5":"Tikal Jets S.A.",
    "A6":"Air Alps Aviation",
    "A7":"ASTANAIR",
    "A8":"AEROLINEAS PYS",
    "A9":"AIRZENA GEORGIAN",
    "AA":"아메리칸항공",
    "AB":"베를린항공",
    "AC":"에어캐나다",
    "AD":"Azul 브라질",
    "AE":"만다린항공",
    "AF":"에어프랑스",
    "AH":"Air Algerie",
    "AI":"에어인디아",
    "AK":"Airasia",
    "AM":"에어로멕시코",
    "AN":"안셋항공",
    "AO":"호주항공",
    "AP":"Air One",
    "AQ":"9 AIR CO LTD",
    "AR":"아르헨티나항공",
    "AS":"알래스카에어라인",
    "AT":"로얄에어모로코",
    "AU":"아우스트랄 항공",
    "AV":"아비앙카항공",
    "AW":"Dirgantara Air Services",
    "AX":"트랜스스테이트",
    "AY":"핀에어",
    "AZ":"알리탈리아",
    "B0":"PROMECH INC",
    "B1":"비틀훼리",
    "B2":"Belavia",
    "B3":"Bellview Airlines",
    "B4":"BHOJA A L",
    "B5":"Flightline",
    "B6":"제트블루에어",
    "B7":"유니항공",
    "B8":"에리트레아",
    "BA":"영국항공",
    "BB":"Seaborne Aviation Inc.",
    "BC":"스카이마크",
    "BD":"바이욘항공",
    "BE":"British European",
    "BF":"Aero-Service",
    "BG":"방글라데시항공",
    "BH":"HAWKAIR AVIATION SVCS",
    "BI":"로열브루나이항공",
    "BJ":"Santa Barbara Airlines C.A.",
    "BK":"오케이항공",
    "BL":"젯스타",
    "BN":"BRITANNIA",
    "BO":"Pt Bouraq Indonesia Airlines",
    "BP":"Air Botswana",
    "BQ":"Aeromar C. por A.",
    "BR":"에바항공",
    "BT":"Air Baltic Corporation",
    "BU":"Braathens Asa",
    "BV":"BLUE PANORAMA",
    "BW":"카리브항공",
    "BX":"에어부산",
    "BY":"BONAIRE AIRWAYS",
    "BZ":"블루버드",
    "C0":"WINGS OF ALASKA",
    "C1":"CZ-STOCK CHINA SOUTHERN AIRLIN",
    "C2":"Air Caribbean Limited",
    "C3":"Icar",
    "C4":"A L OF CARRIACOU",
    "C6":"BRIGHT AIR",
    "C7":"Aerocontinente Chile S.A.",
    "C8":"CHICAGO EXPRESS",
    "C9":"Cirrus Airlines",
    "CA":"중국국제항공",
    "CB":"Scotairways",
    "CC":"Macair",
    "CD":"Alliance Air",
    "CE":"Nationwide Air",
    "CG":"Mba Pty Ltd",
    "CH":"Bemidji Airlines",
    "CI":"중화항공",
    "CJ":"중국북방항공",
    "CK":"중국남방항공CZ 784",
    "CL":"LUFTHANSA CTYLINE",
    "CM":"코파항공",
    "CN":"그랜드차이나에어",
    "CO":"콘티넨탈항공",
    "CP":"COMPASS AIRLINES",
    "CQ":"SUNSHINE EXP AL",
    "CR":"카멜리아",
    "CS":"CONTINENTAL MICRO",
    "CT":"ALITALIA CITYLINER SPA",
    "CU":"Cubana",
    "CV":"Air Chathams",
    "CW":"Air Marshall Islands",
    "CX":"캐세이패시픽",
    "CY":"Cyprus Airways",
    "CZ":"중국남방항공",
    "D1":"부산 DELTA AIR LINES INC.",
    "D2":"다이나믹항공",
    "D3":"Daallo Airlines",
    "D5":"NEPC AIRLINES",
    "D6":"인터에어",
    "D7":"에어아시아",
    "D8":"Djibouti Airlines",
    "D9":"Aeroflot-Don",
    "DA":"AD STOCK - AIR PARADISE",
    "DB":"BRIT AIR",
    "DC":"Golden Air Flyg",
    "DD":"단동페리",
    "DE":"Condor Flugdienst",
    "DF":"AVIOSARDA AL",
    "DG":"씨에어",
    "DH":"ATLANTIC COAST A",
    "DI":"DEUTSCHE BA",
    "DJ":"버진 오스트레일리아",
    "DK":"Eastland Air",
    "DL":"델타항공",
    "DM":"Maersk Air",
    "DN":"VASO AIRLINES",
    "DO":"AIR VALLEE",
    "DP":"Air 2000",
    "DQ":"ALEXANDRIA AIRLINES",
    "DR":"Air Link",
    "DS":"Air Senegal",
    "DT":"TAAG앙골라",
    "DU":"Hemus Air",
    "DV":"Lufttaxi Dortmund",
    "DX":"Danish Air Transport",
    "DY":"NORWEGIAN AIR SHUTTLE",
    "DZ":"TRANSCARAIBES AIR",
    "E2":"Rio Grande Air",
    "E3":"Domodedovo Airlines",
    "E4":"Aero Asia",
    "E5":"Samara Airlines",
    "E6":"Aviaexpresscruise Airline",
    "E7":"DOWNEAST FLYING",
    "E8":"Alpi Eagles",
    "E9":"COMPAGNIE AFRICA",
    "EA":"EUROPEAN REGIONS",
    "EB":"EMERY WORLDWIDE",
    "EC":"AVIALEASING AVIATION COMPANY",
    "ED":"CCAIR",
    "EG":"AER LINGUS UK",
    "EH":"ALL NIPPON NETWORK CO",
    "EI":"에어 링구스",
    "EJ":"New England Airlines",
    "EK":"에미레이트",
    "EL":"Air Nippon",
    "EM":"EMPIRE AIRLINES",
    "EN":"Air Dolomiti",
    "EO":"LLC IKAIR DBA PEGAS FLY",
    "EP":"IRAN ASSEMAN",
    "EQ":"Tame Linea Aerea Del Ecuador",
    "ER":"DHL AW",
    "ES":"DHL INTL EC",
    "ET":"에티오피아",
    "EU":"Ecuatoriana de Aviacion S.A.",
    "EV":"ATLANTIC SOUTHEAST AIRLINES",
    "EW":"Eurowings",
    "EX":"SANTO DOMINGO",
    "EY":"에티하드항공",
    "EZ":"SUN AIR",
    "F3":"Flying Enterprise",
    "F4":"EURECA",
    "F6":"China National Aviation",
    "F7":"다윈항공",
    "F8":"AIR MONTREAL",
    "F9":"프론티어항공",
    "FA":"CENTENNIAL AL",
    "FB":"불가리아항공",
    "FC":"LINK AIRWAYS FLY FC",
    "FD":"CITYFLYER EXPRESS",
    "FE":"원동항공",
    "FF":"TOWER AIR",
    "FG":"ARIANA AFGHAN A L",
    "FH":"ASPIRING AIR",
    "FI":"아이슬란드항공",
    "FJ":"피지 에어웨이즈",
    "FK":"Keewatin Air Limited",
    "FL":"에어트랜",
    "FM":"상해항공",
    "FN":"Regional Air Lines",
    "FP":"PAR AVION",
    "FQ":"Air Aruba",
    "FR":"라이언에어",
    "FS":"Missionary Aviation Fellowship",
    "FT":"Siem Reap Airways",
    "FU":"Air Littoral",
    "FV":"러시아 에어",
    "FW":"Fair",
    "FX":"FEDEX",
    "FY":"파이어플라이",
    "FZ":"플라이두바이",
    "G1":"Trans Air Link",
    "G2":"Avirex Gabon",
    "G3":"GOL 항공",
    "G4":"Allegiant Air Inc.",
    "G5":"Enkor Airlines",
    "G7":"Gandalf Airlines",
    "G9":"Continental Wings",
    "GA":"가루다인도네시아",
    "GC":"GAMBIA INTL AIRLINES",
    "GD":"TAESA",
    "GE":"부흥항공",
    "GF":"걸프에어",
    "GG":"Air Guyane",
    "GH":"Ghana Airways",
    "GI":"ITEK AIR",
    "GK":"GO ONE AIRWAYS",
    "GL":"Greenlandair",
    "GM":"Air Slovakia",
    "GN":"Air Gabon",
    "GO":"AIR STORD AIRSYS",
    "GP":"CHINA GENERAL AV",
    "GQ":"Big Sky Airlines",
    "GR":"Aurigny Air Services",
    "GS":"TIANJIN AIRLINES",
    "GT":"GB AIRWAYS LTD",
    "GU":"Aviateca",
    "GV":"RIGA AIRLINES",
    "GW":"Airlines Of Kuban",
    "GX":"Pacific Air",
    "GY":"Guyana Airways Corporation",
    "GZ":"Air Rarotonga",
    "H1":"HAHN AIR SYSTEMS",
    "H2":"스카이항공",
    "H3":"Harbour Air Ltd",
    "H4":"Inter Islands Airlines",
    "H5":"Magadan Airlines",
    "H6":"해남항공",
    "H8":"극동러시아항공",
    "H9":"TAM EX LA",
    "HA":"하와이안항공",
    "HB":"그레이터베이 항공",
    "HC":"Aero-Tropics Air Services",
    "HD":"AIRDO CO LTD",
    "HE":"Luftfahrt Gesellschaft Walter",
    "HF":"Hapag Lloyd Fluggesellschaft",
    "HG":"니키항공",
    "HH":"Islandsflug",
    "HI":"Papillon Airways",
    "HJ":"Swedeways Air Lines",
    "HM":"에어세이셸",
    "HO":"길상항공",
    "HP":"아메리칸웨스트",
    "HQ":"THOMAS COOK AIRLINES",
    "HR":"한에어",
    "HS":"HIGHLAND AIR",
    "HU":"중국해남항공",
    "HV":"Transavia Airlines",
    "HW":"North-Wright Airways Ltd.",
    "HX":"홍콩항공",
    "HY":"우즈베키스탄",
    "HZ":"오로라항공",
    "I2":"이베리아 익스프레스",
    "I4":"JET EXP TIMETABLE",
    "I5":"에어아시아 인디아",
    "IA":"IRAQI A W",
    "IB":"이베리아",
    "IC":"인디아에어라인",
    "ID":"바틱항공",
    "IE":"솔로몬항공",
    "IG":"Meridiana",
    "IH":"Falcon Air",
    "IJ":"Air Liberte",
    "IK":"Imair",
    "IL":"Istanbul Airlines",
    "IM":"Intensive Air",
    "IN":"마케도니아항공",
    "IO":"인도네시아항공",
    "IQ":"Augsburg Airways",
    "IR":"이란항공",
    "IT":"Tigeair Taiwan",
    "IU":"AIR STRAUBING",
    "IV":"Fujian Airlines",
    "IW":"AOM French Airlines",
    "IX":"FLANDRE AIR",
    "IY":"Yemenia Yemen Airways",
    "IZ":"아키스이스라엘항공",
    "J1":"AIRVANTAGE LLC",
    "J2":"Azerbaijan Airlines",
    "J3":"NORTHWESTERN AIR",
    "J4":"Buffalo Airways Ltd.",
    "J6":"Larry's Flying Service",
    "J7":"Centre-Avia Airlines",
    "J8":"Berjaya Air",
    "J9":"Guinee Airlines",
    "JA":"Air Bosna",
    "JB":"Helijet Airways",
    "JC":"Jal Express",
    "JD":"Beijing Capital Airlines Co. Ltd.",
    "JE":"Manx Airlines",
    "JF":"젯아시아",
    "JG":"AIR GREECE",
    "JH":"Nordeste-Linhas Aereas Regionais SA",
    "JI":"Midway Airlines",
    "JJ":"탐항공",
    "JK":"Spanair",
    "JL":"일본항공",
    "JM":"JAMBOJET",
    "JN":"준 항공",
    "JO":"Jalways",
    "JP":"아드리아항공",
    "JQ":"JETSTAR AIRWAYS",
    "JR":"JOY AIR",
    "JS":"Air Koryo",
    "JT":"TRANSAIR",
    "JU":"세르비아항공",
    "JV":"Bearskin Airlines",
    "JX":"Nice Helicopteres",
    "JY":"British European",
    "JZ":"Skyways",
    "K0":"ALASKA CENTRAL E",
    "K2":"키르키스탄항공",
    "K3":"TAQUAN AIR SVC",
    "K4":"KRONFLYG",
    "K6":"캄보디아앙코르항공",
    "K7":"AIR KBZ",
    "K8":"KALININGRAD AIR",
    "K9":"Skyward Aviation",
    "KA":"드래곤항공",
    "KB":"드럭항공",
    "KC":"에어아스타나",
    "KD":"Kendell Airlines",
    "KE":"대한항공",
    "KF":"블루원",
    "KG":"KING ISLAND A L",
    "KH":"ALOHA AIR CARGO",
    "KI":"AIR ATLANTIQUE",
    "KJ":"BRITISH MED A W",
    "KK":"ATLASJET AIRLINES INC",
    "KL":"KLM네덜란드",
    "KM":"에어몰타",
    "KN":"Maroomba Airlines",
    "KO":"ALASKA CENTRAL E",
    "KP":"KIWI INTL",
    "KQ":"케냐항공",
    "KS":"Penair",
    "KT":"캄보디아항공",
    "KU":"쿠웨이트항공",
    "KV":"AIR CANADA EXPRESS - SKY REGIONAL AIRLINES",
    "KW":"Air Company Kas",
    "KX":"Cayman Airways",
    "KY":"AIR S T PRINCIPE",
    "L1":"POLAR AIR CARGO",
    "L2":"DJIBOUTI AIR",
    "L3":"AIR KETCHUM IDAHO",
    "L4":"Lauda-Air S.P.A",
    "L5":"Helikopter Service",
    "L6":"MAURITANIAN AIRLINES INT",
    "L7":"LAOAG INTL A L",
    "L8":"EUROPEAN AIRWAYS",
    "L9":"Air Mali S.A.",
    "LA":"란항공",
    "LB":"Lloyd Aereo Boliviano",
    "LC":"LEGEND AIR",
    "LD":"Linea Turistica Aereotuy",
    "LE":"Helgoland Airlines",
    "LF":"LUFTHANSA CGO IND",
    "LG":"Luxair",
    "LH":"루프트한자",
    "LI":"LIAT (1974)",
    "LJ":"진에어",
    "LK":"GOLDFIELDS A S",
    "LL":"LINEAS AEREAS ALG",
    "LM":"Air Alm",
    "LN":"Libyan Arab Airlines",
    "LO":"LOT폴란드항공",
    "LP":"란페루",
    "LQ":"Lanmei Airlines",
    "LR":"LACSA",
    "LS":"Iliamna Air Taxi",
    "LT":"L.T.U. International Airways",
    "LV":"Albanian Airlines",
    "LW":"퍼시픽윙스",
    "LX":"스위스에어라인",
    "LY":"엘알이스라엘",
    "LZ":"발칸",
    "M2":"Mahfooz Aviation",
    "M3":"EUROPEAN EXPRESS",
    "M4":"AVIOMPEX",
    "M6":"Chalair",
    "M7":"Tropical Airways D'Haiti S.A.",
    "M8":"SKYJET",
    "MA":"말레브헝가리항공",
    "MB":"WESTERN A L",
    "MD":"마다가스카르",
    "ME":"Middle East Airlines",
    "MF":"하문항공",
    "MG":"DJIBOUTI AL",
    "MH":"말레이시아항공",
    "MI":"Silk Air",
    "MJ":"Lapa",
    "MK":"모리셔스항공",
    "ML":"AIR MALI S A",
    "MM":"피치항공",
    "MN":"COMMERCIAL A W",
    "MO":"Calm Air Intl Ltd",
    "MP":"Martinair Holland",
    "MQ":"Simmons Airlines",
    "MR":"훈누에어",
    "MS":"이집트에어",
    "MT":"MELBOURNE REG A L",
    "MU":"중국동방항공",
    "MW":"모클레레항공",
    "MX":"멕시코항공",
    "MZ":"메르파티항공",
    "N1":"NX-STOCK AIR MACAU COMPANY LI",
    "N2":"Aerolineas Internacionales",
    "N4":"LLC NORD WIND",
    "N6":"Aero Continente",
    "N7":"Ragun Air",
    "N9":"North Coast Aviation",
    "NA":"NORTH AMERICAN",
    "NB":"STERLING",
    "NC":"National Jet System",
    "ND":"Airlink Limited",
    "NE":"NORTHEAST FS",
    "NF":"Air Vanuatu",
    "NG":"Lauda Air",
    "NH":"ANA항공",
    "NI":"Portugalia",
    "NJ":"Vanguard Airlines",
    "NK":"스피릿항공",
    "NL":"Shaheen Air International",
    "NN":"VIM AIRLINES",
    "NO":"AUS AIR",
    "NQ":"AIR JAPAN COMPANY LTD",
    "NR":"NORONTAIR",
    "NS":"CAPE YORK AIR SVC",
    "NT":"Binter Canarias",
    "NU":"Japan Transocean Air",
    "NV":"NORTHWEST TERR",
    "NW":"노스웨스트항공",
    "NX":"에어마카오",
    "NY":"AIR ICELAND",
    "NZ":"에어뉴질랜드",
    "O2":"국내 아시아나 (new)",
    "O3":"ASIANA AIRLINES (청주국내)",
    "O4":"ANTRAK AIR LIMITED",
    "O5":"ASIANA AIRLINES(대구국내)",
    "O6":"AVIANCA BRASIL",
    "OA":"올림픽항공",
    "OB":"BOLIVIANA DE AVIACION",
    "OC":"SUNSHINE AV",
    "OD":"말린도에어",
    "OE":"WESTAIR A L",
    "OF":"Travaux Aeriens De Madagascar",
    "OG":"Go Fly Ltd",
    "OH":"COMAIR",
    "OJ":"Air St Barthelemy",
    "OK":"체코항공",
    "OL":"Olt Ostfriesische Lufttransport GMBH",
    "OM":"몽골항공",
    "ON":"Air Nauru",
    "OO":"SKY WEST A L",
    "OP":"CHALKS OCEAN",
    "OR":"Crimea Air",
    "OS":"오스트리아항공",
    "OT":"Avant Airlines",
    "OU":"크로아티아항공",
    "OV":"Estonian Air",
    "OW":"EXECUTIVE AIR",
    "OX":"오리엔트 익스프레스",
    "OZ":"아시아나항공",
    "P1":"PUBLIC CHARTERS",
    "P4":"Aero Lineas Sosa",
    "P5":"Aerorepublica",
    "P6":"TRANS AIR",
    "P7":"JOINT STOCK CO",
    "P8":"Pantanal Linhas Aereas",
    "PC":"페가수스항공",
    "PD":"Pem-Air",
    "PE":"Air Europe",
    "PF":"PALESTINIAN AIRL",
    "PG":"방콕항공",
    "PH":"Polynesian Airlines",
    "PI":"Sunflower Airlines Ltd.",
    "PJ":"AIR ST PIERRE",
    "PK":"파키스탄항공",
    "PL":"AEROPERU",
    "PM":"Tropic Air",
    "PN":"Pan American Airways Corp.",
    "PO":"POLAR AIR CARGO",
    "PQ":"TROPICAL INTL",
    "PR":"필리핀항공",
    "PS":"우크라이나항공",
    "PT":"WEST AIR SWEDEN",
    "PU":"Pluna",
    "PW":"프리시전에어",
    "PX":"에어뉴기니",
    "PY":"Surinam Airways",
    "PZ":"탐 메르코수르",
    "Q3":"Zambian Airways",
    "Q4":"EUROAIRLINES",
    "Q5":"40 Mile Air",
    "Q6":"Aero-Condor S.A.",
    "Q7":"Sobelair",
    "Q8":"MINERVA AIRLINES",
    "Q9":"SAYAKHAT AIR CO",
    "QA":"AERO CARIBE",
    "QC":"Aviation Quebec Labrador",
    "QD":"JC INTL AIRLINES",
    "QE":"Crossair Europe",
    "QF":"콴타스항공",
    "QG":"Citilink Indonesia",
    "QH":"뱀부항공",
    "QI":"Cimber Air",
    "QJ":"Latpass Airlines",
    "QK":"AIR NOVA",
    "QL":"LASER",
    "QM":"Air Malawi",
    "QN":"Royal Aviation Inc.",
    "QO":"Origin Pacific Airways",
    "QP":"Starlight Airline",
    "QQ":"RENO AIR",
    "QR":"카타르항공",
    "QS":"Travel Service A.S.",
    "QT":"Regional Air",
    "QU":"Uganda Airlines",
    "QV":"라오항공",
    "QW":"Turks/Caicos Airways",
    "QX":"Horizon Airlines",
    "QY":"EUROPEAN AIR TR",
    "QZ":"ZAMBIA A W",
    "R2":"GROUPE TRANSAIR S.A.",
    "R3":"야쿠티아 항공",
    "R4":"The State Transport Company Russia",
    "R6":"Air Srpska",
    "R7":"Aserca Airlines",
    "R8":"Reguljair",
    "R9":"CAMAIR AIR",
    "RA":"로얄네팔항공",
    "RB":"Syrian Arab Airlines",
    "RC":"ATLANTIC AIRWAYS/ATLANTIC AIRWAYS",
    "RD":"Alitalia Team",
    "RE":"Aer Arann Express",
    "RF":"Ord Air Charter",
    "RG":"바리그브라질",
    "RH":"Regionnair Inc.",
    "RJ":"요르단항공",
    "RK":"Air Afrique",
    "RM":"AIRCOMPANY ARMENIA",
    "RN":"EURALAIR INTL",
    "RO":"타롬 루마니아",
    "RP":"CHAUTAUQUA 항공",
    "RQ":"KLM Alps / Air Engiadina",
    "RR":"Royal Air Force",
    "RS":"에어서울",
    "RT":"Airlines Of South Australia",
    "RU":"Tci Skyking Ltd.",
    "RV":"AIR CANADA ROUGE",
    "RW":"RAS FLUGGESELL",
    "RX":"Redwing Airways Inc.",
    "RY":"로얄캐리비안크루즈",
    "RZ":"Servicios Aereos Nacionales S.A.(Sansa)",
    "S1":"SU-STOCK AEROFLOT RUSSIAN AIRL",
    "S2":"Sahara Airlines",
    "S4":"Sata International",
    "S5":"Shuttle America",
    "S7":"SIBERIA AIRLINES",
    "S8":"SOUNDS AIR",
    "SA":"남아프리카항공",
    "SB":"에어칼린",
    "SC":"산동항공",
    "SD":"Sudan Airways",
    "SE":"Samoa Air",
    "SF":"SHANGHAI A L",
    "SG":"Jetsgo",
    "SH":"AIR TOULOUSE",
    "SI":"SVEA FLYG",
    "SJ":"Aviandina",
    "SK":"스칸디나비아",
    "SL":"THAI LION AIR",
    "SM":"SUNWORLD INTL",
    "SN":"브뤼셀항공",
    "SP":"SATA Air Acores",
    "SQ":"싱가포르항공",
    "SR":"SWISS AIR TRANSPORT CO. LTD.(S",
    "SS":"Corsair International",
    "ST":"Yanda Airlines",
    "SU":"러시아항공",
    "SV":"사우디아라비아항공",
    "SW":"Air Namibia",
    "SX":"AEROJECUTIVO",
    "SY":"SUN COUNTRY",
    "SZ":"AIRCOMPANY SOMON AIR",
    "T1":"부산THAI AIRWAYS INTERNATIONAL",
    "T2":"Nakina Air Service Ltd.",
    "T3":"Eastern Airways",
    "T4":"Transeast Airlines",
    "T5":"TURKMENISTAN",
    "T6":"Tavrey Air Company",
    "T7":"Trans Asian Airlines",
    "T9":"Varmlandsflyg",
    "TA":"그루포타카항공",
    "TC":"Air Tanzania",
    "TD":"Atlantis European Airways",
    "TE":"Lithuanian Airlines",
    "TF":"Tasman Australia Airlines",
    "TG":"타이항공",
    "TI":"AIR BALTIC CORP",
    "TJ":"Tradewind Aviation",
    "TK":"터키항공",
    "TL":"에어노스",
    "TM":"LAN-Linhas Aereas De Mocambique",
    "TN":"타이티누이 항공",
    "TO":"PRESEDENT AL",
    "TP":"에어포르투갈",
    "TQ":"TANDEM AERO",
    "TR":"Transbrasil",
    "TS":"Air Transat A.T.Inc.",
    "TT":"TIGER AIRWAYS AUSTRALIA",
    "TU":"Tunis Air",
    "TV":"Virgin Express",
    "TW":"티웨이항공",
    "TX":"La Caribeenne Des Transports Aeriens",
    "U2":"이지젯",
    "U4":"PMT항공",
    "U5":"Iba",
    "U6":"Ural Airlines",
    "U8":"TUS AIRWAYS LTD",
    "UA":"유나이티드항공",
    "UB":"미얀마항공",
    "UC":"LAN Chile Cargo",
    "UD":"HEX AIR",
    "UE":"ULTIMATE AIR SHUTTLE",
    "UF":"UKRANIAN-MEDITER",
    "UG":"TUNINTER",
    "UJ":"AEROSANT A L",
    "UK":"VISTARA",
    "UL":"스리랑카항공",
    "UM":"에어짐바브웨",
    "UN":"트랜스아에로",
    "UO":"홍콩익스프레스",
    "UP":"Bahamasair",
    "UQ":"O'Connor - Mt Gambiers Airline",
    "UR":"Ukraine National Airlines",
    "US":"US에어",
    "UT":"UTA",
    "UU":"Air Austral",
    "UV":"Helicopteros Del Sureste",
    "UW":"Perimeter Airlines",
    "UX":"에어유로파",
    "UY":"Cameroon Airlines",
    "V3":"Carpatair",
    "V4":"South Airlines",
    "V5":"VNUKOVO AIR",
    "V6":"ORIENT AVIA AL",
    "V8":"Air Mikisew",
    "VA":"버진 오스트레일리아",
    "VB":"",
    "VC":"Servivensa",
    "VD":"AIR LIBERTE",
    "VE":"Avensa",
    "VF":"TROPICAL AIRLINES",
    "VH":"AEROPOSTAL Alas De Venezuela S.A.",
    "VI":"Vieques Air Link",
    "VJ":"비엣젯항공",
    "VL":"North Vancouver Air",
    "VM":"Regional Airlines",
    "VN":"베트남항공",
    "VO":"Tyrolean Airways",
    "VP":"인도차이나항공",
    "VQ":"Impulse Airlines",
    "VR":"Tacv Cabo Verde Airlines",
    "VS":"버진아틀란틱",
    "VT":"에어 타히티",
    "VU":"AIR IVOIRE",
    "VV":"에어로스비트",
    "VW":"아에로마르",
    "VX":"버진아메리카",
    "VY":"부엘링항공",
    "VZ":"ARIZONA A W",
    "W1":"FINE AIRLINES",
    "W2":"플렉스플라잇",
    "W4":"LC Peru",
    "W5":"마한에어",
    "W8":"LA COSTENA S A",
    "W9":"EASTWIND AIR",
    "WB":"Servicios Aereos Nacionales",
    "WC":"Islena Airlines",
    "WD":"위동페이",
    "WE":"타이스마일",
    "WF":"Wideroe's Flyveselskap",
    "WG":"WASAYA AIR",
    "WH":"China Northwest Airlines",
    "WI":"WHITE AIRWAYS S.A.",
    "WJ":"Labrador Airways",
    "WK":"American Falcon S.A.",
    "WL":"WORLD ATLANTIC AIRLINES",
    "WM":"Windward Island Airways International",
    "WN":"사우스웨스트항공",
    "WO":"WORLD AIRWAYS",
    "WP":"아일랜드에어",
    "WR":"Royal Tongan Airlines",
    "WS":"웨스트젯",
    "WT":"Nigeria Airways",
    "WU":"WUHAN A L",
    "WW":"Whyalla Airlines",
    "WX":"City Jet",
    "WY":"오만에어",
    "WZ":"WESTERN AIR",
    "X3":"Baikal Airlines",
    "X5":"Cronus Airlines",
    "X7":"Zambia Skyways",
    "X8":"AEROVIAS DAP S A",
    "X9":"JSC AVION EXPRESS",
    "XB":"TASF",
    "XC":"K.D. Air Corporation",
    "XD":"A L IND FUNCTION",
    "XE":"코리아 익스프레스에어",
    "XF":"블라디보스톡항공",
    "XG":"REG LINEAS AEREAS",
    "XJ":"타이에어아시아엑스",
    "XK":"Compagnie Aerienne Corse Mediterranee",
    "XL":"LAN ECUADOR",
    "XM":"ALITALIA S.P.A",
    "XN":"Axon Airlines",
    "XO":"씨에어",
    "XP":"TAHOE AIR",
    "XQ":"ACTION A L",
    "XR":"LUXAVIA AIRWING",
    "XT":"KLM Exel",
    "XV":"AIR EXPRESS",
    "XW":"녹스쿠트항공",
    "XY":"RYAN AIR INC",
    "XZ":"사우스 아프리칸 익스프레스",
    "Y2":"Sa Alliance Air",
    "Y4":"Eagle Aviation",
    "Y6":"EUROPE ELITE",
    "Y7":"F S AIR SVC",
    "Y8":"Passaredo Transportes Aereos S/A",
    "Y9":"TRANS AIR CONGO",
    "YC":"Flight West Airlines",
    "YD":"Gomelavia",
    "YE":"EMERALD AIR",
    "YH":"AIR NUNAVUT",
    "YI":"Air Sunshine",
    "YK":"아비아트랙픽",
    "YL":"LIBYAN WINGS",
    "YM":"몬테네그로항공",
    "YN":"Air Creebec (1994) Inc.",
    "YO":"헬리에어모나코",
    "YP":"에어프레미아",
    "YQ":"TAR AEROLINEAS",
    "YR":"Scenic Airlines",
    "YT":"YETI AIRLINES",
    "YU":"Dominair",
    "YV":"메사에어",
    "YW":"AIR NOSTRUM",
    "YX":"Midwest Express Airlines Inc.",
    "YY":"모든항공사",
    "Z1":"SANTA CRUZ AIR",
    "Z2":"필리핀에어아시아",
    "Z3":"Promech Air Inc",
    "Z4":"팬스타페리",
    "Z6":"Dnieproavia Joint Stock Aviation Co",
    "Z8":"아마조나스",
    "Z9":"BEK AIR",
    "ZA":"스카이앙코르",
    "ZB":"Monarch Airlines",
    "ZD":"ROSS AVIATION INC",
    "ZE":"이스타항공",
    "ZF":"AIRBORNE SWEDEN",
    "ZG":"VIVA MACAU",
    "ZH":"심천항공",
    "ZI":"AIGLE AZUR",
    "ZJ":"TEDDY AIR",
    "ZK":"그레이트레이크항공",
    "ZL":"Hazelton Airlines",
    "ZM":"SCIBE AIRLIFT",
    "ZN":"Eagle Airlines",
    "ZO":"Ozark Air Lines Inc.",
    "ZP":"Air St. Thomas",
    "ZQ":"안셋뉴질랜드항공",
    "ZR":"Muk Air",
    "ZS":"Azzurra Air",
    "ZT":"Air Zambezi",
    "ZV":"브이에어",
    "ZW":"AIR WISCONSIN",
    "ZX":"AIRBC",
    "ZY":"ADA Air"
}