import {atom} from "recoil";

/**
 *
 * hccVoucherAbleAmount: null, //"99999999",
 * hccVoucherAbleRemaining: null, //"92303009",
 * hccVoucherNumber: null,// "TS1QBC7GI2E48E7FG",
 * hccVoucherUseAbleDate: null,// "2028-03-23 00:00:00",
 * hccVoucherUseRegDate: null,// "2023-03-23 17:55:27",
 * usable: true, // true,false
 * useAmount: null, // 아마 문자열 숫자로 올듯?
 * voucherNumber: null// "TS1QBC7GI2E48E7FG",
 * */
export const ticketState = atom({
    key: 'ticketState', // Unique ID (with respect to other atoms/selectors)
    default: {
        loading: false,
        error: null,
        ticketList: [],
    },
});


export const couponState = atom({
    key: 'couponState', // Unique ID (with respect to other atoms/selectors)
    default: {
        loading: false,
        error: null,
        couponList: [],
    },
});
