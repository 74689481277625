export default class UserMPointEntity {
    constructor(data) {
        this.data = data;
    }

    isValid() {
        if (this.data == null) {
            return false;
        }
        if (this.data['loading']) {
            return false;
        }
        if(this.data["mPoint"] ===0) {
            return false
        }

        return this.data["mPoint"]['isMPointCardYn']
    }

    isLoaded() {
        if (!this.data) return false
        return this.data['loading'] === false
    }

    static getPercent() {
        // M포인트 현재 10%로 고정
        return 10
    }

    getMPoint() {
        if (!this.isValid()) return 0
        if (!this.data["mPoint"]) return 0
        return this.data["mPoint"]['mpoint']
    }

    getData() {
        if (!this.isValid()) return null
        return this.data["mPoint"]
    }
    static calculateMaxMPoint(sumAmount) {
        const percent = this.getPercent()
        return sumAmount * percent / 100
    }
}