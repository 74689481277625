import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
        removeEle();
    }, [pathname]);

    const removeEle = () =>{
        const headerEle = document.querySelector('.o-CHO-inner')
        if (headerEle) {
            headerEle.classList.remove(`header-sec-fixed`)
        }
        const commonHeaderObjEle = document.querySelector('.commonHeaderObject')
        if (commonHeaderObjEle) {
            commonHeaderObjEle.style.height = 'auto'
        }
    }

    return null;
};