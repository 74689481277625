import moment from "moment/moment";
import React from "react";


export default function ReservationProductSummaryCard({
                                                          saleProductCtx,
                                                          representativeProductCtx,
                                                      }) {

    if (!saleProductCtx) {
        return null;
    }
    if (saleProductCtx.loading) {
        return null
    }
    if (!representativeProductCtx) {
        return null
    }
    if (representativeProductCtx.loading) {
        return null
    }
    const saleProduct = saleProductCtx.saleProduct
    const representativeProduct = representativeProductCtx.representativeProduct
    const {
        saleProductName,
        saleProductCode,
        supplierName,
        departureDay,
        departureTime,
        arrivalDay,
        arrivalTime,
        travelDayCount,
        travelNightCount,
    } = saleProduct.getData();

    const {
        representImageUrl
    } = representativeProduct.getData();


    function onClickSaleProduct() {
        window.location.href = saleProduct.getDetailUrl()
    }

    return (
        <section className="book-detail-area a-mt50">
            <div className="pkg-tit-page-cont bb-line">
                <h3 className="title">주문상품</h3>
            </div>

            <div className="book-article border-bottom">
                <div className="book-pd-info-part">
                    <ImageFrameComponent imageUrl={representImageUrl} onClick={onClickSaleProduct}/>

                    <div className="pd-info">
                        <div className="wfull">
                            <p className="pd-nav">
                                <span>{supplierName}</span>
                                <span>상품코드 {saleProductCode}</span>
                            </p>
                            <div className="pd-title">
                                <a style={{cursor: "pointer"}} onClick={onClickSaleProduct}>
                                    {saleProductName}
                                </a>
                            </div>
                        </div>
                        <p className="pd-book-seleted">
                            {moment(departureDay).format("MM/DD(dd)")} {moment(departureTime).format("HH:mm")}
                            &nbsp; ~ &nbsp;
                            {moment(arrivalDay).format("MM/DD(dd)")} {moment(arrivalTime).format("HH:mm")}
                            &nbsp; ({travelNightCount}박 {travelDayCount}일)
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}


function ImageFrameComponent({imageUrl, onClick}) {
    if (!imageUrl) {
        return (
            <span className={"vis"} onClick={onClick}>
                <span className={"no-img"}></span>
            </span>
        )
    }

    if (imageUrl === 'http://image8.hanatour.com/product/img_no_M.gif') {
        return (
            <span className={"vis"} onClick={onClick}>
                <span className={"no-img"}></span>
            </span>
        )
    }

    return (
        <span className="vis" style={{cursor: "pointer"}} onClick={onClick}>
            <img src={imageUrl} alt=""/>
        </span>
    )
}