import useLoadChildProductDynamicList from "../../loader/useLoadChildProductDynamicList";
import useChildProductFilter from "../product-filter/child/useChildProductFilter";
import useMoment from "../../../infra/hooks/moment/useMoment";
import {getToday} from "../../../infra/time/Kmoment";

export default function useListChildProduct({parentProduct, notLoad = false}) {
    const {
        childProductList,
        loading,
        error,
        allLoadedChildProductList,
        isEmpty,
    } = useLoadChildProductDynamicList({parentProduct, notLoad})
    const {moment, today} = useMoment()


    const {
        filtering: {atDateFiltering, afterDateFiltering},
        sorting: {sortPrice, sortDepartureDate},
        extractPrice
    } = useChildProductFilter()

    function minPriceAt(yyyymmdd) {
        if (allLoadedChildProductList.length === 0) return null

        const sortAsc = sortPrice('asc')

        const list = allLoadedChildProductList
            .filter(atDateFiltering(getToday()))

        list.sort(sortAsc)
        if (list.length === 0) return null
        const newAll = [...allLoadedChildProductList]
        newAll.sort(sortAsc)
        const allMinPrice = extractPrice(newAll[0])
        const minPrice = extractPrice(list[0])

        return {
            minPrice,
            isMinPrice: minPrice === allMinPrice
        }
    }

    function gotoOnlyOne() {
        /* DT-19632
        * 모상품 하위에 자상품도 1개인 경우 (날짜 1개, 해당 날짜의 자상품도 1개라서 선택할 필요도 없는 경우) 상품상세로 바로 이동입니다.
        * */
        if (loading === true) return
        if (allLoadedChildProductList.length === 1) {
            gotoDetail( allLoadedChildProductList[0])
        }
    }

    function getChildProductMapByDepartureDay() {

        const ret = allLoadedChildProductList.reduce((acc, product) => {
            const departureDayIso = product['departureDay']
            const day = moment.makeDayByIso8601(departureDayIso)
            const dayString = moment.makeStringToYYYYMMDD(day)
            if (dayString) {
                if (!acc[dayString]) {
                    acc[dayString] = []
                }
                acc[dayString].push({
                    ...product,
                    day,
                    calculatedPrice: extractPrice(product)
                })
            }
            return acc
        }, {})

        return ret
    }

    function gotoDetail(childProduct) {
        const departureDayIso = childProduct['departureDay']
        const day = moment.makeDayByIso8601(departureDayIso)
        const dayString = moment.makeStringToYYYYMMDD(day)
        const searchKeyword = childProduct.saleProductName
        const param = `searchKeyword=${encodeURIComponent(searchKeyword)}&&startAt=${dayString}&&endAt=${dayString}`
        // DT-26511 모상품 & 자상품은 searchKeyword 없게 수정
        // window.location.href = `/representative/${childProduct.representProductCode}/sale/${childProduct.saleProductCode}/detail?${param}`;
        window.location.href = `/representative/${childProduct.representProductCode}/sale/${childProduct.saleProductCode}/detail`;
    }


    return {
        getChildProductMapByDepartureDay,
        childProductList,
        loading,
        minPriceAt,
        error,
        gotoOnlyOne,
        gotoDetail,
        isEmpty,
    }

}