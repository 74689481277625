import React from "react";

function Pagination({ total, limit, page, setPage }) {

    const numPages = Math.ceil(total / limit);

    return (
        <React.Fragment>
            <div className="paging-wrap">
                <a style={{cursor: "pointer"}} className={page === 1 ? "btn first disabled" : "btn first"} onClick={() => setPage(1)}>처음</a>
                <a style={{cursor: "pointer"}} className={page === 1 ? "btn prev disabled" : "btn prev"} onClick={() => setPage(page - 1)}>이전</a>
                {Array(numPages).fill().map((_, i) => (
                    <a style={{cursor: "pointer"}} className={page === i + 1 ? "num on" : "num"}
                        key={`paging-num${i + 1}`}
                        onClick={() => setPage(i + 1)}
                        aria-current={page === i + 1 ? "page" : null}
                    >
                      {i + 1}
                    </a>
                ))}
                {total === 0 &&
                    <a style={{cursor: "pointer"}} className="num" aria-current="page">1</a>
                }
                <a style={{cursor: "pointer"}} className={page === numPages ? "btn next disabled" : "btn next"} onClick={() => setPage(page + 1)}>다음</a>
                <a style={{cursor: "pointer"}} className={page === numPages ? "btn last disabled" : "btn last"} onClick={() => setPage(numPages)}>마지막</a>
            </div>
        </React.Fragment>
    );
}

export default Pagination;
