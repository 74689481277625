

const CantFindReservation = () => {

  return (
      <section className="pkg-nodata-sec ly-inner">
          <div className="pkg-list-nodata">
              <i className="icon res-nodata" />
              <p className="con"> 예약을 찾을 수 없습니다. </p>
              <span className="btn-base btn-st-full btn-line-bl btn-color-bl">
              <a href="javascript:history.go(-1);" className="btn-init reset">이전 페이지로</a>
            </span>
              <span className="btn-base btn-st-full btn-bg-bl">
              <a className="btn-init reset">메인 페이지로</a>
            </span>
          </div>
      </section>
    );
};

export default CantFindReservation;