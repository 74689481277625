import React, {useEffect} from "react";

const ProductDetail = ({id, title, contents}) => {

    let content = "";
    if (typeof contents !== 'string' && contents && contents.length > 1) {
        content = contents.map((content) => {
            return "<div style='width: 100%'>" + content.scheduleInfo + "</div>";
        }).join("");
    } else {
        content = contents;
    }

    useEffect(() => {
        let moreBox = document.querySelectorAll('.more_box_wrap');
        moreBox.forEach((box) => {
            let moreBoxHeight = box.querySelector('.more-box').scrollHeight;
            if (moreBoxHeight > 800) {
                box.querySelector('.more-box').classList.add('isfold');
                box.querySelector('.more-view-btn-wrap').style.display = "block";
            } else {
                box.querySelector('.more-view-btn-wrap').style.display = "none";
            }
            // console.log("clientHeight: " + box.querySelector('.more-box').children.clientHeight);
            // if (box.querySelector('.more-box').children.clientHeight < 800) {
            //     box.querySelector('.more-box').style.height = "auto";
            // }
        });
    }, []);

    const clickMore = (e) => {
        e.preventDefault()
        let box = e.currentTarget.closest('.more_box_wrap');
        if (e.currentTarget.classList.contains('active')) {
            box.querySelector('.more-box').classList.remove('isfold');
            e.currentTarget.classList.remove('active');
        } else {
            box.querySelector('.more-box').classList.add('isfold');
            e.currentTarget.classList.add('active');
        }
    }

    return (
        <React.Fragment>
        { contents && (
            <article className="pkg-article ly-inner tab-on" id={id}>
                <div className="pkg-left-inner more_box_wrap" >
                    <div className="product-item-wrap more-box">
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '790px',
                            // minHeight: '800px',
                            height: '100%',
                            fontSize: '14px',
                        }} dangerouslySetInnerHTML={{__html: content}}></div>
                    </div>
                    <div className="more-view-btn-wrap">
                        <span className="btn-base btn-st-bfull wfull btn-line-grd bb-more active" onClick={(e) => { clickMore(e) }}>
                            <button type="button"><span className="off">{title} 더보기</span><span className="on">{title} 접기</span></button>
                        </span>
                    </div>
                </div>
            </article>
        )}
        </React.Fragment>
    );
};

export default ProductDetail;