const GetaddBanners = "Main/GetaddBanners";
const Getpictograms = "Main/Getpictograms";
const GetthemeA = "Main/GetthemeA";
const GetthemeB = "Main/GetthemeB";
const GetthemeC = "Main/GetthemeC";
const GetthemeD = "Main/GetthemeD";
const GetthemeE = "Main/GetthemeE";
const GetthemeF = "Main/GetthemeF";
const GetthemeTabA = "Main/GetthemeTabA"
const GetthemeTabB = "Main/GetthemeTabB"
const GetTOPBANNERS = "Main/GetTOPBANNERS"
const AddBannerData = 'Main/AddBannerData'


export const isGetBanners = ()=>({type : GetaddBanners})
export const isGetpictograms = ()=>({type : Getpictograms})
export const isGetthemeA = ()=>({type : GetthemeA})
export const isGetthemeB = ()=>({type : GetthemeB})
export const isGetthemeC = ()=>({type : GetthemeC})
export const isGetthemeD = ()=>({type : GetthemeD})
export const isGetthemeE = ()=>({type : GetthemeE})
export const isGetthemeF = ()=>({type : GetthemeF})
export const isGetthemeTabA = ()=>({type : GetthemeTabA})
export const isGetthemeTabB = ()=>({type : GetthemeTabB})
export const isGetTOPBANNERS = (payload)=>({type : AddBannerData , payload})


const initialState = {
    resData : []
}

const mainState = (state = initialState , action) =>{
    switch (action.type) {
        case AddBannerData :
            return{
                ...state ,
                resData : action.payload
            }
        case GetaddBanners:
            return {
                resData: action.resData
            }
        case Getpictograms:
            return {
                resData: state.pictograms
            }
        default:
            return state

    }
}
export default mainState;