import {Link} from "react-router-dom";
import React from "react";
import {VOUCHER_TYPE} from "../../../../../domain/model/user/UserVoucherEntity";


export default function VoucherModalContent({
                                                voucherType,
                                                title,
                                                voucherList,
                                                totalUseVoucherAmount,
                                                onApply,
                                                onCheck,
                                                onClose,
                                                selectedVoucherDict,
                                            }) {
    return (
        <>
            <div className="voucher-detail pkg-over-scroll" style={{maxHeight: '750px',marginTop:'16px'}}>
                <section className="lpu-item-sec">
                    <div className="lpu-sub-item">
                        <div className="lpu-sub-title a-mb20"> {title} </div>
                        <table summary={title} className="tbl-style list">
                            <caption>{title}에 대한 테이블</caption>
                            <colgroup>
                                <col width="*"/>
                                <col width={120}/>
                            </colgroup>
                            <tbody>
                            {
                                voucherList.map(voucher => {
                                    return <VoucherTrItem key={voucher.hccTicketSeq}
                                                          voucher={voucher}
                                                          isSelected={selectedVoucherDict[voucher.id] === true}
                                                          onCheckVoucher={onCheck}/>
                                })
                            }
                            </tbody>
                        </table>
                        <VoucherTotal
                            totalUseVoucherAmount={totalUseVoucherAmount}
                            onApplyVoucher={onApply}
                            onClose={onClose}
                        />
                    </div>

                    {selectIntroducer(voucherType)}
                    <div className="lpu-sub-item">
                        <div className="lpu-sub-title a-mb20 a-mt10">유의사항</div>
                        {selectAttention(voucherType)}
                    </div>
                </section>
            </div>
        </>
    )
}

function VoucherTotal({
                          totalUseVoucherAmount,
                          onApplyVoucher,
                          onClose,
                      }) {

    function handleApplyVoucher(e) {
        e.stopPropagation()
        onApplyVoucher && onApplyVoucher()
    }

    function handleCancel(e) {
        e.stopPropagation()
        onClose && onClose()
    }

    return (
        <div className="btn-wrap a-right a-mt20">
            <span className="voucher-price">바우처 총 사용 금액 <em>{totalUseVoucherAmount}</em> 만원</span>
            <span className="btn-base btn-st-full btn-bg-bl w130">
              <a style={{cursor: "pointer"}} onClick={handleApplyVoucher}>적용</a>
            </span>
            <span className="btn-base btn-st-full btn-bg-gr w130">
              <a style={{cursor: "pointer"}} onClick={handleCancel}>취소</a>
            </span>
        </div>
    )
}


function VoucherTrItem({voucher, onCheckVoucher, isSelected}) {
    // the Green Voucher {voucher.hccTicketAmount >= 10000 ? (voucher.hccTicketAmount / 10000) + '만원권' : formatNumber(voucher.hccTicketAmount) + '원권'}({moment(voucher.hccTicketServiceStartDate).format("YYYY/MM/DD")} ~ {moment(voucher.hccTicketServiceEndDate).format("YYYY/MM/DD")})
    function handleVoucherCheck(e) {
        e.stopPropagation()
        onCheckVoucher && onCheckVoucher(voucher)
    }

    return (
        <tr key={`${voucher.id}`}>
            <td>
                {voucher.name}
            </td>
            <td>
                  <span className="check-group">
                    <input type="checkbox" className="hidden_check"
                           id={`chk-voucher-${voucher.id}`}
                           name={`greenVoucher${voucher.id}`}
                           checked={isSelected}
                           readOnly={true}
                           onChange={handleVoucherCheck}
                    />
                    <label htmlFor={`chk-voucher-${voucher.id}`} className="label-check">사용</label>
                  </span>
            </td>
        </tr>
    )
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function selectIntroducer(voucherType) {
    switch (voucherType) {
        case VOUCHER_TYPE.RED5:
            return <RedVoucherIntroducer/>
        case VOUCHER_TYPE.GREEN:
            return <GreenVoucherIntroducer/>
        case VOUCHER_TYPE.RED4:
            return <RedVoucher4Introducer/>
        case VOUCHER_TYPE.RED_STRIP:
            return <RedVoucherStripIntroducer/>
        case VOUCHER_TYPE.SUMMIT:
            return <SummitVoucherIntroducer/>
        default:
            return <></>
    }
}

function SummitVoucherIntroducer() {
    return (
        <div className="lpu-sub-item">
            <div className="lpu-sub-title a-mb20 a-mt10">현대카드 Summit Voucher 서비스란?</div>
            <ul className="list-dot voucher-desc-list">
                <li>
                    현대카드 Summit 본인 회원에게만 제공되는 혜택으로 쇼핑, 호텔, 여행 영역에서 사용하실 수 있는 서비스 입니다.
                </li>
            </ul>
        </div>
    )
}


function RedVoucherIntroducer() {
    return (
        <div className="lpu-sub-item">
            <div className="lpu-sub-title a-mb20 a-mt10">the Red Voucher 서비스란?</div>
            <ul className="list-dot voucher-desc-list">
                <li>현대카드 the Red Edition5 본인 회원에게만 제공되는 혜택으로 다양한 라이프 스타일 영역(트래블∙쇼핑∙고메∙라이프스타일∙레저)에서 최대 20만원까지 선택하여 사용하실 수
                    있습니다.<br/>
                    * 본 서비스는 the Red Edition5 전용 혜택으로 기존 상품(theRed Edition4, the Red Edition3, the Red Edition2 등) 바우처
                    사용 불가합니다.
                </li>
            </ul>
        </div>
    )
}

function RedVoucher4Introducer() {

    return (
        <div className="lpu-sub-item">
            <div className="lpu-sub-title a-mb20 a-mt10">the Red Voucher 서비스란?</div>
            <ul className="list-dot voucher-desc-list">
                <li>현대카드 the Red Edition4 본인 회원에게만 제공되는 혜택으로 다양한 라이프스타일 영역(여행∙쇼핑∙고메∙뷰티∙커피)에서 최대 25만원권까지 선택하여 사용하실 수 있는
                    서비스
                    입니다.<br/>
                    * 본 서비스는 the Red Edition4 전용 혜택으로 기존 상품(the Red Edition3, the Red Edition2 등) 바우처로는 사용 불가합니다.
                </li>
            </ul>
        </div>
    )
}

function RedVoucherStripIntroducer() {
    return (
        <div className="lpu-sub-item">
            <div className="lpu-sub-title a-mb20 a-mt10">the Red Voucher 서비스란?</div>
            <ul className="list-dot voucher-desc-list">
                <li>현대카드 SC제일은행-the Red Stripe 본인 회원에게만 제공되는 혜택으로 다양한 라이프스타일 영역(여행∙쇼핑∙고메∙뷰티∙커피)에서 최대 25만원권까지 선택하여 사용하실 수
                    있는 서비스 입니다.<br/>
                    * 본 서비스는 SC제일은행-the Red Stripe 전용 혜택으로 기존 상품(the Red Edition3, the Red Edition2 등) 바우처로는 사용 불가합니다.
                </li>
            </ul>
        </div>
    )
}

function GreenVoucherIntroducer() {
    return (
        <div className="lpu-sub-item">
            <div className="lpu-sub-title a-mb20 a-mt10">the Green Voucher 서비스란?</div>
            <ul className="list-dot voucher-desc-list">
                <li>현대카드 the Green 본인 회원에게만 제공되는 혜택으로, 보유한 M포인트를 금액권(10만원권)으로 교환하여
                    <br/>지정된 사용처*에서 사용하실 수 있는 서비스입니다.
                    <br/>(*PRIVIA 여행, 롯데면세점, 국내 특급 호텔)
                </li>
            </ul>
        </div>
    )
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

function selectAttention(voucherType) {
    switch (voucherType) {
        case VOUCHER_TYPE.RED5:
            return <RedVoucherAttention/>
        case VOUCHER_TYPE.RED4:
            return <RedVoucher4Attention/>
        case VOUCHER_TYPE.GREEN:
            return <GreenVoucherAttention/>
        case VOUCHER_TYPE.RED_STRIP:
            return <RedVoucherStripAttention/>
        case VOUCHER_TYPE.SUMMIT:
            return <SummitVoucherAttention/>
        default:
            return <></>
    }
}

function SummitVoucherAttention() {
    return (
        <ul className="list-dot voucher-desc-list">
            <li>항공권 가격 비교 사이트(스카이스캐너/네이버)를 통해 접속한 결제 건의 경우 바우처 사용 불가</li>
            <li>바우처로 항공권 결제 시, 순수 항공요금만 적용(유류할증료, 제세공과금, 발권대행료 미포함)</li>
            <li>다른 PRIVIA 이용권 및 바우처와 중복 사용 불가</li>
            <li>바우처로 결제 시 부분 취소 불가하며, 부분 취소가 필요한 경우 전체 결제금액 취소 후 재결제 필요</li>
            <li>바우처로 호텔 요금 결제 시, 결제 요금에서 다음 순서로 할인 적용됩니다. (바우처 > 현대카드 할인 > M포인트)</li>
            <li>국제선 항공권 결제 시 바우처 권면금액은 M포인트 적립 불가(바우처 권면금액 제외한 초과금액에 대해 적립 가능)</li>
            <li>국내선 저가 항공의 경우 바우처 사용 불가</li>
            <li>현대카드 Summit 바우처는 모바일 바우처로 제공되며, 본인 카드 결제 시에만 사용 가능<br/>
                (본인 회원 탈회, 유효기간 만료, 폐기, 거래정지, 신용불량, 가족카드 등의 경우 사용 불가)
            </li>

            <li>바우처 사용 조건 : 현대카드 Summit(본인+가족카드)의 일시불 및 할부 이용금액 기준<br/>
                발급 초년도 : 발급 즉시 제공되며, 누적 이용 금액 100만원 이상 시 실적 달성일 기준 2영업일 후 바우처 사용<br/>
                2차년도 이후 : 전년도(카드 발급 확정월 포함 12개월) 이용 금액 1,200만원 이상 시 제공되며, 전년도 이용 금액 산정 시작월로부터 13개월 차에 바우처 제공
            </li>
            <li>바우처는 30만원 이상 결제 시 사용 가능하며, 초과금액은 현대카드 Summit 본인 카드로 결제 필수</li>
            <li>유효 기간은 카드 발급 확정월 포함 12개월이며 유효 기간 경과 시 사용 불가</li>

            <li>바우처는 승인 건당 1매에 한해 사용 가능</li>
            <li>모바일 바우처 확인 경로<br/>
                현대카드 모바일웹 > 로그인 > 나만의 혜택 > 보유 바우처<br/>
                현대카드 앱 > 로그인 > 메뉴바 > 나만의 혜택 > 보유 바우처<br/>
            </li>
            <li>자세한 내용은 현대카드 홈페이지(<a href="https://www.hyundaicard.com" className="deco">www.hyundaicard.com</a>)참고</li>
        </ul>
    )
}

function RedVoucherAttention() {
    const red5VoucherLink = 'https://www.hyundaicard.com/cpb/pv/CPBPV0102_03.hc'
    return (
        <ul className="list-dot voucher-desc-list">
            <li>항공권 가격 비교 사이트(스카이스캐너/네이버/카약)를 통해 접속한 결제 건의 경우 바우처 사용 불가</li>
            <li>바우처로 항공권 결제 시, 순수 항공요금만 적용(유류할증료, 제세공과금, 발권대행료 미포함)</li>
            <li>다른 PRIVIA 이용권 및 바우처와 중복 사용 불가</li>
            <li>바우처로 결제 시 부분 취소 불가하며, 부분 취소가 필요한 경우 전체 결제금액 취소 후 재결제 필요</li>
            <li>국제선 항공권 결제 시 바우처 권면금액은 M포인트 적립 불가(바우처 권면금액 제외한 초과금액에 대해 적립 가능)</li>
            <li>국내선 저가 항공의 경우 바우처 사용 불가</li>
            <li>the Red Edition5 바우처는 모바일 바우처로 제공되며, 본인 카드 결제 시에만 사용 가능(본인 회원 탈회, 유효기간 만료, 폐기, 거래정지, 신용불량, 가족카드 등의 경우 사용
                불가)
            </li>
            <li>바우처 사용 조건 : the Red Edition5(본인+가족카드)의 일시불 및 할부 이용금액 기준<br/>
                <span className="sc">- 발급 초년도 : 바우처 사용 2일 전까지 250만원 이상 이용 시</span><br/>
                <span className="sc">- 2차년도 이후 : 바우처는 전년도(카드 발급 확정월로부터 1년) 1,000만원 이상 이용 시 제공되며, 바우처 제공일 이후 본인 카드 유효기간 내 정상 이용 시 사용 가능</span>
            </li>
            <li>바우처는 권면금액(합산된 총 바우처 금액) 이상 결제 시 사용 가능하며, 초과금액은 the Red Edition5 본인 카드로 결제 필수</li>
            <li>바우처의 유효기간은 1년이며 유효기간 경과 시 사용 불가</li>
            <li>the Red Edition2, the Red Edition3 트래블 바우처 사용 시 the Red 데스크(02-3015-9500)로 문의</li>
            <li>
                바우처 보유 현황은 현대카드 홈페이지 &gt; 로그인 &gt; 나만의 혜택 &gt; Premium 혜택 안내 &gt; the Red &gt; the Red
                바우처에서 확인하시기 바랍니다.
                <br/><span className="btn-base btn-st-inline btn-bg-gr a-mt10">
                <Link to={red5VoucherLink}>보유 바우처 현황 바로 가기 &gt;</Link></span>
            </li>
        </ul>
    )
}

function RedVoucher4Attention() {
    return (
        <div className="lpu-sub-item">
            <div className="lpu-sub-title a-mb20 a-mt10">유의사항</div>
            <ul className="list-dot voucher-desc-list">
                <li><strong>사용한 바우처는 부분 취소가 불가하며 상품가 전액 취소 후 재 결제 진행</strong></li>
                <li>바우처 사용은 총 결제금액이 바우처 권면금액(합산된 총 바우처 금액) 이상 시에 한하며, 추가금액은 the Red으로만 결제 가능</li>
                <li>바우처는 본인카드가 유효한 경우에만 사용 가능(본인 회원 탈회, 유효기간 만료, 폐기, 거래정지(연체), 신용불량 등의 경우 사용 불가)</li>
                <li>바우처는 타인에게 양도 및 판매 불가</li>
                <li>가격 비교 사이트(스카이스캐너/네이버 등)를 통해 접속한 결제 건의 경우 바우처 사용 불가</li>
                <li>순수항공요금, 객실요금, 기타 여행비 이외의 비용(유류할증료, 제세공과금, 발권대행료)은 회원 부담<br/><span
                    class="sc">※ 항공권은 국제선에 한해 적용 가능</span></li>
                <li>the Green Voucher, PRIVIA 이용권과 동시 사용 불가</li>
                <li>
                    바우처 보유 현황은 현대카드 홈페이지 &gt; 로그인 &gt; 나만의 혜택 &gt; Premium 혜택 안내 &gt; the Red &gt; the Red 바우처에서 확인하시기
                    바랍니다.
                    <br/><span className="btn-base btn-st-inline btn-bg-gr a-mt10"><a
                    href="#">보유 바우처 현황 바로 가기 &gt;</a></span>
                </li>
            </ul>
        </div>
    )
}

function RedVoucherStripAttention() {
    return (
        <div className="lpu-sub-item">
            <ul className="list-dot voucher-desc-list">
                <li><strong>사용한 바우처는 부분 취소가 불가하며 상품가 전액 취소 후 재 결제 진행</strong></li>
                <li>바우처 사용은 총 결제금액이 바우처 권면금액(합산된 총 바우처 금액) 이상 시에 한하며, 추가금액은 the Red으로만 결제 가능</li>
                <li>바우처는 본인카드가 유효한 경우에만 사용 가능(본인 회원 탈회, 유효기간 만료, 폐기, 거래정지(연체), 신용불량 등의 경우 사용 불가)</li>
                <li>바우처는 타인에게 양도 및 판매 불가</li>
                <li>가격 비교 사이트(스카이스캐너/네이버 등)를 통해 접속한 결제 건의 경우 바우처 사용 불가</li>
                <li>순수항공요금, 객실요금, 기타 여행비 이외의 비용(유류할증료, 제세공과금, 발권대행료)은 회원 부담<br/><span
                    className="sc">※ 항공권은 국제선에 한해 적용 가능</span></li>
                <li>the Green Voucher, PRIVIA 이용권과 동시 사용 불가</li>
                <li>
                    바우처 보유 현황은 현대카드 홈페이지 &gt; 로그인 &gt; 나만의 혜택 &gt; Premium 혜택 안내 &gt; the Red &gt; the Red 바우처에서 확인하시기
                    바랍니다.
                    <br/><span className="btn-base btn-st-inline btn-bg-gr a-mt10"><a href="#">보유 바우처 현황 바로 가기 &gt;</a></span>
                </li>
            </ul>
        </div>
    )
}
function GreenVoucherAttention() {
    const greenVoucherLink = 'https://www.hyundaicard.com/cpb/pv/CPBPV0102_04.hc'
    return (
        <ul className="list-dot voucher-desc-list">
            <li>바우처 사용은 총 결제금액이 바우처 권면금액(합산된 총 바우처 금액) 이상 시에 한하며, 추가금액은 현대카드 the Green으로만 <br/>결제 가능
            </li>
            <li>바우처는 본인카드가 유효한 경우에만 사용 가능(본인 회원 탈퇴, 유효기간 만료, 폐기, 거래정지(연체), 신용불량 등의 경우 사용 불가)</li>
            <li>바우처는 타인에게 양도 및 판매 불가</li>
            <li>가격 비교 사이트(스카이스캐너/네이버 등)를 통해 접속한 결제 건의 경우 바우처 사용 불가</li>
            <li>순수 항공권료, 객실요금, 기타 여행비 이외의 비용(유류할증료, 제세공과금, 발권대행료)은 회원 부담. <br/>※ 항공권은 국제선에 한해 적용 가능</li>
            <li>PRIVIA 이용권, PRIVIA 쿠폰과 동시 사용 불가</li>
            <li>
                the Green Voucher 교환은 현대카드 홈페이지 &gt; 로그인 &gt; 나만의 혜택 &gt; Premium 혜택 안내 &gt; the
                Green &gt; the Green
                바우처에서
                <br/>확인하시기 바랍니다.
                <br/><span className="btn-base btn-st-inline btn-bg-gr a-mt10">
              <Link to={greenVoucherLink}>the Green Voucher 이용안내 &gt;</Link></span>
            </li>
        </ul>
    )
}

