import {useParams} from "react-router-dom";
import useLoadReservation from "../../loader/useLoadReservation";
import {getCookie} from "../../../../common/util/Cookie";

export default function useResultReservation() {
    const params = useParams();
    const memberNumber = getCookie("memberNo");
    const reservationNumber = params.reservationNumber;
    const loadReservationCtx = useLoadReservation(reservationNumber)
    const serverUrl = process.env.REACT_APP_SERVER_URL;
    const priviaUrl = process.env.REACT_APP_PRIVIA_BASE_URL;

    return {
        reservationNumber,
        loadReservationCtx,
        serverUrl,
        priviaUrl,
        memberNumber
    }
}