import React, { useEffect, useState } from "react";
import axios from "axios";
import commonAPI from "./commonAPI";
import { Link } from "react-router-dom";


const tabElement = {
    '상품': 'FQ1501',
    '예약/결제': 'FQ1502',
    '변경/취소': 'FQ1503',
    '할인': 'FQ1504',
    '기타': 'FQ1505',
}

const Faq = () => {
    const serverUrl = process.env.REACT_APP_SERVER_URL;
    const priviaUrl = process.env.REACT_APP_PRIVIA_BASE_URL;
    const [faq, setFaq] = useState("")
    const [tabMenuState, setTabMenuState] = useState("")
    const [notice, setNotice] = useState("")

    const resData = async () => {
        let res = await axios.all([
            axios.get(`${serverUrl}/api/v1/main/faq?catCd=${tabMenuState}`, { headers: { 'Authorization': commonAPI.AUTHROIZATION, 'Channel-Id': 'PRIVIA', 'Content-Type': 'application/json' } }),
            axios.get(`${serverUrl}/api/v1/main/notice`, { headers: { 'Authorization': commonAPI.AUTHROIZATION, 'Channel-Id': 'PRIVIA', 'Content-Type': 'application/json' } })
        ]).then(axios.spread((res1, res2) => { return ({ faq: res1.data, notice: res2.data }) })).catch(error => console.log(error));
        if (res) {
            setFaq(res.faq);
            setNotice(res.notice);
        }
    }
    
    const faqElement = faq && faq.map((value, index) => {
        if (tabMenuState === '') {
            return (
                <ul key={`faq${index}`} className={"list ui-tab-cont tab-on"} id={"faqList1"}>
                    <li><a style={{ cursor: "pointer" }} onClick={() => { goFaqView(value.seq, value.catCd, value.svcDivn) }}>{value && value.title}</a></li>
                </ul>
            )
        } else {
            return (
                <ul key={`faq${index}`} className={value && value.catCd === tabMenuState ? "list ui-tab-cont tab-on" : "list ui-tab-cont"} id="faqList1">
                    <li><a style={{ cursor: "pointer" }} onClick={() => { goFaqView(value.seq, value.catCd, value.svcDivn) }}>{value && value.title}</a></li>
                </ul>
            )
        }
    })

    const goFaqView = (seq, catCd, svcDivn) => {
        window.location.href = `${priviaUrl}/noticefaq/faqList?seq=${seq}&searchSvcDivn=${svcDivn}&searchCatCd=${catCd}&procType=main`;
    }

    const noticeElement = notice && notice.map((value, index) => {
        return (
            <li key={`notice${index}`}><a style={{ cursor: "pointer" }} onClick={() => { goNoticeView(value.seq, value.catCd) }}>{value.title}</a></li>
        )
    })

    const goNoticeView = (seq, catCd) => {
        window.location.href = priviaUrl + "/noticefaq/noticeList?seq=" + seq + "&searchSvcDivn=NOT010&searchCatCd=" + catCd + "&procType=main";
    }


    const faqTabMenuEventBind = (e, tabMenu) => {
        setTabMenuState(tabMenu)
    }


    useEffect(() => {
        resData()
    }, [tabMenuState])


    return (

        // svcDivn: FQ1500  (FQ600은 (구)해외패키지입니다)
        // catCd
        // 상품: FQ1501
        // 예약/결제: FQ1502
        // 변경/취소: FQ1503
        // 할인: FQ1504
        // 기타: FQ1505
        // 전체: catCd = ''
        <div className="s-information-cont sic-faq ly-inner">
            <div className="sic-left">
                <div className="sic-board">
                    <h2 className="pkg-tit-sec">
                        <Link to={process.env.REACT_APP_PRIVIA_BASE_URL + "/noticefaq/faqList?mode=&seq=&page=0&pageSize=10&searchSvcDivn=FQ1500&searchCatCd=&searchText="}><span>FAQ</span></Link>
                    </h2>
                    <div className="o-tab-menu">
                        <ul className="ui-tab-menu">
                            <li className={tabMenuState === `` ? "on" : ""} style={{ cursor: "pointer" }} onClick={(e) => { faqTabMenuEventBind(e, ``) }}><span className="btn-base bbr-st3"><a>전체</a></span></li>
                            <li className={tabMenuState === `FQ1501` ? "on" : ""} style={{ cursor: "pointer" }} onClick={(e) => { faqTabMenuEventBind(e, `FQ1501`) }}><span className="btn-base bbr-st3"><a>상품</a></span></li>
                            <li className={tabMenuState === `FQ1502` ? "on" : ""} style={{ cursor: "pointer" }} onClick={(e) => { faqTabMenuEventBind(e, `FQ1502`) }}><span className="btn-base bbr-st3"><a>예약/결제</a></span></li>
                            <li className={tabMenuState === `FQ1503` ? "on" : ""} style={{ cursor: "pointer" }} onClick={(e) => { faqTabMenuEventBind(e, `FQ1503`) }}><span className="btn-base bbr-st3"><a>변경/취소</a></span></li>
                            <li className={tabMenuState === `FQ1504` ? "on" : ""} style={{ cursor: "pointer" }} onClick={(e) => { faqTabMenuEventBind(e, `FQ1504`) }}><span className="btn-base bbr-st3"><a>할인</a></span></li>
                            <li className={tabMenuState === `FQ1505` ? "on" : ""} style={{ cursor: "pointer" }} onClick={(e) => { faqTabMenuEventBind(e, `FQ1505`) }}><span className="btn-base bbr-st3"><a>기타</a></span></li>
                        </ul>
                        {faqElement}
                    </div>
                </div>
            </div>
            <div className="sic-right">
                <div className="sic-board">
                    <h2 className="pkg-tit-sec">
                        <Link to={process.env.REACT_APP_PRIVIA_BASE_URL + "/noticefaq/noticeList?searchSvcDivn=NOT010"}><span>공지사항</span></Link>
                    </h2>
                    <ul className="list">
                        {noticeElement}
                    </ul>
                </div>
                <div className="sic-aside-services">
                    <Link to={process.env.REACT_APP_PRIVIA_BASE_URL + "/customer/main/counselMain"}>PRIVIA 해외패키지<br />상담 서비스</Link>
                </div>
            </div>
        </div>
    )
}


export default Faq