import React from "react";
import styles from "../../../../../../reservation/page/Reservation.module.css";

export default function UsageTerms({
                                           open,
                                           terms,
                                           handleOpenContents,
                                           termsCtx,
                                           content,
                                    }) {
    return (
        <>
            {/*PRIVIA 이용약관(필수) 비회원 노출*/}
            <div>
                <div className="pkg-tit-term-cont">
                    <h3 className="title between-box fc closed">
                        <a className={`o-acdi-click ${open ? 'o-ac-on' : ''}`}
                           onClick={handleOpenContents} id="usageTermsToggle">
                            PRIVIA 이용약관(필수)</a>
                        <div className="agr-area">
                                <span className="check-group">
                                    <input type="checkbox" className="hidden_check" id="usageTerms"
                                           name="usageTerms" checked={terms.usageTerms} onChange={termsCtx.onCheck}/>
                                    <label htmlFor="usageTerms" className="label-check">동의</label>
                                </span>
                        </div>
                    </h3>
                </div>
                <div className={`term-cont-box o-customscrollbar ${styles.term} ${styles.hidden} ${open ? styles.open : ''}`} id="tm-3" style={{display: "block"}}>
                    <div className="t-content" id="agmtAgree" dangerouslySetInnerHTML={{__html:content}} />
                </div>
            </div>
        </>
    );
}