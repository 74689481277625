import {formatNumber} from "../../../../../utils/formatNumber";
import React, {useEffect, useState} from "react";
import SaleProductEntity from "../../../../domain/model/product/SaleProductEntity";


export default function SaleProductPriceSpan({saleProduct, adultCount, childCount, infantCount}) {
    const [discountAmount, setDiscountAmount] = useState(0);
    useEffect(() => {
        if (saleProduct) {
            const model = new SaleProductEntity(saleProduct);
            const {discountAmount} = model.getDiscountBySaleProduct({
                saleProduct, adultCount, childCount, infantCount
            })
            setDiscountAmount(discountAmount);
        }
    }, [saleProduct, adultCount, childCount, infantCount]);

    function isDiscountSunDiscount() {
        const model = new SaleProductEntity(saleProduct);
        return model.isDiscountRank()
    }

    return (
        <>
            <div className="price_tt">
                {isDiscountSunDiscount() && <p>현대카드 <span className="c-point5">3%</span>할인</p>}
                <p><span className="c-point1">10%</span> M포인트 사용</p>
            </div>
            <div className="pd-total-amt">
                <span className="price"><em>{formatNumber(discountAmount)}</em>원</span>
            </div>
        </>

    )
}