export default function LoadingHoc({isEmpty, isLoading, children}) {
    if (isEmpty) {
        return children[1]
    }
    if (isLoading) {
        return children[0]
    }
    return children[2]
}


