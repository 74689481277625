import Modal from "@mui/material/Modal";
import React, { useState } from "react";
import Select from "react-select";
import Loading from "../../../../../common/Loading";
import useFormWithValidation from "../../../../../common/util/useFromWithValidation";
import { countryOptionsList } from "../../../../../common/util/CountryOptionsList";
import { phoneOptionList } from "../../../../../common/util/phonOptionList";
import { autoHyphen } from "../../../../../common/util/Event";

export default function TravelerAddPopup({ isOpenTravelerAdd, handleClose, addTravelerInfoData }) {
    const { formValues, errors, setFormData, handleInputChange, validateForm, setFormValues } = useFormWithValidation({}, 'travelerAddDiv');
    const [disabled, setDisabled] = useState(false);
    const [countryOption, setCountryOption] = useState(countryOptionsList[0]);
    const [travelCountryOption, setTravelCountryOption] = useState(countryOptionsList[0]);
    const [gender, setGender] = useState('M');
    const [phoneOption, setPhoneOption] = useState(phoneOptionList[0]);


    const handleChangeCountryCode = (option) => {
        setCountryOption(option);
    };

    const handleChangeTravelCountryCode = (option) => {
        setTravelCountryOption(option);
    };

    const validateTravelers = () => {
        return validateForm();
    }

    const phoneOptionChange = (option) => {
        setPhoneOption(option);
    }


    async function saveAddTraveler() {
        let travelerReq = {
            korName: formValues.newTraveler_koreanName,
            engLastName: formValues.newTraveler_engSurname.toUpperCase(),
            engFirstName: formValues.newTraveler_engGivenName.toUpperCase(),
            sex: gender,
            birth: formValues.newTraveler_birth,
            nationality: countryOption.value,
            stayNationality: travelCountryOption.value,
            mobileNo: formValues.newTraveler_phoneNumber.replaceAll("-", ""),
        };
        setDisabled(true);
        addTravelerInfoData(travelerReq).then(result => {
            if (result) {
                alert("여행자 정보가 저장되었습니다.");
                handleClose();
                return;
            }
        }).catch(error => {
            console.log(error);
            alert("여행자 정보 저장에 실패하였습니다.");
        }).finally(() => {
            setDisabled(false);
        });
    }

    const handleClickSave = () => {
        if (validateTravelers()) {
            saveAddTraveler();
        }
    };
    const handleGenderChange = (e) => {
        setGender(e.target.value);
    };

    return (
        <>
            {disabled && disabled ? (<Loading />) : ''}
            <Modal open={isOpenTravelerAdd} onClose={handleClose}>
                <div id={'travelerAddDiv'} className="popup-wrap" style={{ width: 600 }}>
                    {/* header */}
                    <div className="heading">
                        <h2>여행자 추가</h2>
                        <a href="#" className="btn_close" onClick={handleClose}>닫기</a>
                    </div>
                    {/* //header */}
                    {/* content */}
                    <div className="content">
                        <div className="inner">
                            {/* 상세 */}
                            <div className="mytraveler-detail">
                                <section className="lpu-item-sec">
                                    <div className="lpu-sub-item">
                                        <table summary="여행자 추가" className="tbl-style basic">
                                            <caption>한국이름, 영문이름, 성별, 생년월일, 국적, 거주국가, 연락처가 포함된 여행자 추가에 대한 테이블</caption>
                                            <colgroup>
                                                <col width={140} />
                                                <col width="*" />
                                            </colgroup>
                                            <tbody>
                                                <tr>
                                                    <th scope="row" className="a-left">
                                                        <div className="necessary tit-input">한국이름</div>
                                                    </th>
                                                    <td>
                                                        <div className="input-area">
                                                            <input type="text" id={'newTraveler_koreanName'}
                                                                name={'koreanName'}
                                                                className={`input-base type01 wfull koreanName ${errors['newTraveler_koreanName'] ? "err" : ''}`}
                                                                value={formValues['newTraveler_koreanName']}
                                                                onChange={handleInputChange}
                                                                placeholder="예) 홍길동" />
                                                        </div>
                                                        {/*{errors['newTraveler_koreanName'] &&
                              <p className="err-feedback">한글성명을 입력해 주세요.(ex.홍길동)</p>}*/}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" className="a-left">
                                                        <div className="necessary tit-input">영문이름</div>
                                                    </th>
                                                    <td>
                                                        <div className="input-area">
                                                            <input type="text"
                                                                id={'newTraveler_engSurname'}
                                                                name={'engSurname'}
                                                                className={`input-base type01 w140 englishOnly ${errors['newTraveler_engSurname'] ? "err" : ''}`}
                                                                value={formValues['newTraveler_engSurname'] ? formValues['newTraveler_engSurname'].toUpperCase() : ""}
                                                                onChange={handleInputChange}
                                                                placeholder="예) HONG" />
                                                        </div>
                                                        <div className="input-area">
                                                            <input type="text"
                                                                id={'newTraveler_engGivenName'}
                                                                name={'engGivenName'}
                                                                className={`input-base type01 w140 englishOnly ${errors['newTraveler_engGivenName'] ? "err" : ''}`}
                                                                value={formValues['newTraveler_engGivenName'] ? formValues['newTraveler_engGivenName'].toUpperCase() : ""}
                                                                onChange={handleInputChange}
                                                                placeholder="예) GILDONG" />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" className="a-left">
                                                        <div className="necessary tit-input">성별</div>
                                                    </th>
                                                    <td>
                                                        <div className="input-area">
                                                            <span className="radio-wrap">
                                                                <input type="radio" id="gender1" name="gender" value={'M'} onChange={handleGenderChange} defaultChecked={true} />
                                                                <label htmlFor="gender1" className="label-base">남자(Male)</label>
                                                            </span>
                                                            <span className="radio-wrap">
                                                                <input type="radio" id="gender2" name="gender" value={'F'} onChange={handleGenderChange} />
                                                                <label htmlFor="gender2" className="label-base">여자(Female)</label>
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" className="a-left">
                                                        <div className="necessary tit-input">생년월일</div>
                                                    </th>
                                                    <td>
                                                        <div className="input-area">
                                                            <input type="text"
                                                                id={'newTraveler_birth'}
                                                                name={'birth'}
                                                                className={`input-base type01 w140 birth ${errors['newTraveler_birth'] ? "err" : ''}`}
                                                                value={formValues['newTraveler_birth']}
                                                                onChange={handleInputChange}
                                                                placeholder="예) 19750101" /></div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" className="a-left">
                                                        <div className="tit-input">국적</div>
                                                    </th>
                                                    <td>
                                                        <div className="pay-card-select" style={{ width: "200px" }}>
                                                            <Select options={countryOptionsList} value={countryOption}
                                                                onChange={handleChangeCountryCode}
                                                                styles={{
                                                                    control: (baseStyles, state) => ({
                                                                        ...baseStyles,
                                                                        width: "140px",
                                                                        borderRadius: 3,
                                                                        boxShadow: "none",
                                                                        fontSize: 14,
                                                                        fontWeight: 400
                                                                    })
                                                                }}
                                                                components={{
                                                                    IndicatorSeparator: () => null
                                                                }}
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" className="a-left">
                                                        <div className="tit-input">거주국가</div>
                                                    </th>
                                                    <td>
                                                        <div className="pay-card-select" style={{ width: "200px" }}>
                                                            <Select options={countryOptionsList} value={travelCountryOption}
                                                                onChange={handleChangeTravelCountryCode}
                                                                styles={{
                                                                    control: (baseStyles, state) => ({
                                                                        ...baseStyles,
                                                                        width: "140px",
                                                                        borderRadius: 3,
                                                                        boxShadow: "none",
                                                                        fontSize: 14,
                                                                        fontWeight: 400
                                                                    })
                                                                }}
                                                                components={{
                                                                    IndicatorSeparator: () => null
                                                                }}
                                                            />

                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row" className="a-left">
                                                        <div className="tit-input">연락처</div>
                                                    </th>
                                                    <td>
                                                        <div className="input-wrap" style={{ width: `100px`, fontSize: `14px`, display: `inline-flex` }}>
                                                            <Select options={phoneOptionList} value={phoneOption}
                                                                onChange={phoneOptionChange}
                                                                styles={{
                                                                    control: (baseStyles, state) => ({
                                                                        ...baseStyles,
                                                                        width: "100px",
                                                                        borderRadius: 3,
                                                                        boxShadow: "none",
                                                                        fontSize: 14,
                                                                        fontWeight: 400
                                                                    })
                                                                }}
                                                                components={{
                                                                    IndicatorSeparator: () => null
                                                                }}
                                                            />
                                                            {/*<select className="select-base sel-st2 w100">
                              <option value={1} checked>휴대폰</option>
                              <option value={2}>유선</option>
                            </select>
                            <select className="select-base" style={{width: 100, position: 'absolute', left: '-999em'}}
                                    data-fakeform-index={2}>
                              <option value={1} checked>휴대폰</option>
                              <option value={2}>유선</option>
                            </select>*/}
                                                            <div className="input-area" style={{ marginLeft: `10px` }}>
                                                                <input style={{ height: `38px` }}
                                                                    type="text"
                                                                    id={'newTraveler_phoneNumber'}
                                                                    name={'phoneNumber'}
                                                                    className={`input-base type01 w180 phoneNumber ${errors['newTraveler_phoneNumber'] ? "err" : ''}`}
                                                                    value={formValues['newTraveler_phoneNumber']}
                                                                    onChange={handleInputChange}
                                                                    onKeyUp={autoHyphen}
                                                                    placeholder="예) 010-1234-5678" />
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="btn-wrap a-right a-pt5">
                                        <span className="btn-base btn-st-full btn-bg-bl w130">
                                            <button onClick={handleClickSave}>등록</button>
                                        </span>
                                    </div>
                                </section>
                            </div>
                            {/* //상세 */}
                        </div>
                    </div>
                    {/* //content */}
                </div>

            </Modal>
        </>
    )
}