import {useState} from "react";
import {EmailRegx, KoreanCellPhoneRegx, KoreanNameRegx} from "../../../../infra/const/RegularExpression";
import useEvent from "../../../../infra/event/useEvent";
import {EVENT_NAMES} from "../event/events";
import {useRecoilState} from "recoil";
import {
    reservationPersonEmailState,
    reservationPersonNameState,
    reservationPersonPhoneState
} from "../../../state/user/ReservationPersonState";

/*
* 비회원일 때 더 내용이 변경될 듯
* 일단 회원일 때만 구현
* */

const PHONE_ERROR_REQUIRE = "휴대폰 번호를 입력해 주세요.(ex 01012345678)"
const EMAIL_ERROR_REQUIRE = "이메일을 입력해 주세요."

const PHONE_ERROR_INVALID = "유효하지 않은 휴대폰 번호입니다."
const EMAIL_ERROR_INVALID = "이메일 형식에 맞게 입력해 주세요.(ex hong@naver.com)"

const NAME_ERROR_REQUIRE = "이름을 입력해 주세요."
const NAME_ERROR_INVALID = "유효하지 않은 이름입니다."

export const ERROR_TYPE = Object.freeze({
    REQUIRE: "REQUIRE",
    INVALID: "INVALID"
})

export default function useReservationPerson(eventManager) {
    const [user, setUser] = useState(null)
    const [memberId, setMemberId] = useState(null)
    const [phone, setPhone] = useRecoilState(reservationPersonPhoneState)
    const [email, setEmail] = useRecoilState(reservationPersonEmailState)
    const [name, setName] = useRecoilState(reservationPersonNameState)

    const [nameError, setNameError] = useState(null)
    const [emailError, setEmailError] = useState(null)
    const [phoneError, setPhoneError] = useState(null)

    useEvent(eventManager, EVENT_NAMES.LOAD_USER, (evt) => {
        const user = evt.getPayload()
        setUser(user)
        setPhone(user.getPhoneNumber())
        setEmail(user.getEmail())
        setName(user.getUserName())
        setMemberId(user.getMemberId())

        // checkEmail(user.getEmail())
        // checkPhone(user.getPhoneNumber())
        // checkName(user.getUserName())
    })

    function checkEmail(email) {
        let emailError = null
        if (email === '' || email === null || email === undefined) {
            emailError = {
                type: ERROR_TYPE.REQUIRE,
                message: EMAIL_ERROR_REQUIRE
            }
            setEmailError(emailError)
            return false;
        }
        const valid = EmailRegx.test(email)
        if (!valid) {
            emailError = {
                type: ERROR_TYPE.INVALID,
                message: EMAIL_ERROR_INVALID
            }
        }
        setEmailError(emailError)
        return emailError === null;
    }

    function checkPhone(phone) {
        let phoneError = null
        if (phone === '' || phone === null || phone === undefined) {
            phoneError = {
                type: ERROR_TYPE.REQUIRE,
                message: PHONE_ERROR_REQUIRE
            }
            setPhoneError(phoneError)
            return false;
        }
        const valid = KoreanCellPhoneRegx.test(phone)
        if (!valid) {
            phoneError = {
                type: ERROR_TYPE.INVALID,
                message: PHONE_ERROR_INVALID
            }
        }
        setPhoneError(phoneError)
        return phoneError === null;
    }

    function checkName(name) {
        let nameError = null
        if (name === '' || name === null || name === undefined) {
            nameError = {
                type: ERROR_TYPE.REQUIRE,
                message: "이름을 입력해 주세요."
            }
            setNameError(nameError)
            return false;
        }
        const valid = KoreanNameRegx.test(name)
        if (!valid) {
            nameError = {
                type: ERROR_TYPE.INVALID,
                message: NAME_ERROR_INVALID
            }
        }
        setNameError(nameError)
        return nameError === null;
    }

    function onChange(target, value) {
        if (target === 'name') {
            setName(value)
            checkName(value)
        }

        if (target === 'phone') {
            setPhone(value)
            checkPhone(value)
        }

        if (target === 'email') {
            setEmail(value)
            checkEmail(value)
        }
    }


    function getSubmitData() {
        let reservationPersonInformation = {
            memberName: name,
            callNumber: phone,
            email: email,
        }

        if (!user) return {
            reservationPersonInformation
        }

        reservationPersonInformation['memberNumber'] = user.getMemberNumber()
        return {
            reservationPersonInformation,
            memberId: user.getMemberId(),
            memberNumber: user.getMemberNumber(),
            hccGrade: user.getHccGrade(),
            hccNumber: user.getHccNumber(),
        }
    }

    function validate() {
        if (!checkName(name)) {
            return {
                valid: false,
                message: "예약자 이름 정보를 확인 해주세요."
            }
        }
        if (!checkEmail(email)) {
            return {
                valid: false,
                message: "예약자 이메일 정보를 확인 해주세요."
            }
        }
        if (!checkPhone(phone)) {
            return {
                valid: false,
                message: "예약자 휴대폰 정보를 확인 해주세요."
            }
        }
        return {
            valid: true,
            message: ""
        }
    }
    function getInicisPayData() {
        return {
            buyerName: name,
            buyerEmail: email,
            buyerTel: phone,
        }
    }


    return {
        enable: true,
        name,
        phone,
        email,
        memberId,
        nameError,
        phoneError,
        emailError,
        onChange,
        getSubmitData,
        getInicisPayData,
        validate,
    }
}