import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import axios from "axios";
import {setMemberNm, setUserInfo} from "../../redux/module/common/HeaderReducer";
import {getCookie} from "../util/Cookie";
import {getAxiosHeaders} from "../util/Axios";

const useFetchUserInformation = () => {
    const dispatch = useDispatch();
    const memberNo = getCookie("memberNo");
    const serverUrl = process.env.REACT_APP_SERVER_URL;

    useEffect(() => {
        const getLoginUserInfo = async () => {

            if (memberNo != null && !memberNo.isEmpty) {
                axios.get(serverUrl + '/api/v1/memberInformationByCryptedMemberNm/' + memberNo,{
                    headers: getAxiosHeaders()
                }).then(function (response) {
                    let {memberName} = response.data;
                    let userInfo =  response.data;
                    dispatch(setMemberNm(memberName));
                    dispatch(setUserInfo(userInfo));
                    // dispatch(setIsLogin(true));
                }).catch(function (error) {
                    console.log(error);
                });
            } else {
                dispatch(setMemberNm(''));
                dispatch(setUserInfo({}));
            }
        };

        getLoginUserInfo().then();
    }, [memberNo]);
};

export default useFetchUserInformation;