import styles from "../../../../../../reservation/page/Reservation.module.css";
import React from "react";

export default function PersonalInfo({
                                         open,
                                         terms,
                                         handleOpenContents,
                                         termsCtx,
                                         content,
                                     }) {

    return (
        <>
            <div className="pkg-tit-term-cont">
                <h3 className="title between-box fc closed">
                    <a className={`o-acdi-click ${open ? 'o-ac-on' : ''}`}
                       onClick={handleOpenContents} id="personalInfoToggle">
                        개인정보 수집ㆍ이용 동의(필수)
                    </a>
                    <div className="agr-area">
                                    <span className="check-group">
                                        <input type="checkbox" className="hidden_check" id="personalInfo"
                                               name="personalInfo" checked={terms.personalInfo} onChange={termsCtx.onCheck}/>
                                        <label htmlFor="personalInfo" className="label-check">동의</label>
                                    </span>
                    </div>
                </h3>
            </div>
            <div
                className={`term-cont-box o-customscrollbar ${styles.term} ${styles.hidden} ${open ? styles.open : ''}`}
                id="tm-5" style={{display: "block"}}>
                <div className="t-content" id="indinfoAgree" dangerouslySetInnerHTML={{__html: content}}/>
            </div>
        </>
    );
}