import {useParams} from 'react-router-dom';
import React, {useEffect, useState} from "react";
import axios from "axios";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {setRepresentativeProduct, setSaleProduct} from "../redux/module/common/ProductReducer";
import ProductInfo from "./component/ProductInfo";
import ProductDetailTab from "./component/ProductDetailTab";
import ProductDetail from "./component/ProductDetail";
import Inquire from "./component/Inquire";
import Loading from "../common/Loading";
import TopSwiper from "./component/TopSwiper";
import ProductInfoIconBox from "./component/ProductInfoIconBox";
import DetailBanner from "./component/DetailBanner";
import {isHeaderStateTrue, setHeaderType} from "../redux/module/common/HeaderReducer";
import FloatingBox from "./component/FloatingBox";
import {getAxiosHeaders} from "../common/util/Axios";
import DetailError from "./DetailError";
import moment from "moment";
/*
*  디테일 페이지
*  리팩토링 대상
* */
const Detail = () => {

    const params = useParams();
    const dispatch = useDispatch();
    const serverUrl = process.env.REACT_APP_SERVER_URL;

    const [errorState, setErrorState] = useState('');
    const [catDivnCd, setCatDivnCd] = useState('');

    let representativeProduct = useSelector((state) => state.productReducer.representativeProduct, shallowEqual);
    let saleProduct = useSelector((state) => state.productReducer.saleProduct, shallowEqual);


    // useEffect(() => {
    //     dispatch(setHeaderType("quick"));
    //     dispatch(isHeaderStateTrue());
    // }, [dispatch]);

    useEffect(() => {
        getRepresentativeInformationByCode(params.representativeCode.toUpperCase()).then(r => console.log(r))
        getSaleDetailInformationBySaleCode(params.representativeCode.toUpperCase(), params.saleCode.toUpperCase()).then(r => console.log(r));
    }, [dispatch, params.representativeCode, params.saleCode, serverUrl]);

    const getRepresentativeInformationByCode = async (representativeCode) => {
        if (!representativeCode.isNull && !representativeCode.isEmpty) {
            axios.get(serverUrl + '/api/v1/product/' + representativeCode + '/representativeProduct', {
                headers: getAxiosHeaders()
            }).catch(function (error) {
                // console.log(error.toJSON());
                setErrorState(error.toJSON().message);
            }).then(function (response) {
                if (response) {
                    let representativeProducts = response.data.representativeProducts;
                    let representativeProduct = representativeProducts.at(0);
                    dispatch(setRepresentativeProduct(representativeProduct));
                    setMetaTags({
                        title: `${representativeProduct && representativeProduct.representProductName}` + " | PRIVIA 해외패키지",
                        description: `타이드스퀘어 화이트라벨 해외패키지`,
                        imageUrl: `${representativeProduct && representativeProduct.representImageUrl}`
                    });
                } else {
                    setErrorState('error');
                }
            });
        }
    };

    const getSaleDetailInformationBySaleCode = async (representativeCode, saleCode) => {
        if (!representativeCode.isNull && !representativeCode.isEmpty && !saleCode.isNull && !saleCode.isEmpty) {
            axios.get(serverUrl + '/api/v1/product/' + representativeCode + '/sale/' + saleCode + '/saleProduct',{
                headers: getAxiosHeaders()
            }).catch(function (error) {
                // console.log(error.toJSON());
                setErrorState(error.toJSON().message);
            }).then(function (response) {
                if (response) {
                    let saleProduct = response.data.saleProducts;
                    dispatch(setSaleProduct(saleProduct.at(0)));
                    const supplierCode = saleProduct.at(0).supplierCode;
                    switch (supplierCode.toUpperCase()) {
                        case 'HANA':
                            setCatDivnCd('TRV0010301');
                            break;
                        case 'MODE':
                            setCatDivnCd('TRV0010307');
                            break;
                        case 'VERYGOOD':
                            setCatDivnCd('TRV0010308');
                            break;
                        case 'KAL':
                            setCatDivnCd('TRV0010310');
                            break;
                    }
                    //자상품 페이지에서 퀵서치에 자상품 내용 들어가지 않도록 삭제
                    // let searchKeywordObj  = {
                    //     key : saleProduct.at(0).saleProductCode,
                    //     value: saleProduct.at(0).saleProductName,
                    //     startDate:moment(saleProduct.at(0).departureDay).format("YYYY-MM-DD (dd)")
                    // }
                    // dispatch(setCommonSearchKeyword(searchKeywordObj));
                } else {
                    setErrorState('error');
                }
            });
        }
    };

    const setMetaTags = ({ title="기본 타이틀", description="기본 설명", imageUrl="기본 사이트 이미지 경로" }) => {
        document
            .querySelector('meta[property="twitter:title"]')
            .setAttribute("content", `${title}`);
        document
            .querySelector('meta[property="twitter:card"]')
            .setAttribute("content", "photo");
        document
            .querySelector('meta[property="twitter:url"]')
            .setAttribute("content", window.location.href);
        document
            .querySelector('meta[property="twitter:image"]')
            .setAttribute("content", imageUrl);
        document
            .querySelector('meta[property="og:type"]')
            .setAttribute("content", "website");
        document
            .querySelector('meta[property="og:title"]')
            .setAttribute("content", `${title}`);
        document
            .querySelector('meta[property="og:description"]')
            .setAttribute("content", description);
        document
            .querySelector('meta[property="og:image"]')
            .setAttribute("content", imageUrl);
        document
            .querySelector('meta[property="og:url"]')
            .setAttribute("content", window.location.href);
        document
            .querySelector('meta[property="og:site-name"]')
            .setAttribute("content", `${title}`);
        document
            .querySelector('meta[property="og:locale"]')
            .setAttribute("content", "ko_KR");
        document
            .querySelector('meta[property="og:image:width"]')
            .setAttribute("content", "1200");
        document
            .querySelector('meta[property="og:image:height"]')
            .setAttribute("content", "630");
    };

    return (errorState ? <DetailError /> : (
            <React.Fragment>
                {
                    representativeProduct && saleProduct ? (
                        <div className={"w-content-sec attach"}>
                            <link type="text/css" rel="stylesheet" href="//static.priviatravel.com/css/front/mtravel/pkgPdCo.css" />
                            {/*<SearchBar*/}
                            {/*    searchWord={saleProduct.saleProductName}*/}
                            {/*    searchStartDate={saleProduct.departureDay}*/}
                            {/*    searchEndDate={null}*/}
                            {/*    />*/}

                            <section className="pkg-product-info ly-inner">
                                <div className="pkg-left-inner">
                                    <TopSwiper
                                        representImageUrl={representativeProduct.representImageUrl}
                                        additionalImageUrls={representativeProduct.additionalImageUrls}
                                    />
                                    <ProductInfo />
                                    <ProductInfoIconBox />
                                    <div className="pd-sched-info-box">
                                        <div className="flight-info-box">
                                            <div className="t-center">
                                                <div className="loc">출발날짜</div>
                                                <div className="date">{saleProduct && moment(saleProduct.departureDay).format("MM/DD(dd)")}</div>
                                                <div className="time">{saleProduct && moment(saleProduct.departureTime).format("HH:mm")}</div>
                                            </div>
                                            <div className="sched">{saleProduct && saleProduct.travelNightCount}박
                                                {saleProduct && saleProduct.travelDayCount}일</div>
                                            <div className="t-center">
                                                <div className="loc">도착날짜</div>
                                                <div className="date">{saleProduct && moment(saleProduct.arrivalDay).format("MM/DD(dd)")}</div>
                                                <div className="time">{saleProduct && moment(saleProduct.arrivalTime).format("HH:mm")}</div>
                                            </div>
                                        </div>
                                        <ul className="pkg-info-list">
                                            <li className="dest">
                                                <span>좌석 : {saleProduct && saleProduct.seatCount}석
                                                    (잔여석 {saleProduct && saleProduct.remainSeatCount}석),
                                                    최소출발 : {saleProduct && saleProduct.minDepartureNumberOfPerson}명<span className="help-tooltip small darker">
                                                        <span className="over">?</span>
                                                        <span className="cont" style={{width:'250px'}}>최소 출발 인원은 성인기준이며, 여행을 진행하기 위해 필요한 최소 구성인원입니다.
                                                            예약인원이 최소 출발인원에 도달하지 않을 경우, 여행약관 9조에 따라 여행사는 여행출발 7일전까지 취소 통보를 하여 계약을 해지할 수 있습니다.
                                                            <br /><br />*특별약관 적용의 경우, 표준약관보다 높은 취소수수료가 부과될 수 있습니다.
                                                        </span>
                                                    </span>
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                    <DetailBanner />
                                </div>
                            </section>

                            <ProductDetailTab />

                            <section className="pkg-product-detail ly-inner" id={(saleProduct && saleProduct.supplierCode === 'VERYGOOD' && 'vgtour') || (saleProduct && saleProduct.supplierCode === 'KAL' && 'hjtour')}>
                                <FloatingBox catDivnCd={catDivnCd}/>

                                {saleProduct && saleProduct.supplierCode === 'HANA' &&
                                    <link type="text/css" rel="stylesheet" href="//static.priviatravel.com/css/front/travel/pkgPdCo.css" />
                                }
                                <ProductDetail id={"pkgConList1"} title={"일정표"} contents={saleProduct.scheduleInfosApiDtoList} />
                                <ProductDetail id={"pkgConList2"} title={"상품상세"} contents={saleProduct.productDetail} />
                                <ProductDetail id={"pkgConList3"} title={"선택관광"} contents={saleProduct.optionalTour} />
                                <ProductDetail id={"pkgConList4"} title={"취소환불규정"} contents={saleProduct.refundPolicy} />
                                <ProductDetail id={"pkgConList5"} title={"여권/비자정보"} contents={saleProduct.majorDocument} />
                                <ProductDetail id={"pkgConList6"} title={"쇼핑정보"} contents={saleProduct.shoppingInfo} />
                                <Inquire catDivnCd={catDivnCd}/>

                            </section>
                        </div>
                    ) : (
                        <Loading />
                    )
                }
            </React.Fragment>
        ));
};

export default Detail;