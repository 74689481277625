import React, {useState} from "react";
import TravelForm from "./TravelForm";

export default function TravelPersonInfo({
                                             travelPersonCtx,
                                             userType,
                                         }) {

    const [toggleTraveler, setToggleTraveler] = useState(true);
    const {
        travelerAdultList,
        travelerChildrenList,
        travelerInfantList,
        travelerSelectList,
        onCheckSyncReservationPerson,
        isReservationPerson,
        departureDay,
        addTravelerInfoData,
        isMember,
    } = travelPersonCtx;

    const handleToggleTraveler = () => {
        setToggleTraveler(!toggleTraveler);
    }

    return (
        <section className="book-detail-area o-acdi-one">
            <div className="pkg-tit-page-cont bb-line">
                <h3 className="title">
                    <a className={toggleTraveler ? 'o-acdi-click between o-ac-on' : 'o-acdi-click between'}
                       style={{cursor: "pointer"}} onClick={handleToggleTraveler}>여행자 정보</a></h3>
            </div>
            <div className={toggleTraveler ? 'book-article' : 'book-article o-ac-on'}
                 id="fc-1"
                 style={toggleTraveler ? {display: ''} : {display: 'none'}}>
                {
                    travelerAdultList && travelerAdultList.map((traveler, index) => (
                        <TravelForm key={`adult_${index}`} index={index} travelPerson={traveler} travelPersonCtx={travelPersonCtx} userType={userType}/>
                    ))
                }
                {
                    travelerChildrenList && travelerChildrenList.map((traveler, index) => (
                        <TravelForm key={`children_${index}`} index={index} travelPerson={traveler} travelPersonCtx={travelPersonCtx} userType={userType}/>
                    ))
                }
                {
                    travelerInfantList && travelerInfantList.map((traveler, index) => (
                        <TravelForm key={`infant_${index}`} index={index} travelPerson={traveler} travelPersonCtx={travelPersonCtx} userType={userType}/>
                    ))
                }
            </div>
        </section>
    )
}

