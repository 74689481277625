import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom';
import Root from "./Root";
import {CookiesProvider} from 'react-cookie';
import {legacy_createStore} from "redux";
import rootReducer from "./redux/reducer/store";
import {Provider} from "react-redux";
import ScrollToTop from "./ScrolltoTop";

const root = ReactDOM.createRoot(document.getElementById('wrap'));

// 리덕스 개발자도구 적용
const detTools = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();
// create store
const store = legacy_createStore(rootReducer , detTools)

/**
 *  엄격 모드로 실행 되어서 렌더링이 두번씩 된다.
 * */

root.render(
  <React.StrictMode>
      <CookiesProvider>
          <Provider store={store}>
              <BrowserRouter>
                  <ScrollToTop />
                  <Root />
              </BrowserRouter>
          </Provider>
      </CookiesProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
