import React from "react";

const ProductLevelPopup = ({ closeModalPopup }) => {

    return (
    <React.Fragment>
        <div className="popup-wrap" style={{width: '510px'}}>
            <div className="heading sm">
                <h2>상품 등급 및 상태</h2>
                <a className="btn_close" style={{cursor:"pointer"}} onClick={closeModalPopup}>닫기</a>
            </div>
            <div className="content">
                <div className="inner">
                    <div className="goodslevel-detail">
                        <section className="lpu-item-sec">
                            <div className="lpu-sub-item">
                                <div className="lpu-sub-title a-mb20">상품 등급</div>
                                <ul className="level-list a-mb20">
                                    <li>
                                        <div className="th-style c-point1">프리미엄</div>
                                        <div className="td-style">고급스러움과 편안함, 보통과 다른 특별한 가치를 담은 고품격 상품</div>
                                    </li>
                                    <li>
                                        <div className="th-style">프라이빗</div>
                                        <div className="td-style">단독 가이드/차량 이용하여 소규모로 떠나는 프라이빗 상품</div>
                                    </li>
                                    <li>
                                        <div className="th-style">가성비甲</div>
                                        <div className="td-style">가성비, 효율성, 편리함을 모두 담은 실속형 상품</div>
                                    </li>
                                </ul>
                            </div>

                            <div className="lpu-sub-item">
                                <div className="lpu-sub-title a-mb20">상품 상태</div>
                                <ul className="level-list">
                                    <li>
                                        <div className="th-style c-point5">출발확정</div>
                                        <div className="td-style">
                                            최소 출발인원 이상 예약되어, 예약과 동시에 출발이 가능합니다.
                                            <p className="dim-color">
                                                단, 기존 예약자의 일부가 취소되어 최소 출발인원이 미충족될 경우 불가할 수 있습니다.
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="th-style">예약가능</div>
                                        <div className="td-style">여유좌석 내에서 예약이 가능하지만, 출발여부가 미정입니다.</div>
                                    </li>
                                    <li>
                                        <div className="th-style">예약대기</div>
                                        <div className="td-style">여유좌석 확보를 위해 담당자 확인 중인 상태로 대기예약으로 진행됩니다.</div>
                                    </li>
                                </ul>
                            </div>

                            <div className="btn-wrap a-right">
                                <span className="btn-base btn-st-full btn-bg-bl">
                                    <a style={{cursor:"pointer"}} onClick={closeModalPopup}>확인</a>
                                </span>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
    );
};

export default ProductLevelPopup;