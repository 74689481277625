import React from "react";

export default function LiabilityNotice({
                                            terms,
                                            termsCtx,
                                        }) {

    return (
        <>
            <div className="pkg-tit-term-cont">
                <h3 className="title between-box fc">
                    (주)타이드스퀘어는 통신판매중개자이며, 통신 판매 당사자가 아닙니다.<br/>따라서 상품ㆍ거래 정보 및 거래에 대하여 책임을 지지 않습니다.(필수)
                    <div className="agr-area">
                                    <span className="check-group">
                                        <input type="checkbox" className="hidden_check" id="liabilityNotice"
                                               name="liabilityNotice" checked={terms.liabilityNotice} onChange={termsCtx.onCheck}/>
                                        <label htmlFor="liabilityNotice" className="label-check">동의</label>
                                    </span>
                    </div>
                </h3>
            </div>
        </>
    );
}