import axios from "axios";

const getAxiosHeaders = () => {
    return {
        'Authorization': 'Bearer ' + process.env.REACT_APP_API_KEY,
        //  'Authorization': "Bearer " + " eyJhbGciOiJIUzUxMiJ9.ewogICJzdWIiOiAiUEFDS0FHRSIsCiAgImlzcyI6ICJwcml2aWEtcGFja2FnZS1hcGktdjEiLAogICJpYXQiOiAxNjc0NzE4NjM0LAogICJleHAiOiAyMzA1NDM4NjM0LAogICJpZCI6IDEsCiAgIm5hbWUiOiAiUFJJVklBIiwKICAicm9sZSI6ICJGUk9OVF9DSEFOTkVMIiwKICAic3RhZ2UiOiAicHJvZCIKfQ==.r5zupOSopmO9cruF6C9HpOJL9g7vAxIPX-xL16fljnrB7qkf3_deofwI7DfIvqUgaToywYnqiD-BTnNXCaxoPg",
        'Channel-Id': 'PRIVIA',
        'Content-Type': "application/json"
    };
}

export async function findParentProduct(representativeCode) {
    let API_URL = process.env.REACT_APP_SERVER_URL;
    return await axios.get(`${API_URL}/api/v1/product/${representativeCode}/representativeProduct`, {
        headers: getAxiosHeaders(),
    });
}
export async function findParentProductList(params) {
    let API_URL = process.env.REACT_APP_SERVER_URL;
    return await axios.get(`${API_URL}/api/v1/product/representative/list?` + params, {
        headers: getAxiosHeaders(),
    });
}

export async function findParentProductListDynamicFilter(params) {
    let API_URL = process.env.REACT_APP_SERVER_URL;
    return await axios.get(`${API_URL}/api/v1/product/representative/list/dynamicFilter?` + params, {
        headers: getAxiosHeaders(),
    });
}
export async function findChildProductByCode(representativeCode) {
    let API_URL = process.env.REACT_APP_SERVER_URL;
    // 왜 REACT_APP_API_URL 주입 못받지?
    //  API_URL = "https://dev-package-api.tidesquare.com"

    return await axios.get(`${API_URL}/api/v1/product/${representativeCode}/sale`, {
        headers: getAxiosHeaders()
    });
}

export async function findOneProductByCode(representativeCode, saleCode) {
    let API_URL = process.env.REACT_APP_SERVER_URL;

    return await axios.get(`${API_URL}/api/v1/product/${representativeCode}/sale/${saleCode}/saleProduct`, {
        headers: getAxiosHeaders()
    });
}

export async function findProductByKeyword(keyword) {
    let API_URL = process.env.REACT_APP_SERVER_URL;
    // 왜 REACT_APP_API_URL 주입 못받지?
    // API_URL = "https://dev-package-api.tidesquare.com"
    // API_URL = "https://local.priviatravel.com:8181"

    // 이거 왜 Post로 요청하지?
    return await axios.post(`${API_URL}/api/v1/keyword`,
        {"keyword": keyword},
        {headers: getAxiosHeaders()}
    )

}

