import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {VOUCHER_TYPE} from "../../../../domain/model/user/UserVoucherEntity";
import {
    getGreenVoucherDiscountSelector,
    getRedVoucher4DiscountValueSelector,
    getRedVoucherDiscountSelector,
    getRedVoucherStripeDiscountSelector,
    getSummitVoucherDiscountSelector,
    setGreenVoucherOptionListSelector,
    setInvalidGreenVoucherSelector,
    setInvalidRedVoucher4Selector,
    setInvalidRedVoucherSelector,
    setInvalidRedVoucherStripeSelector,
    setInvalidSummitVoucherSelector,
    setRedVoucher4OptionListSelector,
    setRedVoucherOptionListSelector,
    setRedVoucherStripeOptionListSelector,
    setSelectedGreenVoucherSelector,
    setSelectedRedVoucher4Selector,
    setSelectedRedVoucherSelector,
    setSelectedRedVoucherStripeSelector,
    setSelectedSummitVoucherSelector,
    setSummitVoucherOptionListSelector
} from "../../../state/reservation/benefit/VoucherSelector";
import useEvent from "../../../../infra/event/useEvent";
import {EVENT_NAMES} from "../event/events";
import usePaymentPrice from "../usePaymentPrice";
import {HccOption} from "../../../state/reservation/PaymentPriceState";
import {DiscountStep, DiscountType} from "../../../../domain/service/payment/PaymentEnum";
import {PRICE_TYPE} from "../../../state/reservation/PriceSelectors";
import {getDiscountValueDictSelector} from "../../../state/reservation/DiscountSelector";


function getOptionListSelectorByType(voucherType) {
    switch (voucherType) {
        case VOUCHER_TYPE.RED5:
            return setRedVoucherOptionListSelector;
        case VOUCHER_TYPE.RED4:
            return setRedVoucher4OptionListSelector;
        case VOUCHER_TYPE.RED_STRIP:
            return setRedVoucherStripeOptionListSelector
        case VOUCHER_TYPE.GREEN:
            return setGreenVoucherOptionListSelector;
        case VOUCHER_TYPE.SUMMIT:
            return setSummitVoucherOptionListSelector;
    }
}


function getSelectedVoucherSelectorByType(voucherType) {
    switch (voucherType) {
        case VOUCHER_TYPE.RED5:
            return setSelectedRedVoucherSelector;
        case VOUCHER_TYPE.RED4:
            return setSelectedRedVoucher4Selector;
        case VOUCHER_TYPE.RED_STRIP:
            return setSelectedRedVoucherStripeSelector;
        case VOUCHER_TYPE.GREEN:
            return setSelectedGreenVoucherSelector;
        case VOUCHER_TYPE.SUMMIT:
            return setSelectedSummitVoucherSelector;
    }
}

function getDiscountByType(voucherType) {
    switch (voucherType) {
        case VOUCHER_TYPE.RED4:
            return getRedVoucher4DiscountValueSelector
        case VOUCHER_TYPE.RED_STRIP:
            return getRedVoucherStripeDiscountSelector
        case VOUCHER_TYPE.RED5:
            return getRedVoucherDiscountSelector
        case VOUCHER_TYPE.GREEN:
            return getGreenVoucherDiscountSelector
        case VOUCHER_TYPE.SUMMIT:
            return getSummitVoucherDiscountSelector
    }
}

function getNameViewString(voucherType) {
    switch (voucherType) {
        case VOUCHER_TYPE.RED5:
        case VOUCHER_TYPE.RED_STRIP:
        case VOUCHER_TYPE.RED4:
            return `the Red Voucher`
        case VOUCHER_TYPE.GREEN:
            return `the Green Voucher`
        case VOUCHER_TYPE.SUMMIT:
            return `the Summit Voucher`
    }
}

export function getVoucherTitleViewString(voucherType, count) {
    switch (voucherType) {
        case VOUCHER_TYPE.RED5:
            return `the Red Voucher5 (총 ${count}장)`
        case VOUCHER_TYPE.RED4:
            return `the Red Voucher4 (총 ${count}장)`
        case VOUCHER_TYPE.RED_STRIP:
            return `the Red Voucher Stripe (총 ${count}장)`
        case VOUCHER_TYPE.GREEN:
            return `the Green Voucher (총 ${count}장)`
        case VOUCHER_TYPE.SUMMIT:
            return `Summit Voucher (총 ${count}장)`
    }
}

function getModalTitleViewString(voucherType, count) {
    switch (voucherType) {
        case VOUCHER_TYPE.RED5:
        case VOUCHER_TYPE.RED_STRIP:
        case VOUCHER_TYPE.RED4:
            return `the Red Voucher 보유 현황 (총 ${count}매)`
        case VOUCHER_TYPE.GREEN:
            return `the Green Voucher 보유 현황 (총 ${count}매)`
        case VOUCHER_TYPE.SUMMIT:
            return `Summit Voucher 보유 현황 (총 ${count}매)`
    }
}

function getVoucherDiscountType(voucherType) {
    switch (voucherType) {
        case VOUCHER_TYPE.RED5:
            return DiscountType.RedVoucher
        case VOUCHER_TYPE.RED4:
            return DiscountType.RedVoucher4
        case VOUCHER_TYPE.RED_STRIP:
            return DiscountType.RedVoucherStrip
        case VOUCHER_TYPE.GREEN:
            return DiscountType.GreenVoucher
        case VOUCHER_TYPE.SUMMIT:
            return DiscountType.SummitVoucher
    }
}


function getSetInvalidVoucher(voucherType) {
    switch (voucherType) {
        case VOUCHER_TYPE.RED5:
            return setInvalidRedVoucherSelector
        case VOUCHER_TYPE.RED4:
            return setInvalidRedVoucher4Selector
        case VOUCHER_TYPE.RED_STRIP:
            return setInvalidRedVoucherStripeSelector
        case VOUCHER_TYPE.GREEN:
            return setInvalidGreenVoucherSelector
        case VOUCHER_TYPE.SUMMIT:
            return setInvalidSummitVoucherSelector
    }

}

/*
* 바우처 최대 선택 갯수
* 레드 바우처 1개
* 그린 바우처 5개
* */
function getMaxSelectedCount(voucherType) {
    switch (voucherType) {
        case VOUCHER_TYPE.RED5:
            return 1
        case VOUCHER_TYPE.RED4:
            return 1
        case VOUCHER_TYPE.RED_STRIP:
            return 1
        case VOUCHER_TYPE.SUMMIT:
            return 1
        case VOUCHER_TYPE.GREEN:
            return 5
    }
    return 1
}

export default function useVoucherV2({
                                         eventManager,
                                         voucherType,
                                     }) {
    const {
        setDiscountV2,
        removeDiscountV2,
        isSetDiscount,
        cardOption,
        priceDict,
    } = usePaymentPrice()

    const [optionList, setOptionList] = useRecoilState(getOptionListSelectorByType(voucherType))
    const [selectedVoucherList, setSelectedVoucherList] = useRecoilState(getSelectedVoucherSelectorByType(voucherType))
    const discount = useRecoilValue(getDiscountByType(voucherType))
    const discountValueDict = useRecoilValue(getDiscountValueDictSelector)
    const setInvalidVoucher = useSetRecoilState(getSetInvalidVoucher(voucherType))

    const adaptedMoney = discountValueDict[getVoucherDiscountType(voucherType)]
    const applicableAmount = priceDict[PRICE_TYPE.SUM_TARGET_PRICE]
    const optionCount = optionList.length

    useEvent(eventManager, EVENT_NAMES.LOAD_VOUCHER, (evt) => {
        const userVoucherEntity = evt.getPayload()
        setOptionList(userVoucherEntity.getVoucherOptionList(voucherType))
    })

    function onOpen() {
        if (cardOption.value !== HccOption.value) {
            alert('현대카드 결제 시에만 사용할 수 있습니다.')
            return false
        }
        const otherDiscountCtx = isSetDiscount(DiscountStep.FIRST_STEP, getVoucherDiscountType(voucherType))
        if (otherDiscountCtx.isSet && !otherDiscountCtx.equal) {
            return window.confirm(otherDiscountCtx.message)
        }
        return true
    }

    function getTotalUseVoucherAmount() {
        return selectedVoucherList.reduce((acc, voucher) => acc + voucher.manWon, 0)
    }

    function onCheck(voucher) {
        const maxSelectedCount = getMaxSelectedCount(voucherType)
        const selectedFound = selectedVoucherList.find((item) => item.id === voucher.id)
        if (selectedFound) {
            setSelectedVoucherList(
                selectedVoucherList.filter((item) => item.id !== voucher.id)
            )
            return
        }

        if (!selectedFound) {
            const accWon = selectedVoucherList.reduce((acc, v) => acc + v.won, 0)
            if (voucher.won + accWon > applicableAmount) {
                alert('사용할 바우처 금액이 결제금액 보다 많습니다.\n※바우처는 상품가에만 적용 가능 합니다. (유류할증료, 제세공과금은 회원 부담)');
                return
            }

            if (voucher.type === VOUCHER_TYPE.SUMMIT) {
                if (applicableAmount < 300000) {
                    alert('Summit Voucher는 상품가 기준 30만원 이상일 때 사용 가능합니다. (유류할증료, 제세공과금은 제외)')
                    return
                }
            }

            const newSelectedVoucherList = [...selectedVoucherList, voucher]
            if (newSelectedVoucherList.length > maxSelectedCount) {
                alert(`최대 ${maxSelectedCount}개까지 선택 가능합니다.`)
                return
            }
            setSelectedVoucherList(newSelectedVoucherList)
        }

    }

    function onApply() {
        if (selectedVoucherList.length === 0) return
        setDiscountV2(discount)
    }

    function onClose() {
        removeDiscountV2(discount)
        setSelectedVoucherList([])
    }
    function getSubmitData() {
        // const selectedOptionList = getSelectedOptionList()
        return {
            // voucherList: selectedOptionList.map(option => option.data)
        }
    }

    function setInvalid() {
        setInvalidVoucher()
    }

    return {
        voucherType,
        optionList,
        totalUseVoucherAmount: getTotalUseVoucherAmount(),
        name: getNameViewString(voucherType),
        enable: optionCount > 0,
        title: getVoucherTitleViewString(voucherType, optionCount),
        modalTitle: getModalTitleViewString(voucherType, optionCount),
        adaptedMoney,
        selectedVoucherDict: selectedVoucherList.reduce((acc, voucher) => {
            acc[voucher.id] = true
            return acc
        }, {}),
        onOpen,
        onCheck,
        onApply,
        onClose,
        getSubmitData,
        setInvalid,
    }
}