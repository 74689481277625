import {atom} from 'recoil';

export const reservationPersonNameState = atom({
    key: 'reservationPersonNameState', // Unique ID (with respect to other atoms/selectors)
    default: '', // default value (aka initial value)
});

export const reservationPersonPhoneState = atom({
    key: 'reservationPersonPhoneState', // Unique ID (with respect to other atoms/selectors)
    default: '', // default value (aka initial value)
});

export const reservationPersonEmailState = atom({
    key: 'reservationPersonEmailState', // Unique ID (with respect to other atoms/selectors)
    default: '', // default value (aka initial value)
});