import React, {useEffect} from "react";
import {shallowEqual, useSelector} from "react-redux";
import axios from "axios";
import {formatNumber} from "../../utils/formatNumber";
import moment from "moment";
import {getAxiosHeaders} from "../../common/util/Axios";

const CouponDownloadPopup = ({ getDownloadableCouponList, getIssuedCouponList }) => {

    const serverUrl = process.env.REACT_APP_SERVER_URL;

    let downloadableCouponList = useSelector((state) => state.detailReducer.downloadableCouponList, shallowEqual);
    let issuedCouponList = useSelector((state) => state.detailReducer.issuedCouponList, shallowEqual);
    let userInfo = useSelector((state) => state.headerReducer.userInfo, shallowEqual);
    const [couponAllState , setCouponAllState] = React.useState(false);

    useEffect(() => {
        //
    }, []);

    const clickedCouponDownload = (couponNumber) => {
        if (!couponNumber.isNull && !couponNumber.isEmpty ) {
            doCouponDownload(couponNumber).then(r => { console.log(r); });
        }
    }

    const doCouponDownload = async (couponNumber: number) => {
        if (!couponNumber.isNull && !couponNumber.isEmpty ) {
            axios.post(serverUrl + '/api/v1/payment/coupon/download', {
                "couponIssueDivision": "3",
                "couponIssueReason": "Privia package coupon download",
                "couponNumbers": [couponNumber],
                "issueChannel": "CHANNEL0002",
                "memberId": userInfo.memberId
            }, {
                headers: getAxiosHeaders()
            }).catch(function (error) {
                console.log(error.toJSON());
            }).then(function (response) {
                if (response && response.data !== 'NG') {
                    getDownloadableCouponList(userInfo.memberNumber);
                    getIssuedCouponList(userInfo.memberNumber);
                    alert("쿠폰이 발급되었습니다.");
                } else {
                    alert("쿠폰을 발급할 수 없습니다.");
                }
            });
        }
    }

    const doAllCouponDownload = async () => {
        let downloadableCoupons = [];
        downloadableCoupons = downloadableCouponList.map(coupon => coupon.couponNumber);
        axios.post(serverUrl + '/api/v1/payment/coupon/download', {
            "couponIssueDivision": "3",
            "couponIssueReason": "Privia package coupon download",
            "couponNumbers": downloadableCoupons,
            "issueChannel": "CHANNEL0002",
            "memberId": userInfo.memberId
        }, {
            headers: getAxiosHeaders()
        }).catch(function (error) {
            console.log(error.toJSON());
        }).then(function (response) {
            if (response && response.data !== 'NG') {
                getDownloadableCouponList(userInfo.memberNumber);
                getIssuedCouponList(userInfo.memberNumber);
                alert("쿠폰이 발급되었습니다.");
            } else {
                alert("쿠폰을 발급할 수 없습니다.");
            }
        });
    }

    const couponClassAllState = () =>{
        if(downloadableCouponList && downloadableCouponList.length > 0) {
            return ''
        } else {
            return 'disabled'
        }
    }


    return (
    <React.Fragment>
        <div className="coupon-detail pkg-over-scroll">
            <section className="lpu-item-sec">
            {((downloadableCouponList && downloadableCouponList.filter((coupon) => coupon.plusYn === false).length > 0)
                    || (issuedCouponList && issuedCouponList.filter((coupon) => coupon.plusYn === false).length > 0)) &&
                <div className="lpu-sub-item">
                    <div className="lpu-big-title a-pt5 a-mb15">일반 쿠폰</div>
                    {downloadableCouponList && downloadableCouponList.map((coupon, index) => (coupon.plusYn === false) && (
                        <div className="coupon-list" key={`couponNumber${coupon.couponNumber}`}>
                            <ul className="coupon-desc-list">
                                <li>
                                    {coupon.couponDcDivision === "1" ?
                                        (<h5><em>{formatNumber(coupon.couponDcAmount)}</em>원 할인</h5>) :
                                        (<h5><em>{formatNumber(coupon.couponDcRate)}</em>% 할인</h5>)}
                                </li>
                                <li>
                                    <p className="ellipsis2">{coupon.couponName}</p>
                                </li>
                                {coupon.couponUseMinProductAmount > 0 &&
                                    <li className="coupon-desc">
                                        {formatNumber(coupon.couponUseMinProductAmount)}원 이상 구매 시 {coupon.couponDcDivision === "1" ? formatNumber(coupon.couponDcAmount) + "원" : formatNumber(coupon.couponDcRate) + "%"} 할인
                                    </li>
                                }
                                <li className="coupon-desc">
                                    <span className="dim-color">{moment(moment(coupon.couponDownloadEndDate).format('YYYY-MM-DD 23:59')).diff(new Date(), 'days')}일 남음(소진 시 조기 종료)</span>
                                </li>
                            </ul>
                            <span className="btn-cp-down">
                                {coupon.downloadableChannel && coupon.downloadableChannel.indexOf("CHANNEL0002") !== -1 ?
                                    ( <button type="button" aria-label="해당쿠폰 다운로드" onClick={() => clickedCouponDownload(coupon.couponNumber)}></button> ) :
                                    ( <button type="button" aria-label="해당쿠폰 다운로드" className="disabled"></button> )
                                }
                            </span>
                        </div>
                        )
                    )}
                    {issuedCouponList && issuedCouponList.map((coupon, index) => (coupon.plusYn === false) && (
                        <div className="coupon-list" key={`couponNumber${coupon.couponNumber}`}>
                            <ul className="coupon-desc-list">
                                <li>
                                    {coupon.couponDcDivision === "1" ?
                                        (<h5><em>{formatNumber(coupon.couponDcAmount)}</em>원 할인</h5>) :
                                        (<h5><em>{formatNumber(coupon.couponDcRate)}</em>% 할인</h5>)}
                                </li>
                                <li>
                                    <p className="ellipsis2">{coupon.couponName}</p>
                                </li>
                                {coupon.couponUseMinProductAmount > 0 &&
                                    <li className="coupon-desc">
                                        {formatNumber(coupon.couponUseMinProductAmount)}원 이상 구매 시 {coupon.couponDcDivision === "1" ? formatNumber(coupon.couponDcAmount) + "원" : formatNumber(coupon.couponDcRate) + "%"} 할인
                                    </li>
                                }
                                <li className="coupon-desc">
                                    <span className="dim-color">{coupon.couponUseIssuedBaseDd ? coupon.couponUseIssuedBaseDd : moment(moment(coupon.couponUseEndDate).format('YYYY-MM-DD 23:59')).diff(new Date(), 'days')}일 남음</span>
                                </li>
                            </ul>
                            <span className="btn-cp-down">
                                <button type="button" aria-label="해당쿠폰 다운로드" className="disabled"></button>
                            </span>
                        </div>
                        )
                    )}
                </div>
            }
            {((downloadableCouponList && downloadableCouponList.filter((coupon) => coupon.plusYn === true).length > 0)
                    || (issuedCouponList && issuedCouponList.filter((coupon) => coupon.plusYn === true).length > 0)) &&
                <div className="lpu-sub-item">
                    <div className="lpu-big-title a-mt20 a-mb15">플러스 쿠폰</div>
                    {downloadableCouponList && downloadableCouponList.map((coupon, index) => (coupon.plusYn === true) && (
                        <div className="coupon-list" key={`couponNumber${coupon.couponNumber}`}>
                            <ul className="coupon-desc-list">
                                <li>
                                    {coupon.couponDcDivision === "1" ?
                                        (<h5><em>{formatNumber(coupon.couponDcAmount)}</em>원 할인</h5>) :
                                        (<h5><em>{formatNumber(coupon.couponDcRate)}</em>% 할인</h5>)}
                                </li>
                                <li>
                                    <p className="ellipsis2">{coupon.couponName}</p>
                                </li>
                                {coupon.couponUseMinProductAmount > 0 &&
                                    <li className="coupon-desc">
                                        {formatNumber(coupon.couponUseMinProductAmount)}원 이상 구매 시 {coupon.couponDcDivision === "1" ? formatNumber(coupon.couponDcAmount) + "원" : formatNumber(coupon.couponDcRate) + "%"} 할인
                                    </li>
                                }
                                <li className="coupon-desc">
                                    <span className="dim-color">{moment(moment(coupon.couponDownloadEndDate).format('YYYY-MM-DD 23:59')).diff(new Date(), 'days')}일 남음(소진 시 조기 종료)</span>
                                </li>
                            </ul>
                            <span className="btn-cp-down">
                                {coupon.downloadableChannel && coupon.downloadableChannel.indexOf("CHANNEL0002") !== -1 ?
                                    ( <button type="button" aria-label="해당쿠폰 다운로드" onClick={() => clickedCouponDownload(coupon.couponNumber)}></button> ) :
                                    ( <button type="button" aria-label="해당쿠폰 다운로드" className="disabled"></button> )
                                }
                            </span>
                        </div>
                        )
                    )}
                    {issuedCouponList && issuedCouponList.map((coupon, index) => (coupon.plusYn === true) && (
                        <div className="coupon-list" key={`couponNumber${coupon.couponNumber}`}>
                            <ul className="coupon-desc-list">
                                <li>
                                    {coupon.couponDcDivision === "1" ?
                                        (<h5><em>{formatNumber(coupon.couponDcAmount)}</em>원 할인</h5>) :
                                        (<h5><em>{formatNumber(coupon.couponDcRate)}</em>% 할인</h5>)}
                                </li>
                                <li>
                                    <p className="ellipsis2">{coupon.couponName}</p>
                                </li>
                                {coupon.couponUseMinProductAmount > 0 &&
                                    <li className="coupon-desc">
                                        {formatNumber(coupon.couponUseMinProductAmount)}원 이상 구매 시 {coupon.couponDcDivision === "1" ? formatNumber(coupon.couponDcAmount) + "원" : formatNumber(coupon.couponDcRate) + "%"} 할인
                                    </li>
                                }
                                <li className="coupon-desc">
                                    <span className="dim-color">{coupon.couponUseIssuedBaseDd ? coupon.couponUseIssuedBaseDd : moment(moment(coupon.couponUseEndDate).format('YYYY-MM-DD 23:59')).diff(new Date(), 'days')}일 남음</span>
                                </li>
                            </ul>
                            <span className="btn-cp-down">
                                <button type="button" aria-label="해당쿠폰 다운로드" className="disabled"></button>
                            </span>
                        </div>
                        )
                    )}
                </div>
            }
                <div className="btn-wrap a-center a-pt5">
                    <span className="btn-base btn-st-full btn-bg-bl w150 .disabled">
                        <button {...downloadableCouponList && downloadableCouponList.length > 0 ? {disabled:false} : {disabled: true}} onClick={doAllCouponDownload}>{downloadableCouponList && downloadableCouponList.length > 0 ? '전체 다운로드':'전체 다운로드 완료'}</button>
                    </span>
                </div>
            </section>
        </div>
    </React.Fragment>
    );
};

export default CouponDownloadPopup;