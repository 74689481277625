export default class RepresentativeProductEntity {
    constructor(data) {
        this.data = data;
    }

    getDiscountObjectAmount() {
        if (this.data === undefined) {
            return 0;
        }
        // 일단 깡값.
        return this.data['minExhibitionDiscountAmount']
    }

    getName() {
        if (!this.data) {
            return ""
        }
        return this.data['representProductName']
    }


    getData() {
        return this.data;
    }
}