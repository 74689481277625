import ReservationCompleteComponent from "../../component/reservation/ReservationCompleteComponent";
import useResultReservation from "../../../usecase/reservation/useResultReservation";


export default function CompleteReservationContainer() {
    const {
        reservationNumber,
        loadReservationCtx,
        serverUrl,
        priviaUrl,
        memberNumber,
    } = useResultReservation()
    const {
        loading,
        error,
        reservationData,
        representativeProductData,
        saleProductData,
        paymentData,
        inicisData,
        inquireListData
    } = loadReservationCtx

    return (
        <ReservationCompleteComponent
            loading={loading}
            reservation={reservationData}
            representativeProduct={representativeProductData}
            saleProduct={saleProductData}
            paymentData={paymentData}
            priviaUrl={priviaUrl}
            memberNumber={memberNumber}
        />
    )
}