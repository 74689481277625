import {DiscountStep, PaymentType} from "../../../../domain/service/payment/PaymentEnum";
import usePaymentPrice from "../usePaymentPrice";
import useEnv from "../../../../infra/env/useEnv";
import {PRICE_TYPE} from "../../../state/reservation/PriceSelectors";
import useEvent from "../../../../infra/event/useEvent";
import {EVENT_NAMES} from "../event/events";
import {SaleProductAmountTarget} from "../../../../domain/model/product/SaleProductEntity";
import {initFirstStepDiscountSelector} from "../../../state/reservation/DiscountSelector";
import {useSetRecoilState} from "recoil";
import {useLocation} from "react-router-dom";
import {calculateReservationPrice} from "../../../../domain/repository/ReservationRepository";


export default function usePayment(eventManager) {
    const {
        paymentPrice,
        setUpAmountCount,
        setPaymentType,
        amountList,
        discountValueDict,
        paymentType,
        priceDict,
        discountDict,
        cardOption,
        inicisEnv,
        paymentSubmitData,
        inicisPaySubmitData,
        setAmountDict,
    } = usePaymentPrice()
    const initFirstStepDiscount = useSetRecoilState(initFirstStepDiscountSelector)
    const env = useEnv()
    const search = useLocation()
    const query = new URLSearchParams(search.search)

    useEvent(eventManager, EVENT_NAMES.LOAD_SALE_PRODUCT, (etv) => {
        const saleProduct = etv.getPayload()
        const amountCountDict = {
            [SaleProductAmountTarget.ADULT]: parseInt(query.get("adultCount") || 0),
            [SaleProductAmountTarget.CHILD]: parseInt(query.get("childrenCount") || 0),
            [SaleProductAmountTarget.INFANT]: parseInt(query.get("infantCount") || 0),
        }

        const amountDict = [
            SaleProductAmountTarget.ADULT,
            SaleProductAmountTarget.CHILD,
            SaleProductAmountTarget.INFANT
        ].reduce((acc, target) => {
            acc[target] = saleProduct.getAmount(target, amountCountDict[target])
            return acc
        }, {})
        setAmountDict(amountDict)
    })

    /**
     * 결제 타입을 설정한다. 전액결제/예약금결제
     * @param type
     */
    function setUpPaymentPriceType(type = PaymentType.ALL_PAY) {
        setPaymentType(type)
    }

    function getSubmitData() {
        return paymentSubmitData
    }

    function getInicisPayData() {
        return inicisPaySubmitData
    }

    function onSetUpAmount(amount) {
        const {count} = amount
        if (count < 0) {
            if (discountDict[DiscountStep.FIRST_STEP]) {
                const isInitFirstStep = window.confirm("인원 변경 시 적용된 혜택이 모두 초기화 됩니다. 변경하시겠습니까?")
                if (isInitFirstStep) {
                    initFirstStepDiscount()
                    setUpAmountCount(amount)
                }
                return
            }
        }
        setUpAmountCount(amount)
    }


    return {
        amountList,
        setUpAmountCount: onSetUpAmount,
        paymentPrice,
        readOnlyAmount: paymentPrice.readOnlyAmount,
        setUpPaymentPriceType,
        paymentMethod: paymentPrice.paymentMethod,
        discountValueDict,
        priceInfo: {
            reservationPrice: priceDict[PRICE_TYPE.RESERVATION_PRICE],
            prePaidPrice: priceDict[PRICE_TYPE.PRE_PAID_PRICE],
            totalPrice: priceDict[PRICE_TYPE.SUM_PRODUCT_PRICE],
            totalDiscount: priceDict[PRICE_TYPE.SUM_BENEFIT_PRICE],
            addAmount: priceDict[PRICE_TYPE.ADD_AMOUNT],
            paymentPrice: priceDict[PRICE_TYPE.PAYMENT_PRICE],
            step1: priceDict[PRICE_TYPE.PAYMENT_PRICE_STEP_1],
            step2: priceDict[PRICE_TYPE.PAYMENT_PRICE_STEP_2],
            rootTargetPrice: priceDict[PRICE_TYPE.ROOT_TARGET_PRICE],
        },
        paymentType: paymentPrice.paymentType,
        paymentStep: paymentPrice.paymentStep,
        inicisReleaseURL: env.releaseURL,
        getSubmitData,
        getInicisPayData,
        priceDict,

    }

}