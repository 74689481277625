import React, { useState } from "react";
import {formatNumber} from "../../../../../utils/formatNumber";
import HyundaiAuth from "./HyundaiAuth";


export default function MPointSection({
                                          reservationPersonCtx,
                                          mPointCtx,
                                          paymentCtx,
                                          userCtx
                                      }) {
    const [isOpen, setIsOpen] = useState(false);

    const {
        percent,
        isCheck,
        maxPossibleMPoint,
        toggleMPoint,
        usingMPoint,
        maxPoint
    } = mPointCtx

    const {priceInfo} = paymentCtx
    // 할인 대상 금액이 0원이면 렌더링 하지 않음.
    if (priceInfo.rootTargetPrice <= 0) {
        return null
    }
    function handleMPointCheck(e) {
        if(!mPointCtx.enable && e.target.checked){
            setIsOpen(true);
            return;
        }

        toggleMPoint && toggleMPoint()
    }

    const closePopup = () => {
        setIsOpen(false);
    };

    return (
        <>
            <section className="book-detail-area pay-type-cont js-pt-all">
                <div className="pkg-tit-page-cont bb-line">
                    <h3 className="title">현대카드 M포인트</h3>
                </div>

                <div className="book-article">

                    <ul className="f-field-box">
                        <li className="wfull">
                            <div className="pay-bnf-wrap">
                                <div className="between-box fc inner-price-li">
                                    <div className="between-box fc">
                                                <span className="check-group">
                                                    <input type="checkbox"
                                                        className="hidden_check"
                                                        id="chk-book-4"
                                                        name="pkgBnf"
                                                        onChange={handleMPointCheck}
                                                        checked={isCheck}/>
                                                    <label htmlFor="chk-book-4"
                                                        className="label-check a-bold"><span>M포인트 <em
                                                        className="c-point1">{percent}%</em> 사용</span></label>
                                                </span>
                                        <span className="help-tooltip pkg-st small">
                                                    <span className="over">?</span>
                                                    <div className="cont mpoint" style={{width: '400px'}}>
                                                        <ul className="list-dot stPkg tpPop">
                                                            <li>M포인트 할인은 정상금액 결제 후 청구 시 포인트가 차감됩니다.</li>
                                                            <li>현대카드 청구 할인 혜택 적용 시 추가요금, 유류할증료, 제세공과금은 제외됩니다.</li>
                                                            <li>M포인트 부족 시 잔여 M 포인트 만큼 사용 가능합니다.</li>
                                                            <li>전액 또는 잔금 결제 시 10% 까지 사용 가능 (1포인트 = 1원)</li>
                                                            <li>M포인트 사용 및 적립은 M포인트 사용 가능한 현대카드에 적용됩니다.<br/>(법인또는 Gift카드 제외)</li>
                                                        </ul>
                                                    </div>
                                                </span>
                                        <ViewMaxPoint maxPoint={maxPoint} maxPossibleMPoint={maxPossibleMPoint}/>
                                    </div>
                                    <div className="input-area a-bold">
                                        <input type="text"
                                            disabled
                                            className={"input-base type01 w140" + (isCheck ? ' state-checked' : '')}
                                            value={formatNumber(usingMPoint)}/>P
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </section>

            {isOpen && (
                <HyundaiAuth open={isOpen} onClose={closePopup} userCtx={userCtx} toggleMPoint={toggleMPoint} />
            )}
        </>
    )
}


function ViewMaxPoint({maxPoint, maxPossibleMPoint}) {
    if (maxPoint === 0) return null

    const viewPoint = maxPossibleMPoint > 0 ? maxPossibleMPoint : 0
    return (
        <>
        <span>
            (보유 포인트
            <span className="c-point1 a-bold">{formatNumber(viewPoint)}</span>
            <span className="a-bold">P</span>)
        </span>
        </>
    )
}