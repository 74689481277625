import Select from "react-select";
import {formatNumber} from "../../../../../../utils/formatNumber";
import {Sheet} from "@mui/joy";
import React from "react";

import "../../../../../../css/NumberInput.css"

export default function TicketModalContent({
                                               applicableAmount, // 이용권 최대 적용금액
                                               ticketOptionList, // 이용권 리스트
                                               onSelectTicket, // 이용권 선택.
                                               selectedTicket, // 선택된 이용권
                                               usingTicket,// 사용중인 이용권
                                               onChangeAmountToUse,// 사용할 금액 변경시 발생 이벤트
                                               onApplyAmountToUse, //amountToUse 가 실제 사용 금액으로 변경될 때
                                               onCancelUsingTicket,//amountToUse 취소 될 떄
                                               onApply, // 완전 적용
                                               onClose, // 모달창 닫기
                                           }) {

    return (
        <div className="voucher-detail pkg-over-scroll" style={{maxHeight: '750px'}}>
            <section className="lpu-item-sec">
                <div className="vc-info-box a-mb20">이용권 적용가능금액
                    : <strong>{formatNumber(applicableAmount)}</strong>원
                </div>

                <SelectTicket
                    ticketOptionList={ticketOptionList}
                    selectedTicket={selectedTicket}
                    onSelectTicket={onSelectTicket}
                    onChangeAmountToUse={onChangeAmountToUse}
                    onApplyAmountToUse={onApplyAmountToUse}
                />

                <UsingTicket ticketOptionList={ticketOptionList}
                             usingTicket={usingTicket}
                             onCancelAmountToUse={onCancelUsingTicket}
                             onApply={onApply}
                             onClose={onClose}
                />

                <TicketTerms/>
            </section>
        </div>
    )
}

function UsingTicket({usingTicket, ticketOptionList, onCancelAmountToUse, onApply, onClose}) {
    return (
        <div className="lpu-sub-item a-mt20">
            <table summary="이용권 사용 현황" className="tbl-style list">
                <caption>PRIVIA 이용권 사용 현황에 대한 테이블</caption>
                <colgroup>
                    <col width="25%"/>
                    <col width="25%"/>
                    <col width="25%"/>
                    <col width="25%"/>
                </colgroup>
                <thead>
                <tr>
                    <th scope="col">이용권 번호</th>
                    <th scope="col">이용권 액면가</th>
                    <th scope="col">사용가능금액</th>
                    <th scope="col">사용할 금액</th>
                </tr>
                </thead>
                <tbody>
                {usingTicket && (
                    <tr key={usingTicket.data.hccVoucherNumber}>
                        <td className="a-center">{usingTicket.data.hccVoucherNumber}</td>
                        <td className="a-center">{formatNumber(usingTicket.data.hccVoucherAbleAmount)}원</td>
                        <td className="a-center">{formatNumber(usingTicket.data.hccVoucherAbleRemaining)}원</td>
                        <td className="a-center">
                            <span className="c-point5">{formatNumber(usingTicket.useAmount)}원</span>
                            <a style={{cursor: "pointer"}} onClick={onCancelAmountToUse} className="delete">delete</a>
                        </td>
                    </tr>)
                }
                {(!usingTicket) &&
                    <tr>
                        <td colSpan={4} className="a-center">선택된 이용권이 없습니다.</td>
                    </tr>
                }
                </tbody>
            </table>
            <div className="btn-wrap a-right a-mt20">
                        <span className="btn-base btn-st-full btn-bg-bl w130">
                            <a style={{cursor: "pointer"}} onClick={onApply}>적용</a>
                        </span>
                <span className="btn-base btn-st-full btn-bg-gr w130">
                            <a style={{cursor: "pointer"}} onClick={onClose}>취소</a>
                        </span>
            </div>
        </div>
    )
}

function SelectTicket({
                          ticketOptionList,
                          selectedTicket,
                          onSelectTicket,
                          onChangeAmountToUse,
                          onApplyAmountToUse,
                      }) {
    const defaultTicketOption = {
        value: null,
        id: null,
        label: "이용권 선택",
    }

    return (
        <div className="lpu-sub-item">
            <table summary="PRIVIA 이용권 요약" className="tbl-style list">
                <caption>PRIVIA 이용권, 사용할 금액에 대한 테이블</caption>
                <colgroup>
                    <col width={136}/>
                    <col width="*"/>
                    <col width={117}/>
                    <col width={151}/>
                </colgroup>
                <tbody>
                <tr>
                    <th scope="row">PRIVIA 이용권</th>
                    <td className="ndlr">
                        <div className={"select-base sel-st2"} style={{ width:"100%", float: 'left'}}>
                            <Select
                                options={[defaultTicketOption, ...ticketOptionList]}
                                value={selectedTicket || defaultTicketOption}
                                onChange={onSelectTicket}
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        height: "40px",
                                        borderRadius: 3,
                                        boxShadow: "none",
                                        fontSize: 13,
                                        fontWeight: 400,
                                    })
                                }}
                                isSearchable={false}
                                components={{IndicatorSeparator: () => null,}}
                            />
                        </div>
                    </td>
                    <th scope="row">사용할 금액</th>
                    <td className="ndlr">
                        <div className="input-area">
                            <input type="number"
                                   disabled={!selectedTicket}
                                   className="input-base type01 w100"
                                   onChange={onChangeAmountToUse}
                                   min={10000}
                                   value={selectedTicket?.amountToUse === 0 ? "" : selectedTicket?.amountToUse}/>
                            <span className="won">원</span>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
            <div className="btn-wrap a-right a-mt20">
                        <span className="btn-base btn-st-full btn-bg-bl w130">
                            <a style={{cursor: "pointer"}} onClick={onApplyAmountToUse}>이용권 추가</a>
                        </span>
            </div>
        </div>
    )
}


function TicketTerms() {
    return (
        <div className="lpu-sub-item">
            <div className="lpu-sub-title a-mb20 a-mt10">PRIVIA 이용권 사용 안내</div>
            <div className="lpu-ui-box pkg-over-scroll">
                <Sheet sx={{
                    width: "auto",
                    height: 180,
                    overflow: "auto",
                }}>
                    <div className="t-content">
                        <h4 className="list-dot-tit">PRIVIA 이용권이란?</h4>
                        <ul className="list-dot voucher-desc-list">
                            <li>PRIVIA에서 제공해드리는 기명식 이용 쿠폰으로서 PRIVIA에서 서비스하는 상품에 대해 명시된 금액만큼 이용이 가능합니다.
                                <br/>"<strong>경로 : 예약결제하기 &gt; 혜택/할인적용 &gt; PRIVIA 이용권[적용하기] 시</strong>"
                            </li>
                        </ul>
                        <h4 className="list-dot-tit">PRIVIA 이용권 사용시 유의사항</h4>
                        <ul className="list-dot voucher-desc-list">
                            <li>이용권은 PRIVIA에서 취급하는 상품에 대해 명시된 금액만큼 이용 가능합니다.</li>
                            <li>이용권은 상품권이 아니며 환불 및 유효기간 연장, 유상매매가 불가합니다.</li>
                            <li>이용권은 PRIVIA 홈페이지를 통해 등록한 후 이용이 가능하며, 미등록되거나 등록된 명의의 사용자가 다른 경우 이용이 불가합니다.</li>
                            <li>본 이용권의 등록 및 양도 시 안전한 거래를 위한 신분증 확인과 본인확인 절차가 진행됩니다.</li>
                            <li>본 이용권은 세금계산서가 발행되지 않습니다.</li>
                            <li>본 이용권을 통해 사용한 PRIVIA 여행 상품은 할인이 적용되지 않습니다.</li>
                            <li>본 이용권을 통해 항공권 이용 시, 해외여행보험 무료 가입 혜택은 적용되지 않습니다.</li>
                            <li>본 이용권은 발행일로 부터 5년 동안 유효합니다.</li>
                        </ul>
                    </div>
                </Sheet>
            </div>
        </div>
    )
}

