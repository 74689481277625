import {cloneDeep} from 'lodash'
import {selector} from "recoil";
import {paymentPriceStateAtom} from "../PaymentPriceState";
import {getDiscountValueDict} from "../DiscountSelector";
import {DiscountType} from "../../../../domain/service/payment/PaymentEnum";
import {getNoneTarget, getTargetPrice} from "../PriceSelectors";

export function getTicketTargetValue(paymentPrice) {
    const ret = getTargetPrice(paymentPrice.amountDict) + getNoneTarget(paymentPrice.amountDict) - 100 - paymentPrice.prePaidPrice
    if (ret <= 0) {
        return 0
    }
    return ret
}

/*
* 티켓의 할인 대상 금액 구하는 로직
* */
export function getTicketDiscountTargetValue(paymentPrice, acc) {
    // 티켓의 할인 대상금액은 전체금액에서 -100
    const targetValue = getTicketTargetValue(paymentPrice)
    if (targetValue <= 0) {
        return 0
    }
    if (targetValue - acc <= 0) {
        return 0
    }
    return targetValue - acc
}

export const setTicketOptionListSelector = selector({
    key: 'setTicketOptionListSelector',
    get: ({get}) => {
        return get(paymentPriceStateAtom).ticket.optionList
    },
    set: ({set}, ticketOptionList) => {
        function setTicketOptionList(paymentPrice, ticketOptionList) {
            let ticket = cloneDeep(paymentPrice.ticket)
            if (!ticket.valid) {
                return paymentPrice
            }
            ticket.optionList = ticketOptionList
            return {
                ...paymentPrice,
                ticket
            }
        }

        set(paymentPriceStateAtom, paymentPrice =>
            setTicketOptionList(paymentPrice, ticketOptionList)
        )
    }
})

export function getTicketDiscountValue(paymentPrice, targetValue) {
    const ticket = paymentPrice.ticket
    const usingTicket = ticket.discount.usingTicket
    if (!usingTicket) {
        return 0
    }
    const won = parseInt(usingTicket.useAmount)
    if (targetValue < won) {
        return targetValue
    }
    return won
}

export function getTicketSubmitData(paymentPrice) {
    let ticketList = []
    const usingTicket = paymentPrice.ticket.discount.usingTicket
    if (usingTicket) {
        const discountValueDict = getDiscountValueDict(paymentPrice)
        ticketList.push({
            ...usingTicket.data,
            useAmount: discountValueDict[DiscountType.Ticket]
        })
    }
    return {
        ticketList
    }
}

export function initTicket(ticket) {
    return {
        ...ticket,
        selected: null,
        discount: {
            ...ticket.discount,
            usingTicket: null
        }
    }
}

export const getSelectedTicketSelector = selector({
    key: "getSelectedTicketSelector",
    get: ({get}) => {
        return get(paymentPriceStateAtom).ticket.selected
    }
})

export const getTicketDiscountSelector = selector({
    key: "getTicketDiscountSelector",
    get: ({get}) => {
        return get(paymentPriceStateAtom).ticket.discount
    }
})

export const setUsingTicketSelector = selector({
    key: "setUsingTicketSelector",
    get: ({get}) => {
        return get(paymentPriceStateAtom).ticket.discount.usingTicket
    },
    set: ({set}, usingTicket) => {
        function setUsingTicket(paymentPrice, usingTicket) {
            let ticket = cloneDeep(paymentPrice.ticket)
            ticket.discount.usingTicket = usingTicket
            return {
                ...paymentPrice,
                ticket
            }
        }

        set(paymentPriceStateAtom, paymentPrice =>
            setUsingTicket(paymentPrice, usingTicket)
        )
    }
})

/*
* 선택된 옵션 업데이트 -> 주로 amountToUse 값 업데이트 할려고..
* */
export const updateSelectedTicketSelector = selector({
    key: "setSelectedTicketSelector",
    get: ({get}) => {
        return get(paymentPriceStateAtom).ticket.selected
    },
    set: ({set}, selectedTicket) => {
        function setSelectedTicket(paymentPrice, selectedTicket) {
            let ticket = cloneDeep(paymentPrice.ticket)
            const ret = {
                ...paymentPrice,
                ticket
            }
            if (!ticket.selected) {
                return ret
            }
            ticket.selected = {
                ...ticket.selected,
                ...selectedTicket
            }
            return ret
        }

        set(paymentPriceStateAtom, paymentPrice =>
            setSelectedTicket(paymentPrice, selectedTicket)
        )
    }
})


export const setTicketOptionSelector = selector({
    key: "setTicketOptionSelector",
    get: ({get}) => {
        return get(paymentPriceStateAtom).ticket.optionList
    },
    set: ({set}, ticketOption) => {
        function setTicketOption(paymentPrice, ticketOption) {
            let ticket = cloneDeep(paymentPrice.ticket)
            ticket.selected = null
            ticket.optionList.forEach(opt => {
                opt.selected = false
            })
            const ret = {
                ...paymentPrice,
                ticket
            }
            if (!ticketOption || !ticketOption.id) {
                return ret
            }
            let foundOpt = ticket.optionList
                .find((option) => option.id === ticketOption.id)
            foundOpt.selected = true
            ticket.selected = foundOpt
            return ret
        }

        set(paymentPriceStateAtom, paymentPrice =>
            setTicketOption(paymentPrice, ticketOption)
        )
    }
});


export const setInvalidTicketSelector = selector({
    key: "setInvalidTicketSelector",
    get: ({get}) => {
        return get(paymentPriceStateAtom).ticket
    },
    set: ({set}, invalid) => {
        function setInvalidTicket(paymentPrice, invalid) {
            let ticket = cloneDeep(paymentPrice.ticket)
            ticket.valid = false
            ticket.enable = false
            ticket.optionList = []
            ticket.selected = null
            return {
                ...paymentPrice,
                ticket
            }
        }

        set(paymentPriceStateAtom, paymentPrice =>
            setInvalidTicket(paymentPrice, invalid)
        )
    }
});