import {useState} from "react";

export default function usePaging({initPage = 1, initPerPage = 20, initTotalCount = 0}) {

    const [originPage, setOriginPage] = useState(initPage)
    const [originPerPage, setOriginPerPage] = useState(initPerPage)
    const [page, setPage] = useState(initPage)
    const [perPage, setPerPage] = useState(initPerPage)
    const [totalCount, setTotalCount] = useState(initTotalCount)

    function isNextPage() {
        const currentSum = page * perPage
        return currentSum < totalCount;

    }

    function nextPage() {
        if (isNextPage()) {
            setPage(page + 1)
            return {
                page: page + 1,
                perPage
            }
        }
        return null
    }

    function getPageParameter() {
        return `page=${page}&&perPage=${perPage}`
    }


    function initPaging() {
        setPerPage(originPerPage)
        setPage(originPage)
    }

    return {
        page,
        perPage,
        totalCount,
        initPaging,
        setPage,
        setPerPage,
        setTotalCount,
        isNextPage,
        nextPage,
        getPageParameter,
    }
}