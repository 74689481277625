import {formatNumber} from "../../../../../../utils/formatNumber";
import {createTheme, Dialog} from "@mui/material";
import {PaymentStep} from "../../../../state/reservation/PaymentPriceState";

const theme = createTheme({
    components: {
        MuiDialogContent: {
            styleOverrides: {   // Name of the slot
                root: { // Name of the rule
                    overflowX: 'hidden',
                    padding: '0px',
                    borderTop: '0px',
                    borderBottom: '0px',
                },
            }
        }
    }
})

export default function PaymentProcessPopUp({
                                                open,
                                                setOpen,
                                                onClose,
                                                onPayment,
                                                targetPrice,
                                                noneTargetPrice,
                                                step,
                                            }) {

    if (!open) return null;
    if (step === PaymentStep.INIT) return null;

    function handleClose(e) {
        e?.stopPropagation();
        setOpen && setOpen(false);
        onClose && onClose()
    }

    return (

        <Dialog
            open={open}
            onClose={handleClose}
            theme={theme}
        >
            {step === 1 && <FirstStep targetPrice={targetPrice} noneTargetPrice={noneTargetPrice}/>}
            {step === 2 && <SecondStep noneTargetPrice={noneTargetPrice}
                                       onPayment={onPayment}
                                       onClose={handleClose}/>}
        </Dialog>
    )
}

function FirstStep({
                       targetPrice,
                       noneTargetPrice
                   }) {
    return (
        <div className="loading-pkg step">
            <div className="loading-bar"/>

            <div className="loading-title sub">
                현대카드 M포인트 사용 시<br/>할인 대상 금액과 비대상 금액으로 나뉘어 결제됩니다.
            </div>

            <div className="loading-desc">
                새로고침 또는 뒤로가기를 할 경우 결제가 정상 진행되지 않습니다.<br/>잠시만 기다려주세요.
            </div>

            <ul className="loading-step-list">
                <li className="now"><span> 할인 대상 금액<br/> <span
                    className="price">{formatNumber(targetPrice)}원</span></span>
                </li>
                <li className="next">
                    <span>비할인 대상 금액<br/>
                        <span className="price">{formatNumber(noneTargetPrice)}원</span>
                    </span>
                </li>
                <li className="next">
                    <span>예약/결제 완료</span>
                </li>
            </ul>
        </div>
    )
}

const ATagDefaultStyle = {
    cursor: "pointer"
}

function SecondStep({
                        noneTargetPrice,
                        onPayment,
                        onClose,
                    }) {

    async function handlePayment(e) {
        e?.stopPropagation();
        const res = await onPayment()
        if (!res.isValid) {
            if (res.message) {
                alert(res.message)
            }
            if (res.redirectUrl) {
                window.location.href = res.redirectUrl;
            }
        }
    }

    return (
        <div className="loading-pkg step">
            <a style={ATagDefaultStyle} className="btn_close" onClick={onClose}>닫기</a>
            <div className="loading-title sub">
                현대카드 M포인트 사용 시<br/>할인 대상 금액과 비대상 금액으로 나뉘어 결제됩니다.
            </div>
            <div className="loading-desc">
                할인 대상금액이 결제되었습니다.<br/>비할인 대상 금액 결제를 진행해주세요.
            </div>

            <ul className="loading-step-list">
                <li className="done"><span>할인 대상 금액<br/>결제완료</span></li>
                <li className="now"><span>비할인 대상 금액<br/>
                    <span className="price">{formatNumber(noneTargetPrice)}원</span></span></li>
                <li className="next"><span>예약/결제 완료</span></li>
            </ul>

            <div className="btn-wrap">
                <span className="btn-base btn-st-bfull w300 btn-bg-pkg">
                    <a style={ATagDefaultStyle} onClick={handlePayment}><span
                        className="a-bold">{formatNumber(noneTargetPrice)}원</span> 결제하기</a>
                </span>
            </div>
        </div>
    )
}