
export const VOUCHER_TYPE = Object.freeze({
    GREEN: 'G',
    RED5: 'J',
    RED4: 'H',
    RED_STRIP: '8',
    SUMMIT: '4'
})

export default class UserVoucherEntity {
    constructor(data) {
        this.data = data;
    }

    isLoaded(){
        if (!this.data) return false
        return this.data['loading'] === false
    }

    getAllVoucherList() {
        if (!this.data) return []
        const list = this.data['voucherList'] || []
        return list.flatMap(item => item['voucherInfoList'])
    }

    static getVoucherType(voucher) {
        if (voucher.hccTicketUseDivision === VOUCHER_TYPE.GREEN) {
            return VOUCHER_TYPE.GREEN
        }
        if (voucher.hccTicketUseDivision === VOUCHER_TYPE.RED5) {
            return VOUCHER_TYPE.RED5
        }
        if (voucher.hccTicketUseDivision === VOUCHER_TYPE.RED4) {
            return VOUCHER_TYPE.RED4
        }
        if (voucher.hccTicketUseDivision === VOUCHER_TYPE.RED_STRIP) {
            return VOUCHER_TYPE.RED_STRIP
        }
        if (voucher.hccTicketUseDivision === VOUCHER_TYPE.SUMMIT) {
            return VOUCHER_TYPE.SUMMIT
        }
    }

    static getVoucherTypeName(voucherType) {
        if (voucherType === VOUCHER_TYPE.GREEN) {
            return 'the Green Voucher'
        }
        if (voucherType === VOUCHER_TYPE.RED5) {
            return 'the Red Voucher'
        }
        if (voucherType === VOUCHER_TYPE.RED4) {
            return 'the Red Voucher'
        }
        if (voucherType === VOUCHER_TYPE.RED_STRIP) {
            return 'the Red Voucher'
        }
        if (voucherType === VOUCHER_TYPE.SUMMIT) {
            return 'the Summit Voucher'
        }
    }


    getVoucherOptionList(type = VOUCHER_TYPE.GREEN) {
        if (!this.data) return []
        const list = this.getAllVoucherList()
        const retList = list.filter(voucher => voucher.hccTicketUseDivision === type);
        function makeDataString(date) {
            // data  -> 20230501
            let year = date.substring(0, 4);
            let month = date.substring(4, 6);
            let day = date.substring(6);
            return `${year}-${month}-${day}`
        }

        function getWon(voucher) {
            return Math.floor(voucher.hccTicketAmount / 10000)
        }

        function makeVoucherName(v) {
            const voucherTypeName = UserVoucherEntity.getVoucherTypeName(UserVoucherEntity.getVoucherType(v))
            const amount = getWon(v) + "만원"
            const startAt = makeDataString(v.hccTicketServiceStartDate)
            const endAt = makeDataString(v.hccTicketServiceEndDate)
            return `${voucherTypeName} ${amount} (${startAt} ~ ${endAt})`
        }

        return retList.map(voucher => {
            return {
                type: UserVoucherEntity.getVoucherType(voucher),
                selected: false,
                id: voucher.hccTicketSeq,
                name: makeVoucherName(voucher),
                data: voucher,
                manWon: getWon(voucher),
                won:voucher.hccTicketAmount
            }
        })
    }

}