import axios from "axios";
import {getAxiosHeaders} from "../../../common/util/Axios";

export async function findVoucherList(memberId, encryptedHccNumber) {
    const API_URL = process.env.REACT_APP_SERVER_URL;
    const url = `${API_URL}/api/v2/payment/voucherList/${memberId}/${encryptedHccNumber}`;

    return await axios.get(url, {
        headers: getAxiosHeaders()
    });
}

export async function findHccFamilyUsable(memberId, encryptedHccNumber) {
    const API_URL = process.env.REACT_APP_SERVER_URL;
    const url = `${API_URL}/api/v1/payment/family`;

    return await axios.get(url, {
        headers: getAxiosHeaders(),
        params: {
            memberId: memberId,
            hccCustNo: encryptedHccNumber
        }
    });
}

export async function findMPoint(memberId, encryptedHccNumber) {
    const API_URL = process.env.REACT_APP_SERVER_URL;
    const url = `${API_URL}/api/v1/payment/mPoint/${memberId}/${encryptedHccNumber}`;
    return await axios.get(url, {
        headers: getAxiosHeaders()
    });
}