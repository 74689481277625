import React, {useEffect} from "react";
import {shareKakao} from "../../common/util/ShareKakaoLink";
import {shallowEqual, useSelector} from "react-redux";

const SnsShare = () => {

    let representativeProduct = useSelector((state) => state.productReducer.representativeProduct, shallowEqual);
    let saleProduct = useSelector((state) => state.productReducer.saleProduct, shallowEqual);
    const SNS_URL = window.location.href;

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "//developers.kakao.com/sdk/js/kakao.js";
        script.async = true;
        document.body.appendChild(script);
        return () => document.body.removeChild(script);
    }, []);

    const showSnsBox = () => {
        const snsBox = document.querySelector('.sns_box');
        snsBox.style.display = 'block';
    }

    const hideSnsBox = () => {
        const snsBox = document.querySelector('.sns_box');
        snsBox.style.display = 'none';
    }

    const onClickedFacebook = () => {
        window.open('https://www.facebook.com/sharer/sharer.php?u=' + SNS_URL);
    };

    const onClickedTwitter = () => {
        console.log("트위터");
        window.open('https://twitter.com/share?url=' + SNS_URL);
    };

    const onClickedKakaoTalk = () => {
        console.log("카카오톡");
        shareKakao(SNS_URL, "PRIVIA 해외패키지", representativeProduct.representImageUrl, saleProduct.saleProductName);
    };

    const onClickedClipboard = () => {
        if (navigator.clipboard) {
            navigator.clipboard
                .writeText(SNS_URL)
                .then(() => {
                    alert("클립보드에 복사되었습니다.");
                })
                .catch(() => {
                    alert("복사를 다시 시도해주세요.");
                });
        } else {
            if (!document.queryCommandSupported("copy")) {
                return alert("복사하기가 지원되지 않는 브라우저입니다.");
            }

            const textarea = document.createElement("textarea");
            textarea.value = window.location.href;
            textarea.style.top = 0;
            textarea.style.left = 0;
            textarea.style.position = "fixed";

            document.body.appendChild(textarea);
            textarea.focus();
            textarea.select();
            document.execCommand("copy");
            document.body.removeChild(textarea);
            alert("클립보드에 복사되었습니다.");
        }
    };

    return (
        <div className="sns-share-wrap">
            <div className="lp-area share">
                <a style={{cursor:"pointer"}} className="btn">
                    <span className="share-link" onClick={showSnsBox}>공유하기</span>
                </a>
                <div className="lp-cont sns_box" style={{top: '28px', right: '0', width: '210px', zIndex: '2'}}>
                    <ul className="share-list">
                        <li><a style={{cursor:"pointer"}} onClick={onClickedFacebook}><span className="facebook_icon"></span>페이스북</a></li>
                        <li><a style={{cursor:"pointer"}} onClick={onClickedTwitter}><span className="twitter_icon"></span>트위터</a></li>
                        <li><a style={{cursor:"pointer"}} onClick={onClickedKakaoTalk}><span className="email_icon"></span>카카오톡</a></li>
                        <li><a style={{cursor:"pointer"}} onClick={onClickedClipboard}><span className="copy_icon"></span>링크복사</a></li>
                    </ul>
                    <a style={{cursor:"pointer"}} className="btn-close" onClick={hideSnsBox}>닫기</a>
                </div>
            </div>
        </div>
    );
};

export default SnsShare;