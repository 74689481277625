import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import axios from "axios";
import {getAxiosHeaders} from "./util/Axios";

const Menu = () =>{


    const serverUrl = process.env.REACT_APP_SERVER_URL
    const [clickStatus , setClickStatus] = useState(false)
    const [menuData , setMenuData] = useState([])

    const menu = async () =>{
        let resData = await axios.get(`${serverUrl}/api/v1/pc/main/menu`,{headers: getAxiosHeaders()}).then(res=>res.data).catch(err=>console.log(err))

        if(resData !== 'null' && resData !== undefined){
            let swap = [resData && resData.air , resData.hotel , resData.tna , resData.pkg , resData.promotion , resData.payment , resData.customer ,resData.mypage , resData.about]
            setMenuData(swap)
        }
    }

    useEffect(()=>{
        menu()
    },[])

    const onMenuClick = () =>{
        setClickStatus(!clickStatus)
    }

    const onMenuLinkClickHandle = (url) =>{
        setClickStatus(!clickStatus)
        window.location.href = url
    }

    const className = (index) =>{
        if(index === '항공'){
            return "air"
        }else if(index === '호텔'){
            return "hotel"
        }else if(index === '투어&티켓'){
            return "tna"
        }else if(index === '해외패키지'){
            return "pkg"
        }else if(index === '기획전'){
            return "promotion"
        }else if(index === '혜택 및 서비스'){
            return "payment"
        }else if(index === '고객센터'){
            return "customer"
        }else if(index === '마이페이지'){
            return "mypage"
        }else if(index === 'About PRIVIA 여행'){
            return "about"
        }
    }

    const menuEle = menuData && menuData.map((value , index)=>{
        if(!!value){
            return (
                <li key={index} className={`${className(value.title)}`} style={{height:`345px`}}>
                <span className={"tit"}>
                    <a href={value.link}>{value.title}</a>
                </span>
                    <ul className={"list"}>
                        {
                            value && value.subNav.map((subMenuData , subindex)=>{
                                return (
                                    <li key={subindex}>
                                        <Link to={()=>false} onClick={(e)=>onMenuLinkClickHandle(subMenuData.link)}>{subMenuData.title}</Link>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </li>
            )
        } else {
            return (
                <>
                </>
            )
        }
    })

    return(
        <div className="w-header-gnb nw-ptpc-bann-st">
            <div className="ly-inner">
                <h1 className="h-logo privia">
                    <Link to={process.env.REACT_APP_MENU_HOME_LINK}>
                        <span className="a11y">PRIVIA 여행</span>
                    </Link>
                </h1>
                <h2 className="a11y">GNB 영역</h2>

                <ul id="nav-gnb" className="nav-gnb">
                    <li id="n-gnb-air"><Link to={process.env.REACT_APP_MENU_AIR_LINK}><span className="txt">항공</span></Link></li>
                    <li id="n-gnb-hotel"><Link to={process.env.REACT_APP_MENU_HOTEL_LINK}><span className="txt">호텔</span></Link></li>
                    <li id="n-gnb-tna"><Link to={process.env.REACT_APP_MENU_TOUR_LINK}><span className="txt">투어&amp;티켓</span></Link></li>
                    <li id="n-gnb-pkg" className="on"><a style={{cursor:"pointer"}} onClick={() => {window.location.href = process.env.REACT_APP_MENU_PACKAGE_LINK}}><span className="txt">해외패키지</span></a></li>
                    <li id="n-gnb-promotion"><Link to={process.env.REACT_APP_MENU_PROMOTION_LINK}><span className="txt">기획전</span></Link></li>
                    <li className="b-total-nav">
                        <Link to={()=>false} onClick={onMenuClick} className={clickStatus ? "on":""}><span className="a11y">전체메뉴</span></Link>
                        <div className="w-nav-gnb-total" style={clickStatus ? {height:`692px`}:{height:`0px`}}>
                            <ul className="nav-gnb-total">
                                {
                                    menuEle
                                }
                            </ul>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

    )
}

export default Menu