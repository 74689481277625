import PaymentProcessPopUp from "../../component/reservation/payment-panel/PaymentProcessPopUp";
import React from "react";
import useTwoPaymentReservation from "../../../usecase/reservation/useTwoPaymentReservation";
import InicisComponent from "../../component/payment/InicisComponent";

/*
* 2회 결제시 이동 하는 페이지.
*
* */

export default function TwoPartInicisContainer() {
    const {
        noneTargetPrice,
        targetPrice,
        onPayment,
        env,
        paymentData,
        onCloseHandler,
        openPaymentModel,
        setOpenPaymentModel,
        revEmail,
    } = useTwoPaymentReservation()


    return (
        <>
            <PaymentProcessPopUp
                open={openPaymentModel}
                setOpen={setOpenPaymentModel}
                onClose={onCloseHandler}
                targetPrice={targetPrice}
                onPayment={onPayment}
                noneTargetPrice={noneTargetPrice}
                step={2}
            />

            <InicisComponent
                isRepay={true}
                payData={{
                    ...paymentData,
                    isTwoPay: true
                }}
                releaseURL={env.releaseURL}
                revEmail={revEmail}
            />

        </>
    )
}