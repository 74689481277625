import styles from "../../../../../../reservation/page/Reservation.module.css";
import React from "react";

export default function CancellationRefundPolicy({
                                                     open,
                                                     terms,
                                                     handleOpenContents,
                                                     termsCtx,
                                                 }) {

    const supplierName = "여행사명";

    return (
        <>
            {/*취소 및 환불 규정(필수)*/}
            <div className="pkg-tit-term-cont">
                <h3 className="title between-box fc closed">
                    <a className={`o-acdi-click ${open ? 'o-ac-on' : ''}`}
                       onClick={handleOpenContents} id="cancellationRefundPolicyToggle">
                        취소 및 환불 규정(필수)
                    </a>
                    <div className="agr-area">
                                    <span className="check-group">
                                        <input type="checkbox" className="hidden_check" id="cancellationRefundPolicy"
                                               name="cancellationRefundPolicy" checked={terms.cancellationRefundPolicy} onChange={termsCtx.onCheck}/>
                                        <label htmlFor="cancellationRefundPolicy" className="label-check">동의</label>
                                    </span>
                    </div>
                </h3>
            </div>
            <div className={`term-cont-box o-customscrollbar ${styles.term} ${styles.hidden} ${open ? styles.open : ''}`} id="tm-2" style={{display: "block"}}>
                <ul className="list-dot stPkg">
                    <li>예약 상품을 취소하실 경우, 판매자가 제공한 약관에 의거하여 적용됩니다. 취소 규정은 각 상품 상세 페이지에서 확인 가능합니다.</li>
                    <li>취소 요청 시 판매자의 영업시간 내 취소 접수가 가능하며 토/일요일 및 공휴일 등 영업시간 외 취소 요청은 익영업일로 적용됩니다. (영업시간 : 월~금
                        10:00~17:00, 토/일요일 및 국가 공휴일 제외)
                    </li>
                    <li>PRIVIA 여행은 예약 대행사로서 천재지변, 항공사 사정 등의 여러 사유로 발생한 직,간접적인 예약 고객님의 피해에 대하여 배상의 책임을 지지
                        않습니다.
                    </li>
                </ul>
            </div>
        </>
    );
}