export class EventBase {
    constructor(name, payload) {
        this.name = name;
        this.payload = payload;
    }

    getName() {
        return this.name;
    }

    getPayload() {
        return this.payload;
    }
}

export default class EventManager {
    constructor() {
        this.events = {};
    }

    subscribe(eventName, callback) {
        if (!this.events[eventName]) {
            this.events[eventName] = [];
        }
        this.events[eventName].push(callback);
    }

    unsubscribe(eventName, handlerToString) {
        if (this.events[eventName]) {
            this.events[eventName] = this.events[eventName]
                .filter(cb => cb.toString() !== handlerToString);
        }
    }

    publish(event) {
        // console.log('publishing event', event);
        const eventCallbacks = this.events[event.getName()];
        if (eventCallbacks) {
            eventCallbacks.forEach(callback => callback(event));
        }
    }
}