import React from "react";
import BenefitDialog from "./BenefitDialog";
import {formatNumber} from "../../../../../../utils/formatNumber";

export default function BenefitDiscountItem({
                                                enable,
                                                title,
                                                modalTitle,
                                                children,
                                                buttonTitle,
                                                adaptedMoney,
                                                onApply,
                                                onClose,
                                                onOpen
                                            }) {
    const btnTitle = buttonTitle ?? "조회/적용"
    const money = adaptedMoney ?? 0
    const [openModal, setOpenModal] = React.useState(false)
    if (!enable) return
    function getInputCls() {
        if (money > 0) {
            return "input-base type01 w140 state-checked"
        }
        return "input-base type01 w140"
    }

    function handleClickOpen(e) {
        e.stopPropagation();
        if (!onOpen) {
            setOpenModal(true)
            return
        }
        setOpenModal(onOpen())
    }

    return (
        <div className="between-box fc inner-price-li">
            <div className="between-box fc">
                <div className="price_tt">
                    {title}
                </div>
                <span className="btn-base btn-st-sfull btn-bg-gr">
                       <button type="button" onClick={handleClickOpen}>
                         {btnTitle}
                       </button>
                    <BenefitDialog
                        open={openModal}
                        modalTitle={modalTitle}
                        setOpen={setOpenModal}
                        onClose={onClose}
                        onApply={onApply}
                    >
                     {children}
                    </BenefitDialog>
                </span>
            </div>
            <div className="input-area">
                <input type="text"
                       disabled
                       className={getInputCls()}
                       value={formatNumber(money)}/>원
            </div>
        </div>
    )

}