import { getCookie } from "../../common/util/Cookie";

export const getAxiosHeaders = () => {
    return {
        'Authorization': 'Bearer ' + process.env.REACT_APP_API_KEY,
        'Channel-Id': 'PRIVIA',
        'Content-Type': "application/json"
    };
}


export const getAxiosHeadersWithToken = () => {
    const token = getCookie('PV_C_INFO')
    return {
        'Authorization': `Bearer ${token}`,
        'Channel-Id': 'PRIVIA',
        'Content-Type': "application/json"
    };
}