export default function useCookie() {
    function getParseCookie(name) {
        const nameEQ = name + "=";
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }

    function getCookie(name) {
        const cookie = getParseCookie(name);
        return cookie ? JSON.parse(cookie) : null;
    }

    function setCookie(name, value) {
        const data = JSON.stringify(value)
        document.cookie = `${name}=${data}; domain=.priviatravel.com; path=/`
    }


    return {
        getCookie,
        setCookie
    }
}