import {formatNumber} from "../../../../utils/formatNumber";

export default class UserTicketEntity {

    constructor(data) {
        this.data = data;
    }

    isEnable() {
        if (!this.data) return false
        return true
    }

    isLoaded() {
        if (!this.data) return false
        return this.data['loading'] === false
    }

    getTicketSize() {
        if (!this.data) return 0
        return this.data['ticketList'].length
    }

    getTicketOptionList() {
        if (!this.data) return []

        function getLabel(ticket) {
            return ticket.hccVoucherNumber + ' | ' + formatNumber(ticket.hccVoucherAbleAmount) + '원 | ' + formatNumber(ticket.hccVoucherAbleRemaining) + '원'
        }


        const list = this.data['ticketList']
        return list.map((item) => {
            return {
                id: item['voucherNumber'],
                label: getLabel(item),
                value: item['voucherNumber'],
                amountToUse: 0,// 사용할 금액
                amount: item['hccVoucherAbleRemaining'], // 금액 총액
                maxAmount: item['hccVoucherAbleAmount'], // 사용 금액 총액
                useAmount: 0,// 사용설정 된 금액.
                data: item,
                selected: false
            }
        })
    }

}