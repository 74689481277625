import {useEffect, useRef, useState} from 'react';

const useFormWithValidation = (initialValidationRules = {}, travelerAddFormId) => {
    const [formValues, setFormValues] = useState({});
    const [errors, setErrors] = useState({});
    const [validationRules] = useState({
        ...initialValidationRules,
        koreanName: /^[가-힣]+$/,
        englishOnly: /^[A-Za-z\s]+$/,
        phoneNumber: /^01(?:0|1|[6-9])-(?:\d{3}|\d{4})-\d{4}$/,
        email:  /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
        birth: /^(19|20)\d{2}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])$/,
    });

    const inputRefs = useRef({});  // New

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormValues({ ...formValues, [id.includes("gender") ? id.split("-")[0] : id]: value });
        inputRefs.current[id] = e.target;  // New
        // validateInput(e.target);
    };

    useEffect(() => {
        Object.entries(formValues).forEach(([id, value]) => {
            const input = document.getElementById(id);
            if (!input) {
                return
            }
            validateInput(input);
        });
    }, [formValues]);

    const validateInput = (input) => {
        const className = Array.from(input.classList).find((cls) => validationRules.hasOwnProperty(cls));
        const validationRegex = validationRules[className];

        if (!input.value) {
            setErrors((prevErrors) => ({ ...prevErrors, [input.id]: 'require' }));
            // inputRefs.current[input.id] && inputRefs.current[input.id].focus();
        } else if (validationRegex && !validationRegex.test(input.value)) {
            setErrors((prevErrors) => ({ ...prevErrors, [input.id]: 'invalidate' }));
            // inputRefs.current[input.id] && inputRefs.current[input.id].focus();
        } else {
            setErrors((prevErrors) => ({ ...prevErrors, [input.id]: false }));
        }
    };

    const validateForm = () => {
        const newErrors = {};
        Object.keys(validationRules).forEach((ruleKey) => {

            let inputLists
            if (travelerAddFormId) {
                let formElement = document.getElementById(travelerAddFormId);
                inputLists = formElement.getElementsByClassName(ruleKey);
            } else {
                inputLists = document.getElementsByClassName(ruleKey);
            }

            console.log(inputLists);
            if (!inputLists) {
                return
            }

            Array.prototype.forEach.call(inputLists,(item) => {
                let value = item.value;
                let id = item.id;
                if (item.value) {
                    const className = Array.from(item.classList).find((cls) => validationRules.hasOwnProperty(cls));
                    const validationRegex = validationRules[className];

                     if (value && validationRegex && !validationRegex.test(value)) {
                        newErrors[id] = 'invalidate';
                    }
                } else {
                    newErrors[id] = 'require';
                }
            });
        });

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const setFormData = (choiceData, travelerId) =>{
        if (Object.keys(choiceData).length === 0)    return;
        setFormValues({
            ...formValues,
            [travelerId + '_koreanName']:choiceData.koreanName,
            [travelerId + '_englishSurname']:choiceData.englishFamilyName,
            [travelerId + '_englishGivenName']:choiceData.englishFirstName,
            [travelerId + '_travelerPhone']:choiceData.mobileNumber,
            [travelerId + '_travelerEmail']: !choiceData.emailAddress ? '' : choiceData.emailAddress,
            [travelerId + '_birth']:choiceData.birth,
            [travelerId + '_gender']:choiceData.sex,
        })
    }

  return { formValues, errors, setFormData , handleInputChange, validateForm, setFormValues, setErrors };
};

export default useFormWithValidation;