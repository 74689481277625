import React, {useEffect, useRef, useState} from "react";
import SnsShare from "./SnsShare";
import moment from "moment/moment";
import {shallowEqual, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {formatNumber, getDiscountAmount, getTotalAmount} from "../../utils/formatNumber";
import {goReservationLoginPage} from "../../common/util/RedirectLogin";
import ChangeDeparturePopup from "../popup/ChangeDeparturePopup";
import {createTheme, Dialog, DialogContent} from "@mui/material";
import SaleProductPriceSpan from "../../kyb/app/view/component/product/SaleProductPriceSpan";

// find: 예약하기 /detail

const FloatingBox = (props) => {

    const frontServerUrl = process.env.REACT_APP_FRONT_URL;

    const [discountAmount , setDiscountAmount] = useState(0);
    const [totalAmount , setTotalAmount] = useState(0);
    const adultQuantityRef = useRef(null);
    const childQuantityRef = useRef(null);
    const infantQuantityRef = useRef(null);

    let saleProduct = useSelector((state) => state.productReducer.saleProduct, shallowEqual);
    let userInfo = useSelector((state) => state.headerReducer.userInfo, shallowEqual);
    let detailBannerList = useSelector((state) => state.detailReducer.detailBannerList, shallowEqual);

    const [open, setOpen] = useState(false);
    const [scroll, setScroll] = useState('paper');
    const theme = createTheme({
        components: {
            MuiDialogContent: {
                styleOverrides: {   // Name of the slot
                    root: { // Name of the rule
                        overflowX: 'hidden',
                        padding: '0px',
                        borderTop: '0px',
                        borderBottom: '0px'
                    },
                }
            }
        }
    })

    useEffect(() => {
        adultQuantityRef.current.value = 1;
        childQuantityRef.current.value = 0;
        infantQuantityRef.current.value = 0;
        calculationTotalAmount();

        loadFloatBox();
        window.addEventListener('scroll', handleFloatingScroll);
        return () => {
            window.removeEventListener('scroll', handleFloatingScroll);
        };
    }, [saleProduct]);

    useEffect(() => {
        loadFloatBox();
    }, [detailBannerList]);

    const loadFloatBox = () => {
        const uiFloatBoxWrap = document.querySelector(".ui-floatbox-wrap");
        const pkgProductInfo = document.querySelector(".pkg-product-info");
        uiFloatBoxWrap.style.marginTop = `${-1 * pkgProductInfo.clientHeight}px`;
    }

    const handleFloatingScroll = () => {
        let scroll = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop;
        let pkgArticleTabOn = document.querySelector('.pkg-article.tab-on');
        let pkgProductInfo = document.querySelector('.pkg-product-info');
        let uiFloatBoxWrap = document.querySelector('.ui-floatbox-wrap');

        // floating박스
        // 플로팅 박스가 처음엔 고정이였다가 스크롤을 내려서볼때 플로팅 박스가 가려지면 아래에 위치(fixed-floatbox 클래스)
        if (scroll > pkgArticleTabOn.getBoundingClientRect().top - 60) {
            uiFloatBoxWrap.classList.add("fixed-floatbox");
        }
        // console.log("pkgProductInfo.clientHeight : " + pkgProductInfo.clientHeight);
        if (scroll > pkgProductInfo.clientHeight + 230) {
            uiFloatBoxWrap.classList.add("sticky-float");
            uiFloatBoxWrap.classList.remove("fixed-floatbox");
        } else if (scroll < pkgProductInfo.clientHeight + 230) {
            uiFloatBoxWrap.classList.remove("sticky-float");
            uiFloatBoxWrap.classList.remove("fixed-floatbox");
        }
    };

    const handleClickOpen = (scrollType) => () => {
        setOpen(true);
        setScroll(scrollType);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const clickReservation = () => {
        if (saleProduct.remainSeatCount === 0) {
            if (["MODE", "KAL"].includes(saleProduct.supplierCode)) {
                alert('해당 상품은 예약 가능한 좌석이 없습니다.');
                return;
            }
        }
        if (userInfo && userInfo.memberNumber) {
            //회원 예약 페이지로 이동
            window.location.href = `/representative/${saleProduct.representProductCode}/sale/${saleProduct.saleProductCode}/reservation?adultCount=${adultQuantityRef.current.value}&childrenCount=${childQuantityRef.current.value}&infantCount=${infantQuantityRef.current.value}`;
        } else {
            //로그인 페이지로 이동
            let returnUrl = frontServerUrl + `/front/representative/${saleProduct.representProductCode}/sale/${saleProduct.saleProductCode}/reservation`+encodeURIComponent(`?adultCount=${adultQuantityRef.current.value}&childrenCount=${childQuantityRef.current.value}&infantCount=${infantQuantityRef.current.value}`);
            goReservationLoginPage(returnUrl);
        }
    }

    const clickDecreaseBtn = (e, division) => {
        const adultQuantity = Number(adultQuantityRef.current.value);
        const childQuantity = Number(childQuantityRef.current.value);
        const infantQuantity = Number(infantQuantityRef.current.value);
        //인원 삭제
        if (division === 'adult' && adultQuantity < 2) {
            return;
        } else if (division === 'child' && childQuantity === 0) {
            return
        } else if (division === 'infant' && infantQuantity === 0) {
            return;
        }
        if (division === 'adult') {
            adultQuantityRef.current.value = adultQuantity - 1;
        } else if (division === 'child') {
            childQuantityRef.current.value = childQuantity - 1;
        } else if (division === 'infant') {
            infantQuantityRef.current.value = infantQuantity - 1;
        }
        calculationTotalAmount();
    }

    const clickIncreaseBtn = (e, division) => {
        //인원 추가
        const adultQuantity = Number(adultQuantityRef.current.value);
        const childQuantity = Number(childQuantityRef.current.value);
        const infantQuantity = Number(infantQuantityRef.current.value);
        if (division === 'adult' && adultQuantity > 9) {
            return;
        } else if (division === 'child' && childQuantity > 9) {
            return
        } else if (division === 'infant' && infantQuantity > 9) {
            return;
        }
        if (division === 'adult') {
            adultQuantityRef.current.value = Number(adultQuantityRef.current.value) + 1;
        } else if (division === 'child') {
            childQuantityRef.current.value = Number(childQuantityRef.current.value) + 1;
        } else if (division === 'infant') {
            infantQuantityRef.current.value = Number(infantQuantityRef.current.value) + 1;
        }
        calculationTotalAmount();
    }

    const calculationTotalAmount = () => {
        //가격 계산
        setDiscountAmount(getDiscountAmount(
            saleProduct.priceAmount, saleProduct.taxDutyAmount, saleProduct.fuelSurchargeAmount, adultQuantityRef.current.value,
            saleProduct.childPriceAmount, saleProduct.childTaxDutyAmount, saleProduct.childFuelSurchargeAmount, childQuantityRef.current.value,
            saleProduct.infantPriceAmount, saleProduct.infantTaxDutyAmount, saleProduct.infantFuelSurchargeAmount, infantQuantityRef.current.value
            ));
        setTotalAmount(getTotalAmount(
            saleProduct.priceAmount, saleProduct.taxDutyAmount, saleProduct.fuelSurchargeAmount, adultQuantityRef.current.value,
            saleProduct.childPriceAmount, saleProduct.childTaxDutyAmount, saleProduct.childFuelSurchargeAmount, childQuantityRef.current.value,
            saleProduct.infantPriceAmount, saleProduct.infantTaxDutyAmount, saleProduct.infantFuelSurchargeAmount, infantQuantityRef.current.value
        ));
    }

    return (
        <div className="ui-floatbox-wrap">
            <SnsShare />

            <div className="floating-inner">
                <div className="floating-title">인원 선택</div>
                <ul className="pd-headcnt-info">
                    <li className="between-box fc">
                        <div className="headcnt-cell">
                            <div className="headcnt-type">성인 1인</div>
                            <span className="price"><em>{formatNumber(saleProduct.priceAmount + saleProduct.fuelSurchargeAmount + saleProduct.taxDutyAmount)}</em>원</span>
                        </div>
                        <div className="headcnt-btn">
                            <div className="count-wrap" data-ctn='{"min":1, "max":10}'>
                                <span className="count-btn decrease-btn" onClick={(e) => { clickDecreaseBtn(e, "adult") }}></span>
                                <input type="text" readOnly className="input-base type00 count-num" ref={adultQuantityRef} />
                                <span className="count-btn increase-btn" onClick={(e) => { clickIncreaseBtn(e, "adult") }}></span>
                            </div>
                        </div>
                        <p className="desc sm wfull">
                            유류할증료 {formatNumber(saleProduct.fuelSurchargeAmount)}원
                            <br />
                            제세공과금 {formatNumber(saleProduct.taxDutyAmount)}원
                        </p>
                    </li>
                    <li className="between-box fc">
                        <div className="headcnt-cell">
                            <div className="headcnt-type">아동 1인
                                <span className="help-tooltip small darker">
                                    <span className="over">?</span>
                                    <span className="cont" style={{width: '175px'}}>만12세 미만<br />{moment(saleProduct.departureDay).add('-12','y').format('YYYY.MM.DD')} ~ {moment(saleProduct.departureDay).add('-2','y').add('-1','d').format('YYYY.MM.DD')}</span>
                                </span>
                            </div>
                            <span className="price"><em>{formatNumber(saleProduct.childPriceAmount + saleProduct.childFuelSurchargeAmount + saleProduct.childTaxDutyAmount)}</em>원</span>
                        </div>
                        <div className="headcnt-btn">
                            <div className="count-wrap" data-ctn='{"min":0, "max":10}'>
                                <span className="decrease-btn count-btn" onClick={(e) => { clickDecreaseBtn(e, "child") }}></span>
                                <input type="text" readOnly className="input-base type00 count-num" ref={childQuantityRef} />
                                <span className="increase-btn count-btn" onClick={(e) => { clickIncreaseBtn(e, "child") }}></span>
                            </div>
                        </div>
                        <p className="desc sm wfull">
                            유류할증료 {formatNumber(saleProduct.childFuelSurchargeAmount)}원
                            <br />
                            제세공과금 {formatNumber(saleProduct.childTaxDutyAmount)}원
                        </p>
                    </li>
                    <li className="between-box fc">
                        <div className="headcnt-cell">
                            <div className="headcnt-type">유아 1인
                                <span className="help-tooltip small darker">
                                    <span className="over">?</span>
                                    <span className="cont" style={{width: '175px'}}>만2세 미만<br />{moment(saleProduct.departureDay).add('-2','y').format('YYYY.MM.DD')} ~ {moment().format('YYYY.MM.DD')}</span>
                                </span>
                            </div>
                            <span className="price"><em>{formatNumber(saleProduct.infantPriceAmount + saleProduct.infantFuelSurchargeAmount + saleProduct.infantTaxDutyAmount)}</em>원</span>
                        </div>
                        <div className="headcnt-btn">
                            <div className="count-wrap" data-ctn='{"min":0, "max":10}'>
                                <span className="decrease-btn count-btn" onClick={(e) => { clickDecreaseBtn(e, "infant") }}></span>
                                <input type="text" readOnly className="input-base type00 count-num" ref={infantQuantityRef} />
                                <span className="increase-btn count-btn" onClick={(e) => { clickIncreaseBtn(e, "infant") }}></span>
                            </div>
                        </div>
                        {(saleProduct.infantFuelSurchargeAmount > 0 || saleProduct.infantTaxDutyAmount > 0) &&
                            <p className="desc sm wfull">
                                유류할증료 {formatNumber(saleProduct.infantFuelSurchargeAmount)}원
                                <br />
                                제세공과금 {formatNumber(saleProduct.infantTaxDutyAmount)}원
                            </p>
                        }
                    </li>
                </ul>
                <div className="price-wrap">
                    <div className="between-box fc inner-price-li">
                        <SaleProductPriceSpan
                            saleProduct={saleProduct}
                            adultCount={adultQuantityRef?.current?.value || 0}
                            childCount={childQuantityRef?.current?.value || 0}
                            infantCount={infantQuantityRef?.current?.value || 0}
                        />
                    </div>
                    <div className="between-box fc inner-price-li">
                        <div className="price_tt">총 상품 금액</div>
                        <div className="sub-total-amt">
                            <span className="price"><em>{formatNumber(totalAmount)}</em>원</span>
                        </div>
                    </div>
                </div>
                <div className="btn-wrap">
                    <SubmitButton saleProduct={saleProduct} clickReservation={clickReservation}/>
                    {/*<span className="btn-base btn-st-bfull wfull btn-bg-pkg">*/}
                    {/*    <a style={{cursor:"pointer"}} onClick={clickReservation} className={saleProduct.supplierCode === 'MODE' && saleProduct.remainSeatCount === 0 ? `disabled` : ``}>*/}
                    {/*        {saleProduct ?*/}
                    {/*            saleProduct.reservationDivision === 'RESERVATION_CONFIRM' ?*/}
                    {/*                "예약하기" : (saleProduct.reservationDivision === 'RESERVATION_NORMAL' ? "예약하기" : "예약대기")*/}
                    {/*            : ''*/}
                    {/*        }*/}
                    {/*    </a>*/}
                    {/*</span>*/}
                    <span className="btn-base btn-st-bfull wfull btn-line-grl">
                        {/*{saleProduct.saleProductName.replace("#", "")}*/}
                        <Link to={process.env.REACT_APP_PRIVIA_BASE_URL
                            + "/customer/inquire/form?svcDivnCd=TRV0010300&catDivnCd=" + props.catDivnCd + "&prodCd=" + saleProduct.saleProductCode
                            + "&prodNm=" + unescape(saleProduct.saleProductName)} className="btn-base">문의하기</Link>
                    </span>
                </div>
            </div>

            <div className="floating-inner">
                <a style={{cursor:"pointer"}}>
                    <div className="between-box chg-date-wrap" onClick={handleClickOpen('paper')}>
                        <div className="tt">출발일 변경</div>
                        <div className="arrow">{moment(saleProduct.departureDay).format("YY/MM/DD")}</div>
                    </div>
                </a>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    scroll={scroll}
                    theme={theme}
                >
                    <div id className="popup-wrap" style={{width: '980px'}}>
                        <div className="heading">
                            <h2>다른 출발일 상품</h2>
                            <a style={{cursor:"pointer"}} className="btn_close" onClick={handleClose}>닫기</a>
                        </div>
                        <div className="content">
                            <div className="inner">
                                <DialogContent dividers={scroll === 'paper'} theme={theme}>
                                    <ChangeDeparturePopup />
                                </DialogContent>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </div>
        </div>
    );
};

export default FloatingBox;


function SubmitButton({saleProduct, clickReservation}) {

    if (!saleProduct) return null;
    const aStyle = {cursor: "pointer"};

    const {supplierCode, remainSeatCount, reservationDivision} = saleProduct;
    if (remainSeatCount === 0) {
        if (['MODE', "KAL"].includes(supplierCode)) {
            return (
                <span className="btn-base btn-st-bfull wfull btn-bg-pkg">
                    <a style={aStyle} className={"disabled"} onClick={clickReservation}>
                        예약대기
                    </a>
               </span>
            )
        }
    }

    let viewText = '예약대기'
    switch (reservationDivision) {
        case 'RESERVATION_CONFIRM':
            viewText = '예약하기'
            break;
        case 'RESERVATION_NORMAL':
            viewText = '예약하기'
            break;
    }

    return (
        <span className="btn-base btn-st-bfull wfull btn-bg-pkg">
            <a style={aStyle} onClick={clickReservation}>
                {viewText}
            </a>
        </span>
    )
}