const SET_CALENDAR_HOLIDAY = "CALENDAR/SET_CALENDAR_HOLIDAY";

export const setCalendarHoliday = (holiday) => ({ type: SET_CALENDAR_HOLIDAY,holiday });

const initialState = {
    holiday : null,
};

const calendarState = (state = initialState , action) => {
    switch (action.type) {
        case SET_CALENDAR_HOLIDAY :
            return {
                ...state,
                holiday: action.holiday
            }
        default:
            return state;
    }
}

export default calendarState;
