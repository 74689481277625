import React, { useState } from "react";

import Modal from "@mui/material/Modal";
import { TableContainer } from "@mui/material";
import TravelerAddPopup from "./TravelerAddPopup";
import { autoHyphenNotInput } from "../../../../infra/for-adaptation/form-utils"

export default function TravelerSelectModal({
    isOpen,
    onClose,
    traveler,
    selectTraveler,
    travelerSelectList,
    addTravelerInfoData,
    title,
}) {
    const handleChoice = (e, selectedTraveler) => {
        selectTraveler({
            ...traveler,
            title: title,
            koreanFullName: selectedTraveler.korName,
            englishFamilyName: selectedTraveler.engLastName,
            englishGivenName: selectedTraveler.engFirstName,
            sex: selectedTraveler.sex === '남' ? 'M' : 'F',
            birth: selectedTraveler.birth ? selectedTraveler.birth.replaceAll('/', '') : '',
            phone: selectedTraveler.mobileNo ? autoHyphenNotInput(selectedTraveler.mobileNo) : '',
            email: selectedTraveler.emailAddress ?? '',
        })
        onClose()
    }

    const [isOpenTravelerAdd, setIsOpenTravelerAdd] = useState(false)

    const handleAddOpen = () => setIsOpenTravelerAdd(true);
    const handleAddClose = () => setIsOpenTravelerAdd(false);


    const travelSelectElement = travelerSelectList && travelerSelectList.map((element, index) => {
        return (
            <tr key={index}>
                <td className="a-center ndlr">{index + 1}</td>
                <td className="a-center ndlr">{element.korName}</td>
                <td className="a-center ndlr">{`${element.engLastName} ${element.engLastName}`}</td>
                <td className="a-center ndlr">{element.sex === '여' ? '여자' : element.sex === '남' ? '남자' : null}</td>
                <td className="a-center ndlr">{element.birth}</td>
                <td className="a-center ndlr">
                    <span className="btn-base btn-st-sfull btn-bg-gr">
                        <button onClick={(e) => handleChoice(e, element)} type="button"
                            style={{ minWidth: 'inherit' }}>선택</button>
                    </span>
                </td>
            </tr>
        )
    })

    return (
        <>
            <Modal open={isOpen} onClose={onClose}>
                <div className="popup-wrap" style={{ width: 650 }}>
                    {/* header */}
                    <div className="heading">
                        <h2>여행자 추가</h2>
                        <button className="btn_close" onClick={onClose}>닫기</button>
                    </div>
                    {/* header */}
                    {/* content */}
                    <div className="content">
                        <div className="inner">
                            {/* 상세 */}
                            <div className="btn-wrap a-right a-pt5 a-mb20">
                                <span className="btn-base btn-st-full btn-bg-gr w130">
                                    <button onClick={handleAddOpen}>여행자 추가</button>
                                </span>
                            </div>
                            <div className="mytraveler-detail pkg-over-scroll mCustomScrollbar _mCS_1 mCS-autoHide"
                                style={{ position: 'relative' }}>
                                <div className="mCustomScrollBox mCS-minimal-dark mCSB_vertical mCSB_outside"
                                    tabIndex={0}>
                                    <div className="mCSB_container" style={{ position: 'relative', top: 0, left: 0 }}
                                        dir="ltr">
                                        <section className="lpu-item-sec">
                                            <div className="lpu-sub-item">
                                                <TableContainer sx={{ maxHeight: 580 }}>
                                                    <table summary="여행자 목록" className="tbl-style list">
                                                        <caption>번호, 한국이름, 영문이름, 성별, 생년월일, 선택버튼이 포함된 여행자 목록 대한 테이블
                                                        </caption>
                                                        <colgroup>
                                                            <col width={84} />
                                                            <col width={70} />
                                                            <col width={159} />
                                                            <col width={40} />
                                                            <col width="*" />
                                                            <col width={80} />
                                                        </colgroup>
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">No</th>
                                                                <th scope="col">한글이름</th>
                                                                <th scope="col">영문이름</th>
                                                                <th scope="col">성별</th>
                                                                <th scope="col">생년월일</th>
                                                                <th scope="col">선택</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {travelSelectElement}
                                                        </tbody>
                                                    </table>
                                                </TableContainer>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                            {/* 상세 */}
                        </div>
                    </div>
                    {/* content */}
                </div>
            </Modal>
            <TravelerAddPopup
                isOpenTravelerAdd={isOpenTravelerAdd}
                handleClose={handleAddClose}
                addTravelerInfoData={addTravelerInfoData}
            />
        </>
    )
}