import {useSearchParams} from "react-router-dom";
import {useEffect} from "react";
import useTwoPaymentReservation from "../../kyb/app/usecase/reservation/useTwoPaymentReservation";

const releaseURL = process.env.REACT_APP_INICIS_URL;

const Fail = () => {
    const [searchParams] = useSearchParams();
    // let errCode = searchParams.get("errCode");
    let errMessage = searchParams.get("errMessage");
    let failCount = searchParams.get("failCount");
    const {
        reservationCtx,
    } = useTwoPaymentReservation()
    // const nav = useNavigate()

    useEffect(() => {
        if (reservationCtx.loading) return;
        // failCount 있으면 무조건 2차 결제 실패임 ->  2차 결제 실패시..
        if (failCount && failCount >= 3) {
            // useTwoPaymentReservation 에서 얼럿하기 때문에 생략
            window.location.href = reservationCtx.getRedirectReservationUrl({
                frontPaymentStep: "TwoPay"
            });
        } else {
            // 1차 결제 실패
            const url = reservationCtx.getRedirectReservationUrl()
            if (url) {
                window.location.href = url;
            } else {
                window.location.href = '/';
            }
        }
    }, [reservationCtx.loading]);
    return null;
}

export default Fail;