import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {
    getAmountListSelector,
    getInicisEnvSelector,
    getUsingBenefitSelector,
    setAmountDictSelector,
    setPaymentPriceState,
    setPaymentStepSelector,
    setPaymentTypeState,
    setUpAmountCountSelector,
} from "../../state/reservation/PaymentPriceState";

import {getPriceDictSelector} from "../../state/reservation/PriceSelectors";
import {getInicisPayDataSelector, getPaymentSubmitDataSelector} from "../../state/reservation/PaymentSelector";
import {setCardOptionSelector} from "../../state/reservation/benefit/CardSelector";
import {DiscountType} from "../../../domain/service/payment/PaymentEnum";
import {setInitFamilySelector} from "../../state/reservation/benefit/FamilySelectors";
import {getCouponSelector, setEnableCouponSelector} from "../../state/reservation/benefit/CouponSelector";

import {
    getDiscountDictSelector,
    getDiscountValueDictSelector,
    removeDiscountSelectorV2,
    setDiscountSelectorV2
} from "../../state/reservation/DiscountSelector";

export default function usePaymentPrice() {
    const [paymentPrice, _] = useRecoilState(setPaymentPriceState)
    const amountList = useRecoilValue(getAmountListSelector)
    const discountDict = useRecoilValue(getDiscountDictSelector)
    const [paymentType, setPaymentType] = useRecoilState(setPaymentTypeState)
    const setUpAmountCount = useSetRecoilState(setUpAmountCountSelector)
    const discountValueDict = useRecoilValue(getDiscountValueDictSelector)
    const priceDict = useRecoilValue(getPriceDictSelector)
    const [cardOption, setCardOption] = useRecoilState(setCardOptionSelector)
    const inicisEnv = useRecoilValue(getInicisEnvSelector)
    const setPaymentStep = useSetRecoilState(setPaymentStepSelector)
    const paymentSubmitData = useRecoilValue(getPaymentSubmitDataSelector)
    const inicisPaySubmitData = useRecoilValue(getInicisPayDataSelector)
    const removeDiscountV2 = useSetRecoilState(removeDiscountSelectorV2)
    const setDiscountV2 = useSetRecoilState(setDiscountSelectorV2)
    const usingBenefit = useRecoilValue(getUsingBenefitSelector)
    const [amountDict, setAmountDict] = useRecoilState(setAmountDictSelector)
    const setInitFamily = useSetRecoilState(setInitFamilySelector)
    const setEnableCoupon = useSetRecoilState(setEnableCouponSelector)
    const coupon = useRecoilValue(getCouponSelector)
    const user = paymentPrice.user


    function isSetDiscount(step, kind) {
        function getMessage(kind) {
            switch (kind) {
                case DiscountType.Coupon:
                    return '쿠폰'
                case DiscountType.RedVoucher:
                    return 'Red Voucher'
                case DiscountType.GreenVoucher:
                    return 'Green Voucher'
                case DiscountType.Ticket:
                    return '이용권'
                case DiscountType.SummitVoucher:
                    return 'Summit Voucher'
                case DiscountType.RedVoucher4:
                    return 'Red Voucher4'
                case DiscountType.RedVoucherStrip:
                    return 'Red Voucher Strip'
                default:
                    return '다른 혜택'
            }
        }

        if (discountDict[step] === undefined) {
            return {
                isSet: false,
                equal: false,
                message: ''
            }
        }
        if (discountDict[step] !== undefined) {
            return {
                isSet: true,
                equal: discountDict[step] === kind,
                message: getMessage(kind) + "은 " + getMessage(discountDict[step]) + "과 동시 사용이 불가합니다. " + getMessage(kind) + "을 적용하시겠습니까?"
            }
        }
    }

    return {
        amountList,
        paymentPrice,
        setDiscountV2,
        removeDiscountV2,
        paymentType,
        setPaymentType,
        setUpAmountCount,
        discountDict,
        discountValueDict,
        priceDict,
        cardOption,
        setCardOption,
        inicisEnv,
        setPaymentStep,
        paymentSubmitData,
        inicisPaySubmitData,
        usingBenefit,
        isSetDiscount,
        setAmountDict,
        setInitFamily,
        setEnableCoupon,
        coupon,
        user,
    }
}