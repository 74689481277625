import React, {useEffect,  useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isHeaderStateTrue , isHeaderStateFalse} from "../../redux/module/common/HeaderReducer";
import {Link} from "react-router-dom";
import "./TopSlider.css"

const TopSlider = () =>{
    const dispatch = useDispatch();
    const mainSelector = useSelector(state => state.mainReducer)

    const [state , setState] = useState("button0")
    const [style , setStyle] = useState({transform : `translate3d(0px, 0px, 0px)`})
    const [rightStyle , setRightStyle] = useState({transform : `translate3d(0px, 0px, 0px)`})
    const [rStaet , setRstate ] = useState("rButton01")
    const [arrayCount , setArrayCount] = useState(0)
    const divRef = useRef(null)
    const [isPoint , setIsPoint] = useState(false)


    const setPointHeader = () =>{
        const isScrollPoint = window.scrollY > divRef.current.offsetTop
        if(isScrollPoint !== isPoint) {
            setIsPoint(isScrollPoint);
            dispatch(isScrollPoint ? isHeaderStateTrue() : isHeaderStateFalse())
        }
    }


    useEffect(()=>{
        //Layout.js 에서 스크롤 처리 하도록 변경
        // window.addEventListener("scroll", setPointHeader)
        // return () =>{
        //     window.removeEventListener("scroll", setPointHeader)
        // }
    },[isPoint])

    const handelClick = (e , state) => {
        if(state === `button0`){
            setState(`button0`)
            setStyle({transform : `translate3d(0px, 0px, 0px)`  , transitionDuration : `300ms`})
        }else if (state === `button1`){
            setState(`button1`)
            setStyle({transform : `translate3d(-390px, 0px, 0px)` , transitionDuration : `300ms`})
        }
    }

    const handleRightButton = (e , state) =>{
        switch (state) {
            case `rButton01`:
                setRightStyle({transform : `translate3d(0px, 0px, 0px)` , transitionDuration : `300ms`})
                setRstate(`rButton01`)
                setArrayCount(0)
                break;
            case `rButton02`:
                setRightStyle({transform : `translate3d(-780px, 0px, 0px)` , transitionDuration : `300ms`})
                setRstate(`rButton02`)
                setArrayCount(1)
                break;
            case `rButton03`:
                setRightStyle({transform : `translate3d(-1560px, 0px, 0px)` , transitionDuration : `300ms`})
                setRstate(`rButton03`)
                setArrayCount(2)
                break;
            case `rButton04`:
                setRightStyle({transform : `translate3d(-2340px, 0px, 0px)` , transitionDuration : `300ms`})
                setRstate(`rButton04`)
                setArrayCount(3)
                break;
            default:
                break;
        }
    }

    const arrayHandleButton = () =>{
        let count = arrayCount +1
        if(count === 0){
            setRightStyle({transform : `translate3d(0px, 0px, 0px)` , transitionDuration : `300ms`})
            setRstate(`rButton01`)
        }
        else if (count === 1){
            setRightStyle({transform : `translate3d(-780px, 0px, 0px)` , transitionDuration : `300ms`})
            setRstate(`rButton02`)
        } else if (count ===2) {
            setRightStyle({transform : `translate3d(-1560px, 0px, 0px)` , transitionDuration : `300ms`})
            setRstate(`rButton03`)
        } else if (count === 3) {
            setRightStyle({transform : `translate3d(-2340px, 0px, 0px)` , transitionDuration : `300ms`})
            setRstate(`rButton04`)
        } else if (count > 3){
            setRightStyle({transform : `translate3d(0px, 0px, 0px)` , transitionDuration : `300ms`})
            setRstate(`rButton01`)
            setArrayCount(0)
            count = 0;
        }
        setArrayCount(count)
    }



    const BannerEle = mainSelector.resData && mainSelector.resData.pcLeftBanner && mainSelector.resData.pcLeftBanner.map((value, index) => {
        if(index <= 2) return <Banner key={index} title={value.title} imageUrl={value.ImageUrl} linkUrl={value.linkUrl}/>
    })
    const BannerTitle = mainSelector.resData && mainSelector.resData.pcLeftBanner && mainSelector.resData.pcLeftBanner.map((value , index)=>{

        if(index === 0){
            return (
                <div key={index} className={state === `button0` ? "swiper-slide swiper-slide-active swiper-slide-thumb-active" : "swiper-slide swiper-slide-next"} style={{width : `195px`}} onClick={(e)=>handelClick(e ,  `button${index}`)}>
                    <span>{value.title}</span>
                </div>
            )
        } else if(index === 1){
            return (
                <div key={index} className={state === `button1` ? "swiper-slide swiper-slide-active swiper-slide-thumb-active" : "swiper-slide swiper-slide-next"} style={{width : `195px`}} onClick={(e)=>handelClick(e ,  `button${index}`)}>
                    <span>{value.title}</span>
                </div>
            )
        }

    })

    const pcRightBanner = mainSelector.resData && mainSelector.resData.pcRightBanner && mainSelector.resData.pcRightBanner.map((value , index)=>{
        if(index <= 4){
            return(
                <div key={index} className="swiper-slide ga-promo swiper-slide-duplicate swiper-slide-prev" style={{width : `780px`}} >
                    <input type="hidden" className="ga-promo-data" />
                    <Link to={value.linkUrl} className="ga-promo-click">
                        {
                            value.ImageUrl !== 'http://image8.hanatour.com/product/img_no_M.gif' ?
                                (
                                    <span className="vis">
                                        <img src={`https:${value.ImageUrl}`} />
                                    </span>
                                ):
                                (
                                    <span className={"vis"}>
                                        <span className={"no-img"}></span>
                                    </span>
                                )
                        }
                    </Link>
                </div>
            )
        }
    })

    const pcRightBannerTitle = mainSelector.resData && mainSelector.resData.pcRightBanner && mainSelector.resData.pcRightBanner.map((value , index)=>{
        if(index === 0){
            return (
                <div key={index} className={rStaet === `rButton01` ? "swiper-slide swiper-slide-active swiper-slide-thumb-active" : "swiper-slide swiper-slide-active"} style={{width : `195px`}}  onClick={(e)=>handleRightButton(e , `rButton01`)}>
                    <span>{value.title}</span>
                </div>
            )
        } else if (index === 1) {
            return (
                <div key={index} className={rStaet === `rButton02` ? "swiper-slide swiper-slide-active swiper-slide-thumb-active" : "swiper-slide swiper-slide-active"} style={{width : `195px`}}  onClick={(e)=>handleRightButton(e , `rButton02`)}>
                    <span>{value.title}</span>
                </div>
            )
        } else if (index === 2) {
            return (
                <div key={index} className={rStaet === `rButton03` ? "swiper-slide swiper-slide-active swiper-slide-thumb-active" : "swiper-slide swiper-slide-active"} style={{width : `195px`}}  onClick={(e)=>handleRightButton(e , `rButton03`)}>
                    <span>{value.title}</span>
                </div>
            )
        } else if (index === 3) {
            return (
                <div key={index} className={rStaet === `rButton04` ? "swiper-slide swiper-slide-active swiper-slide-thumb-active" : "swiper-slide swiper-slide-active"} style={{width : `195px`}}  onClick={(e)=>handleRightButton(e , `rButton04`)}>
                    <span>{value.title}</span>
                </div>
            )
        }
    })


    function onClickHref(url) {
        return (e) => {
            e?.stopPropagation();
            window.location.href = url;
        }
    }

    const midBannerEle = mainSelector.resData && mainSelector.resData.pcMidBanner && mainSelector.resData.pcMidBanner.map((value , index)=>{
        if(!!value.imageUrl) {
            return (
                <li className="ga-promo list" key={index}>
                    <Link onClick={onClickHref(value.linkUrl)} className="ga-promo-click">
                        {
                            value.imageUrl && value.imageUrl  !== 'http://image8.hanatour.com/product/img_no_M.gif' ?
                                (
                                    <span className="vis">
                                    <img src={value.imageUrl && value.imageUrl} />
                                </span>
                                ):
                                (
                                    <span className={"vis"}>
                                    <span className={"no-img"}></span>
                                </span>
                                )
                        }

                        <span className="info-box">
                        <span className="tit"><span>{value.tltle && value.tltle && value.title && value.title}<br /> {value.content}</span></span>
                        <span className="date">예약기간<span className="txt-colon">:</span><em>{value.rangeStartDate}</em></span>
                    </span>
                    </Link>
                </li>
            )
        } else if(!!value.ImageUrl) {
            return (
                <li className="ga-promo list" key={index}>
                    <Link onClick={onClickHref(value.linkUrl)} className="ga-promo-click">
                        {
                            value.ImageUrl && value.ImageUrl  !== 'http://image8.hanatour.com/product/img_no_M.gif' ?
                                (
                                    <span className="vis">
                                    <img src={value.ImageUrl && value.ImageUrl} />
                                </span>
                                ):
                                (
                                    <span className={"vis"}>
                                    <span className={"no-img"}></span>
                                </span>
                                )
                        }

                        <span className="info-box">
                        <span className="tit"><span>{value.title && value.title}<br /> {value.content}</span></span>
                            {
                                value.rangeStartDate ? <span className="date">예약기간<span className="txt-colon">:</span><em>{value.rangeStartDate}</em></span> : null
                            }
                    </span>
                    </Link>
                </li>
            )
        }
    })


    return(
        <div className="s-rec-promotion-cont ly-inner" ref={divRef}>
            <div className="srpc-prom">
                <h2 className="pkg-tit-sec">
                    <a href="//www.priviatravel.com/promotion/promotionList?recomDivn=PACKAGE">
                        <span>PRIVIA 해외패키지 프로모션</span>
                    </a>
                </h2>
                <div className="srpc-left">
                    <div className="swiper listSwiper swiper-container-initialized swiper-container-horizontal">
                        <div className="swiper-wrapper" style={style}>
                            {BannerEle}
                        </div>
                    </div>
                    <div thumbsslider="" className="swiper thumbsSwiper srpcBtn swiper-container-initialized swiper-container-horizontal swiper-container-free-mode swiper-container-thumbs">
                        <div className="swiper-wrapper" style={{transitionDuration : `0ms`}}>
                            {BannerTitle}
                        </div>
                    </div>
                </div>

                <div className="srpc-right circle-btn-type">
                    <div className="swiper listSwiper swiper-container-initialized swiper-container-horizontal">
                        <div className="swiper-wrapper" style={rightStyle}>
                            {pcRightBanner}
                        </div>
                        <span className="swiper-notification" aria-live="assertive" aria-atomic="true"></span></div>
                            <div thumbsslider="" className="swiper rThumbsSwiper srpcBtn swiper-container-initialized swiper-container-horizontal swiper-container-free-mode swiper-container-thumbs">
                                <div className="swiper-wrapper" >
                                    {pcRightBannerTitle}
                                </div>
                                <span className="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
                            </div>
                    <div className="swiper-button-next" tabIndex="0" role="button" aria-label="Next slide" onClick={arrayHandleButton}></div>
                </div>
            </div>
            <ul className="list-item-pakage">
                {midBannerEle}
            </ul>
        </div>
    )
}


const Banner = (props) =>{

    function onClickHref(url) {
        return (e) => {
            e?.stopPropagation();
            window.location.href = url;
        }
    }
    return(
        <div className={props.state === `button01` ? `swiper-slide ga-promo swiper-slide-duplicate swiper-slide-prev swiper-slide-duplicate-next` : `swiper-slide ga-promo swiper-slide-duplicate swiper-slide-active`} data-swiper-slide-index="1" style={{width : `390px`}}>
            <Link onClick={onClickHref(props.linkUrl + `?searchKeyword=${props.title}`)} className="ga-promo-click">
                {
                    props.imageUrl !== 'http://image8.hanatour.com/product/img_no_M.gif' ?
                        (
                            <span className="vis">
                                        <img src={props.imageUrl} alt={props.title} />
                                    </span>
                        ):
                        (
                            <span className={"vis"}>
                                        <span className={"no-img"}></span>
                                    </span>
                        )
                }
                {/*<span className="vis">*/}
                {/*    <img src={`${props.imageUrl}`} alt={props.title} className="o-txt"/>*/}
                {/*</span>*/}
            </Link>
        </div>
    )
}


export default TopSlider