import useLoadProductDynamicList from "../../loader/useLoadProductDynamicList";
import useProgressPercent from "../../../infra/hooks/useProgressPercent";


export function useListProduct() {
    const {
        loading,
        error,
        isEmpty,
        productList,
        totalCount
    } = useLoadProductDynamicList()

    const {loadingPercent} = useProgressPercent({
        isEnd: !loading,
    })

    function getVisitCityName() {
        if (loading) return ''
        if (productList === null) return ''
        if (productList === undefined) return ''
        if (productList.length === 0) return ''
        return productList[0]['visitCityName'] || ''
    }

    return {
        productList,
        loading,
        isEmpty,
        loadingPercent,
        error,
        visitCityName: getVisitCityName(),
        productListTotalCount: totalCount
    }

}