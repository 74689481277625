const overlayStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 999999,
};


export default function InicisLoadingBox({open}) {
    if (!open) {
        return null
    }
    return (
        <div style={overlayStyle}>
            <div className="loading-pop" style={{display: 'block', opacity: 1.0, zIndex: 2147483647}}>
                <div className="loading-cont">
                    <div className="loading-pkg" style={{display: 'block', zIndex: 2147483647}}>
                        <div className="loading-bar"/>
                        <img className="pkg-pd-loading-icon"
                             src="http://static.priviatravel.com/images/front/travel/svg/ico-pkg-book-card.svg"
                             alt="카드혜택 아이콘"/>
                        <div className="loading-title">
                            예약 및 결제 중입니다.
                        </div>
                        <div className="loading-desc">
                            새로고침 또는 뒤로가기를 할 경우<br/>결제가 정상 진행되지 않습니다.<br/>잠시만 기다려주세요.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}