import moment from "moment/moment";


/**
 * 나도 안다. 뷰 모델을 도메인 모델 있는곳에 선언하는것이 기본적으로 설계 미스라는것을
 * 또한 도메인 모델이 뷰 모델을 반환 하면서, 도메인 모델이 뷰 모델을 의존 하는것이 설계 미스라는것
 * 그렇지만 타입 없는 자바스크립트에서 너무 헷갈려서 이렇게 한다.
 */
export class CouponOption {
    constructor(type, value, label, isDisabled, useAmount, selected) {
        this.type = type;
        this.value = value;
        this.label = label;
        this.isDisabled = isDisabled;
        this.useAmount = useAmount;
        this.selected = selected;
    }

    getType() {
        return this.type
    }

    isEqualType(type) {
        return this.type === type;
    }

    setOptionSelected(selected) {
        this.selected = selected
    }

    isSelected() {
        return this.selected;
    }

    setUseAmount(useAmount) {
        this.useAmount = useAmount;
    }

    getUseAmount() {
        return this.useAmount;
    }
}

export const CouponType = Object.freeze({
    Normal: "Normal",
    Plus: "Plus",
})

export default class UserCouponEntity {
    constructor(data) {
        this.data = data;
    }

    isLoaded() {
        if (this.data === null) {
            return false;
        }
        return this.data.loading === false;
    }

    static isUsableCoupon(coupon, representativeProductEntity, saleProductEntity) {


        const discountObjectAmount = representativeProductEntity.getDiscountObjectAmount()

        if (coupon.couponUseMinProductAmountDivision === "1" &&
            coupon.couponUseMinProductAmount > discountObjectAmount) {
            return true;
        }
        const saleProduct = saleProductEntity.getData()
        const representativeProduct = representativeProductEntity.getData()

        if (coupon.couponTravelStartDate &&
            moment(coupon.couponTravelStartDate).format("YYYYMMDD") > moment(saleProduct.departureDay).format("YYYYMMDD")) {
            return true;
        }
        if (coupon.couponTravelEndDate &&
            moment(coupon.couponTravelEndDate).format("YYYYMMDD") < moment(saleProduct.departureDay).format("YYYYMMDD")) {
            return false;
        }
        // console.log(index + " / representativeProduct : " + representativeProduct);
        if (!coupon.useScopes.at(0).scop7.includes("*") &&
            representativeProduct &&
            !coupon.useScopes.at(0).scop7.includes(saleProduct.saleProductCode)) {
            return false;
        } else if (!coupon.useScopes.at(0).scop6.includes("*") &&
            representativeProduct &&
            !coupon.useScopes.at(0).scop6.includes(saleProduct.representProductCode)) {
            // console.log("6"+index);
            return false;
        } else if (!coupon.useScopes.at(0).scop5.includes("*") &&
            representativeProduct &&
            !coupon.useScopes.at(0).scop5.includes(representativeProduct.visitCityCode)) {
            // console.log("5"+index);
            return false;
        } else if (!coupon.useScopes.at(0).scop4.includes("*") &&
            representativeProduct &&
            !coupon.useScopes.at(0).scop4.includes(representativeProduct.visitCountryCode)) {
            return false;
        } else if (!coupon.useScopes.at(0).scop3.includes("*") &&
            representativeProduct &&
            !coupon.useScopes.at(0).scop3.includes(representativeProduct.visitContinentCode)) {
            return false;
        } else if (!coupon.useScopes.at(0).scop2.includes("*") &&
            !coupon.useScopes.at(0).scop2.includes("PACKAGE")) {
            return false;
        } else if (!coupon.useScopes.at(0).scop1.includes("*") &&
            !coupon.useScopes.at(0).scop1.includes(saleProduct.supplierCode)) {
            return false;
        } else {
            return true;
        }
    }

    static getViewCouponName(coupon) {
        return coupon.couponName
            + ' (' + moment(coupon.couponIssueDate).format("YYYY-MM-DD") + '~'
            + (coupon.couponUseIssuedBaseDd ? moment(coupon.couponIssueDate).add(coupon.couponUseIssuedBaseDd, "d").format("YYYY-MM-DD") : moment(coupon.couponUseEndDate).format("YYYY-MM-DD")) + ')';
    }

    getCouponOptionList(representativeProduct, saleProduct) {
        if (this.data === null) {
            return [];
        }

        return this.data.couponList.map((coupon) => {
            let type = CouponType.Normal
            if (coupon['plusYn']) {
                type = CouponType.Plus
            }
            const value = coupon['couponIssueNumber']
            return new CouponOption(
                type,
                value,
                UserCouponEntity.getViewCouponName(coupon),
                !UserCouponEntity.isUsableCoupon(coupon, representativeProduct, saleProduct),
                0,
                false
            );
        });
    }


    static getEachAmount(coupon, totalProductPrice) {
        if (!coupon) return 0;
        if (coupon.couponDcDivision === "1") {
            return coupon.couponDcAmount
        }
        if (coupon.couponUseMaxDcAmount === 0 ||
            coupon.couponUseMaxDcAmount >
            Math.floor(totalProductPrice * coupon.couponDcRate * 0.01)) {
            return Math.floor(totalProductPrice * coupon.couponDcRate * 0.001) * 10;
        }
        return coupon.couponUseMaxDcAmount;
    }

    getCouponList(){
      return  this.data.couponList
    }

    // makeDiscount() {
    //     if (this.data === null) return null
    //     return new CouponDiscount(this.data.couponList)
    // }

    getUseAmount(couponIdList, totalProductPrice) {
        if (this.data === null) return []
        if (couponIdList.length === 0) return []

        const couponTypeMap = couponIdList.reduce((acc, id) => {
            const found = this.data.couponList.find(coupon => coupon.couponIssueNumber === id)
            acc[found.plusYn ? CouponType.Plus : CouponType.Normal] = found
            return acc
        }, {})

        let currentTotalPrice = totalProductPrice;
        let normalUseAmount = 0;
        const ret = []

        if (couponTypeMap[CouponType.Normal]) {
            const normalCoupon = couponTypeMap[CouponType.Normal];
            normalUseAmount = UserCouponEntity.getEachAmount(normalCoupon, currentTotalPrice);
            ret.push({
                couponId: normalCoupon.couponIssueNumber,
                useAmount: normalUseAmount
            })
        }
        currentTotalPrice -= normalUseAmount;

        if (couponTypeMap[CouponType.Plus]) {
            const plusCoupon = couponTypeMap[CouponType.Plus];
            ret.push({
                couponId: plusCoupon.couponIssueNumber,
                useAmount: UserCouponEntity.getEachAmount(plusCoupon, currentTotalPrice)
            })
        }
        return ret
    }


}