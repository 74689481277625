import {useEffect, useState} from "react";

export default function useScrollStatus() {
    const [isScrolling, setIsScrolling] = useState(false);

    useEffect(() => {
        let timeoutId;
        const handleScrollStart = () => {
            if (!isScrolling) setIsScrolling(true); // 스크롤 시작
        };
        const handleScrollEnd = () => {
            clearTimeout(timeoutId); // 이전 timeout을 취소
            timeoutId = setTimeout(() => {
                if (isScrolling) setIsScrolling(false); // 스크롤이 멈춘 후 상태 변경
            }, 150); // 스크롤 이벤트가 150ms 동안 없으면 스크롤이 멈춘 것으로 간주
        };

        window.addEventListener('scroll', handleScrollStart);
        window.addEventListener('scroll', handleScrollEnd);

        return () => {
            window.removeEventListener('scroll', handleScrollStart);
            window.removeEventListener('scroll', handleScrollEnd);
            clearTimeout(timeoutId); // 컴포넌트 언마운트 시 timeout 취소
        };
    }, [isScrolling]);

    return {
        isScrolling,
    }
}