import React, {useEffect, useState} from "react";
import axios from "axios";
import commonAPI from "../../../../common/commonAPI";
import {Link} from "react-router-dom";


export default function KMiddleBanner() {
    const serverUrl = process.env.REACT_APP_SERVER_URL;
    const [bannerData, setBannerData] = useState()
    // loader hook으로 변경 및 fetch 따로 분리 해야함..
    useEffect(() => {
        async function getBannerData() {
            const res = await axios.get(serverUrl + `/api/v1/pc/main`, {
                headers: {
                    'Authorization': commonAPI.AUTHROIZATION,
                    'Channel-Id': 'PRIVIA',
                    'Content-Type': 'application/json'
                }
            })
                .then(res => res.data)
                .catch(error => console.log(error))
            setBannerData(res)
        }
        getBannerData().catch(error => console.log(error))
    }, [])

    //
    const midBannerEle = bannerData && bannerData.pcMidBanner.map((value, index) => {
        if (!!value.imageUrl) {
            return (
                <li className="ga-promo list" key={index}>
                    <Link to={value.linkUrl} className="ga-promo-click">
                        {
                            value.imageUrl && value.imageUrl !== 'http://image8.hanatour.com/product/img_no_M.gif' ?
                                (
                                    <span className="vis">
                                    <img src={value.imageUrl && value.imageUrl}/>
                                </span>
                                ) :
                                (
                                    <span className={"vis"}>
                                    <span className={"no-img"}></span>
                                </span>
                                )
                        }

                        <span className="info-box">
                        <span
                            className="tit"><span>{value.tltle && value.tltle && value.title && value.title}<br/> {value.content}</span></span>
                        <span className="date">예약기간<span
                            className="txt-colon">:</span><em>{value.rangeStartDate}</em></span>
                    </span>
                    </Link>
                </li>
            )
        } else if (!!value.ImageUrl) {
            return (
                <li className="ga-promo list" key={index}>
                    <Link to={value.linkUrl} className="ga-promo-click">
                        {
                            value.ImageUrl && value.ImageUrl !== 'http://image8.hanatour.com/product/img_no_M.gif' ?
                                (
                                    <span className="vis">
                                    <img src={value.ImageUrl && value.ImageUrl}/>
                                </span>
                                ) :
                                (
                                    <span className={"vis"}>
                                    <span className={"no-img"}></span>
                                </span>
                                )
                        }

                        <span className="info-box">
                        <span className="tit"><span>{value.title && value.title}<br/> {value.content}</span></span>
                            {
                                value.rangeStartDate ? <span className="date">예약기간<span
                                    className="txt-colon">:</span><em>{value.rangeStartDate}</em></span> : null
                            }
                    </span>
                    </Link>
                </li>
            )
        }
    })

    return (
        <>
            {midBannerEle}
        </>
    )

}