// 전역으로 사용할 수 있는 이벤트 매니저 생성
import EventManager from "../../../../infra/event/EventManager";
import {createContext, useContext} from "react";
import useRemindReservation from "../../../usecase/reservation/useRemindReservation";
import CreateReservationHeader from "../../component/reservation/CreateReservationHeader";
import ReservationProductSummaryCard from "../../component/reservation/ReservationProductSummaryCard";
import MPointSection from "../../component/reservation/MPointSection";
import SelectCreditCard from "../../component/reservation/SelectCreditCard";
import BenefitDiscount from "../../component/reservation/benefit-discount";
import ReservationPersonQuantityPanel from "../../component/reservation/payment-panel/ReservationPersonQuantityPanel";

const eventManager = new EventManager();

// 이벤트 매니저 컨텍스트 생성
const EventManagerContext = createContext(eventManager);


export default function RemindReservationContainer() {
    return (
        <EventManagerContext.Provider value={eventManager}>
            <RootContainer/>
        </EventManagerContext.Provider>
    )
}

function RootContainer() {
    const eventManager = useContext(EventManagerContext);
    const {
        reservationCtx,
        representativeProductCtx,
        saleProductCtx,
        couponCtx,
        ticketCtx,
        creditCardCtx,
        mPointCtx,
        paymentCtx,
        redVoucherCtx,
        greenVoucherCtx,
        hcc3Ctx,
        familyCtx,
        redVoucherStripCtx,
        redVoucher4Ctx,
        summitVoucherCtx,
        showBenefit,
        userCtx
    } = useRemindReservation(eventManager);

    return (
        <div className={"w-content-sec"}>
            <CreateReservationHeader/>

            <div className="pkg-book-sec ly-inner clear_fix">
                <ReservationPersonQuantityPanel
                    paymentCtx={paymentCtx}
                    reservationCtx={reservationCtx}
                    saleProductCtx={saleProductCtx}
                    revEmail={reservationCtx?.inicisPayData?.buyerEmail}
                />


                <div className="pkg-left-inner">
                    <ReservationProductSummaryCard
                        representativeProductCtx={representativeProductCtx}
                        saleProductCtx={saleProductCtx}
                    />

                    {showBenefit &&
                        <BenefitDiscount
                            summitVoucherCtx={summitVoucherCtx}
                        paymentCtx={paymentCtx}
                        couponCtx={couponCtx}
                        ticketCtx={ticketCtx}
                        creditCardCtx={creditCardCtx}
                        redVoucherCtx={redVoucherCtx}
                        redVoucherStripCtx={redVoucherStripCtx}
                        redVoucher4Ctx={redVoucher4Ctx}
                        greenVoucherCtx={greenVoucherCtx}
                        hcc3Ctx={hcc3Ctx}
                        familyCtx={familyCtx}
                    />
                    }

                    <MPointSection
                        mPointCtx={mPointCtx}
                        paymentCtx={paymentCtx}
                        userCtx={userCtx}
                    />

                    <SelectCreditCard creditCardCtx={creditCardCtx}/>

                </div>
            </div>

        </div>
    )
}