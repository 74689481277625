import {getAxiosHeaders} from "../../../common/util/Axios";
import axios from "axios";

/*
*  예약금,전액 결제시 가예약 생성
* */
export async function createPreReservation(preReservationBody) {
    const API_URL = process.env.REACT_APP_SERVER_URL;
    const url = `${API_URL}/api/v2/reservation/preSave`
    return await axios.post(url, preReservationBody, {headers: getAxiosHeaders()})
}

/**
 * 예약번호로 예약정보 조회
 */
export async function getReservation(reservationNumber) {
    const API_URL = process.env.REACT_APP_SERVER_URL;
    const url = `${API_URL}/api/v1/reservation/getData/` + reservationNumber;
    return await axios.get(url, {headers: getAxiosHeaders()})
}

/*
*  중도금 추가금 결제시 예약 업데이트
* */
export async function updateRepayReservation(reservationInfoParam) {
    const API_URL = process.env.REACT_APP_SERVER_URL;
    const url = `${API_URL}/api/v2/reservation/repayPreSave`
    return await axios.post(url, reservationInfoParam, {headers: getAxiosHeaders()})
}


/*
* 예약 서버 계산(예약 생성)
* */

export async function calculateReservationPrice(params, paymentData) {
    const API_URL = process.env.REACT_APP_SERVER_URL;
    const url = `${API_URL}/api/v2/payment/calculateAmount/${params.representativeCode}/${params.saleCode}`
    return await axios.post(url, paymentData, {headers: getAxiosHeaders()})
}

/*
* 예약 서버 계산(잔금 결제)
* */

export async function calculateReservationPriceByReservation(reservationNumber, paymentData) {
    const API_URL = process.env.REACT_APP_SERVER_URL;
    const url = `${API_URL}/api/v2/payment/calculateAmount/${reservationNumber}`
    return await axios.post(url, paymentData, {headers: getAxiosHeaders()})
}

export async function checkMFirstPayment(reservationNumber) {
    const API_URL = process.env.REACT_APP_SERVER_URL;
    const url = `${API_URL}/api/v2/payment/check/one-pay/${reservationNumber}`
    return await axios.get(url, {headers: getAxiosHeaders()})
}

