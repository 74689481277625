import {useLocation} from "react-router-dom";

export const PRIVIA_URL = Object.freeze({
    HOME: /^\/$/, // 홈 ("/") URL을 정확히 매칭합니다.
    PRODUCT_LIST: /^\/representative$/, // "/representative"로 정확히 끝나는 URL만 매칭합니다.
    PRODUCT_DETAIL:/^\/representative\/[^\/]+\/sale\/[^\/]+\/detail$/ // detail 페이지
})

export default function usePriviaUrl() {

    const location = useLocation(); // 현재 위치의 정보를 담고 있는 객체를 반환합니다.

    function isUrl(priviaUrl) {
        return priviaUrl.test(location.pathname);
    }

    return {
        isUrl,
        path: location.pathname
    }
}