import {useEffect, useState} from "react";
import useEvent from "../../../../infra/event/useEvent";
import {EVENT_NAMES} from "../event/events";
import {DiscountStep, DiscountType} from "../../../../domain/service/payment/PaymentEnum";
import usePaymentPrice from "../usePaymentPrice";
import {
    getDiscountSelector,
    initCouponSelector,
    selectCouponOptionListSelector,
    setInvalidCouponSelector
} from "../../../state/reservation/benefit/CouponSelector";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {PRICE_TYPE} from "../../../state/reservation/PriceSelectors";

export default function useCoupon(eventManager) {

    const {
        setDiscountV2,
        removeDiscountV2,
        priceDict,
        isSetDiscount,
        setEnableCoupon,
        coupon,
        discountValueDict
    } = usePaymentPrice()

    const [userCouponEntity, setUserCouponEntity] = useState(null)
    const [representativeProduct, setRepresentativeProduct] = useState(null)
    const [saleProduct, setSaleProduct] = useState(null)

    const selectCouponOptionList = useSetRecoilState(selectCouponOptionListSelector)
    const discount = useRecoilValue(getDiscountSelector)
    const setInvalidCoupon = useSetRecoilState(setInvalidCouponSelector)
    const initCoupon = useSetRecoilState(initCouponSelector)

    useEvent(eventManager, EVENT_NAMES.LOAD_COUPON, (loadCouponEvent) => {
        const userCouponEntity = loadCouponEvent.getPayload()
        setUserCouponEntity(userCouponEntity)
    })

    useEvent(eventManager, EVENT_NAMES.LOAD_REPRESENTATIVE_PRODUCT, (loadRepresentativeProductEvent) => {
        const representativeProduct = loadRepresentativeProductEvent.getPayload()
        setRepresentativeProduct(representativeProduct)
    })

    useEvent(eventManager, EVENT_NAMES.LOAD_SALE_PRODUCT, (loadSaleProductEvent) => {
        const saleProduct = loadSaleProductEvent.getPayload()
        setSaleProduct(saleProduct)
    })


    useEffect(()=>{
        if (!userCouponEntity) return
        if (!representativeProduct) return
        if (!saleProduct) return

        setEnableCoupon({
            representativeProduct,
            saleProduct,
            couponList: userCouponEntity.getCouponList(),
            priceDict
        })
    }, [userCouponEntity, representativeProduct, saleProduct,
        priceDict[PRICE_TYPE.SUM_TARGET_PRICE]])


    function onSelectCoupon(option, targetType) {
        selectCouponOptionList({
            id: option.value,
            type: targetType,
        })

    }

    function onApplyCoupon() {
        const selectedCouponIds = Object.keys(coupon.discount.selectedCouponDict)
        if (selectedCouponIds.length >= 1) {
            setDiscountV2(discount)
        }
    }

    function onCloseCoupon() {
        removeDiscountV2(discount)
        initCoupon()
    }

    function getSubmitData() {
        let couponList = coupon.optionList.filter(opt => opt.selected)

        return {
            couponList
        }
    }

    function onOpen() {
        const otherDiscountCtx = isSetDiscount(DiscountStep.FIRST_STEP, DiscountType.Coupon)
        if (otherDiscountCtx.isSet && !otherDiscountCtx.equal) {
            return window.confirm(otherDiscountCtx.message)
        }
        return true
    }

    function setInvalid() {
        setInvalidCoupon()
    }

    return {
        enable: coupon.enable,
        options: [...coupon.optionList],
        adaptedMoney: discountValueDict[DiscountType.Coupon],
        title: `PRIVIA 쿠폰 (총 ${coupon.optionList.length}장)`,
        onSelectCoupon,
        onApplyCoupon,
        onCloseCoupon,
        getSubmitData,
        onOpen,
        setInvalid
    }
}
