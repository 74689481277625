import {CouponType} from "../../../../domain/model/user/UserCouponEntity";
import moment from "moment";
import {selector} from "recoil";
import {paymentPriceStateAtom} from "../PaymentPriceState";
import {getDiscountValueDict, getTargetPrice} from "../DiscountSelector";
import {cloneDeep} from "lodash";
import {PRICE_TYPE} from "../PriceSelectors";
import {DiscountType} from "../../../../domain/service/payment/PaymentEnum";

function findCoupon(discount, couponId) {
    const couponList = discount.couponList
    return couponList.find(coupon => coupon.couponIssueNumber === couponId);
}

/*
* 실제 쿠폰 할인 금액 구하는 비즈니스 로직
* */
function discountByCouponId(discount, couponId, originPriceValue) {
    let foundCoupon = findCoupon(discount, couponId)
    if (!foundCoupon) {
        return 0
    }
    const coupon = foundCoupon
    if (coupon.couponDcDivision === "1") {
        return coupon.couponDcAmount
    }
    if (coupon.couponDcDivision === "2") {
        if (coupon.couponUseMaxDcAmount === 0 ||
            coupon.couponUseMaxDcAmount >
            Math.floor(originPriceValue * coupon.couponDcRate * 0.01)) {
            return Math.floor(originPriceValue * coupon.couponDcRate * 0.001) * 10
        }
        return coupon.couponUseMaxDcAmount
    }
    return 0
}


/*
* 타입별 쿠폰 가격 구함
* */
export function getCouponDiscountValueByType(discount, targetValue, couponType = CouponType.Normal) {
    const couponList = discount.couponList
    if (couponList.length === 0) return 0
    const selectedCouponDict = discount.selectedCouponDict
    let normalDiscount = 0
    let couponTypeFilter = couponType !== CouponType.Normal
    const foundCoupons = Object.keys(selectedCouponDict).map(id => findCoupon(discount, id))
    const coupons = foundCoupons.filter(coupon => coupon.plusYn === couponTypeFilter)
    if (coupons.length >= 1) {
        normalDiscount = discountByCouponId(discount, coupons[0]['couponIssueNumber'], targetValue)
    }
    return normalDiscount
}


/*
* 쿠폰의 할인 객체로, 할인 금액을 계산하는 함수를 반환합니다.
* 쿠폰은 일반,플러스 쿠폰으로 나뉩니다. 따라서 반환값은 딕셔너리 형태로 반환됩니다.
* */
export function getCouponDiscountValueDict(discount, targetValue) {
    const couponList = discount.couponList
    if (couponList.length === 0) return 0
    const normalDiscountValue = getCouponDiscountValueByType(discount, targetValue, CouponType.Normal)
    const plusDiscountValue = getCouponDiscountValueByType(discount, targetValue - normalDiscountValue, CouponType.Plus)
    return {
        [CouponType.Normal]: normalDiscountValue,
        [CouponType.Plus]: plusDiscountValue
    }
}

/*
* 쿠폰 할인 금액 합산
* */
export function getCouponDiscountValue(paymentPrice, targetValue) {
    const coupon = paymentPrice.coupon
    if (!coupon) return 0
    const discount = coupon.discount
    if (!discount) return 0
    const dict = getCouponDiscountValueDict(discount, targetValue)
    return Object.values(dict).reduce((acc, value) => acc + value, 0)
}

export function getCouponSubmitData(paymentPrice) {

    const coupon = paymentPrice.coupon
    const couponDataDict = coupon.discount.couponList.reduce((acc, couponData) => {
        return {
            ...acc,
            [couponData.couponIssueNumber]: couponData
        }
    }, {})
    let couponList = coupon.optionList
        .filter(opt => opt.selected)
        .map(opt => ({
            ...couponDataDict[opt.value],
            useAmount: opt.useAmount
        }))

    const discountValueDict = getDiscountValueDict(paymentPrice)
    if (discountValueDict[DiscountType.Coupon]) {
        return {
            couponList
        }
    }
    return {
        couponList: []
    }

}

export function initCoupon(coupon) {
    return {
        ...coupon,
        optionList: coupon.optionList.map(opt => ({...opt})).map(opt => {
            opt.selected = false
            return opt
        }),
        discount: {
            ...coupon.discount,
            selectedCouponDict: {}
        }
    }
}

function getViewCouponName(couponData) {
    return couponData.couponName
        + ' (' + moment(couponData.couponIssueDate).format("YYYY-MM-DD") + '~'
        + (couponData.couponUseIssuedBaseDd ? moment(couponData.couponIssueDate).add(couponData.couponUseIssuedBaseDd, "d").format("YYYY-MM-DD") : moment(couponData.couponUseEndDate).format("YYYY-MM-DD")) + ')';
}

function isUsableCoupon(couponData, representativeProductEntity, saleProductEntity, priceDict) {

    // const discountObjectAmount = representativeProductEntity.getDiscountObjectAmount()

    // console.log(1)
    if (couponData.couponUseMinProductAmountDivision === "1" &&
        couponData.couponUseMinProductAmount > priceDict[PRICE_TYPE.SUM_TARGET_PRICE]) {
        return false;
    }

    const saleProduct = saleProductEntity.getData()
    const representativeProduct = representativeProductEntity.getData()
    //   console.log(2)
    if (couponData.couponTravelStartDate &&
        moment(couponData.couponTravelStartDate).format("YYYYMMDD") > moment(saleProduct.departureDay).format("YYYYMMDD")) {
        return false;
    }
    // console.log(3)
    if (couponData.couponTravelEndDate &&
        moment(couponData.couponTravelEndDate).format("YYYYMMDD") < moment(saleProduct.departureDay).format("YYYYMMDD")) {
        return false;
    }
    // console.log(4)
    // console.log(index + " / representativeProduct : " + representativeProduct);
    if (!couponData.useScopes.at(0).scop7.includes("*") &&
        representativeProduct &&
        !couponData.useScopes.at(0).scop7.includes(saleProduct.saleProductCode)) {
        return false;
    } else if (!couponData.useScopes.at(0).scop6.includes("*") &&
        representativeProduct &&
        !couponData.useScopes.at(0).scop6.includes(saleProduct.representProductCode)) {
        // console.log(5)
        return false;
    } else if (!couponData.useScopes.at(0).scop5.includes("*") &&
        representativeProduct &&
        !couponData.useScopes.at(0).scop5.includes(representativeProduct.visitCityCode)) {
        //console.log(6)
        return false;
    } else if (!couponData.useScopes.at(0).scop4.includes("*") &&
        representativeProduct &&
        !couponData.useScopes.at(0).scop4.includes(representativeProduct.visitCountryCode)) {
        //  console.log(7)
        return false;
    } else if (!couponData.useScopes.at(0).scop3.includes("*") &&
        representativeProduct &&
        !couponData.useScopes.at(0).scop3.includes(representativeProduct.visitContinentCode)) {
        // console.log(8)
        return false;
    } else if (!couponData.useScopes.at(0).scop2.includes("*") &&
        !couponData.useScopes.at(0).scop2.includes("PACKAGE")) {
        //   console.log(9)
        return false;
    } else if (!couponData.useScopes.at(0).scop1.includes("*") &&
        !couponData.useScopes.at(0).scop1.includes(saleProduct.supplierCode)) {
        //   console.log(10)
        return false;
    } else {
        //  console.log(11)
        return true;
    }
}

/*
* 모든 쿠폰 사용 여부를 제어하는 함수
* */
export function allSelectCouponOptionList(paymentPrice, type, select) {
    const coupon = {...paymentPrice.coupon}
    const optionList = [...coupon.optionList].map((opt) => {
        return {
            ...opt,
        }
    })

    optionList.filter(opt => opt.type === type)
        .forEach((option, index, array) => {
            option.selected = select
        })
    return {
        ...paymentPrice,
        coupon: {
            ...coupon,
            optionList
        }
    }
}

/*
* 특정 타입 쿠폰 선택할 때 발생
* */
export function selectCouponOptionList(paymentPrice, value) {
    let newPaymentPrice = allSelectCouponOptionList(paymentPrice, value.type, false)
    const coupon = {...newPaymentPrice.coupon}
    const couponDiscount = {...coupon.discount}

    const optionList = coupon.optionList.map((opt) => {
        return {
            ...opt,
        }
    })

    const foundOption = optionList.find((opt) => opt.value === value.id)
    if (foundOption) {
        foundOption.selected = true
    }

    const selectedCouponDict = optionList.filter(opt => opt.selected)
        .reduce((acc, opt) => {
            return {
                ...acc,
                [opt.value]: true
            }
        }, {})

    const newDiscount = {
        ...couponDiscount,
        selectedCouponDict
    }
    const targetPrice = getTargetPrice(paymentPrice.amountDict)
    const discountValueDict = getCouponDiscountValueDict(newDiscount, targetPrice)

    if (foundOption) {
        foundOption.useAmount = discountValueDict[value.type]
    }

    newPaymentPrice = {
        ...paymentPrice,
        coupon: {
            ...coupon,
            optionList,
            discount: newDiscount
        }
    }

    return newPaymentPrice
}

export function setUpCouponOptionList(paymentPrice, representativeProductEntity, saleProductEntity, priceDict) {

    const coupon = {...paymentPrice.coupon}
    const couponList = [...coupon.discount.couponList]
    if (couponList.length === 0) return paymentPrice

    const optionList = couponList.map(couponData => {
        let type = couponData['plusYn'] ? CouponType.Plus : CouponType.Normal
        const value = couponData['couponIssueNumber']
        const label = getViewCouponName(couponData)
        const isDisabled = !isUsableCoupon(couponData, representativeProductEntity, saleProductEntity, priceDict)
        const useAmount = 0
        return {
            type,
            value,
            label,
            selected: false,
            isDisabled,
            useAmount,
        }
    })

    return {
        ...paymentPrice,
        coupon: {
            ...coupon,
            optionList
        }
    }
}

/*
* 처음 쿠폰 초기화 할 때 사용하는 셀렉터
* */
export const setEnableCouponSelector = selector({
    key: 'setEnableCouponSelector',
    get: ({get}) => {
        const paymentPrice = get(paymentPriceStateAtom)
        return paymentPrice.coupon
    },
    set: ({set, get}, newValue) => {
        const {
            representativeProduct,
            saleProduct,
            couponList,
            priceDict
        } = newValue

        const old = get(paymentPriceStateAtom)
        const oldCoupon = cloneDeep(old.coupon)

        if (!oldCoupon.valid) {
            return
        }

        const newCoupon = {
            ...old.coupon,
            enable: couponList.length > 0,
            discount: {
                ...old.coupon.discount,
                couponList // 리스트 넣어준다.
            }
        }
        let newPaymentPrice = {
            ...old,
            coupon: newCoupon
        }
        // 데이터가 있는 상태에서 옵션 리스트 만든다.
        newPaymentPrice = setUpCouponOptionList(newPaymentPrice, representativeProduct, saleProduct, priceDict)
        set(paymentPriceStateAtom, newPaymentPrice)
    }
})


export const getCouponSelector = selector({
    key: 'getCouponSelector',
    get: ({get}) => {
        const paymentPrice = get(paymentPriceStateAtom)
        return paymentPrice.coupon
    }
})

export const selectCouponOptionListSelector = selector({
    key: 'selectCouponOptionListSelector',
    get: ({get}) => {
        const paymentPrice = get(paymentPriceStateAtom)
        return paymentPrice.coupon
    },
    set: ({set, get}, value) => {
        const paymentPrice = get(paymentPriceStateAtom)
        const newPaymentPrice = selectCouponOptionList(paymentPrice, value)
        set(paymentPriceStateAtom, newPaymentPrice)
    }
})

export const getDiscountSelector = selector({
    key: 'getDiscountSelector',
    get: ({get}) => {
        const paymentPrice = get(paymentPriceStateAtom)
        return paymentPrice.coupon.discount
    }
})

export const initCouponSelector = selector({
    key: 'initCouponSelector',
    get: ({get}) => {
        const paymentPrice = get(paymentPriceStateAtom)
        return paymentPrice.coupon
    },
    set: ({set, get}) => {
        const paymentPrice = get(paymentPriceStateAtom)
        const coupon = paymentPrice.coupon
        const newPaymentPrice = {
            ...paymentPrice,
            coupon: {
                ...coupon,
                optionList: coupon.optionList.map((coup) => ({
                    ...coup,
                    selected: false
                })),
                discount: {
                    ...coupon.discount,
                    selectedCouponDict: {}
                }
            }
        }
        set(paymentPriceStateAtom, newPaymentPrice)
    }
})

export const setInvalidCouponSelector = selector({
    key: 'setInvalidCoupon',
    get: ({get}) => {
        const paymentPrice = get(paymentPriceStateAtom)
        return paymentPrice.coupon
    },
    set: ({set, get}, value) => {
        const paymentPrice = get(paymentPriceStateAtom)
        const newCoupon = cloneDeep(paymentPrice.coupon)
        newCoupon.valid = false
        newCoupon.enable = false
        newCoupon.optionList = []
        newCoupon.selected = null

        set(paymentPriceStateAtom, {
            ...paymentPrice,
            coupon: newCoupon
        })
    }
})