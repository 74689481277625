import React from "react";


export default function RequirementTextBox({
                                               requestTextCtx,
                                           }) {
    const {requestText, onChange} = requestTextCtx;

    function handleRequestTextArea(e) {
        onChange && onChange(e.target.value);
    }

    return (
        <section className="book-detail-area">
            <div className="pkg-tit-page-cont between-box fc">
                <h3 className="title">요청사항</h3>
                <span className="text-num">
                                    <em>{requestText.length}</em><em>/</em><em>500</em>
                                </span>
            </div>

            <div className="book-article">
                <textarea name="request" className="textarea-basic" placeholder="요청사항을 기재해 주세요." maxLength={500}
                          onChange={handleRequestTextArea}></textarea>
            </div>
        </section>
    )
}