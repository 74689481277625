import {useEffect, useState} from "react";
import {findParentProduct} from "../../domain/repository/ProductRepository";
import RepresentativeProductEntity from "../../domain/model/product/RepresentativeProductEntity";

export default function useLoadProduct(representativeCode) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [representativeProduct, setRepresentativeProduct] = useState(null);

    useEffect(() => {
        if (!representativeCode) return;
        setLoading(true);
        setError(null);
        findParentProduct(representativeCode).then(res => {
            setRepresentativeProduct(
                new RepresentativeProductEntity(res.data['representativeProducts'][0])
            );
            setLoading(false);
        }).catch(err => {
            setError(err);
            setLoading(false);
        })
    }, [representativeCode]);

    return {
        loading,
        error,
        representativeProduct,
        productName: representativeProduct ? representativeProduct.getName() : ''
    }
}