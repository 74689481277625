/*
* searchHistory {
*   representativeProductName:String
*   startDate:String
*   endDate:String
*   representativeCode:String?
*   nationCode:String?
*   continentCode:String?
* }
*
* */

export default function RecentlySearch({
                                           onClickSearchHistory,
                                           searchHistory
                                       }) {
    function handleClickRecently() {
        document.querySelector(".ui-recently-search").classList.toggle("on");
    }


    return (
        <div className="hss-recently-search on">
            <a style={{cursor: "pointer"}} className="qrs-area" data-panel="recently"
               data-panel-name="ui-recently-search" onClick={handleClickRecently}>
            <span
                className="inf-total a-bold">최근검색 ({searchHistory.length})</span>
            </a>
            <div className="sc-ui-search-panel ui-recently-search">
                <div
                    className="urs-tit">최근검색 <em>({searchHistory.length})</em>
                </div>
                <ul className="list">
                    {searchHistory && searchHistory.length > 0 && [...searchHistory].reverse().map((value, index) => (
                        <li key={`searchHistory${index}`}>
                            <a style={{cursor: "pointer"}} onClick={() => {
                                handleClickRecently()
                                onClickSearchHistory(value)
                            }}>
                                <div>
                                      <span className="cont">
                                        {value.representativeProductName}<br/>
                                        <DayRange startDate={value.startAt} endDate={value.endAt}/>
                                    </span>
                                </div>
                            </a>
                        </li>
                    ))}
                </ul>
                <a style={{cursor: "pointer"}} className="b-scb-close"
                   onClick={handleClickRecently}><span className="a11y">닫기</span></a>
            </div>
        </div>
    )
}

function DayRange({
                      startDate,
                      endDate
                  }) {

    if (!startDate) return null
    return (
        <>
            {startDate} ~ {endDate}
        </>
    )
}

