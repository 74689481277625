import {useEffect, useState} from "react";
import {EVENT_NAMES} from "../event/events";
import useEvent from "../../../../infra/event/useEvent";

export default function useTerms(eventManager) {
    const [commit, setCommit] = useState(false)
    const [allAgreed, setAllAgreed] = useState(false)
    const [travelTerms, setTravelTerms] = useState(false)
    const [personalInfo, setPersonalInfo] = useState(false)
    const [thirdPartyInfo, setThirdPartyInfo] = useState(false)
    const [cancellationRefundPolicy, setCancellationRefundPolicy] = useState(false)
    const [liabilityNotice, setLiabilityNotice] = useState(false)
    const [usageTerms, setUsageTerms] = useState(false)
    const [eFinanceUsageTerms, setEFinanceUsageTerms] = useState(false)
    const [hasUserInfo, setHasUserInfo] = useState(false)
    const [supplierName, setSupplierName] = useState('')

    useEvent(eventManager, EVENT_NAMES.LOAD_USER, (evt) => {
        const user = evt.getPayload()
        setHasUserInfo(user.isValid())
    })

    useEvent(eventManager, EVENT_NAMES.LOAD_SALE_PRODUCT, (loadSaleProductEvent) => {
        const saleProduct = loadSaleProductEvent.getPayload()
        setSupplierName(saleProduct.getSupplierName())
    })

    useEffect(() => {
        if (allAgreed) {
            setCommit(true)
            return
        }
        if (hasUserInfo && travelTerms && personalInfo && thirdPartyInfo && cancellationRefundPolicy && liabilityNotice) {
            setAllAgreed(true)
            setCommit(true)
            return
        }
        if (!hasUserInfo && travelTerms && personalInfo && thirdPartyInfo && cancellationRefundPolicy && liabilityNotice && usageTerms && eFinanceUsageTerms) {
            setAllAgreed(true)
            setCommit(true)
            return
        }
        setAllAgreed(false)
        setCommit(false)
    }, [commit, allAgreed, travelTerms, personalInfo, thirdPartyInfo, cancellationRefundPolicy, liabilityNotice, usageTerms, eFinanceUsageTerms]);


        function onCheck(e) {
            const name = e.target.name
            setAllAgreed(false)
            switch (name) {
                case 'allAgreed':
                    setAllAgreed(e.target.checked)
                    setTravelTerms(e.target.checked)
                    setPersonalInfo(e.target.checked)
                    setUsageTerms(e.target.checked)
                    setEFinanceUsageTerms(e.target.checked)
                    setThirdPartyInfo(e.target.checked)
                    setCancellationRefundPolicy(e.target.checked)
                    setLiabilityNotice(e.target.checked)
                    break
                case 'travelTerms':
                    setTravelTerms(e.target.checked)
                    break
                case 'personalInfo':
                    setPersonalInfo(e.target.checked)
                    break
                case 'usageTerms':
                    setUsageTerms(e.target.checked)
                    break
                case 'eFinanceUsageTerms':
                    setEFinanceUsageTerms(e.target.checked)
                    break
                case 'thirdPartyInfo':
                    setThirdPartyInfo(e.target.checked)
                    break
                case 'cancellationRefundPolicy':
                    setCancellationRefundPolicy(e.target.checked)
                    break
                case 'liabilityNotice':
                    setLiabilityNotice(e.target.checked)
                    break
                default:
                    break
            }
        }

    const isValid = () => {
        if (commit) {
            return {
                termsAgreeYn : commit ? 'Y' : 'N',
                errorMessage : '',
            };
        }

        let errMsg = '';

        if (!travelTerms) {
            errMsg = '국외여행 표준약관(필수)에 체크하지 않으셨습니다.'
        } else if (!cancellationRefundPolicy) {
            errMsg = '취소 및 환불 규정(필수)에 체크하지 않으셨습니다.'
        } else if (!hasUserInfo && !usageTerms) {
            errMsg = 'PRIVIA 이용약관(필수)에 체크하지 않으셨습니다.'
        } else if (!hasUserInfo && !eFinanceUsageTerms) {
            errMsg = '전자금융거래 이용약관(필수)에 체크하지 않으셨습니다.'
        } else if (!personalInfo) {
            errMsg = '개인정보 수집ㆍ이용 동의(필수)에 체크하지 않으셨습니다.'
        } else if (!thirdPartyInfo) {
            errMsg = '제 3자 개인정보 제공 동의(필수)에 체크하지 않으셨습니다.'
        } else if (!liabilityNotice) {
            errMsg = '(주)타이드스퀘어는 통신판매중개자이며, 통신 판매 당사자가 아닙니다.따라서 상품ㆍ거래 정보 및 거래에 대하여 책임을 지지 않습니다.(필수)에 체크하지 않으셨습니다.'
        }

        return {
            termsAgreeYn : commit ? 'Y' : 'N',
            errorMessage : errMsg,
        };

    }

    function getSubmitData() {
        const {termsAgreeYn, errorMessage} = isValid();
        return {
            termsAgreeYn: termsAgreeYn,
        }
    }

    function validate() {
        const {
            termsAgreeYn,
            errorMessage
        } = isValid()
        return {
            valid: termsAgreeYn === 'Y',
            message: errorMessage
        }
    }


    return {
        commit,
        allAgreed,
        hasUserInfo: hasUserInfo,
        agreement : {
            travelTerms: travelTerms,
            personalInfo: personalInfo,
            thirdPartyInfo: thirdPartyInfo,
            cancellationRefundPolicy: cancellationRefundPolicy,
            liabilityNotice: liabilityNotice,
            usageTerms: usageTerms,
            eFinanceUsageTerms: eFinanceUsageTerms,
        },

        onCheck: onCheck,
        supplierName: supplierName,
        getSubmitData,
        isValid,
        validate,
    }
}
