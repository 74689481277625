import {atom, selector} from "recoil";
import {getToday} from "../../infra/time/Kmoment";
import {cloneDeep} from "lodash";


function initDynamicFilterAtom(reset) {
    // 이렇게 초기화 안해주면, 토글 할 때마다, 다이나믹 필터가 변경된 상태로 바뀌어서
    // 다른 상태에도 영향 줌
    reset(priceRangeFilterAtom)
    reset(airlineFilterListAtom)
    reset(shopCountFilterListAtom)
}

function createToggleSelector(key, stateAtom, isDynamic = false) {
    return selector({
        key: key,
        get: ({get}) => {
            // 이 셀렉터는 get 함수를 사용하지 않지만, 정의해야 합니다.
            return get(stateAtom)
        },
        set: ({get, set, reset}, value) => {
            const list = get(stateAtom);
            const count = get(filterCountAtom);
            const newList = list.includes(value)
                ? list.filter(item => item !== value) // 제거
                : [...list, value];                  // 추가
            set(stateAtom, newList);
            set(filterCountAtom, count + 1)
            if (isDynamic) {
                initDynamicFilterAtom(reset)
                const dynamicCount = get(dynamicFilterCountAtom)
                set(dynamicFilterCountAtom, dynamicCount + 1)
            }
        }
    });
}

function createResetSelector(key, stateAtom, isDynamic = false) {
    return selector({
        key: key,
        get: ({get}) => {
        }, set: ({get, set, reset}, {forceUpdate = true} = {}) => {
            if (get(stateAtom).length === 0) return;
            reset(stateAtom);

            if (forceUpdate) {
                const count = get(filterCountAtom);
                set(filterCountAtom, count + 1)
                if (isDynamic) {
                    const dynamicCount = get(dynamicFilterCountAtom)
                    set(dynamicFilterCountAtom, dynamicCount + 1)
                }
            }
        }
    });
}


////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// 모든 필터가 값이 변경 되면 이 값을 변경 시켜줘야함.
// 필터가 변했다는 판단 기준 값
export const filterCountAtom = atom({
    key: 'filterCount',
    default: 0
});

export const upFilterCountAtom = selector({
    key: 'upFilterCount',
    get: ({get}) => {
        return get(filterCountAtom)
    },
    set: ({get, set}, value) => {
        //   const count = get(filterCountAtom)
        if (value && value.isReset) {
            set(filterCountAtom, 1)
            return
        }
        set(filterCountAtom, get(filterCountAtom) + 1)
    }
})

// 다이나믹 필터 -> 항공라인, 가격범위, 투어조건(쇼핑 회수) 같이 동적으로 변하는 필터들이
// 새롭게 로딩 해야하는 근거가 되는 기준값
export const dynamicFilterCountAtom = atom({
    key: 'dynamicFilterCount',
    default: 0
});

export const upDynamicFilterCountAtom = selector({
    key: 'upDynamicFilterCount',
    get: ({get}) => {
        return get(dynamicFilterCountAtom)
    },
    set: ({get, set}, value) => {
        if (value && value.isReset) {
            set(dynamicFilterCountAtom, 1)
            return
        }
        set(dynamicFilterCountAtom, get(dynamicFilterCountAtom) + 1)
    }

})

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const supplierFilterListAtom = atom({
    key: 'supplierFilterList',
    default: []
})
export const toggleSupplierFilterAtom =
    createToggleSelector('toggleSupplierFilter', supplierFilterListAtom, true)
export const resetSupplierFilterAtom =
    createResetSelector('resetSupplierFilter', supplierFilterListAtom, true)
export const serializeSupplierFilterAtom =
    selector({
        key: "serializeSupplierFilter",
        get: ({get}) => {
            function supplierNameToCode(supplierName) {
                switch (supplierName) {
                    case "하나투어":
                        return "HANA";
                    case "모두투어":
                        return "MODE";
                    case "참좋은여행":
                        return "VERYGOOD";
                    case "한진관광":
                        return "KAL";
                    default:
                        return null;
                }
            }

            const list = get(supplierFilterListAtom)
                .map(supplierNameToCode).filter(item => item !== null)
            if (list.length === 0) return null
            return list.map(item => `supplierCodes=${item}`).join("&&")
        }
    });


////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const productTypeFilterListAtom = atom({
    key: 'productTypeFilterList',
    default: []
});
export const toggleProductTypeFilterAtom =
    createToggleSelector('toggleProductTypeFilter', productTypeFilterListAtom, true)

export const resetProductTypeFilterAtom =
    createResetSelector('resetProductTypeFilter', productTypeFilterListAtom, true)


export const serializeProductTypeFilterAtom =
    selector({
        key: "serializeProductTypeFilterAtom",
        get: ({get}) => {
            function productTypeToDivisionCode(productType) {
                switch (productType) {
                    case "패키지":
                        return "PACKAGE";
                    case "에어텔":
                        return "AIRTEL";
                    case "골프":
                        return "GOLF";
                    case "허니문":
                        return "HONEYMOON";
                    case "크루즈":
                        return "CRUISE";
                    default:
                        return null;
                }
            }

            const list = get(productTypeFilterListAtom)
                .map(productTypeToDivisionCode).filter(item => item !== null)
            if (list.length === 0) return null
            return list.map(item => `divisionCodes=${item}`).join("&&")
        }
    });

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const periodFilterListAtom = atom({
    key: 'periodFilterList',
    default: []
});
export const togglePeriodFilterAtom =
    createToggleSelector('togglePeriodFilter', periodFilterListAtom)

export const resetPeriodFilterAtom =
    createResetSelector('resetPeriodFilter', periodFilterListAtom)


export const serializePeriodFilterAtom =
    selector({
            key: "serializePeriodFilterAtom",
            get: ({get}) => {
                /*
                *  요건 확인하기 지금은 min max로 간단하게 가져옴..
                * */
                function getMinMaxDays(days) {
                    switch (days) {
                        case "withinFourDay":
                            return {
                                min: 0,
                                max: 4
                            }
                        case "fiveToEight":
                            return {
                                min: 5,
                                max: 8
                            }
                        case "nineToTen":
                            return {
                                min: 9,
                                max: 10
                            }
                        case "elevenToSixteen":
                            return {
                                min: 11,
                                max: 16
                            }
                        case "beyondSeventeen":
                            return {
                                min: 17,
                                max: 999999999
                            }
                        default:
                            return null
                    }
                }

                const list = get(periodFilterListAtom).map(getMinMaxDays).filter(item => item !== null)
                if (list.length === 0) return null
                const minDay = list.map(item => item.min).reduce((a, b) => Math.min(a, b))
                const maxDay = list.map(item => item.max).reduce((a, b) => Math.max(a, b))
                return `minTravelDays=${minDay}&&maxTravelDays=${maxDay}`
            }
        }
    )


////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const allAirlineListAtom = atom({
    key: 'allAirlineList',
    default: [
        "7C",
        "BX",
        "OZ",
        "TW",
        "LJ",
        "YP",
        "ZE",
        "RF"
    ],
})

export const setAllAirlineListAtom = selector({
    key: 'setAllAirlineList',
    get: ({get}) => {
        return get(allAirlineListAtom)
    },
    set: ({get, set}, value) => {
        if (value === null) return
        set(allAirlineListAtom, value)
    }
})

export const airlineFilterListAtom = atom({
    key: 'airlineList',
    default: [],
})
export const toggleAirlineFilterAtom =
    createToggleSelector('toggleAirlineFilter', airlineFilterListAtom)

export const resetAirlineFilterAtom =
    createResetSelector('resetAirlineFilter', airlineFilterListAtom)


export const serializeAirlineFilterAtom =
    selector({
        key: "serializeAirlineFilterAtom",
        get: ({get}) => {
            const list = get(airlineFilterListAtom)
            if (list.length === 0) return null
            return list.map(item => `airlineCodes=${item}`).join("&&")
        }
    })


////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const departureCityFilterListAtom = atom({
    key: 'departureCity',
    default: []
})
export const toggleDepartureCityFilterAtom =
    createToggleSelector('toggleDepartureCityFilter', departureCityFilterListAtom)

export const resetDepartureCityFilterAtom =
    createResetSelector('resetDepartureCityFilter', departureCityFilterListAtom)


export const serializeDepartureCityFilterAtom =
    selector({
        key: "serializeDepartureCityFilterAtom",
        get: ({get}) => {
            const list = get(departureCityFilterListAtom)
            const copyList = [...list]
            if (list.length === 0) return null
            // 인천공항 코드가 JCN이랑 ICN 두개가 있어서 둘다 넣어줘야함.
            const jcn = copyList.find(item => item === "JCN")
            if (jcn) {
                copyList.push("ICN")
            }
            const icn = copyList.find(item => item === "ICN")
            if (icn) {
                copyList.push("JCN")
            }
            const uniqueList = [...new Set(copyList)]
            return uniqueList.map(item => `departureCityCodes=${item}`).join("&&")
        }
    })


////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const departureTimeFilterListAtom = atom({
    key: 'departureTime',
    default: []
})
export const toggleDepartureTimeFilterAtom =
    createToggleSelector('toggleDepartureTimeFilter', departureTimeFilterListAtom)

export const resetDepartureTimeFilterAtom =
    createResetSelector('resetDepartureTimeFilter', departureTimeFilterListAtom)


export const serializeDepartureTimeFilterAtom =
    selector({
        key: "serializeDepartureTimeFilterAtom",
        get: ({get}) => {
            function departureTimeToCode(departureTime) {
                switch (departureTime) {
                    case "dawn":
                        return 0
                    case "morning":
                        return 1
                    case "lunch":
                        return 2
                    case "afternoon":
                        return 3
                    default:
                        return null;
                }
            }

            const list = get(departureTimeFilterListAtom)
                .map(departureTimeToCode).filter(item => item !== null)
            if (list.length === 0) return null
            return `departureAtCode=${list.join(',')}`
        }
    })


////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const gradeFilterListAtom = atom({
    key: 'GradeFilterList',
    default: []
})
export const toggleGradeFilterAtom =
    createToggleSelector('toggleGradeFilter', gradeFilterListAtom, true)

export const resetGradeFilterAtom =
    createResetSelector('resetGradeFilter', gradeFilterListAtom, true)


export const serializeGradeFilterAtom =
    selector({
        key: "serializeGradeFilterAtom",
        get: ({get}) => {
            const list = get(gradeFilterListAtom)

            if (list.length === 0) return null
            return list.map(item => `saleDivisionCodes=${item}`).join("&&")
        }
    })



////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const hotelFilterListAtom = atom({
    key: 'hotelFilterList',
    default: []
})
export const toggleHotelFilterAtom =
    createToggleSelector('toggleHotelFilter', hotelFilterListAtom)

export const resetHotelFilterAtom =
    createResetSelector('resetHotelFilter', hotelFilterListAtom)

export const serializeHotelFilterAtom =
    selector({
        key: "serializeHotelFilterAtom",
        get: ({get}) => {

            // db에서 3.5,4.5 이런식으로 저장하고 있어서 문제가 있음..
            function hotelGradeToCode(hotelGrade) {
                switch (hotelGrade) {
                    case "fiveStar":
                        return "5";
                    case "fourStar":
                        return "4";
                    case "threeStar":
                        return "3";
                    case "etc":
                        return "0";
                    default:
                        return null
                }
            }

            const list = get(hotelFilterListAtom)
                .map(hotelGradeToCode)
                .filter(item => item !== null)
            if (list.length === 0) return null
            return list.map(item => `hotelGradeCodes=${item}`).join("&&")
        }
    })


////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const conditionFilterListAtom = atom({
    key: 'conditionFilterList',
    default: []
})
export const toggleConditionFilterAtom =
    createToggleSelector('toggleConditionFilter', conditionFilterListAtom)
export const allConditionListAtom = atom({
    key: 'allConditionList',
    default: []
})

export const resetConditionFilterAtom =
    createResetSelector('resetConditionFilter', conditionFilterListAtom)


export const serializeConditionFilterAtom =
    selector({
        key: "serializeConditionFilterAtom",
        get: ({get}) => {
            const list = get(conditionFilterListAtom)
                .filter(item => item !== null)
                .filter(item => item === "includeFreeTour")
            if (list.length === 0) return null
            return `isFreeSchedule=true`
        }
    })

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const shopCountFilterListAtom = atom({
    key: 'shopCountFilterList',
    default: []
})
export const toggleShopCountFilterAtom = selector({
    key: "toggleShopCountFilter",
    get: ({get}) => {
        // 이 셀렉터는 get 함수를 사용하지 않지만, 정의해야 합니다.
        return get(shopCountFilterListAtom)
    },
    set: ({get, set}, value) => {
        const list = get(shopCountFilterListAtom);
        const filtered = list.filter(
            item => item.count === value.count &&
                item.method === value.method
        )
        let newList = [...filtered]
        if (filtered.length === 0) {
            newList.push(value)
        }
        if (filtered.length === 1) {
            newList = list.filter(item => item.count !== value.count && item.method !== value.method)
        }
        const count = get(filterCountAtom);
        set(shopCountFilterListAtom, newList);
        set(filterCountAtom, count + 1)
    }
});

export const resetShopCountFilterAtom =
    createResetSelector('resetShopCountFilter', shopCountFilterListAtom)


export const serializeShopCountFilterAtom =
    selector({
        key: "serializeShopCountFilterAtom",
        get: ({get}) => {
            const list = get(shopCountFilterListAtom)

            if (list.length === 0) return null

            const shopCount = list[0]
            if (shopCount.method === 'equal') {
                return `minShoppingCount=${shopCount.count}&&maxShoppingCount=${shopCount.count}`
            }
            if (shopCount.method === 'over') {
                return `minShoppingCount=${shopCount.count}`
            }
        }
    })



////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const priceRangeFilterAtom = atom({
    key: 'priceFilter',
    default: {
        min: 0,
        currentMin: 0,
        max: 99999999,
        currentMax: 99999999,
    }
});

export const resetPriceFilterAtom = selector({
    key: 'resetPriceFilterAtom',
    get: ({get}) => {
    }, set: ({get, set, reset}, {forceUpdate = true, rangeInit = false}) => {
        const origin = cloneDeep(get(priceRangeFilterAtom));
        let min = origin['min']
        let max = origin['max']

        if (rangeInit) {
            min = 0
            max = 99999999
            origin['min'] = 0
            origin['max'] = 99999999
        }

        const nextVal = {
            min: min,
            max: max,
            currentMin: origin['min'],
            currentMax: origin['max'],
        }
        if (forceUpdate) {
            const count = get(filterCountAtom);
            set(filterCountAtom, count + 1)
        }
        set(priceRangeFilterAtom, nextVal)
    }
});


export const setPriceFilterMinMaxAtom = selector({
    key: 'setPriceFilterMinMax',
    get: ({get}) => {
        return get(priceRangeFilterAtom)
    },
    set: ({get, set}, value) => {
        if (value === null) return
        if (value['min'] === null) return
        if (value['max'] === null) return

        const nextMax = value['max'] === 0 ? 99999999 : value['max']

        set(priceRangeFilterAtom, {
            min: value['min'],
            max: nextMax,
            currentMin: value['min'],
            currentMax: nextMax
        })
    }
})

export function calculatePriceCurrentRangeUtils(origin, changedMinSlider, changedMaxSlider) {
    const currentMin = Math.floor((origin.max - origin.min) * changedMinSlider * 0.01 + origin.min);
    const currentMax = Math.floor((origin.max - origin.min) * changedMaxSlider * 0.01 + origin.min);
    return {currentMin, currentMax};
}

export const setRangePriceFilterAtom = selector({
    key: 'setPriceFilter',
    get: ({get}) => {
        return get(priceRangeFilterAtom)
    },
    set: ({get, set}, value) => {
        const origin = get(priceRangeFilterAtom);
        const {
            currentMin,
            currentMax
        } = calculatePriceCurrentRangeUtils(origin, value.changedMinSlider, value.changedMaxSlider)
        if (currentMin === origin.currentMin) {
            if (currentMax === origin.currentMax) {
                return;
            }
        }

        set(priceRangeFilterAtom, {
            min: origin.min,
            max: origin.max,
            currentMin: currentMin,
            currentMax: currentMax,
        })
        const count = get(filterCountAtom);
        set(filterCountAtom, count + 1)
    }
})


export const serializePriceFilterAtom =
    selector({
        key: "serializePriceFilterAtom",
        get: ({get}) => {
            const priceRange = get(priceRangeFilterAtom)
            return `minPrice=${priceRange.currentMin}&&maxPrice=${priceRange.currentMax}`
        }
    })



////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const startDateFilterAtom = atom({
    key: 'startDate',
    default: getToday()
})

export const setStartDateFilterAtom = selector({
    key: 'setStartDate',
    get: ({get}) => {
        return get(startDateFilterAtom)
    },
    set: ({get, set}, value) => {
        if (value === get(startDateFilterAtom)) return
        set(startDateFilterAtom, value)
        // const count = get(filterCountAtom);
        // set(filterCountAtom, count + 1)
    }
})

export const serializeStartDateFilterAtom =
    selector({
        key: "serializeStartDateFilterAtom",
        get: ({get}) => {
            const startDate = get(startDateFilterAtom)
            if (startDate === null) return null
            return `startAt=${startDate}`
        }
    })

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const endDateFilterAtom = atom({
    key: 'endDate',
    default: null
})
export const setEndDateFilterAtom = selector({
    key: 'setEndDate',
    get: ({get}) => {
        return get(endDateFilterAtom)
    },
    set: ({get, set}, value) => {
        // const nextValue = getFormatDate(value);
        if (value === get(endDateFilterAtom)) return
        set(endDateFilterAtom, value)
        // const count = get(filterCountAtom);
        // set(filterCountAtom, count + 1)
    }
})

export const serializeEndDateFilterAtom =
    selector({
        key: "serializeEndDateFilterAtom",
        get: ({get}) => {
            const endDate = get(endDateFilterAtom)
            if (endDate === null) return null
            return `endAt=${endDate}`
        }
    })


////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const searchKeywordChoiceFilterAtom = atom({
    key: 'searchKeywordChoiceFilter',
    default: 'area'
})

export const setSearchKeywordChoiceFilterAtom = selector({
    key: 'setSearchKeywordChoiceFilter',
    get: ({get}) => {
        return get(searchKeywordChoiceFilterAtom)
    },
    set: ({get, set}, value) => {
        set(searchKeywordChoiceFilterAtom, value)
        // const count = get(filterCountAtom);
        // set(filterCountAtom, count + 1)
    }
})

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const orderFilterAtom = atom({
    key: 'orderFilter',
    default: 'popularity'
})

export const setOrderFilterAtom = selector({
    key: 'setOrderFilter',
    get: ({get}) => {
        return get(orderFilterAtom)
    },
    set: ({get, set}, value) => {
        set(orderFilterAtom, value)
        const count = get(filterCountAtom);
        set(filterCountAtom, count + 1)
    }
})

export const serializeOrderFilterAtom =
    selector({
        key: "serializeOrderFilterAtom",
        get: ({get}) => {
            const order = get(orderFilterAtom)
            switch (order) {
                case "popularity":
                    return `orderProduct=popularity`
                case "shortestDuration":
                    return `orderProduct=shortestDuration`
                case "longestDuration":
                    return `orderProduct=longestDuration`
                case "lowestPrice":
                    return `orderProduct=lowestPrice`
                case "highestPrice":
                    return `orderProduct=highestPrice`
                default:
                    return null
            }
        }
    })

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const continentCodeAtom = atom({
    key: 'continentCode',
    default: null
})

export const setContinentCodeAtom = selector({
    key: 'setContinentCodeAtom',
    get: ({get}) => {
        return get(continentCodeAtom)
    },
    set: ({get, set}, value) => {
        if (value === get(continentCodeAtom)) return
        set(continentCodeAtom, value)
        // const count = get(filterCountAtom);
        // set(filterCountAtom, count + 1)
    }
})

export const serializeContinentCodeAtom =
    selector({
        key: "serializeContinentCodeAtom",
        get: ({get}) => {
            const continentCode = get(continentCodeAtom)
            if (continentCode === null) return null
            return `continentMenuId=${continentCode}`
        }
    })

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const nationCodeAtom = atom({
    key: 'nationCode',
    default: null
})

export const setNationCodeAtom = selector({
    key: 'setNationCodeAtom',
    get: ({get}) => {
        return get(nationCodeAtom)
    },
    set: ({get, set}, value) => {
        if (value === get(nationCodeAtom)) return
        set(nationCodeAtom, value)
        // const count = get(filterCountAtom);
        // set(filterCountAtom, count + 1)
    }
})

export const serializeNationCodeAtom =
    selector({
        key: "serializeNationCodeAtom",
        get: ({get}) => {
            const nationCode = get(nationCodeAtom)
            if (nationCode === null) return null
            return `nationMenuId=${nationCode}`
        }
    })

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const cityCodeAtom = atom({
    key: 'cityCode',
    default: null
})

export const setCityCodeAtom = selector({
    key: 'setCityCodeAtom',
    get: ({get}) => {
        return get(cityCodeAtom)
    },
    set: ({get, set}, value) => {
        if (value === get(cityCodeAtom)) return
        set(cityCodeAtom, value)
        // const count = get(filterCountAtom);
        // set(filterCountAtom, count + 1)
    }
})

export const serializeCityCodeAtom =
    selector({
        key: "serializeCityCodeAtom",
        get: ({get}) => {
            const cityCode = get(cityCodeAtom)
            if (cityCode === null) return null
            return `cityCode=${cityCode}`
        }
    })


////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const countryCodeAtom = atom({
    key: 'countryCode',
    default: null
})

export const setCountryCodeAtom = selector({
    key: 'setCountryCodeAtom',
    get: ({get}) => {
        return get(countryCodeAtom)
    },
    set: ({get, set}, value) => {
        set(countryCodeAtom, value)
        // const count = get(filterCountAtom);
        // set(filterCountAtom, count + 1)
    }
})

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const representProductCodeAtom = atom({
    key: 'representProductCode',
    default: null
})

export const setRepresentProductCodeAtom = selector({
    key: 'setRepresentProductCodeAtom',
    get: ({get}) => {
        return get(representProductCodeAtom)
    },
    set: ({get, set}, value) => {
        if (value === get(representProductCodeAtom)) return

        set(representProductCodeAtom, value)
        // const count = get(filterCountAtom);
        // set(filterCountAtom, count + 1)
    }
})


export const serializeRepresentProductCodeAtom =
    selector({
        key: "serializeRepresentProductCodeAtom",
        get: ({get}) => {
            const representProductCode = get(representProductCodeAtom)
            if (representProductCode === null) return null
            return `representProductCode=${representProductCode}`
        }
    })

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const representProductOneAtom = atom({
    key: 'representProductOne',
    default: null
})

export const getRepresentProductOneAtom = selector({
    key: 'getRepresentProductOneAtom',
    get: ({get}) => {
        return get(representProductOneAtom)
    },
})

export const setRepresentProductOneAtom = selector({
    key: 'setRepresentProductOneAtom',
    get: ({get}) => {
        return get(representProductOneAtom)
    },
    set: ({get, set}, value) => {
        if (value === get(representProductOneAtom)) return

        set(representProductOneAtom, value)
    }
})
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


export const searchKeywordAtom = atom({
    key: 'searchKeywordAtom',
    default: ''
})

export const setSearchKeywordAtom = selector({
    key: 'setSearchKeywordAtom',
    get: ({get}) => {
        return get(searchKeywordAtom)
    },
    set: ({get, set}, value) => {
        //    if (value === get(searchKeywordAtom)) return
        set(searchKeywordAtom, value)
        // const count = get(filterCountAtom);
        // set(filterCountAtom, count + 1)
    }
})

export const serializeSearchKeywordAtom =
    selector({
        key: "serializeSearchKeywordAtom",
        get: ({get}) => {
            const sk = get(searchKeywordAtom)
            if (sk === null) return null
            if (sk === '') return null
            return `searchKeyword=${encodeURIComponent(sk)}`
        }
    })
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const queryParamPathAtom = atom({
    key: 'queryParamPath',
    default: null
})