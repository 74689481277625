import Select from "react-select";
import React from "react";
import {CouponType} from "../../../../../domain/model/user/UserCouponEntity";

export class CouponOption {
    constructor(type, value, label, color, isDisabled, useAmount) {
        this.type = type;
        this.value = value;
        this.label = label;
        this.color = color;
        this.isDisabled = isDisabled;
        this.useAmount = useAmount;
    }
}

export default function CouponModalContent({
                                               couponOptionList,
                                               onSelectCouponOption,
                                               onApply,
                                               onClose,
                                           }) {
    const normalCoupon = couponOptionList.filter(coupon => coupon.type === CouponType.Normal);
    const plusCoupon = couponOptionList.filter(coupon => coupon.type === CouponType.Plus);
    const selectedCouponOptionList = couponOptionList.filter(coupon => coupon.selected);

    const selectedNormalCoupon = selectedCouponOptionList.find(coupon => coupon.type === CouponType.Normal)
    const selectedPlusCoupon = selectedCouponOptionList.find(coupon => coupon.type === CouponType.Plus)

    function handleApplyCoupon() {
        onApply && onApply();
    }

    function handleClose(e) {
        e?.stopPropagation();
        onClose && onClose();
    }


    return (
        <>
            <div className="voucher-detail" style={{maxHeight: '750px'}}>
                <section className="lpu-item-sec">
                    <div className="lpu-sub-item">
                        <table summary="PRIVIA 쿠폰" className="tbl-style basic">
                            <caption>PRIVIA 쿠폰, PRIVIA 플러스 쿠폰, 사용할 금액을 포함한 PRIVIA 쿠폰에 대한 테이블</caption>
                            <colgroup>
                                <col width={165}/>
                                <col width="*"/>
                            </colgroup>
                            <tbody>
                            <CouponSelect
                                title={"PRIVIA 쿠폰"}
                                target={CouponType.Normal}
                                couponOptionList={normalCoupon}
                                selectedOption={selectedNormalCoupon}
                                onSelectOption={onSelectCouponOption}
                            />
                            <CouponSelect
                                title={"PRIVIA 플러스 쿠폰"}
                                target={CouponType.Plus}
                                couponOptionList={plusCoupon}
                                selectedOption={selectedPlusCoupon}
                                onSelectOption={onSelectCouponOption}
                            />
                            </tbody>
                        </table>
                    </div>
                    <div className="lpu-sub-item">
                        <div className="lpu-sub-title a-mb20 a-mt10">PRIVIA 쿠폰 사용 안내</div>
                        <ul className="list-dot voucher-desc-list">
                            <li>PRIVIA 호텔에서 발급된 쿠폰은 사이트별 적용 가능 쿠폰이 제한되어 있습니다.</li>
                            <li>쿠폰은 주문 1예약 건당 PRIVIA쿠폰 1매, PRIVIA 플러스쿠폰 1매 사용 가능합니다.</li>
                            <li>쿠폰은 각종 제세공과금 및 수수료 등을 제외한 상품요금에만 적용됩니다.</li>
                            <li>쿠폰은 지정된 기간에만 사용하실 수 있습니다.</li>
                            <li>결제 시 사용한 쿠폰은 즉시 소진되며 예약취소 시 쿠폰의 유효기간이 남아있을 경우 자동 반환됩니다.</li>
                            <li>쿠폰의 발행 속성에 따라 최소 결제금액은 제한될 수 있습니다.</li>
                            <li>쿠폰의 할인 금액보다 할인 대상 금액이 작은 경우 쿠폰 적용은 가능하나 남은 · 할인 대상 금액만큼 사용이 가능하며 쿠폰은 소진됩니다.</li>
                            <li>쿠폰은 다른 현대카드 혜택 등과 병행 사용이 가능하나 다른 혜택 적용으로 결제 대상금액이 0원이 될 경우 적용이 불가능합니다.</li>
                        </ul>
                    </div>
                    <div className="btn-wrap border-top a-right a-pt20">
                    <span className="btn-base btn-st-full btn-bg-bl w130">
                        <a style={{cursor: "pointer"}} onClick={handleApplyCoupon}>적용</a>
                    </span>

                        <span className="btn-base btn-st-full btn-bg-gr w130">
                        <a style={{cursor: "pointer"}} onClick={handleClose}>취소</a>
                    </span>
                    </div>
                </section>
            </div>
        </>
    )
}


const optionStyle = {
    control: (baseStyles, state) => ({
        ...baseStyles,
        width: "100%",
        height: "40px",
        borderRadius: 3,
        boxShadow: "none",
        fontSize: 13,
        fontWeight: 400,
    })
}


function CouponSelect({title, target, couponOptionList, selectedOption, onSelectOption}) {

    const viewTitle = title ?? "PRIVIA 쿠폰";
    const couponDefault = {value: null, label: "쿠폰을 선택해주세요."};

    function handleSelectChange(selectedOption) {
        onSelectOption && onSelectOption(selectedOption, target);
    }


    return (
        <tr>
            <th scope="row" className="a-left">{viewTitle}</th>
            <td>
                <div style={{display: "flex"}}>
                    <div className={"select-base sel-st2"} style={{width: "60%"}}>
                        <Select options={[couponDefault, ...couponOptionList]}
                            value={selectedOption ?? couponDefault}
                            onChange={handleSelectChange}
                            styles={optionStyle}
                            isSearchable={false}
                            components={{IndicatorSeparator: () => null}}
                        />
                    </div>
                    <div className="input-area">
                        <input type="text" className="input-base type01 w170"
                               defaultValue={0}
                               readOnly={true}
                               value={selectedOption?.useAmount || 0}/>
                        <span className="won">원</span>
                    </div>
                </div>
            </td>
        </tr>
    )
}

