import React, {useEffect, useState} from "react";
import useProductFilter from "../../../usecase/product-filter/parent/userProductFilter";
import {formatNumber} from "../../../../../utils/formatNumber";
import RangeSlider from "react-range-slider-input";
import {AirlineCodeType} from "../../../../../search/AirlineCodeType";
import useMoreThanList from "../../../../infra/hooks/useMoreThanList";
import useDebounce from "../../../../infra/hooks/useDebounce";


// ProductFilter
export default function ParentProductFilter({progress}) {

    const {resetFilterAll} = useProductFilter()

    // if (progress < 100) {
    //     return <SearchSkeletonLeft/>
    // }

    function onResetFilter(e) {
        resetFilterAll()
    }

    return (
        <div className="pkg-listl">
            <div className="pkg-sch-set-accomm">
                <div className="sch-set-inner">
                    <div className="tit-wrap between-box fc">
                        <div className="tit-h3">결과 내 검색</div>
                        <a style={{cursor: "pointer"}} onClick={onResetFilter} className="btn-init reset">초기화</a>
                    </div>
                    <div className="filter-wrap">
                        <SupplierFilter/>
                        <ProductTypeFilter/>
                        <PeriodFilter/>
                        <PriceRangeFilter/>
                        <AirLineFilter/>
                        <DepartureCityFilter/>
                        <DepartureTimeFilter/>
                        <GradeFilter/>
                        <HotelFilter/>
                        <ConditionFilter/>
                    </div>
                </div>
            </div>
        </div>
    )
}

function ConditionFilter() {
    const {
        filter: {conditionFilterList, shopCountFilterList},
        reset: {
            resetConditionFilterList,
            resetShopCountFilterList
        },
        toggle: {
            toggleConditionFilterList,
            toggleShopCountFilterList
        }
    } = useProductFilter()

    function reset() {
        resetConditionFilterList()
        resetShopCountFilterList()
    }

    function onToggleShopCount(value) {
        toggleShopCountFilterList(value)
    }

    return (
        <div className="filter-group">
            <div className="filter-header between-box fc">
                <span className="tit-filter">투어 조건</span>
                <a style={{cursor: "pointer"}} className="btn-init"
                   onClick={(e) => reset()}>선택해제</a>
            </div>
            <ul className="filter-list">
                <li>
                      <span className="check-group">
                        <input type="checkbox" className="hidden_check"
                               id="chk-tourcondtion-1"
                               name="tourCondtion"
                               checked={conditionFilterList.includes('includeFreeTour')}
                               onChange={() => toggleConditionFilterList("includeFreeTour")}/>
                        <label htmlFor="chk-tourcondtion-1" className="label-check">자유일정포함</label>
                      </span>
                </li>

                <li key={0}>
                        <span className="check-group">
                            <input type="checkbox"
                                   className="hidden_check"
                                   id="chk-tourcondtion-2"
                                   name="tourCondtion"
                                   checked={shopCountFilterList.map(f => `${f.method}_${f.count}`).includes('equal_0')}
                                   onChange={() => onToggleShopCount({count: '0', method: 'equal'})}/>
                            <label htmlFor="chk-tourcondtion-2"
                                   className="label-check">쇼핑 없음</label>
                        </span>
                </li>

                <li key={1}>
                        <span className="check-group">
                            <input type="checkbox"
                                   className="hidden_check"
                                   id="chk-tourcondtion-3"
                                   name="tourCondtion"
                                   checked={shopCountFilterList.map(f => `${f.method}_${f.count}`).includes('equal_1')}
                                   onChange={() => onToggleShopCount({count: '1', method: 'equal'})}/>
                            <label htmlFor="chk-tourcondtion-3"
                                   className="label-check">쇼핑 1회</label>
                        </span>
                </li>


                <li key={2}>
                        <span className="check-group">
                            <input type="checkbox" className="hidden_check"
                                   id="chk-tourcondtion-4" name="tourCondtion"
                                   checked={shopCountFilterList.map(f => `${f.method}_${f.count}`).includes('equal_2')}
                                   onChange={() => onToggleShopCount({count: '2', method: 'equal'})}/>
                            <label htmlFor="chk-tourcondtion-4"
                                   className="label-check">쇼핑 2회</label>
                        </span>
                </li>


                <li key={2}>
                        <span className="check-group">
                            <input type="checkbox" className="hidden_check"
                                   id="chk-tourcondtion-5" name="tourCondtion"
                                   checked={shopCountFilterList.map(f => `${f.method}_${f.count}`).includes('over_3')}
                                   onChange={() => onToggleShopCount({count: '3', method: 'over'})}/>
                            <label htmlFor="chk-tourcondtion-5"
                                   className="label-check">쇼핑 3회 이상</label>
                        </span>
                </li>

                {/*{*/}
                {/*    shoppingList && shoppingList.filter(shopping => shopping.count === 0).length > 0 &&*/}
                {/*    (*/}
                {/*        <li key={0}>*/}
                {/*                                <span className="check-group">*/}
                {/*                                    <input type="checkbox" className="hidden_check"*/}
                {/*                                           id="chk-tourcondtion-2" name="tourCondtion"*/}
                {/*                                           checked={filter.condition.shopping && filter.condition.shopping.find(s => s.count === 0).isSelected}*/}
                {/*                                           onChange={() => handleClickShopping('condition', 'shopping_0', "쇼핑없음")}/>*/}
                {/*                                    <label htmlFor="chk-tourcondtion-2"*/}
                {/*                                           className="label-check">쇼핑없음</label>*/}
                {/*                                </span>*/}
                {/*        </li>*/}
                {/*    )*/}
                {/*}*/}
                {/*{*/}
                {/*    shoppingList && shoppingList.filter(shopping => shopping.count > 0 && shopping.count < 9).map((shopping, index) => (*/}
                {/*        <li key={index + 1}>*/}
                {/*                                <span className="check-group">*/}
                {/*                                    <input type="checkbox" className="hidden_check"*/}
                {/*                                           id={`chk-tourcondtion-${index + 3}`} name="tourCondtion"*/}
                {/*                                           checked={filter.condition.shopping && filter.condition.shopping.find(s => s.count === shopping.count).isSelected}*/}
                {/*                                           onChange={() => handleClickShopping('condition', 'shopping_' + shopping.count, shopping.count + "회")}/>*/}
                {/*                                    <label htmlFor={`chk-tourcondtion-${index + 3}`}*/}
                {/*                                           className="label-check">{`${shopping.count}회`}</label>*/}
                {/*                                </span>*/}
                {/*        </li>*/}
                {/*    ))*/}
                {/*}*/}
                {/*{*/}
                {/*    shoppingList && shoppingList.filter(shopping => shopping.count > 9).length > 0 &&*/}
                {/*    (*/}
                {/*        <li key={10}>*/}
                {/*                                <span className="check-group">*/}
                {/*                                    <input type="checkbox" className="hidden_check"*/}
                {/*                                           id="chk-tourcondtion-12" name="tourCondtion"*/}
                {/*                                           checked={filter.condition.shopping && filter.condition.shopping.find(s => s.count === 9).isSelected}*/}
                {/*                                           onChange={() => handleClickShopping('condition', 'shopping_9', "쇼핑 9회 이상")}/>*/}
                {/*                                    <label htmlFor="chk-tourcondtion-12" className="label-check">쇼핑 9회 이상</label>*/}
                {/*                                </span>*/}
                {/*        </li>*/}
                {/*    )*/}
                {/*}*/}
            </ul>
        </div>

    )
}

function HotelFilter() {
    const {
        filter: {hotelFilterList},
        reset: {resetHotelFilterList},
        toggle: {toggleHotelFilterList}
    } = useProductFilter()

    function onToggle(filterValue) {
        toggleHotelFilterList(filterValue)
    }

    return (
        <div className="filter-group">
            <div className="filter-header between-box fc">
                <span className="tit-filter">호텔 성급</span>
                <a style={{cursor: "pointer"}} className="btn-init"
                   onClick={(e) => resetHotelFilterList()}>선택해제</a>
            </div>
            <ul className="filter-list">
                <li>
                      <span className="check-group">
                        <input type="checkbox" className="hidden_check" id="chk-hotelgrade-1"
                               name="hotelGrade" checked={hotelFilterList.includes('fiveStar')}
                               onChange={() => onToggle('fiveStar')}/>
                        <label htmlFor="chk-hotelgrade-1" className="label-check">5성급</label>
                      </span>
                </li>
                <li>
                      <span className="check-group">
                        <input type="checkbox" className="hidden_check" id="chk-hotelgrade-2"
                               name="hotelGrade" checked={hotelFilterList.includes('fourStar')}
                               onChange={() => onToggle('fourStar')}/>
                        <label htmlFor="chk-hotelgrade-2" className="label-check">4성급</label>
                      </span>
                </li>
                <li>
                      <span className="check-group">
                        <input type="checkbox" className="hidden_check" id="chk-hotelgrade-3"
                               name="hotelGrade" checked={hotelFilterList.includes('threeStar')}
                               onChange={() => onToggle('threeStar')}/>
                        <label htmlFor="chk-hotelgrade-3" className="label-check">3성급</label>
                      </span>
                </li>
                <li>
                      <span className="check-group">
                        <input type="checkbox" className="hidden_check" id="chk-hotelgrade-4"
                               name="hotelGrade" checked={hotelFilterList.includes('etc')}
                               onChange={() => onToggle('etc')}/>
                        <label htmlFor="chk-hotelgrade-4" className="label-check">기타</label>
                      </span>
                </li>
            </ul>
        </div>
    )
}

function GradeFilter() {
    const {
        filter: {gradeFilterList},
        reset: {resetGradeFilterList},
        toggle: {toggleGradeFilterList}
    } = useProductFilter()

    function onToggle(filterValue) {
        toggleGradeFilterList(filterValue)
    }

    return (
        <div className="filter-group">
            <div className="filter-header between-box fc">
                <span className="tit-filter">상품 등급
                  <span className="help-tooltip small darker">
                    <span className="over">?</span>
                    <div className="cont grade" style={{width: 248}}>
                      <ul className="level-list">
                        <li>
                          <div
                              className="td-style">프리미엄<br/>고급스러움과 편안함, 보통과 다른 특별한 가치를 담은 고품격 상품</div>
                        </li>
                        <li>
                          <div className="td-style">프라이빗<br/>단독 가이드/차량 이용하여 소규모로 떠나는 프라이빗 상품</div>
                        </li>
                        <li>
                          <div className="td-style">가성비甲<br/>가성비, 효율성, 편리함을 모두 담은 실속형 상품</div>
                        </li>
                      </ul>
                    </div>
                  </span>
                </span>
                <a style={{cursor: "pointer"}} className="btn-init"
                   onClick={(e) => resetGradeFilterList()}>선택해제</a>
            </div>

            <ul className="filter-list">
                <li>
                      <span className="check-group">
                        <input type="checkbox" className="hidden_check" id="chk-pdgrade-3"
                               name="productGrade" checked={gradeFilterList.includes("PREMIUM")}
                               onChange={() => onToggle('PREMIUM')}/>
                        <label htmlFor="chk-pdgrade-3" className="label-check">프리미엄</label>
                      </span>
                </li>
                <li>
                      <span className="check-group">
                        <input type="checkbox" className="hidden_check" id="chk-pdgrade-2"
                               name="productGrade" checked={gradeFilterList.includes("PRIVATE")}
                               onChange={() => onToggle('PRIVATE')}/>
                        <label htmlFor="chk-pdgrade-2" className="label-check">프라이빗</label>
                      </span>
                </li>
                <li>
                      <span className="check-group">
                        <input type="checkbox" className="hidden_check" id="chk-pdgrade-1"
                               name="productGrade" checked={gradeFilterList.includes("SAVE")}
                               onChange={() => onToggle('SAVE')}/>
                        <label htmlFor="chk-pdgrade-1" className="label-check">가성비甲</label>
                      </span>
                </li>
            </ul>
        </div>
    )
}

function DepartureTimeFilter() {
    const {
        filter: {departureTimeFilterList},
        reset: {resetDepartureTimeFilterList},
        toggle: {toggleDepartureTimeFilterList}
    } = useProductFilter()

    function onToggle(filterValue) {
        toggleDepartureTimeFilterList(filterValue)
    }


    return (
        <div className="filter-group">
            <div className="filter-header between-box fc">
                <span className="tit-filter">출발 시간</span>
                <a style={{cursor: "pointer"}} className="btn-init"
                   onClick={(e) => resetDepartureTimeFilterList()}>선택해제</a>
            </div>
            <ul className="filter-list">
                <li>
                      <span className="check-group">
                        <input type="checkbox" className="hidden_check" id="chk-departtime-1"
                               name="departureTime"
                               checked={departureTimeFilterList.includes("dawn")}
                               onChange={() => onToggle("dawn")}/>
                        <label htmlFor="chk-departtime-1" className="label-check">새벽(00~05시)</label>
                      </span>
                </li>
                <li>
                      <span className="check-group">
                        <input type="checkbox" className="hidden_check" id="chk-departtime-2"
                               name="departureTime"
                               checked={departureTimeFilterList.includes("morning")}
                               onChange={() => onToggle("morning")}/>
                        <label htmlFor="chk-departtime-2" className="label-check">아침(05~12시)</label>
                      </span>
                </li>
                <li>
                      <span className="check-group">
                        <input type="checkbox" className="hidden_check" id="chk-departtime-3"
                               name="departureTime"
                               checked={departureTimeFilterList.includes("lunch")}
                               onChange={() => onToggle("lunch")}/>
                        <label htmlFor="chk-departtime-3" className="label-check">점심(12~18시)</label>
                      </span>
                </li>
                <li>
                      <span className="check-group">
                        <input type="checkbox" className="hidden_check" id="chk-departtime-4"
                               name="departureTime"
                               checked={departureTimeFilterList.includes("afternoon")}
                               onChange={() => onToggle("afternoon")}/>
                        <label htmlFor="chk-departtime-4" className="label-check">저녁(18~24시)</label>
                      </span>
                </li>
            </ul>
        </div>

    )
}

function DepartureCityFilter() {
    const BASE_VIEW_COUNT = 5;
    const departureCityList = [
        {value: 'JCN', label: '인천/김포'},
        {value: 'PUS', label: '부산'},
        {value: 'CJJ', label: '청주'},
        {value: 'KWJ', label: '광주(무안)'},
        {value: 'CJU', label: '제주'},
        {value: 'TAE', label: '대구'},
        {value: 'YNY', label: '양양'},
    ]
    const {
        filter: {departureCityFilterList},
        reset: {resetDepartureCityFilterList},
        toggle: {toggleDepartureCityFilterList}
    } = useProductFilter()
    const {isViewAll, viewList, addViewCount} = useMoreThanList({
        originList: departureCityList,
        constAddCount: BASE_VIEW_COUNT,
        defaultViewCount: BASE_VIEW_COUNT
    })

    function onToggle(filterValue) {
        toggleDepartureCityFilterList(filterValue)
    }

    function handleClickMore(e) {
        e.stopPropagation()
        addViewCount()
    }


    return (
        <div className="filter-group">
            <div className="filter-header between-box fc">
                <span className="tit-filter">출발 도시</span>
                <a style={{cursor: "pointer"}} className="btn-init"
                   onClick={(e) => resetDepartureCityFilterList()}>선택해제</a>
            </div>
            <ul className="filter-list">
                {
                    viewList.map(({value, label}) =>
                        <li key={value}>
                           <span className="check-group">
                            <input type="checkbox"
                                   className="hidden_check"
                                   id={"chk-city-" + value}
                                   name="departureCity"
                                   checked={departureCityFilterList.includes(value)}
                                   onChange={() => onToggle(value)}/>
                             <label htmlFor={"chk-city-" + value} className="label-check">{label}</label>
                           </span>
                        </li>
                    )
                }
            </ul>
            <div className="btn-base btn-st-uline more-filter-btn">
                {
                    !isViewAll &&
                    <button type="button" onClick={(e) => {
                        handleClickMore(e)
                    }}>+ 더보기
                    </button>
                }
            </div>
        </div>

    )
}

function AirLineFilter() {
    const MORE_VIEW_COUNT = 5
    const {
        filter: {airlineFilterList},
        constValue: {allAirlineList},
        reset,
        toggle,
    } = useProductFilter()

    const {
        viewList,
        addViewCount,
        isViewAll,
        setOriginList
    } = useMoreThanList({
        originList: allAirlineList,
        constAddCount: MORE_VIEW_COUNT,
        defaultViewCount: MORE_VIEW_COUNT
    })

    function onClear(e) {
        e.stopPropagation()
        reset.resetAirlineFilterList()
    }

    function onToggle(airline) {
        toggle.toggleAirlineFilterList(airline)
    }

    function handleClickMore(e) {
        e.stopPropagation()
        addViewCount()
    }

    useEffect(() => {
        if (Array.isArray(allAirlineList)) {
            const arrayAir = Array.from(allAirlineList)
            setOriginList(arrayAir)
        }
    }, [allAirlineList])

    return (
        <div className="filter-group">
            <div className="filter-header between-box fc">
                <span className="tit-filter">항공사</span>
                <a style={{cursor: "pointer"}} className="btn-init"
                   onClick={(e) => onClear(e)}>선택해제</a>
            </div>
            <ul className="filter-list">
                {
                    viewList.map((air, index) =>
                        (
                            <li key={"airline_" + air}>
                                <span className="check-group">
                                    <input type="checkbox" className="hidden_check"
                                           id={`chk-airline-${index + 1}`} name="airline"
                                           checked={airlineFilterList.includes(air)}
                                           onChange={() => onToggle(air)}/>
                                    <label htmlFor={`chk-airline-${index + 1}`}
                                           className="label-check">{AirlineCodeType[air] || "unknown"}</label>
                                </span>
                            </li>
                        )
                    )
                }
            </ul>
            {!isViewAll &&
                <div className="btn-base btn-st-uline more-filter-btn">
                    <button type="button" onClick={handleClickMore}>+ 더보기
                    </button>
                </div>
            }
        </div>
    )
}

function PriceRangeFilter() {
    const {
        filter: {priceRangeFilter},
        range: {setPriceRangeFilter},
        reset: {resetPriceRangeFilter},
        calculate: {calculatePriceRangeFilter}
    } = useProductFilter()

    const [minSlider, setMinSlider] = useState(0)
    const [maxSlider, setMaxSlider] = useState(100)
    const debounceMinSlider = useDebounce(minSlider, 800)
    const debounceMaxSlider = useDebounce(maxSlider, 800)
    const {currentMin, currentMax} = calculatePriceRangeFilter(minSlider, maxSlider)
    function initSlider() {
        setMinSlider(0)
        setMaxSlider(100)
    }
    function setSlider(value) {
        const minPrice = value.at(0) < value.at(1) ? value.at(0) : value.at(1);
        const maxPrice = value.at(0) > value.at(1) ? value.at(0) : value.at(1);
        setMinSlider(() => minPrice);
        setMaxSlider(() => maxPrice);
    }

    function onReset(e) {
        e && e.stopPropagation()
        resetPriceRangeFilter({forceUpdate: true})
        initSlider()
    }

    useEffect(() => {
        setPriceRangeFilter({
            changedMinSlider: debounceMinSlider,
            changedMaxSlider: debounceMaxSlider,
        })
    }, [debounceMinSlider, debounceMaxSlider]);

    useEffect(() => {
        if (priceRangeFilter.min !== priceRangeFilter.currentMin) return
        if (priceRangeFilter.max !== priceRangeFilter.currentMax) return
        initSlider()

    }, [priceRangeFilter.min, priceRangeFilter.max, priceRangeFilter.currentMin, priceRangeFilter.currentMax])


    return (
        <div className="filter-group">
            <div className="filter-header between-box fc">
                <span className="tit-filter">상품 가격</span>
                <a style={{cursor: "pointer"}} className="btn-init"
                   onClick={onReset}> 선택해제 </a>
            </div>
            <div className="slider-price-wrap">
                <div className="slider-result">

                    <>
                        <span className="t">최저</span>
                        <span className="num" id="priceRangeMin">
                                    {formatNumber(currentMin)}
                        </span>
                        <span className="t">
                                    원
                        </span>

                        <>
                            <span className="between">~</span>
                            <span className="t">최고</span>
                            <span className="num"
                                  id="priceRangeMax">{formatNumber(currentMax)}</span><span
                            className="t">원</span>
                        </>

                    </>

                </div>
                <div className="slider-price">
                    <RangeSlider id="range-slider-package"
                                 min={0}
                                 max={100}
                                 value={[minSlider, maxSlider]}
                                 onInput={setSlider}
                    />
                </div>
            </div>
        </div>
    )
}

function PeriodFilter() {
    const {
        filter: {
            periodFilterList
        },
        reset,
        toggle
    } = useProductFilter()


    function onClear(e) {
        e.stopPropagation()
        reset.resetPeriodFilterList()
    }

    function onToggle(day) {
        toggle.togglePeriodFilterList(day)
    }

    return (
        <div className="filter-group">
            <div className="filter-header between-box fc">
                <span className="tit-filter">여행 기간</span>
                <a style={{cursor: "pointer"}} className="btn-init" onClick={onClear}>선택해제</a>
            </div>
            <ul className="filter-list">
                <li>
                      <span className="check-group">
                        <input type="checkbox" className="hidden_check" id="chk-day-1" name="tourPeriod"
                               checked={periodFilterList.includes("withinFourDay")}
                               onChange={() => onToggle("withinFourDay")}/>
                        <label htmlFor="chk-day-1" className="label-check">4일 이내</label>
                      </span>
                </li>
                <li>
                      <span className="check-group">
                        <input type="checkbox" className="hidden_check" id="chk-day-2" name="tourPeriod"
                               checked={periodFilterList.includes("fiveToEight")}
                               onChange={() => onToggle('fiveToEight')}/>
                        <label htmlFor="chk-day-2" className="label-check">5~8일</label>
                      </span>
                </li>
                <li>
                      <span className="check-group">
                        <input type="checkbox" className="hidden_check" id="chk-day-3" name="tourPeriod"
                               checked={periodFilterList.includes("nineToTen")}
                               onChange={() => onToggle('nineToTen')}/>
                        <label htmlFor="chk-day-3" className="label-check">9~10일</label>
                      </span>
                </li>
                <li>
                      <span className="check-group">
                        <input type="checkbox" className="hidden_check" id="chk-day-4" name="tourPeriod"
                               checked={periodFilterList.includes("elevenToSixteen")}
                               onChange={() => onToggle('elevenToSixteen')}/>
                        <label htmlFor="chk-day-4" className="label-check">11~16일</label>
                      </span>
                </li>
                <li>
                      <span className="check-group">
                        <input type="checkbox" className="hidden_check" id="chk-day-5" name="tourPeriod"
                               checked={periodFilterList.includes("beyondSeventeen")}
                               onChange={() => onToggle('beyondSeventeen')}/>
                        <label htmlFor="chk-day-5" className="label-check">17일 이상</label>
                      </span>
                </li>
            </ul>
        </div>
    )
}

function ProductTypeFilter() {

    const {
        filter: {productTypeFilterList},
        constValue: {allProductTypeList},
        reset,
        toggle
    } = useProductFilter()

    function onClear(e) {
        e.stopPropagation()
        reset.resetProductTypeFilterList()
    }

    function onToggle(productType) {
        toggle.toggleProductTypeFilterList(productType)
    }

    return (
        <div className="filter-group">
            <div className="filter-header between-box fc">
                <span className="tit-filter">상품 타입</span>
                <a style={{cursor: "pointer"}} className="btn-init"
                   onClick={onClear}>선택해제</a>
            </div>
            <ul className="filter-list">
                {
                    allProductTypeList.map(productType => {
                        const tagId = "chk-pdtype-1" + productType
                        return (
                            <li>
                             <span className="check-group">
                                <input type="checkbox" className="hidden_check" id={tagId}
                                       name={"productType"}
                                       checked={productTypeFilterList.includes(productType)}
                                       onChange={() => onToggle(productType)}/>
                                 <label htmlFor={tagId} className="label-check">{productType}</label>
                             </span>
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    )
}

function SupplierFilter() {
    const {
        filter,
        toggle,
        reset,
        constValue: {allSupplierList}

    } = useProductFilter()
    const {supplierFilterList} = filter

    function onToggle(supplier) {
        toggle.toggleSupplierFilterList(supplier)
    }

    function onClear() {
        reset.resetSupplierFilterList()
    }


    return (
        <div className="filter-group">
            <div className="filter-header between-box fc">
                <span className="tit-filter">여행사</span>
                <a style={{cursor: "pointer"}} className="btn-init" onClick={onClear}>선택해제</a>
            </div>
            <ul className="filter-list">
                {
                    allSupplierList.map(supplier => {
                        const key = `${supplier}_chk-comp-1`
                        return (
                            <li key={key}>
                                <span className="check-group">
                                    <input type="checkbox"
                                           className="hidden_check"
                                           id={key} name="supplier"
                                           checked={supplierFilterList.includes(supplier)}
                                           onChange={() => onToggle(supplier)}/>
                                    <label htmlFor={key} className="label-check">{supplier}</label>
                                </span>
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    )
}