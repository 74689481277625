import usePaymentPrice from "../usePaymentPrice";
import {ALL_CARD_OPTIONS} from "../../../state/reservation/PaymentPriceState";


export default function useCreditCard(eventManager) {
    const {
        cardOption,
        setCardOption,
        usingBenefit
    } = usePaymentPrice()


    return {
        cardCode: cardOption ? cardOption.value : null,
        creditCardOptionList: ALL_CARD_OPTIONS,
        selectedCreditCardOption: cardOption,
        onChangeCreditCard: (opt) => {
            if (usingBenefit.hcc3 || usingBenefit.family) {
                alert("현대 카드 혜택 할인을 받을 수 없습니다.")
            }
            opt && setCardOption(opt.value);
        },
        // getSubmitData,
    }
}