import {useEffect, useState} from "react";
import {findParentProductList, findParentProductListDynamicFilter} from "../../domain/repository/ProductRepository";
import useProductFilter from "../usecase/product-filter/parent/userProductFilter";
import {useParams} from "react-router-dom";
import useSerializeProductFilter from "../usecase/product-filter/parent/useSerializeProductFilter";
import useScrollThreshold from "../../infra/hooks/useScrollThreshold";
import {useSetRecoilState} from "recoil";
import {setRepresentProductOneAtom} from "../state/ParentProductFilterState";

export default function useLoadProductDynamicList() {

    const pathParams = useParams();         //코드검색
    const serializedParam = useSerializeProductFilter()
    const {filter: {paging}, filterCount, dynamicFilterCount, dynamicFilter} = useProductFilter()
    const [productList, setProductList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const setRepresentProductOne = useSetRecoilState(setRepresentProductOneAtom)
    const {
        nextPage,
        isNextPage,
        initPaging,
        perPage,
        setTotalCount,
        totalCount
    } = paging


    useScrollThreshold({
        onScrollThresholdReached: () => {
            if (loading === false && error === false) {
                if (isNextPage()) {
                    const nextPageValue = nextPage()
                    nextPageValue && loadProducts(`${serializedParam}&&page=${nextPageValue.page}&&perPage=${nextPageValue.perPage}`, pathParams).then(res => {
                        const data = res.data
                        setTotalCount(data['totalCount'])
                        setProductList([...productList, ...data['list']])
                    })
                }
            }
        }
    })

    async function loadProducts(params) {
        setLoading(true)
        setError(false)
        try {
            const res = await findParentProductList(params)
            setLoading(false)
            return res
        } catch (e) {
            console.log(error)
            setError(true)
        }
    }

    async function loadDynamicFilter(params) {
        try {
            return await findParentProductListDynamicFilter(params)
        } catch (e) {
            console.log(error)
        }
    }

    useEffect(() => {
        initPaging()
        if (filterCount === 0) return
        loadDynamicFilter(`${serializedParam}`).then(res => {
            const fData = res.data;
            console.log(fData)
            const params = serializedParam.split('&&').map((item) => {
                const keyVal = item.split('=');
                if(keyVal?.[0] === 'minPrice'){
                    return `minPrice=${fData['minPrice']}`;
                }else if(keyVal?.[0] === 'maxPrice'){
                    return `maxPrice=${fData['maxPrice']}`;
                }
                return item;
            });

            loadProducts(`${params.join('&&')}&&page=${1}&&perPage=${perPage}`).then(res => {
                const data = res.data
                setTotalCount(data['totalCount'])
                setProductList(data['list'])
                if (data['list'] && data['list'].length > 0) {
                    setRepresentProductOne(data['list'][0])
                }
            })
        })
        
    }, [filterCount]);

    useEffect(() => {
        if (dynamicFilterCount === 0) return
        // 로딩할 떄.. 다이나믹 필터 로딩 막음..
        //  if (loading === true) return

        loadDynamicFilter(`${serializedParam}`).then(res => {
            const data = res.data
            if (data == null) return
            dynamicFilter.setPriceFilterMinMax({
                "min": data['minPrice'],
                "max": data['maxPrice']
            })
            const airlineList = data['airLineCodes']
            if (Array.isArray(airlineList)) {
                dynamicFilter.setAllAirlineList(airlineList)
            }
        })
    }, [dynamicFilterCount]);

    function isEmpty() {
        if (loading === false && error === false) {
            return productList.length === 0
        }
        return false
    }

    return {
        productList,
        loading,
        error,
        totalCount,
        isEmpty: isEmpty(),
    }
}