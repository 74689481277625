import React from "react";
import SearchKeywordPanel from "./SearchKeywordPanel";
import ContinentPanel from "./ContinentPanel";


export default function Index(props) {
    const {
        mode,
        isAreaLoading,
        onClickProduct,
        onClickAreaByKeyword,
        isKeywordLoading,
        show,
        continentList,
        onClickContinentPanel,
        keyword,
        searchByKeyword,
    } = props

    if (!show) return null

    function getRootCls() {
        switch (mode) {
            case `area`:
                return "sc-ui-search-panel ui-mainsel-package on"
            case `keyword`:
                return "sc-ui-search-panel ui-mainsel-package on ui-search-auto"
            default:
                return "sc-ui-search-panel ui-mainsel-package ui-search-auto"
        }
    }

    // 일단 area
    return (
        <div className={getRootCls()}>
            <ContinentPanel continentList={continentList} onClickContinentPanel={onClickContinentPanel}/>
            <SearchKeywordPanel
                isLoading={isKeywordLoading}
                keyword={keyword}
                searchKeyword={searchByKeyword}
                onClickProduct={onClickProduct}
                onClickArea={onClickAreaByKeyword}
            />
            <a style={{cursor: "pointer"}} className="b-scb-close"><span className="a11y">닫기</span></a>
        </div>
    )
}
