import Modal from "@mui/material/Modal";
import axios from "axios";
import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import { findMPoint } from "../../../../domain/repository/HccCardRepository";
import { useRecoilState } from "recoil";
import { mPointState } from "../../../state/user/HccCardDiscountState";

const hCardList = [
    { label: 'the Purple', value: '퍼플' },
    { label: 'the Red', value: '레드' },
    { label: 'Summit', value: '써밋' },
    { label: 'the Green', value: '그린' },
    { label: 'the Pink', value: '핑크' },
    { label: '기타', value: '기타' }
]

const HyundaiAuth = ({open, onClose, userCtx, toggleMPoint}) => {
    const [mPointRecoil, setMPointRecoil] = useRecoilState(mPointState)
    const {user} = userCtx;
    const [isShow, setIsShow] = useState(true);
    const [termText, setTermText] = useState('');
    const termTextRef = useRef(false);
    const inputRefs = useRef({
        cardNo: null,
        validTermMonth: null,
        validTermYear: null,
        pwd: null,
        cvv: null,
        agree: null,
    });
    const [cardInfo, setCardInfo] = useState({
        hccGrade: '퍼플',
        cardNo1: '',
        cardNo2: '',
        cardNo3: '',
        cardNo4: '',
        validTermMonth: '',
        validTermYear: '',
        pwd: '',
        cvv: '',
        hccAuthDivnCd: 'AUTH601',
        agree: false,
    });
    const [cardInfoErr, setCardInfoErr] = useState({
        cardNo: false,
        validTermMonth: false,
        validTermYear: false,
        pwd: false,
        cvv: false,
        agree: false,
    });

    useEffect(() => {
        if(!termTextRef.current){
            termTextRef.current = true;
            const priviaUrl = process.env.REACT_APP_PRIVIA_BASE_URL;
            axios.get(`${priviaUrl}/etc/indivInfoThird3.ajax`).then((res) => {
                setTermText(res.data);
            });
        }
    }, []);

    const termToggle = useCallback(() => {
        setIsShow((prev) => !prev);
    }, []);

    const changeHandler = useCallback((type) => (e) => {
        const {name, value, checked} = e.target;
        if(name.includes('cardNo')){
            setCardInfoErr((prev) => ({...prev, cardNo: false}));
        }else{
            setCardInfoErr((prev) => ({...prev, [name]: false}));
        }

        if(type === 'number'){
            setCardInfo((prev) => ({...prev, [name]: value.replace(/[^0-9]/g, '')}));
        } else if(type === 'checkbox') {
            setCardInfo((prev) => ({...prev, [name]: checked}));
        } else {
            setCardInfo((prev) => ({...prev, [name]: value}));
        }
    }, []);

    const onFocus = useCallback((name) => {
        if(inputRefs.current && inputRefs.current[name]){
            inputRefs.current[name].focus();
        }
    }, []);

    const onApply = useCallback(() => {
        if(!(cardInfo.cardNo1 && cardInfo.cardNo2 && cardInfo.cardNo3 && cardInfo.cardNo4)) {
            setCardInfoErr((prev) => ({...prev, cardNo: true}));
            onFocus('cardNo');
            return;
        }
        if(!cardInfo.validTermMonth || cardInfo.validTermMonth.length !== 2 || Number(cardInfo.validTermMonth) > 12) {
            setCardInfoErr((prev) => ({...prev, validTermMonth: true}));
            onFocus('validTermMonth');
            return;
        }
        if(!cardInfo.validTermYear || cardInfo.validTermYear.length !== 2 || Number(moment().format('YY')) > Number(cardInfo.validTermYear)) {
            setCardInfoErr((prev) => ({...prev, validTermYear: true}));
            onFocus('validTermYear');
            return;
        }
        if(!cardInfo.pwd || cardInfo.pwd.length !== 2) {
            setCardInfoErr((prev) => ({...prev, pwd: true}));
            onFocus('pwd');
            return;
        }
        if(!cardInfo.cvv || cardInfo.cvv.length !== 3) {
            setCardInfoErr((prev) => ({...prev, cvv: true}));
            onFocus('cvv');
            return;
        }
        if(!cardInfo.agree) {
            setCardInfoErr((prev) => ({...prev, agree: true}));
            onFocus('agree');
            return;
        }

        try{
            setMPointRecoil({
                ...mPointRecoil,
                loading: true,
                error: null
            });

            const frontUrl = process.env.REACT_APP_PRIVIA_BASE_URL;
            axios.post(`${frontUrl}/common/hcctran/confirm.json`, {
                ...cardInfo,
                cardNo: `${cardInfo.cardNo1}${cardInfo.cardNo2}${cardInfo.cardNo3}${cardInfo.cardNo4}`,
                validTerm: `20${cardInfo.validTermYear}${cardInfo.validTermMonth}`,
            }, {withCredentials: true, headers: {'Content-Type': 'application/x-www-form-urlencoded'}}).then((res) => {
                if(res.data.success === 'Y'){
                    findMPoint(userCtx.user.data.memberId, res.data.hccMyCustNo).then((res) => {
                        setMPointRecoil({
                            ...mPointRecoil,
                            loading: false,
                            mPoint: res.data
                        });
                        toggleMPoint && toggleMPoint();
                        onClose();
                    }).catch((err) => {
                        setMPointRecoil({
                            ...mPointRecoil,
                            loading: false,
                            error: err
                        });
                    });
                }else {
                    alert(res.data.msg);
                    setMPointRecoil({
                        ...mPointRecoil,
                        loading: false,
                        error: res.data.msg
                    });
                    onClose();
                }
            });
        }catch(e) {
            alert('현대카드 인중 중에 에러가 발생하였습니다. 잠시 후 다시 시도해주세요.');
            setMPointRecoil({
                ...mPointRecoil,
                loading: false,
                error: e
            });
        }

    }, [cardInfo, onFocus, onClose, mPointRecoil, setMPointRecoil, userCtx.user.data.memberId, toggleMPoint]);
    
    return (
        <>
            <Modal open={open} onClose={onClose}>
                <div id="" className="popup-wrap" style={{width: 830}}>
                    <div className="heading">
                    <h2 className="noto">현대카드 회원 확인</h2>
                    <a className="btn_close" onClick={onClose} style={{cursor: 'pointer'}}>닫기</a>
                    </div>
                    <div className="content" style={{overflow: 'auto', maxHeight: 'calc(100vh - 70px)'}}>
                        <div className="inner">
                            <p className="con">현대카드 회원이신 분들은 카드 정보를 추가로 입력하시면 더욱 다양한 혜택과 서비스를 제공받으실 수 있으며, <br />입력하신 카드 정보는 당사에 수집되지 않습니다.</p>

                            <table className="tbl-list2">
                                <caption>현대카드 인증 관련 입력폼</caption>
                                <colgroup>
                                        <col width="180" />
                                        <col width="*" />
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th scope="row">성명</th>
                                        <td>{user.data.memberName}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">보유카드 종류</th>
                                        <td>
                                            <div className="input-wrap">
                                                <div className="input-area">
                                                    {hCardList.map(({label, value}, idx) => {
                                                        return (
                                                            <span key={value} className="radio-base radio-st2">
                                                                <input type="radio" id={`type${idx}`} name="hccGrade" value={value} className="a11y" checked={cardInfo.hccGrade === value} onChange={changeHandler()} />
                                                                <label htmlFor={`type${idx}`} className="label-base">{label}</label>
                                                            </span>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">현대카드 번호입력</th>
                                        <td>
                                            <div className="input-wrap">
                                                <div className="input-area">
                                                    <input ref={(ref) => inputRefs.current.cardNo = ref} type="text" name="cardNo1" className={`input-base tp1 w100 ${cardInfoErr.cardNo ? 'err' : ''}`} maxLength={4} value={cardInfo.cardNo1} onChange={changeHandler('number')} />
                                                    <span className="unit hyphen">-</span>
                                                    <input type="text" name="cardNo2" className={`input-base tp1 w100 ${cardInfoErr.cardNo ? 'err' : ''}`} maxLength={4} value={cardInfo.cardNo2} onChange={changeHandler('number')} />
                                                    <span className="unit hyphen">-</span>
                                                    <input type="text" name="cardNo3" className={`input-base tp1 w100 ${cardInfoErr.cardNo ? 'err' : ''}`} maxLength={4} value={cardInfo.cardNo3} onChange={changeHandler('number')} />
                                                    <span className="unit hyphen">-</span>
                                                    <input type="text" name="cardNo4" className={`input-base tp1 w100 ${cardInfoErr.cardNo ? 'err' : ''}`} maxLength={4} value={cardInfo.cardNo4} onChange={changeHandler('number')} />
                                                    {cardInfoErr.cardNo && (
                                                        <p className="feedback"><span>카드번호는 필수 항목입니다.</span></p>
                                                    )}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">유효기간</th>
                                        <td>
                                            <div className="input-wrap">
                                                <div className="input-area">
                                                    <input ref={(ref) => inputRefs.current.validTermMonth = ref} type="text" name="validTermMonth" className={`input-base tp1 w70 ${cardInfoErr.validTermMonth ? 'err' : ''}`} maxLength={2} value={cardInfo.validTermMonth} onChange={changeHandler('number')} />
                                                    <span className="unit">/</span>
                                                    <input ref={(ref) => inputRefs.current.validTermYear = ref} type="text" name="validTermYear" className={`input-base tp1 w70 ${cardInfoErr.validTermYear ? 'err' : ''}`} maxLength={2} value={cardInfo.validTermYear} onChange={changeHandler('number')} />
                                                    <span className="stxt a-ml20">* 카드 전면에 있는 유효기간을 입력해 주십시오. (월/년)</span>
                                                    {cardInfoErr.validTermMonth && (
                                                        <p className="feedback"><span>유효기간 월은 필수 항목입니다.</span></p>
                                                    )}
                                                    {cardInfoErr.validTermYear && (
                                                        <p className="feedback"><span>유효기간 년은 필수 항목 입니다.</span></p>
                                                    )}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">카드비밀번호</th>
                                        <td>
                                            <div className="input-wrap">
                                                <div className="input-area">
                                                    <input ref={(ref) => inputRefs.current.pwd = ref} type="password" name="pwd" className={`input-base tp1 w70 ${cardInfoErr.pwd ? 'err' : ''}`} maxLength={2} value={cardInfo.pwd} onChange={changeHandler('number')} />
                                                    <span className="unit">●●</span>
                                                    <span className="stxt a-ml20">* 카드 비밀번호 4자리 중 앞자리 2개만 입력해 주십시오.</span>
                                                    {cardInfoErr.pwd && (
                                                        <p className="feedback"><span>카드비밀번호 앞 2자리는 필수 항목입니다.</span></p>
                                                    )}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">CVV번호</th>
                                        <td>
                                            <div className="input-wrap">
                                                <div className="input-area">
                                                    <input ref={(ref) => inputRefs.current.cvv = ref} type="password" name="cvv" className={`input-base tp1 w70 ${cardInfoErr.cvv ? 'err' : ''}`} maxLength={3} value={cardInfo.cvv} onChange={changeHandler('number')} />
                                                    <span className="stxt a-ml20">* 카드 CVV 번호 3자리를 입력해 주십시오.</span>
                                                    {cardInfoErr.cvv && (
                                                        <p className="feedback"><span>CVV는 필수 항목입니다.</span></p>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="img-hcc-cvv">
                                                <span className="ex-img"><img src="//static.priviatravel.com/images/front/travel/contents/img_hcc_cvv.jpg" alt="카드 뒷면 CVV 번호 예시" /></span>
                                                <ul className="dtxt-list">
                                                    <li>CVV는 안전한 거래를 위한 번호로 카드 뒷면 서명란에 표시된 <br />번호 중 가장 뒤의 3자리입니다.</li>
                                                    <li>CVV 번호가 훼손되어 확인되지 않는 경우 고객상담실(1577-6000)로 <br />문의하여 재발급 받으시길 바랍니다.</li>
                                                    <li>
                                                        현대카드 인증 5회 연속 실패 시 해당 카드 사용이 불가능하게 됩니다.<br />
                                                        인증 실패로 카드 사용이 중지된 경우, 인터넷으로 본인확인 후 <br />
                                                        비밀번호를 재설정하거나 본인이 카드소지 후 카드사 ARS를 통해 <br />
                                                        비밀번호를 재설정 해야 카드 사용이 가능합니다.
                                                    </li>
                                                </ul>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <div className="ui-term-wrap" style={{marginTop: 20}}>
                                <ul className="ui-term-list noslide">
                                    <li>
                                        <dl>
                                            <dt className={isShow ? 'on' : ''}>
                                                <div className="ui-term-tit">
                                                    <span className="tit" onClick={termToggle}>제 3자 개인정보 제공 동의 (필수)</span>
                                                    <span className="chk-base chk-st3">
                                                        <input ref={(ref) => inputRefs.current.agree = ref} type="checkbox" id="term-agree" className="a11y" name="agree" checked={cardInfo.agree} onChange={changeHandler('checkbox')} />
                                                        <label htmlFor="term-agree">동의</label>
                                                    </span>
                                                    <span className="arrow" onClick={termToggle}></span>
                                                    {cardInfoErr.agree && (
                                                        <p className="feedback" style={{fontSize: 14, color: '#e00000', letterSpacing: '-.5px'}}><span>제 3자 개인정보 제공 동의에 동의해 주세요.</span></p>
                                                    )}
                                                </div>
                                            </dt>
                                            <dd style={{display: isShow ? 'block' : 'none'}}>
                                                <div className="ui-term-box" style={{overflowY: 'auto'}}>
                                                    <div className="inner term-customscrollbar" style={{overflow: 'initial'}}>
                                                        <div className="content" id="indivInfoThird3" dangerouslySetInnerHTML={{__html: termText}}></div>
                                                    </div>
                                                </div>
                                            </dd>
                                        </dl>
                                    </li>
                                </ul>
                            </div>

                            <div className="ui-btns-wrap" style={{marginTop: 20}}>
                                <div className="ui-btns right">
                                    <span className="btn-base bb-st5"><a onClick={onClose} style={{cursor: 'pointer'}}>취소</a></span>
                                    <span className="btn-base bb-st3"><a onClick={onApply} style={{cursor: 'pointer'}}>확인</a></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default HyundaiAuth;