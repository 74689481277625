import React, {useEffect} from "react";
import {shallowEqual, useSelector} from "react-redux";
import 'moment/locale/ko';
import moment from "moment";
import {formatNumber} from "../../utils/formatNumber";

const PaymentList = () => {

    let reservation = useSelector((state) => state.myPageReducer.reservation, shallowEqual);
    let inicisData = useSelector((state) => state.myPageReducer.inicisData, shallowEqual);

    useEffect(() => {
    }, []);

    const handlePrintReceipt = (tid, approvalNumber) => {
        const agt = navigator.userAgent.toLowerCase();
        const script = document.createElement("script");
        script.src = "//iniweb.inicis.com/js/auth.js";
        document.head.appendChild(script);
        const receiptUrl = '//iniweb.inicis.com/app/publication/apReceipt.jsp?noTid='+ tid;
        script.onload = e => {
            if( (navigator.appName === 'Netscape' && agt.indexOf('trident') !== -1) || (agt.indexOf("msie") !== -1) ||  agt.indexOf('edge')) {
                e.srcElement.ownerDocument.defaultView.showReceipt(receiptUrl, '3', approvalNumber);
            } else {
                e.path[3].defaultView.showReceipt(receiptUrl, '3', approvalNumber);
            }
        }
    }

    return (
        <React.Fragment>
            {reservation &&
                <section className="book-detail-area">
                    <div className="pkg-tit-page-cont bb-line">
                        <h3 className="title">결제내역</h3>
                    </div>
                    <div className="book-article">
                        <table summary="결제내역" className="list-resv-tbl">
                            <caption>결제상태, 결제일시, 카드정보, 결제금액 전표출력버튼 항목이 있는 결제내역 테이블</caption>
                            <colgroup>
                                <col width="200px" />
                                <col width="200px" />
                                <col width="*" />
                                <col width="200px" />
                                <col width="200px" />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>결제상태</th>
                                    <th>결제일시</th>
                                    <th>카드정보</th>
                                    <th>결제금액</th>
                                    <th><span className="a11y">전표출력버튼</span></th>
                                </tr>
                            </thead>
                            <tbody>
                                {inicisData && inicisData.map((data, index) =>
                                <tr key={`inicisData${data.paymentDayTime}`}>
                                    <td className="a-center">{data.isRefund?"환불완료":data.isPartialRefund?"부분취소":"결제완료"}</td>
                                    <td className="a-center">{data.paymentDayTime && data.paymentDayTime.length > 11 && moment(data.paymentDayTime.slice(0,4)+"-"+data.paymentDayTime.slice(4,6)+"-"+data.paymentDayTime.slice(6,8)+"T"+data.paymentDayTime.slice(8,10)+":"+data.paymentDayTime.slice(10,12)+":"+data.paymentDayTime.slice(12)).format("YYYY/MM/DD HH:mm")}</td>
                                    <td className="a-center">{data.cardCompanyName}카드 ({data.cardNumber}) {data.cardQuota === '00' ? `/ 일시불` : `/ ${data.cardQuota}개월`}</td>
                                    <td className="a-center">{formatNumber(parseFloat(data.cardPaymentAmount))}원</td>
                                    <td className="a-center">
                                        <div className="btn-wrap">
                                            <span className="btn-base btn-st-sfull btn-bg-gr w120">
                                                <button type="button" onClick={() => { handlePrintReceipt(data.tid, data.approvalNumber) }}>전표출력</button>
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </section>
            }
        </React.Fragment>
    );
};

export default PaymentList;