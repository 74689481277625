import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from "react";
import axios from "axios";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Loading from "../common/Loading";
import { getAxiosHeaders } from "../common/util/Axios";
import MyPageError from "./MyPageError";
import { goLoginPage } from "../common/util/RedirectLogin";
import {
    setInicisData,
    setInquireList,
    setPaymentData,
    setRefundData,
    setRepresentativeProduct,
    setReservationInfo,
    setSaleProduct
} from "../redux/module/mypage/MyPageReducer";
import 'moment/locale/ko';
import moment from "moment/moment";
import { formatNumber } from "../utils/formatNumber";
import ReservationPersonInfo from "./component/ReservationPersonInfo";
import ProductInfo from "./component/ProductInfo";
import TravelerInfo from "./component/TravelerInfo";
import PaymentInfo from "./component/PaymentInfo";
import PaymentList from "./component/PaymentList";
import InquireList from "./component/InquireList";
import { getCookie } from "../common/util/Cookie";
import PaymentRefundInfo from "./component/PaymentRefundInfo";
import PaymentPartialInfo from "./component/PaymentPartialInfo";
import { deleteTravelerInfo } from "../../src/kyb/domain/repository/TravelerRepository";

const MyPage = () => {

    const params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const serverUrl = process.env.REACT_APP_SERVER_URL;
    const frontUrl = process.env.REACT_APP_FRONT_URL;
    const priviaUrl = process.env.REACT_APP_PRIVIA_BASE_URL;

    let userInfo = useSelector((state) => state.headerReducer.userInfo, shallowEqual);
    let error = useSelector((state) => state.myPageReducer.errorMessage, shallowEqual);
    let reservation = useSelector((state) => state.myPageReducer.reservation);
    let saleProduct = useSelector((state) => state.myPageReducer.saleProduct, shallowEqual);
    let paymentData = useSelector((state) => state.myPageReducer.paymentData, shallowEqual);
    let refundData = useSelector((state) => state.myPageReducer.refundData, shallowEqual);
    const memberNo = getCookie("memberNo");
    const [catDivnCd, setCatDivnCd] = useState('');
    const [inquireLists, setInquireLists] = useState(null)

    useEffect(() => {
        getReservationData();
    }, [userInfo]);

    const getReservationData = () => {
        if (!userInfo.memberNumber) {
            return false;
        }

        if (!memberNo) {
            goLoginPage(`${frontUrl}/myPage/${params.reservationNumber}/detail`);
        }

        axios.get(serverUrl + '/api/v1/reservation/getData/' + params.reservationNumber, {
            headers: getAxiosHeaders()
        })
            .catch(function (error) {
                console.log(error.toJSON());
            })
            .then(function (response) {
                if (response) {
                    if (Number(response.data.memberNumber) !== userInfo.memberNumber) {
                        alert("존재하지 않는 예약입니다.");
                        navigate("/myPage/noReservation", { replace: true });
                        return;
                    }
                    dispatch(setReservationInfo(response.data));
                    dispatch(setRepresentativeProduct(response.data.representativeProduct ? response.data.representativeProduct.representativeProducts[0] : null));
                    dispatch(setSaleProduct(response.data.saleProduct ? response.data.saleProduct.saleProducts[0] : null));
                    dispatch(setPaymentData(response.data.paymentData));
                    dispatch(setRefundData(response.data.refundData));
                    dispatch(setInicisData(response.data.inicisData));
                    dispatch(setInquireList(response.data.inquireList));
                    setInquireLists(response.data.inquireList)
                    switch (response.data?.saleProduct?.saleProducts[0]?.supplierCode.toUpperCase()) {
                        case 'HANA':
                            setCatDivnCd('TRV0010301');
                            break;
                        case 'MODE':
                            setCatDivnCd('TRV0010307');
                            break;
                        case 'VERYGOOD':
                            setCatDivnCd('TRV0010308');
                            break;
                        case 'KAL':
                            setCatDivnCd('TRV0010310');
                            break;
                    }

                } else {
                    alert("존재하지 않는 예약입니다.");
                    navigate("/myPage/noReservation", { replace: true });
                    return false;
                }
            });
    }

    const removeReservation = () => {
        if (!window.confirm("예약 삭제하시겠습니까? 삭제한 내역은 복구할 수 없습니다.")) {
            return;
        }
        deleteTravelerInfo(this.props.reservationNumber).catch(function (error) {
            console.log(error.toJSON());
        }).then(function (response) {
            if (response.data.data === 'OK') {
                alert('예약이 삭제되었습니다.');
                window.location.href = priviaUrl + '/mypage/bookingRecord?pcdSvcType=package';
            } else {
                alert('예약을 삭제할 수 없습니다.');
            }
        });
    }

    const goRemainPayment = () => {
        window.location.href = window.location.href.replaceAll("detail", "payment");
    }
    return (error ? <MyPageError /> : (
        <React.Fragment>
            {
                reservation && saleProduct ? (
                    <div className="w-content-sec">
                        <div className="pkg-tit-page ly-inner">
                            <h2 className="title">해외패키지 예약상세</h2>
                        </div>

                        <div className="pkg-resv-sec ly-inner">

                            {reservation.reservationStep !== 'CANCEL' && reservation.isRemainPaymentButton && (
                                <div className="payment-warn-box a-mt50">
                                    <div className="warn-wrap between-box fc">
                                        <div className="warn-text">
                                            <span className="c-point13 a-bold a-vam">잔액이 있습니다. 결제시한까지 잔액을 결제해 주세요.</span> <span className="btn-base btn-st-uline"></span>
                                            <br />결제시한 : {reservation && reservation.paymentDeadLine && moment(reservation.paymentDeadLine).format("YYYY/MM/DD HH:mm")}
                                        </div>

                                        <div className="btn-wrap">
                                            <span className="btn-base btn-st-sfull btn-bg-bl">
                                                <button type="button" onClick={goRemainPayment}><span className="a-bold">{paymentData && formatNumber(paymentData.outstandingAmount)}원</span> 결제하기</button>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            )}

                            <ProductInfo catDivnCd={catDivnCd} />

                            <ReservationPersonInfo />

                            <TravelerInfo />

                            {paymentData && paymentData.request &&
                                <section className="book-detail-area">
                                    <div className="pkg-tit-page-cont">
                                        <h3 className="title">요청사항</h3>
                                    </div>
                                    <div className="book-article">
                                        <textarea className="textarea-basic" disabled>{paymentData.request}</textarea>
                                    </div>
                                </section>
                            }

                            {reservation &&
                                reservation.reservationStep === 'CANCEL' ?
                                <>{/* 예약 취소 결제 */}<PaymentRefundInfo /></>
                                :
                                //결제완료 or 예약금결제 or 환불내역이 없으면 PaymentInfo 노출
                                ((reservation.paymentStep === 'DONE') || (reservation.isAllPay === false)
                                    || (refundData && refundData.cancelPaymentAmount + refundData.cancelBenefitAmount === 0)) ?
                                    <>{/* 예약금/전액 결제  */}<PaymentInfo /></>
                                    :
                                    <>{/* 부분 취소 결제 */}<PaymentPartialInfo /></>
                            }

                            <PaymentList />

                            <InquireList catDivnCd={catDivnCd} reservation={reservation} inquireList={inquireLists} />

                            {//출발일이 지났거나 취소완료된 예약건에만 노출
                                reservation &&
                                reservation.isCanDelete &&
                                <div className="resv-ex-bottom">
                                    <i className="icon-ex-gr"></i>예약을 삭제하시려면 <a style={{ cursor: "pointer" }} onClick={removeReservation}>여기</a>를 눌러주세요. 삭제한 주문내역은 다시 복구할 수 없습니다.
                                </div>
                            }
                        </div>
                    </div>
                ) : (
                    <Loading />
                )
            }
        </React.Fragment>
    ));
};

export default MyPage;
