// 액션 타입 정의
const SET_ERROR_MESSAGE = "MYPAGE/SET_ERROR_MESSAGE";
const SET_RESERVATION_INFO = "MYPAGE/SET_RESERVATION_INFO";
const SET_REPRESENTATIVE_PRODUCT = "MYPAGE/SET_REPRESENTATIVE_PRODUCT";
const SET_SALE_PRODUCT = "MYPAGE/SET_SALE_PRODUCT";
const SET_PAYMENT_DATA = "MYPAGE/SET_PAYMENT_DATA";
const SET_REFUND_DATA = "MYPAGE/SET_REFUND_DATA";
const SET_INICIS_DATA = "MYPAGE/SET_INICIS_DATA";
const SET_INQUIRE_LIST = "MYPAGE/SET_INQUIRE_LIST";

export const setErrorMessage = (errorMessage) => ({type: SET_ERROR_MESSAGE, errorMessage});
export const setReservationInfo = (reservation) => ({type: SET_RESERVATION_INFO, reservation});
export const setRepresentativeProduct = (representativeProduct) => ({type: SET_REPRESENTATIVE_PRODUCT, representativeProduct});
export const setSaleProduct = (saleProduct) => ({type: SET_SALE_PRODUCT, saleProduct});
export const setPaymentData = (paymentData) => ({type: SET_PAYMENT_DATA, paymentData});
export const setRefundData = (refundData) => ({type: SET_REFUND_DATA, refundData});
export const setInicisData = (inicisData) => ({type: SET_INICIS_DATA, inicisData});
export const setInquireList = (inquireList) => ({type: SET_INQUIRE_LIST, inquireList});

// ***** 초기상태 정의
const initialState = {
    errorMessage: null,
    reservation: null,
    representativeProduct: null,
    saleProduct: null,
    paymentData: null,
    refundData: null,
    inicisData: null,
    inquireList: null
};

//리듀서 작성
const reservationState = (state =initialState , action) =>{/**/
    switch (action.type) {
        case SET_ERROR_MESSAGE:
            return {
                ...state,
                errorMessage : action.errorMessage
            }
        case SET_RESERVATION_INFO:
            return {
                ...state,
                reservation : action.reservation
            }
        case SET_REPRESENTATIVE_PRODUCT:
            return {
                ...state,
                representativeProduct : action.representativeProduct
            }
        case SET_SALE_PRODUCT:
            return {
                ...state,
                saleProduct : action.saleProduct
            }
        case SET_PAYMENT_DATA:
            return {
                ...state,
                paymentData : action.paymentData
            }
        case SET_REFUND_DATA:
            return {
                ...state,
                refundData : action.refundData
            }
        case SET_INICIS_DATA:
            return {
                ...state,
                inicisData : action.inicisData
            }
        case SET_INQUIRE_LIST:
            return {
                ...state,
                inquireList : action.inquireList
            }
        default:
            return state;
    }
}

export default reservationState;