import {getCookie} from "../../../common/util/Cookie";
import axios from "axios";
import {getAxiosHeaders} from "../../../common/util/Axios";

export async function findCurrentUser() {
    let API_URL = process.env.REACT_APP_SERVER_URL;
    const memberNo = getCookie("memberNo");
    if (memberNo === undefined) {
        return null;
    }
    const url = API_URL + '/api/v1/memberInformationByCryptedMemberNm/' + memberNo;

    return await axios.get(url, {
        headers: getAxiosHeaders(),
    });
}