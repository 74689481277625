import React from "react";
import {formatNumber} from "../../../../../../utils/formatNumber";

export default function CheckBoxItem({
                                         enable,
                                         option,
                                         onCheck
                                     }) {
    if (!enable) return null

    function handleCheck(e) {
        onCheck && onCheck()
    }

    return (
        <div className="between-box fc inner-price-li">
            <div className="between-box fc">
                <div className="price_tt">{option.label}</div>
                <span className="check-group">
                    <input type="checkbox"
                           className="hidden_check"
                           id={option.label} name="pkgBnf"
                           checked={option?.checked}
                           onChange={handleCheck}/>
                    <label htmlFor={option.label}
                           className="label-check">적용</label>
                </span>
            </div>
            <div className="input-area">
                <input type="text"
                       className="input-base type01 w140 state-checked"
                       disabled
                       value={formatNumber(option.discountValue)}
                />원
            </div>
        </div>
    )
}