import {useCallback, useEffect} from "react";

/**
 * @param onScrollThresholdReached 임계값에 도달했을 때 호출할 함수
 * @param threshold 임계값 (0 ~ 1)
 * */
export default function useScrollThreshold({onScrollThresholdReached, threshold = 0.8}) {
    const handleScroll = useCallback(() => {
        const scrollTop = window.scrollY; // 현재 수직 스크롤 위치
        const documentHeight = document.documentElement.scrollHeight; // 문서 전체 높이
        const windowHeight = window.innerHeight; // 브라우저 창의 높이
        // 스크롤 위치가 임계값에 도달했는지 확인
        if (scrollTop / (documentHeight - windowHeight) > threshold) {
            onScrollThresholdReached();
        }
    }, [onScrollThresholdReached, threshold]);

    useEffect(() => {
        // 스크롤 이벤트 리스너 추가
        window.addEventListener('scroll', handleScroll);
        // 클린업 함수: 컴포넌트 언마운트 시 이벤트 리스너 제거
        return () => window.removeEventListener('scroll', handleScroll);
    }, [onScrollThresholdReached, threshold]);
}