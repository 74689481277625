import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {
    setFamilyDiscountSelector,
    setFamilyOptionSelector,
    setInvalidFamilySelector
} from "../../../state/reservation/benefit/FamilySelectors";
import usePaymentPrice from "../usePaymentPrice";
import useEvent from "../../../../infra/event/useEvent";
import {EVENT_NAMES} from "../event/events";
import {getDiscountValueDictSelector} from "../../../state/reservation/DiscountSelector";
import {DiscountType} from "../../../../domain/service/payment/PaymentEnum";
import {useEffect, useState} from "react";
import {PRICE_TYPE} from "../../../state/reservation/PriceSelectors";
import {setInvalidHcc3Selector} from "../../../state/reservation/benefit/Hcc3Selectors";


export default function useFamily(eventManager) {
    const {
        setDiscountV2,
        removeDiscountV2,
        setInitFamily,
        priceDict
    } = usePaymentPrice()

    const [familyOption, setFamilyOption] = useRecoilState(setFamilyOptionSelector)
    const [familyDiscount, setFamilyDiscount] = useRecoilState(setFamilyDiscountSelector)
    const discountValueDict = useRecoilValue(getDiscountValueDictSelector)
    const rootDiscountTargetValue = priceDict[PRICE_TYPE.ROOT_TARGET_PRICE]
    const [userFamilyEntity, setUserFamilyEntity] = useState(null)
    const setInvalidFamily = useSetRecoilState(setInvalidFamilySelector)

    useEvent(eventManager, EVENT_NAMES.LOAD_FAMILY_DISCOUNT, (evt) => {
        const userFamilyEntity = evt.getPayload()
        setUserFamilyEntity(userFamilyEntity)
    })

    function onCheck() {
        const nextCheck = !familyOption.checked
        if (nextCheck) {
            setDiscountV2(familyDiscount)
        }
        if (!nextCheck) {
            removeDiscountV2(familyDiscount)
        }
        setFamilyOption({
            ...familyOption,
            checked: nextCheck,
        })
    }

    useEffect(() => {
        if (rootDiscountTargetValue <= 0) {
            setFamilyOption({
                ...familyOption,
                checked: false,
            })
            removeDiscountV2(familyDiscount)
            return
        }
        if (userFamilyEntity === null) {
            return
        }

        if (userFamilyEntity.isValid()) {
            setInitFamily(userFamilyEntity.getData())
        }
    }, [rootDiscountTargetValue, userFamilyEntity]);

    function setInvalid() {
        setInvalidFamily()
    }


    return {
        enable: familyOption.enable,
        option: {
            ...familyOption,
            discountValue: familyOption.checked ? discountValueDict[DiscountType.FamilyDiscount] : 0

        },
        onCheck,
        setInvalid
    }
}