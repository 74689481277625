import React, {useRef, useState} from "react";
import {formatNumber} from "../../utils/formatNumber";
import {Navigation} from "swiper/modules";
import {Swiper, SwiperSlide} from "swiper/react";
import {Link} from "react-router-dom";

const PcSwiperThemeC = (props) =>{
    const swiperRef = useRef(null);
    const [swiperWidth , setSwiperWidth] = useState(0)
    const [style , setStyle] = useState({transform : `translate3d(0px, 0px, 0px)`})
    const [tabData , setTabData] = React.useState(0)
    const [nextButtonClickCount , setNextButtonClickCount] = useState(0)
    const [prevButtonClickCount , setPrevButtonClickCount] = useState(0)
    const data = props.pcSwiperThemeC;

    function onClickHref(url) {
        return (e) => {
            e?.stopPropagation();
            window.location.href = url;
        }
    }

    const contentEle = data&&data.productList.map((value , index)=>{
        const search = value.productDetail.name
        if(index < 8) {
            return(
                <SwiperSlide key={index}>
                    <div key={index} className="swiper-slide ga-promo list" data-swiper-slide-index="1">
                        <Link onClick={onClickHref(value.productDetail.link)}
                             className="ga-promo-click">

                            {
                                value.productDetail.imageUrl !=='http://image8.hanatour.com/product/img_no_M.gif'?
                                    (
                                        <span className="vis">
                                            <img src={value.productDetail.imageUrl} alt={value.productDetail.name} />
                                        </span>
                                    ):
                                    (<span className={"vis"}>
                                        <span className={"no-img"}></span>
                                    </span>)
                            }
                            <span className="info-box">
                                <span className="label-base">
                                    {value.productDetail.description && <span className="label-item">{value.productDetail.description}</span>}
                                    {value.productDetail.supplierName && <span className="label-item">{value.productDetail.supplierName}</span>}
                                </span>
                                <span className="tit"><span className="ellipsis2">{value.productDetail.name}</span></span>
                                <span className="price"><em>{formatNumber(value.productDetail.price)}</em>원~</span>
                        </span>
                        </Link>
                    </div>
                </SwiperSlide>
            )
        }
    })

    const handleNext = () =>{
        swiperRef.current.slideTo(swiperRef.current.activeIndex+1);
    }


    const handlePrev = () =>{
        swiperRef.current.slideTo(swiperRef.current.activeIndex-1);
    }


    return(
        <div className="s-best-promotion-cont ly-inner">
            <h2 className="pkg-tit-sec"><span>{data.title}</span></h2>
            <div className="list-item-pakage circle-btn-type fixed-list-swiper">
                <div className="swiper swiper-container-initialized swiper-container-horizontal">
                    <Swiper
                        className={"swiper-wrapper"}
                        modules={Navigation}
                        spaceBetween={30}
                        slidesPerView={4}
                        navigation={{
                            prevEl: ".swiper-button-prev",
                            nextEl: ".swiper-button-next"
                        }}
                        scrollbar={{draggable:true}}
                        onSwiper={(swiper) => swiperRef.current = swiper}
                    >
                        {contentEle}
                    </Swiper>
                    <span className="swiper-notification" aria-live="assertive" aria-atomic="true"></span></div>
                <div className="swiper-button-next" tabIndex="0" role="button" aria-label="Next slide" onClick={handleNext}></div>
                <div className="swiper-button-prev" tabIndex="0" role="button" aria-label="Previous slide" onClick={handlePrev}></div>
            </div>
        </div>
    )
}

export default PcSwiperThemeC;