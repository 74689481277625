import React from "react";
import {Link} from "react-router-dom";
import {formatNumber} from "../../utils/formatNumber";
import SaleProductEntity from "../../kyb/domain/model/product/SaleProductEntity";





const GuideFeePopup = ({closeModalPopup, saleProduct}) => {
    const {priceAmount, fuelSurchargeAmount, taxDutyAmount} = saleProduct
    const saleProductModel = new SaleProductEntity(saleProduct)

    return (
        <React.Fragment>
            <div className="popup-wrap" style={{width: '490px'}}>
                <div className="heading sm">
                    <h2>요금안내</h2>
                    <a style={{cursor: "pointer"}} className="btn_close" onClick={closeModalPopup}>닫기</a>
                </div>
            <div className="content">
                <div className="inner">
                    <div>
                        <section className="lpu-item-sec">
                            <div className="lpu-sub-item">
                                <div className="lpu-sub-title a-mb20" style={{paddingTop:`20px`}}>현대카드 혜택 적용 시 상품 가격</div>
                                <ul className="fee-list">

                                    <li className="between-box fc">
                                        <div className="th-style">성인 1 인 상품가</div>
                                        <div className="td-style price"><span>{formatNumber(priceAmount + fuelSurchargeAmount + taxDutyAmount)} 원</span></div>
                                        <p className="desc sm wfull">유류할증료 {formatNumber(fuelSurchargeAmount)}원<br/>제세공과금 {formatNumber(taxDutyAmount)}원 포함</p>
                                    </li>
                                    <Hcc3PercentItem model={saleProductModel}/>
                                    <AllDiscountItem model={saleProductModel}/>
                                </ul>
                                <ul className="list-dot narr">
                                    <li>유류할증료 및 제세공과금은 유가와 환율에 따라 변동될 수 있습니다.</li>
                                    <li>객실 1 인 사용 시 추가 요금이 발생됩니다.</li>
                                    <li>현지 합류를 원하실 경우 상담 시 확인 부탁드립니다.</li>
                                </ul>
                            </div>

                            <div className="lpu-mini-item">
                                <div className="lpu-sub-inner-title a-mb15">현대카드 혜택</div>
                                <ul className="list-dot card-bnf-list">
                                    {
                                        saleProductModel.isDiscountRank() &&
                                        <li>
                                            현대카드 결제 시&nbsp;<span className="c-point1">3% </span>즉시 할인
                                            <br/>(카드 종류 및 전월 실적 무관)
                                        </li>
                                    }
                                    <li>
                                        M포인트 <span className="c-point1">10%</span> 사용
                                        <br/>(M 포인트 부족 시 잔여 M 포인트만큼 사용 가능)
                                    </li>
                                </ul>
                            </div>

                            <div className="fee-btn-wrap">
                                <Link to={process.env.REACT_APP_PRIVIA_BASE_URL + "/etc/aboutPriviaTab?svc=aboutPakage"} className="ui-link">결제수단 및 혜택 상세</Link>
                            </div>

                            <div className="btn-wrap a-right a-pt20">
                                <span className="btn-base btn-st-full btn-bg-bl">
                                    <a style={{cursor:"pointer"}} onClick={closeModalPopup}>확인</a>
                                </span>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
    );
};

export default GuideFeePopup;


function Hcc3PercentItem({model}) {
    if (model.isEmpty()) return null
    if (!model.isDiscountRank()) return null
    const {sumAdultPrice} = model.getDiscountBySaleProduct({adultCount: 1, childCount: 0, infantCount: 0})

    return (
        <li className="between-box fc">
            <div className="th-style">현대카드&nbsp;<span className="c-point3">3%</span> 할인</div>
            <div className="td-style price">
                <span>{formatNumber(sumAdultPrice)} 원</span>
            </div>
        </li>
    )
}


function AllDiscountItem({model}) {
    if (model.isEmpty()) return null
    const {sumAdultPrice, mPoint, discountAmount} = model.getDiscountBySaleProduct({
        adultCount: 1,
        childCount: 0,
        infantCount: 0
    })
    if (!model.isDiscountRank()) {
        return (
            <li className="between-box fc">
                <div className="th-style">M포인트&nbsp;<span className="c-point1">10%</span> 할인</div>
                <div className="td-style price"><span>{formatNumber(sumAdultPrice - mPoint)} 원</span></div>
            </li>
        )
    }
    return (
        <li className="between-box fc">
            <div className="th-style">현대카드&nbsp;<span className="c-point3">3%</span> 할인 M포인트&nbsp;<span className="c-point1">10%</span> 할인</div>
            <div className="td-style price">
                <span>{formatNumber(discountAmount)} 원</span>
            </div>
        </li>
    )
}