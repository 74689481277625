import React from "react";
import {MiddleBanner} from "../search/SearchList";
import {goBackPage, goMainPage} from "../common/util/RedirectLogin";

const DetailError = () => {

    return (
        <section className="pkg-nodata-sec ly-inner">
            <div className="pkg-list-nodata">
                <i className="icon res-nodata"></i>
                <p className="con">상품이 존재하지 않습니다.<br/>상품이 삭제되었거나 미노출 상태로 변경되어, <br/>요청하신 페이지를 찾을 수 없습니다.</p>
                <span className="btn-base btn-st-full btn-line-bl btn-color-bl">
                    <a style={{cursor:"pointer"}} onClick={goBackPage} className="btn-init reset">이전 페이지로</a>
                </span>
                <span className="btn-base btn-st-full btn-bg-bl">
                    <a style={{cursor:"pointer"}} onClick={goMainPage} className="btn-init reset">메인 페이지로</a>
                </span>
            </div>
            <div className="btm-promotion-cont ly-inner">
                <ul className="list-item-pakage">
                    <MiddleBanner />
                </ul>
            </div>
        </section>
    );
};

export default DetailError;