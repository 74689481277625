import React from "react";
import {PaymentType} from "../../../../domain/service/payment/PaymentEnum";
import {formatNumber} from "../../../../../utils/formatNumber";


export default function PaymentMethodList({
                                              paymentCtx,
                                          }) {

    const {
        setUpPaymentPriceType,
        paymentType,
        priceInfo: {
            totalPrice,
            reservationPrice
        }
    } = paymentCtx

    return (
        <section className="book-detail-area bk-pay-type">
            <div className="pkg-tit-page-cont">
                <h3 className="title">결제방식</h3>
            </div>

            <div className="book-article">
                <div className="radio-wrap between-box fc">

                    <PaymentMethodItem type={PaymentType.RESERVATION_PAY}
                                       check={paymentType === PaymentType.RESERVATION_PAY}
                                       money={reservationPrice}
                                       onClick={setUpPaymentPriceType}/>

                    <PaymentMethodItem type={PaymentType.ALL_PAY}
                                       check={paymentType === PaymentType.ALL_PAY}
                                       money={totalPrice}
                                       onClick={setUpPaymentPriceType}/>

                </div>
            </div>
            {
                paymentType === PaymentType.ALL_PAY &&
                <div className="pay-type-cont js-pt-all">
                    <ul className="list-dot stPkg tpBook a-mt15">
                        <li>전액 결제 후 출발 확정 시 유류할증료 및 제세공과금은 환율에 따라 변동될 수 있으며,
                            옵션 추가(선택관광, 싱글 차지 등)의 이유로 추가금이 발생될 수 있습니다.
                        </li>
                    </ul>
                </div>
            }
            {
                paymentType === PaymentType.RESERVATION_PAY &&
                <div className="pay-type-cont js-pt-part">
                    <ul className="list-dot stPkg tpBook a-mt15">
                        <li>예약금은 여행 상품 가격의 일부를 먼저 결제하는 금액입니다.</li>
                        <li>
                            <p className="a-bold">PRIVIA 할인/혜택은 잔금 결제 시 전체 가격에 대해 적용됩니다.</p>
                            단, 유아 요금, 유류할증료, 제세공과금, 추가금액에 대해서는 할인/혜택이 적용되지 않습니다.
                        </li>
                    </ul>
                </div>
            }
        </section>
    )
}


function PaymentMethodItem({
                               type,
                               check,
                               money,
                               onClick
                           }) {
    function handleClick(e) {
        e.stopPropagation()
        onClick && onClick(type)
    }

    function viewTitleString(type) {
        switch (type) {
            case PaymentType.ALL_PAY:
                return "전액결제"
            case PaymentType.RESERVATION_PAY:
                return "예약금결제"
        }
    }

    return (
        <label className={`radio-border-wrap ${check && "active"}`}>
            <input type="radio"
                   value={type}
                   checked={check}
                   onClick={handleClick}/>
            <span>{viewTitleString(type)}</span>
            <p className="price">
                <span>{formatNumber(money)}</span>원
            </p>
        </label>
    )
}

