import moment from "moment";


export function getToday(format = 'YYYY-MM-DD') {
    return moment().format(format);
}


export function getFormatDate(date, format = 'YYYY-MM-DD') {
    return moment(date).format(format);
}