import React from "react";

function Loading() {
    return (
        <React.Fragment>
        <div className="loading-dimm" style={{display: 'block', zIndex: 9999}}></div>
            <div className="loading-pop" style={{display: 'block', zIndex: 99999}}>
            <div className="loading-cont">
                <div className="loading-pkg">
                    <div className="loading-bar"></div>
                    <img className="pkg-pd-loading-icon" src="//static.priviatravel.com/images/front/travel/svg/ico-pkg-bnf-card.svg" alt="카드혜택 아이콘" />
                        <div className="loading-title">
                            최대 <span className="c-point1">13%</span> 카드 결제 혜택
                    </div>
                    <div className="loading-desc">
                        최대 3% 즉시 할인 + 10% M포인트 사용
                    </div>
                </div>
            </div>
        </div>
        </React.Fragment>
    );
}

export default Loading;
