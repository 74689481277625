

export const countryOptionsList =  [
    {value: 'KR', label: '한국'},
    {value: 'GH', label: '가나'},
    {value: 'GA', label: '가봉'},
    {value: 'GY', label: '가이아나'},
    {value: 'GM', label: '감비아'},
    {value: 'GP', label: '과들루프'},
    {value: 'GT', label: '과테말라'},
    {value: 'GU', label: '괌'},
    {value: 'UM', label: '군소 외곽 제도 연합국'},
    {value: 'GD', label: '그레나다'},
    {value: 'GE', label: '그루지아(조지아)'},
    {value: 'GR', label: '그리스'},
    {value: 'GL', label: '그린랜드'},
    {value: 'GN', label: '기니'},
    {value: 'GW', label: '기니비사우'},
    {value: 'NA', label: '나미비아'},
    {value: 'NR', label: '나우루'},
    {value: 'NG', label: '나이지리아'},
    {value: 'SS', label: '남수단'},
    {value: 'ZA', label: '남아프리카 공화국'},
    {value: 'GS', label: '남조지아 남샌드위치 제도'},
    {value: 'NL', label: '네덜란드'},
    {value: 'AN', label: '네덜란드 앤틸리스(구)'},
    {value: 'NP', label: '네팔'},
    {value: 'NO', label: '노르웨이'},
    {value: 'NF', label: '노퍽 제도'},
    {value: 'NC', label: '뉴 칼레도니아'},
    {value: 'NZ', label: '뉴질랜드'},
    {value: 'NU', label: '니우에'},
    {value: 'NE', label: '니제르'},
    {value: 'NI', label: '니카라과'},
    {value: 'TW', label: '대만'},
    {value: 'DK', label: '덴마크'},
    {value: 'DM', label: '도미니카'},
    {value: 'DO', label: '도미니카 공화국'},
    {value: 'DE', label: '독일'},
    {value: 'TP', label: '동 티모르'},
    {value: 'LA', label: '라오스'},
    {value: 'LR', label: '라이베리아'},
    {value: 'LV', label: '라트비아'},
    {value: 'RU', label: '러시아 연방'},
    {value: 'LB', label: '레바논'},
    {value: 'LS', label: '레소토'},
    {value: 'RE', label: '레유니온(레위니옹)'},
    {value: 'RO', label: '루마니아'},
    {value: 'LU', label: '룩셈부르크'},
    {value: 'RW', label: '르완다'},
    {value: 'LY', label: '리비아'},
    {value: 'LT', label: '리투아니아'},
    {value: 'LI', label: '리히텐슈타인'},
    {value: 'MG', label: '마다가스카르'},
    {value: 'MQ', label: '마르티니크'},
    {value: 'MH', label: '마샬 제도'},
    {value: 'YT', label: '마요트'},
    {value: 'FM', label: '마이크로네시아 연방'},
    {value: 'MO', label: '마카오'},
    {value: 'MK', label: '마케도니아'},
    {value: 'MW', label: '말라위'},
    {value: 'MY', label: '말레이지아'},
    {value: 'ML', label: '말리'},
    {value: 'MX', label: '멕시코'},
    {value: 'MC', label: '모나코'},
    {value: 'MA', label: '모로코'},
    {value: 'MU', label: '모리셔스'},
    {value: 'MR', label: '모리타니아'},
    {value: 'MZ', label: '모잠비크'},
    {value: 'ME', label: '몬테네그로'},
    {value: 'CS', label: '몬테네그로(구)'},
    {value: 'MS', label: '몬트세렛'},
    {value: 'MD', label: '몰도바'},
    {value: 'MV', label: '몰디브'},
    {value: 'MT', label: '몰타'},
    {value: 'MN', label: '몽골'},
    {value: 'US', label: '미국'},
    {value: 'MM', label: '미얀마'},
    {value: 'VI', label: '미연방 버진 군도'},
    {value: 'AS', label: '미연방 사모아'},
    {value: 'VU', label: '바누아투'},
    {value: 'BH', label: '바레인'},
    {value: 'BB', label: '바베이도스'},
    {value: 'VA', label: '바티칸 시티'},
    {value: 'BS', label: '바하마'},
    {value: 'BD', label: '방글라데시'},
    {value: 'BM', label: '버뮤다'},
    {value: 'BJ', label: '베냉'},
    {value: 'VE', label: '베네주엘라'},
    {value: 'VN', label: '베트남'},
    {value: 'BE', label: '벨기에'},
    {value: 'BY', label: '벨라루스'},
    {value: 'BZ', label: '벨리즈'},
    {value: 'BQ', label: '보네르'},
    {value: 'BA', label: '보스니아 헤르체고비나'},
    {value: 'BW', label: '보츠와나'},
    {value: 'BO', label: '볼리비아'},
    {value: 'BF', label: '부르키나 파소'},
    {value: 'BV', label: '부벳 제도'},
    {value: 'BT', label: '부탄'},
    {value: 'MP', label: '북 마리아나 제도'},
    {value: 'KP', label: '북한'},
    {value: 'BG', label: '불가리아'},
    {value: 'BR', label: '브라질'},
    {value: 'BN', label: '브루나이 다루살람'},
    {value: 'BI', label: '브룬디'},
    {value: 'WS', label: '사모아'},
    {value: 'SA', label: '사우디 아라비아'},
    {value: 'CY', label: '사이프러스(키프로스)'},
    {value: 'SM', label: '산마리노'},
    {value: 'ST', label: '상투메 프린시플'},
    {value: 'MF', label: '생마르탱'},
    {value: 'BL', label: '생바르텔레미'},
    {value: 'EH', label: '서 사하라'},
    {value: 'SN', label: '세네갈'},
    {value: 'RS', label: '세르비아'},
    {value: 'YU', label: '세르비아(구)'},
    {value: 'SC', label: '세이셸'},
    {value: 'LC', label: '세인트 루시아'},
    {value: 'VC', label: '세인트 빈센트 그레나딘'},
    {value: 'KN', label: '세인트 키트 네비스'},
    {value: 'PM', label: '세인트 피에르 미쿠엘론'},
    {value: 'SH', label: '세인트 헬레나'},
    {value: 'SO', label: '소말리아'},
    {value: 'SB', label: '솔로몬 제도'},
    {value: 'SD', label: '수단'},
    {value: 'SR', label: '수리남'},
    {value: 'LK', label: '스리랑카'},
    {value: 'SJ', label: '스발바드 잔 마이엔'},
    {value: 'SZ', label: '스와질란드'},
    {value: 'SE', label: '스웨덴'},
    {value: 'CH', label: '스위스'},
    {value: 'ES', label: '스페인'},
    {value: 'SK', label: '슬로바키아'},
    {value: 'SI', label: '슬로베니아'},
    {value: 'SY', label: '시리아'},
    {value: 'SL', label: '시에라 레온'},
    {value: 'SX', label: '신트마르턴'},
    {value: 'SG', label: '싱가포르'},
    {value: 'AE', label: '아랍 에미리트 연합'},
    {value: 'AW', label: '아루바'},
    {value: 'AM', label: '아르메니아'},
    {value: 'AR', label: '아르헨티나'},
    {value: 'IS', label: '아이슬랜드'},
    {value: 'HT', label: '아이티'},
    {value: 'IE', label: '아일랜드'},
    {value: 'AZ', label: '아제르바이젠'},
    {value: 'AF', label: '아프가니스탄'},
    {value: 'AL', label: '알바니아'},
    {value: 'DZ', label: '알제리'},
    {value: 'AO', label: '앙골라'},
    {value: 'AI', label: '앤귈라(앵귈라)'},
    {value: 'AD', label: '앤도라'},
    {value: 'AG', label: '앤티가 바부다'},
    {value: 'ER', label: '에리트레아'},
    {value: 'EE', label: '에스토니아'},
    {value: 'EC', label: '에콰도르'},
    {value: 'SV', label: '엘살바도르'},
    {value: 'GB', label: '영국'},
    {value: 'VG', label: '영연방 버진 군도'},
    {value: 'IO', label: '영연방 인도양 영해'},
    {value: 'YE', label: '예멘'},
    {value: 'OM', label: '오만'},
    {value: 'AT', label: '오스트리아'},
    {value: 'HN', label: '온두라스'},
    {value: 'JO', label: '요르단'},
    {value: 'UG', label: '우간다'},
    {value: 'UY', label: '우루과이'},
    {value: 'UZ', label: '우즈베키스탄'},
    {value: 'UA', label: '우크라이나'},
    {value: 'WF', label: '월리스 푸투나'},
    {value: 'ET', label: '이디오피아'},
    {value: 'IQ', label: '이라크'},
    {value: 'IR', label: '이란'},
    {value: 'IL', label: '이스라엘'},
    {value: 'EG', label: '이집트'},
    {value: 'IT', label: '이탈리아'},
    {value: 'IN', label: '인도'},
    {value: 'ID', label: '인도네시아'},
    {value: 'JP', label: '일본'},
    {value: 'JM', label: '자메이카'},
    {value: 'ZM', label: '잠비아'},
    {value: 'GQ', label: '적도 기니'},
    {value: 'CN', label: '중국'},
    {value: 'CF', label: '중앙아프리카 공화국'},
    {value: 'DJ', label: '지부티'},
    {value: 'GI', label: '지브롤터'},
    {value: 'ZW', label: '짐바브웨'},
    {value: 'TD', label: '차드'},
    {value: 'CZ', label: '체코 공화국'},
    {value: 'CL', label: '칠레'},
    {value: 'CM', label: '카메룬'},
    {value: 'CV', label: '카보베르데'},
    {value: 'KZ', label: '카자흐스탄'},
    {value: 'QA', label: '카타르'},
    {value: 'KH', label: '캄보디아'},
    {value: 'CA', label: '캐나다'},
    {value: 'KE', label: '케냐'},
    {value: 'KY', label: '케이먼 제도'},
    {value: 'KM', label: '코모로스'},
    {value: 'XK', label: '코소보'},
    {value: 'CR', label: '코스타리카'},
    {value: 'CC', label: '코코스 제도'},
    {value: 'CI', label: '코트디부아르'},
    {value: 'CO', label: '콜롬비아'},
    {value: 'CG', label: '콩고'},
    {value: 'CD', label: '콩고민주공화국'},
    {value: 'CU', label: '쿠바'},
    {value: 'KW', label: '쿠웨이트'},
    {value: 'CK', label: '쿡 제도'},
    {value: 'CW', label: '퀴라소'},
    {value: 'HR', label: '크로아티아'},
    {value: 'CX', label: '크리스마스 제도'},
    {value: 'KG', label: '키르기즈스탄'},
    {value: 'KI', label: '키리바시'},
    {value: 'TJ', label: '타지키스탄'},
    {value: 'PF', label: '타히티(프랑스령 폴리네시아 섬)'},
    {value: 'TZ', label: '탄자니아'},
    {value: 'TH', label: '태국'},
    {value: 'TC', label: '터크스 케이커스'},
    {value: 'TG', label: '토고'},
    {value: 'TK', label: '토켈라우'},
    {value: 'TO', label: '통가'},
    {value: 'TM', label: '투르크메니스탄'},
    {value: 'TV', label: '투발루'},
    {value: 'TN', label: '튀니지'},
    {value: 'TR', label: '튀르키예'},
    {value: 'TT', label: '트리니다드 토바고'},
    {value: 'PA', label: '파나마'},
    {value: 'PY', label: '파라과이'},
    {value: 'PK', label: '파키스탄'},
    {value: 'PG', label: '파푸아 뉴기니아'},
    {value: 'PW', label: '팔라우'},
    {value: 'PS', label: '팔레스타인'},
    {value: 'PE', label: '페루'},
    {value: 'FO', label: '페어로우(페로)제도'},
    {value: 'PT', label: '포르투갈'},
    {value: 'FK', label: '포클랜드 제도'},
    {value: 'PL', label: '폴란드'},
    {value: 'PR', label: '푸에르토리코'},
    {value: 'FR', label: '프랑스'},
    {value: 'GF', label: '프랑스령 기아나'},
    {value: 'FP', label: '프랑스령 폴리네시아'},
    {value: 'FJ', label: '피지'},
    {value: 'FI', label: '핀란드'},
    {value: 'PH', label: '필리핀'},
    {value: 'PN', label: '핏케언'},
    {value: 'HM', label: '허드맥도날드 제도'},
    {value: 'HU', label: '헝가리'},
    {value: 'AU', label: '호주'},
    {value: 'HK', label: '홍콩'}
];