import React, {useEffect, useRef, useState} from "react";
import {formatNumber} from "../../utils/formatNumber";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {setInquireList, setInquireTotal} from "../../redux/module/detail/DetailReducer";
import axios from "axios";
import {setErrorMessage} from "../../redux/module/reservation/ReservationReducer";
import {getAxiosHeaders} from "../../common/util/Axios";
import Pagination from "../../common/Pagination";

const Inquire = (props) => {

    const dispatch = useDispatch();
    const serverUrl = process.env.REACT_APP_SERVER_URL;
    const keywordRef = useRef(null);
    const [limit, setLimit] = useState(5);
    const [page, setPage] = useState(1);

    let saleProduct = useSelector((state) => state.productReducer.saleProduct, shallowEqual);
    let inquireList = useSelector((state) => state.detailReducer.inquireList, shallowEqual);
    let inquireTotal = useSelector((state) => state.detailReducer.inquireTotal, shallowEqual);

    useEffect(() => {
        getInquireList(saleProduct.saleProductCode.toUpperCase()).then(r => console.log(r))
    }, [page]);

    const clickPage = (page) => {
        setPage(page);
    }

    const getInquireList = async (saleCode) => {
        axios.get(serverUrl + '/api/v1/inquire/product/list', {
            headers: getAxiosHeaders(),
            params: {
                productCode: saleCode,
                searchText: keywordRef.current.value,
                startRow: page * limit - (limit - 1),
                endRow: page * limit
            }
        }).catch(function (error) {
            console.log(error.toJSON());
        }).then(function (response) {
            if (response) {
                dispatch(setInquireList(response.data.list));
                dispatch(setInquireTotal(response.data.total));
            } else {
                dispatch(setErrorMessage('error'));
            }
        });
    }

    const clickInquire = (e) => {
        let con = e.currentTarget.nextElementSibling;
        let box = e.currentTarget.closest('.js_accor');

        if (con.hasChildNodes) {
            if (e.currentTarget.classList.contains('on')) {
                con.style.display = "none";
                e.currentTarget.classList.remove('on');
            } else {
                box.querySelectorAll('.js_accor_con').forEach(item => item.style.display = 'none');
                box.querySelectorAll('.js_accor_tit').forEach(item => item.classList.remove('on'));
                con.style.display = "table-row";
                e.currentTarget.classList.add('on');
            }
        }
    }

    const searchKeyword = () => {
        getInquireList(saleProduct.saleProductCode.toUpperCase()).then(r => console.log(r))
    }

    const handleOnKeyPress = e => {
        if (e.key === 'Enter') {
            searchKeyword();
        }
    };

    return (
        <article className="pkg-article ly-inner tab-on" id="pkgConList7">
            <div className="pkg-left-inner">
                <h3 className="pd-article-title">상품 Q&A</h3>
                <ul className="list-dot stPkg qna-item-list">
                    <li>운영 및 답변 가능 시간 : 평일 09:00 ~ 18:00 (주말 및 공휴일 휴무)</li>
                    <li>문의내역 및 답변은  마이페이지> 1:1문의에서도 확인하실 수 있습니다.</li>
                    <li>상품문의 외 개인정보가 포함된 예약문의 및 일반문의는  고객센터 > 1:1문의를 이용해 주시기 바랍니다.</li>
                </ul>

                <div className="between-box fc">
                    <div className="pd-article-sub-title">문의내역 (<em>{formatNumber(inquireTotal)}</em>건)</div>
                    <div className="between-box">
                        <div className="qna-search-wrap">
                            <input className="input-base type01" type="text" ref={keywordRef} onKeyUp={handleOnKeyPress} />
                            <span className="btn-base btn-st-sfull btn-bg-bl">
                                <button type="button" className="btn-base" onClick={searchKeyword}>검색</button>
                            </span>
                        </div>
                        <span className="btn-base btn-st-sfull btn-bg-gr">
                            <Link to={process.env.REACT_APP_PRIVIA_BASE_URL
                                + "/customer/inquire/form?svcDivnCd=TRV0010300&catDivnCd=" + props.catDivnCd + "&prodCd=" + saleProduct.saleProductCode
                                + "&prodNm=" + unescape(saleProduct.saleProductName)} className="btn-base">문의하기</Link>
                        </span>
                    </div>
                </div>

                <table summary="문의내역 요약" className="pkg-qna-list js_accor">
                    <caption className="ally">처리상태, 제목, 작성자, 등록일, 조회에 대한 테이블</caption>
                    <colgroup>
                        <col width="120px" />
                        <col width="*" />
                        <col width="100px" />
                        <col width="115px" />
                        <col width="65px" />
                    </colgroup>
                    <thead>
                        <tr>
                            <th scope="col">처리상태</th>
                            <th scope="col">제목</th>
                            <th scope="col">작성자</th>
                            <th scope="col">등록일</th>
                            <th scope="col">조회수</th>
                        </tr>
                    </thead>
                    <tbody>
                    {inquireList && inquireList.map((inquire, index) => {
                        return (
                            <React.Fragment key={`inquireQnaNo${inquire.qnaNo}`}>
                            <tr className="pkg-qna-title js_accor_tit" onClick={(e) => {
                                clickInquire(e)
                            }}>
                                <td className="a-center"><span className="badge-base">{inquire.stsCdNm}</span></td>
                                <td className="qna-tit">{inquire.title}</td>
                                <td className="a-center">{inquire.regNm}</td>
                                <td className="a-center">{inquire.regDt}</td>
                                <td className="a-center">{inquire.hitCnt}</td>
                            </tr>
                            <tr className="pkg-qna-detail js_accor_con">
                                <td><span className="a11y">없음</span></td>
                                <td colSpan="3" className="qna-con">
                                    <ul>
                                        <li className="q">
                                            <div className="st">질문</div>
                                            <p className="txt" style={{wordBreak: 'break-word'}}>
                                                {inquire.cont}
                                            </p>
                                        </li>
                                        {inquire.ansCont && (
                                            <li className="a">
                                            <div className="st">답변</div>
                                            <p className="txt" style={{wordBreak: 'break-word'}}>
                                                {inquire.ansCont}
                                            </p>
                                        </li>
                                        )}
                                    </ul>
                                </td>
                                <td><span className="a11y">없음</span></td>
                            </tr>
                            </React.Fragment>
                        )})
                    }
                    {!inquireList || inquireList.length === 0 &&
                        <tr><td colSpan="5" className="a-center">아직 등록된 문의글이 없습니다. 처음으로 문의글을 남겨보세요!</td></tr>
                    }
                    </tbody>
                </table>
                <Pagination
                    total={inquireTotal}
                    limit={limit}
                    page={page}
                    setPage={clickPage}
                />
            </div>
        </article>
    );
};

export default Inquire;