import {useState} from "react";
import {MomentWrapper} from "./MomentWrapper";

export default function useMoment() {
    const [moment, setMoment] = useState(new MomentWrapper());
    const [today, setToday] = useState(moment.getToday())

    return {
        moment,
        today
    }
}