import {useEffect, useState} from "react";
import {findChildProductByCode} from "../../domain/repository/ProductRepository";
import useChildProductFilter from "../usecase/product-filter/child/useChildProductFilter";


export default function useLoadChildProductDynamicList({parentProduct, notLoad}) {
    const [parentProductState, _] = useState(parentProduct)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null);
    const [childProductList, setChildProductList] = useState([])
    const {doFilterAndSort} = useChildProductFilter()

    const filteredList = doFilterAndSort(childProductList)

    useEffect(() => {
        if (notLoad) return

        setLoading(true)
        setError(false)
        setChildProductList([])
        async function fetch() {
            try {
                const res = await findChildProductByCode(parentProductState.representProductCode)
                const data = res.data
                setChildProductList(data.saleProducts)
            } catch (error) {
                setError(true)
                console.log("에러 봅시다.", error)
            } finally {
                setLoading(false)
            }
        }

        fetch().catch();
    }, [parentProductState.representProductCode]);

    function isEmpty() {
        if (loading === false && error === false) {
            return filteredList.length === 0
        }
        return false
    }

    return {
        childProductList: filteredList,
        allLoadedChildProductList: childProductList,
        loading,
        error,
        isEmpty: isEmpty(),
    }
}