import {useEffect} from "react";

export default function useEvent(eventManager, eventName, handler) {
    const key = handler.toString()

    useEffect(() => {
        if (!eventManager) {
            return;
        }
        if (!eventName) {
            return;
        }
        if (!handler) {
            return;
        }
        eventManager.subscribe(eventName, handler);
        return () => {
            eventManager.unsubscribe(eventName, key);
        }
    }, [key]);

}