import React from "react";
import useMoment from "../../../../infra/hooks/moment/useMoment";
import {useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import {useRecoilValue} from "recoil";
import {getRepresentProductOneAtom} from "../../../state/ParentProductFilterState";


export default function SimplePanel({
                                        queryParam,
                                         handleClick,
                                     }) {
    let {searchKeyword, startDate, endDate} = queryParam
    const saleProduct = useSelector(state => state.productReducer.saleProduct);
    const representProduct = useRecoilValue(getRepresentProductOneAtom)
    const location = useLocation();
    const locationPath = location.pathname;
    const saleProductDetailRegex = /\/representative\/(.*)\/sale\/(.*)\/detail/;
    const representProductRegex = /\/representative\/(.*)\/sale/;
    if (locationPath.match(saleProductDetailRegex)) {
        searchKeyword = saleProduct?.saleProductName
        startDate = saleProduct?.departureDay
        endDate = startDate
    } else if (locationPath.match(representProductRegex)) {
        searchKeyword = representProduct?.representProductName
        startDate = representProduct?.minDepartureDay
    }

    function onClickSearchArea() {
        handleClick && handleClick();
    }

    return (
        <div className="htp-top-sec" onClick={onClickSearchArea}>
            <div className="ly-inner clear_fix">
                <div className="htp-places clear_fix">
                    <a style={{cursor: "pointer"}}>
                        <p className="label">국가명, 도시명, 상품명, 상품코드</p>
                        <div className="pkg-info">
                            <span className="txt ellipsis">{searchKeyword || ''}</span>
                        </div>
                    </a>
                </div>
                <div className="htp-dates clear_fix">
                    <a style={{cursor: "pointer"}}>
                        <p className="label">출발 날짜</p>
                        <div className="result">
                            <DateRange startDate={startDate} endDate={endDate}/>
                        </div>
                        <span className="ico-search"></span>
                    </a>
                </div>
            </div>
        </div>
    )
}

function DateRange({startDate, endDate}) {
    const {moment} = useMoment()

    if (startDate === '') return null;
    if (startDate === null) return null;

    const startView = moment.makeViewString(moment.yyyymmddToMoment(startDate))
    const endView = moment.makeViewString(moment.yyyymmddToMoment(endDate))
    if (startDate === endDate) return (
        <span>
            {startView}
        </span>
    )

    return (
        <span>
            {startView} ~ {endView}
        </span>
    )
}