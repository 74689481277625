import React, {useEffect, useState} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import Modal from '@mui/material/Modal';
import ProductLevelPopup from "../popup/ProductLevelPopup";
import GuideFeePopup from "../popup/GuideFeePopup";
import {formatNumber} from "../../utils/formatNumber";
import CouponDownloadPopup from "../popup/CouponDownloadPopup";
import {goLoginPage} from "../../common/util/RedirectLogin";
import {useParams} from "react-router-dom";
import axios from "axios";
import {setDownloadableCouponList, setErrorMessage, setIssuedCouponList} from "../../redux/module/detail/DetailReducer";
import {createTheme, Dialog, DialogContent} from "@mui/material";
import {getAxiosHeaders} from "../../common/util/Axios";
import SaleProductEntity from "../../kyb/domain/model/product/SaleProductEntity";

const ProductInfo = () => {

    const params = useParams();
    const dispatch = useDispatch();
    const frontServerUrl = process.env.REACT_APP_FRONT_URL;
    const serverUrl = process.env.REACT_APP_SERVER_URL;
    const [isProductLevelModalOpen, setIsProductLevelModalOpen] = useState(false);
    const [isGuideFeeModalOpen, setIsGuideFeeModalOpen] = useState(false);

    let representativeProduct = useSelector((state) => state.productReducer.representativeProduct, shallowEqual);
    let saleProduct = useSelector((state) => state.productReducer.saleProduct, shallowEqual);
    let downloadableCouponList = useSelector((state) => state.detailReducer.downloadableCouponList, shallowEqual);
    let userInfo = useSelector((state) => state.headerReducer.userInfo, shallowEqual);
    const issuedCouponList = useSelector((state) => state.detailReducer.issuedCouponList, shallowEqual);
    const [open, setOpen] = useState(false);
    const [scroll, setScroll] = React.useState('paper');
    const theme = createTheme({
        components: {
            MuiDialogContent: {
                styleOverrides: {   // Name of the slot
                    root: { // Name of the rule
                        overflowX: 'hidden',
                        padding: '0px',
                        borderTop: '0px',
                        borderBottom: '0px'
                    },
                }
            }
        }
    })

    useEffect(() => {
        getDownloadableCouponList(userInfo ? userInfo.memberNumber : null).then(r => console.log(r));
        getIssuedCouponList(userInfo ? userInfo.memberNumber : null).then(r => console.log(r));
    }, [userInfo]);

    const handleClickOpen = (scrollType) => () => {
        if (userInfo && userInfo.memberNumber) {
            setOpen(true);
            setScroll(scrollType);
        } else {
            let returnUrl = frontServerUrl + `/representative/` + params.representativeCode.toUpperCase() +`/sale/` + params.saleCode.toUpperCase() +`/detail`;
            goLoginPage(returnUrl);
        }
    };
    const handleClose = () => {
        setOpen(false);
    };

    const openModalPopup = (popupName) => {
        if (popupName === 'productLevel') {
            setIsProductLevelModalOpen(true);
        } else if (popupName === 'guideFee') {
            setIsGuideFeeModalOpen(true);
        }
    }

    const closeModalPopup = (popupName) => {
        if (popupName === 'productLevel') {
            setIsProductLevelModalOpen(false);
        } else if (popupName === 'guideFee') {
            setIsGuideFeeModalOpen(false);
        }
    }

    const getDownloadableCouponList = async (memberNo) => {
        if (memberNo) {
        } else {
            memberNo = 0;
        }
        axios.get(serverUrl + '/api/v1/payment/' + memberNo + '/coupon/downloadable',{
            headers: getAxiosHeaders()
        }).catch(function (error) {
            console.log(error.toJSON());
        }).then(function (response) {
            if (response) {
                dispatch(setDownloadableCouponList(response.data));
            } else {
                dispatch(setErrorMessage('error'));
            }
        });
    }

    const getIssuedCouponList = async (memberNo) => {
        if (memberNo) {
            axios.get(serverUrl + '/api/v1/payment/' + memberNo + '/coupon/issued',{
                headers: getAxiosHeaders()
            }).catch(function (error) {
                console.log(error.toJSON());
            }).then(function (response) {
                if (response) {
                    dispatch(setIssuedCouponList(response.data));
                } else {
                    dispatch(setErrorMessage('error'));
                }
            });
        }
    }
    console.log("코와이네>> ", saleProduct.reservationDivisionName)
    return (
            <React.Fragment>
                <div className="between-box fc">
                    <div className="addition-info">
                        {saleProduct.saleProductDivision && (
                            <span className="badge-base">{saleProduct.saleProductDivision}</span>
                        )}
                        <ReservationStatusIcon {...saleProduct} />
                        <p className="pd-nav">
                            <span>{saleProduct.supplierName}</span>
                            <span>상품코드 {saleProduct.saleProductCode}</span>
                        </p>
                    </div>
                    <div className="btn-base btn-st-uline">
                        <button type="button" onClick={() => { openModalPopup('productLevel') }}>상품 등급 및 상태</button>
                        <Modal
                            open={isProductLevelModalOpen}
                            onClose={() => { closeModalPopup('productLevel') }}>
                          <><ProductLevelPopup
                              closeModalPopup={() => { closeModalPopup('productLevel') }}
                          /></>
                        </Modal>
                    </div>
                </div>
                <div className="main-headline">
                    <div className="pd-h1">{saleProduct.saleProductName}</div>
                    <p className="desc ellipsis2">{saleProduct.supplierCode && saleProduct.supplierCode === "MODE" ? representativeProduct.representProductTags && representativeProduct.representProductTags.split(",").map(tag => {return "#" + tag}) : representativeProduct.representProductContents}</p>
                </div>
                <div className="between-box fc">
                    <div className="pd-detail-total-price">
                        <span className="pd-detail-tootip">
                            최대 혜택가(성인 1명 기준)
                            <span className="help-tooltip pkg-st square">
                                <span className="over" onClick={() => { openModalPopup('guideFee') }}>?</span>
                                <Modal
                                    open={isGuideFeeModalOpen}
                                    onClose={() => { closeModalPopup('guideFee') }}>
                                  <><GuideFeePopup
                                      saleProduct={saleProduct}
                                      closeModalPopup={() => { closeModalPopup('guideFee') }}
                                  /></>
                                </Modal>
                            </span>
                        </span>

                        <DiscountSaleProductSpan saleProduct={saleProduct}/>
                    </div>
                    {
                        (()=>{
                           if(downloadableCouponList && downloadableCouponList.length > 0) {
                               return(
                                   <div className="cp-down btn-base btn-st-full btn-bg-bl w130">
                                       <button type="button" onClick={handleClickOpen('paper')}>쿠폰받기</button>
                                       <Dialog
                                           open={open}
                                           onClose={handleClose}
                                           scroll={scroll}
                                           theme={theme}
                                       >
                                           <div className="popup-wrap" style={{width: '530px'}}>
                                               <div className="heading">
                                                   <h2>쿠폰 다운로드</h2>
                                                   <a style={{cursor:"pointer"}} className="btn_close" onClick={handleClose}>닫기</a>
                                               </div>
                                               <div className="content">
                                                   <div className="inner">
                                                       <DialogContent dividers={scroll === 'paper'} theme={theme}>
                                                           <CouponDownloadPopup
                                                               getDownloadableCouponList={getDownloadableCouponList}
                                                               getIssuedCouponList={getIssuedCouponList}
                                                           />
                                                       </DialogContent>
                                                   </div>
                                               </div>
                                           </div>
                                       </Dialog>
                                   </div>
                               )
                           } else if(issuedCouponList && issuedCouponList.length > 0) {
                               return (
                                   <div className="cp-down btn-base btn-st-full btn-bg-bl w130">
                                       <button type="button" onClick={handleClickOpen('paper')}>쿠폰받기</button>
                                       <Dialog
                                           open={open}
                                           onClose={handleClose}
                                           scroll={scroll}
                                           theme={theme}
                                       >
                                           <div className="popup-wrap" style={{width: '530px'}}>
                                               <div className="heading">
                                                   <h2>쿠폰 다운로드</h2>
                                                   <a style={{cursor:"pointer"}} className="btn_close" onClick={handleClose}>닫기</a>
                                               </div>
                                               <div className="content">
                                                   <div className="inner">
                                                       <DialogContent dividers={scroll === 'paper'} theme={theme}>
                                                           <CouponDownloadPopup
                                                               getDownloadableCouponList={getDownloadableCouponList}
                                                               getIssuedCouponList={getIssuedCouponList}
                                                           />
                                                       </DialogContent>
                                                   </div>
                                               </div>
                                           </div>
                                       </Dialog>
                                   </div>
                               )
                           }
                        }
                        )()
                    }

                    {/*{downloadableCouponList && downloadableCouponList.length > 0 && (*/}
                    {/*    <div className="cp-down btn-base btn-st-full btn-bg-bl w130">*/}
                    {/*        <button type="button" onClick={handleClickOpen('paper')}>쿠폰받기</button>*/}
                    {/*        <Dialog*/}
                    {/*            open={open}*/}
                    {/*            onClose={handleClose}*/}
                    {/*            scroll={scroll}*/}
                    {/*            theme={theme}*/}
                    {/*        >*/}
                    {/*            <div className="popup-wrap" style={{width: '530px'}}>*/}
                    {/*                <div className="heading">*/}
                    {/*                    <h2>쿠폰 다운로드</h2>*/}
                    {/*                    <a style={{cursor:"pointer"}} className="btn_close" onClick={handleClose}>닫기</a>*/}
                    {/*                </div>*/}
                    {/*                <div className="content">*/}
                    {/*                    <div className="inner">*/}
                    {/*                        <DialogContent dividers={scroll === 'paper'} theme={theme}>*/}
                    {/*                            <CouponDownloadPopup*/}
                    {/*                              getDownloadableCouponList={getDownloadableCouponList}*/}
                    {/*                              getIssuedCouponList={getIssuedCouponList}*/}
                    {/*                            />*/}
                    {/*                        </DialogContent>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </Dialog>*/}
                    {/*    </div>*/}
                    {/*    )*/}
                    {/*}*/}
                </div>
            </React.Fragment>
    );
};

function DiscountSaleProductSpan({saleProduct}) {
    const saleProductModel = new SaleProductEntity(saleProduct)
    const {discountAmount} = saleProductModel.getDiscountBySaleProduct({adultCount: 1, childCount: 0, infantCount: 0})
    return (
        <span className="pd-total-amt">
            <span className="c-point1">{saleProductModel.getMaxDiscountPercent()}%</span>
            <span className="price">
                <em>
                    {formatNumber(discountAmount)}
                </em>원~
            </span>
        </span>
    )
}

function ReservationStatusIcon({supplierCode, remainSeatCount, reservationDivision, reservationDivisionName}) {

    let viewText = reservationDivisionName
    if (remainSeatCount === 0) {
        if (['MODE', "KAL"].includes(supplierCode)) {
            viewText = '예약대기'
        }
    }
    return (
        <span className="badge-base c-point5">{viewText}</span>
    )
}

export default ProductInfo;