import {atom} from "recoil";


export const voucherState = atom({
        key: 'voucherState', // Unique ID (with respect to other atoms/selectors)
        default: {
            loading: true,
            error: null,
            voucherList: [],
        },
    }
)


export const mPointState = atom({
        key: 'mPointState', // Unique ID (with respect to other atoms/selectors)
        default: {
            loading: false,
            error: null,
            mPoint: 0,
        },
    }
)

export const familyDiscountState = atom({
        key: 'familyDiscountState', // Unique ID (with respect to other atoms/selectors)
        default: {
            loading: false,
            error: null,
            familyDiscount: null, // 확인해야지
        },
    }
)