export const EVENT_NAMES = Object.freeze({
    LOAD_USER: "LOAD_USER",
    LOAD_COUPON: "LOAD_COUPON",
    LOAD_VOUCHER: "LOAD_VOUCHER",
    LOAD_TICKET: "LOAD_TICKET",
    LOAD_FAMILY_DISCOUNT: "LOAD_FAMILY_DISCOUNT",
    LOAD_SALE_PRODUCT: "LOAD_SALE_PRODUCT",
    LOAD_REPRESENTATIVE_PRODUCT: "LOAD_REPRESENTATIVE_PRODUCT",
    LOAD_AMOUNT_LIST: "LOAD_AMOUNT_LIST",
    LOAD_PAYMENT_PRICE: "LOAD_PAYMENT_PRICE",
    LOAD_M_POINT: "LOAD_M_POINT",
    LOAD_TRAVELER: "LOAD_TRAVELER",

    /////////////////////////////////////////////////////////////
    SET_DISCOUNT: "SET_DISCOUNT",
    REMOVE_DISCOUNT: "REMOVE_DISCOUNT",
    /////////////////////////////////////////////////////////////

    CHANGE_COUPON_DISCOUNT: "CHANGE_COUPON_DISCOUNT",
    CHANGE_VOUCHER_DISCOUNT: "CHANGE_VOUCHER_DISCOUNT",
    INIT_VOUCHER_DISCOUNT: "INIT_VOUCHER_DISCOUNT",
    CHANGE_TICKET_DISCOUNT: "CHANGE_TICKET_DISCOUNT",

    SET_VOUCHER_DISCOUNT_CLASS: "SET_VOUCHER_DISCOUNT",
    REMOVE_DISCOUNT_CLASS: "REMOVE_DISCOUNT_CLASS",
});
