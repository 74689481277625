import {selector} from "recoil";
import {paymentPriceStateAtom} from "../PaymentPriceState";
import {VOUCHER_TYPE} from "../../../../domain/model/user/UserVoucherEntity";
import {cloneDeep} from "lodash";
import {getDiscountValueDict} from "../DiscountSelector";
import {DiscountType} from "../../../../domain/service/payment/PaymentEnum";


/*
* 실제 바우처 할인 금액을 계산하는 함수
* */
export function getVoucherDiscountValueAtType(voucher, targetValue) {
    const foundList = voucher.optionList.filter(v => v.selected)
    if (!foundList.length === 0) return 0
    const accWon = foundList.reduce((acc, cur) => {
        return acc + cur.won
    }, 0)
    if (targetValue < accWon) {
        return targetValue
    }
    return accWon
}

export function getVoucherSubmitData(paymentPrice) {
    const voucher = paymentPrice.voucher
    const selectedList = [
        ...voucher[VOUCHER_TYPE.RED5].optionList.filter(v => v.selected),
        ...voucher[VOUCHER_TYPE.GREEN].optionList.filter(v => v.selected),
        ...voucher[VOUCHER_TYPE.RED4].optionList.filter(v => v.selected),
        ...voucher[VOUCHER_TYPE.RED_STRIP].optionList.filter(v => v.selected),
        ...voucher[VOUCHER_TYPE.SUMMIT].optionList.filter(v => v.selected),
    ]
    const discountValueDict = getDiscountValueDict(paymentPrice)

    const voucherValue = discountValueDict[DiscountType.RedVoucher] +
        discountValueDict[DiscountType.GreenVoucher] +
        discountValueDict[DiscountType.RedVoucher4] +
        discountValueDict[DiscountType.RedVoucherStrip] +
        discountValueDict[DiscountType.SummitVoucher]

    if (voucherValue === 0) {
        return {
            voucherList: []
        }
    }
    return {
        voucherList: selectedList.map(v => v.data)
    }
}

export function initVoucher(voucher) {
    return {
        ...voucher,
        [VOUCHER_TYPE.GREEN]: {
            ...voucher[VOUCHER_TYPE.GREEN],
            optionList: voucher[VOUCHER_TYPE.GREEN].optionList.map(opt => ({...opt})).map(opt => {
                opt.selected = false
                return opt
            }),
        },
        [VOUCHER_TYPE.RED5]: {
            ...voucher[VOUCHER_TYPE.RED5],
            optionList: voucher[VOUCHER_TYPE.RED5].optionList.map(opt => ({...opt})).map(opt => {
                opt.selected = false
                return opt
            }),
        },
        [VOUCHER_TYPE.RED4]: {
            ...voucher[VOUCHER_TYPE.RED4],
            optionList: voucher[VOUCHER_TYPE.RED4].optionList.map(opt => ({...opt})).map(opt => {
                opt.selected = false
                return opt
            }),
        },
        [VOUCHER_TYPE.RED_STRIP]: {
            ...voucher[VOUCHER_TYPE.RED_STRIP],
            optionList: voucher[VOUCHER_TYPE.RED_STRIP].optionList.map(opt => ({...opt})).map(opt => {
                opt.selected = false
                return opt
            }),
        },
        [VOUCHER_TYPE.SUMMIT]: {
            ...voucher[VOUCHER_TYPE.SUMMIT],
            optionList: voucher[VOUCHER_TYPE.SUMMIT].optionList.map(opt => ({...opt})).map(opt => {
                opt.selected = false
                return opt
            }),
        },
    }
}
export function getVoucherDiscountValue(voucherType) {
    return (paymentPrice, targetValue) => {
        return getVoucherDiscountValueAtType(paymentPrice.voucher[voucherType], targetValue)
    }
}

function createSetOptionListSelector(voucherType) {
    return selector({
        key: 'setOptionListSelector_' + voucherType,
        get: ({get}) => {
            const old = get(paymentPriceStateAtom);
            return old.voucher[voucherType].optionList
        },
        set: ({set}, optionList) => {
            function setRedVoucherOptionList(paymentPrice, optionList) {
                const voucher = cloneDeep(paymentPrice.voucher)
                voucher[voucherType].optionList = optionList
                return {
                    ...paymentPrice,
                    voucher
                }
            }

            set(paymentPriceStateAtom,
                p => setRedVoucherOptionList(p, optionList))
        }
    })
}

export const setRedVoucherOptionListSelector = createSetOptionListSelector(VOUCHER_TYPE.RED5)
export const setRedVoucher4OptionListSelector = createSetOptionListSelector(VOUCHER_TYPE.RED4)
export const setRedVoucherStripeOptionListSelector = createSetOptionListSelector(VOUCHER_TYPE.RED_STRIP)
export const setGreenVoucherOptionListSelector = createSetOptionListSelector(VOUCHER_TYPE.GREEN)
export const setSummitVoucherOptionListSelector = createSetOptionListSelector(VOUCHER_TYPE.SUMMIT)


function createSetSelectedSelector(voucherType) {
    return selector({
        key: 'setSelectedSelector_' + voucherType,
        get: ({get}) => {
            const old = get(paymentPriceStateAtom);
            return old.voucher[voucherType].optionList.filter(v => v.selected)
        },
        set: ({set}, _selectedList) => {
            const selectedIdDict = _selectedList.reduce((acc, cur) => {
                acc[cur.id] = true
                return acc
            }, {})

            function setSelected(paymentPrice) {
                const voucher = cloneDeep(paymentPrice.voucher)

                voucher[voucherType].optionList.forEach(v => {
                    v.selected = false
                })

                voucher[voucherType].optionList.forEach(v => {
                    if (selectedIdDict[v.id]) {
                        v.selected = true
                    }
                    return v
                })

                return {
                    ...paymentPrice,
                    voucher
                }
            }

            set(paymentPriceStateAtom,
                p => setSelected(p))
        }
    })
}

export const setSelectedRedVoucherSelector = createSetSelectedSelector(VOUCHER_TYPE.RED5)
export const setSelectedRedVoucher4Selector = createSetSelectedSelector(VOUCHER_TYPE.RED4)
export const setSelectedRedVoucherStripeSelector = createSetSelectedSelector(VOUCHER_TYPE.RED_STRIP)
export const setSelectedGreenVoucherSelector = createSetSelectedSelector(VOUCHER_TYPE.GREEN)
export const setSelectedSummitVoucherSelector = createSetSelectedSelector(VOUCHER_TYPE.SUMMIT)



function createGetDiscountSelector(voucherType) {
    return selector({
        key: 'getDiscountSelector_' + voucherType,
        get: ({get}) => {
            const old = get(paymentPriceStateAtom);
            return old.voucher[voucherType].discount
        }
    })
}

export const getRedVoucherDiscountSelector = createGetDiscountSelector(VOUCHER_TYPE.RED5)
export const getRedVoucher4DiscountValueSelector = createGetDiscountSelector(VOUCHER_TYPE.RED4)
export const getRedVoucherStripeDiscountSelector = createGetDiscountSelector(VOUCHER_TYPE.RED_STRIP)
export const getGreenVoucherDiscountSelector = createGetDiscountSelector(VOUCHER_TYPE.GREEN)
export const getSummitVoucherDiscountSelector = createGetDiscountSelector(VOUCHER_TYPE.SUMMIT)


function createSetInvalidSelector(voucherType) {
    return selector({
        key: 'setInvalidSelector_' + voucherType,
        get: ({get}) => {
            const old = get(paymentPriceStateAtom);
            return old.voucher[voucherType]
        },
        set: ({set}, invalid) => {
            function setInvalid(paymentPrice, invalid) {
                const voucher = cloneDeep(paymentPrice.voucher)
                voucher[voucherType].valid = false
                voucher[voucherType].enable = false
                voucher[voucherType].optionList = []
                return {
                    ...paymentPrice,
                    voucher
                }
            }

            set(paymentPriceStateAtom,
                p => setInvalid(p, invalid))
        }
    })
}

export const setInvalidRedVoucherSelector = createSetInvalidSelector(VOUCHER_TYPE.RED5)
export const setInvalidRedVoucher4Selector = createSetInvalidSelector(VOUCHER_TYPE.RED4)
export const setInvalidRedVoucherStripeSelector = createSetInvalidSelector(VOUCHER_TYPE.RED_STRIP)
export const setInvalidGreenVoucherSelector = createSetInvalidSelector(VOUCHER_TYPE.GREEN)
export const setInvalidSummitVoucherSelector = createSetInvalidSelector(VOUCHER_TYPE.SUMMIT)