import React, {useEffect, useState} from "react";
import SHA256 from "../../../../../utils/SHA256";
import MakeTimeStamp from "../../../../../utils/makeTimeStamp";

/*
* 일단 홀딩..
* */
export default function InicisComponent({
                                            payData,
                                            isRepay,
                                            releaseURL,
                                            revEmail,
                                        }) {
    const [timestamp, setTimestamp] = useState(0);
    const [callNumber, setCallNumber] = useState(0);
    useEffect(() => {
        if (payData.preSavedReservationNumber === null) {
            return;
        }
        if (payData.inicisCallNumber === 0) {
            return;
        }
        if (payData.inicisCallNumber === callNumber) {
            return;
        }
        const tid = setTimeout(() => {
            const _timeStamp = MakeTimeStamp();
            setTimestamp(_timeStamp);
            // // PC
            const agt = navigator.userAgent.toLowerCase();
            const script = document.createElement("script");
            script.src = releaseURL;
            document.head.appendChild(script);
            script.onload = e => {
                if ((navigator.appName === 'Netscape' && agt.indexOf('trident') !== -1) || (agt.indexOf("msie") !== -1) || agt.indexOf('edge')) {
                    e.srcElement.ownerDocument.defaultView.INIStdPay.pay('SendPayForm_id');
                } else {
                    e.path[3].defaultView.INIStdPay.pay('SendPayForm_id');
                }
                if (payData.setInicisLoading) {
                    payData.setInicisLoading(false);
                }
                setCallNumber(payData.inicisCallNumber);
            }
        }, 300)

        return () => {
            clearTimeout(tid);
        }
        // return () => {
        //     document.head.removeChild(script);
        // }
    }, [payData.inicisCallNumber, callNumber]);

    if (callNumber === 0) {
        return null
    }

    const {
        envType
    } = payData

    function randomNumberInRange(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }
    let randomNumber = randomNumberInRange(0, 100);

    const reservationNumber = payData.preSavedReservationNumber;

    const merchantDataObj = {
        frontPaymentType: payData.paymentType,
        isPointUse: payData.isPointUse,
        usePoint: payData.usePoint,
        mid: payData.mid,
        isTwoPay: payData.isTwoPay || false,
    }

    return (
        <div style={{display: "none"}}>
            {/* 이니시스 PC 결제 폼 */}
            <form id="SendPayForm_id" name="" method="Post">
                <input type="text" readOnly name="goodname" value={payData.productName}/>
                <input type="text" readOnly name="buyername" value={payData.buyerName}/>
                <input type="text" readOnly name="buyertel" value={payData.buyerTel}/>
                <input type="text" readOnly name="buyeremail" value={payData.buyerEmail}/>
                <input type="text" readOnly name="price" value={payData.productPrice}/>
                <input type="hidden" readOnly name="mid" value={payData.mid}/>
                <input type="hidden" readOnly name="gopaymethod" value="Card"/>
                <input type="hidden" readOnly name="mKey" value={SHA256(payData.mKey)}/>
                <input type="hidden" readOnly name="signature"
                       value={SHA256(`oid=${isRepay ? reservationNumber + "_RP" + randomNumber : reservationNumber}&price=${payData.productPrice}&timestamp=${timestamp}`)}/>
                <input type="hidden" readOnly name="oid"
                       value={isRepay ? reservationNumber + "_RP" + randomNumber : reservationNumber}/>
                <input type="hidden" readOnly name="timestamp" value={timestamp}/>
                <input type="hidden" readOnly name="version" value="1.0"/>
                <input type="hidden" readOnly name="currency" value="WON"/>
                <input type="hidden" readOnly name="merchantData" value={btoa(JSON.stringify(merchantDataObj))}/>

                {payData.productPrice >= 50000 ? (
                    <input type="hidden" readOnly name="quotabase" value="2:3:4:5:6:7:8:9:10:11:12"/>
                ) : ('')}
                {"LOCAL" === envType || "DEV" === envType ? (
                    <input type="hidden" readOnly name="acceptmethod" value={`below1000`}/>
                ) : (
                    <input type="hidden" readOnly name="acceptmethod"
                           value={`below1000:ini_onlycardcode(${payData.cardCode}):noeasypay`}/>
                )}

                <input
                    type="hidden"
                    readOnly
                    name="returnUrl"
                    value={`${payData.returnUrl}?noMbrLogin=${revEmail || ''}`}
                />

                <input
                    type="hidden"
                    readOnly
                    name="closeUrl"
                    value={payData.closeUrl}
                />
            </form>
        </div>
    )
}