const SearchSkeletonLeft = () => {

    return(
        <div className="pkg-listl">
            <div className="pkg-sch-set-accomm">
                <div className="sch-set-inner">
                    <div className="tit-wrap between-box fc">
                        <div className="tit-h3">결과 내 검색</div>
                        <a href="#" className="btn-init reset">초기화</a>
                    </div>
                    <div className="filter-wrap">
                        <div className="pkg-sl-skeleton is-skel">
                            <div className="skel-group">
                                <div className="skel-tit">
                                    <span className="exp1 skel-bg"></span>
                                    <span className="exp1 skel-bg"></span>
                                </div>
                                <ul className="skel-items">
                                    <li>
                                        <span className="exp2 skel-bg"></span>
                                        <span className="exp3 skel-bg"></span>
                                    </li>
                                    <li>
                                        <span className="exp2 skel-bg"></span>
                                        <span className="exp3 skel-bg"></span>
                                    </li>
                                    <li>
                                        <span className="exp2 skel-bg"></span>
                                        <span className="exp3 skel-bg"></span>
                                    </li>
                                    <li>
                                        <span className="exp2 skel-bg"></span>
                                        <span className="exp3 skel-bg"></span>
                                    </li>
                                </ul>
                            </div>
                            <div className="skel-group">
                                <div className="skel-tit">
                                    <span className="exp1 skel-bg"></span>
                                    <span className="exp1 skel-bg"></span>
                                </div>
                                <ul className="skel-items">
                                    <li>
                                        <span className="exp2 skel-bg"></span>
                                        <span className="exp3 skel-bg"></span>
                                    </li>
                                    <li>
                                        <span className="exp2 skel-bg"></span>
                                        <span className="exp3 skel-bg"></span>
                                    </li>
                                    <li>
                                        <span className="exp2 skel-bg"></span>
                                        <span className="exp3 skel-bg"></span>
                                    </li>
                                    <li>
                                        <span className="exp2 skel-bg"></span>
                                        <span className="exp3 skel-bg"></span>
                                    </li>
                                </ul>
                            </div>
                            <div className="skel-group">
                                <div className="skel-tit">
                                    <span className="exp1 skel-bg"></span>
                                    <span className="exp1 skel-bg"></span>
                                </div>
                                <ul className="skel-items">
                                    <li>
                                        <span className="exp2 skel-bg"></span>
                                        <span className="exp3 skel-bg"></span>
                                    </li>
                                    <li>
                                        <span className="exp2 skel-bg"></span>
                                        <span className="exp3 skel-bg"></span>
                                    </li>
                                    <li>
                                        <span className="exp2 skel-bg"></span>
                                        <span className="exp3 skel-bg"></span>
                                    </li>
                                    <li>
                                        <span className="exp2 skel-bg"></span>
                                        <span className="exp3 skel-bg"></span>
                                    </li>
                                    <li>
                                        <span className="exp2 skel-bg"></span>
                                        <span className="exp3 skel-bg"></span>
                                    </li>
                                </ul>
                            </div>
                            <div className="skel-group">
                                <div className="skel-tit">
                                    <span className="exp1 skel-bg"></span>
                                    <span className="exp1 skel-bg"></span>
                                </div>
                                <ul className="skel-items">
                                    <li>
                                        <span className="exp2 skel-bg"></span>
                                        <span className="exp3 skel-bg"></span>
                                    </li>
                                    <li>
                                        <span className="exp2 skel-bg"></span>
                                        <span className="exp3 skel-bg"></span>
                                    </li>
                                    <li>
                                        <span className="exp2 skel-bg"></span>
                                        <span className="exp3 skel-bg"></span>
                                    </li>
                                    <li>
                                        <span className="exp2 skel-bg"></span>
                                        <span className="exp3 skel-bg"></span>
                                    </li>
                                    <li>
                                        <span className="exp2 skel-bg"></span>
                                        <span className="exp3 skel-bg"></span>
                                    </li>
                                </ul>
                            </div>
                            <div className="skel-group">
                                <div className="skel-tit">
                                    <span className="exp1 skel-bg"></span>
                                    <span className="exp1 skel-bg"></span>
                                </div>
                                <ul className="skel-items">
                                    <li>
                                        <span className="exp2 skel-bg"></span>
                                        <span className="exp3 skel-bg"></span>
                                    </li>
                                    <li>
                                        <span className="exp2 skel-bg"></span>
                                        <span className="exp3 skel-bg"></span>
                                    </li>
                                    <li>
                                        <span className="exp2 skel-bg"></span>
                                        <span className="exp3 skel-bg"></span>
                                    </li>
                                    <li>
                                        <span className="exp2 skel-bg"></span>
                                        <span className="exp3 skel-bg"></span>
                                    </li>
                                </ul>
                            </div>
                            <div className="skel-group">
                                <div className="skel-tit">
                                    <span className="exp1 skel-bg"></span>
                                    <span className="exp1 skel-bg"></span>
                                </div>
                                <ul className="skel-items">
                                    <li>
                                        <span className="exp2 skel-bg"></span>
                                        <span className="exp3 skel-bg"></span>
                                    </li>
                                    <li>
                                        <span className="exp2 skel-bg"></span>
                                        <span className="exp3 skel-bg"></span>
                                    </li>
                                    <li>
                                        <span className="exp2 skel-bg"></span>
                                        <span className="exp3 skel-bg"></span>
                                    </li>
                                    <li>
                                        <span className="exp2 skel-bg"></span>
                                        <span className="exp3 skel-bg"></span>
                                    </li>
                                    <li>
                                        <span className="exp2 skel-bg"></span>
                                        <span className="exp3 skel-bg"></span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SearchSkeletonLeft;