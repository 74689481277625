import React, {useEffect} from "react";
import {shallowEqual, useSelector} from "react-redux";
import 'moment/locale/ko';

const TravelerInfo = () => {

    let reservation = useSelector((state) => state.myPageReducer.reservation, shallowEqual);
    let paymentData = useSelector((state) => state.myPageReducer.paymentData, shallowEqual);

    useEffect(() => {
    }, []);

    const adultQuantity = paymentData && paymentData.priceList.filter(p=>p.ageDivision.includes("ADULT")).at(0).quantity;
    const childrenQuantity = paymentData && paymentData.priceList.filter(p=>p.ageDivision.includes("CHILDREN")).at(0).quantity;
    // const infantQuantity = paymentData && paymentData.priceList.filter(p=>p.ageDivision.includes("INFANT")).at(0).quantity;

    return (
        <React.Fragment>
            {reservation &&
                <section className="book-detail-area">
                    <div className="pkg-tit-page-cont a-pb30">
                        <h3 className="title">여행자 정보</h3>
                    </div>
                    <div className="book-article">
                        {paymentData.travelerInformationList && paymentData.travelerInformationList.map((traveler, index) => (
                            <React.Fragment key={traveler.mobileNumber}>
                            <div className={`pkg-resv-tit-article ${index === 0 ? 'a-mt0' : ''} bb-line`}>
                                <h3 className="title">{traveler.ageDivision === "adult" ? "성인" + (index + 1) :
                                    traveler.ageDivision === "children" ? "아동" + (index - adultQuantity + 1) :
                                        "유아" + (index - adultQuantity - childrenQuantity + 1)}</h3>
                            </div>
                            <table summary="여행자 정보" className="list-resv-tbl">
                                <caption>한글명, 영문명, 생년월일, 성별, 연락처, 이메일 항목이 있는 성인 여행자 정보 테이블</caption>
                                <colgroup>
                                    <col className="list-resv-th" />
                                    <col className="list-resv-td" />
                                    <col className="list-resv-th" />
                                    <col className="list-resv-td" />
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th>한글명</th>
                                        <td>{traveler.koreanName}</td>
                                        <th>영문명</th>
                                        <td>{traveler.englishFamilyName} / {traveler.englishFirstName}</td>
                                    </tr>
                                    <tr>
                                        <th>생년월일</th>
                                        <td>{traveler.birth}</td>
                                        <th>성별</th>
                                        <td>{traveler.sex === "M" ? "남자" : "여자"}</td>
                                    </tr>
                                    {traveler.ageDivision === "adult" &&
                                        <tr>
                                        <th>연락처</th>
                                        <td>{traveler.mobileNumber}</td>
                                        <th>이메일</th>
                                        <td>{traveler.emailAddress}</td>
                                    </tr>
                                    }
                                </tbody>
                            </table>
                            </React.Fragment>
                        ))}
                    </div>
                </section>
            }
        </React.Fragment>
    );
};

export default TravelerInfo;