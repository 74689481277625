// 액션 타입 정의
const SET_DOWNLOADABLE_COUPON_LIST = "PRODUCT/SET_DOWNLOADABLE_COUPON_LIST"
const SET_ISSUED_COUPON_LIST = "PRODUCT/SET_ISSUED_COUPON_LIST"
const SET_DETAIL_BANNER_LIST = "PRODUCT/SET_DETAIL_BANNER_LIST"
const SET_INQUIRE_LIST = "PRODUCT/SET_INQUIRE_LIST"
const SET_INQUIRE_TOTAL = "PRODUCT/SET_INQUIRE_TOTAL"
const SET_ERROR_MESSAGE = "DETAIL/SET_ERROR_MESSAGE"

export const setDownloadableCouponList = (downloadableCouponList) => ({ type: SET_DOWNLOADABLE_COUPON_LIST, downloadableCouponList });
export const setIssuedCouponList = (issuedCouponList) => ({ type: SET_ISSUED_COUPON_LIST, issuedCouponList });
export const setDetailBannerList = (detailBannerList) => ({ type: SET_DETAIL_BANNER_LIST, detailBannerList });
export const setInquireList = (inquireList) => ({ type: SET_INQUIRE_LIST, inquireList });
export const setInquireTotal = (inquireTotal) => ({ type: SET_INQUIRE_TOTAL, inquireTotal });
export const setErrorMessage = (errorMessage) => ({ type: SET_ERROR_MESSAGE, errorMessage });

// ***** 초기상태 정의
const initialState = {
    downloadableCouponList : null,
    issuedCouponList : null,
    detailBannerList: null,
    inquireList: null,
    inquireTotal: null,
    errorMessage: null
};

//리듀서 작성
const detailState = (state = initialState, action) => {
    switch (action.type) {
        case SET_ERROR_MESSAGE:
            return {
                ...state,
                errorMessage : action.errorMessage
            }
        case SET_DOWNLOADABLE_COUPON_LIST:
            return {
                ...state,
                downloadableCouponList : action.downloadableCouponList
            }
        case SET_ISSUED_COUPON_LIST:
            return {
                ...state,
                issuedCouponList : action.issuedCouponList
            }
        case SET_DETAIL_BANNER_LIST:
            return {
                ...state,
                detailBannerList : action.detailBannerList
            }
        case SET_INQUIRE_LIST:
            return {
                ...state,
                inquireList : action.inquireList
            }
        case SET_INQUIRE_TOTAL:
            return {
                ...state,
                inquireTotal : action.inquireTotal
            }
        default:
            return state;
    }
}

export default detailState;