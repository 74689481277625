import React from "react";
import 'moment/locale/ko';
import { Link } from "react-router-dom";

const InquireList = (props) => {

    const priviaUrl = process.env.REACT_APP_PRIVIA_BASE_URL;
    let inquireState = {
        "QNASTS001": "대기",
        "QNASTS002": "진행",
        "QNASTS003": "완료"
    };

    const goInquireForm = () => {
        window.location.href = priviaUrl + "/customer/inquire/form?svcDivnCd=TRV0010300&catDivnCd=" + props.catDivnCd
            + "&rservCd=" + props.reservation.reservationNumber
            + "&rservNm=" + props.reservation.saleProduct.saleProducts.at(0).saleProductName;
    }

    return (
        <React.Fragment>
            <section className="book-detail-area">
                <div className="pkg-tit-page-cont bb-line">
                    <h3 className="title">나의 질문답변 / 변경 내역</h3>
                </div>
                <div className="book-article">
                    <table summary="나의 질문답변 / 변경 내역" className="list-resv-tbl">
                        <caption>요청일, 요청내용, 진행사항 항목이 있는 나의 질문답변 / 변경 내역 테이블</caption>
                        <colgroup>
                            <col width="200px" />
                            <col width="*" />
                            <col width="200px" />
                        </colgroup>
                        <thead>
                            <tr>
                                <th>요청일</th>
                                <th>요청내용</th>
                                <th>진행사항</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(!props.inquireList || props.inquireList.length === 0) &&
                                <tr>
                                    <td colSpan={3} className="a-center">문의 내역이 없습니다.</td>
                                </tr>
                            }
                            {props.inquireList && props.inquireList.map((inquire, index) => (
                                <tr>
                                    <td className="a-center">{inquire.regDt}</td>
                                    <td className="a-center"><Link to={`${priviaUrl}/customer/inquire/view?qnaNo=${inquire.qnaNo}`}>{inquire.title}</Link></td>
                                    <td className="a-center">{inquireState[inquire.stsCd]}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <div className="btn-wrap a-right a-mt30">
                    <span className="btn-base btn-st-full btn-bg-bl w150">
                        <button type="button" onClick={goInquireForm}>온라인 문의</button>
                    </span>
                </div>
            </section>
        </React.Fragment>
    );
};

export default InquireList;