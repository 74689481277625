import React, {useEffect} from "react";
import {shallowEqual, useSelector} from "react-redux";
import 'moment/locale/ko';
import {formatNumber} from "../../utils/formatNumber";
import VoucherSpan from "../VoucherSpan";

const PaymentPartialInfo = () => {

    let reservation = useSelector((state) => state.myPageReducer.reservation, shallowEqual);
    let paymentData = useSelector((state) => state.myPageReducer.paymentData, shallowEqual);
    let refundData = useSelector((state) => state.myPageReducer.refundData, shallowEqual);

    useEffect(() => {
    }, []);

    const adult = paymentData && paymentData.priceList.filter(p=>p.ageDivision.includes("ADULT")).at(0);
    const children = paymentData && paymentData.priceList.filter(p=>p.ageDivision.includes("CHILDREN")).at(0);
    const infant = paymentData && paymentData.priceList.filter(p=>p.ageDivision.includes("INFANT")).at(0);
    console.log("제발..", paymentData)
    return (
        <React.Fragment>
            {reservation &&
            <>
            {/* 최초 결제정보 */}
                <section className="book-detail-area">
                    <div className="pkg-tit-page-cont bb-line">
                        <h3 className="title">최초 결제정보</h3>
                    </div>
                    <div className="book-article border-bottom">
                        <table summary="최초 결제정보" className="list-resv-tbl paytype-tbl">
                            <caption>구분, 성인 1, 아동 1, 유아 1 항목이 있는 최초 결제정보 테이블</caption>
                            <colgroup>
                                <col width="200px" />
                                <col width="*" />
                                <col width="*" />
                                <col width="*" />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>구분</th>
                                    <th>성인 {adult.quantity}</th>
                                    <th>아동 {children.quantity}</th>
                                    <th>유아 {infant.quantity}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="a-center">상품 금액</td>
                                    <td className="a-center">{formatNumber((adult.priceAmount + adult.fuelSurchargeAmount + adult.taxAmount) * adult.quantity)}원</td>
                                    <td className="a-center">{formatNumber((children.priceAmount + children.fuelSurchargeAmount + children.taxAmount) * children.quantity)}원</td>
                                    <td className="a-center">{formatNumber((infant.priceAmount + infant.fuelSurchargeAmount + infant.taxAmount) * infant.quantity)}원</td>
                                </tr>
                            </tbody>
                        </table>
                        <ul className="payment-info-ul border-top a-mt30">
                            <li>
                                <i className="pay-icons-bl minus" />
                                <div className="pm-info-header between-box fc">
                                    <div className="pm-info-tt">상품 금액</div>
                                    <div className="sub-total-amt">
                                        <span className="price a-bold"><em>{formatNumber( paymentData && (paymentData.totalAmount))}</em>원</span>
                                    </div>
                                </div>
                                {paymentData && paymentData.etcAmountList && paymentData.etcAmountList.length > 0 && paymentData.etcAmountList[0].amount > 0 && (
                                    <div className="price-wrap bnf-price-list">
                                    <div className="between-box fc inner-price-li">
                                        <div className="price_tt">추가 금액
                                            <div className="help-tooltip pkg-st small">
                                                <span className="over">?</span>
                                                <div className="cont overcharge" style={{width: '350px'}}>
                                                    <ul className="plus-charge-desc">
                                                        {paymentData.etcAmountList.map((item, index) => (
                                                            <li className="between-box ft" key={`etcAmount${index}`}>
                                                            <span>{item.etcAmountTitle}</span>
                                                            <span className="wfix a-right">{formatNumber(item.amount)}원</span>
                                                        </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <span className="price"><em>{formatNumber(paymentData.addAmount)}</em>원</span>
                                    </div>
                                </div>
                                )}
                            </li>
                            <li>
                                <i className="pay-icons-bl" />
                                <div className="pm-info-header between-box fc">
                                    <div className="pm-info-tt">혜택/할인 금액</div>
                                    <div className="sub-total-amt">
                                        <span className="price a-bold"><em>{formatNumber(paymentData.totalBenefitsAmount)}</em>원</span>
                                    </div>
                                </div>
                                <div className="price-wrap bnf-price-list">
                                    <VoucherSpan voucherList={paymentData.voucherList}/>

                                {paymentData.ticketList && paymentData.ticketList.length!==0 &&
                                    <div className="between-box fc inner-price-li">
                                        <p className="price_tt">{"PRIVIA 이용권 (총"+paymentData.ticketList.length+"장)"}</p>
                                        <span className="price"><em>-{formatNumber(paymentData.ticketList.at(0).useAmount)}</em>원</span>
                                    </div>
                                }
                                {paymentData.couponList && paymentData.couponList.length!==0 &&
                                    <div className="between-box fc inner-price-li">
                                        <p className="price_tt">{"PRIVIA 쿠폰 (총"+paymentData.couponList.length+"장)"}</p>
                                        <span className="price"><em>-{formatNumber(paymentData.couponList.map(c=>c.useAmount).reduce((prev, current) => prev + current, 0))}</em>원</span>
                                    </div>
                                }
                                {paymentData.isHccSaleUse &&
                                    <div className="between-box fc inner-price-li">
                                        <p className="price_tt">현대카드 3% 할인</p>
                                        <span className="price"><em>-{formatNumber(paymentData.hccSale)}</em>원</span>
                                    </div>
                                }
                                {paymentData.isHccFamilyUse &&
                                    <div className="between-box fc inner-price-li">
                                        <p className="price_tt">현대카드 패밀리 2% 할인</p>
                                        <span className="price"><em>-{formatNumber(paymentData.hccFamilySale)}</em>원</span>
                                    </div>
                                }
                                </div>
                            </li>
                            <li>
                                <div className="pm-info-header between-box fc">
                                    <div className="pm-info-tt">최종 결제금액</div>
                                    <div className="big-total-amt">
                                        <span className="price"><em>{paymentData && formatNumber(paymentData.actualPaymentAmount)}</em>원</span>
                                    </div>
                                </div>
                                <div className="price-wrap">
                                    {paymentData && paymentData.usePoint > 0 &&
                                    <div className="between-box fc inner-price-li point-price">
                                        <div className="price_tt">M포인트 <span className="c-point1">10%</span> 사용</div>
                                        <span className="price c-point1"><em>{paymentData && formatNumber(-1 * Math.floor(paymentData.usePoint))}</em>원</span>
                                        <p className="desc fs14">※ M포인트 차감된 금액으로 청구됩니다.</p>
                                    </div>
                                    }
                                    {paymentData && (paymentData.outstandingAmount > 0 || paymentData.overPaidAmount > 0) &&
                                        <div className={paymentData.usePoint > 0 ? 'case-plus-price-wrap' : ''}>
                                            <div className="between-box fc inner-price-li point-price">
                                                <div className="price_tt">{paymentData.outstandingAmount > 0 ? "남은 결제금액" :
                                                    paymentData.overPaidAmount > 0 ? "과입금 금액" : ""}</div>
                                                <span className="price">-{
                                                    paymentData.outstandingAmount > 0
                                                        ? formatNumber(paymentData.outstandingAmount) :
                                                        paymentData.overPaidAmount > 0
                                                            ? formatNumber(paymentData.overPaidAmount) : ""
                                                }원</span>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </li>
                        </ul>
                    </div>
                </section>
                {/*// 최초 결제정보 */}
                {/* 환불정보 */}
                <section className="book-detail-area">
                    <div className="pkg-tit-page-cont bb-line">
                        <h3 className="title">환불정보</h3>
                    </div>
                    <div className="book-article border-bottom">
                        <ul className="payment-info-ul">
                            <li>
                                <i className="pay-icons-bl minus"></i>
                                <div className="pm-info-header between-box fc">
                                    <div className="pm-info-tt">환불 요청 금액</div>
                                    <div className="sub-total-amt">
                                        <span className="price a-bold"><em>{formatNumber(paymentData.totalAmount)}</em>원</span>
                                    </div>
                                </div>
                                <div className="price-wrap">
                                    <div className="between-box fc inner-price-li a-mb15">
                                        <div className="price_tt btn-color-bl">상품 금액</div>
                                        <span className="price"><em>{formatNumber(paymentData.totalSalePrice)}</em>원</span>
                                    </div>
                                    <div className="detail-price-wrap">
                                        <div className="between-box fc inner-price-li">
                                            <div className="price_tt">성인x{adult.quantity}</div>
                                            <span className="price"><em>{formatNumber((adult.priceAmount + adult.fuelSurchargeAmount + adult.taxAmount) * adult.quantity)}</em>원</span>
                                        </div>
                                        {children.quantity > 0 &&
                                        <div className="between-box fc inner-price-li">
                                            <div className="price_tt">아동x{children.quantity}</div>
                                            <span className="price"><em>{formatNumber((children.priceAmount + children.fuelSurchargeAmount + children.taxAmount) * children.quantity)}</em>원</span>
                                        </div>
                                        }
                                        {infant.quantity > 0 &&
                                        <div className="between-box fc inner-price-li">
                                            <div className="price_tt">아동x{infant.quantity}</div>
                                            <span className="price"><em>{formatNumber((infant.priceAmount + infant.fuelSurchargeAmount + infant.taxAmount) * infant.quantity)}</em>원</span>
                                        </div>
                                        }
                                    </div>
                                    {paymentData && paymentData.etcAmountList && paymentData.etcAmountList.length > 0 && paymentData.etcAmountList[0].amount > 0 && (
                                        <div className="price-wrap bnf-price-list">
                                        <div className="between-box fc inner-price-li">
                                            <div className="price_tt">추가 금액
                                                <div className="help-tooltip pkg-st small">
                                                    <span className="over">?</span>
                                                    <div className="cont overcharge" style={{width: '350px'}}>
                                                        <ul className="plus-charge-desc">
                                                            {paymentData.etcAmountList.map((item, index) => (
                                                                <li className="between-box ft" key={`etcAmount${index}`}>
                                                                <span>{item.etcAmountTitle}</span>
                                                                <span className="wfix a-right">{formatNumber(item.amount)}원</span>
                                                            </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <span className="price"><em>{formatNumber(paymentData.addAmount)}</em>원</span>
                                        </div>
                                    </div>
                                    )}
                                </div>
                            </li>
                            <li>
                                <i className="pay-icons-bl"></i>
                                <div className="pm-info-header between-box fc">
                                    <div className="pm-info-tt">취소수수료</div>
                                    <div className="sub-total-amt">
                                        <span className="price a-bold c-point13"><em>{formatNumber(refundData.cancelPenaltyAmount)}</em>원</span>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="pm-info-header between-box fc">
                                    <div className="pm-info-tt">환불금액</div>
                                    <div className="big-total-amt">
                                        <span className="price"><em>{formatNumber(refundData.cancelPaymentAmount + refundData.cancelBenefitAmount)}</em>원</span>
                                    </div>
                                </div>
                                <div className="price-wrap">
                                    <div className="between-box fc inner-price-li a-mb15">
                                        <div className="price_tt">혜택/할인 취소 금액</div>
                                        <span className="price"><em>{formatNumber(refundData.cancelBenefitAmount)}</em>원</span>
                                    </div>
                                    <div className="price-wrap bnf-price-list">
                                        <VoucherSpan voucherList={refundData.voucherList}/>

                                        {refundData.ticketList && refundData.ticketList.length!==0 &&
                                            <div className="between-box fc inner-price-li">
                                            <p className="price_tt">{"PRIVIA 이용권 (총"+refundData.ticketList.length+"장)"}</p>
                                            <span className="price"><em>-{formatNumber(refundData.ticketList.at(0).useAmount)}</em>원</span>
                                        </div>
                                        }
                                        {refundData.couponList && refundData.couponList.length!==0 &&
                                            <div className="between-box fc inner-price-li">
                                            <p className="price_tt">{"PRIVIA 쿠폰 (총"+refundData.couponList.length+"장)"}</p>
                                            <span className="price"><em>-{formatNumber(refundData.couponList.map(c=>c.useAmount).reduce((prev, current) => prev + current, 0))}</em>원</span>
                                        </div>
                                        }
                                        {refundData.isHccSaleUse &&
                                            <div className="between-box fc inner-price-li">
                                            <p className="price_tt">현대카드 3% 할인</p>
                                            <span className="price"><em>-{formatNumber(refundData.hccSale)}</em>원</span>
                                        </div>
                                        }
                                        {refundData.isHccFamilyUse &&
                                            <div className="between-box fc inner-price-li">
                                            <p className="price_tt">현대카드 패밀리 2% 할인</p>
                                            <span className="price"><em>-{formatNumber(refundData.hccFamilySale)}</em>원</span>
                                        </div>
                                        }
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </section>
                {/*// 환불정보 */}
                {/* 최종 결제정보 */}
                <section className="book-detail-area">
                    <div className="pkg-tit-page-cont bb-line">
                        <h3 className="title">최종 결제정보</h3>
                    </div>
                    <div className="book-article border-bottom">
                        <table summary="최종 결제정보" className="list-resv-tbl paytype-tbl">
                            <caption>구분, 성인 1, 아동 1, 유아 1 항목이 있는 최종 결제정보 테이블</caption>
                            <colgroup>
                                <col width="200px" />
                                <col width="*" />
                                <col width="*" />
                                <col width="*" />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>구분</th>
                                    <th>성인 {adult.quantity}</th>
                                    <th>아동 {children.quantity}</th>
                                    <th>유아 {infant.quantity}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="a-center">상품 금액</td>
                                    <td className="a-center">{formatNumber((adult.priceAmount + adult.fuelSurchargeAmount + adult.taxAmount) * adult.quantity)}원</td>
                                    <td className="a-center">{formatNumber((children.priceAmount + children.fuelSurchargeAmount + children.taxAmount) * children.quantity)}원</td>
                                    <td className="a-center">{formatNumber((infant.priceAmount + infant.fuelSurchargeAmount + infant.taxAmount) * infant.quantity)}원</td>
                                </tr>
                            </tbody>
                        </table>
                        <ul className="payment-info-ul border-top a-mt30">
                            <li>
                                <i className="pay-icons-bl minus" />
                                <div className="pm-info-header between-box fc">
                                    <div className="pm-info-tt">상품 금액</div>
                                    <div className="sub-total-amt">
                                        <span className="price a-bold"><em>{formatNumber( paymentData && (paymentData.totalAmount))}</em>원</span>
                                    </div>
                                </div>
                                {paymentData && paymentData.etcAmountList && paymentData.etcAmountList.length > 0 && paymentData.etcAmountList[0].amount > 0 && (
                                <div className="price-wrap bnf-price-list">
                                    <div className="between-box fc inner-price-li">
                                        <div className="price_tt">추가 금액
                                            <div className="help-tooltip pkg-st small">
                                                <span className="over">?</span>
                                                <div className="cont overcharge" style={{width: '350px'}}>
                                                    <ul className="plus-charge-desc">
                                                        {paymentData.etcAmountList.map((item, index) => (
                                                            <li className="between-box ft" key={`etcAmount${index}`}>
                                                            <span>{item.etcAmountTitle}</span>
                                                            <span className="wfix a-right">{formatNumber(item.amount)}원</span>
                                                        </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <span className="price"><em>{formatNumber(paymentData.addAmount)}</em>원</span>
                                    </div>
                                </div>
                                )}
                            </li>
                            <li>
                                <i className="pay-icons-bl"></i>
                                <div className="pm-info-header between-box fc">
                                    <div className="pm-info-tt">혜택/할인 금액</div>
                                    <div className="sub-total-amt">
                                        <span className="price a-bold"><em>{formatNumber(paymentData.totalBenefitsAmount)}</em>원</span>
                                    </div>
                                </div>
                                <div className="price-wrap bnf-price-list">
                                    {paymentData.voucherList && paymentData.voucherList.length > 0 &&
                                        <div className="between-box fc inner-price-li">
                                        <p className="price_tt">{paymentData.voucherList.at(0).hccTicketUseDivision === "G"
                                            ? "the Green Voucher (총" + paymentData.voucherList.length + "장)"
                                            : "the Red Voucher (총" + paymentData.voucherList.length + "장)"}</p>
                                        <span className="price"><em>-{formatNumber(paymentData.voucherList.map(c => c.hccTicketAmount).reduce((prev, current) => prev + current, 0))}</em>원</span>
                                    </div>
                                    }
                                    {paymentData.ticketList && paymentData.ticketList.length!==0 &&
                                        <div className="between-box fc inner-price-li">
                                        <p className="price_tt">{"PRIVIA 이용권 (총"+paymentData.ticketList.length+"장)"}</p>
                                        <span className="price"><em>-{formatNumber(paymentData.ticketList.at(0).useAmount)}</em>원</span>
                                    </div>
                                    }
                                    {paymentData.couponList && paymentData.couponList.length!==0 &&
                                        <div className="between-box fc inner-price-li">
                                        <p className="price_tt">{"PRIVIA 쿠폰 (총"+paymentData.couponList.length+"장)"}</p>
                                        <span className="price"><em>-{formatNumber(paymentData.couponList.map(c=>c.useAmount).reduce((prev, current) => prev + current, 0))}</em>원</span>
                                    </div>
                                    }
                                    {paymentData.isHccSaleUse &&
                                        <div className="between-box fc inner-price-li">
                                        <p className="price_tt">현대카드 3% 할인</p>
                                        <span className="price"><em>-{formatNumber(paymentData.hccSale)}</em>원</span>
                                    </div>
                                    }
                                    {paymentData.isHccFamilyUse &&
                                        <div className="between-box fc inner-price-li">
                                        <p className="price_tt">현대카드 패밀리 2% 할인</p>
                                        <span className="price"><em>-{formatNumber(paymentData.hccFamilySale)}</em>원</span>
                                    </div>
                                    }
                                </div>
                            </li>
                            <li>
                                <div className="pm-info-header between-box fc">
                                    <div className="pm-info-tt">최종 결제금액</div>
                                    <div className="big-total-amt">
                                        <span className="price"><em>{paymentData && formatNumber(paymentData.actualPaymentAmount)}</em>원</span>
                                    </div>
                                </div>
                                <div className="price-wrap">
                                    {paymentData && paymentData.usePoint > 0 &&
                                    <div className="between-box fc inner-price-li point-price">
                                        <div className="price_tt">M포인트 <span className="c-point1">10%</span> 사용</div>
                                        <span className="price c-point1"><em>{paymentData && formatNumber(-1 * Math.floor(paymentData.usePoint))}</em>원</span>
                                        <p className="desc fs14">※ M포인트 차감된 금액으로 청구됩니다.</p>
                                    </div>
                                    }
                                    {paymentData && (paymentData.outstandingAmount > 0 || paymentData.overPaidAmount > 0) &&
                                        <div className={paymentData.usePoint > 0 ? 'case-plus-price-wrap' : ''}>
                                            <div className="between-box fc inner-price-li point-price">
                                                <div className="price_tt">{paymentData.outstandingAmount > 0 ? "남은 결제금액" :
                                                    paymentData.overPaidAmount > 0 ? "과입금 금액" : ""}</div>
                                                <span className="price">-{
                                                    paymentData.outstandingAmount > 0
                                                        ? formatNumber(paymentData.outstandingAmount) :
                                                        paymentData.overPaidAmount > 0
                                                            ? formatNumber(paymentData.overPaidAmount) : ""
                                                }원</span>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </li>
                        </ul>
                    </div>
                </section>
                {/*// 최종 결제정보 */}
            </>
            }
        </React.Fragment>
    );
};

export default PaymentPartialInfo;