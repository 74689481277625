import Modal from "@mui/material/Modal";
import Select from "react-select";
import React from "react";
import styles from "../../../../../css/Popup.module.css";
import stylesCommon from "../../../../../css/PopupCommon.module.css";


export default function SelectCreditCard({
                                             creditCardCtx
                                         }) {

    const {creditCardOptionList, selectedCreditCardOption} = creditCardCtx;

    function onChangeCreditCard(opt) {
        creditCardCtx.onChangeCreditCard && creditCardCtx.onChangeCreditCard(opt);
    }

    return (
        <section className="book-detail-area">
            <div className="pkg-tit-page-cont bb-line between-box fc">
                <h3 className="title">결제정보</h3>
                <div className="btn-wrap">
                    <TermsModal buttonName={"공인인증서안내"}>
                        <Certification1PopUp/>
                    </TermsModal>

                    <TermsModal buttonName={"안전결제안내"}>
                        <Certification2PopUp/>
                    </TermsModal>

                    <TermsModal buttonName={"안심클릭안내"}>
                        <Certification3PopUp/>
                    </TermsModal>
                </div>
            </div>

            <div className="book-article">
                <ul className="f-field-box">
                    <li className="flex-2n wfull">
                        <div className="tit-input">카드종류</div>
                        <div className="pay-card-select">
                            <Select options={creditCardOptionList}
                                    value={selectedCreditCardOption}
                                    onChange={onChangeCreditCard}
                                    styles={{
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            width: "352px",
                                            borderRadius: 3,
                                            boxShadow: "none",
                                            fontSize: 14,
                                            fontWeight: 400
                                        })
                                    }}
                                    isSearchable={false}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                            />
                        </div>
                    </li>
                </ul>
            </div>
        </section>

    )
}

function TermsModal({buttonName, children}) {
    const [on, setOn] = React.useState(false);

    function onClose() {
        setOn(false);
    }

    const childrenWithProps = React.Children.map(children, child => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child, {
                ...child.props,
                onClose: () => {
                    if (child.props.onClose) {
                        child.props.onClose()
                        return
                    }
                    onClose()
                },
            });
        }
        return child;
    });

    return (
        <div className="btn-base btn-up-lpu">
            <button className="ui-link" onClick={() => setOn(true)}>{buttonName}</button>
            <Modal
                open={on}
                onClose={onClose}>
                <>
                    {childrenWithProps}
                </>
            </Modal>
        </div>
    )
}


const Certification1PopUp = ({onClose}) => {

    return (
        <div className="modal show popup-wrap" role="dialog" style={{
            top: '50%',
            left: '50%',
            width: '820px',
            height: '720px',
            display: 'block',
            backgroundColor: '#ffffff',
            zIndex: '1000',
            overflow: 'overlay'
        }}>
            {/*<link type="text/css" rel="stylesheet" href="//static.priviatravel.com/css/front/mtravel/base.css" />*/}
            {/*<link type="text/css" rel="stylesheet" href="//static.priviatravel.com/css/front/mtravel/common.css" />*/}
            <div className={`${styles["header-secV2"]} ${styles["header-pop-st"]}`} style={{width: '100%'}}>
                <div className={`${styles["in-header-secV2"]}`}>
                    <h2 className={`${styles["ihg-title"]}`}>공인인증안내</h2>
                    <div className={`${styles["ly-right"]}`}>
                        <a href={() => false} className={`${styles["b-close"]}`} onClick={onClose}><span
                            className={`${styles["a11y"]}`}>페이지 닫기</span></a>
                    </div>
                </div>
            </div>
            <div className={`${styles["content-sec"]}`}>
                <section className={`${styles["w-content-sec"]}`}>
                    <div className={`${styles["guide-pop-sec"]}`} style={{padding: '20px', textAlign: 'left'}}>
                        <p className={`${styles["c-txt-st3"]}`}>2005년 11월 1일 부터 공인인증서 사용이 의무화 되었습니다.</p>
                        <p className={` ${styles["c-txt-st3"]} ${styles[" a-mb20"]} `}>금감원의 전자상거래 안정성 강화정책에 따라 30만원 이상의
                            모든 신용카드 결제에 공인인증 사용이 의무화됩니다.(단, 30만원 이하 결제시에는 공인인증서 없어도 결제가능)</p>
                        <table summary="공인인증안내" className={`${styles["c-table-st3"]}`}>
                            <colgroup>
                                <col width="33.333%"/>
                                <col width="*"/>
                                <col width="33.333%"/>
                            </colgroup>
                            <thead>
                            <tr>
                                <th scope="col">카드</th>
                                <th scope="col">30만원 미만</th>
                                <th scope="col">30만원 이상</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>BC, 국민, 우리</td>
                                <td>안전결제</td>
                                <td>안전결제+공인인증</td>
                            </tr>
                            <tr>
                                <td>그외 모든카드</td>
                                <td>안심클릭</td>
                                <td>공인인증</td>
                            </tr>
                            </tbody>
                        </table>
                        <p className={`${styles["c-txt-st2"]} ${styles["light"]} ${styles["a-mt10"]} ${styles["a-mb30"]}`}>그외
                            모든카드: LG, 삼성, 외환, 신한, 조흥, 롯데, 하나, 한미, 전북, 수협, 제주, 광주, 은행카드 등</p>
                        <h3 className={`${styles["c-title-st1"]}`}>공인인증발급</h3>
                        <p className={`${styles["c-txt-st3"]} ${styles["a-mb20"]}`}>공인인증서는 인터넷 뱅킹 또는 카드결제 시에 본인임을 확인하는
                            보안장치로, 다음과 같이 발급받으실 수 있습니다.</p>
                        <ul className={`${styles["step-list"]}`}>
                            <li>거래은행 방문</li>
                            <li>인터넷 뱅킹 신청</li>
                            <li>거래은행 홈페이지 접속</li>
                            <li>공인인증서 다운로드 및 설치</li>
                        </ul>
                        {/*c-txt-st2 light a-mt10*/}
                        <p className={`${styles["c-txt-st2"]} ${styles["light"]} ${styles["a-mt10"]}`}>*신용카드 결제 시 공인인증서는
                            범용공인인증서(유료발급) 또는 신용 카드용</p>
                    </div>
                </section>
            </div>
        </div>
    );
};

const Certification2PopUp = ({onClose}) => {
    return (
        <div className="modal show popup-wrap" role="dialog" style={{
            top: '50%',
            left: '50%',
            width: '820px',
            height: '720px',
            display: 'block',
            backgroundColor: '#ffffff',
            zIndex: '1000',
            overflow: 'overlay'
        }}>
            <div className={`${styles["header-secV2"]} ${styles["header-pop-st"]}`} style={{width: '100%'}}>
                <div className={`${styles["in-header-secV2"]}`}>
                    <h2 className={`${styles["ihg-title"]}`}>안전결제안내</h2>
                    <div className={`${styles["ly-right"]}`}>
                        <a href={() => false} className={`${styles["b-close"]}`} onClick={onClose}><span
                            className={`${styles["a11y"]}`}>페이지 닫기</span></a>
                    </div>
                </div>
            </div>
            <div className={`${styles["content-sec"]}`}>
                <section className={`${styles["w-content-sec"]}`}>
                    <div className={`${styles["guide-pop-sec"]}`} style={{padding: '20px', textAlign: 'left'}}>
                        <h3 className={`${stylesCommon["c-title-st1"]}`}>안전결제(ISP)란?</h3>
                        <p className={`${stylesCommon["c-txt-st3"]} ${stylesCommon["a-mb20"]}`}>쇼핑몰 이용 시 고객님의 카드번호/비밀번호
                            등을 입력함으로써 발생될 수 있는 개인정보 유출 및 카드도용 등의 문제점을 줄이는 지불수단으로, 인터넷 안전 결제(ISP)비밀번호만으로 안전한 전자상거래를 할 수
                            있는 서비스입니다.</p>
                        <div className={`${stylesCommon["c-box-st7"]}`}>
                            <ul className={`${stylesCommon["c-box-cont"]}`}>
                                <li>BC 국민카드·우리카드 사는 모든 전자상거래에 인터넷 안전결제를 의무화시켰습니다.</li>
                                <li><span
                                    className={`${stylesCommon["c-point3"]}`}>30만원 이상 결제 시는 공인인증서가 필요합니다.</span><br/>공인인증서가
                                    없으신 고객님은 공인인증서를 발급 받아주시기 바랍니다.
                                </li>
                            </ul>
                        </div>
                        <ul className={`${stylesCommon["explain-list"]} ${stylesCommon["a-mt20"]}`}>
                            <li>
                                <p className={`${stylesCommon["tit"]}`}>1. 인터넷 안전결제(ISP), 이런 점이 좋습니다.</p>
                                <ul className={`${stylesCommon["blist-st1"]} ${stylesCommon["sc"]}`}>
                                    <li>신용카드번호, 비밀번호 등 개인정보 유출 및 카드 도용방지</li>
                                    <li>인터넷 안전결제(ISP) 인증번호만으로 간단하고 편리한 신용결제</li>
                                    <li>인터넷 안전결제(ISP) 및 공인인증 확인으로 2중 보호가 되는 안전한 거래</li>
                                </ul>
                            </li>
                            <li>
                                <p className={`${stylesCommon["tit"]}`}>2. 인터넷 안전결제(ISP), 이렇게 하세요!</p>
                                <ul className={`${stylesCommon["blist-st1"]} ${stylesCommon["sc"]}`}>
                                    <li>BC카드/국민카드/우리카드 고객님을 위한 인터넷 안전결제(ISP)</li>
                                </ul>
                                <ul className={`${stylesCommon["step-list"]} ${stylesCommon["a-mt15"]}`}>
                                    <li>주문 결제 시 결제수단 선택</li>
                                    <li>ISP 인증, 비밀번호 신청 변경, 비밀번호 입력 결제</li>
                                    <li>확인하기</li>
                                    <li>주문완료</li>
                                </ul>
                                <p className={`${stylesCommon["c-txt-st2"]} ${stylesCommon["light"]} ${stylesCommon["a-mt10"]} ${stylesCommon["a-right"]}`}>*30만원
                                    이상 결제 시 공인인증(공인인증 비밀번호 입력)</p>
                            </li>
                            <li>
                                <p className={`${stylesCommon["tit"]}`}>3. 인터넷 안전결제(ISP), 여기서 등록하세요!</p>
                                <p className={`${stylesCommon["c-txt-st6"]}`}>BC카드/국민카드/우리카드 홈페이지에서 인터넷 안전결제(ISP) 서비스
                                    등록(웹 사이트를 통해 신청과 동시에 바로 사용 가능합니다.)</p>
                            </li>
                        </ul>
                    </div>
                </section>
            </div>
        </div>
    );
};

const Certification3PopUp = ({onClose}) => {

    return (
        <div className="modal show popup-wrap" role="dialog" style={{
            top: '50%',
            left: '50%',
            width: '820px',
            height: '720px',
            display: 'block',
            backgroundColor: '#ffffff',
            zIndex: '1000',
            overflow: 'overlay'
        }}>
            <div className={`${styles["header-secV2"]} ${styles["header-pop-st"]}`} style={{width: '100%'}}>
                <div className={`${styles["in-header-secV2"]}`}>
                    <h2 className={`${styles["ihg-title"]}`}>안심클릭안내</h2>
                    <div className={`${styles["ly-right"]}`}>
                        <a href={() => false} className={`${styles["b-close"]}`} onClick={onClose}><span
                            className="a11y">페이지 닫기</span></a>
                    </div>
                </div>
            </div>
            <div className={`${styles["content-sec"]}`}>
                <section className={`${styles["w-content-sec"]}`}>
                    <div className={`${styles["guide-pop-sec"]}`} style={{padding: '20px', textAlign: 'left'}}>
                        <h3 className={`${stylesCommon["c-title-st1"]}`}>안심클릭 서비스란?</h3>
                        <p className={`${stylesCommon["c-txt-st3"]} ${stylesCommon["a-mb20"]}`}>안전하고 편리한 온라인 전자상거래를 돕기
                            위해 개발된 인증 서비스로 온라인 쇼핑 시 고객님의 카드번호, 유효기간, 주민번호, 비밀번호를 입력하지 않고 안심클릭서비스 암호를 통해 거래하는 새로운 개념의
                            서비스입니다.</p>
                        <div className={`${stylesCommon["c-box-st7"]}`}>
                            <ul className={`${stylesCommon["c-box-cont"]}`}>
                                <li>삼성·LG·외환·신한·현대·롯데·하나·한미·씨티·신세계·전북·수협·조흥·제주카드가 시행하고 있습니다.</li>
                                <li><span
                                    className={`${stylesCommon["c-point3"]}`}>30만원 이상 결제 시는 공인인증서가 필요합니다.</span><br/>공인인증서가
                                    없으신 고객님은 공인인증서를 발급 받아주시기 바랍니다.
                                </li>
                            </ul>
                        </div>
                        <ul className={`${stylesCommon["explain-list"]} ${stylesCommon["a-mt20"]}`}>
                            <li>
                                <p className={`${stylesCommon["tit"]}`}>1. 안심클릭, 이런 점이 좋습니다.</p>
                                <ul className={`${stylesCommon["blist-st1"]} ${stylesCommon["sc"]}`}>
                                    <li>신용카드번호, 비밀번호 등 개인정보 유출 및 카드 도용방지</li>
                                    <li>인터넷 안심클릭 암호만으로 간단하고 편리한 신용결제 인터넷</li>
                                    <li>안심클릭 및 공인인증 확인으로 2중 보호가 되는 안전한 거래</li>
                                </ul>
                            </li>
                            <li>
                                <p className={`${stylesCommon["tit"]}`}>2. 안심클릭, 이렇게 하세요!</p>
                                <ul className={`${stylesCommon["blist-st1"]} ${stylesCommon["sc"]}`}>
                                    <li>삼성/LG/외환/신한/현대/롯데/하나/한미/씨티/신세계/전북/수협/조흥/제주카드 등</li>
                                    <li>고객님을 위한 안심클릭</li>
                                </ul>
                                <ul className={`${stylesCommon["step-list"]} ${stylesCommon["a-mt15"]}`}>
                                    <li>주문 결제 시 결제수단 선택</li>
                                    <li>안심클릭 선택, 비밀번호 신청 변경, 비밀번호 입력·결제</li>
                                    <li>확인하기</li>
                                    <li>주문완료</li>
                                </ul>
                                <p className={`${stylesCommon["c-txt-st2"]} ${stylesCommon["light"]} ${stylesCommon["a-mt10"]} ${stylesCommon["a-right"]}`}>*30만원
                                    이상 결제 시 공인인증(공인인증 비밀번호 입력)</p>
                            </li>
                            <li>
                                <p className={`${stylesCommon["tit"]}`}>3. 안심클릭, 여기에서 등록하세요!</p>
                                <p className={`${stylesCommon["c-txt-st6"]}`}>삼성/LG/외환/신한/현대/롯데/하나/한미/씨티/신세계/전북/수협/조흥/제주카드
                                    안심클릭 서비스 등록(웹 사이트를 통해 신청과 동시에 바로 사용 가능합니다.)</p>
                            </li>
                        </ul>
                    </div>
                </section>
            </div>
        </div>
    );
};