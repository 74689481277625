import React, {useState} from "react";
import styles from "../../../../../../reservation/page/Reservation.module.css";
import {
    SaleProductAmountPriceType,
    SaleProductAmountTarget
} from "../../../../../domain/model/product/SaleProductEntity";
import {formatNumber} from "../../../../../../utils/formatNumber";
import {DiscountType, PaymentType} from "../../../../../domain/service/payment/PaymentEnum";
import {PaymentMethod} from "../../../../state/reservation/PaymentPriceState";
import PaymentProcessPopUp from "./PaymentProcessPopUp";
import InicisComponent from "../../payment/InicisComponent";
import moment from "moment";
import InicisLoadingBox from "../../payment/InicisLoadingBox";


export default function ReservationPersonQuantityPanel({
                                                           paymentCtx,
                                                           saleProductCtx,
                                                           reservationCtx,
                                                           revEmail,
                                                       }) {
    const [timestamp, setTimestamp] = useState(0);
    async function onClickPurchase() {
        // 가계약 생성..
        return await reservationCtx.savePreReservation()
    }


    return (
        <div className="ui-floatbox-wrap pkg-booking o-acdi-one" style={{zIndex: 999}}>
                <div className="floating-inner">
                    <QuantityPanel paymentCtx={paymentCtx}
                                   saleProductCtx={saleProductCtx}/>

                    <PriceDetail paymentCtx={paymentCtx}/>
                    <CommitButton
                        reservationCtx={reservationCtx}
                        paymentCtx={paymentCtx}
                        onSubmit={onClickPurchase}
                        revEmail={revEmail}
                    />
                </div>
            </div>
    )
}


function QuantityItemReadOnly({amount}) {
    const priceMap = amount.getPriceMap()
    const perPrice = priceMap[SaleProductAmountPriceType.PRICE]
    const fuelSurcharge = priceMap[SaleProductAmountPriceType.FUEL_SURCHARGE]
    const tax = priceMap[SaleProductAmountPriceType.TAX_DUTY]


    function getView(count) {
        if (amount.isTarget(SaleProductAmountTarget.ADULT)) {
            return "성인 " + count + "인"
        } else if (amount.isTarget(SaleProductAmountTarget.CHILD)) {
            return "아동 " + count + "인"
        } else if (amount.isTarget(SaleProductAmountTarget.INFANT)) {
            return "유아 " + count + "인"
        }
    }

    return (
        <li className="between-box fc">
            <div className="headcnt-cell">
                <div className="headcnt-type">
                    {getView(amount.getCount())}
                </div>
            </div>
            <div className="headcnt-btn">
                <span className="price"><em>{formatNumber(perPrice + fuelSurcharge + tax)}</em>원</span>
            </div>
            {
                !amount.isTarget(SaleProductAmountTarget.INFANT) &&
                <p className="desc sm wfull">
                    유류할증료 {formatNumber(fuelSurcharge)}원 <br/>
                    제세공과금 {formatNumber(tax)}원
                </p>
            }
        </li>
    )
}

function QuantityItem({
                          amount,
                          saleProductEntity,
                          onClickPersonQuantity
                      }) {


    function onClickQuantity(addCount) {
        onClickPersonQuantity(amount.target, addCount)
    }

    const priceMap = amount.getPriceConstMap()
    const perPrice = priceMap[SaleProductAmountPriceType.PRICE]
    const fuelSurcharge = priceMap[SaleProductAmountPriceType.FUEL_SURCHARGE]
    const tax = priceMap[SaleProductAmountPriceType.TAX_DUTY]
    const quantity = amount.count
    const saleProduct = saleProductEntity.getData()
    return (
        <li className="between-box fc">
            <div className="headcnt-cell">
                <div className="headcnt-type">{amount.getTargetViewString()}
                    {amount.isTarget(SaleProductAmountTarget.CHILD) && <span className="help-tooltip small darker">
                                    <span className="over">?</span>
                                    <span className="cont"
                                          style={{width: '175px'}}>만12세 미만<br/>{moment(saleProduct.departureDay).add('-12', 'y').format('YYYY.MM.DD')} ~ {moment(saleProduct.departureDay).add('-2', 'y').add('-1', 'd').format('YYYY.MM.DD')}</span>
                                </span>
                    }
                    {
                        amount.isTarget(SaleProductAmountTarget.INFANT) &&
                        <span className="help-tooltip small darker">
                                    <span className="over">?</span>
                                    <span className="cont"
                                          style={{width: '175px'}}>만2세 미만<br/>{moment(saleProduct.departureDay).add('-2', 'y').format('YYYY.MM.DD')} ~ {moment().format('YYYY.MM.DD')}</span>
                                </span>
                    }
                </div>
                <span className="price"><em>{formatNumber(perPrice + fuelSurcharge + tax)}</em>원</span>
            </div>
            <div className="headcnt-btn">
                <div className="count-wrap" data-ctn='{"min":1, "max":10}'>
                    <span className="count-btn decrease-btn" onClick={() => onClickQuantity(-1)}></span>
                    <input type="text" value={quantity} readOnly
                           className="input-base type00 count-num"/>
                    <span className="count-btn increase-btn" onClick={() => onClickQuantity(1)}></span>
                </div>
            </div>
            {
                !amount.isTarget(SaleProductAmountTarget.INFANT) &&
                <p className="desc sm wfull">
                    유류할증료 {formatNumber(fuelSurcharge)}원 <br/>
                    제세공과금 {formatNumber(tax)}원
                </p>
            }
        </li>
    )
}

function QuantityPanel({paymentCtx, saleProductCtx}) {
    const [open, setOpen] = useState(false);
    const {readOnlyAmount} = paymentCtx
    function toggleQuantity(e) {
        if (open) {
            e.target.classList.remove("o-ac-on");
            setOpen(false);
        } else {
            e.target.classList.add("o-ac-on");
            setOpen(true);
        }
    }

    function onClickQuantityItem(type, addCount) {
        paymentCtx &&
        paymentCtx.setUpAmountCount &&
        paymentCtx.setUpAmountCount({
            target: type,
            count: addCount
        })
    }

    if (saleProductCtx.loading) {
        return
    }

    return (
        <div className="o-acdi-one">
            <div className="floating-title">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a className="o-acdi-click between" onClick={toggleQuantity} style={{cursor: "pointer"}}>인원
                    선택</a>
            </div>
            <ul className={`pd-headcnt-info o-acdi-cont ${styles.hidden} ${open ? styles.open : ''}`}
                id="fl-1">
                {
                    paymentCtx.amountList.map((amount) => {
                        if (readOnlyAmount) {
                            return <QuantityItemReadOnly
                                key={amount.getTarget()}
                                amount={amount}/>
                        }

                        return (<QuantityItem
                                key={amount.getTarget()}
                                readOnly={readOnlyAmount}
                                saleProductEntity={saleProductCtx.saleProduct}
                                amount={amount}
                                onClickPersonQuantity={onClickQuantityItem}/>
                        )
                    })
                }
            </ul>
        </div>
    )
}

function PriceDetailItem({
                             enable,
                             title,
                             price
                         }) {
    if (!enable) {
        return null;
    }

    if (price === 0) {
        return null;
    }
    return (
        <div className="between-box fc inner-price-li">
            <div className="price_tt">{title}</div>
            <span className="price"><em>- {formatNumber(price)}</em>원</span>
        </div>
    )
}

function PriceReservation({paymentCtx}) {
    const {
        priceInfo,
        paymentPrice,
        discountValueDict
    } = paymentCtx;
    return (
        <div className="price-wrap o-acdi-one">
            <div className="between-box fc inner-price-li">
                <div className="price_tt">총 상품 금액</div>
                <div>
                    <span className="price"><em>{formatNumber(priceInfo.totalPrice)}</em>원</span>
                </div>
            </div>

            <div className="between-box fc inner-price-li">
                <div className="price_tt btn-color-bl">나중 결제금액</div>
                <span
                    className="price"><em>- {formatNumber(priceInfo.totalPrice - priceInfo.paymentPrice)}</em>원</span>
            </div>

            <MPointDetailItem mPoint={discountValueDict[DiscountType.MPoint]}/>
        </div>
    )
}

function PriceDetail({paymentCtx}) {
    const [openBenefitLayer, setOpenBenefitLayer] = useState(false);
    const {
        priceInfo: {
            totalPrice,
            totalDiscount,
            prePaidPrice,
            addAmount,
            rootTargetPrice,
        },
        discountValueDict,
        paymentType,
        paymentPrice,
    } = paymentCtx;


    function handleOpenBenefitLayer() {
        setOpenBenefitLayer(!openBenefitLayer)
    }

    if (paymentType === PaymentType.RESERVATION_PAY) {
        return <PriceReservation paymentCtx={paymentCtx}/>
    }

    return (
        <>
            {addAmount !== null && <div className="price-wrap">
                <div className="between-box fc inner-price-li">
                    <div className="price_tt">추가 금액</div>
                    <div>
                        <span className="price"><em>{formatNumber(addAmount)}</em>원</span>
                    </div>
                </div>
            </div>
            }

            <div className="price-wrap o-acdi-one">
                <div className="between-box fc inner-price-li">
                    <div className="price_tt">총 상품 금액</div>
                    <div>
                        <span className="price"><em>{formatNumber(totalPrice)}</em>원</span>
                    </div>
                </div>

                {prePaidPrice !== 0 && <div className="between-box fc inner-price-li">
                    <div className="price_tt">결제한 금액</div>
                    <div>
                        <span className="price"><em>- {formatNumber(prePaidPrice)}</em>원</span>
                    </div>
                </div>
                }

                {rootTargetPrice > 0 &&
                    <div className="between-box fc inner-price-li a-mb15">
                        <div className="price_tt btn-color-bl">
                            <a style={{cursor: "pointer"}}
                               className={openBenefitLayer ? `o-acdi-click small left o-ac-on` : `o-acdi-click small left`}
                               onClick={handleOpenBenefitLayer}>혜택/할인 금액</a>
                        </div>
                        <div>
                            <span
                                className="price"><em>- {formatNumber(totalDiscount)}</em>원</span>
                        </div>
                    </div>
                }

                <div className="detail-price-wrap" id="fl-2"
                     style={openBenefitLayer ? {display: ""} : {display: "none"}}>

                    <PriceDetailItem
                        enable={true}
                        title={"the Green Voucher"}
                        price={discountValueDict[DiscountType.GreenVoucher]}
                    />

                    <PriceDetailItem
                        enable={true}
                        title={"the Red Voucher"}
                        price={discountValueDict[DiscountType.RedVoucher]}
                    />

                    <PriceDetailItem
                        enable={true}
                        title={"PRIVIA 이용권"}
                        price={discountValueDict[DiscountType.Ticket]}
                    />

                    <PriceDetailItem
                        enable={true}
                        title={"PRIVIA 쿠폰"}
                        price={discountValueDict[DiscountType.Coupon]}
                    />

                    <PriceDetailItem
                        enable={true}
                        title={"현대카드 3% 할인"}
                        price={discountValueDict[DiscountType.HCC3Discount]}
                    />

                    <PriceDetailItem
                        enable={true}
                        title={"현대카드 패밀리 2% 할인"}
                        price={discountValueDict[DiscountType.FamilyDiscount]}
                    />
                </div>
                {
                    rootTargetPrice > 0 &&
                    <MPointDetailItem mPoint={discountValueDict[DiscountType.MPoint]}/>
                }
            </div>
        </>
    )
}


function MPointDetailItem({mPoint}) {
    return (
        <div className="between-box fc inner-price-li point-price">
            <div className="price_tt">M포인트 <span className="c-point1">10%</span> 사용</div>
            <div>
                <span className="price c-point1"><em>- {formatNumber(mPoint)}</em> P</span>
            </div>
        </div>
    )
}


function CommitButton({reservationCtx, paymentCtx, onSubmit, revEmail}) {
    const {priceInfo, paymentMethod} = paymentCtx;
    function handleClick() {
        onSubmit && onSubmit()
    }

    const {
        inicisLoading,
        setInicisLoading,
    } = reservationCtx.inicisPayData


    return (
        <>
            <div className="price-wrap">
                <div className="between-box fc inner-price-li">
                    <div className="price_tt">총 결제금액</div>
                    <div className="big-total-amt">
                        <span className="price"><em>{formatNumber(priceInfo.paymentPrice)}</em>원</span>
                    </div>
                    <p className="desc sm a-right">유류할증료&amp;제세공과금 포함</p>
                </div>
            </div>

            <div className="btn-wrap">
                {
                    paymentMethod === PaymentMethod.TWO_PART &&
                    < span className="btn-notice">총 결제금액 중 M포인트 사용 대상금액 먼저 결제 </span>
                }

                <span className="btn-base btn-st-bfull wfull btn-bg-pkg">
                  <button onClick={handleClick}><span
                      className="a-bold">{formatNumber(priceInfo.paymentPrice)} 원</span> 결제하기</button>
                </span>
            </div>

            {
                paymentMethod === PaymentMethod.TWO_PART ?
                <PaymentProcessPopUp
                    open={inicisLoading}
                    setOpen={setInicisLoading}
                    targetPrice={priceInfo.step1}
                    noneTargetPrice={priceInfo.step2}
                    step={1}
                /> : <InicisLoadingBox open={inicisLoading}/>
            }

            <InicisComponent
                isRepay={true}
                payData={reservationCtx.inicisPayData}
                releaseURL={paymentCtx.inicisReleaseURL}
                revEmail={revEmail}
            />
        </>

    )
}