//PKG981411470
import {useLocation, useParams} from "react-router-dom";
import useLoadReservation from "../../loader/useLoadReservation";
import useEnv from "../../../infra/env/useEnv";
import {useEffect, useState} from "react";
import {CardOptionDict, PaymentStep} from "../../state/reservation/PaymentPriceState";
import {findCurrentUser} from "../../../domain/repository/UserRepository";
import {checkMFirstPayment} from "../../../domain/repository/ReservationRepository";


export default function useTwoPaymentReservation() {
    const params = useParams();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [paymentStep, setPaymentStep] = useState(PaymentStep.FIRST)
    const [paymentData, setPaymentData] = useState({
        inicisCallNumber: 0,
        preSavedReservationNumber: null
    })
    const reservationCtx = useLoadReservation(params.reservationNumber)
    const env = useEnv()
    const [inicisCallNumber, setInicisCallNumber] = useState(0)
    const [openPaymentModel, setOpenPaymentModel] = useState(false);

    const {check} = useCheckReservation({reservationCtx, setOpenPaymentModel})

    let targetPrice = 0
    let totalAmount = 0
    if (reservationCtx && reservationCtx.paymentData) {
        targetPrice = reservationCtx.paymentData.actualPaymentAmount + reservationCtx.paymentData.totalBenefitsAmount
        totalAmount = reservationCtx.paymentData.totalAmount
    }
    const noneTargetPrice = totalAmount - targetPrice

    async function onPayment() {
        if (reservationCtx.loading) return {
            isValid: false,
            redirectUrl: null,
            message: '로딩중입니다. 잠시만 기다려주세요.',
        }
        const checkRes = await check()

        if (!checkRes.isValid) {
            return checkRes
        }

        const inicisDataList = reservationCtx.inicisData || []
        const inicisData = inicisDataList[inicisDataList.length - 1]
        const card = CardOptionDict[inicisData.cardCode]
        const reservationPerson = reservationCtx.paymentData.reservationPersonInformation
        const representativeProductData = reservationCtx.representativeProductData
        const newPaymentData = {
            mid: card.mid,
            mKey: card.mKey,
            envType: env.envType,
            releaseURL: env.releaseURL,
            cardCode: card.value,
            serverUrl: env.serverUrl,
            returnUrl: env.serverUrl + "/front/reservation/result",
            closeUrl: env.frontUrl + "/reservation/close",
            totalPrice: totalAmount,
            productPrice: noneTargetPrice,
            buyerName: reservationPerson.memberName,
            buyerEmail: reservationPerson.email,
            buyerTel: reservationPerson.callNumber,
            productName: representativeProductData.representProductName,
            preSavedReservationNumber: params.reservationNumber,
            inicisCallNumber: inicisCallNumber + 1,
        }
        setPaymentData(newPaymentData)
        setInicisCallNumber(inicisCallNumber + 1)
        setPaymentStep(PaymentStep.SECOND)

        return {
            isValid: true,
            redirectUrl: null,
            message: null,
            data: newPaymentData
        }
    }

    async function onCloseHandler() {
        if (reservationCtx.loading) return
        // 취소 로직 -> 이제 자동으로 스케쥴링 되어서 진행 된다.
        window.location.href = reservationCtx.getRedirectReservationUrl();
    }



    return {
        reservationCtx,
        targetPrice,
        noneTargetPrice,
        onPayment,
        reservationNumber: params.reservationNumber,
        env,
        paymentStep,
        paymentData,
        onCloseHandler,
        openPaymentModel,
        setOpenPaymentModel,
        revEmail: queryParams.get('noMbrLogin') ?? ''
    }
}


function useCheckReservation({reservationCtx, setOpenPaymentModel}) {
    const params = useParams();
    const env = useEnv();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const errMessage = queryParams.get('errMessage')

    async function checkUser() {
        const userRes = await findCurrentUser();
        if (userRes === null) {
            return false
        }
        if (!userRes.data) {
            return false
        }
        const resMemberNumber = userRes.data.memberNumber
        const reservationMemberNumber = reservationCtx.reservationData.memberNumber
        return `${resMemberNumber}` === `${reservationMemberNumber}`
    }

    async function checkOnePayment() {
        const res = await checkMFirstPayment(params.reservationNumber)
        if (!res) {
            return false
        }
        if (!res.data) {
            return false
        }
        return res.data.data;
    }

    function checkNoMbrValid() {
        const noMbrLogin = queryParams.get('noMbrLogin');
        if(!noMbrLogin){
            return false;
        }
        return decodeURIComponent(noMbrLogin) === reservationCtx?.reservationData?.paymentData?.reservationPersonInformation?.email;
    }

    async function check() {
        const noMbrLogin = queryParams.get('noMbrLogin');
        const isValidUser = await checkUser();
        const isNoMbrValid = checkNoMbrValid();

        if(noMbrLogin){
            if (!isNoMbrValid) {
                return {
                    isValid: false,
                    redirectUrl: env.loginLink,
                    message: '재로그인이 필요합니다.'
                }
            }
        }else{
            if (!isValidUser) {
                return {
                    isValid: false,
                    redirectUrl: env.loginLink,
                    message: '재로그인이 필요합니다.'
                }
            }
        }

        const isValidPayment = await checkOnePayment()
        if (!isValidPayment) {
            return {
                isValid: false,
                redirectUrl: reservationCtx.getRedirectReservationUrl(),
                message: 'M포인트 할인대상금액 결제 내역을 확인할 수 없습니다.'
            }
        }
        setOpenPaymentModel(true)
        return {
            isValid: true,
            redirectUrl: '',
            message: ''
        }
    }

    /*
    * 예약이 2회 결제가 가능한지 체크하고, 리다이렉트
    * */
    useEffect(() => {
        // 예약번호가 없으면 메인으로 이동
        if (params.reservationNumber === 'undefined') {
            window.location.href = '/'
        }
        if (reservationCtx.loading) {
            return
        }

        check().then(r => {
            if (!r.isValid) {
                alert(r.message)
                window.location.href = r.redirectUrl
            }
        }).catch((err) => {
            window.location.href = '/'
        })
    }, [reservationCtx.loading]);

    useEffect(() => {
        if (reservationCtx.loading) {
            return
        }
        if (errMessage !== null && errMessage !== '' && errMessage !== undefined) {
            alert(errMessage);
        }
    }, [reservationCtx.loading]);

    return {
        checkUser,
        checkOnePayment,
        check,
    }
}