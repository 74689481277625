import {PayDataType} from "../../../common/type/CommonType";
import type {PriceInfoType} from "../../../common/type/CommonType";

const serverUrl = process.env.REACT_APP_FRONT_URL;
// 액션 타입 정의

const SET_ERROR_MESSAGE = "RESERVATION/SET_ERROR_MESSAGE";
const SET_PAY_DATA = "RESERVATION/SET_PAY_DATA";
const SET_PRICE_INFO = "RESERVATION/SET_PRICE_INFO";
const SET_IS_TRAVELER_INFO_TRUE = "RESERVATION/SET_IS_TRAVELER_INFO_TRUE";
const SET_IS_TRAVELER_INFO_FALSE = "RESERVATION/SET_IS_TRAVELER_INFO_FALSE";
const SET_IS_RESERVATION_INFO_TRUE = "RESERVATION/SET_IS_RESERVATION_INFO_TRUE";
const SET_IS_RESERVATION_INFO_FALSE = "RESERVATION/SET_IS_RESERVATION_INFO_FALSE";
const SET_TRAVELER_INFO = "RESERVATION/SET_TRAVELER_INFO";
const SET_RESERVATION_INFO = "RESERVATION/SET_RESERVATION_INFO";
const SET_RESERVATION_VOUCHER_LIST = "RESERVATION/SET_RESERVATION_VOUCHER_LIST";
const SET_RESERVATION_SELECTED_VOUCHER_LIST = "RESERVATION/SET_RESERVATION_SELECTED_VOUCHER_LIST";
const SET_RESERVATION_TICKET_LIST = "RESERVATION/SET_RESERVATION_TICKET_LIST";
const SET_RESERVATION_SELECTED_TICKET_LIST = "RESERVATION/SET_RESERVATION_SELECTED_TICKET_LIST";
const SET_RESERVATION_COUPON_LIST = "RESERVATION/SET_RESERVATION_COUPON_LIST";
const IS_TRAVEL_CHOICE_STATE = "RESERVATION/IS_TRAVEL_CHOICE_STATE";
const SET_TRAVELER_SELECT_POPUP_DATA = "RESERVATION/SET_TRAVELER_SELECT_POPUP_DATA";
const SET_TRAVELER_CHOICE_DATA = "RESERVATION/SET_TRAVELER_CHOICE_DATA";
const SET_RESERVATION_SELECTED_COUPON_LIST = "RESERVATION/SET_RESERVATION_SELECTED_COUPON_LIST";
const SET_RESERVATION_NUMBER = "RESERVATION/SET_RESERVATION_NUMBER";
const SET_IS_TERM_CHECKED = "RESERVATION/SET_IS_TERM_CHECKED";
const SET_IS_PURCHASE_CLICKED = "RESERVATION/SET_IS_PURCHASE_CLICKED";
const SET_IS_INICIS_CLICKED = "RESERVATION/SET_IS_INI_CIS_CLICKED";
const SET_IS_INICIS_PARAM = "RESERVATION/SET_IS_INI_CIS_PARAM";
const SET_IS_TERM_CHECKED_LIST_MSG = "RESERVATION/SET_IS_TERM_CHECKED_LIST_MSG"

export const setErrorMessage = (errorMessage) => ({type: SET_ERROR_MESSAGE, errorMessage});
export const setPayData = (payData: PayDataType) => ({type: SET_PAY_DATA, payData});
export const setPriceInfo = (priceInfo: PriceInfoType) => ({type: SET_PRICE_INFO, priceInfo});
export const setIsTravelerInfoTrue = () => ({type: SET_IS_TRAVELER_INFO_TRUE});
export const setIsTravelerInfoFalse = () => ({type: SET_IS_TRAVELER_INFO_FALSE});
export const setIsReservationInfoTrue = () => ({type: SET_IS_RESERVATION_INFO_TRUE});
export const setIsReservationInfoFalse = () => ({type: SET_IS_RESERVATION_INFO_FALSE});
export const setTravelerInfo = (travelers) => ({type: SET_TRAVELER_INFO, travelers});
export const setReservationInfo = (reservation) => ({type: SET_RESERVATION_INFO, reservation});
export const setVoucherList = (voucherList) => ({type: SET_RESERVATION_VOUCHER_LIST, voucherList});
export const setSelectedVoucherList = (selectedVoucherList) => ({type: SET_RESERVATION_SELECTED_VOUCHER_LIST, selectedVoucherList});
export const setTicketList = (ticketList) => ({type: SET_RESERVATION_TICKET_LIST, ticketList});
export const setSelectedTicketList = (selectedTicketList) => ({type: SET_RESERVATION_SELECTED_TICKET_LIST, selectedTicketList});
export const setCouponList = (couponList) => ({type: SET_RESERVATION_COUPON_LIST, couponList});
export const setSelectedCouponList = (selectedCouponList) => ({type: SET_RESERVATION_SELECTED_COUPON_LIST, selectedCouponList});

export const setIsTravelChoiceState = (isTravelChoiceState, travelerId) => ({type: IS_TRAVEL_CHOICE_STATE , isTravelChoiceState, travelerId});

export const setTravelerSelectPopupData = (travelerSelectPopupData) => ({type: SET_TRAVELER_SELECT_POPUP_DATA , travelerSelectPopupData});
export const setSelectedTravelerData = (selectedTravelerData) => ({type: SET_TRAVELER_CHOICE_DATA , selectedTravelerData}); // 여행자 선택 팝업에서 선택한 데이터
export const setReservationNumber = (reservationNumber) => ({type: SET_RESERVATION_NUMBER , reservationNumber});
export const setIsTermChecked = (isTermChecked) => ({type: SET_IS_TERM_CHECKED , isTermChecked});
export const setIsPurchaseClicked = (isPurchaseClicked) => ({type: SET_IS_PURCHASE_CLICKED , isPurchaseClicked});
export const setIsIniCisClicked = (isIniCisClicked) => ({type: SET_IS_INICIS_CLICKED , isIniCisClicked});
export const setIsIniCisParam = (isIniCisParam) => ({type: SET_IS_INICIS_PARAM , isIniCisParam});
export const setIsTermCheckedListMsg = (isTermCheckdListMsg)=>({type:SET_IS_TERM_CHECKED_LIST_MSG , isTermCheckdListMsg})
// ***** 초기상태 정의
const initialState: { payData: PayDataType, priceInfo: PriceInfoType} = {
    errorMessage: null,
    payData: {
        mid: "",
        productPrice: 0,
        buyerEmail: "",
        buyerName: "",
        buyerTel: "",
        productName: "",
        returnUrl: serverUrl + "/front/reservation/result",
        closeUrl: serverUrl + "/reservation/close"
    },
    priceInfo : {
        adultPrice : 0,
        adultTax : 0,
        adultFuelSurcharge : 0,
        adultTotalPrice : 0,
        childrenPrice : 0,
        childrenTax : 0,
        childrenFuelSurcharge : 0,
        childrenTotalPrice : 0,
        infantPrice : 0,
        infantTax : 0,
        infantFuelSurcharge : 0,
        infantTotalPrice : 0,
        hccDiscountAmount : 0,
        hccFamilyDiscountAmount : 0,
        saleDepositPrice : 0,
        totalPrice : 0,
        totalProductPrice : 0,
        totalBenefitAmount : 0,
        totalPaymentAmount : 0,
        discountObjectAmount : 0,
        noneDiscountObjectAmount : 0,
        usedPoint: 0,
        isPointUsed : false,
        pointAmount : 0,
        addAmount : 0,
        isBenefitUsed : false,
    },
    isTravelerInfo : false,
    isReservationInfo : false,
    reservation : {
        isPayAll : true,
        isHccSaleUse: true,
        isHccFamilyUse: true,
        reservationNumber: "",
    },
    voucherList : null,
    selectedVoucherList : [],
    ticketList: null,
    selectedTicketList: [],
    couponList: null,
    selectedCouponList: {
        normalCoupon: null,
        useNormalCouponAmount: 0,
        plusCoupon: null,
        usePlusCouponAmount: 0
    },
    travelers : {},
    isTravelChoiceState : false,
    travelerId : null,
    travelerSelectPopupData:[],
    selectedTravelerData :{},
    reservationNumber : null,
    isTermChecked: false,
    isPurchaseClicked : false,
    isIniCisClicked: false,
    // 약관 및 이용 약관 checkdListMsg
    isTermCheckdListMsg : '국외여행 표준약관(필수)에 체크하지 않으셨습니다.'
};

//리듀서 작성
const reservationState = (state =initialState , action) =>{/**/
    switch (action.type) {
        case SET_ERROR_MESSAGE:
            return {
                ...state,
                errorMessage : action.errorMessage
            }
        case SET_PAY_DATA:
            return {
                ...state,
                payData : action.payData
            }
        case SET_PRICE_INFO:
            return {
                ...state,
                priceInfo : action.priceInfo
            }
        case SET_IS_TRAVELER_INFO_TRUE:
            return {
                ...state,
                isTravelerInfo : true
            }
        case SET_IS_TRAVELER_INFO_FALSE:
            return {
                ...state,
                isTravelerInfo : false
            }
        case SET_IS_RESERVATION_INFO_TRUE:
            return {
                ...state,
                isReservationInfo : true
            }
        case SET_IS_RESERVATION_INFO_FALSE:
            return {
                ...state,
                isReservationInfo : false
            }
        case SET_RESERVATION_INFO:
            return {
                ...state,
                reservation : action.reservation
            }
        case SET_RESERVATION_VOUCHER_LIST:
            return {
                ...state,
                voucherList : action.voucherList
            }
        case SET_RESERVATION_SELECTED_VOUCHER_LIST:
            return {
                ...state,
                selectedVoucherList : action.selectedVoucherList
            }
        case SET_RESERVATION_TICKET_LIST:
            return {
                ...state,
                ticketList : action.ticketList
            }
        case SET_RESERVATION_SELECTED_TICKET_LIST:
            return {
                ...state,
                selectedTicketList : action.selectedTicketList
            }
        case SET_RESERVATION_COUPON_LIST:
            return {
                ...state,
                couponList : action.couponList
            }
        case SET_RESERVATION_SELECTED_COUPON_LIST:
            return {
                ...state,
                selectedCouponList : action.selectedCouponList
            }
        case SET_TRAVELER_INFO:
            return {
                ...state,
                travelers : action.travelers
            }
        case IS_TRAVEL_CHOICE_STATE:
            if (action.travelerId) {
                return {
                    ...state,
                    isTravelChoiceState : action.isTravelChoiceState,
                    travelerId : action.travelerId
                }
            } else {
                return {
                    ...state,
                    isTravelChoiceState : action.isTravelChoiceState
                }
            }
        case SET_TRAVELER_SELECT_POPUP_DATA:
            return {
                ...state,
                travelerSelectPopupData : action.travelerSelectPopupData
            }
        case SET_TRAVELER_CHOICE_DATA:
            return {
                ...state,
                selectedTravelerData : action.selectedTravelerData
            }
        case SET_RESERVATION_NUMBER:
            return {
                ...state,
                reservationNumber : action.reservationNumber
            }
        case SET_IS_TERM_CHECKED:
            return {
                ...state,
                isTermChecked : action.isTermChecked
            }
        case SET_IS_PURCHASE_CLICKED:
            return {
                ...state,
                isPurchaseClicked : action.isPurchaseClicked
            }
        case SET_IS_INICIS_CLICKED:
            return {
                ...state,
                isIniCisClicked : action.isIniCisClicked
            }
        case SET_IS_INICIS_PARAM:
            return {
                ...state,
                isIniCisParam : action.isIniCisParam
            }
        case SET_IS_TERM_CHECKED_LIST_MSG:
            return {
                ...state,
                isTermCheckdListMsg : action.isTermCheckdListMsg
            }
        default:
            return state;
    }
}

export default reservationState;