import {selector} from "recoil";
import {PaymentMethod, paymentPriceStateAtom, PaymentStep} from "./PaymentPriceState";
import {getPriceDictSelector, PRICE_TYPE} from "./PriceSelectors";
import {PaymentType} from "../../../domain/service/payment/PaymentEnum";
import {getFamilySubmitData} from "./benefit/FamilySelectors";
import {getHcc3SubmitData} from "./benefit/Hcc3Selectors";
import {getMPointSubmitData, isUsingMPointSelector} from "./benefit/MPointSelector";
import {getVoucherSubmitData} from "./benefit/VoucherSelector";
import {getTicketSubmitData} from "./benefit/TicketSelector";
import {getCouponSubmitData} from "./benefit/CouponSelector";

const getPriceListSelector = selector({
    key: 'getPriceListSelector',

    get: ({get}) => {
        const paymentPrice = get(paymentPriceStateAtom);
        return Object.values(paymentPrice.amountDict).map((amount) => {
            return {
                ageDivision: amount.getTarget(),
                quantity: amount.getCount(),
                price: amount.calculateTotalPrice(),
                priceAmount: amount.getPrice(),
                fuelSurchargeAmount: amount.getFuelSurcharge(),
                taxAmount: amount.getTaxDuty(),
            }
        });
    }
});


const getNextIsPayAllSelector = selector({
    key: 'getNextIsPayAllSelector',

    get: ({get}) => {
        const paymentPrice = get(paymentPriceStateAtom);
        let nextIsPayAll = true

        if (paymentPrice.paymentType === PaymentType.RESERVATION_PAY) {
            return false
        }
        return nextIsPayAll
        // // 단건 결제면서 전액 결제면 => 다음 결제는 전액 결제
        // if (paymentPrice.paymentMethod === PaymentMethod.SINGLE) {
        //     if (paymentPrice.paymentType === PaymentType.ALL_PAY) {
        //         nextIsPayAll = true
        //     }
        // }
        // // 2회 결제면서 2회차 결제면 => 다음 결제는 전액 결제 상태
        // if (paymentPrice.paymentMethod === PaymentMethod.TWO_PART) {
        //     if (paymentPrice.paymentStep === PaymentStep.SECOND) {
        //         nextIsPayAll = true
        //     }
        // }
        // if (paymentPrice.paymentType === PaymentType.PARTIAL_PAY) {
        //     nextIsPayAll = true
        // }
        //
        // return nextIsPayAll
    }
});


export const getPaymentSubmitDataSelector = selector({
    key: 'getPaymentSubmitData',

    get: ({get}) => {
        const paymentPrice = get(paymentPriceStateAtom);
        const priceDict = get(getPriceDictSelector);
        const nextIsPayAll = get(getNextIsPayAllSelector);

        return {
            ...getFamilySubmitData(paymentPrice),
            ...getHcc3SubmitData(paymentPrice),
            ...getVoucherSubmitData(paymentPrice),
            ...getTicketSubmitData(paymentPrice),
            ...getCouponSubmitData(paymentPrice),
            ...getMPointSubmitData(paymentPrice),
            priceList: get(getPriceListSelector),
            priceAmount: priceDict[PRICE_TYPE.SUM_PRODUCT_PRICE],
            totalAmount: priceDict[PRICE_TYPE.SUM_PRODUCT_PRICE],
            isPayAll: nextIsPayAll,
            paymentType: paymentPrice.paymentType, // 예약금 결제인지, 결제금액 결제인지
            // 실제 결제 금액인듯.
            totalPaymentAmount: priceDict[PRICE_TYPE.PAYMENT_PRICE_STEP_1],
            addAmount: priceDict[PRICE_TYPE.ADD_AMOUNT] || 0,
        }
    }
});


export const getInicisPayDataSelector = selector({
    key: 'getInicisPayDataSelector',
    get: ({get}) => {
        const paymentPrice = get(paymentPriceStateAtom);
        const priceDict = get(getPriceDictSelector);
        const isUsingMPoint = get(isUsingMPointSelector)
        const envType = process.env.REACT_APP_ENV_TYPE;
        const releaseURL = process.env.REACT_APP_INICIS_URL;
        const serverUrl = process.env.REACT_APP_INICIS_SERVER_URL;
        const frontUrl = process.env.REACT_APP_FRONT_URL;
        const nextIsPayAll = get(getNextIsPayAllSelector);

        function getCardInfo(pp) {
            const creditCard = pp.creditCard;
            let mid = creditCard.mid
            let mKey = creditCard.mKey
            if (isUsingMPoint) {
                mid = creditCard.mPointMid
                mKey = creditCard.mPointMKey
            }
            return {
                cardCode: creditCard.value,
                mid,
                mKey,
            }
        }

        function getNextUrl(paymentPrice) {
            let returnUrl = serverUrl + "/front/reservation/result";
            let closeUrl = frontUrl + "/reservation/close"
            if (paymentPrice.paymentMethod === PaymentMethod.TWO_PART) {
                returnUrl = serverUrl + "/front/reservation/two-pay";
            }
            return {
                returnUrl,
                closeUrl
            }
        }


        return {
            envType,
            releaseURL,
            serverUrl,
            ...getNextUrl(paymentPrice),
            ...getCardInfo(paymentPrice),
            totalPrice: priceDict[PRICE_TYPE.SUM_PRODUCT_PRICE],
            productPrice: priceDict[PRICE_TYPE.PAYMENT_PRICE_STEP_1]
        }
    }
});