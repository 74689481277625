import React from "react";
import {goBackPage, goMainPage} from "../../common/util/RedirectLogin";
import {useSearchParams} from "react-router-dom";

const PaymentError = () => {
    const [searchParams] = useSearchParams();
    let errMessage = searchParams.get("errMessage");

    return (
        <section className="pkg-nodata-sec ly-inner">
            <div className="pkg-list-nodata">
                <i className="icon res-nodata" />
                <p className="con"> 예약이 실패되었습니다.  <br/> {errMessage} </p>
                <span className="btn-base btn-st-full btn-line-bl btn-color-bl">
                    <button onClick={goBackPage} className="btn-init reset">이전 페이지로</button>
                </span>
                <span className="btn-base btn-st-full btn-bg-bl">
                    <button onClick={goMainPage} className="btn-init reset">메인 페이지로</button>
                </span>
            </div>
        </section>
    );

};

export default PaymentError;