import {useEffect, useState} from "react";

export default function useScrollVisible(ref) {
    const [isVisible, setIsVisible] = useState(true);
    const [elementRef, setElementRef] = useState(ref);
    function handleScroll() {
        if (!elementRef) return;
        if (!elementRef.current) return;
        const rect = elementRef.current.getBoundingClientRect();
        if (rect.bottom >= 0) {
            setIsVisible(() => true);
        }
        if (rect.bottom < 0) {
            setIsVisible(() => false);
        }
    }


    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);


    useEffect(() => {
        if (!ref) return;
        if (ref.current) {
            setElementRef(ref);
        }
    }, [ref]);

    return {
        isVisible,
        elementRef
    }
}