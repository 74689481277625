import {selector} from "recoil";
import {HccOption, NullCreditCardOption, paymentPriceStateAtom} from "../PaymentPriceState";
import {getDiscountValueDict, getTargetPrice} from "../DiscountSelector";
import {DiscountType} from "../../../../domain/service/payment/PaymentEnum";
import {cloneDeep} from "lodash";


export function getHccDiscountTargetValue(paymentPrice, acc) {
    const targetPrice = getTargetPrice(paymentPrice.amountDict)
    const reservationPayCard = paymentPrice.reservationPayCard
    let ret = targetPrice - acc
    if (!reservationPayCard.isHcc) {
        ret -= reservationPayCard.paid
    }
    if (ret < 0) {
        ret = 0
    }
    return ret
}

export function getHccDiscountValue(paymentPrice, targetValue) {
    return Math.floor(targetValue * 0.03)
}

export const getHcc3OptionSelector = selector({
    key: 'getHcc3OptionSelector',
    get: ({get}) => {
        const paymentPrice= get(paymentPriceStateAtom)
        return paymentPrice.hcc3.option
    }
})

export const getHcc3DiscountSelector = selector({
    key: 'getHcc3DiscountSelector',
    get: ({get}) => {
        const paymentPrice = get(paymentPriceStateAtom)
        return paymentPrice.hcc3.discount
    }
})

export const setHcc3OptionSelector = selector({
    key: 'setHcc3OptionSelectorV2',
    get: ({get}) => {
        const paymentPrice = get(paymentPriceStateAtom)
        return paymentPrice.hcc3.option
    },
    set: ({get, set}, newOption) => {
        const paymentPrice = get(paymentPriceStateAtom)
        const discountDict = cloneDeep(paymentPrice.discountDict)
        let creditCard = {
            ...HccOption
        }
        const newHcc3 = cloneDeep(paymentPrice.hcc3)
        const family = cloneDeep(paymentPrice.family)
        // 진짜 이해 안되는 요구 사항
        // 선할인 해제되면 패밀리 할인도 해제 및 카드 선택으로 변경
        if (!newOption.checked) {
            family.option.checked = false
            delete discountDict[family.discount.step]
            creditCard = {
                ...NullCreditCardOption
            }
        }

        set(paymentPriceStateAtom, {
            ...paymentPrice,
            creditCard,
            family,
            discountDict,
            hcc3: {
                ...newHcc3,
                option: newOption
            }
        })
    }
})


export function getHcc3SubmitData(paymentPrice) {
    const hcc3 = paymentPrice.hcc3
    let usingPoint = 0
    const isUse = hcc3.option.enable && hcc3.option.checked
    if (isUse) {
        const discountValueDict = getDiscountValueDict(paymentPrice)
        usingPoint = discountValueDict[DiscountType.HCC3Discount]
    }

    return {
        hccSale: usingPoint,
        isHccSaleUse: isUse,
    }
}



/*
* 자상품의 커미션 등급에 따라 hcc3 사용 여부를 결정하는 함수
* */
export const setHcc3EnableByCommissionRankSelector = selector({
    key: 'setHcc3EnableByCommissionRankSelector',
    get: ({get}) => {
        const paymentPrice = get(paymentPriceStateAtom)
        return paymentPrice.hcc3.option.enable
    },
    set: ({get, set}, commissionRank) => {
        const paymentPrice = get(paymentPriceStateAtom)
        const newHcc3 = cloneDeep(paymentPrice.hcc3)
        const newDiscountDict = {...paymentPrice.discountDict}

        if (!newHcc3.enable) {
            return
        }

        let enable = true
        if (!commissionRank) {
            enable = false
        }
        if (commissionRank === "ARank") {
            enable = false
        }

        const newOption = {
            ...newHcc3.option,
            enable,
            checked: true,
        }

        if (enable && commissionRank !== "ARank") {
            newDiscountDict[newHcc3.discount.step] = newHcc3.discount.kind
        }

        const newDiscount = {
            ...newHcc3.discount,
            enable
        }
        set(paymentPriceStateAtom, {
            ...paymentPrice,
            discountDict: newDiscountDict,
            hcc3: {
                ...newHcc3,
                option: newOption,
                discount: newDiscount
            }
        })
    }
})

export const setInvalidHcc3Selector = selector({
    key: 'setInvalidHcc3Selector',
    get: ({get}) => {
        const paymentPrice = get(paymentPriceStateAtom)
        return paymentPrice.hcc3
    },
    set: ({get, set}, invalid) => {
        const paymentPrice = get(paymentPriceStateAtom)
        const newHcc3 = cloneDeep(paymentPrice.hcc3)
        newHcc3.enable = false
        newHcc3.option.enable = false
        newHcc3.option.checked = false
        set(paymentPriceStateAtom, {
            ...paymentPrice,
            hcc3: newHcc3
        })
    }
})

