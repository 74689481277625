export class Money {

    constructor(amount, currency = "KR") {
        this.amount = amount;
        this.currency = currency;
    }

    viewString() {
        const numberGroupSeparator = ',';
        let number = this.amount;
        if (isNaN(number)) {
            return '0';
        }
        if (!number) {
            return number;
        }
        const ret = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, numberGroupSeparator);

        if (this.currency === "KR") {
            return ret + "원";
        }
        return ret
    }
}