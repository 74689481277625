// 액션 타입 정의

import {UserInfoType} from "../../../common/type/CommonType";

const SET_STATE_POINT_TRUE = "TOP_HEADER/SET_STATE_POINT_TRUE"
const SET_STATE_POINT_FALSE = "TOP_HEADER/SET_STATE_POINT_FALSE"
const SET_MEMBER_NO = "TOP_HEADER/SET_MEMBER_NO"
const IS_LOGIN = "TOP_HEADER/IS_LOGIN"
const SET_MEMBER_NM = "TOP_HEADER/SET_MEMBER_NM"
const SET_USER_INFO = "TOP_HEADER/SET_USER_INFO"
const SET_HEADER_TYPE = "TOP_HEADER/SET_HEADER_TYPE"
const SET_HEADER_SEARCH_STATE = "TOP_HEADER/SET_HEADER_SEARCH_STATE"
const IS_SEARCH_STATE = "TOP_HEADER/IS_SEARCH_STATE"
const SET_SKYPER_TYPE_STATE = "TOP_HEADER/SET_SKYPER_TYPE_STATE"
const SET_SHOW_CLOSE_BUTTON = "TOP_HEADER/SET_SHOW_CLOSE_BUTTON"

// 액션 생성함수 정의
export const isHeaderStateTrue = () =>({type : SET_STATE_POINT_TRUE});
export const isHeaderStateFalse = () =>({type: SET_STATE_POINT_FALSE});
export const setMemberNo = (memberNo) =>({type : SET_MEMBER_NO , memberNo});
export const setMemberNm = (memberNm) =>({type : SET_MEMBER_NM , memberNm});
export const setUserInfo = (userInfo: UserInfoType) =>({type : SET_USER_INFO , userInfo});
export const setIsLogin = (isLogin) => ({type : IS_LOGIN, isLogin});
export const setHeaderType = (headerType) => ({type : SET_HEADER_TYPE, headerType});

export const setHeaderSearchState = headerSearchState =>({type:SET_HEADER_SEARCH_STATE , headerSearchState})

export const isSearchState = (isSearchState) =>({type:IS_SEARCH_STATE , isSearchState})

export const setSkyperTypeState = (isSkyperTypeState) =>({type:SET_SKYPER_TYPE_STATE , isSkyperTypeState})
export const setShowCloseButton = (isShowCloseButton) =>({type:SET_SHOW_CLOSE_BUTTON , isShowCloseButton})

// ***** 초기상태 정의
const initialState = {
    isHeaderStickyState : false,
    memberNo : null,
    memberNm : null,
    userInfo : {
        termsAgreeYn: "",
        callNumber: "",
        email: "",
        emailReceiveAgreeYn: "",
        hccAuthorizationDate: "",
        hccGrade: "",
        hccMkusmnCustomNumber: "",
        hccMyCustomNumber: "",
        hccThirdAgreeYn: "",
        individualInformation3MnpofAgreeYn: "",
        individualInformationAgreeYn: "",
        memberBirth: "",
        memberDivision: "",
        memberId: "",
        memberName: "",
        memberNumber: "",
        memberSex: "",
        memberStatus: "",
        marketingReceiveAgreeYn: "",
        modifiedDate: "",
        nativeForeignDivision: "",
        optionalInformationAgreeYn: "",
        registeredDate: "",
        smsReceiveAgreeYn: "",
        telNumber: "",
    },
    isLogin : false,
    headerType : null,
    isHeaderSearch : false,
    isSearchState:true,
    isSkyperTypeState : false,
    isShowCloseButton: false
};

//리듀서 작성
const headerState = (state =initialState , action) =>{
    switch (action.type) {
        case SET_STATE_POINT_TRUE :
            return {
                ...state,
                isHeaderStickyState: state.isHeaderStickyState = true
            }
        case SET_STATE_POINT_FALSE:
            return {
                ...state,
                isHeaderStickyState : state.isHeaderStickyState = false
            }
        case SET_MEMBER_NO:
            return {
                ...state,
                memberNo : state.memberNo = action.memberNo
            }
        case SET_MEMBER_NM:
            return {
                ...state,
                memberNm : state.memberNm = action.memberNm
            }
        case IS_LOGIN:
            return {
                ...state,
                isLogin : state.isLogin = action.isLogin
            }
        case SET_USER_INFO:
            return {
                ...state,
                userInfo : state.userInfo = action.userInfo
            }
        case SET_HEADER_TYPE:
            return {
                ...state,
                headerType : state.headerType = action.headerType
            }
        case SET_HEADER_SEARCH_STATE:
            return {
                ...state,
                isHeaderSearch:action.isHeaderSearch
            }
        case IS_SEARCH_STATE:
            return {
                isSearchState:action.isSearchState
            }
        case SET_SKYPER_TYPE_STATE:
            return {
                isSkyperTypeState:action.isSkyperTypeState
            }
        case SET_SHOW_CLOSE_BUTTON:
            return {
                ...state,
                isShowCloseButton: action.isShowCloseButton
            }
        default:
            return state;
    }
}

export default headerState;