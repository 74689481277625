import {useLocation, useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {
    queryParamPathAtom,
    setCityCodeAtom,
    setContinentCodeAtom,
    setEndDateFilterAtom,
    setNationCodeAtom,
    setRepresentProductCodeAtom,
    setSearchKeywordAtom,
    setStartDateFilterAtom,
    supplierFilterListAtom,
    upDynamicFilterCountAtom,
    upFilterCountAtom
} from "../../../state/ParentProductFilterState";
import {useRecoilState} from "recoil";
import {getToday} from "../../../../infra/time/Kmoment";


export const QueryFilterType = {
    CONTINENT: 'continentCode',
    NATION: 'nationCode',
    CITY: 'cityCode',
    START_DATE: 'startAt',
    END_DATE: 'endAt',
    REPRESENTATIVE: 'representativeCode',
    SEARCH_KEYWORD: 'searchKeyword',
    SUPPLIER_CODE: 'supplierCode'

}
export default function useProductQueryParamFilter() {
    const location = useLocation();
    const navigate = useNavigate();
    const params = new URLSearchParams(location.search + location.hash);
    const [continentCode, setContinentCode] = useRecoilState(setContinentCodeAtom);
    const [queryParamPath, setQueryParamPath] = useRecoilState(queryParamPathAtom);
    const [nationCode, setNationCode] = useRecoilState(setNationCodeAtom);
    const [cityCode, setCityCode] = useRecoilState(setCityCodeAtom);
    const [startDate, setStartDate] = useRecoilState(setStartDateFilterAtom);
    const [endDate, setEndDate] = useRecoilState(setEndDateFilterAtom);
    const [representativeCode, setRepresentativeCode] = useRecoilState(setRepresentProductCodeAtom);
    const [searchKeyword, setSearchKeyword] = useRecoilState(setSearchKeywordAtom);
    const [_, upDynamicFilterCount] = useRecoilState(upDynamicFilterCountAtom)
    const [__, upFilterCount] = useRecoilState(upFilterCountAtom)
    const pathSegments = location.pathname.split('/');
    const [supplierCode, setSupplierCode] = useRecoilState(supplierFilterListAtom);

    function makeNewParam() {
        const newParams = {
            continentCode: continentCode,
            nationCode: nationCode,
            cityCode: cityCode,
            startAt: startDate,
            endAt: endDate,
            representativeCode: representativeCode,
            searchKeyword: searchKeyword,
            supplierCode: supplierCode
        }
        return Object.keys(newParams).reduce((acc, key) => {
            if (newParams[key]) {
                acc.push(`${key}=${newParams[key]}`)
            }
            return acc;
        }, []).join("&&")
    }

    function setQueryFilter(filterName, value) {
        let nextValue = value
        if (nextValue === undefined) {
            nextValue = null
        }
        if (nextValue === 'undefined') {
            nextValue = null
        }
        if (nextValue === 'null') {
            nextValue = null
        }

        switch (filterName) {
            case QueryFilterType.CONTINENT:
                setContinentCode(nextValue);
                break;
            case QueryFilterType.NATION:
                setNationCode(nextValue);
                break;
            case QueryFilterType.CITY:
                setCityCode(nextValue);
                break;
            case QueryFilterType.START_DATE:
                setStartDate(nextValue);
                break;
            case QueryFilterType.END_DATE:
                setEndDate(nextValue);
                break;
            case QueryFilterType.REPRESENTATIVE:
                setRepresentativeCode(nextValue);
                break;
            case QueryFilterType.SEARCH_KEYWORD:
                setSearchKeyword(nextValue);
                break;
            case QueryFilterType.SUPPLIER_CODES:
                setSupplierCode(nextValue ? nextValue.split(',') : []);
                break;
        }
    }

    function supplierCodeToName(supplierName) {
        switch (supplierName) {
            case "HANA":
                return "하나투어";
            case "MODE":
                return "모두투어";
            case "VERYGOOD":
                return "참좋은여행";
            case "KAL":
                return "한진관광";
            default:
                return null;
        }
    }


    useEffect(() => {
        if (location.pathname === queryParamPath) {
            // 이거 안해놓으면 계속 호출 되어서, 초기화 됨..
            return;
        }

        setQueryParamPath(location.pathname)
        const continentCode = params.get('continentCode') || null;
        const nationCode = params.get('nationCode') || null;
        const cityCode = params.get('cityCode') || null;
        const representativeCode = pathSegments[2] || null;
        const startAt = params.get('startAt') || getToday();
        const endAt = params.get('endAt') || null;
        const searchKeyword = params.get('searchKeyword') || '';
        const supplierCode = params.get('supplierCode') || '';
        setContinentCode(continentCode)
        setNationCode(nationCode)
        setCityCode(cityCode)
        setStartDate(startAt)
        setEndDate(endAt)
        setRepresentativeCode(representativeCode)
        setSearchKeyword(searchKeyword)
        upDynamicFilterCount({isReset: true})
        upFilterCount({isReset: true})
        setSupplierCode(supplierCode ? supplierCode.split(',').map((item) => supplierCodeToName(item)) : [])
    }, [location.pathname]);


    function getParam() {
        return makeNewParam();
    }

    function refreshFilter() {
        upDynamicFilterCount()
        upFilterCount()
    }

    return {
        continentCode,
        nationCode,
        cityCode,
        startDate,
        endDate,
        representativeCode,
        searchKeyword,
        setQueryFilter,
        getParam,
        refreshFilter
    }
}