import {json, Link, useLocation, useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import axios from "axios";

import {
    isLoading,
    isSearchChildData,
    isSearchData, isSearchDate, isSessionState, isSetPriceState,
    setFilter, setFilterData,
    setResetFilter,
    setResetPriceFilter,
    setSelectedFilterList
} from "../redux/module/search/SearchReducer";
import {useDispatch, useSelector} from "react-redux";
import {formatNumber} from "../utils/formatNumber";
import SearchChildList from "./SearchChildList";
import SearchSkeletonRight from "./SearchSkeletonRight";
import {isHeaderStateTrue, setHeaderType, setSkyperTypeState} from "../redux/module/common/HeaderReducer";
import SearchBar from "../detail/component/SearchBar";
import queryString from 'query-string';
import {getAxiosHeaders} from "../common/util/Axios";
import SearchListFilter from "./SearchListFilter";
import {Swiper, SwiperSlide} from "swiper/react";
import Select from "react-select";
import commonAPI from "../common/commonAPI";
import {goBackPage, goMainPage} from "../common/util/RedirectLogin";
import Loading from "../common/Loading";
import moment from "moment/moment";

const SearchList = () => {
    const option = [
        {value:`01` , label: `인기순`},
        {value:`02` , label: `여행기간 짧은순`},
        {value:`03` , label: `여행기간 긴순`},
        {value:`04` , label: `낮은 가격순`},
        {value:`05` , label: `높은 가격순`},
    ]

    let timer = undefined;
    let sessionNullble = JSON.parse(sessionStorage.getItem('filter')) ? JSON.parse(sessionStorage.getItem('filter')).filter(f=> f.includes('calendarType')) : [];
    const searchSelector = useSelector(state => state.searchReducer);
    const [progress, setProgress] = useState(0);
    const location = useLocation();
    const params = queryString.parse(location.search);     //검색조건
    const pathParams = useParams();         //코드검색
    const [searchAddClick , setSearchAddClick] = useState([]);
    const [searLoadingState , setSearchLoadingState] = useState(false);
    const dispatch = useDispatch();
    const serverUrl = process.env.REACT_APP_SERVER_URL;
    const [options , setOptions] = useState(option[0])
    const [sessionDataCheck , setSessionDataCheck] = useState('');
    const buttonRef = useRef(null);

    const navigate = useNavigate();



    //선택한 필터 (SearchList 에서 Display)
    const selectedFilterList = useSelector(state => state.searchReducer.selectedFilterList);
    const filter = useSelector(state => state.searchReducer.filter);
    const defaultMinPrice = useSelector(state => state.searchReducer.defaultMinPrice);
    const defaultMaxPrice = useSelector(state => state.searchReducer.defaultMaxPrice);

    useEffect(()=> {
        getSearchData().then(r => console.log(r));
        setProgress(0)
        dispatch(setSkyperTypeState(true));
        dispatch(setHeaderType("quick"));
        dispatch(isHeaderStateTrue());

        window.onpageshow = function(event){
            if(event.persisted || (window.performance && window.performance.navigation.type == 2)){
                window.location.reload()
            }
        }
    },[params.startDate , params.endDate , params.search])

    useEffect(() => {
        if (searchSelector.loadingState) {
            timer = setInterval(()=> {
                setProgress((prevState) => prevState > 97 ? 98 : prevState + 1);
            },50);
        }
        return () => {
            clearInterval(timer);
        }
    }, [searchSelector.loadingState]);

    useEffect(()=> {
        if (progress === 100) {
            clearInterval(timer);
            if (searchSelector.searchData.representativeProducts &&searchSelector.searchData.representativeProducts.length < 2) {
                if (buttonRef.current && buttonRef.current)
                    buttonRef.current.click()
            }
        }
    },[progress])

    //모상품 검색 완료 시 로딩바 및 스켈레톤 제거
    useEffect(()=> {
        if (searchSelector.searchData.representativeProducts) {
            console.log("searchSelector.searchData useEffect " + searchSelector.searchData.representativeProducts && searchSelector.searchData.representativeProducts.length);
            defaultSort(searchSelector.searchData);
            setProgress(100)
            dispatch(isLoading(false));
        }
    },[searchSelector.searchData])

    useEffect(()=> {
        if (searchSelector.filterData.representativeProducts) {
            console.log('searchSelector.filterData.representativeProducts')
            if(sessionStorage.getItem('endDate') && sessionStorage.getItem('startDate')) {
                sessionStorage.setItem('filter' , JSON.stringify(selectedFilterList))
            }
            console.log("SearchList searchSelector.filterData.representativeProducts.length : " , searchSelector.filterData.representativeProducts.length);
            handleChangeSort(options)
            //getSessionDaraNullbleCheck();
            //getSessionDaraNullbleCheck();
        }
    },[searchSelector.filterData.representativeProducts])

    const getSearchData = async () => {
        dispatch(isLoading(true));
        let resData;
        if (pathParams.representativeCode) {
            //모상품 검색
            resData = await axios.get(`${serverUrl}/api/v1/product/${pathParams.representativeCode}/representativeProduct`, {
                headers: getAxiosHeaders()
            })
            .then(res => res.data)
            .catch(err => console.log(err));
        } else {
            //국가, 도시, 상품명 검색
            resData = await axios.get(`${serverUrl}/api/v1/product/representative`, {
                headers: getAxiosHeaders(),
                params: {
                    supplierCode: params.supplierCode ? params.supplierCode : "",
                    keyword: `${pathParams.representativeCode ? pathParams.representativeCode : params.search}`,
                    continentCode: "",
                    nationCode: "",
                    cityCode: "",
                    startDate: `${params.startDate ? params.startDate : ''}`,
                    endDate: `${params.endDate ? params.endDate : ''}`,
                }
            })
            .then(res => res.data)
            .catch(err => console.log(err));
        }
        if (resData !== undefined && resData !== null) {
            dispatch(isSearchData(resData));        //검색리스트
        }
    }

    const defaultSort = (resData) => {
        //기본 소팅 - 가격순 먼저 정렬 (모바일과 동일하게 맞추기 위해 추가)
        resData.representativeProducts.sort((a , b)=>{
            if (a.minExhibitionPrice < b.minExhibitionPrice) {
                return -1;
            }
            if (a.minExhibitionPrice > b.minExhibitionPrice) {
                return 1;
            }
            return 0;
        })
        //기본 소팅 - 인기순 (인기순 적용되는게 별로 없어 앞에 가격순 먼저 정렬 추가)
        resData.representativeProducts.sort((a , b)=>{
            if (a.reservationCount + a.exhibitionWeight < b.reservationCount + b.exhibitionWeight) {
                return -1;
            }
            if (a.reservationCount + a.exhibitionWeight > b.reservationCount + b.exhibitionWeight) {
                return 1;
            }
            return 0;
        })
    }

    const searchDataClickHandle = async (e , representProductCode , index) => {
        setSearchLoadingState(true)
        searchAddClick.forEach(s => s.key === index ? s.state = !s.state : s.state = false);
        let addClick = searchAddClick && searchAddClick.find(s => s.key === index);
        if (!addClick) {
            searchAddClick.push({key: index, state: true});
        }
        setSearchAddClick(searchAddClick)
        let resData = await axios(`${serverUrl}/api/v1/product/${representProductCode}/sale`,
            {
                headers: getAxiosHeaders()
            }).then(res=>res.data).catch(err=>setSearchLoadingState(false));
        if(resData !== undefined) {
            console.log(resData)
            setSearchLoadingState(false)
            dispatch(isSearchChildData(resData))
        }
    }

    const filterDataEle = () =>{
        if (searchSelector.filterData.representativeProducts && searchSelector.filterData.representativeProducts.length > 0) {
            return searchSelector.filterData.representativeProducts.map((value , index) => {
                let addClick = searchAddClick && searchAddClick.find(s => s.key === index);
                console.log('filterDataEle')
                console.log(addClick)
                const dayCount = value.dayCounts && value.dayCounts.map((dayCount, number) => {
                    return (number === 0 ? '' : '/') + dayCount + '일'
                });
              return (
                    <React.Fragment  key={`representProduct${index}`}>
                        <div className="res-pd-wrap o-acdi-one">
                            <div className="pd-info-area">
                                {
                                    value.representImageUrl !== 'http://image8.hanatour.com/product/img_no_M.gif' ?
                                        (
                                            <span className="vis">
                                                <img src={value.representImageUrl} />
                                            </span>
                                        ):
                                        (
                                            <span className={"vis"}>
                                                <span className={"no-img"}></span>
                                            </span>
                                        )
                                }
                                <div className="product between-box fb">
                                    <div className="pd-info-wrap">
                                        <div className="pd-h2 ellipsis2">
                                            {value.representProductName}
                                        </div>
                                        <div className="pd-info-detail">
                                            <span className="detail ico-pdType">{value.representProductDivisionName}</span>
                                            <span className="detail ico-calendar">여행기간 {dayCount}</span>
                                            <span className="detail ico-depart">{value.supplierName}</span>
                                            <div className="hash-wrap">{value.representProductTags && value.representProductTags.split(",").map(tag => {return "#" + tag})}</div>
                                        </div>
                                    </div>
                                    <div className="pkg-price">
                                    <span className="price">
                                        <em>{formatNumber(value.minExhibitionPrice)}</em>원~
                                    </span>
                                        <span className="btn-base btn-st-full btn-bg-bl wfull">
                                        <a style={{cursor: "pointer"}} className={addClick && addClick.state ? "o-acdi-click o-ac-on" :"o-acdi-click" } onClick={(e)=>searchDataClickHandle(e , value.representProductCode , index)}>판매상품보기</a>
                                     </span>
                                    </div>
                                </div>
                            </div>
                            {/*  하단 자식 상품 리스트  */}
                            {
                                addClick && addClick.state ?
                                    <SearchChildList key={index} name={`searchReducer`} childNm={`searchChildData`}
                                                     startDate={params.startDate} endDate={params.endDate} detailPage={false} /> : null
                            }
                        </div>
                    </React.Fragment>
                )
            })
        } else {
            return (
                <><FilterNullBle /></>
            )
        }
    }

    const filterCheckEle = ()=> {
        if (selectedFilterList && selectedFilterList.length > 0) {
            return filterDataEle()
        } else {
            return searchDataEle()
        }
    }

    const searchDataEle = () => {
        console.log("searchDataEle");
        if (searchSelector.searchData.representativeProducts) {
            return searchSelector.searchData.representativeProducts.map((value , index) => {
                let addClick = searchAddClick && searchAddClick.find(s => s.key === index);
                const dayCount = value.dayCounts && value.dayCounts.map((dayCount, number) => {
                    return (number === 0 ? '' : '/') + dayCount + '일'
                });
                return (
                    <React.Fragment  key={`representProduct${index}`}>
                        <div className="res-pd-wrap o-acdi-one">
                            <div className="pd-info-area">
                                {
                                    value.representImageUrl !== 'http://image8.hanatour.com/product/img_no_M.gif' ?
                                        (
                                            <span className="vis">
                                                <img src={value.representImageUrl} />
                                            </span>
                                        ):
                                        (
                                            <span className={"vis"}>
                                                <span className={"no-img"}></span>
                                            </span>
                                        )
                                }
                                <div className="product between-box fb">
                                    <div className="pd-info-wrap">
                                        <div className="pd-h2 ellipsis2">
                                            {value.representProductName}
                                        </div>
                                        <div className="pd-info-detail">
                                            <span className="detail ico-pdType">{value.representProductDivisionName}</span>
                                            <span className="detail ico-calendar">여행기간 {dayCount}</span>
                                            <span className="detail ico-depart">{value.supplierName}</span>
                                            <div className="hash-wrap">{value.representProductTags && value.representProductTags.split(",").map(tag => {return "#" + tag})}</div>
                                        </div>
                                    </div>
                                    <div className="pkg-price">
                                    <span className="price">
                                        <em>{formatNumber(value.minExhibitionPrice)}</em>원~
                                    </span>
                                    <span className="btn-base btn-st-full btn-bg-bl wfull">
                                        {/* pathParams.representativeCode 가 있는 경우 판매상품보기 오픈 필요 */}
                                        <a style={{cursor: "pointer"}} className={addClick && addClick.state ? "o-acdi-click o-ac-on" : "o-acdi-click"}
                                           onClick={(e)=>searchDataClickHandle(e , value.representProductCode , index)} ref={buttonRef}>판매상품보기</a>
                                     </span>
                                    </div>
                                </div>
                            </div>
                            {/*  하단 자식 상품 리스트  */}
                            {
                                addClick && addClick.state ?
                                    <SearchChildList key={index} name={`searchReducer`} childNm={`searchChildData`}
                                                     startDate={params.startDate} endDate={params.endDate} detailPage={false} /> : null
                            }
                        </div>
                    </React.Fragment>
                )
            })
        } else {
            return (
                <>
                    <FilterNullBle />
                </>
            )
        }
    }

    const deleteFilterAll = (productType) => {
        let newSelectedFilterList = [];
        selectedFilterList.findIndex((filter,index) => {
            if (filter.includes(productType)) {
            } else {
                newSelectedFilterList.push(filter);
            }
        });
        if (productType === 'airline') {
            //항공사
            Object.keys(filter.airline).map(a => filter.airline[a].isSelected = false);
        } else if (productType === 'shopping') {
            //쇼핑
            filter.condition.includeFreeTour = false;
            filter.condition.shopping.map(s => s.isSelected = false);
        } else {
            //기타
            Object.keys(filter[productType]).map(s => filter[productType][s] = false)
        }
        dispatch(setFilter(filter));
        dispatch(setSelectedFilterList(newSelectedFilterList));
    }

    const sessionDelteFilterAll = (productType) =>{
        let sessionData = sessionStorage.getItem('filter') ? JSON.parse(sessionStorage.getItem('filter')).filter(f=>!f.includes(productType)) : [];
        sessionStorage.setItem(`filter` ,JSON.stringify(sessionData))
    }

    const deleteFilter = (item) => {
        let newSelectedFilterList = [];
        selectedFilterList.findIndex((filter,index) => {
            if (filter === item) {
            } else {
                newSelectedFilterList.push(filter);
            }
        });
        if (item.includes('price')) {
            //가격
            filter.price.minPrice = defaultMinPrice;
            filter.price.maxPrice = defaultMaxPrice;
            dispatch(setResetPriceFilter(true));
        } else if (item.includes('airline')) {
            //항공사
            let selectedFilter = filter.airline.find(air => air.airline === item.split("|")[1]);
            selectedFilter.isSelected = false;
        } else if (item.includes('shopping')) {
            //쇼핑
            let type = item.split("|")[1];
            let count = Number(type.split("_")[1]);
            let selectedFilter = filter.condition.shopping.find(s => s.count === count);
            if (selectedFilter) {
                selectedFilter.isSelected = false;
            } else {
                filter.condition.includeFreeTour = false;
            }
        } else {
            //기타
            filter[item.split('|')[0]][item.split('|')[1]] = false;
        }
        deleteSessionFilterList(item)
        dispatch(setFilter(filter));
        dispatch(setSelectedFilterList(newSelectedFilterList));
        dispatch(isSetPriceState(false))


    }

    const deleteSessionFilterList = (item) =>{
        console.log(item)
        let data = JSON.parse(sessionStorage.getItem('filter'))
        data = data.filter((value , index)=> {
            return `${value}` !== `${item}`
        })
        sessionStorage.setItem('filter' , JSON.stringify(data))

        if(item.includes('calendarType')) {
            deleteCalendarType()
        }
    }

    const deleteCalendarType = () =>{
        sessionStorage.setItem('event' , '')
        sessionStorage.setItem('startDate' , '')
        sessionStorage.setItem('endDate' , '')
        navigate(`/representative?searchKeywordChoice=${params.searchKeywordChoice}&search=${params.search}&startDate=&endDate=` , {replace:true})
    }

    const filterDelete = (e , item) => {
        console.log(item)
        if (item.includes("전체")) {
            //전체 삭제
            deleteFilterAll(item.split("|")[0]);
            sessionDelteFilterAll(item.split("|")[0])
        } else if(item.includes('calendarType')){
            deleteFilter(item)
        } else {
            deleteFilter(item);
        }
    }

    const clearFilter = () => {
        // 필터 초기화
        dispatch(setResetFilter(true));
    }

    const FilterNullBle = () => {
        return(
            <>
            <div className="pkg-list-nodata">
                <i className="icon res-nodata"></i>
                <p className="con">등록된 상품이 없습니다.<br />선택한 필터를 변경해 보세요.</p>
                <span className="btn-base btn-st-full btn-bg-bl">
                    <a style={{cursor: "pointer"}} onClick={clearFilter} className="btn-init reset">필터 초기화</a>
                </span>
            </div>
        </>
        )
    }

    const handleChangeSort = (option) => {
        setOptions(option)
        let sort = {representativeProducts: {}};
        if(searchSelector.filterData && searchSelector.filterData.representativeProducts.length > 0){
            if(option.label === '인기순') {
                sort.representativeProducts = searchSelector.filterData.representativeProducts.sort((a , b)=>{
                    if (a.reservationCount + a.exhibitionWeight < b.reservationCount + b.exhibitionWeight) {
                        return -1;
                    }
                    if (a.reservationCount + a.exhibitionWeight > b.reservationCount + b.exhibitionWeight) {
                        return 1;
                    }
                    return 0;
                })
            } else if (option.label === '여행기간 짧은순') {
                sort.representativeProducts = searchSelector.filterData.representativeProducts.sort((a , b)=>{
                    if (a.dayCount < b.dayCount) {
                        return -1;
                    }
                    if (a.dayCount >b.dayCount) {
                        return 1;
                    }
                    return 0;
                })
            } else if(option.label === '여행기간 긴순') {
                sort.representativeProducts = searchSelector.filterData.representativeProducts.sort((a , b)=>{
                    if (a.dayCount > b.dayCount) {
                        return -1;
                    }
                    if (a.dayCount < b.dayCount) {
                        return 1;
                    }
                    return 0;
                })
            } else if (option.label === '낮은 가격순') {
                sort.representativeProducts = searchSelector.filterData.representativeProducts.sort((a , b)=>{
                    if (a.minExhibitionPrice < b.minExhibitionPrice) {
                        return -1;
                    }
                    if (a.minExhibitionPrice > b.minExhibitionPrice) {
                        return 1;
                    }
                    return 0;
                })
            } else if (option.label === '높은 가격순') {
                sort.representativeProducts = searchSelector.filterData.representativeProducts.sort((a , b)=>{
                    if (a.minExhibitionPrice > b.minExhibitionPrice) {
                        return -1;
                    }
                    if (a.minExhibitionPrice < b.minExhibitionPrice) {
                        return 1;
                    }
                    return 0;
                })
            }
            dispatch(setFilterData({representativeProducts: sort.representativeProducts}));
        } else if(searchSelector.searchData && searchSelector.searchData.representativeProducts.length > 0){
            if(option.label === '인기순') {
                sort.representativeProducts = searchSelector.searchData.representativeProducts.sort((a , b)=>{
                    if (a.reservationCount + a.exhibitionWeight < b.reservationCount + b.exhibitionWeight) {
                        return -1;
                    }
                    if (a.reservationCount + a.exhibitionWeight > b.reservationCount + b.exhibitionWeight) {
                        return 1;
                    }
                    return 0;
                })
            } else if (option.label === '여행기간 짧은순') {
                sort.representativeProducts = searchSelector.searchData.representativeProducts.sort((a , b)=>{
                    if (a.dayCount < b.dayCount) {
                        return -1;
                    }
                    if (a.dayCount >b.dayCount) {
                        return 1;
                    }
                    return 0;
                })
            } else if(option.label === '여행기간 긴순') {
                sort.representativeProducts = searchSelector.searchData.representativeProducts.sort((a , b)=>{
                    if (a.dayCount > b.dayCount) {
                        return -1;
                    }
                    if (a.dayCount < b.dayCount) {
                        return 1;
                    }
                    return 0;
                })
            } else if (option.label === '낮은 가격순') {
                sort.representativeProducts = searchSelector.searchData.representativeProducts.sort((a , b)=>{
                    if (a.minExhibitionPrice < b.minExhibitionPrice) {
                        return -1;
                    }
                    if (a.minExhibitionPrice > b.minExhibitionPrice) {
                        return 1;
                    }
                    return 0;
                })
            } else if (option.label === '높은 가격순') {
                sort.representativeProducts = searchSelector.searchData.representativeProducts.sort((a , b)=>{
                    if (a.minExhibitionPrice > b.minExhibitionPrice) {
                        return -1;
                    }
                    if (a.minExhibitionPrice < b.minExhibitionPrice) {
                        return 1;
                    }
                    return 0;
                })
            }
            dispatch(isSearchData(sort));
        }
    }

    let searchWord = pathParams.representativeCode ? pathParams.representativeCode : (params.search ? params.search.split('|')[1] : "");

    //여행사
    let supplierTotal = selectedFilterList.filter(f => f.split("|")[0].includes("supplier")).length;
    let isSupplierAll = {isAll: (supplierTotal === (filter.supplier ? Object.keys(filter.supplier).length : 0)), display: false};
    //상품 타입
    let productTypeTotal = selectedFilterList.filter(f => f.split("|")[0].includes("productType")).length;
    let isProductTypeAll = {isAll: (productTypeTotal === (filter.productType ? Object.keys(filter.productType).length : 0)), display: false};
    //여행기간
    let periodTotal = selectedFilterList.filter(f => f.split("|")[0].includes("period")).length;
    let isPeriodAll = {isAll: (periodTotal === (filter.period ? Object.keys(filter.period).length : 0)), display: false};
    //항공사
    let airlineTotal = selectedFilterList.filter(f => f.split("|")[0].includes("airline")).length;
    let isAirlineAll = {isAll: (airlineTotal === (filter.airline ? Object.keys(filter.airline).length : 0)), display: false};
    //출발도시
    let departureCityTotal = selectedFilterList.filter(f => f.split("|")[0].includes("departureCity")).length;
    let isDepartureCityAll = {isAll: (departureCityTotal === (filter.departureCity ? Object.keys(filter.departureCity).length : 0)), display: false};
    //출발시간
    let departureTimeTotal = selectedFilterList.filter(f => f.split("|")[0].includes("departureTime")).length;
    let isDepartureTimeAll = {isAll: (departureTimeTotal === (filter.departureTime ? Object.keys(filter.departureTime).length : 0)), display: false};
    //상품등급
    let gradeTotal = selectedFilterList.filter(f => f.split("|")[0].includes("grade")).length;
    let isGradeAll = {isAll: (gradeTotal === (filter.grade ? Object.keys(filter.grade).length : 0)), display: false};
    //호텔성급
    let hotelTotal = selectedFilterList.filter(f => f.split("|")[0].includes("hotel")).length;
    let isHotelAll = {isAll: (hotelTotal === (filter.hotel ? Object.keys(filter.hotel).length : 0)), display: false};
    //투어조건
    let conditionTotal = selectedFilterList.filter(f => f.split("|")[0].includes("condition")).length;
    let isConditionAll = {isAll: (conditionTotal === (filter.condition ? 1 + (filter.condition.shopping ? filter.condition.shopping.length : 0) : 0)), display: false};
    return (
        <>
            <SearchBar
                searchWord={params.search ? params.search && params.search.split('|').at(1) : searchSelector.searchData.representativeProducts && searchSelector.searchData.representativeProducts[0] &&
                    searchSelector.searchData.representativeProducts[0].representProductName}
                searchStartDate={params.startDate ? params.startDate : null}
                searchEndDate={params.endDate ? params.endDate : null}
            />
            {progress < 100 ?
                <>
                <div className="htp-list-sec">
                    <div className="ly-inner">
                        <SearchListFilter progress={progress} />
                        <div className="pkg-listr">
                            <div className="pkg-gage-wrap">
                                <p className="txt"><span><em>{progress}</em>%</span> 잠시만 기다려 주세요. 해외패키지 요금을 조회하고 있습니다.</p>
                                <p className="gage"><span className="active" style={{width: `${progress}%`}}/></p>
                            </div>
                            <div className="pkg-listr-header">
                                <div className="pkg-listr-noti">
                                    <strong>{params.search ? params.search && params.search.split('|').at(1) : searchSelector.searchData.representativeProducts && searchSelector.searchData.representativeProducts[0].visitCityName}</strong> 검색결과 <span>0개</span>
                                    <p className="noti-info">현대카드 <span className="c-point1">3%</span> 할인 및 최대
                                        <span className="c-prvblue">10%</span> M포인트 차감된 할인요금 입니다. 정확한 상품 금액은 예약 시 확인해주세요.
                                    </p>
                                </div>
                                <div className="res-sort-filter">
                                    <select className="select-base sel-st2" data-option-width="150" data-option-left="0" style={{width: '100'}} >
                                        <option value="1" defaultChecked={true}>인기순</option>
                                        <option value="2">여행기간 짧은순</option>
                                        <option value="2">여행기간 긴순</option>
                                        <option value="3">낮은 가격순</option>
                                        <option value="4">높은 가격순</option>
                                    </select>
                                </div>
                                <SearchSkeletonRight />
                            </div>
                        </div>
                    </div>
                </div>
                </>
                :
                <>
                {!(searchSelector.searchData.representativeProducts && searchSelector.searchData.representativeProducts.length > 0)
                    && selectedFilterList.length === 0 ?
                    <section className="pkg-nodata-sec ly-inner">
                        <div className="pkg-list-nodata">
                            <i className="icon res-nodata"/>
                            <p className="con">검색결과가 없습니다. <br/>출발지를 변경하거나, 다른 날짜를 검색해보세요.</p>
                            <span className="btn-base btn-st-full btn-line-bl btn-color-bl">
                                <a style={{cursor: "pointer"}} onClick={goBackPage} className="btn-init reset">이전 페이지로</a>
                            </span>
                            <span className="btn-base btn-st-full btn-bg-bl">
                                <a style={{cursor: "pointer"}} onClick={goMainPage} className="btn-init reset">메인 페이지로</a>
                            </span>
                        </div>
                        <div className="btm-promotion-cont ly-inner">
                            <ul className="list-item-pakage">
                                <MiddleBanner />
                            </ul>
                        </div>
                    </section>
                    :
                    <div className="htp-list-sec">
                        <div className="ly-inner">
                            <SearchListFilter progress={progress} />
                            <div className="pkg-listr">
                                <div className="pkg-res-wrap">
                                    {/* 리스트 헤더 */}
                                    <div className="pkg-listr-header">
                                        <div className="pkg-listr-noti">
                                            {/*searchDataLength*/}
                                            <strong>{params.search ? params.search && params.search.split('|').at(1) : searchSelector.searchData.representativeProducts && searchSelector.searchData.representativeProducts[0].visitCityName}</strong> 검색결과 <span>{`(${searchSelector.filterData.representativeProducts ? formatNumber(searchSelector.filterData.representativeProducts.length) : 0})`}개</span>
                                            <p className="noti-info">현대카드 최대 <span className="c-point1">3%</span> 할인 및 최대
                                                <span className="c-prvblue">10%</span> M포인트 차감된 할인요금 입니다. 정확한 상품 금액은 예약 시 확인해주세요.
                                            </p>
                                        </div>
                                        <div className="res-sort-filter" style={{zIndex:100}}>
                                            <Select options={option} value={options}
                                                    onChange={handleChangeSort}
                                                    styles={{
                                                        control: (baseStyles, state) => ({
                                                            ...baseStyles,
                                                            width: "160px",
                                                            minHeight:'40.8px',
                                                            borderRadius: 3,
                                                            boxShadow: "none",
                                                            fontSize: 14,
                                                            fontWeight: 400,
                                                        })}}
                                                    components={{
                                                        IndicatorSeparator: () => null,
                                                    }}
                                                    isSearchable={false}
                                            />
                                        </div>
                                        {selectedFilterList && selectedFilterList.length > 0 &&
                                        <Swiper
                                                className={"res-selected-badge swiper-container-initialized swiper-container-horizontal"}
                                                spaceBetween={5}
                                                slidesPerView={'auto'}
                                                scrollbar={{ draggable: true }}
                                            >
                                               <div className={"swiper-wrapper"}>
                                                   {selectedFilterList && selectedFilterList.map((item, index) => {
                                                       let key = item;
                                                       let productType = item.split("|")[0];
                                                       let title = item.split("|")[2];
                                                       if (isSupplierAll.isAll && productType.includes("supplier")) {
                                                           if (isSupplierAll.display === true) return null;
                                                           key = "supplier|여행사 전체";
                                                           title = "여행사 전체";
                                                           isSupplierAll.display = true;
                                                       } else if (isProductTypeAll.isAll && productType.includes("productType")) {
                                                           if (isProductTypeAll.display === true)    return null;
                                                           key = "productType|상품타입 전체";
                                                           title = "상품타입 전체";
                                                           isProductTypeAll.display = true;
                                                       } else if (isPeriodAll.isAll && productType.includes("period")) {
                                                           if (isPeriodAll.display === true)    return null;
                                                           key = "period|여행기간 전체";
                                                           title = "여행기간 전체";
                                                           isPeriodAll.display = true;
                                                       } else if (isAirlineAll.isAll && productType.includes("airline")) {
                                                           if (isAirlineAll.display === true)    return null;
                                                           key = "airline|항공사 전체";
                                                           title = "항공사 전체";
                                                           isAirlineAll.display = true;
                                                       } else if (isDepartureCityAll.isAll && productType.includes("departureCity")) {
                                                           if (isDepartureCityAll.display === true)    return null;
                                                           key = "departureCity|출발도시 전체";
                                                           title = "출발도시 전체";
                                                           isDepartureCityAll.display = true;
                                                       } else if (isDepartureTimeAll.isAll && productType.includes("departureTime")) {
                                                           if (isDepartureTimeAll.display === true)    return null;
                                                           key = "departureTime|출발시간 전체";
                                                           title = "출발시간 전체";
                                                           isDepartureTimeAll.display = true;
                                                       } else if (isGradeAll.isAll && productType.includes("grade")) {
                                                           if (isGradeAll.display === true)    return null;
                                                           key = "grade|상품등급 전체";
                                                           title = "상품등급 전체";
                                                           isGradeAll.display = true;
                                                       } else if (isHotelAll.isAll && productType.includes("hotel")) {
                                                           if (isHotelAll.display === true)    return null;
                                                           key = "hotel|호텔성급 전체";
                                                           title = "호텔성급 전체";
                                                           isHotelAll.display = true;
                                                       } else if (isConditionAll.isAll && productType.includes("condition")) {
                                                           if (isConditionAll.display === true)    return null;
                                                           key = "condition|투어조건 전체";
                                                           title = "투어조건 전체";
                                                           isConditionAll.display = true;
                                                       }
                                                       return (
                                                           <SwiperSlide key={key}>
                                                               <span className="selected-item">{title}</span>
                                                               <button className="del-btn" onClick={(e) => filterDelete(e , key)} aria-label="필터 삭제"></button>
                                                           </SwiperSlide>
                                                       )
                                                   })}
                                               </div>
                                            </Swiper>
                                        }
                                    </div>
                                    {/* //리스트 헤더 */}
                                    {/* skeleton loading */}
                                    {/* //skeleton loading */}
                                    {/* 상품 리스트 */}
                                    <div className="res-pd-list htp-res-list origin">{/* htp-res-list/origin 두 클래스는 퍼블단에서 아래 목록 추가를 위해 넣은 클래스임*/}
                                        {/*<Loading/>*/}
                                        {
                                            searLoadingState ?
                                                <Loading/> : null
                                        }
                                        {/* 첫 목록에만 자상품리스트 있음 */}
                                        {
                                            filterCheckEle()
                                            // searchDataEle()
                                        }
                                    </div>
                                    {/* //상품 리스트 */}
                                    {/* 하단 리스트 로딩 */}
                                    <div className="loading-list" style={{marginTop: 30, display: 'none'}}>
                                        <img src="https://static.priviatravel.com/images/front/travel/svg/tna_new_loading_icon.svg"/>
                                    </div>
                                    {/* //하단 리스트 로딩 */}
                                </div>
                                {/* //pkg-res-wrap */}
                            </div>
                        </div>
                    </div>


                }
                </>
            }
        </>
    )
}

export const MiddleBanner = () =>{
    const serverUrl = process.env.REACT_APP_SERVER_URL;

    const [bannerData , setBannerData] = useState()


    useEffect(()=>{
        async function getBannerData(){
            const res = await axios.get(serverUrl+`/api/v1/pc/main`,{ headers: {'Authorization' : commonAPI.AUTHROIZATION, 'Channel-Id': 'PRIVIA', 'Content-Type':'application/json'}})
                .then(res=>res.data)
                .catch(error=>console.log(error))
            setBannerData(res)
        }
        getBannerData()
    },[])


    //
    const midBannerEle = bannerData && bannerData.pcMidBanner.map((value , index)=>{
        if(!!value.imageUrl) {
            return (
                <li className="ga-promo list" key={index}>
                    <Link to={value.linkUrl} className="ga-promo-click">
                        {
                            value.imageUrl && value.imageUrl  !== 'http://image8.hanatour.com/product/img_no_M.gif' ?
                                (
                                    <span className="vis">
                                    <img src={value.imageUrl && value.imageUrl} />
                                </span>
                                ):
                                (
                                    <span className={"vis"}>
                                    <span className={"no-img"}></span>
                                </span>
                                )
                        }

                        <span className="info-box">
                        <span className="tit"><span>{value.tltle && value.tltle && value.title && value.title}<br /> {value.content}</span></span>
                        <span className="date">예약기간<span className="txt-colon">:</span><em>{value.rangeStartDate}</em></span>
                    </span>
                    </Link>
                </li>
            )
        } else if(!!value.ImageUrl) {
            return (
                <li className="ga-promo list" key={index}>
                    <Link to={value.linkUrl} className="ga-promo-click">
                        {
                            value.ImageUrl && value.ImageUrl  !== 'http://image8.hanatour.com/product/img_no_M.gif' ?
                                (
                                    <span className="vis">
                                    <img src={value.ImageUrl && value.ImageUrl} />
                                </span>
                                ):
                                (
                                    <span className={"vis"}>
                                    <span className={"no-img"}></span>
                                </span>
                                )
                        }

                        <span className="info-box">
                        <span className="tit"><span>{value.title && value.title}<br /> {value.content}</span></span>
                            {
                                value.rangeStartDate ? <span className="date">예약기간<span className="txt-colon">:</span><em>{value.rangeStartDate}</em></span> : null
                            }
                    </span>
                    </Link>
                </li>
            )
        }
    })

    return (
        <>
            {midBannerEle}
        </>
    )
}

export default SearchList;