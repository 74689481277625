import {selector} from "recoil";
import {PaymentMethod, paymentPriceStateAtom} from "../PaymentPriceState";
import {DiscountStep, DiscountType, PaymentType} from "../../../../domain/service/payment/PaymentEnum";
import {getDiscountValueDict, getTargetPrice} from "../DiscountSelector";
import {getNoneTarget} from "../PriceSelectors";
import {cloneDeep} from "lodash";
import {UserType} from "../../user/UserState";

export function isEnableMPoint(paymentPrice) {
    const mPoint = paymentPrice.mPoint
    //   if (!mPoint.loaded) return false
    return true
}

export const isEnableMPointSelector = selector({
    key: 'isEnableMPointSelector',
    get: ({get}) => {
        const paymentPrice = get(paymentPriceStateAtom)
        return isEnableMPoint(paymentPrice)
    }
})

/*
* mPoint 할인 대상 금액 찾는 함수
* */
export function getMPointDiscountTargetValue(paymentPrice, acc) {
    // M포인트는 할인 대상 금액에서 paymentPrice.prePaidPrice 뺴줘야한다.
    const targetVale = getTargetPrice(paymentPrice.amountDict) - paymentPrice.prePaidPrice
    if (targetVale <= 0) return 0
    if (targetVale - acc <= 0) return 0
    return targetVale - acc
}

export function getMPointDiscountValue(paymentPrice, targetValue) {
    const mPoint = paymentPrice.mPoint
    const user = paymentPrice.user
    let newTargetValue = targetValue

    if (paymentPrice.paymentType === PaymentType.RESERVATION_PAY) {
        const sumValue = getTargetPrice(paymentPrice.amountDict) + getNoneTarget(paymentPrice.amountDict)
        newTargetValue = Math.floor(sumValue * 0.01) * 10
    }
    if (!mPoint.useMPoint) return 0
    const nextMPointValue = Math.floor(newTargetValue * mPoint.percent / 1000) * 10
    if (user.type === UserType.NONE) {
        return nextMPointValue
    }

    if (nextMPointValue > mPoint.maxMPoint) {
        return mPoint.maxMPoint
    }
    return nextMPointValue
}

export function getMPointSubmitData(paymentPrice) {
    const mPoint = paymentPrice.mPoint
    const discountValueDict = getDiscountValueDict(paymentPrice)
    const ret = {
        isPointUse: isEnableMPoint(paymentPrice) && mPoint.useMPoint,
        usePoint: discountValueDict[DiscountType.MPoint],
    }
    return ret
}

export const isUsingMPointSelector = selector({
    key: 'isUsingMPointSelector',
    get: ({get}) => {
        const paymentPrice = get(paymentPriceStateAtom)
        const mPoint = paymentPrice.mPoint
        if (!isEnableMPoint(mPoint)) return false
        return mPoint.useMPoint
    }
})

/*
* m포인트 설정
* */
export const setMPointSelector = selector({
    key: 'setMPointSelector',
    get: ({get}) => {
        return get(paymentPriceStateAtom).mPoint;
    },
    set: ({set, get}, newValue) => {
        const old = get(paymentPriceStateAtom);
        set(
            paymentPriceStateAtom,
            {
                ...old,
                mPoint: {
                    ...old.mPoint,
                    ...newValue
                }
            }
        );
    }
})

export const initMPointSelector = selector(
    {
        key: 'initMPointSelector',
        get: ({get}) => {
            return get(paymentPriceStateAtom).mPoint;
        },
        set: ({get, set}) => {
            const paymentPrice = get(paymentPriceStateAtom)
            const discountDict = cloneDeep(paymentPrice.discountDict)
            const mPoint = cloneDeep(paymentPrice.mPoint)
            mPoint.useMPoint = false
            delete discountDict[DiscountStep.FOURTH_STEP]
            set(paymentPriceStateAtom, {
                ...paymentPrice,
                paymentMethod: PaymentMethod.SINGLE,
                mPoint,
                discountDict
            })
        }
    }
)


/*
* m포인트 사용 여부를 토글하는 selector
* */
export const toggleMPointSelector = selector({
    key: 'toggleMPointSelector',
    get: ({get}) => {
        return get(paymentPriceStateAtom).mPoint;
    },
    set: ({set, get}) => {
        const old = get(paymentPriceStateAtom);
        let paymentMethod = PaymentMethod.SINGLE
        const newDiscountDict = {...old.discountDict}
        const newMPoint = {
            ...old.mPoint,
            useMPoint: !old.mPoint.useMPoint
        }
        if (!newMPoint.useMPoint) {
            delete newDiscountDict[DiscountStep.FOURTH_STEP]
        }
        if (newMPoint.useMPoint) {
            newDiscountDict[DiscountStep.FOURTH_STEP] = newMPoint.discount.kind
            if (old.paymentType !== PaymentType.RESERVATION_PAY) {
                const noneTarget = getNoneTarget(old.amountDict)
                if (noneTarget !== 0) { // 할인 비대상 금액이 없으면 2회차 결제 할 필요 없다.
                    paymentMethod = PaymentMethod.TWO_PART
                }
            }
        }

        set(paymentPriceStateAtom, {
            ...old,
            paymentMethod,
            mPoint: newMPoint,
            discountDict: newDiscountDict
        })
    }
})

