import {atom} from 'recoil';
import {TravelPersonInfoModel} from "../../usecase/reservation/info/useTravelPerson";
import {SaleProductAmountTarget} from "../../../domain/model/product/SaleProductEntity";

export const travelerListLoadingState = atom({
    key: 'travelerListLoadingState', // Unique ID (with respect to other atoms/selectors)
    default: true, // default value (aka initial value)
});

export const travelerListErrorState = atom({
    key: 'travelerListErrorState', // Unique ID (with respect to other atoms/selectors)
    default: null, // default value (aka initial value)
});

export const travelerListState = atom({
    key: 'travelerState', // Unique ID (with respect to other atoms/selectors)
    default: null,
});