import {createTheme, Dialog, DialogContent} from "@mui/material";
import React from "react";


const theme = createTheme({
    components: {
        MuiDialogContent: {
            styleOverrides: {   // Name of the slot
                root: { // Name of the rule
                    overflowX: 'hidden',
                    padding: '0px',
                    borderTop: '0px',
                    borderBottom: '0px',
                },
            }
        }
    }
})
export default function BenefitDialog({
                                          open,
                                          modalTitle,
                                          children,
                                          setOpen,
                                          onClose,
                                          onApply
                                      }) {
    function handleClose(e) {
        e?.stopPropagation();
        setOpen && setOpen(false);
        onClose && onClose()
    }

    const childrenWithProps = React.Children.map(children, child => {
        if (React.isValidElement(child)) {
            // 기존의 child.props는 유지하면서 handleClose 함수를 추가

            return React.cloneElement(child, {
                ...child.props,
                onClose: () => {
                    if (child.props.onClose) {
                        child.props.onClose()
                        setOpen && setOpen(false);
                        return
                    }
                    handleClose()
                },
                onApply: () => {
                    if (child.props.onApply) {
                        child.props.onApply()
                        setOpen && setOpen(false);
                        return
                    }
                    onApply && onApply()
                    setOpen && setOpen(false);
                }
            });
        }
        return child;
    });

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            theme={theme}
        >
            <div className="popup-wrap"
                 style={{width: 770}}>
                <div className="heading">
                    <h2>{modalTitle}</h2>
                    <a style={{cursor: "pointer"}}
                       className="btn_close"
                       onClick={handleClose}>닫기</a>
                </div>
                <div className="content">
                    <div className="inner">
                        <DialogContent dividers={true}
                                       theme={theme}>
                            {childrenWithProps}
                        </DialogContent>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}