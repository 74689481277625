import React from "react";
import useEnv from "../../../../infra/env/useEnv";


export default function ReservationPersonInfo({
                                                  reservationPersonCtx,
                                                  // reservationPersonInfoModel,
                                                  // onChange
                                              }) {

    const env = useEnv()

    if (reservationPersonCtx === undefined) {
        return null;
    }
    if (!reservationPersonCtx.enable) {
        return null;
    }

    const {
        name,
        phone,
        email,
        memberId,
        nameError,
        phoneError,
        emailError,
    } = reservationPersonCtx;

    function isReadOnlyRequired() {
        const envType = env && env.envType;
        if (memberId !== null) {
            if (envType === 'DEV' || envType === 'LOCAL') {
                return false;
            }
            return true;
        }
    }

    function handleOnChange(e) {
        const {name, value} = e.target;

        reservationPersonCtx.onChange && reservationPersonCtx.onChange(name, value);
    }

    function handlePhoneChange(e) {
        e.target.value = e.target.value.replaceAll(" ", "")
            .replace(/[^0-9]/g, '')
            .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3").replace(/-{1,2}$/g, "");
        const {name, value} = e.target;
        reservationPersonCtx.onChange && reservationPersonCtx.onChange(name, value);
    }


    return (
        <section className="book-detail-area">

            <div className="pkg-tit-page-cont bb-line">
                <h3 className="title">예약자 정보</h3>
            </div>
            <div className="book-article border-bottom">
                <ul className="f-field-box">
                    <li className="flex-2n">
                        <div className="necessary tit-input">한글명</div>
                        <div className="input-area">
                            <input type="text"
                                   id="reserverKoreanName"
                                   name="name"
                                   className={`input-base type01 wfull koreanName ${nameError ? "err" : ""} `}
                                   placeholder="예) 홍길동"
                                   onChange={handleOnChange}
                                   value={name}
                                   readOnly={isReadOnlyRequired()}
                            />
                        </div>
                        {
                            nameError && <p className="err-feedback">{nameError['message']}</p>
                        }
                    </li>
                    <li className="flex-2n">
                        <div className="necessary tit-input">연락처</div>
                        <div className="input-area">
                            <input type="text"
                                   id="reserverCallNumber"
                                   name="phone"

                                   className={`input-base type01 wfull phoneNumber ${phoneError ? "err" : ''}`}
                                   placeholder="예) 010-1234-5678"
                                   defaultValue={phone}
                                   onChange={handlePhoneChange}
                            />

                        </div>
                        {
                            phoneError && <p className="err-feedback">{phoneError['message']}</p>
                        }
                    </li>
                    <li className="flex-2n">
                        <div className="necessary tit-input">이메일</div>
                        <div className="input-area">
                            <input type="text"
                                   id="reserverEmail"
                                   name="email"
                                   className={`input-base type01 wfull email ${emailError ? "err" : ''}`}
                                   value={email}
                                   placeholder="예) test@naver.com"
                                   onChange={handleOnChange}
                            />
                        </div>
                        {
                            emailError && (
                                emailError["type"] === 'REQUIRE' &&
                                <p className="err-feedback">{emailError['message']}</p>
                            )
                        }
                        {
                            emailError && (
                                emailError["type"] === 'INVALID' &&
                                <p className="err-feedback" style={{bottom: `5px`, letterSpacing: `-0.9px`}}>
                                    {emailError['message']}
                                </p>
                            )
                        }
                    </li>
                </ul>
            </div>
        </section>
    )
}