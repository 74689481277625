export function formatNumber (number: any): string {
    const numberGroupSeparator = ',';
    if (isNaN(number)) {
        return '0';
    }

    if (!number) {
        return number;
    }

    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, numberGroupSeparator);
}

//모상품 13% 할인 금액 계산
export function displayExhibitionAmount(priceAmount: number, taxDutyAmount: number, fuelSurchargeAmount: number): number {
    if (isNaN(priceAmount)) {
        return 0;
    }
    let productPrice = priceAmount - taxDutyAmount - fuelSurchargeAmount;
    let hcc3price = Math.floor(productPrice * 0.97) + taxDutyAmount + fuelSurchargeAmount;
    return hcc3price - (hcc3price * 0.1 / 10) * 10;
}
//모상품 외 13% 할인 금액 계산
export function displayAmount(priceAmount: number, taxDutyAmount: number, fuelSurchargeAmount: number): number {
    if (isNaN(priceAmount)) {
        return 0;
    }
    let hcc3price = Math.floor(priceAmount * 0.97) + taxDutyAmount + fuelSurchargeAmount;
    return hcc3price - Math.floor(hcc3price * 0.1 / 10) * 10;
}

export function displayAmountWithCommission(saleProduct): number {
    if (isNaN(saleProduct.priceAmount)) {
        return 0;
    }
    const {priceAmount, taxDutyAmount, fuelSurchargeAmount, commissionRank} = saleProduct;
    let sunPercent = 0.03;
    if (commissionRank === "ARank") {
        sunPercent = 0
    }
    let hcc3price = Math.floor(priceAmount * (1.0 - sunPercent)) + taxDutyAmount + fuelSurchargeAmount;
    return hcc3price - Math.floor(hcc3price * 0.1 / 10) * 10;
}



//3% 할인 금액 계산
export function display3PercentDiscountAmount(priceAmount: number, taxDutyAmount: number, fuelSurchargeAmount: number): number {
    if (isNaN(priceAmount)) {
        return 0;
    }
    return Math.floor(priceAmount * 0.97) + taxDutyAmount + fuelSurchargeAmount;
}
//타입별 10% 할인 금액 계산
// DT- 19436 이후 사실상 Deprecated
export function getDiscountAmount(
        priceAmount: number, taxDutyAmount: number, fuelSurchargeAmount: number, quantity: number,
        childPriceAmount: number, childTaxDutyAmount: number, childFuelSurchargeAmount: number, childQuantity: number,
        infantPriceAmount: number, infantTaxDutyAmount: number, infantFuelSurchargeAmount: number, infantQuantity: number
    ): number {
    let hcc3price = 0;

    hcc3price += (Math.floor(priceAmount * 0.97) + taxDutyAmount + fuelSurchargeAmount) * quantity;
    hcc3price += (Math.floor(childPriceAmount * 0.97) + childTaxDutyAmount + childFuelSurchargeAmount) * childQuantity;
    hcc3price += (infantPriceAmount + infantTaxDutyAmount + infantFuelSurchargeAmount) * infantQuantity;
    hcc3price = hcc3price - Math.floor(hcc3price * 0.1 / 10) * 10;
    return hcc3price;
}
//타입별 총 금액 계산
export function getTotalAmount(
        priceAmount: number, taxDutyAmount: number, fuelSurchargeAmount: number, quantity: number,
        childPriceAmount: number, childTaxDutyAmount: number, childFuelSurchargeAmount: number, childQuantity: number,
        infantPriceAmount: number, infantTaxDutyAmount: number, infantFuelSurchargeAmount: number, infantQuantity: number
    ): number {
    let totalPrice = 0;
    totalPrice += (priceAmount + taxDutyAmount + fuelSurchargeAmount) * quantity;
    totalPrice += (childPriceAmount + childTaxDutyAmount + childFuelSurchargeAmount) * childQuantity;
    totalPrice += (infantPriceAmount + infantTaxDutyAmount + infantFuelSurchargeAmount) * infantQuantity;
    return totalPrice;
}

export function nvl(str, defaultStr) {
    if (typeof str == "undefined" || str == null || str == "") {
        str = defaultStr;
    }
    return str;
}