import {useEffect, useState} from "react";
import {getReservation} from "../../domain/repository/ReservationRepository";

export default function useLoadReservation(reservationNumber) {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [reservationData, setReservationData] = useState(null)
    const [representativeProductData, setRepresentativeProductData] = useState(null)
    const [saleProductData, setSaleProductData] = useState(null)
    const [paymentData, setPaymentData] = useState(null)
    const [inicisData, setInicisData] = useState(null)
    const [inquireListData, setInquireListData] = useState(null)

    useEffect(() => {
        if (!reservationNumber) return
        if (reservationNumber === '') return
        if (reservationNumber === null) return
        if (reservationNumber === undefined) return
        if (reservationNumber === 'undefined') return
        if (reservationNumber === 'null') return

        setLoading(true)
        setError(null)
        getReservation(reservationNumber)
            .then((response) => {
                setLoading(false)
                setReservationData(response.data)
                setRepresentativeProductData(response.data.representativeProduct ? response.data.representativeProduct.representativeProducts[0] : null)
                setSaleProductData(response.data.saleProduct ? response.data.saleProduct.saleProducts[0] : null)
                setPaymentData(response.data.paymentData)
                setInicisData(response.data.inicisData)
                setInquireListData(response.data.inquireList)
            })
            .catch((e) => {
                setError(e)
                setLoading(false)
            })
    }, [reservationNumber]);

    /*
    * 예약 생성 페이지 url 구한다 (모상품,자상품 필요)
    * */
    function getCreateReservationUrl() {
        if (saleProductData === null) return null
        if (paymentData === null) return null

        const representProductCode = saleProductData.representProductCode;
        const saleProductCode = saleProductData.saleProductCode;
        const adultCount = paymentData.priceList[0].quantity;
        const childrenCount = paymentData.priceList[1].quantity;
        const infantCount = paymentData.priceList[2].quantity;
        return `/representative/${representProductCode}/sale/${saleProductCode}/reservation?adultCount=${adultCount}&childrenCount=${childrenCount}&infantCount=${infantCount}`;
    }

    /*
    * 예약 상세 결제 페이지 -> 잔금 결제 진행 할 수 있는 페이지 url 구한다
    * 예약 번호 필수
     */
    function getRemainPaymentReservationUrl() {
        if (!reservationData) return null
        return `/myPage/${reservationData.reservationNumber}/payment`
    }

    /*
    * 예약 상세  페이지 -> 잔금 결제 진행 할 수 있는 페이지 url 구한다
    * 예약 번호 필수
    */
    function getRemainDetailReservationUrl() {
        if (!reservationData) return null
        return `/myPage/${reservationData.reservationNumber}/detail`
    }


    /*
    * 전액 결제 -> 예약 생성 페이지 Redirect
    * 잔금 결제 -> 잔금 결제 진행 할 수 있는 페이지(디테일) Redirect
    * */
    function getRedirectReservationUrl({frontPaymentStep} = {
        frontPaymentStep: "OnePay"
    }) {
        if (!reservationData) return null
        const {reservationStep} = reservationData

        if (["PRE"].includes(reservationStep)) {
            return getCreateReservationUrl()
        }
        // 잔금: 1차 결제 실패시
        if (frontPaymentStep === "OnePay") {
            return getRemainPaymentReservationUrl()
        }
        // 잔금: 2차 결제 실패시
        if (frontPaymentStep === "TwoPay") {
            return getRemainDetailReservationUrl()
        }

        return "/"
    }


    return {
        loading,
        error,
        reservationData,
        representativeProductData,
        saleProductData,
        paymentData,
        inicisData,
        inquireListData,
        getRedirectReservationUrl

    }

}