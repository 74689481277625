import {selector} from "recoil";
import {paymentPriceStateAtom} from "./PaymentPriceState";
import {DiscountStep, DiscountType, INIT_DISCOUNT_VALUE} from "../../../domain/service/payment/PaymentEnum";
import {SaleProductAmountPriceType, SaleProductAmountTarget} from "../../../domain/model/product/SaleProductEntity";
import {getVoucherDiscountValue, initVoucher} from "./benefit/VoucherSelector";
import {VOUCHER_TYPE} from "../../../domain/model/user/UserVoucherEntity";
import {getFamilyDiscountTargetValue, getFamilyDiscountValue} from "./benefit/FamilySelectors";
import {getHccDiscountTargetValue, getHccDiscountValue} from "./benefit/Hcc3Selectors";
import {getTicketDiscountTargetValue, getTicketDiscountValue, initTicket} from "./benefit/TicketSelector";
import {getCouponDiscountValue, initCoupon} from "./benefit/CouponSelector";
import {getMPointDiscountTargetValue, getMPointDiscountValue} from "./benefit/MPointSelector";
import {cloneDeep} from "lodash";


export function makeStepRange(maxStep) {
    return Array.from({length: maxStep}, (v, i) => i + 1)
}

function getDiscounterByKind(kind) {
    switch (kind) {
        case DiscountType.GreenVoucher:
            return getVoucherDiscountValue(VOUCHER_TYPE.GREEN)
        case DiscountType.RedVoucher:
            return getVoucherDiscountValue(VOUCHER_TYPE.RED5)
        case DiscountType.RedVoucher4:
            return getVoucherDiscountValue(VOUCHER_TYPE.RED4)
        case DiscountType.RedVoucherStrip:
            return getVoucherDiscountValue(VOUCHER_TYPE.RED_STRIP)
        case DiscountType.SummitVoucher:
            return getVoucherDiscountValue(VOUCHER_TYPE.SUMMIT)
        case DiscountType.FamilyDiscount:
            return getFamilyDiscountValue
        case DiscountType.HCC3Discount:
            return getHccDiscountValue
        case DiscountType.Ticket:
            return getTicketDiscountValue
        case DiscountType.Coupon:
             return getCouponDiscountValue
        case DiscountType.MPoint:
            return getMPointDiscountValue
    }
}

function getTargetValueByKind(kind) {
    switch (kind) {
        case DiscountType.GreenVoucher:
        case DiscountType.RedVoucher:
        case DiscountType.RedVoucher4:
        case DiscountType.RedVoucherStrip:
        case DiscountType.SummitVoucher:
        case DiscountType.Coupon:
            return (paymentPrice, acc) => {
                const targetPrice = getTargetPrice(paymentPrice.amountDict)
                const ret = targetPrice - acc
                if (ret < 0) return 0
                return ret
            }
        case DiscountType.FamilyDiscount:
            return getFamilyDiscountTargetValue
        case DiscountType.HCC3Discount:
            return getHccDiscountTargetValue
        case DiscountType.Ticket:
            return getTicketDiscountTargetValue
        case DiscountType.MPoint:
            return getMPointDiscountTargetValue
    }
}

/*
* 할인 대상 금액 구한다.
* */
export function getTargetPrice(amountDict) {
    return Object.values(amountDict || {}).reduce((acc, amount) => {
        // 어린이는 할인 대상 가격이 포함이 안되어있다.
        if (amount.getTarget() === SaleProductAmountTarget.INFANT) {
            return acc + 0
        }
        // 어른,청소년은 상품 가격만이 할인 대상 금액이다.
        const amountPriceMap = amount.getPriceMap()
        return acc + amountPriceMap[SaleProductAmountPriceType.PRICE]
    }, 0)
}

export function getDiscountValueDict(paymentPrice) {
    const {discountDict} = paymentPrice
    const ret = {...INIT_DISCOUNT_VALUE}
    const steps = makeStepRange(DiscountStep.FOURTH_STEP)
    const dic = discountDict || {}
    steps.reduce((acc, step) => {
        if (!dic[step]) return acc
        const discountKind = dic[step]
        const discounter = getDiscounterByKind(discountKind)
        if (!discounter) return acc
        const _getTargetValue = getTargetValueByKind(discountKind)

        const discountValue = discounter(paymentPrice, _getTargetValue(paymentPrice, acc))
        ret[discountKind] = discountValue
        return acc + discountValue
    }, 0)
    return ret
}

/*
*
* 스텝별로 할인 금액을 구한다.
*
* */
export function getDiscountAccByStep(paymentPrice, step) {
    const {discountDict} = paymentPrice
    const ret = {...INIT_DISCOUNT_VALUE}
    const steps = makeStepRange(step)
    const dic = discountDict || {}
    return steps.reduce((acc, step) => {
        if (!dic[step]) return acc
        const discountKind = dic[step]
        const discounter = getDiscounterByKind(discountKind)
        if (!discounter) return acc
        const _getTargetValue = getTargetValueByKind(discountKind)
        const discountValue = discounter(paymentPrice, _getTargetValue(paymentPrice, acc))
        return acc + discountValue
    }, 0)
}


export const getDiscountDictSelector = selector({
    key: 'getDiscountDictSelector',
    get: ({get}) => {
        const {discountDict} = get(paymentPriceStateAtom);
        return discountDict
    }
})

function initOldDiscount(paymentPrice, newDiscount, oldKind) {
    // 이미 지정된 할인이 없음
    if (!oldKind) return {}

    // 새로 할당 되는 할인과 이전이 같다면, 할인을 초기화 하지 않는다.
    if (oldKind === newDiscount.kind) {
        return {}
    }
    const step = newDiscount.step
    // 첫번째 단계가 아니라면, 각자만의 스텝을 가지기 때문에 초기화 해줄 필요가 없다.
    if (step !== DiscountStep.FIRST_STEP) return {}

    let ret = {
        voucher: initVoucher(paymentPrice.voucher),
        coupon: initCoupon(paymentPrice.coupon),
        ticket: initTicket(paymentPrice.ticket),
    }
    if (oldKind === DiscountType.GreenVoucher) {
        delete ret['coupon']
        delete ret['ticket']
    }
    if (oldKind === DiscountType.RedVoucher) {
        delete ret['ticket']
        delete ret['coupon']
    }

    if (oldKind === DiscountType.RedVoucher4) {
        delete ret['ticket']
        delete ret['coupon']
    }
    if (oldKind === DiscountType.RedVoucherStrip) {
        delete ret['ticket']
        delete ret['coupon']
    }

    if (oldKind === DiscountType.SummitVoucher) {
        delete ret['ticket']
        delete ret['coupon']
    }

    if (oldKind === DiscountType.Coupon) {
        delete ret['voucher']
        delete ret['ticket']
    }
    if (oldKind === DiscountType.Ticket) {
        delete ret['voucher']
        delete ret['coupon']
    }
    return ret
}

/*
* 첫 번째 스텝 할인 혜택 초기화.
* */
export const initFirstStepDiscountSelector = selector(
    {
        key: 'initFirstStepDiscountSelector',
        get: ({get}) => {
            return get(paymentPriceStateAtom)
        },
        set: ({set, get}) => {
            const old = get(paymentPriceStateAtom);
            const oldKind = old.discountDict[DiscountStep.FIRST_STEP]
            const discountDict = cloneDeep(old.discountDict)
            delete discountDict[DiscountStep.FIRST_STEP]
            let voucher = cloneDeep(old.voucher)
            let coupon = cloneDeep(old.coupon)
            let ticket = cloneDeep(old.ticket)

            if (oldKind === DiscountType.GreenVoucher) {
                voucher = initVoucher(voucher)
            }
            if (oldKind === DiscountType.RedVoucher) {
                voucher = initVoucher(voucher)
            }
            if (oldKind === DiscountType.RedVoucher4) {
                voucher = initVoucher(voucher)
            }
            if (oldKind === DiscountType.RedVoucherStrip) {
                voucher = initVoucher(voucher)
            }
            if (oldKind === DiscountType.SummitVoucher) {
                voucher = initVoucher(voucher)
            }
            if (oldKind === DiscountType.Coupon) {
                coupon = initCoupon(coupon)
            }
            if (oldKind === DiscountType.Ticket) {
                ticket = initTicket(ticket)
            }
            set(
                paymentPriceStateAtom,
                {
                    ...old,
                    voucher,
                    coupon,
                    ticket,
                    discountDict
                }
            );
        }
    }
)
export const setDiscountSelectorV2 = selector({
    key: 'setDiscountSelectorV2',
    get: ({get}) => {
        return get(paymentPriceStateAtom).discountDict;
    },
    set: ({set, get}, newDiscount) => {
        const old = cloneDeep(get(paymentPriceStateAtom));

        const newDiscountDict = {...old.discountDict}
        const oldKind = newDiscountDict[newDiscount.step]

        newDiscountDict[newDiscount.step] = newDiscount.kind
        const refreshed = initOldDiscount(old, newDiscount, oldKind)
        set(
            paymentPriceStateAtom,
            {
                ...old,
                ...refreshed,
                discountDict: newDiscountDict
            }
        );
    }
})
export const removeDiscountSelectorV2 = selector({
    key: 'removeDiscountSelectorV2',
    get: ({get}) => {
        return get(paymentPriceStateAtom).discountDict;
    },
    set: ({set, get}, discount) => {
        const old = get(paymentPriceStateAtom);
        const newDiscountDict = {...old.discountDict}
        delete newDiscountDict[discount.step]
        set(
            paymentPriceStateAtom,
            {
                ...old,
                discountDict: newDiscountDict,
            }
        );
    }
})
export const getDiscountValueDictSelector = selector({
    key: 'getDiscountValueDictSelector',
    get: ({get}) => {
        return getDiscountValueDict(get(paymentPriceStateAtom))
    }
})
