import {SaleProductAmountPriceType, SaleProductAmountTarget} from "../../model/product/SaleProductEntity";

export default class AmountPrice {
    constructor(data) {
        this.target = data.target;
        let {priceMap} = data;
        /*
        *      priceMap: {
                [SaleProductAmountPriceType.PRICE]: this.data.priceAmount,
                [SaleProductAmountPriceType.FUEL_SURCHARGE]: this.data.fuelSurchargeAmount,
                [SaleProductAmountPriceType.TAX_DUTY]: this.data.taxDutyAmount,
            },
        *
        *
        * */
        this.priceMap = {...priceMap}
        this.count = data.count;
    }

    getTarget() {
        return this.target
    }

    getCount() {
        return this.count
    }

    getPrice() {
        return this.priceMap[SaleProductAmountPriceType.PRICE]
    }

    getFuelSurcharge() {
        return this.priceMap[SaleProductAmountPriceType.FUEL_SURCHARGE]
    }

    getTaxDuty() {
        return this.priceMap[SaleProductAmountPriceType.TAX_DUTY]
    }

    /*
    * 기준값
    * */
    getPriceConstMap() {
        const keys = Object.keys(this.priceMap);
        return keys.reduce((acc, key) => {
            acc[key] = this.priceMap[key]
            return acc;
        }, {})
    }

    /*
    *  count까지 고려된 가격을 반환하는 함수
    * */
    getPriceMap() {
        const keys = Object.keys(this.priceMap);
        return keys.reduce((acc, key) => {
            acc[key] = this.priceMap[key] * this.count
            return acc;
        }, {})
    }

    isTarget(target = SaleProductAmountTarget.ADULT) {
        return this.target === target;
    }

    getTargetViewString() {
        if (this.target === SaleProductAmountTarget.ADULT) {
            return "성인 1인"
        }
        if (this.target === SaleProductAmountTarget.CHILD) {
            return "아동 1인"
        }
        return "유아 1인"
    }

    setCount(count) {
        this.count = count
        return new AmountPrice({
            count,
            target: this.target,
            priceMap: {...this.priceMap}
        })
    }

    addCountAndNew(count) {
        let nextCount = this.count + count;
        if (nextCount < 0) {
            nextCount = 0;
        }
        if (nextCount === 0) {
            if (this.target === SaleProductAmountTarget.ADULT) {
                nextCount = 1;
            }
        }
        if (nextCount > 10) {
            nextCount = 10;
        }

        return new AmountPrice({
            count:nextCount,
            target: this.target,
            priceMap: {...this.priceMap}
        })
    }

    addCount(count) {
        let nextCount = this.count + count;
        if (nextCount < 0) {
            nextCount = 0;
        }
        if (nextCount === 0) {
            if (this.target === SaleProductAmountTarget.ADULT) {
                nextCount = 1;
            }
        }
        if (nextCount > 10) {
            nextCount = 10;
        }
        this.setCount(nextCount);
    }


    /**
     * 모든 가격 종류를 합산하여 총 금액을 구하는 함수
     */
    calculateTotalPrice() {
        const map = this.getPriceMap()
        return Object.values(map).reduce((acc, cur) => acc + cur, 0)
    }

}