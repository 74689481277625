import React, {useEffect} from "react";
import {shallowEqual, useSelector} from "react-redux";
import 'moment/locale/ko';
import moment from "moment/moment";
import {formatNumber} from "../../utils/formatNumber";
import {Link} from "react-router-dom";

const ProductInfo = (props) => {

    const priviaUrl = process.env.REACT_APP_PRIVIA_BASE_URL;

    let reservation = useSelector((state) => state.myPageReducer.reservation, shallowEqual);
    let representativeProduct = useSelector((state) => state.myPageReducer.representativeProduct, shallowEqual);
    let saleProduct = useSelector((state) => state.myPageReducer.saleProduct, shallowEqual);
    let paymentData = useSelector((state) => state.myPageReducer.paymentData, shallowEqual);

    useEffect(() => {
    }, []);

    const cancelRequest = () => {
        if (window.confirm("취소는 프리비아 온라인 문의로 접수해 주세요. 온라인 문의 페이지로 이동하시겠습니까?")) {
            window.location.href = priviaUrl + "/customer/inquire/form?svcDivnCd=TRV0010300&catDivnCd=" + props.catDivnCd +
                "&rservCd=" + reservation.reservationNumber +
                "&rservNm=" + saleProduct.saleProductName;
        }
    }

    const goSaleProductDetail = () =>{
        const startAt = saleProduct.departureDay.split("T")[0]
        const param = "?searchKeyword=" + saleProduct.saleProductName + "&&startAt=" + startAt
        // window.location.href = `/representative/${saleProduct.representProductCode}/sale/${saleProduct.saleProductCode}/detail` + param
        window.location.href = `/representative/${saleProduct.representProductCode}/sale/${saleProduct.saleProductCode}/detail`
    }

    return (
        <React.Fragment>
            {reservation &&
                <section className="book-detail-area a-mt50">
                    <div className="pkg-tit-page-cont bb-line between-box fc">
                        <h3 className="title">주문상품</h3>
                        <div className="book-code">
                            <p className="pd-nav">
                                <span>예약번호 {reservation.reservationNumber}</span>
                                <span>{saleProduct.supplierName} 예약번호 {reservation.reservationCode}</span>
                                <span>예약일 {moment(reservation.reservationDayTime).format("YYYY/MM/DD(dd)")}</span>
                            </p>
                        </div>
                    </div>

                    <div className="book-article border-bottom">
                        <div className="book-pd-info-part">
                            <span className="vis"><a style={{cursor: "pointer"}} onClick={goSaleProductDetail}>
                                {representativeProduct && (representativeProduct.representImageUrl && representativeProduct.representImageUrl !== 'http://image8.hanatour.com/product/img_no_M.gif') ?
                                    <img src={representativeProduct.representImageUrl} alt={saleProduct.saleProductName} />
                                    :
                                    <span className="no-img"></span>
                                }
                            </a></span>
                            <div className="pd-info">
                                <div className="wfull">
                                    <div className="addition-info">
                                        <span className="badge-base">{reservation.reservationStepName}</span>
                                        <p className="pd-nav">
                                            <span>{saleProduct.supplierName}</span>
                                            <span>상품코드 {saleProduct.saleProductCode}</span>
                                        </p>
                                    </div>
                                    <div className="pd-title"><Link to={''} style={{cursor: "pointer"}} onClick={goSaleProductDetail}>{saleProduct.saleProductName}</Link></div>
                                </div>
                                <p className="pd-resv-seleted">
                                    {saleProduct && moment(saleProduct.departureDay).format("MM/DD(dd)")} {saleProduct && moment(saleProduct.departureTime).format("HH:mm")}
                                    &nbsp;~ {saleProduct && moment(saleProduct.arrivalDay).format("MM/DD(dd)")} {saleProduct && moment(saleProduct.arrivalTime).format("HH:mm")}
                                    &nbsp;({saleProduct && saleProduct.travelNightCount}박{saleProduct && saleProduct.travelDayCount}일)
                                    <br />인원 : 성인 {paymentData.priceList.find(price => price.ageDivision === "ADULT").quantity} / 아동 {paymentData.priceList.find(price => price.ageDivision === "CHILDREN").quantity} / 유아 {paymentData.priceList.find(price => price.ageDivision === "INFANT").quantity}</p>
                                {reservation.reservationStep && reservation.reservationStep !== "CANCEL" &&
                                    saleProduct && saleProduct.reservationDivision === 'RESERVATION_CONFIRM' && (
                                <p className="state"><span className="c-point5 a-bold">출발확정</span> (최소 출발 : 성인 {saleProduct.minDepartureNumberOfPerson}명)</p>
                                )}
                            </div>
                        </div>
                        <div className="book-pd-price-part">
                            <span className="price-tt">총 상품 금액</span>
                            <span className="price"><em>{formatNumber(paymentData.totalAmount)}</em>원</span>
                        </div>
                    </div>

                    {reservation.reservationStep && reservation.reservationStep !== "CANCEL" &&(
                    <div className="btn-wrap a-right a-mt30">
                        <span className="btn-base btn-st-full btn-line-grd w150">
                            <button type="button" onClick={cancelRequest}>취소요청</button>
                        </span>
                    </div>
                    )}
                </section>
            }
        </React.Fragment>
    );
};

export default ProductInfo;