
export default function SliderCalendarCell({
                                               dataCell,
                                               isDisabled,
                                               childProducts,
                                               selectedDay,
                                               handleClickDataCell,
                                               holiday
                                           }) {

    function onClickDateCell(e) {
        e.stopPropagation()
        if (isDisabled) return
        handleClickDataCell && handleClickDataCell(dataCell)
    }

    function viewPrice() {
        if (childProducts === undefined) return '-'
        const prices = childProducts.map(x => x['calculatedPrice'])
        const sortedPrices = prices.sort((a, b) => a - b)
        const price = sortedPrices[0]
        const viewPriceValue = price / 10000
        return Math.floor(viewPriceValue) + "만"
    }

    function getClassName(dataCell) {
        const clsList = ["day-column"]
        if (isDisabled) {
            clsList.push("disabled")
            return clsList.join(" ")
        }

        if (childProducts === undefined) {
            clsList.push("disabled")
            return clsList.join(" ")
        }
        if (childProducts) {
            childProducts.forEach(x => {
                if (x.isMinPrice) {
                    clsList.push("lowest")
                }
            })
            // clsList.push("lowest")
        }

        if (dataCell.dayOfWeek === "토") {
            clsList.push("sat holi")
        }
        if (dataCell.dayOfWeek === "일" || holiday.includes(dataCell.date)) {
            clsList.push("holi")
        }

        if (dataCell.date === selectedDay) {
            clsList.push("today")
        }
        return clsList.join(" ")
    }

    return (
        <div className={getClassName(dataCell)} onClick={onClickDateCell}>
            <span className="week">
                {dataCell.dayOfWeek}
            </span>

            <span className={"day"}>
                    <a>{dataCell.day}</a>
            </span>

            {childProducts && !isDisabled && <span className={"amt"}>
               {viewPrice()}
            </span>
            }
        </div>
    )

}