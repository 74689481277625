import TopSlider from "../main/TopSlider/TopSlider";
import Faq from "../common/Faq";
import {useEffect, useState} from "react";

import {useDispatch} from "react-redux";
import {isGetTOPBANNERS} from "../redux/module/mainModule/MainReducer";
import {isSearchState, setSkyperTypeState} from "../redux/module/common/HeaderReducer";
import PcSwiperThemeA from "../main/TitleSlider/PcSwiperThemeA";
import PcSwiperThemeB from "../main/TitleSlider/PcSwiperThemeB";
import PcSwiperThemeC from "../main/TitleSlider/PcSwiperThemeC";
import PcSwiperThemeD from "../main/TitleSlider/PcSwiperThemeD";
import PcSwiperThemeE from "../main/TitleSlider/PcSwiperThemeE";
import PcSwiperTabA from "../main/NoAboutButtonSlider/PcSwiperTabA";
import PcSwiperTabB from "../main/NoAboutButtonSlider/PcSwiperTabB";
import axios from "axios";
import commonAPI from "./commonAPI";
import Loading from "./Loading";
import moment from "moment";


function urlAdapter() {

}

const Main = () => {
    const serverUrl = process.env.REACT_APP_SERVER_URL;
    const priviaUrl = process.env.REACT_APP_PRIVIA_BASE_URL;
    const dispatch = useDispatch();
    const [bannerData , setBannerData] = useState();
    const data = async ()=>{
        let resData =
            await axios.get(serverUrl+`/api/v1/pc/main`,{ headers: {'Authorization' : commonAPI.AUTHROIZATION, 'Channel-Id': 'PRIVIA', 'Content-Type':'application/json'}})
                .then(res=>res.data)
                .catch(error=>console.log(error))

        // let resData = BannerJson;
        setBannerData(resData);
        dispatch(isGetTOPBANNERS(resData))
    }

    useEffect(()=>{
        data()
        dispatch(setSkyperTypeState(false));
        dispatch(isSearchState(true))
    },[])

    //숙박 페스타 노출 기간 설정
    const hotelSalefestaEarlyStartDt = "2024-02-20 14:00:00";
    const hotelSalefestaEarlyEndDt = "2024-02-26 23:59:59";
    const hotelSalefestaLocalStartDt = "2024-02-26 23:59:59";
    const hotelSalefestaLocalEndDt = "2024-02-26 23:59:59";
    const hotelSalefestaNationStartDt = "2024-02-27 00:00:00";
    const hotelSalefestaNationEndDt = "2024-03-03 06:59:59";
    const isHotelSaleFesta = moment.now() >= moment(hotelSalefestaEarlyStartDt) && moment.now() <= moment(hotelSalefestaNationEndDt);
    const isHotelSaleFestaEarly = moment.now() >= moment(hotelSalefestaEarlyStartDt) && moment.now() <= moment(hotelSalefestaEarlyEndDt);
    const isHotelSaleFestaLocal = moment.now() >= moment(hotelSalefestaLocalStartDt) && moment.now() <= moment(hotelSalefestaLocalEndDt);
    const isHotelSaleFestaNation = moment.now() >= moment(hotelSalefestaNationStartDt) && moment.now() <= moment(hotelSalefestaNationEndDt);

    return (
        <>
            {isHotelSaleFesta &&
            <div id="mobile-promotion-sec">
                <div className="w-mobile-promotion new">
                    {isHotelSaleFestaEarly &&
                    <a href={`${priviaUrl}/promotion/early/entry`} className="mobile-htl lodge-festa" target="_blank">
                        <span className="bg"></span>
                    </a>
                    }
                    {isHotelSaleFestaNation &&
                    <a href={`${priviaUrl}/promotion/early/coupon`} className="mobile-htl lodge-festa nation" target="_blank">
                        <span className="bg"></span>
                    </a>
                    }
                    <a href={`${priviaUrl}/promotion/sectionEvent/7100`} className="mobile-htl lodge-festa faq" target="_blank">
                        <span className="bg"></span>
                    </a>
                    <div className="block" style={{display: "block"}}></div>
                </div>
            </div>
            }
            { bannerData ? (
                <div className={"w-content-sec"}>
                    <TopSlider />
                    {/* 긴급 모객 상품 */}
                    {bannerData && bannerData.pcSwiperTabA &&
                        <PcSwiperTabA pcSwiperTabA={bannerData&&bannerData.pcSwiperTabA}/>
                    }
                    {/* 출발 확정 상품 */}
                    {bannerData && bannerData.pcSwiperThemeA &&
                        <PcSwiperThemeA pcSwiperThemeA={bannerData&&bannerData.pcSwiperThemeA}/>
                    }
                    {/*테마 별 상품*/}
                    {bannerData && bannerData.pcSwiperThemeB &&
                        <PcSwiperThemeB pcSwiperThemeB={bannerData&&bannerData.pcSwiperThemeB}/>
                    }
                    {/*테마 별 상품*/}
                    {bannerData && bannerData.pcSwiperThemeC &&
                        <PcSwiperThemeC pcSwiperThemeC={bannerData&&bannerData.pcSwiperThemeC}/>
                    }
                    {/*테마 별 상품*/}
                    {bannerData && bannerData.pcSwiperThemeD &&
                        <PcSwiperThemeD pcSwiperThemeD={bannerData&&bannerData.pcSwiperThemeD}/>
                    }
                    {/*테마 별 상품*/}
                    {bannerData && bannerData.pcSwiperThemeE &&
                        <PcSwiperThemeE pcSwiperThemeE={bannerData&&bannerData.pcSwiperThemeE}/>
                    }
                    {/**/}
                    {bannerData && bannerData.pcSwiperTabB &&
                        <PcSwiperTabB pcSwiperTabB={bannerData&&bannerData.pcSwiperTabB}/>
                    }
                    <Faq />
                </div>
            ) : <Loading />}
        </>
    );
};

export default Main;