import {useEffect} from 'react';

const releaseURL = process.env.REACT_APP_INICIS_URL;

const Close = () => {
    // 구매하기 버튼 클릭
    useEffect(() => {
        // console.log("Close called");
        // console.log(releaseURL);
        // const event = new MouseEvent('iniCisClickedFalse', {});
        // window.parent.dispatchEvent(event);
        // // PC
        // const isTest = true;
        // const script = document.createElement("script");
        // script.src = releaseURL;
        // document.head.appendChild(script);
        const script = document.createElement("script");
        script.src = "https://stgstdpay.inicis.com/stdjs/INIStdPay_close.js";
        document.head.appendChild(script);
        return () => document.head.removeChild(script);
    }, []);
    return null;
};

export default Close;