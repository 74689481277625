import useFetchUserInformation from "../Auth/UseFetchUserInformation";
import {shallowEqual, useSelector} from "react-redux";
import {Outlet, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import TopBanner from "../Header/TopBanner";
import TopHeaderMenu from "../Header/TopHeaderMenu";
import Menu from "../Menu";
import Footer from "../Footer";

const ReservationLayout = () => {
    useFetchUserInformation();
    const [bannerButton , setBannerButton] = useState(false)

    const headerState = useSelector((state)=>state.headerReducer.isHeaderStickyState , shallowEqual);
    const navigate = useNavigate();
    useEffect(() => {
    },[headerState])

    const goBack = () => {
        // 이전 페이지로 이동
        navigate(-1);
    };


    const bannerButtonClickHandle = () =>{
        setBannerButton(!bannerButton)
    }

    return (
        <>
            {/* .commonHeaderObject : 헤더 타입 클래스 change(o-CHO- : full(기본), section(섹션), quick(퀵버전), header-sec-fixed(헤더 fixed) */}
            <div className="commonHeaderObject o-CHO-quick" style={headerState ?  {height : `487px`} : { height: 'auto'}  }>
            {/* .o-CHO-inner : 헤더 inner (header-sec-fixed(헤더 fixed), no-header-fixed(fixed 모드 사용안함) */}
                <div className={headerState ?  "o-CHO-inner header-sec-fixed" : "o-CHO-inner" }>
                    <TopBanner bannerState={bannerButton}/>
                    <div className={"o-search-package"}>
                        <div className={"header-sec o-bg-package"}>
                            <TopHeaderMenu bannerButtonClickHandle={bannerButtonClickHandle} bannerButtonState={bannerButton}/>
                            <Menu />
                        </div>
                    </div>
                </div>
            </div>
            <div id="content-sec">
                <Outlet />
            </div>
            <div id="footer-sec">
                <Footer/>
            </div>
        </>
    );
}

export default ReservationLayout;