import {useParams} from "react-router-dom";
import {useState} from "react";
import useLoadUser from "../../loader/useLoadUser";
import useReservationPerson from "./info/useReservationPerson";
import useCoupon from "./benefit/useCoupon";
import useTicket from "./benefit/useTicket";
import useCreditCard from "./benefit/useCreditCard";
import useTerms from "./info/useTerms";
import useTravelPerson from "./info/useTravelPerson";
import usePayment from "./info/usePayment";
import useRequestText from "./info/useRequestText";
import useMPoint from "./benefit/useMPoint";
import useLoadEventUser from "./event/useLoadEventUser";
import useLoadEventProduct from "./event/useLoadEventProduct";
import usePaymentPrice from "./usePaymentPrice";
import useHcc3 from "./benefit/useHcc3";
import useFamily from "./benefit/useFamily";
import {VOUCHER_TYPE} from "../../../domain/model/user/UserVoucherEntity";
import useVoucherV2 from "./benefit/useVoucherV2";
import {calculateReservationPrice, createPreReservation} from "../../../domain/repository/ReservationRepository";
import {PRICE_TYPE} from "../../state/reservation/PriceSelectors";
import useEnv from "../../../infra/env/useEnv";

export default function useCreateReservation(eventManager) {
    const userCtx = useLoadUser();
    const reservationPersonCtx = useReservationPerson(eventManager)
    const params = useParams();

    //  const voucherCtx = useVoucher(eventManager);
    const couponCtx = useCoupon(eventManager)
    const ticketCtx = useTicket(eventManager)
    const creditCardCtx = useCreditCard(eventManager)
    const termsCtx = useTerms(eventManager)
    const travelPersonCtx = useTravelPerson(eventManager)
    const paymentCtx = usePayment(eventManager)
    const requestTextCtx = useRequestText()
    const mPointCtx = useMPoint(eventManager);
    const hcc3Ctx = useHcc3(eventManager)
    const familyCtx = useFamily(eventManager)
    const [preReservation, setPreReservation] = useState(null)
    const [inicisCallNumber, setInicisCallNumber] = useState(0)
    const [inicisLoading, setInicisLoading] = useState(false)
    const {clientType} = useEnv()

    const redVoucherCtx = useVoucherV2({
        eventManager,
        voucherType: VOUCHER_TYPE.RED5
    })

    const redVoucher4Ctx = useVoucherV2({
        eventManager,
        voucherType: VOUCHER_TYPE.RED4
    })

    const redVoucherStripCtx = useVoucherV2({
        eventManager,
        voucherType: VOUCHER_TYPE.RED_STRIP
    })

    const greenVoucherCtx = useVoucherV2({
        eventManager,
        voucherType: VOUCHER_TYPE.GREEN
    })

    const summitVoucherCtx = useVoucherV2({
        eventManager,
        voucherType: VOUCHER_TYPE.SUMMIT
    })

    const {
        setPaymentStep
    } = usePaymentPrice()

    useLoadEventUser(eventManager)

    const {saleProductCtx, representativeProductCtx} =
        useLoadEventProduct(eventManager)

    function getInicisPayData() {
        return {
            inicisCallNumber,
            inicisLoading,
            setInicisLoading,
            preSavedReservationNumber: preReservation ? preReservation.reservationNumber : null,
           ...paymentCtx.getSubmitData(),
            ...paymentCtx.getInicisPayData(),
            ...reservationPersonCtx.getInicisPayData(),
            productName: representativeProductCtx.productName
        }
    }

    function validate() {
        function check(validation) {
            if (!validation.valid) {
                alert(validation.message)
                throw new Error(validation.message)
            }
        }

        check(reservationPersonCtx.validate())
        check(travelPersonCtx.validate())
        check(termsCtx.validate())
    }


    /**
     * 서버에서 가격 검증 하는 메서드
     * */
    async function checkServerPrice(restRequest) {
        const priceDict = paymentCtx.priceDict
        const calRes = await calculateReservationPrice(restRequest, restRequest.paymentData)
        if (calRes.status !== 200) {
            alert("서버와 연결이 불안정합니다. 다시 확인해 주세요.")
            return {
                valid: false
            }
        }
        const data = calRes.data
        if (!data) {
            alert("서버와 연결이 불안정합니다. 다시 확인해 주세요.")
            return {
                valid: false
            }
        }

        const step1 = priceDict[PRICE_TYPE.PAYMENT_PRICE_STEP_1]
        const step2 = priceDict[PRICE_TYPE.PAYMENT_PRICE_STEP_2]

        if (Math.abs(step1 + step2 - data['paymentAmount']) >= 10) {
            alert("가격이 변경되었습니다. 다시 확인해 주세요.")
            return {
                valid: false
            }
        }
        return {
            valid: true
        }
    }

    async function savePreReservation() {
        if (creditCardCtx.cardCode === null) {
            alert("결제하실 카드를 선택해 주세요.")
            return null
        }
        const inicisData = getInicisPayData()
        if (inicisData.productPrice < 100) {
            alert("최소 결제금액 100원 부터 결제 가능합니다.")
            return null
        }

        try {
            validate()
        } catch (e) {
            return null
        }

        if (!window.confirm('패키지 상품은 항공 좌석 및 객실 상황이 유동적이므로 담당자 확인 후 해피콜 시 예약 확정 여부를 알려드립니다. 확정불가 시 결제내역은 자동 취소처리 됩니다. ')) {
            return null
        }

        const paymentData = {
            ...paymentCtx.getSubmitData(),
            ...requestTextCtx.getSubmitData(),
            ...reservationPersonCtx.getSubmitData(),
            ...travelPersonCtx.getSubmitData(),
            addAmount: 0,
        }
        paymentData['reservationPersonInformation']["termsAgreeYn"] =
            termsCtx.getSubmitData()['termsAgreeYn']
        const restRequest = {
            representativeCode: params.representativeCode,
            saleCode: params.saleCode,
            clientType: clientType,
            paymentData
        }
        setInicisLoading(true)

        const calRes = await checkServerPrice(restRequest)
        if (!calRes.valid) {
            setInicisLoading(false)
            window.location.reload()
            return null
        }

        const res = await createPreReservation(restRequest)
        if (res.data['data'] === null) {
            setInicisLoading(false)
            alert("예약이 실패하였습니다.")
            return null
        }
        if (res.status === 200) {
            setPreReservation(res.data['data'])
            setInicisCallNumber((prev) => prev + 1)
            return res.data['data'].reservationNumber
        }
        return null
    }

    return {
        paymentCtx,
        mPointCtx,
        redVoucher4Ctx,
        redVoucherStripCtx,
        greenVoucherCtx,
        couponCtx,
        ticketCtx,
        creditCardCtx,
        termsCtx,
        saleProductCtx,
        representativeProductCtx,
        reservationPersonCtx,
        travelPersonCtx,
        hcc3Ctx,
        familyCtx,
        redVoucherCtx,
        summitVoucherCtx,
        reservationCtx: {
            inicisPayData: getInicisPayData(),
            savePreReservation,
        },
        requestTextCtx,
        userCtx,
    }
}