import {atom} from 'recoil';

export const UserType = {
    NONE: "NONE",
    IDENTIFY: "IDENTIFY",
};

export const userLoadingState = atom({
    key: 'userLoadingState', // Unique ID (with respect to other atoms/selectors)
    default: true, // default value (aka initial value)
});

export const userErrorState = atom({
    key: 'userErrorState', // Unique ID (with respect to other atoms/selectors)
    default: null, // default value (aka initial value)
});

export const userState = atom({
    key: 'userState', // Unique ID (with respect to other atoms/selectors)
    default: {
        userType: UserType.NONE, // NONE or IDENTIFY
        callNumber: null, // 010-0000-0000
        email: null, // test@test.com
        emailReceiveAgreeYn: null, // Y or N
        hccAuthorizationDate: null, // "2023-12-19 00:00:00"
        hccGrade: null, // "그린"
        hccMkusmnCustomNumber: null, //1234567890
        hccMyCustomNumber: null,//1234567890
        hccThirdAgreeYn: null, // Y or N
        individualInformation3MnpofAgreeYn: null, // Y or N
        individualInformationAgreeYn: null,// Y or N
        marketingReceiveAgreeYn: null,// Y or N
        memberBirth: null,// 2002-02-02
        memberDivision: null, // MBRDIVN001
        memberId: null, // "rucy1234"
        memberName: null, // 홍길동
        memberNumber: null, // 123456
        memberSex: null, // M or F
        memberStatus: null, // MEMBERSTS01
        modifiedDate: null, // 2024-01-22 15:42:06
        nativeForeignDivision: null, // "0"
        optionalInformationAgreeYn: null,// Y or N
        registeredDate: null, // 2023-01-17 13:25:37
        smsReceiveAgreeYn: null, // Y or N
        telNumber: null, // ?
        termsAgreeYn: null, // Y or N
    },
});