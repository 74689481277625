import {useEffect, useState} from "react";
import {getAreaList} from "../../domain/repository/AreaRepository";


export default function useLoadArea() {

    /**
     * interface Continent {
     *     code: string;
     *     name: string;
     *     nationList: Nation[];
     * }
     *
     * interface Nation {
     *     continentCode: string;
     *     continentName: string;
     *     nationCode: string;
     *     nationName: string;
     * }
     *
     * interface ContinentsData {
     *     continentList: Continent[];
     * }
     *
     * // 사용 예시
     * const data: ContinentsData = {
     *     continentList: [
     *         // ... 여기에 JSON 데이터를 삽입합니다.
     *     ]
     * };
     *
     *
     */
    const [continentList, setContinentList] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [isError, setError] = useState(false);

    useEffect(() => {
        setLoading(true)
        setError(false)

        getAreaList().then((res) => {
            setLoading(true)
            setContinentList(res.data['continentList'])
        }).catch((err) => {
            setError(true)
        })
    }, []);

    return {
        isAreaLoading: isLoading,
        isAreaLoadError: isError,
        isError,
        continentList
    }
}