import {useEffect, useState} from "react";
import {QueryFilterType} from "../parent/useProductQueryParamFilter";
import usePriviaUrl, {PRIVIA_URL} from "../../../../infra/hooks/usePriviaUrl";
import useMoment from "../../../../infra/hooks/moment/useMoment";

export default function useDoubleCalendar(queryParamFilterCtx) {
    const {
        startDate,
        endDate,
        setQueryFilter,
    } = queryParamFilterCtx

    const {isUrl, path} = usePriviaUrl()

    const {moment} = useMoment()
    const [openCalendar, setOpenCalendar] = useState(false)
    const [startAt, setStartAtState] = useState(null)
    const [endAt, setEndAtState] = useState(null)

    useEffect(() => {
        // privia가 홈이 아닐 경우

        if (isUrl(PRIVIA_URL.PRODUCT_LIST)) {
            setStartAtState(moment.yyyymmddToMoment(startDate))
            setEndAtState(moment.yyyymmddToMoment(endDate))
        }
    }, [path, startDate, endDate]);

    function setStartAt(date) {
        const yymmdd = moment.makeStringToYYYYMMDD(date)
        setQueryFilter(QueryFilterType.START_DATE, yymmdd)
        setStartAtState(date)

    }
    function setEndAt(date) {
        const yymmdd = moment.makeStringToYYYYMMDD(date)
        setQueryFilter(QueryFilterType.END_DATE, yymmdd)
        setEndAtState(date)
    }

    function setPeriodRange(range) {
        setStartAt(range[0])
        setEndAt(range[1])
    }

    function getDate(at) {
        if (at === null) return moment.makeStringToYYYYMMDD(moment.addDayCount(moment.getToday(), 0))
        return moment.makeStringToYYYYMMDD(at)
    }

    return {
        startAtString: moment.makeViewString(startAt) || '',
        endAtString: moment.makeViewString(endAt) || '',
        startAtDate: getDate(startAt),
        endAtDate: getDate(endAt),
        setPeriodRange,
        openCalendar,
        setOpenCalendar,
    }
}