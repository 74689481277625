import {useParams} from "react-router-dom";
import useLoadChildProduct from "../../../loader/useLoadChildProduct";
import useLoadProduct from "../../../loader/useLoadProduct";
import {useEffect} from "react";
import {EVENT_NAMES} from "./events";
import {EventBase} from "../../../../infra/event/EventManager";


export default function useLoadEventProduct(eventManager) {
    const params = useParams();
    const saleProductCtx = useLoadChildProduct(params.representativeCode, params.saleCode);
    const representativeProductCtx = useLoadProduct(params.representativeCode);

    useEffect(() => {
        if (representativeProductCtx.loading) return;
        eventManager.publish(new EventBase(EVENT_NAMES.LOAD_REPRESENTATIVE_PRODUCT, representativeProductCtx.representativeProduct))
    }, [representativeProductCtx.loading])

    useEffect(() => {
        if (saleProductCtx.loading) return;
        eventManager.publish(new EventBase(EVENT_NAMES.LOAD_SALE_PRODUCT, saleProductCtx.saleProduct));
    }, [saleProductCtx.loading]);

    function getInicisPayData() {
        return {
            productName: representativeProductCtx.productName,
        }
    }
    return {
        saleProductCtx,
        representativeProductCtx,
        getInicisPayData
    }
}