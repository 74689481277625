import {useState} from "react";
import {findProductByKeyword} from "../../domain/repository/ProductRepository";

/**
 *
 * interface KeywordArea {
 *     city: string;
 *     state: string;
 *     country: string;
 *     saleProductCount: number;
 *     keyworCityProductCount: number;
 * }
 *
 * interface KeywordProduct {
 *     representProductCode: string;
 *     code: string;
 *     name: string;
 *     visitCountryCode: string;
 * }
 *
 * interface KeywordData {
 *     keywordAreaList: KeywordArea[];
 *     keywordProductList: KeywordProduct[];
 * }
 *
 * // 사용 예시
 * const data: KeywordData = {
 *     keywordAreaList: [
 *         // ... 여기에 keywordAreaList의 데이터를 삽입합니다.
 *     ],
 *     keywordProductList: [
 *         // ... 여기에 keywordProductList의 데이터를 삽입합니다.
 *     ]
 * };
 */
export default function useLoadProductByKeyword() {
    const [keyword, setKeyword] = useState(null);
    const [searchByKeyword, setSearchByKeyword] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    async function loadProductByKeyword(inputString) {
        setLoading(true)
        setIsError(false)
        setKeyword(inputString)

        if (inputString === null || inputString === undefined || inputString === "") {
            setLoading(false)
            setSearchByKeyword(null)
            setKeyword('')
            return;
        }

        try {
            const response = await findProductByKeyword(inputString);
            setLoading(false)
            setSearchByKeyword(null);
            const requestBody = JSON.parse(response.config.data);
            if (requestBody['keyword'] !== inputString) {
                return;
            }
            setSearchByKeyword(response.data);
            if (response.data.keywordProductList.length === 0 && response.data.keywordAreaList.length === 0) {
                setSearchByKeyword(null)
            }
        } catch (e) {
            setIsError(true);
            console.log(e);
        }
    }

    // useEffect(() => {
    //     if (keyword) {
    //         loadProductByKeyword().catch(e => console.log(e));
    //     }
    // }, [keyword]);

    return {
        loadProductByKeyword,
        keyword,
        setKeyword,
        searchByKeyword,
        isLoadingProductByKeyword: loading,
        isErrorProductByKeyword: isError,
    }
}