import useLoadUser from "../../../loader/useLoadUser";
import {useEffect} from "react";
import {EVENT_NAMES} from "./events";
import {EventBase} from "../../../../infra/event/EventManager";
import {useSetRecoilState} from "recoil";
import {setUserTypeSelector} from "../../../state/reservation/UserSelector";
import {UserType} from "../../../state/user/UserState";

export default function useLoadEventUser(eventManager) {
    const setRecoilUserType = useSetRecoilState(setUserTypeSelector)


    const userCtx = useLoadUser()
    const user = userCtx.user
    const coupon = user.getCouponEntity()
    const voucher = user.getVoucherEntity()
    const ticket = user.getTicketEntity()
    const family = user.getFamilyEntity()
    const mPoint = user.getMPointEntity()
    // publish coupon
    useEffect(() => {
        if (coupon.isLoaded()) {
            eventManager.publish(new EventBase(EVENT_NAMES.LOAD_COUPON, coupon))
        }
    }, [coupon.isLoaded()])

    // publish user
    useEffect(() => {
        if (userCtx.loading) return
        eventManager.publish(new EventBase(EVENT_NAMES.LOAD_USER, userCtx.user))
        if (userCtx.user.isValid()) {
            setRecoilUserType(UserType.IDENTIFY)
        }
    }, [userCtx.loading])

    // publish voucher
    useEffect(() => {
        if (voucher.isLoaded()) {
            eventManager.publish(new EventBase(EVENT_NAMES.LOAD_VOUCHER, voucher))
        }
    }, [voucher.isLoaded()])

    // publish ticket
    useEffect(() => {
        if (ticket.isLoaded()) {
            eventManager.publish(new EventBase(EVENT_NAMES.LOAD_TICKET, ticket))
        }
    }, [ticket.isLoaded()])

    // publish family
    useEffect(() => {
        if (family.isLoaded()) {
            eventManager.publish(new EventBase(EVENT_NAMES.LOAD_FAMILY_DISCOUNT, family))
        }
    }, [family.isLoaded()])

    // publish mPoint
    useEffect(() => {
        if (mPoint.isLoaded()) {
            eventManager.publish(new EventBase(EVENT_NAMES.LOAD_M_POINT, mPoint))
        }
    }, [mPoint.isLoaded()]);

}