import {cloneDeep} from "lodash";
import {selector} from "recoil";
import {paymentPriceStateAtom} from "./PaymentPriceState";
import {UserType} from "../user/UserState";

function setUserType(paymentPrice, userType = UserType.IDENTIFY) {
    const user = cloneDeep(paymentPrice.user)
    user.type = UserType.IDENTIFY

    return {
        ...paymentPrice,
        user
    }
}


export const setUserTypeSelector = selector({
    key: 'setUserTypeSelector',
    get: ({get}) => {
        const paymentPrice = get(paymentPriceStateAtom)
        return paymentPrice.user.type
    },
    set: ({set, get}, type) => {
        const paymentPrice = get(paymentPriceStateAtom)
        set(paymentPriceStateAtom, setUserType(paymentPrice, type))
    }
})