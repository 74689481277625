import {formatNumber} from "../../../../../utils/formatNumber";
import React from "react";
import {ChildProductCardList} from "./ChildProductCardList";
import useProductFilter from "../../../usecase/product-filter/parent/userProductFilter";

export default function ParentProductCard({product, viewChildProductCode, setViewChildProductCode}) {

    const {filter: {startDateFilter}} = useProductFilter()


    if (product == null) return null
    function toggleChildProductView(e) {
        // 여기다가 주입.
        e.stopPropagation()
        let nextViewChildProductCode = product.representProductCode
        if (product.representProductCode === viewChildProductCode) {
            nextViewChildProductCode = null
        }
        setViewChildProductCode(nextViewChildProductCode)
    }

    const isChildProductView = product.representProductCode === viewChildProductCode

    return (
        <React.Fragment key={`representProduct${product.representProductCode}`}>
            <div className="res-pd-wrap o-acdi-one">
                <div className="pd-info-area">
                    {
                        product.representImageUrl !== 'http://image8.hanatour.com/product/img_no_M.gif' ?
                            (
                                <span className="vis">
                                                <img src={product.representImageUrl}/>
                                            </span>
                            ) :
                            (
                                <span className={"vis"}>
                                                <span className={"no-img"}></span>
                                            </span>
                            )
                    }
                    <div className="product between-box fb">
                        <div className="pd-info-wrap">
                            <div className="pd-h2 ellipsis2">
                                {product.representProductName}
                            </div>
                            <div className="pd-info-detail">
                                <span className="detail ico-pdType">{product.representProductDivisionName}</span>
                                <PeriodDay dayCounts={product.dayCounts} dayCount={product.dayCount}/>
                                <span className="detail ico-depart">{product.supplierName}</span>
                                <div
                                    className="hash-wrap">{product.representProductTags && product.representProductTags.split(",").map(tag => {
                                    return "#" + tag
                                })}</div>
                            </div>
                        </div>
                        <div className="pkg-price">
                                    <span className="price">
                                        <em>{formatNumber(product.minExhibitionPrice)}</em>원~
                                    </span>
                            <span className="btn-base btn-st-full btn-bg-bl wfull">
                                        <a style={{cursor: "pointer"}}
                                           className={isChildProductView ? "o-acdi-click o-ac-on" : "o-acdi-click"}
                                           onClick={toggleChildProductView}
                                        >판매상품보기</a>
                            </span>
                        </div>
                    </div>
                </div>

                {/*  하단 자식 상품 리스트  */}
                {
                    isChildProductView ? <ChildProductCardList isGotoOne={true} parentProduct={product}/> : null
                }
            </div>
        </React.Fragment>
    )

}


function PeriodDay({dayCounts, dayCount}) {
    let str = '';
    if (dayCounts && Array.isArray(dayCounts)) {
        str = dayCounts.map((dayCount, index) => (index === 0 ? '' : '/') + dayCount + '일')
    }
    if (dayCount) {
        str = dayCount + '일';
    }

    return (
        <span className="detail ico-calendar">
            여행기간&nbsp;{str}
        </span>
    )
}