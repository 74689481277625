
export default function useEnv(){
    const serverUrl = process.env.REACT_APP_INICIS_SERVER_URL;
    const defaultMid = process.env.REACT_APP_DEFAULT_MID;
    const hccMid = process.env.REACT_APP_HCC_MID;
    const pointMid = process.env.REACT_APP_MPOINT_MID;
    const defaultMkey = process.env.REACT_APP_DEFAULT_MKEY;
    const hccMkey = process.env.REACT_APP_HCC_MKEY;
    const pointMkey = process.env.REACT_APP_MPOINT_MKEY;
    const releaseURL = process.env.REACT_APP_INICIS_URL;
    const envType = process.env.REACT_APP_ENV_TYPE;
    const inicisReturnUrl = serverUrl + "/front/reservation/result"
    const inicisCloseUrl = serverUrl + "/reservation/close"
    const frontUrl = process.env.REACT_APP_FRONT_URL;
    const loginLink = process.env.REACT_APP_MENU_LOGIN_LINK;
    const clientType = process.env.REACT_APP_CLIENT_TYPE;
    return {
        frontUrl,
        serverUrl,
        defaultMid,
        hccMid,
        pointMid,
        defaultMkey,
        hccMkey,
        pointMkey,
        releaseURL,
        envType,
        inicisReturnUrl,
        inicisCloseUrl,
        loginLink,
        clientType,
    }
}