import React from "react";
import {goBackPage, goMainPage} from "../common/util/RedirectLogin";

const ReservationError = () => {

    return (
        <div className="content-sec ui-pkg pb0">
            <section className="w-content-sec">
                <div className="pkg-product-con-sec">
                    <div className="pkg-con-group">
                        <article className="pkg-pd-off">
                            <div className="lpu-item-wrap">
                                <div className="lpu-sub-item off-nonedata">
                                    <div className="txt-nonedata">
                                        예약정보가 존재하지 않습니다.<br/>예약정보가 삭제되었거나 미노출 상태로 변경되어, 요청하신 페이지를 찾을 수 없습니다.
                                    </div>
                                    <div className="btn-side-wrap">
                                        <span className="btn-base bb-st-line" onClick={goBackPage}><button type="button">이전 페이지로</button></span>
                                        <span className="btn-base bb-st-full" onClick={goMainPage}><button type="button">메인 페이지로</button></span>
                                    </div>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
            </section>
        </div>
    );

};

export default ReservationError;