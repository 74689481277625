import {useEffect, useState} from "react";
import axios from "axios";
import {getAxiosHeaders} from "../util/Axios";
import {Link} from "react-router-dom";
import "./TopBanner.css"
import styles from "./TopBanner.module.css";


const TopBanner = ({bannerState}) =>{
    const [topBanner , setTopBanner] = useState([])

    const banner = async () =>{
        const serverUrl = process.env.REACT_APP_SERVER_URL
        let resData = await axios.get(`${serverUrl}/api/v1/pc/main/ribbon`,{
            headers: getAxiosHeaders()
        }).then(res=>res.data)
            .catch(error=>console.log(error))

        if(resData !== undefined) setTopBanner(resData)
    }

    useEffect(()=>{
        banner();
    },[])

    return(
        <div>
                <div className={`top-area-sec ${styles.hidden} ${bannerState ? '' : styles.open}`}>
                        <h2 className="a11y">섹션별 프로모션 영역</h2>
                        <div className="top-wide-banner">
                            <div className="wide-banner-bg">
                                {
                                    topBanner && topBanner.map((item , index)=>{
                                        if(index === 0) {
                                            return (
                                                <span key={`wideBannerBg${index}`} className={"left-bg"} style={{background:item.bnrColorLeft}}></span>
                                            )
                                        } else if (index === 1) {
                                            return (
                                                <span key={`wideBannerBg${index}`} className={"right-bg"} style={{background:item.bnrColorRight}}></span>
                                            )
                                        }
                                    })
                                }
                            </div>
                            <ul className="banner-area item2">
                                {
                                    topBanner && topBanner.map((item , index)=>{
                                        return (
                                            <li key={`topBanner${index}`}>
                                                <Link to={item.linkAddr}>
                                                    <img src={item.bnrImg} alt={item.title}/>
                                                </Link>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                </div>
        </div>
    )
}

export default TopBanner