import {selector} from "recoil";
import {DiscountType, PaymentType} from "../../../domain/service/payment/PaymentEnum";
import {paymentPriceStateAtom} from "./PaymentPriceState";
import {SaleProductAmountPriceType, SaleProductAmountTarget} from "../../../domain/model/product/SaleProductEntity";
import {getDiscountValueDict} from "./DiscountSelector";

export const PRICE_TYPE = Object.freeze({
    SUM_PRODUCT_PRICE: 'SUM_PRODUCT_PRICE', // 총 상품 가격  = 할인 대상 금액 + 비할인 대상 금액
    SUM_TARGET_PRICE: "SUM_TARGET_PRICE", // 할인 대상 금액
    SUM_NONE_TARGET_PRICE: "SUM_NONE_TARGET_PRICE", // 할인 대상이 아닌 금액
    RESERVATION_PRICE: 'RESERVATION_PRICE', // 총 상품 가격에 10% -> 예약금
    PRE_PAID_PRICE: 'PRE_PAID_PRICE',// 선수금 -> 기본적으로 예약금에서 발생
    ADD_AMOUNT: 'ADD_AMOUNT',// 추가금 -> 어드민에서 추가한 금액
    SUM_BENEFIT_PRICE: 'SUM_BENEFIT_PRICE',// 혜택 총합
    M_POINT_PRICE: 'M_POINT_PRICE', // M포인트 사용 금액 , -> 이거 사용 안하는 중.
    PAYMENT_PRICE: 'PAYMENT_PRICE',// 결제 금액 -> 이것도 삭제 되어야할 듯.
    PAYMENT_PRICE_STEP_1: 'PAYMENT_PRICE_STEP_1',// 1회차 결제 금액
    PAYMENT_PRICE_STEP_2: 'PAYMENT_PRICE_STEP_2',// 2회차 결제 금액
    ROOT_TARGET_PRICE: 'ORIGIN_TARGET_PRICE',// 첫 결제 금액에서 PREPAID 제외한 금액. 즉 모든 할인의 ROOT
    IS_DISCOUNTABLE: 'IS_TARGET_PRICE',// 할인 대상 여부
})

/*
* 할인 대상 금액 구한다.
* */
export function getTargetPrice(amountDict) {
    return Object.values(amountDict || {}).reduce((acc, amount) => {
        // 어린이는 할인 대상 가격이 포함이 안되어있다.
        if (amount.getTarget() === SaleProductAmountTarget.INFANT) {
            return acc + 0
        }
        // 어른,청소년은 상품 가격만이 할인 대상 금액이다.
        const amountPriceMap = amount.getPriceMap()
        return acc + amountPriceMap[SaleProductAmountPriceType.PRICE]
    }, 0)
}

export function getNoneTarget(amountDict) {
    return Object.values(amountDict || {}).reduce((acc, amount) => {
        let nextAcc = acc
        const amountPriceMap = amount.getPriceMap()
        if (amount.getTarget() === SaleProductAmountTarget.INFANT) {
            // 유아의 경우 상품 가격도 할인 대상 금액이 아니다.
            nextAcc += amountPriceMap[SaleProductAmountPriceType.PRICE]
        }
        nextAcc += amountPriceMap[SaleProductAmountPriceType.TAX_DUTY]
        nextAcc += amountPriceMap[SaleProductAmountPriceType.FUEL_SURCHARGE]
        return nextAcc
    }, 0)
}
function getBasePriceDict(paymentPrice) {
    const amountDict = paymentPrice.amountDict
    const noneTargetValue = getNoneTarget(amountDict)
    const targetValue = getTargetPrice(amountDict)
    const all = noneTargetValue + targetValue
    const reservationPrice = Math.floor(all / 10)
    const discountValueDict = getDiscountValueDict(paymentPrice)
    const sumDiscount = Object.values(discountValueDict).reduce((acc, cur) => acc + cur, 0) -
        discountValueDict[DiscountType.MPoint]


    return {
        [PRICE_TYPE.SUM_PRODUCT_PRICE]: all,
        [PRICE_TYPE.RESERVATION_PRICE]: reservationPrice,
        [PRICE_TYPE.SUM_TARGET_PRICE]: targetValue,
        [PRICE_TYPE.SUM_NONE_TARGET_PRICE]: noneTargetValue,
        [PRICE_TYPE.SUM_BENEFIT_PRICE]: sumDiscount,
        [PRICE_TYPE.PRE_PAID_PRICE]: paymentPrice.prePaidPrice,
        [PRICE_TYPE.ADD_AMOUNT]: paymentPrice.addAmount,
        [PRICE_TYPE.ROOT_TARGET_PRICE]: targetValue - paymentPrice.prePaidPrice,
    }
}

export function getPriceDict(paymentPrice) {
    const basePriceDict = getBasePriceDict(paymentPrice)
    const all = basePriceDict[PRICE_TYPE.SUM_PRODUCT_PRICE]
    const sumDiscount = basePriceDict[PRICE_TYPE.SUM_BENEFIT_PRICE]
    const prePaidPrice = basePriceDict[PRICE_TYPE.PRE_PAID_PRICE]
    const newAddAmount = basePriceDict[PRICE_TYPE.ADD_AMOUNT] ? basePriceDict[PRICE_TYPE.ADD_AMOUNT] : 0
    // 예약금 계산임.
    if (paymentPrice.paymentType === PaymentType.RESERVATION_PAY) {
        return {
            ...basePriceDict,
            [PRICE_TYPE.SUM_TARGET_PRICE]: basePriceDict[PRICE_TYPE.RESERVATION_PRICE],
            [PRICE_TYPE.PAYMENT_PRICE]: basePriceDict[PRICE_TYPE.RESERVATION_PRICE],
            [PRICE_TYPE.PAYMENT_PRICE_STEP_1]: basePriceDict[PRICE_TYPE.RESERVATION_PRICE],
            [PRICE_TYPE.PAYMENT_PRICE_STEP_2]: 0
        }
    }

    let resultPrice = all - sumDiscount - prePaidPrice + newAddAmount
    if (!paymentPrice.mPoint || !paymentPrice.mPoint.useMPoint) { // mPoint 사용 안하면 -> 2차 결제 x
        return {
            ...basePriceDict,
            [PRICE_TYPE.PAYMENT_PRICE]: resultPrice,
            [PRICE_TYPE.PAYMENT_PRICE_STEP_1]: resultPrice,
            [PRICE_TYPE.PAYMENT_PRICE_STEP_2]: 0,
        }
    }
    // mPoint 사용하면 -> 2차 결제 진행.
    // 예약금 케이스
    let step1 = basePriceDict[PRICE_TYPE.SUM_TARGET_PRICE] - sumDiscount - prePaidPrice + newAddAmount
    let step2 = basePriceDict[PRICE_TYPE.SUM_NONE_TARGET_PRICE]
    // 스텝별로 1차,2차 나누고 금액 보여줌.
    return {
        ...basePriceDict,
        [PRICE_TYPE.PAYMENT_PRICE]: resultPrice,
        [PRICE_TYPE.PAYMENT_PRICE_STEP_1]: step1,
        [PRICE_TYPE.PAYMENT_PRICE_STEP_2]: step2,
    }
}




export const getPriceDictSelector = selector({
        key: 'getPriceDictSelector',
        get: ({get}) => {
            const paymentPrice = get(paymentPriceStateAtom);
            return getPriceDict(paymentPrice)
        }
    }
)

