import {useEffect, useState} from "react";

export default function useProgressPercent({minPercent = 0, isEnd = false, maxPercent = 99, perTimeMils = 500}) {
    const [percent, setPercent] = useState(minPercent);

    useEffect(() => {
        // 최대 퍼센트에 도달했거나 isEnd가 true일 때 타이머를 중지합니다.
        if (isEnd || percent >= maxPercent) {
            return;
        }

        // 지정된 시간마다 퍼센트를 증가시킵니다.
        const timer = setTimeout(() => {
            setPercent(prev => {
                if (prev >= maxPercent) {
                    return maxPercent;
                }
                return prev + 1
            })
        }, perTimeMils);

        // 컴포넌트 언마운트 시 타이머 제거
        return () => clearTimeout(timer);
    }, [percent, maxPercent, isEnd, perTimeMils]); // 의존성 배열에 percent 추가

    return {loadingPercent: isEnd ? 100 : percent};
}