import React, {useState} from "react";
import {formatNumber} from "../../utils/formatNumber";
import {Link} from "react-router-dom";

const PcSwiperTabB = (props) =>{
    const [style , setStyle] = useState({transform : `translate3d(0px, 0px, 0px)`})
    const [tabData , setTabData] = React.useState(0)
    const data = props.pcSwiperTabB;

    const handleBannertab = (e , index) =>{
        setTabData(index)
    }

    function onClickHref(url) {
        return (e) => {
            e?.stopPropagation();
            window.location.href = url;
        }
    }
    const bannerTabEle =data && data.themeList.map((val , index)=>{
        return(
            <li key={index} className={index === tabData ? "on" : "btn-base bbr-st3"}
                style={{cursor:"pointer"}} onClick={(e)=>{handleBannertab(e ,index)}}>
                <span className="btn-base bbr-st3">
                    <a>{val.tabTitle}</a>
                </span>
            </li>
        )
    })

    const bannerImgEle = data && data.themeList.map((val , index)=>{
        return val.productList.map((item , number)=>{
            const search = item.productDetail.name
            if(tabData === index) {
                return(
                    <li key={number} className={"ga-promo list"}>
                        <Link onClick={onClickHref(item.productDetail.link)}>
                            {
                                item.productDetail.imageUrl !=='http://image8.hanatour.com/product/img_no_M.gif' ?
                                    (
                                        <span className="vis">
                                        <img src={item.productDetail.imageUrl} alt={item.productDetail.name} />
                                    </span>
                                    ):
                                    (
                                        <span className={"vis"}>
                                        <span className={"no-img"}></span>
                                    </span>
                                    )
                            }
                            <span className="info-box">
                                <span className="label-base">
                                    {item.productDetail.description && <span className="label-item">{item.productDetail.description}</span>}
                                    {item.productDetail.supplierName && <span className="label-item">{item.productDetail.supplierName}</span>}
                                </span>

                                <span className="tit">
                                    <span className="ellipsis2">{item.productDetail.name}</span>
                                </span>
                                <span className="price"><em>{formatNumber(item.productDetail.price)}</em>원~</span>
                            </span>
                        </Link>
                    </li>
                )
            }
        })
    })

    return(
        <div className="s-best-promotion-cont ly-inner">
            <h2 className="pkg-tit-sec">
                <span>{data && data.name}</span>
            </h2>
            <div className="o-tab-menu">
                <ul className="ui-tab-menu">
                    {bannerTabEle}
                </ul>
                <div className="ui-tab-cont tab-on area-cont-box" id="asapList1">
                    <ul className={"list-item-pakage"}>
                        {bannerImgEle}
                    </ul>

                    <div className="btn-wrap a-center a-pt40">
							<span className="btn-base btn-st-bfull btn-bg-bl w300">
                                {
                                    data.themeList.at(tabData).additionLinkYn === 'Y' ?
                                        <Link to={data.themeList.at(tabData).additionLink}>{data && `${data.themeList.at(tabData).tabTitle} ${data.themeList.at(tabData).additionLinkName}`}</Link>:null
                                }
							</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default  PcSwiperTabB;