import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {
    allConditionListAtom,
    calculatePriceCurrentRangeUtils,
    dynamicFilterCountAtom,
    filterCountAtom,
    resetAirlineFilterAtom,
    resetConditionFilterAtom,
    resetDepartureCityFilterAtom,
    resetDepartureTimeFilterAtom,
    resetGradeFilterAtom,
    resetHotelFilterAtom,
    resetPeriodFilterAtom,
    resetPriceFilterAtom,
    resetProductTypeFilterAtom,
    resetShopCountFilterAtom,
    resetSupplierFilterAtom,
    setAllAirlineListAtom,
    setEndDateFilterAtom,
    setOrderFilterAtom,
    setPriceFilterMinMaxAtom,
    setRangePriceFilterAtom,
    setSearchKeywordChoiceFilterAtom,
    setStartDateFilterAtom,
    toggleAirlineFilterAtom,
    toggleConditionFilterAtom,
    toggleDepartureCityFilterAtom,
    toggleDepartureTimeFilterAtom,
    toggleGradeFilterAtom,
    toggleHotelFilterAtom,
    togglePeriodFilterAtom,
    toggleProductTypeFilterAtom,
    toggleShopCountFilterAtom,
    toggleSupplierFilterAtom,
} from "../../../state/ParentProductFilterState";
import useProductQueryParamFilter from "./useProductQueryParamFilter";
import usePaging from "../../../../infra/hooks/usePaging";
import {AirlineCodeType} from "../../../../../search/AirlineCodeType";
import {Money} from "../../../../infra/type/Money";
//import {AirlineCodeType} from "../../../../../../search/AirlineCodeType";
// import usePaging from "../../../../../infra/hooks/usePaging";
// import {Money} from "../../../../../infra/type/Money";


export default function useProductFilter() {
    useProductQueryParamFilter()
    const allProductTypeList = [
        "패키지",
        "에어텔",
        "골프",
        "허니문",
        "크루즈",
    ]

    const allSupplierList = [
        "하나투어",
        "모두투어",
        "참좋은여행",
        "한진관광"
    ]

    const paging = usePaging({})

    const filterCount = useRecoilValue(filterCountAtom)
    const dynamicFilterCount = useRecoilValue(dynamicFilterCountAtom)
    const [allAirlineList, setAllAirlineList] = useRecoilState(setAllAirlineListAtom);
    const allConditionList = useRecoilValue(allConditionListAtom)
    const [supplierFilterList, toggleSupplierFilterList] = useRecoilState(toggleSupplierFilterAtom);
    const [productTypeFilterList, toggleProductTypeFilterList] = useRecoilState(toggleProductTypeFilterAtom);
    const [periodFilterList, togglePeriodFilterList] = useRecoilState(togglePeriodFilterAtom);
    const [airlineFilterList, toggleAirlineFilterList] = useRecoilState(toggleAirlineFilterAtom);
    const [departureCityFilterList, toggleDepartureCityFilterList] = useRecoilState(toggleDepartureCityFilterAtom);
    const [departureTimeFilterList, toggleDepartureTimeFilterList] = useRecoilState(toggleDepartureTimeFilterAtom);
    const [gradeFilterList, toggleGradeFilterList] = useRecoilState(toggleGradeFilterAtom);
    const [hotelFilterList, toggleHotelFilterList] = useRecoilState(toggleHotelFilterAtom);
    const [conditionFilterList, toggleConditionFilterList] = useRecoilState(toggleConditionFilterAtom);
    const [shopCountFilterList, toggleShopCountFilterList] = useRecoilState(toggleShopCountFilterAtom);
    const [priceRangeFilter, setPriceRangeFilter] = useRecoilState(setRangePriceFilterAtom);
    const [startDateFilter, setStartDateFilter] = useRecoilState(setStartDateFilterAtom);
    const [endDateFilter, setEndDateFilter] = useRecoilState(setEndDateFilterAtom);
    const [orderFilter, setOrderFilter] = useRecoilState(setOrderFilterAtom);
    const [searchKeywordChoiceFilter, setSearchKeywordChoiceFilter] = useRecoilState(setSearchKeywordChoiceFilterAtom);
    const [_, setPriceFilterMinMax] = useRecoilState(setPriceFilterMinMaxAtom);

    const resetSupplierFilterList = useSetRecoilState(resetSupplierFilterAtom);
    const resetProductTypeFilterList = useSetRecoilState(resetProductTypeFilterAtom);
    const resetPeriodFilterList = useSetRecoilState(resetPeriodFilterAtom);
    const resetAirlineFilterList = useSetRecoilState(resetAirlineFilterAtom);
    const resetDepartureCityFilterList = useSetRecoilState(resetDepartureCityFilterAtom);
    const resetDepartureTimeFilterList = useSetRecoilState(resetDepartureTimeFilterAtom);
    const resetGradeFilterList = useSetRecoilState(resetGradeFilterAtom);
    const resetHotelFilterList = useSetRecoilState(resetHotelFilterAtom);
    const resetConditionFilterList = useSetRecoilState(resetConditionFilterAtom);
    const resetShopCountFilterList = useSetRecoilState(resetShopCountFilterAtom);
    const resetPriceRangeFilter = useSetRecoilState(resetPriceFilterAtom);


    function isFiltering() {
        /**
         * 필터링 조건이 하나라도 존재하면 true
         * */
        if (supplierFilterList.length > 0) return true
        if (productTypeFilterList.length > 0) return true
        if (periodFilterList.length > 0) return true
        if (airlineFilterList.length > 0) return true
        if (departureCityFilterList.length > 0) return true
        if (departureTimeFilterList.length > 0) return true
        if (gradeFilterList.length > 0) return true
        if (hotelFilterList.length > 0) return true
        if (conditionFilterList.length > 0) return true
        if (shopCountFilterList.length > 0) return true
        // 가격이 필터링중인거 판단하는거 어렵네. 일단은 필터링 중 아니라고 판단
        // 따로 판단하도록 하자.
        if (priceRangeFilter.currentMin !== priceRangeFilter.min
            || priceRangeFilter.currentMax !== priceRangeFilter.max) return true

        return false
    }


    function calculatePriceRangeFilter(changedMinSlider, changedMaxSlider) {
        return calculatePriceCurrentRangeUtils(priceRangeFilter, changedMinSlider, changedMaxSlider)
    }

    function getFilterChips() {
        function getSupplierChips() {
            if (supplierFilterList.length === allSupplierList.length) return [{
                type: 'supplierName',
                label: "여행사(전체)",
                onDelete: () => {
                    resetSupplierFilterList()
                }
            }]

            return supplierFilterList.map(supplier => {
                return {
                    type: 'supplierName',
                    label: supplier,
                    onDelete: () => {
                        toggleSupplierFilterList(supplier)
                    }
                }
            })
        }
        function getProductTypeChips() {
            const MAX_PRODUCT_TYPE_SIZE = 5
            if (productTypeFilterList.length === MAX_PRODUCT_TYPE_SIZE) return [{
                type: 'saleProductDivisions',
                label: "상품타입(전체)",
                onDelete: () => {
                    resetProductTypeFilterList()
                }
            }]
            return productTypeFilterList.map(productType => {
                return {
                    type: 'saleProductDivisions',
                    label: productType,
                    onDelete: () => {
                        toggleProductTypeFilterList(productType)
                    }
                }
            })
        }
        function getPeriodChips() {
            const MAX_PERIOD_SIZE = 5
            if (periodFilterList.length === MAX_PERIOD_SIZE) return [{
                type: 'period',
                label: "여행기간(전체)",
                onDelete: () => {
                    resetPeriodFilterList()
                }
            }]

            function getKrLabel(period) {
                switch (period) {
                    case 'withinFourDay':
                        return '4일 이내'
                    case 'fiveToEight':
                        return '5~8일'
                    case 'nineToTen':
                        return '9~10일'
                    case 'elevenToSixteen':
                        return '11~16일'
                    case 'beyondSeventeen':
                        return '17일 이상'
                }
            }

            return periodFilterList.map(period => {
                return {
                    type: 'period',
                    label: getKrLabel(period),
                    onDelete: () => {
                        togglePeriodFilterList(period)
                    }
                }
            })
        }
        function getAirlineChips() {
            if (allAirlineList.length === 0) return []

            if (airlineFilterList.length === allAirlineList.length) return [{
                type: 'airline',
                label: "항공사(전체)",
                onDelete: () => {
                    resetAirlineFilterList()
                }
            }]

            return airlineFilterList.map(airline => {
                return {
                    type: 'airline',
                    label: AirlineCodeType[airline],
                    onDelete: () => {
                        toggleAirlineFilterList(airline)
                    }
                }
            })
        }
        function getDepartureCityChips() {
            const MAX_DEPARTURE_CITY_SIZE = 7
            if (departureCityFilterList.length === MAX_DEPARTURE_CITY_SIZE) return [{
                type: 'departureCity',
                label: "출발도시(전체)",
                onDelete: () => {
                    resetDepartureCityFilterList()
                }
            }]
            return departureCityFilterList.map(departureCity => {
                const dict = {
                    "JCN": "인천/김포",
                    "PUS": "부산",
                    "CJJ": "청주",
                    "YNY": "양양",
                    "KWJ": "광주",
                    "CJU": "제주",
                    "TAE": "대구",
                }

                return {
                    type: 'departureCity',
                    label: dict[departureCity],
                    onDelete: () => {
                        toggleDepartureCityFilterList(departureCity)
                    }
                }
            })
        }
        function getDepartureTimeChips() {
            const MAX_DEPARTURE_TIME_SIZE = 4
            if (departureTimeFilterList.length === MAX_DEPARTURE_TIME_SIZE) return [{
                type: 'departureTime',
                label: "출발시간(전체)",
                onDelete: () => {
                    resetDepartureTimeFilterList()
                }
            }]

            const dict = {
                "dawn": "새벽(00~05시)",
                "morning": "아침(05~12시)",
                "lunch": "점심(12~18시)",
                "afternoon": "저녁(18~24시)",
            }

            return departureTimeFilterList.map(departureTime => {
                return {
                    type: 'departureTime',
                    label: dict[departureTime],
                    onDelete: () => {
                        toggleDepartureTimeFilterList(departureTime)
                    }
                }
            })
        }
        function getGradeChips() {
            const MAX_GRADE_SIZE = 3
            if (gradeFilterList.length === MAX_GRADE_SIZE) return [{
                type: 'grade',
                label: "등급(전체)",
                onDelete: () => {
                    resetGradeFilterList()
                }
            }]

            const dict = {
                "PREMIUM": "프리미엄",
                "PRIVATE": "프라이빗",
                "SAVE": "가성비甲",
            }

            return gradeFilterList.map(grade => {
                return {
                    type: 'grade',
                    label: dict[grade],
                    onDelete: () => {
                        toggleGradeFilterList(grade)
                    }
                }
            })
        }
        function getHotelChips() {
            const MAX_HOTEL_SIZE = 4
            if (hotelFilterList.length === MAX_HOTEL_SIZE) return [{
                type: 'hotel',
                label: "호텔(전체)",
                onDelete: () => {
                    resetHotelFilterList()
                }
            }]
            const dict = {
                "fiveStar": "5성급",
                "fourStar": "4성급",
                "threeStar": "3성급",
                "etc": "기타",
            }
            return hotelFilterList.map(hotel => {
                return {
                    type: 'hotel',
                    label: dict[hotel],
                    onDelete: () => {
                        toggleHotelFilterList(hotel)
                    }
                }
            })
        }
        function getConditionChips() {
            return conditionFilterList.map(condition => {
                const dict = {
                    "includeFreeTour": "자유일정포함",
                }
                return {
                    type: 'condition',
                    label: dict[condition],
                    onDelete: () => {
                        toggleConditionFilterList(condition)
                    }
                }
            })
        }
        function getShopCountChips() {
            return shopCountFilterList.map(shopCount => {
                return {
                    type: 'shopCount',
                    label: `쇼핑 ${shopCount.count}회 ${shopCount.method === 'over' ? '이상' : ''}`,
                    onDelete: () => {
                        toggleShopCountFilterList(shopCount)
                    }
                }
            })
        }

        function getPriceRangeChips() {
            if (priceRangeFilter.currentMin === priceRangeFilter.min
                && priceRangeFilter.currentMax === priceRangeFilter.max) return []

            return [{
                type: 'priceRange',
                label: `${new Money(priceRangeFilter.currentMin).viewString()} ~ ${new Money(priceRangeFilter.currentMax).viewString()}`,
                onDelete: () => {
                    resetPriceRangeFilter({forceUpdate: true})
                }
            }]
        }

        return [
            ...getSupplierChips(),
            ...getProductTypeChips(),
            ...getPeriodChips(),
            ...getAirlineChips(),
            ...getDepartureCityChips(),
            ...getDepartureTimeChips(),
            ...getGradeChips(),
            ...getHotelChips(),
            ...getConditionChips(),
            ...getShopCountChips(),
            ...getPriceRangeChips()
        ]
    }

    function resetFilterAll(forceUpdate = true, rangeInit = false) {
        resetSupplierFilterList({forceUpdate})
        resetProductTypeFilterList({forceUpdate})
        resetPeriodFilterList({forceUpdate})
        resetAirlineFilterList({forceUpdate})
        resetDepartureCityFilterList({forceUpdate})
        resetDepartureTimeFilterList({forceUpdate})
        resetGradeFilterList({forceUpdate})
        resetHotelFilterList({forceUpdate})
        resetConditionFilterList({forceUpdate})
        resetShopCountFilterList({forceUpdate})
        resetPriceRangeFilter({forceUpdate, rangeInit})
        paging.initPaging()
    }

    const filter = {
        filterCount,
        dynamicFilterCount,
        airlineFilterList,
        supplierFilterList,
        productTypeFilterList,
        periodFilterList,
        departureCityFilterList,
        departureTimeFilterList,
        gradeFilterList,
        hotelFilterList,
        conditionFilterList,
        shopCountFilterList,
        priceRangeFilter,
        searchKeywordChoiceFilter,
        startDateFilter,
        endDateFilter,
        setOrderFilter: orderFilter,
        paging
    }

    const constValue = {
        allAirlineList,
        allConditionList,
        allProductTypeList,
        allSupplierList
    }

    const toggle = {
        toggleSupplierFilterList,
        toggleProductTypeFilterList,
        togglePeriodFilterList,
        toggleAirlineFilterList,
        toggleDepartureCityFilterList,
        toggleDepartureTimeFilterList,
        toggleGradeFilterList,
        toggleHotelFilterList,
        toggleConditionFilterList,
        toggleShopCountFilterList,
    }

    const set = {
        setPriceRangeFilter,
        setStartDateFilter,
        setEndDateFilter,
        setSetOrderFilter: setOrderFilter,
        setSearchKeywordChoiceFilter,
    }

    const range = {
        setPriceRangeFilter,
    }

    const reset = {
        resetSupplierFilterList,
        resetProductTypeFilterList,
        resetPeriodFilterList,
        resetAirlineFilterList,
        resetDepartureCityFilterList,
        resetDepartureTimeFilterList,
        resetGradeFilterList,
        resetHotelFilterList,
        resetConditionFilterList,
        resetShopCountFilterList,
        resetPriceRangeFilter,
    }

    const dynamicFilter = {
        setPriceFilterMinMax,
        setAllAirlineList
    }

    const calculate = {
        calculatePriceRangeFilter
    }

    return {
        isFiltering: isFiltering(),
        getFilterChips,
        filterCount,
        dynamicFilterCount,
        resetFilterAll,
        filter,
        toggle,
        reset,
        set,
        range,
        constValue,
        dynamicFilter,
        calculate,
    }
}
