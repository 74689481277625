import React, { useCallback, useEffect, useRef, useState } from "react";
import TravelerSelectModal from "./TravelerSelectModal";

const ErrorType = {
    VALID: "VALID",
    REQUIRED: "REQUIRED",
    INVALID: "INVALID",
}

const initErrors = {
    koreanFullName: null,
    sex: null,
    englishFamilyName: null,
    englishGivenName: null,
    birth: null,
    phone: null,
    email: null,
}

export default function TravelForm({
    index,
    travelPerson,
    travelPersonCtx,
    userType,
}) {
    const {
        travelerSelectList,
        onCheckSyncReservationPerson,
        isReservationPerson,
        addTravelerInfoData,
        isMember,
        travelerInfoChangeHandler,
        updateTravelerInfoList,
    } = travelPersonCtx;

    const {
        title,
        errors,
    } = travelPerson

    const isFirst = title.includes("성인") && index === 0;
    const isAdult = title.includes("성인");
    let ageDivision
    if (title.includes("성인")) {
        ageDivision = "adult"
    } else if (title.includes("아동")) {
        ageDivision = "children"
    } else if (title.includes("유아")) {
        ageDivision = "infant"
    }

    const [traveler, setTraveler] = useState(null)

    const [inputErrors, setInputErrors] = useState(errors)

    const [isModalOpen, setIsModalOpen] = useState(false);
    const closeModal = () => {
        setIsModalOpen(false);
    };

    let travelerValue = travelPerson;

    useEffect(() => {
        valueSetting(travelPerson)
        setInputErrors(errors)
    }, [travelPerson]);

    useEffect(() => {
        if (!traveler) return

        const updatedTraveler = {
            title: title,
            errors: initErrors,
            ...traveler,
        };
        updateTravelerInfoList(ageDivision, index, updatedTraveler)
    }, [traveler]);

    const koreanFullNameInput = useRef();
    const sexMInput = useRef();
    const sexFInput = useRef();
    const englishFamilyNameInput = useRef();
    const englishGivenNameInput = useRef();
    const birthInput = useRef();
    const phoneInput = useRef();
    const emailInput = useRef();

    const valueSetting = useCallback((values) => {
        travelerValue = values;
        koreanFullNameInput.current.value = travelerValue.koreanFullName
        if (travelerValue.sex === "") {
            sexMInput.current.checked = false
            sexFInput.current.checked = false
        }
        if (travelerValue.sex === null) {
            sexMInput.current.checked = false
            sexFInput.current.checked = false
        }
        if (travelerValue.sex === 'M') sexMInput.current.checked = true
        if (travelerValue.sex === 'F') sexFInput.current.checked = true

        englishFamilyNameInput.current.value = travelerValue.englishFamilyName.toUpperCase()
        englishGivenNameInput.current.value = travelerValue.englishGivenName.toUpperCase()
        birthInput.current.value = travelerValue.birth
        if (isAdult) {
            phoneInput.current.value = travelerValue.phone;
            emailInput.current.value = travelerValue.email
        }
    }, [travelPerson]);

    useEffect(() => {


    }, [inputErrors]);

    function selectTraveler(selectedTraveler) {
        setTraveler(selectedTraveler)
    }

    const componentId = "component_" + index


    function onChangeHandler(e) {
        if (travelerInfoChangeHandler) {
            travelerInfoChangeHandler(e, inputErrors, ageDivision, index, travelerValue);
        }
    }

    function handleTravelerChoice() {
        setIsModalOpen(true);
    }

    return (
        <React.Fragment key={`${title}`}>
            <div className="pkg-tit-article-cont between-box fc">
                <h3 className="title">{title}</h3>
                {
                    isFirst && <span className="check-group">
                        <input type="checkbox" className="hidden_check"
                            id={componentId}
                            checked={isReservationPerson}
                            onChange={() => onCheckSyncReservationPerson && onCheckSyncReservationPerson()}
                            name={"pkgBooking_" + title} />
                        <label htmlFor={componentId}
                            className="label-check"> 예약자와 동일</label>
                    </span>
                }
            </div>

            <ul className="f-field-box">
                <li className="flex-2n">
                    <div className="necessary tit-input">한글명</div>
                    <div className={"input-area  " + (isMember ? "w160" : "")}>
                        <input type="text"
                            id={`koreanFullName`}
                            name={`koreanFullName`}
                            className={`input-base type01 wfull koreanFullName ${inputErrors.koreanFullName === null || inputErrors.koreanFullName?.type === ErrorType.VALID ? '' : 'err'}`}
                            onChange={onChangeHandler}
                            ref={koreanFullNameInput}
                            placeholder="예) 홍길동"
                        />
                    </div>
                    {
                        isMember &&
                        <>
                            <span className="btn-base btn-st-sfull btn-bg-gr w115">
                                <button type="button" onClick={handleTravelerChoice}>
                                    여행자 선택
                                </button>
                            </span>
                            {
                                isModalOpen &&
                                <TravelerSelectModal isOpen={isModalOpen} onClose={closeModal} traveler={traveler}
                                    selectTraveler={selectTraveler}
                                    travelerSelectList={travelerSelectList}
                                    addTravelerInfoData={addTravelerInfoData}
                                    title={title}
                                />
                            }

                        </>
                    }
                    {
                        inputErrors.koreanFullName && inputErrors.koreanFullName.type !== ErrorType.VALID &&
                        <p className="err-feedback">{inputErrors.koreanFullName.message}</p>
                    }
                </li>


                <li className="flex-2n">
                    <div className="necessary tit-input">성별</div>
                    <div className="input-area">
                        <span className="radio-wrap">
                            <input type="radio"
                                id="sex_m"
                                name={`sex_${title}`}
                                onChange={onChangeHandler}
                                ref={sexMInput}
                            />
                            <label htmlFor="sex_m" className="label-base">남자</label>
                        </span>

                        <span className="radio-wrap">
                            <input type="radio"
                                id="sex_f"
                                name={`sex_${title}`}
                                onChange={onChangeHandler}
                                ref={sexFInput}
                            />
                            <label htmlFor='sex_f' className="label-base">여자</label>
                        </span>
                    </div>
                    {
                        inputErrors.sex && inputErrors.sex.type !== ErrorType.VALID &&
                        <p className="err-feedback">{inputErrors.sex.message}</p>
                    }
                </li>


                <li className="flex-2n">
                    <div className="necessary tit-input">영문성</div>
                    <div className="input-area">
                        <input type="text"
                            name={`englishFamilyName`}
                            id={`englishFamilyName`}
                            className={`input-base type01 wfull englishOnly ${inputErrors.englishFamilyName === null || inputErrors.englishFamilyName?.type === ErrorType.VALID ? '' : 'err'}`}
                            onChange={onChangeHandler}
                            placeholder="예) HONG"
                            ref={englishFamilyNameInput}
                        />
                    </div>
                    {
                        inputErrors.englishFamilyName && inputErrors.englishFamilyName.type !== ErrorType.VALID
                        && <p className="err-feedback">{inputErrors.englishFamilyName.message}</p>
                    }
                </li>


                <li className="flex-2n">
                    <div className="necessary tit-input">영문이름</div>
                    <div className="input-area">
                        <input type="text"
                            name={`englishGivenName`}
                            id={`englishGivenName`}
                            onChange={onChangeHandler}
                            className={`input-base type01 wfull englishOnly ${inputErrors.englishGivenName === null || inputErrors.englishGivenName?.type === ErrorType.VALID ? '' : 'err'}`}
                            placeholder="예) GILDONG"
                            ref={englishGivenNameInput}
                        />
                    </div>
                    {
                        inputErrors.englishGivenName && inputErrors.englishGivenName.type !== ErrorType.VALID
                        && <p className="err-feedback">{inputErrors.englishGivenName.message}</p>
                    }
                </li>

                <li className="flex-2n wfull">
                    <div className="necessary tit-input">생년월일</div>
                    <div className="input-area">
                        <input type="text"
                            name={`birth`}
                            id={`birth`}
                            maxLength={8}
                            className={`input-base type01 wfull birth ${inputErrors.birth === null || inputErrors.birth?.type === ErrorType.VALID ? '' : 'err'}`}
                            onChange={onChangeHandler}
                            placeholder="예) 19930516"
                            ref={birthInput}
                        />
                    </div>
                    {
                        inputErrors.birth && inputErrors.birth.type !== ErrorType.VALID
                        && <p className="err-feedback">{inputErrors.birth.message}</p>
                    }
                </li>

                {isAdult &&
                    <>
                        <li className="flex-2n">
                            <div className="necessary tit-input">연락처</div>
                            <div className="input-area"><input type="text"
                                name={`phone`}
                                id={`phone`}
                                maxLength={13}
                                className={`input-base type01 wfull phoneNumber ${inputErrors.phone === null || inputErrors.phone?.type === ErrorType.VALID ? '' : 'err'}`}
                                onChange={onChangeHandler}
                                placeholder="예) 010-1234-5678"
                                ref={phoneInput}
                            />
                            </div>
                            {
                                inputErrors.phone && inputErrors.phone.type !== ErrorType.VALID
                                && <p className="err-feedback">{inputErrors.phone.message} </p>
                            }
                        </li>

                        <li className="flex-2n">
                            <div className="necessary tit-input">이메일</div>
                            <div className="input-area"><input type="text"
                                name={`email`}
                                id={`email`}
                                className={`input-base type01 wfull email ${inputErrors.email === null || inputErrors.email?.type === ErrorType.VALID ? '' : 'err'}`}
                                onChange={onChangeHandler}
                                placeholder="예) test@naver.com"
                                ref={emailInput}
                            />

                            </div>
                            {
                                inputErrors.email && inputErrors.email.type !== ErrorType.VALID
                                && <p className="err-feedback"
                                    style={{ bottom: `5px`, letterSpacing: `-0.9px` }}> {inputErrors.email.message} </p>
                            }
                        </li>
                    </>
                }
            </ul>
        </React.Fragment>
    )
}