const LOADING = `Search/LOADING`
const QUICK_SEARCH_LOADING = `Search/QUICK_SEARCH_LOADING`
const SEARCH_SET_DATA = `Search/SEARCH_SET_DATA`
const SEARCH_SET_CHIL_DDATA = `Search/SEARCH_SET_CHILD_DATA`
const SEARCH_SET_AREA = `Search/SEARCH_SET_AREA`
const SEARCH_MODAL_STATE = `Search/SEARCH_MODAL_STATE`
const SEARCH_KEYWORD = `Search/SEARCH_KEYWORD`
const SEARCH_KEYWORD_CHOICE = `Search/SEARCH_KEYWORD_CHOICE`;
const SEARCH_DATE = `Search/SEARCH_DATE`
const SEARCH_CHILD_DATE = `Search/SEARCH_CHILD_DATE`
const SEARCH_FILTER_DATA = `Search/SEARCH_FILTER_DATA`
const SEARCH_FILTER_DATA_DELETE = `Search/SEARCH_FILTER_DATA_DELETE`
const SET_SELECTED_FILTER_LIST = `Search/SET_SELECTED_FILTER_LIST`
const SET_FILTER = `Search/SET_FILTER`
const SET_DEFAULT_MIN_PRICE = `Search/SET_DEFAULT_MIN_PRICE`
const SET_DEFAULT_MAX_PRICE = `Search/SET_DEFAULT_MAX_PRICE`
const SET_RESET_FILTER = `Search/SET_RESET_FILTER`
const SET_RESET_PRICE_FILTER = `Search/SET_RESET_PRICE_FILTER`
const SET_COMMON_SEARCH_KEYWORD = `Search/SET_COMMON_SEARCH_KEYWORD`
const SET_PRICE_STATE = `Search/SET_PRICE_STATE`
export const isLoading = (loadingState) =>({type:LOADING , loadingState})
export const setQuickSearchLoadingState = (quickSearchLoadingState) =>({type:QUICK_SEARCH_LOADING , quickSearchLoadingState})
export const isSearchData = (searchData) =>({type:SEARCH_SET_DATA , searchData})
export const isSearchChildData = (searchChildData) =>({type:SEARCH_SET_CHIL_DDATA , searchChildData})
export const isSearchArea = (searchArea) =>({type:SEARCH_SET_AREA , searchArea})
export const isSearchModalState = (searchModalState) =>({type:SEARCH_MODAL_STATE , searchModalState: searchModalState})
export const isSearchKeyword = (searchKeyword) =>({type:SEARCH_KEYWORD , searchKeyword})
export const isSearchKeywordChoice = (name , isKeywordChoice) =>({type:SEARCH_KEYWORD_CHOICE , name, isKeywordChoice})
export const isSearchDate = (isDateObj) =>({type:SEARCH_DATE , isDateObj})
export const isSearchChildDate = (date) =>({type:SEARCH_CHILD_DATE , date})
export const setFilterData = (filterData) =>({type:SEARCH_FILTER_DATA , filterData})
export const setSearchFilterDataDelete = (filterData) =>({type:SEARCH_FILTER_DATA_DELETE , filterData})
export const setSelectedFilterList = (selectedFilterList) => ({type: SET_SELECTED_FILTER_LIST, selectedFilterList})
export const setFilter = (filter) => ({type: SET_FILTER, filter})
export const setDefaultMinPrice = (defaultMinPrice) => ({type: SET_DEFAULT_MIN_PRICE, defaultMinPrice})
export const setDefaultMaxPrice = (defaultMaxPrice) => ({type: SET_DEFAULT_MAX_PRICE, defaultMaxPrice})
export const setResetFilter = (isResetFilter) => ({type: SET_RESET_FILTER, isResetFilter})
export const setResetPriceFilter = (isResetPriceFilter) => ({type: SET_RESET_PRICE_FILTER, isResetPriceFilter})
export const setCommonSearchKeyword = (commonSearchKeyword) => ({type: SET_COMMON_SEARCH_KEYWORD, commonSearchKeyword})
export const isSetPriceState = (isPriceState)=>({type:SET_PRICE_STATE , isPriceState})

const initialState ={
    priceState : false ,
    loadingState : true,
    quickSearchLoadingState: false,
    searchData : [],
    searchChildData : [],
    searchArea : [],
    searchModalState: false,
    searchKeyword : [],
    isKeywordChoice:{
        id:'',
        data:{}
    },
    isSearchDate:{
        startDate : ``,
        endDate : ``
    },
    isSearchChildDate:{
        date : ``,
    },
    filterData : [],
    selectedFilterList: [],
    filter: [],
    defaultMinPrice: 0,
    defaultMaxPrice: 9999999999,
    isResetFilter: false,
    isResetPriceFilter: false,
    isResetAirlineFilter: false,
    isSearchCommonKeyword: {key:'', value:'' , startDate:''}
}

const searchState = (state = initialState , action) => {
    switch (action.type){
        case LOADING:
            return{
                ...state,
                loadingState : action.loadingState
            }
        case QUICK_SEARCH_LOADING:
            return{
                ...state,
                quickSearchLoadingState : action.quickSearchLoadingState
            }
        case SEARCH_SET_DATA:
            return{
                ...state,
                searchData : action.searchData
            }
        case SEARCH_SET_CHIL_DDATA:
            return{
                ...state,
                searchChildData : action.searchChildData
            }
            case SEARCH_SET_AREA:
                return {
                    ...state,
                    searchArea : action.searchArea
                }
        case SEARCH_MODAL_STATE:
            return{
                ...state,
                searchModalState : action.searchModalState
            }
            case SEARCH_KEYWORD:
                return{
                    ...state,
                    searchKeyword : action.searchKeyword
                }
        case SEARCH_KEYWORD_CHOICE:
            return{
                ...state,
                isKeywordChoice : {...state.isKeywordChoice , id: action.name , data: action.isKeywordChoice}
            }
        case SEARCH_DATE:
            return{
                ...state,
                isSearchDate : {...state.isSearchDate , startDate: action.isDateObj.startDate , endDate: action.isDateObj.endDate}
            }
            case SEARCH_CHILD_DATE:
                return{
                    ...state,
                    isSearchChildDate : {...state.isSearchChildDate , date: action.date}
                }
        case SEARCH_FILTER_DATA:
            return{
                ...state,
                filterData : action.filterData
            }
        case SEARCH_FILTER_DATA_DELETE:
            return{
                ...state,
                filterData : state.filterData.filter((data) => data !== action.filterData)
            }
        case SET_SELECTED_FILTER_LIST:
            return{
                ...state,
                selectedFilterList : action.selectedFilterList
            }
        case SET_FILTER:
            return{
                ...state,
                filter : action.filter
            }
        case SET_DEFAULT_MIN_PRICE:
            return{
                ...state,
                defaultMinPrice : action.defaultMinPrice
            }
        case SET_DEFAULT_MAX_PRICE:
            return{
                ...state,
                defaultMaxPrice : action.defaultMaxPrice
            }
        case SET_RESET_FILTER:
            return{
                ...state,
                isResetFilter : action.isResetFilter
            }
        case SET_RESET_PRICE_FILTER:
            return{
                ...state,
                isResetPriceFilter : action.isResetPriceFilter
            }
        case SET_COMMON_SEARCH_KEYWORD:
            return{
                ...state,
                isSearchCommonKeyword : { key: action.commonSearchKeyword.key , value: action.commonSearchKeyword.value  , startDate: action.commonSearchKeyword.startDate}
            }
        case SET_PRICE_STATE:
            return {
                ...state,
                priceState : action.isPriceState
            }
        default:
            return state
    }
}
export default searchState;