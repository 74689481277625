import React from "react";
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';

const TopSwiper = ({ representImageUrl, additionalImageUrls }) => {

    return (
        <Swiper
            className={"pkg-pd-swiper circle-btn-type"}
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            spaceBetween={50}
            slidesPerView={1}
            navigation
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
        >
             {
                 representImageUrl && (
                     <SwiperSlide>{
                         representImageUrl !== 'http://image8.hanatour.com/product/img_no_M.gif'?
                             (
                               <span className="vis">
                                   <img src={representImageUrl.replace("/resize/400", "/resize/800")} alt="" />
                               </span>
                             ):
                             (
                                 <span className={"vis"}>
                                     <span className={"no-img"}></span>
                                 </span>
                             )
                     }</SwiperSlide>
                 )
             }
             {
                 additionalImageUrls &&  additionalImageUrls.map((image, index) => {
                     return (
                        <SwiperSlide key={image.imageUrl}>
                            {
                                image.imageUrl !== 'http://image8.hanatour.com/product/img_no_M.gif'?
                                    (
                                        <span className="vis">
                                            <img src={image.imageUrl.replace("/resize/400", "/resize/800")} alt="" />
                                        </span>
                                    ):
                                    (
                                        <span className={"vis"}>
                                            <span className={"no-img"}></span>
                                        </span>
                                    )
                            }
                        </SwiperSlide>
                     )
                 })
             }
        </Swiper>
    );
};

export default TopSwiper;