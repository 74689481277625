import {useRecoilValue} from "recoil";

import {
    serializeAirlineFilterAtom,
    serializeCityCodeAtom,
    serializeConditionFilterAtom,
    serializeContinentCodeAtom,
    serializeDepartureCityFilterAtom,
    serializeDepartureTimeFilterAtom,
    serializeEndDateFilterAtom,
    serializeGradeFilterAtom,
    serializeHotelFilterAtom,
    serializeNationCodeAtom,
    serializeOrderFilterAtom,
    serializePeriodFilterAtom,
    serializePriceFilterAtom,
    serializeProductTypeFilterAtom,
    serializeRepresentProductCodeAtom,
    serializeSearchKeywordAtom,
    serializeShopCountFilterAtom,
    serializeStartDateFilterAtom,
    serializeSupplierFilterAtom
} from "../../../state/ParentProductFilterState";


export default function useSerializeProductFilter() {
    const supplierCodes = useRecoilValue(serializeSupplierFilterAtom)
    const productTypes = useRecoilValue(serializeProductTypeFilterAtom)
    const period = useRecoilValue(serializePeriodFilterAtom)
    const departureCity = useRecoilValue(serializeDepartureCityFilterAtom)
    const departureTime = useRecoilValue(serializeDepartureTimeFilterAtom)
    const productGrade = useRecoilValue(serializeGradeFilterAtom)
    const hotelGrade = useRecoilValue(serializeHotelFilterAtom)
    const condition = useRecoilValue(serializeConditionFilterAtom)
    const price = useRecoilValue(serializePriceFilterAtom)
    const shopCount = useRecoilValue(serializeShopCountFilterAtom)
    const airline = useRecoilValue(serializeAirlineFilterAtom)
    const order = useRecoilValue(serializeOrderFilterAtom)
    const continentCode = useRecoilValue(serializeContinentCodeAtom)
    const nationMenuId = useRecoilValue(serializeNationCodeAtom)
    const cityCode = useRecoilValue(serializeCityCodeAtom)
    const startAt = useRecoilValue(serializeStartDateFilterAtom)
    const endAt = useRecoilValue(serializeEndDateFilterAtom)
    const representativeCode = useRecoilValue(serializeRepresentProductCodeAtom)
    const searchKeyword = useRecoilValue(serializeSearchKeywordAtom)

    const ret = []

    supplierCodes && ret.push(supplierCodes)
    productTypes && ret.push(productTypes)
    period && ret.push(period)
    departureCity && ret.push(departureCity)
    departureTime && ret.push(departureTime)
    productGrade && ret.push(productGrade)
    hotelGrade && ret.push(hotelGrade)
    condition && ret.push(condition)
    price && ret.push(price)
    shopCount && ret.push(shopCount)
    airline && ret.push(airline)
    order && ret.push(order)
    continentCode && ret.push(continentCode)
    nationMenuId && ret.push(nationMenuId)
    cityCode && ret.push(cityCode)
    startAt && ret.push(startAt)
    endAt && ret.push(endAt)
    representativeCode && ret.push(representativeCode)
    searchKeyword && ret.push(searchKeyword)

    return ret.join("&&")
}