import {useEffect, useState} from "react";
import {findCurrentUser} from "../../domain/repository/UserRepository";
import {useRecoilState} from "recoil";
import {userErrorState, userLoadingState, userState, UserType} from "../state/user/UserState";
import UserEntity from "../../domain/model/user/UserEntity";
import {couponState, ticketState} from "../state/user/PriviaDiscountState";
import {findCouponList, findTicketList} from "../../domain/repository/PriviaRepository";
import {findHccFamilyUsable, findMPoint, findVoucherList} from "../../domain/repository/HccCardRepository";
import {familyDiscountState, mPointState, voucherState} from "../state/user/HccCardDiscountState";
import {getCookie} from "../../../common/util/Cookie";


/*
* 프리비아 할인 정보를 가져오는 훅 (쿠폰, 티켓)
* */
export function useLoadPriviaDiscount(memberNumber) {
    const [ticketRecoil, setTicketRecoil] = useRecoilState(ticketState)
    const [couponRecoil, setCouponRecoil] = useRecoilState(couponState)
    const MAX_LOAD_TRY_COUNT = 3
    const [ticketLoadTryCount, setTicketLoadTryCount] = useState(0)
    const [couponLoadTryCount, setCouponLoadTryCount] = useState(0)
    // console.log("티켓 로드 시도> ", ticketLoadTryCount, "쿠폰 로드 시도>", couponLoadTryCount)
    useEffect(() => {
        if (ticketLoadTryCount >= MAX_LOAD_TRY_COUNT) return;
        if (!memberNumber) return;
        setTicketRecoil({
            ...ticketRecoil,
            loading: true,
            error: null
        })
        findTicketList(memberNumber).then((res) => {
            setTicketRecoil({
                ...ticketRecoil,
                loading: false,
                ticketList: res.data
            })
            if (res.data.length === 0) {
                setTicketLoadTryCount(ticketLoadTryCount + 1)
            }
            if (res.data.length !== 0) {
                setTicketLoadTryCount(MAX_LOAD_TRY_COUNT)
            }
        }).catch((err) => {
            setTicketRecoil({
                ...ticketRecoil,
                loading: false,
                error: err
            })
        })
    }, [memberNumber, ticketLoadTryCount]);

    useEffect(() => {
        if (couponLoadTryCount >= MAX_LOAD_TRY_COUNT) return;
        if (!memberNumber) return;
        setCouponRecoil({
            ...couponRecoil,
            loading: true,
            error: null
        })
        findCouponList(memberNumber).then((res) => {
            setCouponRecoil({
                ...couponRecoil,
                loading: false,
                couponList: res.data
            })
            if (res.data.length === 0) {
                setCouponLoadTryCount(couponLoadTryCount + 1)
            }
            if (res.data.length !== 0) {
                setCouponLoadTryCount(MAX_LOAD_TRY_COUNT)
            }

        }).catch((err) => {
            setCouponRecoil({
                ...couponRecoil,
                loading: false,
                error: err
            })
        })
    }, [memberNumber, couponLoadTryCount])


    return {
        ticketRecoil,
        couponRecoil
    }
}


/*
* 현대카드에 종속된 할인정보 가져오기(바우처,패밀리,m포인트)
* */
export function useLoadHccDiscount(memberId, encryptedHccNumber) {
    const [voucherRecoil, setVoucherRecoil] = useRecoilState(voucherState)
    const [mPointRecoil, setMPointRecoil] = useRecoilState(mPointState)
    const [familyDiscountRecoil, setFamilyDiscountRecoil] = useRecoilState(familyDiscountState)
    const MAX_COUNT = 3
    const [voucherLoadTryCount, setVoucherLoadTryCount] = useState(0)
    const [mPointLoadTryCount, setMPointLoadTryCount] = useState(0)
    const [familyLoadTryCount, setFamilyLoadTryCount] = useState(0)
    // console.log("바우처 로드 시도> ", voucherLoadTryCount, "m포인트 로드 시도>", mPointLoadTryCount, "패밀리 할인 로드 시도>", familyLoadTryCount)
    useEffect(() => {
        if (voucherLoadTryCount >= MAX_COUNT) return;

        const notLoadable = {
            ...voucherRecoil,
            loading: false,
            voucherList: []
        }
        if (!memberId) {
            setVoucherRecoil(notLoadable)
            return;
        }
        if (!encryptedHccNumber) {
            setVoucherRecoil(notLoadable)
            return;
        }
        setVoucherRecoil({
            ...voucherRecoil,
            loading: true,
            error: null
        })
        findVoucherList(memberId, encryptedHccNumber).then((res) => {
            setVoucherRecoil({
                ...voucherRecoil,
                loading: false,
                voucherList: res.data
            })
            if (res.data.length === 0) {
                setVoucherLoadTryCount(voucherLoadTryCount + 1)
            }
            if (res.data.length !== 0) {
                setVoucherLoadTryCount(MAX_COUNT)
            }
        }).catch((err) => {
            setVoucherRecoil({
                ...voucherRecoil,
                loading: false,
                error: err
            })
        })
    }, [memberId, encryptedHccNumber, voucherLoadTryCount]);

    useEffect(() => {
        if (mPointLoadTryCount >= MAX_COUNT) return;
        if (!memberId) return;
        if (!encryptedHccNumber) return;
        setMPointRecoil({
            ...mPointRecoil,
            loading: true,
            error: null
        })
        findMPoint(memberId, encryptedHccNumber).then((res) => {
            setMPointRecoil({
                ...mPointRecoil,
                loading: false,
                mPoint: res.data
            })
            if (res.data.isMPointCardYn) {
                setMPointLoadTryCount(MAX_COUNT)
            }
            if (!res.data.isMPointCardYn) {
                setMPointLoadTryCount(mPointLoadTryCount + 1)
            }
        }).catch((err) => {
            setMPointRecoil({
                ...mPointRecoil,
                loading: false,
                error: err
            })
        })
    }, [memberId, encryptedHccNumber, mPointLoadTryCount]);

    useEffect(() => {
        if (familyLoadTryCount >= MAX_COUNT) return;
        if (!memberId) return;
        if (!encryptedHccNumber) return;
        setFamilyDiscountRecoil({
            ...familyDiscountRecoil,
            loading: true,
            error: null
        })
        findHccFamilyUsable(memberId, encryptedHccNumber).then((res) => {
            setFamilyDiscountRecoil({
                ...familyDiscountRecoil,
                loading: false,
                familyDiscount: res.data
            })
            if (res.data.length === 0) {
                setFamilyLoadTryCount(familyLoadTryCount + 1)
            }
            if (res.data.length !== 0) {
                setFamilyLoadTryCount(MAX_COUNT)
            }
        }).catch((err) => {
            setFamilyDiscountRecoil({
                ...familyDiscountRecoil,
                loading: false,
                error: err
            })
        })
    }, [memberId, encryptedHccNumber, familyLoadTryCount])

    return {
        voucherRecoil,
        mPointRecoil,
        familyDiscountRecoil
    }
}

export default function useLoadUser() {
    const encryptedHccNumber = getCookie("hccMkusmnCustNo");
    const [loading, setLoading] = useRecoilState(userLoadingState)
    const [error, setError] = useRecoilState(userErrorState)
    const [userRecoil, setUserRecoil] = useRecoilState(userState)
    const {ticketRecoil, couponRecoil} = useLoadPriviaDiscount(userRecoil.memberNumber)
    const {
        voucherRecoil,
        mPointRecoil,
        familyDiscountRecoil
    } = useLoadHccDiscount(userRecoil.memberId, encryptedHccNumber)


    useEffect(() => {
        setLoading(true)
        setError(false)
        findCurrentUser().then((res => {
            setLoading(false)
            let tobeError = true
            if (res && res.data) {
                setUserRecoil({
                    ...res.data,
                    userType: UserType.IDENTIFY
                })
                tobeError = false
            }
            setError(tobeError)
        })).catch((err) => {
            setLoading(false)
            setError(true)
        })
    }, []);


    return {
        loading,
        error,
        user: new UserEntity(
            userRecoil,
            couponRecoil,
            ticketRecoil,
            voucherRecoil,
            mPointRecoil,
            familyDiscountRecoil
        )
    }
}
