import React, {useEffect} from "react";
import {shallowEqual, useSelector} from "react-redux";
import 'moment/locale/ko';

const ReservationPersonInfo = () => {

    let reservation = useSelector((state) => state.myPageReducer.reservation, shallowEqual);
    let paymentData = useSelector((state) => state.myPageReducer.paymentData, shallowEqual);

    useEffect(() => {
    }, []);

    return (
        <React.Fragment>
            {reservation &&
                <section className="book-detail-area">
                    <div className="pkg-tit-page-cont bb-line">
                        <h3 className="title">예약자 정보</h3>
                    </div>
                    <div className="book-article">
                        <table summary="예약자 정보" className="list-resv-tbl">
                            <caption>한글명, 연락처, 이메일 항목이 있는 예약자 정보 테이블</caption>
                            <colgroup>
                                <col className="list-resv-th" />
                                <col className="list-resv-td" />
                                <col className="list-resv-th" />
                                <col className="list-resv-td" />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>한글명</th>
                                    <td>{paymentData.reservationPersonInformation.memberName}</td>
                                    <th>연락처</th>
                                    <td>{paymentData.reservationPersonInformation.callNumber}</td>
                                </tr>
                                <tr>
                                    <th>이메일</th>
                                    <td colSpan="3">{paymentData.reservationPersonInformation.email}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </section>
            }
        </React.Fragment>
    );
};

export default ReservationPersonInfo;