import useLoadReservation from "../../loader/useLoadReservation";
import {useParams} from "react-router-dom";
import SaleProductEntity, {SaleProductAmountPriceType} from "../../../domain/model/product/SaleProductEntity";
import RepresentativeProductEntity from "../../../domain/model/product/RepresentativeProductEntity";
import useCoupon from "./benefit/useCoupon";
import useTicket from "./benefit/useTicket";
import useCreditCard from "./benefit/useCreditCard";
import useMPoint from "./benefit/useMPoint";
import useLoadEventUser from "./event/useLoadEventUser";
import {useEffect, useState} from "react";
import {EventBase} from "../../../infra/event/EventManager";
import {EVENT_NAMES} from "./event/events";
import {PaymentType} from "../../../domain/service/payment/PaymentEnum";
import {useRecoilState} from "recoil";
import {CardOptionDict, HccOption, setPaymentPriceState} from "../../state/reservation/PaymentPriceState";
import usePayment from "./info/usePayment";
import AmountPrice from "../../../domain/service/payment/AmountPrice";
import useVoucherV2 from "./benefit/useVoucherV2";
import {VOUCHER_TYPE} from "../../../domain/model/user/UserVoucherEntity";
import useHcc3 from "./benefit/useHcc3";
import useFamily from "./benefit/useFamily";
import {
    calculateReservationPriceByReservation,
    updateRepayReservation
} from "../../../domain/repository/ReservationRepository";
import {PRICE_TYPE} from "../../state/reservation/PriceSelectors";
import useLoadUser from "../../loader/useLoadUser";

export default function useRemindReservation(eventManager) {
    const userCtx = useLoadUser();
    const params = useParams();
    const [init, setInit] = useState(false)
    const [showBenefit, setShowBenefit] = useState(true) // 어쩔 수 없었다. 수기 결제했을 경우 할인 못보도록.
    const [paymentPrice, setPaymentPrice] = useRecoilState(setPaymentPriceState)
    const reservationCtx = useLoadReservation(params.reservationNumber)
    const [saleProductCtx, setSaleProductCtx] = useState({loading: true, error: null})
    const [representativeProductCtx, setRepresentativeProductCtx] = useState({loading: true, error: null})
    const [preSavedReservationNumber, setPreSavedReservationNumber] = useState(null)
    const [inicisCallNumber, setInicisCallNumber] = useState(0)
    const [inicisLoading, setInicisLoading] = useState(false)


    const couponCtx = useCoupon(eventManager)
    const ticketCtx = useTicket(eventManager)
    const creditCardCtx = useCreditCard(eventManager)
    const hcc3Ctx = useHcc3(eventManager)
    const familyCtx = useFamily(eventManager)
    const mPointCtx = useMPoint(eventManager);
    const paymentCtx = usePayment(eventManager)
    const redVoucherCtx = useVoucherV2({
        eventManager,
        voucherType: VOUCHER_TYPE.RED5
    })

    const redVoucher4Ctx = useVoucherV2({
        eventManager,
        voucherType: VOUCHER_TYPE.RED4
    })

    const redVoucherStripCtx = useVoucherV2({
        eventManager,
        voucherType: VOUCHER_TYPE.RED_STRIP
    })

    const greenVoucherCtx = useVoucherV2({
        eventManager,
        voucherType: VOUCHER_TYPE.GREEN
    })

    const summitVoucherCtx = useVoucherV2({
        eventManager,
        voucherType: VOUCHER_TYPE.SUMMIT
    })


    useLoadEventUser(eventManager)

    useEffect(() => {
        if (reservationCtx.loading) {
            return
        }
        if (init) {
            return
        }

        // console.log("으악.", reservationCtx)

        function createSaleProductCtx() {
            if (!reservationCtx || reservationCtx.loading) {
                return {
                    loading: true,
                    error: null,
                }
            }
            return {
                loading: false,
                error: null,
                saleProduct: new SaleProductEntity(
                    reservationCtx.saleProductData
                )
            }
        }
        function createRepresentativeProductCtx() {
            if (!reservationCtx || reservationCtx.loading) {
                return {
                    loading: true,
                    error: null,
                }
            }
            return {
                loading: false,
                error: null,
                representativeProduct: new RepresentativeProductEntity(
                    reservationCtx.representativeProductData
                )
            }
        }
        function createAmountDict(reservation) {
            const paymentData = reservation.paymentData
            const priceList = paymentData.priceList
            return priceList.reduce((acc, price) => {
                const data = {
                    target: price.ageDivision,
                    priceMap: {
                        [SaleProductAmountPriceType.PRICE]: price.priceAmount,
                        [SaleProductAmountPriceType.FUEL_SURCHARGE]: price.fuelSurchargeAmount,
                        [SaleProductAmountPriceType.TAX_DUTY]: price.taxAmount,
                    },
                    count: price.quantity
                }
                acc[price.ageDivision] = new AmountPrice(data)
                return acc
            }, {})
        }
        function getReservationPayCard(reservation) {
            const inicisData = reservation.inicisData[0]
            let paid = 0
            if (!inicisData || !inicisData.cardCode) {
                return {
                    isHcc: false,
                    paid
                }
            }

            const opt = CardOptionDict[inicisData.cardCode]
            if (!opt) {
                return {
                    isHcc: false,
                    paid
                }
            }
            paid = parseInt(inicisData.cardPaymentAmount) || 0
            return {
                isHcc: opt.value === HccOption.value,
                cardCode: opt.value,
                paid
            }
        }

        const newSaleProductCtx = createSaleProductCtx()
        setSaleProductCtx(newSaleProductCtx)
        const newRepresentativeProductCtx = createRepresentativeProductCtx()
        setRepresentativeProductCtx(newRepresentativeProductCtx)

        setPaymentPrice({
            prePaidPrice: reservationCtx.paymentData.actualPaymentAmount + reservationCtx.paymentData.totalBenefitsAmount,
            addAmount: reservationCtx.paymentData.addAmount,
            paymentType: PaymentType.PARTIAL_PAY,
            readOnlyAmount: true,
            amountDict: createAmountDict(reservationCtx),
            reservationPayCard: getReservationPayCard(reservationCtx),
        })

        function allInvalid() {
            hcc3Ctx.setInvalid()
            familyCtx.setInvalid()
            couponCtx.setInvalid()
            ticketCtx.setInvalid()
            greenVoucherCtx.setInvalid()
            summitVoucherCtx.setInvalid()
            redVoucher4Ctx.setInvalid()
            redVoucherCtx.setInvalid()
            redVoucherStripCtx.setInvalid()
            setShowBenefit(false)
        }

        if (reservationCtx.paymentData.isHccSaleUse) {
            allInvalid()
        }
        if (reservationCtx.paymentData.isHccFamilyUse) {
            allInvalid()
        }
        if (reservationCtx.paymentData.couponList.length > 0) {
            allInvalid()
        }
        if (reservationCtx.paymentData.ticketList.length > 0) {
            allInvalid()
        }
        if (reservationCtx.paymentData.voucherList.length > 0) {
            allInvalid()
        }

        if (reservationCtx.paymentData.isHccSaleUse) {
            allInvalid()
        }
        if (reservationCtx.paymentData.isHccFamilyUse) {
            allInvalid()
        }
        if (reservationCtx.paymentData.couponList.length > 0) {
            allInvalid()
        }
        if (reservationCtx.paymentData.ticketList.length > 0) {
            allInvalid()
        }
        if (reservationCtx.paymentData.voucherList.length > 0) {
            allInvalid()
        }


        setPreSavedReservationNumber(reservationCtx.reservationData.reservationNumber)
        eventManager.publish(new EventBase(EVENT_NAMES.LOAD_SALE_PRODUCT, newSaleProductCtx.saleProduct));
        eventManager.publish(new EventBase(EVENT_NAMES.LOAD_REPRESENTATIVE_PRODUCT, newRepresentativeProductCtx.representativeProduct))
        setInit(true)
    }, [reservationCtx.loading, init]);


    /**
     * 서버에서 가격 검증 하는 메서드
     * */
    async function checkServerPrice(reservationNumber, paymentData) {
        const priceDict = paymentCtx.priceDict
        const calRes = await calculateReservationPriceByReservation(reservationNumber, paymentData)
        if (calRes.status !== 200) {
            alert("서버와 연결이 불안정합니다. 다시 확인해 주세요.")
            return {
                valid: false
            }
        }
        const data = calRes.data
        if (!data) {
            alert("서버와 연결이 불안정합니다. 다시 확인해 주세요.")
            return {
                valid: false
            }
        }
        const sumDiscount = priceDict[PRICE_TYPE.SUM_BENEFIT_PRICE]
        if (data['totalBenefitAmount'] < sumDiscount) {
            alert("할인 금액이 변경 되었습니다. 다시 확인해 주세요.")
            return {
                valid: false
            }
        }
        return {
            valid: true
        }
    }

    async function savePreReservation() {
        if (creditCardCtx.cardCode === null) {
            alert("결제하실 카드를 선택해 주세요.")
            return
        }

        const inicisData = getInicisPayData()
        if (inicisData.productPrice < 100) {
            alert("최소 결제금액 100원 부터 결제 가능합니다.")
            return
        }

        if (!reservationCtx || reservationCtx.loading) {
            alert("결제 시도 실패 . 재시도 부탁 드립니다.")
            return
        }

        const paymentData = {
            ...paymentCtx.getSubmitData(),
        }

        let reservationInfoParam = {
            representativeCode: reservationCtx.representativeProductData.representProductCode,
            saleCode: reservationCtx.saleProductData.saleProductCode,
            reservationNumber: reservationCtx.reservationData.reservationNumber,
            paymentData: paymentData
        }
        setInicisLoading(true)
        const calRes = await checkServerPrice(reservationInfoParam.reservationNumber, reservationInfoParam.paymentData)
        if (!calRes.valid) {
            setInicisLoading(false)
            window.location.reload()
            return
        }

        const response = await updateRepayReservation(reservationInfoParam)
        let result = response.data;
        setInicisLoading(false)

        if (result.success === true) {
            setInicisCallNumber((prev) => (prev + 1))
            return reservationCtx.reservationData.reservationNumber
        }
        return
    }

    function getInicisPayData() {
        if (!reservationCtx || reservationCtx.loading) {
            return {
                inicisCallNumber,
                preSavedReservationNumber,
            }
        }

        return {
            inicisCallNumber,
            preSavedReservationNumber,
            inicisLoading,
            ...paymentCtx.getSubmitData(),
            ...paymentCtx.getInicisPayData(),
            buyerName: reservationCtx.paymentData.reservationPersonInformation.memberName,
            buyerTel: reservationCtx.paymentData.reservationPersonInformation.callNumber,
            buyerEmail: reservationCtx.paymentData.reservationPersonInformation.email,
            productName: reservationCtx.saleProductData ? reservationCtx.saleProductData.saleProductName : null,
            payStatus: '0',
        }
    }

    return {
        reservationCtx: {
            inicisPayData: getInicisPayData(),
            savePreReservation
        },
        saleProductCtx,
        representativeProductCtx,
        couponCtx,
        ticketCtx,
        creditCardCtx,
        mPointCtx,
        paymentCtx,
        redVoucherCtx,
        redVoucher4Ctx,
        redVoucherStripCtx,
        greenVoucherCtx,
        summitVoucherCtx,
        hcc3Ctx,
        familyCtx,
        showBenefit,
        userCtx
    }
}