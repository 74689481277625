import {UserType} from "../../../app/state/user/UserState";
import UserCouponEntity from "./UserCouponEntity";
import UserTicketEntity from "./UserTicketEntity";
import UserVoucherEntity from "./UserVoucherEntity";
import UserMPointEntity from "./UserMPointEntity";
import UserFamilyDiscountEntity from "./UserFamilyDiscountEntity";

export default class UserEntity {
    constructor(data, couponCtx, ticketCtx, voucherCtx, mPointCtx, familyCtx) {
        this.data = data;
        this.userCouponEntity = new UserCouponEntity(couponCtx);
        this.userTicketEntity = new UserTicketEntity(ticketCtx);
        this.userVoucherEntity = new UserVoucherEntity(voucherCtx);
        this.userMpointEntity = new UserMPointEntity(mPointCtx);
        this.userFamilyEntity = new UserFamilyDiscountEntity(familyCtx);
    }

    isValid() {
        return this.data.userType === UserType.IDENTIFY;
    }

    getMPointEntity() {
        return this.userMpointEntity;
    }

    getVoucherEntity() {
        return this.userVoucherEntity;
    }

    getCouponEntity() {
        return this.userCouponEntity;
    }

    getTicketEntity() {
        return this.userTicketEntity;
    }

    getFamilyEntity() {
        return this.userFamilyEntity;
    }

    isAnonymous() {
        if (!this.data) return true;
        return this.data.userType === UserType.NONE
    }

    isIdentify() {
        if (!this.data) return false;
        return this.data.userType === UserType.IDENTIFY
    }

    getMemberNumber() {
        if (!this.data) return null;
        return this.data.memberNumber;
    }

    getMemberId() {
        if (!this.data) return null;
        return this.data.memberId;
    }

    getPhoneNumber() {
        if (!this.data) return null;
        return this.data.callNumber;
    }

    getUserName() {
        if (!this.data) return null;
        return this.data.memberName;
    }

    getEmail() {
        if (!this.data) return null;
        return this.data.email;
    }



    getHccNumber(){
        if (!this.data) return null;
        return this.data.hccMyCustomNumber;
    }

    getHccGrade(){
        if (!this.data) return null;
        return this.data.hccGrade;
    }

}