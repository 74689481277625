import React, {useEffect} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {setErrorMessage, setDetailBannerList} from "../../redux/module/detail/DetailReducer";
import {getAxiosHeaders} from "../../common/util/Axios";
import {Link} from "react-router-dom";

const DetailBanner = () => {

    const dispatch = useDispatch();
    const serverUrl = process.env.REACT_APP_SERVER_URL;

    const getDetailBannerList = () => {
        axios.get(serverUrl + '/api/v1/product/banner?isPc=true',{
            headers: getAxiosHeaders()
        }).catch(function (error) {
            console.log(error.toJSON());
        }).then(function (response) {
            if (response) {
                dispatch(setDetailBannerList(response.data));
            } else {
                dispatch(setErrorMessage('error'));
            }
        });
    }

    useEffect(() => {
        getDetailBannerList();
    }, []);

    let detailBannerList = useSelector((state) => state.detailReducer.detailBannerList, shallowEqual);

    return (
        detailBannerList && detailBannerList.map((item, index) => {
            return (
                <div className="pd-detail-banner" key={item.imageUrl}>
                    <Link to={item.linkUrl}><img src={item.imageUrl} alt="이벤트 배너" /></Link>
                </div>
            )
        })
    );
};

export default DetailBanner;