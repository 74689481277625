import {useState} from "react";

export default function useRequestText() {
    const [requestText, setRequestText] = useState('')

    function getSubmitData() {
        return {
            request: requestText
        }
    }

    function onChange(value) {
        setRequestText(value);
    }

    return {
        requestText,
        getSubmitData,
        onChange
    }
}