import axios from "axios";
import {useEffect, useState} from "react";
import commonAPI from "./commonAPI";

const SkyScraper = () => {
    const serverUrl = process.env.REACT_APP_SERVER_URL;
    const [bannerData , setBannerData] = useState();

    useEffect(()=> {
        loadBanner().then(r => console.log(r));
    },[]);

    const loadBanner = async ()=>{
        let resData =
            await axios.get(serverUrl+`/api/v1/pc/skyscraper`,{ headers: {'Authorization' : commonAPI.AUTHROIZATION, 'Channel-Id': 'PRIVIA', 'Content-Type':'application/json'}})
                .then(res=>res.data)
                .catch(error=>console.log(error));
        setBannerData(resData);
    }

    const goBanner = (linkUrl) => {
        window.open(linkUrl);
    }

    return(
        <>
        {bannerData && (bannerData.pcSearchResultsUp || bannerData.pcSearchResultsDown) && (
            <div className="ui-skyScraper">
                <div className="layout-right">
                    {bannerData && bannerData.pcSearchResultsUp && bannerData.pcSearchResultsUp.map((banner, index) => (
                    <div className="section" key={`pcSearchResultsUp${index}`}>
                        <div className="vis">
                            <a style={{cursor:"pointer"}} onClick={() => {goBanner(banner.linkUrl)}} target="_blank">
                                <img src={banner.ImageUrl} alt={banner.title} />
                            </a>
                        </div>
                    </div>
                    ))
                    }
                    {bannerData && bannerData.pcSearchResultsDown && bannerData.pcSearchResultsDown.map((banner, index) => (
                    <div className="section" key={`pcSearchResultsDown${index}`}>
                        <div className="vis">
                            <a style={{cursor:"pointer"}} onClick={() => {goBanner(banner.linkUrl)}} target="_blank">
                                <img src={banner.ImageUrl} alt={banner.title} />
                            </a>
                        </div>
                    </div>
                    ))
                    }
                </div>
            </div>
        )}
        </>
    )
}
export default SkyScraper;