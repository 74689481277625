import axios from "axios";
import {getAxiosHeaders} from "../../../common/util/Axios";

export async function findCouponList(memberNumber) {
    const API_URL = process.env.REACT_APP_SERVER_URL;
    const url = `${API_URL}/api/v1/payment/${memberNumber}/coupon/issued`;
    return await axios.get(url, {
        headers: getAxiosHeaders(),
    });
}

export async function findTicketList(memberNumber) {
    const API_URL = process.env.REACT_APP_SERVER_URL;
    const url = `${API_URL}/api/v1/payment/tickets/${memberNumber}`;
    return await axios.get(url, {
        headers: getAxiosHeaders()
    });
}