import {useEffect, useState} from "react";
import useEvent from "../../../../infra/event/useEvent";
import {EVENT_NAMES} from "../event/events";
import usePaymentPrice from "../usePaymentPrice";
import {HccOption} from "../../../state/reservation/PaymentPriceState";
import {DiscountStep, DiscountType} from "../../../../domain/service/payment/PaymentEnum";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {
    getMPointDiscountTargetValue,
    initMPointSelector,
    isEnableMPointSelector,
    setMPointSelector,
    toggleMPointSelector
} from "../../../state/reservation/benefit/MPointSelector";
import {getDiscountAccByStep} from "../../../state/reservation/DiscountSelector";
import {UserType} from "../../../state/user/UserState";

export default function useMPoint(eventManager) {
    const {
        paymentPrice,
        cardOption,
        discountValueDict,
        user,
    } = usePaymentPrice()
    const mPoint = paymentPrice.mPoint
    const setMPoint = useSetRecoilState(setMPointSelector)
    const toggleMPointSel = useSetRecoilState(toggleMPointSelector)
    const isEnableMPoint = useRecoilValue(isEnableMPointSelector)
    const initMPoint = useSetRecoilState(initMPointSelector)
    const [userMPointEntity, setUserMPointEntity] = useState(null)
    const mDiscountPointTargetValue = getMPointDiscountTargetValue(paymentPrice, getDiscountAccByStep(paymentPrice, DiscountStep.THIRD_STEP))

    useEvent(eventManager, EVENT_NAMES.LOAD_M_POINT, (evt) => {
        const loadedUserMPointEntity = evt.getPayload()
        setUserMPointEntity(loadedUserMPointEntity)
    })

    useEffect(() => {
        if (user.type === UserType.NONE) {
            setMPoint({
                maxMPoint: 0,
                loaded: true,
            })
            return;
        }
        if (userMPointEntity === null) {
            return
        }
        setMPoint({
            maxMPoint: userMPointEntity.getMPoint(),
            loaded: userMPointEntity.isValid(),
        })
    }, [userMPointEntity, user.type])

    function toggleMPoint() {
        if (cardOption.value !== HccOption.value) {
            alert("현대카드 결제 시에만 사용할 수 있습니다.")
            return
        }
        if (mDiscountPointTargetValue === 0) {
            alert("할인 대상 금액이 없습니다.")
            return
        }
        toggleMPointSel()
    }

    function getSubmitData() {
        return {
            isPointUse: mPoint.enable && mPoint.useMPoint,
            usePoint: discountValueDict[DiscountType.MPoint],
        }
    }

    useEffect(() => {
        if (mDiscountPointTargetValue === 0) {
            initMPoint()
        }
    }, [mDiscountPointTargetValue]);


    return {
        isCheck: mPoint.useMPoint,
        enable: mPoint.loaded,
        maxPoint: mPoint.maxMPoint,
        maxPossibleMPoint: mPoint.maxMPoint - discountValueDict[DiscountType.MPoint],
        usingMPoint: discountValueDict[DiscountType.MPoint],
        percent: mPoint.percent,
        toggleMPoint,
        getSubmitData
    }
}
