import {useEffect, useState} from "react";
import useLoadArea from "../../../loader/useLoadArea";
import useLoadProductByKeyword from "../../../loader/useLoadProductByKeyword";
import useHref from "../../useHref";
import useDebounce from "../../../../infra/hooks/useDebounce";
import useCookie from "../../../../infra/hooks/useCookie";
import useDoubleCalendar from "./useDoubleCalendar";
import useProductFilter from "../parent/userProductFilter";
import useProductQueryParamFilter, {QueryFilterType} from "../parent/useProductQueryParamFilter";
import usePriviaUrl, {PRIVIA_URL} from "../../../../infra/hooks/usePriviaUrl";

export default function useProductPanel() {
    const COOKIE_KEY = "_search_v2"
    const MAX_SEARCH_HISTORY_SIZE = 4 // 최근 검색 최대 4개
    const {
        constValue: {allProductTypeList},
        toggle: {toggleProductTypeFilterList, toggleGradeFilterList},
        reset: {resetProductTypeFilterList, resetGradeFilterList},
        resetFilterAll
    } = useProductFilter()
    const {path, isUrl} = usePriviaUrl()
    const {
        gotoProductListByRepresentative,
        gotoProductList,
        gotoProductDetail,
        isUrlProductList
    } = useHref()

    const {
        continentList,
        isAreaLoading,
        isAreaLoadError
    } = useLoadArea()

    const {
        keyword,
        isLoadingProductByKeyword,
        loadProductByKeyword,
        searchByKeyword
    } = useLoadProductByKeyword()

    const {
        searchKeyword,
        continentCode,
        nationCode,
        cityCode,
        startDate,
        setQueryFilter,
        getParam,
        refreshFilter,
        endDate
    } = useProductQueryParamFilter()

    // 현재 이 상태값 하나로 검색 모드 인지 아니면 지역모드 판단한다.
    //const inputString = searchKeyword
    const [inputString, setInputStringState] = useState('')

    const [searchMode, setSearchMode] = useState('area')
    const [openProductPanel, setOpenProductPanel] = useState(false)
    const [panelMode, setPanelMode] = useState('simple')
    const [prePanelMode, setPrePanelMode] = useState(panelMode)
    const doubleCalendarState = useDoubleCalendar({
        startDate: startDate,
        endDate: endDate,
        setQueryFilter
    })

    const {getCookie, setCookie} = useCookie()
    const searchHistory = getCookie(COOKIE_KEY) || []
    const debounceInputString = useDebounce(inputString, 200)

    function setInputString(value) {
        const nextString = value || ''
        setQueryFilter(QueryFilterType.SEARCH_KEYWORD, nextString)
        setInputStringState(nextString)
    }

    /*
    * 컴포넌트에서 키 입력할 때 CONTINENT, NATION 초기화 해줘야함..
    * */
    function setInputStringForComponent(value) {
        const nextString = value || ''
        setInputStringState(nextString)
        setQueryFilter(QueryFilterType.SEARCH_KEYWORD, nextString)
        setQueryFilter(QueryFilterType.CONTINENT, null)
        setQueryFilter(QueryFilterType.NATION, null)
        setQueryFilter(QueryFilterType.CITY, null)
        setQueryFilter(QueryFilterType.REPRESENTATIVE, null)
    }

    function changePanelMode(mode) {
        setPrePanelMode(panelMode)
        setPanelMode(mode)
    }

    function revertPanelMode() {
        const currentPanelMode = panelMode
        setPanelMode(prePanelMode)
        setPrePanelMode(currentPanelMode)
    }


    /*
    * 프리미엄 에어텔 골프 허니문...
    * */
    function makeProductTypeItemList() {
        const ret = []
        ret.push({
            title: "프리미엄",
            value: "PREMIUM",
            type: "grade"
        })
        ret.push({
            title: "에어텔",
            type: "productType"
        })
        ret.push({
            title: "골프",
            type: "productType"
        })
        ret.push({
            title: "허니문",
            type: "productType"
        })

        return ret
    }

    function setContinentAndNation(continent, nation) {
        function getNationString(continent, nation) {
            if (!continent) return ''
            if (nation) {
                return nation.nationName
            }
            return continent.name
        }

        setSearchMode('area')
        const inputString = getNationString(continent, nation)
        setQueryFilter(QueryFilterType.CONTINENT, continent?.code)
        setQueryFilter(QueryFilterType.NATION, nation?.nationCode)
        setInputString(inputString)
        setOpenProductPanel(false)
    }

    function setSearchHistory(search) {
        const nextBase = searchHistory.filter(v => v.representativeProductName !== search.representativeProductName)
        let newSearchHistory = [...nextBase, search]
        while (newSearchHistory.length > MAX_SEARCH_HISTORY_SIZE) {
            newSearchHistory.shift()
        }
        setCookie(COOKIE_KEY, newSearchHistory)
    }


    /*
    * 모상품,자상품 클릭 했을 때
    * */
    function onClickProduct(product) {
        setInputString(product['name'])
        setSearchHistory({
                representativeProductName: product['name'],
                representativeCode: product['representProductCode'],
                saleCode: product['code'],
            }
        )

        const rpCode = product['representProductCode']
        const saleCode = product['code']
        setQueryFilter(QueryFilterType.REPRESENTATIVE, rpCode)
        setQueryFilter(QueryFilterType.SEARCH_KEYWORD, product['name'])
        // 어쩔 수 없었다.
        // getParam 쓰면 이전 상태로 param 만들어서

        // DT-26511 모상품 & 자상품은 searchKeyword 없도록 처리
        // const param = `searchKeyword=${product['name']}`
        const param = null;
        resetFilterAll(false, true)
        if (saleCode !== rpCode) {
            gotoProductDetail({
                representativeCode: rpCode,
                saleCode: saleCode
            }, param)
        } else {
            gotoProductListByRepresentative({
                representativeCode: rpCode
            }, param)
        }
        refreshFilter()
    }

    /*
    * 지역 클릭 했을 때
    * */
    function onClickAreaByKeyword(areaKeyword) {
        // {
        //     "continentCode": "159",
        //     "continentName": "중국/홍콩/대만/몽골",
        //     "nationCode": "161",
        //     "nationName": "홍콩/마카오"
        // },
        /*
        * {
             "city": "688|홍콩",
             "state": "홍콩/마카오",
             "country": "100045|중국",
             "saleProductCount": 10568,
             "keyworCityProductCount": 79
           }
        * */
        const splitCity = areaKeyword['city'].split('|')
        setSearchMode('keyword')
        setInputString(areaKeyword['state'])
        setOpenProductPanel(false)
        setQueryFilter(QueryFilterType.CITY, splitCity[0])
        setQueryFilter(QueryFilterType.REPRESENTATIVE, null)
    }

    /*
    * 검색 버튼 누를때
    * */
    function handleSubmit() {
        if (!cityCode && !continentCode && !nationCode) {
            return {
                error: true,
                errorMessage: "여행지를 입력해주세요"
            }
        }
        setSearchHistory({
            representativeProductName: inputString,
            continentCode: continentCode,
            nationCode: nationCode,
            startAt: doubleCalendarState.startAtDate,
            endAt: doubleCalendarState.endAtDate,
            startAtString: doubleCalendarState.startAtString,
            endAtString: doubleCalendarState.endAtString
        })

        let addParam = ''
        if (startDate === null) {
            addParam += `&&startAt=${doubleCalendarState.startAtDate}`
        }
        changePanelMode('simple')
        gotoProductList(getParam() + addParam)
        resetFilterAll(true, true)
        return {
            error: false,
            errorMessage: '',
        }
    }

    function changeInputString(keyword) {
        //   setOpenProductPanel(true)
        if (keyword === '') {
            setSearchMode('area')
            return
        }
        setSearchMode('keyword')
        loadProductByKeyword(keyword)
    }


    function handleSearchHistory(search) {
        resetFilterAll(false, true)
        setQueryFilter(QueryFilterType.CONTINENT, search.continentCode)
        setQueryFilter(QueryFilterType.NATION, search.nationCode)
        setQueryFilter(QueryFilterType.CITY, search.cityCode)
        setQueryFilter(QueryFilterType.REPRESENTATIVE, search.representativeCode)
        setQueryFilter(QueryFilterType.START_DATE, search.startAt)
        setQueryFilter(QueryFilterType.END_DATE, search.endAt)
        setInputString(search.representativeProductName)

        const newParam = Object.keys(search).map(key => {
            if (search[key] === null) return null
            if (search[key] === '') return null
            if (key === 'representativeProductName') {
                return `searchKeyword=${search[key]}`
            }
            return `${key}=${search[key]}`
        }).filter(p => p !== null).join('&&')


        if (search.saleCode && search.saleCode !== search.representativeCode) {
            gotoProductDetail({
                representativeCode: search.representativeCode,
                saleCode: search.saleCode
            }, newParam)
            refreshFilter()
            return
        }

        search.representativeCode && gotoProductListByRepresentative({representativeCode: search.representativeCode}, newParam)
        !search.representativeCode && gotoProductList(newParam)
        refreshFilter()
    }

    function toggleProductPanelMode() {
        // 토글 될 때 창 닫혀줌
        doubleCalendarState.setOpenCalendar(false)
        setOpenProductPanel(false)
        if (panelMode === 'detail') {
            changePanelMode('simple')
            setPanelMode('simple')
        }
        if (panelMode === 'simple') {
            changePanelMode('detail')
        }

    }


    /*
    * 프리미엄, 에어텔 같은 상품 타입 클릭 했을 때
    * */
    function onClickProductTypeItem(productTypeItem) {
        resetFilterAll(false, true)
        if (productTypeItem.type === "productType") {
            toggleProductTypeFilterList(productTypeItem.title)
        }
        if (productTypeItem.type === "grade") {
            toggleGradeFilterList(productTypeItem.value)
        }
        if (isUrlProductList()) return
        gotoProductList()
        refreshFilter()
    }


    useEffect(() => {
        changeInputString(debounceInputString)
    }, [debounceInputString]);

    useEffect(() => {
        // 상품 상세에서는 검색어가 사라져야함..
        if (!isUrl(PRIVIA_URL.PRODUCT_DETAIL)) {
            setInputString(searchKeyword)
        }
    }, [path, searchKeyword])

    return {
        openProductPanel,
        setContinentAndNation,
        setOpenProductPanel,
        queryParam: {
            searchKeyword,
            startDate,
            endDate
        },
        continentList,
        isAreaLoading,
        isAreaLoadError,
        isLoadingProductByKeyword,
        mode: searchMode,
        inputString,
        keyword,
        searchByKeyword,
        setInputString: setInputStringForComponent,
        onClickProduct,
        onClickAreaByKeyword,
        handleSubmit,
        doubleCalendarState,
        searchHistory,
        panelMode,
        setPanelMode,
        toggleProductPanelMode,
        changePanelMode,
        revertPanelMode,
        handleSearchHistory,
        productTypeItemList: makeProductTypeItemList(),
        onClickProductTypeItem,
    }

}