import React from "react";

export default function MenuProductTypeList({
                                                itemList,
                                                onClickItem
                                            }) {

    return (
        <ul className="hss-sub">
            {
                itemList.map((item, index) => {
                    return (
                        <li key={index}>
                            <span className="btn-base btn-color-wh">
                                <a
                                    style={{cursor: "pointer"}}
                                    onClick={event => onClickItem(item)}
                                    data-tabsub="activity">{item.title}</a>
                            </span>
                        </li>
                    )
                })
            }

            {/*{mainSelector.resData && mainSelector.resData.pcPictograms && mainSelector.resData.pcPictograms.map((pictogram, index) => {*/}
            {/*    return (*/}
            {/*        <li key={index}><span className="btn-base btn-color-wh"><a*/}
            {/*            style={{cursor: "pointer"}}*/}
            {/*            onClick={event => window.location.href = pictogram.linkUrl + "&isPictogram=true"}*/}
            {/*            data-tabsub="activity">{pictogram.title}</a></span></li>*/}
            {/*    )*/}
            {/*})}*/}
        </ul>
    )
}