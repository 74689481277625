import {SaleProductAmountPriceType, SaleProductAmountTarget} from "../../model/product/SaleProductEntity";

export const PaymentType = Object.freeze({
    ALL_PAY: "ALL_PAY",// 전액 -> 1차 2차 결제. 끝 엠포인트 한번 적용 가능하자.
    RESERVATION_PAY: "RESERVATION_PAY", // 예약금
    PARTIAL_PAY: "PARTIAL_PAY", // 잔금 = 전액 - 예약금
})


export const DiscountStep = Object.freeze({
    ZERO_STEP: 0, // 할인 없음
    FIRST_STEP: 1,// 첫번째 할인
    SECOND_STEP: 2, // 두번째 할인
    THIRD_STEP: 3, // 세번째 할인
    FOURTH_STEP: 4, // 네번째 할인
})

export const DiscountType = Object.freeze({
    Coupon: "Coupon", // 쿠폰 전부.
    GreenVoucher: "GreenVoucher",
    RedVoucher: "RedVoucher",
    RedVoucher4: "RedVoucher4",
    SummitVoucher: "SummitVoucher",
    RedVoucherStrip: "RedVoucherStrip",
    FamilyDiscount: "FamilyDiscount",
    HCC3Discount: "HCC3Discount",
    Ticket: "Ticket",
    MPoint: "MPoint",
})

export const INIT_DISCOUNT_VALUE = Object.keys(DiscountType).reduce((acc, key) => {
    acc[key] = 0;
    return acc;
}, {})