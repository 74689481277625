import axios from "axios";
import commonAPI from "../../../common/commonAPI";


export async function getAreaList() {
    let API_URL = process.env.REACT_APP_SERVER_URL;
    // 왜 REACT_APP_API_URL 주입 못받지?
    // API_URL = "https://dev-package-api.tidesquare.com"
    // API_URL = "https://local.priviatravel.com:8181"

    const headers = {
        'Authorization': commonAPI.AUTHROIZATION,
        'Channel-Id': 'PRIVIA',
        'Content-Type': 'application/json'
    }
    try {
        return await axios.post(`${API_URL}/api/v1/area`, null, {headers})
    } catch (e) {
        throw e
    }
}