import {createContext, useContext} from "react";

import CreateReservationHeader from "../../component/reservation/CreateReservationHeader";
import ReservationPersonQuantityPanel from "../../component/reservation/payment-panel/ReservationPersonQuantityPanel";
import ReservationProductSummaryCard from "../../component/reservation/ReservationProductSummaryCard";
import ReservationPersonInfo from "../../component/reservation/ReservationPersonInfo";
import TravelPersonInfo from "../../component/reservation/TravelPersonInfo";
import RequirementTextBox from "../../component/reservation/RequirementTextBox";
import PaymentMethodList from "../../component/reservation/PaymentMethodList";
import MPointSection from "../../component/reservation/MPointSection";
import useCreateReservation from "../../../usecase/reservation/useCreateReservation";
import BenefitDiscount from "../../component/reservation/benefit-discount";
import SelectCreditCard from "../../component/reservation/SelectCreditCard";
import TermsOfUse from "../../component/reservation/TermsOfUse";
import EventManager from "../../../../infra/event/EventManager";
import {PaymentType} from "../../../../domain/service/payment/PaymentEnum";


// 전역으로 사용할 수 있는 이벤트 매니저 생성
const eventManager = new EventManager();

// 이벤트 매니저 컨텍스트 생성
const EventManagerContext = createContext(eventManager);
export default function CreateReservationContainer() {
    return (
        <EventManagerContext.Provider value={eventManager}>
            <RootContainer/>
        </EventManagerContext.Provider>
    )
}

function RootContainer() {
    const eventManager = useContext(EventManagerContext);

    const {
        paymentCtx,
        saleProductCtx,
        couponCtx,
        //  voucherCtx,
        ticketCtx,
        creditCardCtx,
        termsCtx,
        representativeProductCtx,
        reservationPersonCtx,
        travelPersonCtx,
        reservationCtx,
        mPointCtx,
        hcc3Ctx,
        familyCtx,
        requestTextCtx,
        redVoucherCtx,
        greenVoucherCtx,
        redVoucher4Ctx,
        redVoucherStripCtx,
        summitVoucherCtx,
        userCtx,
    } = useCreateReservation(eventManager)

    return (
        <div className={"w-content-sec"}>
            <CreateReservationHeader/>

            <div className="pkg-book-sec ly-inner clear_fix">
                <ReservationPersonQuantityPanel
                    paymentCtx={paymentCtx}
                    reservationCtx={reservationCtx}
                    saleProductCtx={saleProductCtx}
                    revEmail={reservationPersonCtx.email}
                />

                <div className="pkg-left-inner">
                    <ReservationProductSummaryCard
                        representativeProductCtx={representativeProductCtx}
                        saleProductCtx={saleProductCtx}
                    />

                    <ReservationPersonInfo reservationPersonCtx={reservationPersonCtx} />

                    <TravelPersonInfo
                        paymentCtx={paymentCtx}
                        travelPersonCtx={travelPersonCtx}
                        userType={"USER"} // 일단 비회원 생각 X
                    />

                    <RequirementTextBox requestTextCtx={requestTextCtx}/>

                    <PaymentMethodList paymentCtx={paymentCtx}/>

                    {paymentCtx.paymentType === PaymentType.ALL_PAY &&
                        <BenefitDiscount
                            redVoucher4Ctx={redVoucher4Ctx}
                            redVoucherStripCtx={redVoucherStripCtx}
                            summitVoucherCtx={summitVoucherCtx}
                            paymentCtx={paymentCtx}
                            couponCtx={couponCtx}
                            ticketCtx={ticketCtx}
                            hcc3Ctx={hcc3Ctx}
                            familyCtx={familyCtx}
                            redVoucherCtx={redVoucherCtx}
                            greenVoucherCtx={greenVoucherCtx}
                        />
                    }

                    <MPointSection
                        mPointCtx={mPointCtx}
                        paymentCtx={paymentCtx}
                        userCtx={userCtx}
                    />

                    <SelectCreditCard creditCardCtx={creditCardCtx}/>

                    <TermsOfUse termsCtx={termsCtx}/>
                </div>
            </div>
        </div>
    )
}


