import React from "react";
import moment from "moment/moment";
import {formatNumber} from "../../../../../utils/formatNumber";
import {goMainPage} from "../../../../../common/util/RedirectLogin";
import {getVoucherTitleViewString} from "../../../usecase/reservation/benefit/useVoucherV2";


export default function ReservationCompleteComponent({
                                                         loading,
                                                         reservation,
                                                         saleProduct,
                                                         representativeProduct,
                                                         memberNumber,
                                                         priviaUrl,
                                                         paymentData,
                                                     }) {

    if (loading) {
        return <Loading/>
    }

    const handleLinkUrlSaleDetail = () => {
        window.location.href = `/representative/${saleProduct.representProductCode}/sale/${saleProduct.saleProductCode}/detail`
    }

    const goReservationView = () => {
        //비회원인 경우
        if (memberNumber) {
            //회원인 경우
            window.location.href = "/myPage/" + reservation.reservationNumber + "/detail";
        } else {
            window.location.href = priviaUrl + "/mypage/index/reserve";
        }
    }

    return (
        <div className="w-content-sec">
            <div className="pkg-tit-page ly-inner">
                <h2 className="title">해외패키지 결제완료</h2>
            </div>
            <div className="pkg-resv-sec ly-inner">
                <section className="book-detail-area a-mt50">
                    <div className="pkg-tit-page-cont bb-line between-box fc">
                        <h3 className="title">주문상품</h3>
                        <div className="book-code">
                            <p className="pd-nav">
                                <span>예약번호 {reservation.reservationNumber}</span>
                                <span>{saleProduct.supplierName} 예약번호 {reservation.reservationCode}</span>
                                <span>예약일 {moment(reservation.reservationDayTime).format("YYYY/MM/DD(dd)")}</span>
                            </p>
                        </div>
                    </div>

                    <div className="book-article border-bottom">
                        <div className="book-pd-info-part">
                                        <span className="vis off-no-img">
                                            <a style={{cursor: "pointer"}} onClick={handleLinkUrlSaleDetail}>
                                                <img
                                                    src={representativeProduct && representativeProduct.representImageUrl ? representativeProduct.representImageUrl : ""}
                                                    alt=""/>
                                            </a>
                                        </span>
                            <div className="pd-info">
                                <div className="wfull">
                                    <p className="pd-nav">
                                        <span>{saleProduct.supplierName}</span>
                                        <span>상품코드 {saleProduct.saleProductCode}</span>
                                    </p>
                                    <div className="pd-title"><a style={{cursor: "pointer"}}
                                                                 onClick={handleLinkUrlSaleDetail}>{saleProduct.saleProductName}</a>
                                    </div>
                                </div>
                                <p className="pd-resv-seleted">
                                    {
                                        saleProduct && moment(saleProduct.departureDay).format("MM/DD(dd)")} {saleProduct && moment(saleProduct.departureTime).format("HH:mm")}
                                    &nbsp;~ {saleProduct && moment(saleProduct.arrivalDay).format("MM/DD(dd)")} {saleProduct && moment(saleProduct.arrivalTime).format("HH:mm")}
                                    &nbsp;({saleProduct && saleProduct.travelNightCount}박{saleProduct && saleProduct.travelDayCount}일)
                                    <br/>인원 :
                                    성인 {paymentData.priceList.find(price => price.ageDivision === "ADULT").quantity} /
                                    아동 {paymentData.priceList.find(price => price.ageDivision === "CHILDREN").quantity} /
                                    유아 {paymentData.priceList.find(price => price.ageDivision === "INFANT").quantity}
                                </p>
                                {
                                    reservation.reservationStep && reservation.reservationStep !== "CANCEL" &&
                                    saleProduct && saleProduct.reservationDivision === 'RESERVATION_CONFIRM' && (
                                        <p className="state"><span className="c-point5 a-bold">출발확정</span> (최소
                                            출발 : 성인 {saleProduct.minDepartureNumberOfPerson}명)</p>
                                    )
                                }
                            </div>
                        </div>
                        <div className="book-pd-price-part">
                            <span className="price-tt">총 상품 금액</span>
                            <span className="price"><em>{formatNumber(paymentData.totalAmount)}</em>원</span>
                        </div>
                    </div>
                </section>

                <section className="book-detail-area">
                    <div className="pkg-tit-page-cont bb-line">
                        <h3 className="title">결제정보</h3>
                    </div>
                    <div className="book-article border-bottom">
                        {
                            paymentData.isPayAll ?
                                <ul className="payment-info-ul">
                                    <li>
                                        <i className="pay-icons-bl minus"></i>
                                        <div className="pm-info-header between-box fc">
                                            <div className="pm-info-tt">
                                                상품 금액
                                            </div>
                                            <div className="sub-total-amt">
                                               <span
                                                   className="price a-bold"><em>{formatNumber(paymentData && (paymentData.totalSalePrice))}</em>원</span>
                                            </div>
                                        </div>

                                        {paymentData && paymentData.etcAmountList && paymentData.etcAmountList.length > 0 && paymentData.etcAmountList[0].amount > 0 && (
                                            <div className="price-wrap bnf-price-list">
                                                <div className="between-box fc inner-price-li">
                                                    <div className="price_tt">추가 금액
                                                        <div className="help-tooltip pkg-st small">
                                                            <span className="over">?</span>
                                                            <div className="cont overcharge" style={{width: 350}}>
                                                                <ul className="plus-charge-desc">
                                                                    {paymentData.etcAmountList.map((item, index) => (
                                                                        <li className="between-box ft"
                                                                            key={`etcAmount${index}`}>
                                                                            <span>{item.etcAmountTitle}</span>
                                                                            <span
                                                                                className="wfix a-right">{formatNumber(item.amount)}원</span>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <span
                                                        className="price"><em>{formatNumber(paymentData.addAmount)}</em>원</span>
                                                </div>
                                            </div>
                                        )}
                                    </li>
                                    <li>
                                        <i className="pay-icons-bl"></i>
                                        <div className="pm-info-header between-box fc">
                                            <div className="pm-info-tt">
                                                혜택/할인 금액
                                            </div>
                                            <div className="sub-total-amt">
                                               <span
                                                   className="price a-bold"><em>{formatNumber(paymentData.totalBenefitsAmount)}</em>원</span>
                                            </div>
                                        </div>
                                        <div className="price-wrap bnf-price-list">
                                            <VoucherSpan voucherList={paymentData.voucherList}/>

                                            {paymentData.ticketList && paymentData.ticketList.length !== 0 &&
                                                <div className="between-box fc inner-price-li">
                                                    <p className="price_tt">{"PRIVIA 이용권 (총" + paymentData.ticketList.length + "장)"}</p>
                                                    <span
                                                        className="price"><em>{formatNumber(-1 * paymentData.ticketList.at(0).useAmount)}</em>원</span>
                                                </div>
                                            }
                                            {paymentData.couponList && paymentData.couponList.length !== 0 &&
                                                <div className="between-box fc inner-price-li">
                                                    <p className="price_tt">{"PRIVIA 쿠폰 (총" + paymentData.couponList.length + "장)"}</p>
                                                    <span
                                                        className="price"><em>{formatNumber(-1 * paymentData.couponList.map(c => c.useAmount).reduce((prev, current) => prev + current, 0))}</em>원</span>
                                                </div>
                                            }
                                            {paymentData.isHccSaleUse && paymentData.hccSale > 0 &&
                                                <div className="between-box fc inner-price-li">
                                                    <p className="price_tt">현대카드 3% 할인</p>
                                                    <span
                                                        className="price"><em>{formatNumber(-1 * paymentData.hccSale)}</em>원</span>
                                                </div>
                                            }
                                            {paymentData.isHccFamilyUse && paymentData.hccFamilySale > 0 &&
                                                <div className="between-box fc inner-price-li">
                                                    <p className="price_tt">현대카드 패밀리 2% 할인</p>
                                                    <span
                                                        className="price"><em>{formatNumber(-1 * paymentData.hccFamilySale)}</em>원</span>
                                                </div>
                                            }
                                        </div>
                                    </li>
                                    <li>
                                        <div className="pm-info-header between-box fc">
                                            <div className="pm-info-tt">
                                                총 결제금액
                                            </div>
                                            <div className="big-total-amt">
                                               <span
                                                   className="price"><em>{paymentData && formatNumber(paymentData.actualPaymentAmount)}</em>원</span>
                                            </div>
                                        </div>
                                        <div className="price-wrap">
                                            {paymentData && paymentData.usePoint > 0 &&
                                                <div className="between-box fc inner-price-li point-price">
                                                    <div className="price_tt">M포인트 <span
                                                        className="c-point1">10%</span> 사용
                                                    </div>
                                                    <span
                                                        className="price c-point1"><em>{paymentData && formatNumber(-1 * Math.floor(paymentData.usePoint))}</em>원</span>
                                                    <p className="desc fs14">※ M포인트 차감된 금액으로 청구됩니다.</p>
                                                </div>
                                            }
                                        </div>
                                    </li>
                                </ul>
                                :
                                <ul className="payment-info-ul">
                                    <li>
                                        <i className="pay-icons-bl minus"></i>
                                        <div className="pm-info-header between-box fc">
                                            <div className="pm-info-tt">
                                                상품 금액
                                            </div>
                                            <div className="sub-total-amt">
                                               <span
                                                   className="price a-bold"><em>{formatNumber(paymentData && (paymentData.totalAmount))}</em>원</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <i className="pay-icons-bl"></i>
                                        <div className="pm-info-header between-box fc">
                                            <div className="pm-info-tt">
                                                결제한 금액
                                            </div>
                                            <div className="sub-total-amt">
                                               <span
                                                   className="price a-bold"><em>{formatNumber(paymentData.totalPaymentAmount)}</em>원</span>
                                            </div>
                                        </div>

                                        <div className="price-wrap">
                                            {paymentData && paymentData.usePoint > 0 &&
                                                <div className="between-box fc inner-price-li point-price">
                                                    <div className="price_tt">M포인트 <span
                                                        className="c-point1">10%</span> 사용
                                                    </div>
                                                    <span
                                                        className="price c-point1"><em>{paymentData && formatNumber(-1 * Math.floor(paymentData.usePoint))}</em>원</span>
                                                    <p className="desc fs14">※ M포인트 차감된 금액으로 청구됩니다.</p>
                                                </div>
                                            }
                                        </div>
                                    </li>
                                    <li>
                                        <div className="pm-info-header between-box fc">
                                            <div className="pm-info-tt">
                                                남은 결제금액
                                            </div>
                                            <div className="big-total-amt">
                                               <span
                                                   className="price"><em>{formatNumber(paymentData.totalAmount - paymentData.totalPaymentAmount)}</em>원</span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                        }
                    </div>
                </section>

                <div className="btn-wrap a-center a-mt80">
                                <span className="btn-base btn-st-bfull btn-line-bl w250 h50">
                                    <a style={{cursor: "pointer"}} onClick={goReservationView}>예약상세 보기</a>
                                </span>
                    <span className="btn-base btn-st-bfull btn-bg-bl w250 h50">
                                    <a style={{cursor: "pointer"}} onClick={goMainPage}>해외패키지 메인 가기</a>
                                </span>
                </div>
            </div>
        </div>
    )
}


function Loading() {
    return (
        <React.Fragment>
            <div className="loading-dimm" style={{display: 'block', zIndex: 9999}}></div>
            <div className="loading-pop" style={{display: 'block', zIndex: 99999}}>
                <div className="loading-cont">
                    <div className="loading-pkg">
                        <div className="loading-bar"></div>
                        <img className="pkg-pd-loading-icon"
                             src="//static.priviatravel.com/images/front/travel/svg/ico-pkg-bnf-card.svg"
                             alt="카드혜택 아이콘"/>
                        <div className="loading-title">
                            최대 <span className="c-point1">13%</span> 카드 결제 혜택
                        </div>
                        <div className="loading-desc">
                            최대 3% 즉시 할인 + 10% M포인트 사용
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

function VoucherSpan({voucherList}) {
    if (!voucherList) {
        return null
    }

    if (voucherList.length === 0) {
        return null
    }

    const voucher = voucherList.at(0)

    let label = getVoucherTitleViewString(voucher.hccTicketUseDivision, voucherList.length)
    const price = voucherList.map(c => c.hccTicketAmount).reduce((prev, current) => prev + current, 0)

    return (
        <div className="between-box fc inner-price-li">
            <p className="price_tt">{label}</p>
            <span className="price"><em>{formatNumber(-1 * price)}</em>원</span>
        </div>
    )
}

