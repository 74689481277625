import BenefitDiscountLayout from "./BenefitDiscountLayout";
import BenefitDiscountItem from "./BenefitDiscountItem";
import CouponModalContent from "./CouponModalContent";
import VoucherModalContent from "./VoucherModalContent";
import TicketModalContent from "./TicketModalContent";
import CheckBoxItem from "./CheckBoxItem";
import React from "react";


export default function BenefitDiscount({
                                            paymentCtx,
                                            couponCtx,
                                            summitVoucherCtx,
                                            ticketCtx,
                                            hcc3Ctx,
                                            familyCtx,
                                            redVoucherCtx,
                                            greenVoucherCtx,
                                            redVoucher4Ctx,
                                            redVoucherStripCtx,
                                        }) {
    const {priceInfo} = paymentCtx
    // 할인 대상 금액이 0원이면 렌더링 하지 않음.
    if (priceInfo.rootTargetPrice <= 0) {
        return null
    }


    return (
        <BenefitDiscountLayout sumBenefitValue={priceInfo.totalDiscount}>
            <VoucherItem voucherCtx={redVoucherCtx}/>
            <VoucherItem voucherCtx={redVoucher4Ctx}/>
            <VoucherItem voucherCtx={redVoucherStripCtx}/>
            <VoucherItem voucherCtx={greenVoucherCtx}/>
            <VoucherItem voucherCtx={summitVoucherCtx}/>


            <BenefitDiscountItem
                enable={couponCtx.enable}
                title={couponCtx.title}
                modalTitle={"PRIVIA 쿠폰"}
                adaptedMoney={couponCtx.adaptedMoney}
                onApply={couponCtx.onApplyCoupon}
                onClose={couponCtx.onCloseCoupon}
                onOpen={couponCtx.onOpen}
            >
                <CouponModalContent
                    couponOptionList={couponCtx.options}
                    onSelectCouponOption={couponCtx.onSelectCoupon}
                />
            </BenefitDiscountItem>


            <BenefitDiscountItem
                enable={ticketCtx.enable}
                title={ticketCtx.title}
                modalTitle={ticketCtx.modalTitle}
                adaptedMoney={ticketCtx.adaptedMoney}
                onApply={ticketCtx.onApply}
                onClose={ticketCtx.onClose}
                onOpen={ticketCtx.onOpen}
            >
                <TicketModalContent {...ticketCtx}/>
            </BenefitDiscountItem>

            <CheckBoxItem {...hcc3Ctx} />
            <CheckBoxItem {...familyCtx}/>


        </BenefitDiscountLayout>
    )
}


function VoucherItem({voucherCtx}) {
    return (
        <BenefitDiscountItem
            enable={voucherCtx.enable}
            title={voucherCtx.title}
            modalTitle={voucherCtx.name}
            adaptedMoney={voucherCtx.adaptedMoney}
            onApply={voucherCtx.onApply}
            onClose={voucherCtx.onClose}
            onOpen={voucherCtx.onOpen}
        >
            <VoucherModalContent
                voucherType={voucherCtx.voucherType}
                title={voucherCtx.modalTitle}
                voucherList={voucherCtx.optionList}
                totalUseVoucherAmount={voucherCtx.totalUseVoucherAmount}
                selectedVoucherDict={voucherCtx.selectedVoucherDict}
                onCheck={voucherCtx.onCheck}
            />
        </BenefitDiscountItem>
    )
}