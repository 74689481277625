import React, {useState} from "react";
import useMoment from "../../../../../infra/hooks/moment/useMoment";
import SinglePartCalendar from "./SinglePartCalendar";


/**
 * export interface Props {
 *     onChangeRange?: (range: Day[]) => void
 * }
 * */
export default function DoubleCalendar(props) {
    const {moment, today} = useMoment();
    const [prevCalendarDay, setPrevCalendarDay] = useState(moment.getToday());
    const [nextCalendarDay, setNextCalendarDay] = useState(moment.getNextMonth(prevCalendarDay, 1));
    const [rangeDay, setRangeDay] = useState([]);

    if (!props.show) return null

    function onSelectRange(e) {
        let range = [...rangeDay];
        if (rangeDay.length === 0) {
            range.push(today)
            range.push(today)
        }
        if (rangeDay.length === 1) {
            range.push(rangeDay[0])
        }
        props.onSubmitRange && props.onSubmitRange(range)
    }

    function onChangeRange(days) {
        setRangeDay([...days]);
    }

    function onClickPrev(e) {
        if (isPrevMonth()) {
            setPrevCalendarDay(moment.getPrevMonth(prevCalendarDay, 1));
            setNextCalendarDay(moment.getPrevMonth(nextCalendarDay, 1));
        }
    }

    function isNextMonth() {
        function getMaxDay() {
            return moment.getNextMonth(moment.getToday(), 12);
        }

        const maxDay = getMaxDay();
        const toNextCalendarDay = moment.getNextMonth(nextCalendarDay, 1);
        return !moment.isNext(toNextCalendarDay, maxDay);
    }

    function isPrevMonth() {
        function getMinDay() {
            return moment.getToday();
        }

        const minDay = getMinDay();
        const toPrevCalendarDay = moment.getPrevMonth(prevCalendarDay, 1);
        return !moment.isPrev(toPrevCalendarDay, minDay);
    }

    function onClickNext(e) {
        if (isNextMonth()) {
            setNextCalendarDay(moment.getNextMonth(nextCalendarDay, 1));
            setPrevCalendarDay(moment.getNextMonth(prevCalendarDay, 1));
        }
    }

    return (
        <div className={"sc-ui-search-panel ui-date-calendar on"}>
            <div className="uis-calendar">
                <div className="uis-datepicker hasDatepicker">
                    <div
                        className="ui-datepicker-inline ui-datepicker ui-widget ui-widget-content ui-helper-clearfix ui-corner-all ui-datepicker-multi-2 ui-datepicker-multi"
                        style={{width: "34em", display: "block"}}>

                        <SinglePartCalendar day={prevCalendarDay} rangeDay={rangeDay} onChangeRange={onChangeRange}>
                            <a className={`ui-datepicker-prev ui-corner-all ${!isPrevMonth() && "ui-state-disabled"}`}
                               title="이전달"
                               onClick={onClickPrev}>
                                <span className="ui-icon ui-icon-circle-triangle-w">이전달</span>
                            </a>
                        </SinglePartCalendar>

                        <SinglePartCalendar day={nextCalendarDay} rangeDay={rangeDay} onChangeRange={onChangeRange}>
                            <a className={`ui-datepicker-next ui-corner-all ${!isNextMonth() && "ui-state-disabled"} `}
                               data-handler="next" data-event="click" title="다음달" onClick={onClickNext}>
                                <span className="ui-icon ui-icon-circle-triangle-e">다음달</span>
                            </a>
                        </SinglePartCalendar>

                        <div className="ui-datepicker-row-break"></div>
                    </div>
                </div>
            </div>

            <div className="btn-wrap a-right">
                <span className="btn-base btn-st-sfull btn-bg-pkg btn-complete">
                    <button type="button" onClick={onSelectRange}>선택완료</button>
                </span>
            </div>
        </div>
    );
}
