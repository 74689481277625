import React, {useEffect} from "react";
import {Sheet} from "@mui/joy";
import ChildProductCard from "./ChildProductCard";
import useListChildProduct from "../../../usecase/product-list/useListChildProduct";
import LoadingHoc from "../../component/product/LoadingHoc";
import SliderCalendar from "../../component/calendar/slider/SliderCalendar";
import useChildProductFilter from "../../../usecase/product-filter/child/useChildProductFilter";
import LoadingCenter from "../../component/product/LoadingCenter";


export function ChildProductCardList({parentProduct,isGotoOne}) {
    const {
        getChildProductMapByDepartureDay,
        childProductList,
        isEmpty,
        loading,
        gotoOnlyOne,
        gotoDetail,
        error
    } = useListChildProduct({parentProduct})


    const {atDateFilter, sorting: {sortPrice}, setUpAtDateFilter} = useChildProductFilter()
    const [viewToggle, setViewToggle] = React.useState(true)

    const VIEW_COUNT = 3
    const childCount = childProductList.length

    useEffect(() => {
        isGotoOne && gotoOnlyOne()
    }, [parentProduct.representProductCode, loading]);

    function onClickMoreBtn(e) {
        e.stopPropagation()
        setViewToggle(!viewToggle)
    }


    return (
        <div className="depart-opt-wrap o-acdi-cont o-ac-on" id="list-p1">
            {!loading && <SliderCalendar
                loading={loading}
                startDay={atDateFilter}
                sortPrice={sortPrice}
                childProductDayMap={getChildProductMapByDepartureDay()}
                handleChangeDateCell={(dateCell) => {
                    setUpAtDateFilter(dateCell.date)
                }}
            />
            }

            <div className="list-sort-wrap between-box fc">
                <div className="total-list-num">총
                    <em className="num">
                        {" " + childCount}
                    </em>개
                </div>
                <div className="list-sort-opt">
                    <ToolTip/>
                    <SortTab/>
                </div>
            </div>
            {
                viewToggle &&
                <LoadingHoc isLoading={loading} isEmpty={isEmpty}>
                    <LoadingCenter/>
                    <EmptyChildList/>
                    <div className={`own-pd-list o-customscrollbar`}>
                        <React.Fragment>
                            <Sheet sx={{
                                width: "auto",
                                maxHeight: 700,
                                overflow: "auto",
                                backgroundColor: "transparent"
                            }}>
                                {
                                    childProductList.map((childProduct, index) => {
                                        return <ChildProductCard
                                            key={`childProductCard${index}`}
                                            parentProduct={parentProduct}
                                            childProduct={childProduct}
                                            gotoDetail={gotoDetail}
                                        />
                                    })
                                }
                            </Sheet>
                        </React.Fragment>
                    </div>
                </LoadingHoc>
            }
            {!loading && childCount > VIEW_COUNT &&
                <div className="more-view-btn-wrap" onClick={onClickMoreBtn}>
                <span className="btn-base btn-st-bfull wfull btn-line-grd btn-bg-wh a-pt20 bb-more">
                    <button type="button">
                         <span>
                             {viewToggle ? "접기" : "펼치기"}
                         </span>
                    </button>
                </span>
                </div>
            }
        </div>
    )
}


function SliderChildProductCalendarCell({dataCell, selectedDay, handleClickDataCell}) {
    const {minPriceAt} = useListChildProduct({parentProduct: null, notLoad: true})
    const price = minPriceAt(dataCell.date)

    function onClickDateCell(e) {
        e.stopPropagation()
        handleClickDataCell && handleClickDataCell(dataCell)
    }

    function viewMoney(money) {
        const man = Math.floor((money / 10000))
        return `${man}만`
    }

    function getClassName(dataCell, isMinPrice) {
        const clsList = ["day-column"]

        if (dataCell.dayOfWeek === "토") {
            clsList.push("sat holi")
        }

        if (dataCell.dayOfWeek === "일") {
            clsList.push("holi")
        }

        if (dataCell.date === selectedDay) {
            clsList.push("today")
        }

        if (isMinPrice) {
            clsList.push("lowest")
        }

        return clsList.join(" ")
    }

    return (
        <div className={getClassName(dataCell, price?.isMinPrice)} onClick={onClickDateCell}>
            <span className="week">
                {dataCell.dayOfWeek}
            </span>

            <span className={"day"}>
                    <a>{dataCell.day}</a>
            </span>
            {
                price &&
                <span className={"amt"}>{viewMoney(price.minPrice)}</span>
            }
        </div>
    )
}


function ToolTip() {
    return (
        <div className="prd-status dim-color">상품 상태
            <div className="help-tooltip pkg-st small darker">
                <span className="over">?</span>
                <div className="cont" style={{width: 295}}>
                    <ul className="level-list">
                        <li>
                            <div className="th-style">출발확정</div>
                            <div className="td-style">
                                최소 출발인원 이상 예약되어, 예약과 동시에 출발이 가능합니다.
                                <p className="c-gr">
                                    단, 기존 예약자의 일부가 취소되어 최소 출발인원이 미충족될 경우 불가할 수 있습니다.
                                </p>
                            </div>
                        </li>
                        <li>
                            <div className="th-style">예약가능</div>
                            <div className="td-style">여유좌석 내에서 예약이 가능하지만, 출발여부가 미정입니다.</div>
                        </li>
                        <li>
                            <div className="th-style">예약대기</div>
                            <div className="td-style">여유좌석 확보를 위해 담당자 확인 중인 상태로 대기예약으로 진행됩니다.</div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

function SortTab() {
    const {orderFilter, setUpOrderFilter} = useChildProductFilter()
    const styles = {cursor: "pointer"}

    function onClickSortTab(orderTarget, orderMethod) {
        setUpOrderFilter(orderTarget, orderMethod)
    }

    function getClassName(orderFilter, orderTarget, orderMethod) {
        const baseCls = ["js_tab_item"]
        if (orderFilter.orderTarget === orderTarget) {
            if (orderFilter.orderMethod === orderMethod) {
                baseCls.push("on")
            }
        }
        return baseCls.join(" ")
    }
    return (
        <ul className="opt-tap-box js_tab_list">
            <li className={getClassName(orderFilter, "reservationConfirm", "none")}>
                <a style={styles} onClick={() => onClickSortTab("reservationConfirm", "none")}>
                    출발 확정순
                </a>
            </li>

            <li className={getClassName(orderFilter, "departureDate", "asc")}>
                <a style={styles} onClick={() => onClickSortTab("departureDate", "asc")}>
                    빠른 출발순
                </a>
            </li>

            <li className={getClassName(orderFilter, "departureDate", "desc")}>
                <a style={styles} onClick={() => onClickSortTab("departureDate", "desc")}>
                    늦은 출발순
                </a>
            </li>

            <li className={getClassName(orderFilter, "price", "asc")}>
                <a style={styles} onClick={() => onClickSortTab("price", "asc")}>
                    낮은 가격순
                </a>
            </li>

            <li className={getClassName(orderFilter, "price", "desc")}>
                <a style={styles} onClick={() => onClickSortTab("price", "desc")}>
                    높은 가격순
                </a>
            </li>
        </ul>
    )
}

function EmptyChildList() {
    return (
        <div className="pkg-list-nodata">
            <i className="icon res-nodata"></i>
            <p className="tit">해당 날짜에는 상품이 존재하지 않습니다.</p>
            <p className="desc">다른 일정에서 상품을 검색해 보세요.</p>
        </div>
    )
}
