export default class UserFamilyDiscountEntity {
    constructor(data) {
        this.data = data
    }

    isLoaded() {
        return this.data.loading !== true
    }

    getData() {
        return this.data
    }

    isValid() {
        if (this.data === null) {
            return false
        }
        if (this.data.loading) {
            return false
        }
        if (this.data.familyDiscount === null) {
            return false
        }
        if (this.data.familyDiscount.length === 0) {
            return false
        }

        return true
    }
    createFamilyOption() {
        return {
            data: this.data,
            label: "현대카드 패밀리 2% 할인",
            checked: false,
            adaptedMoney: 0,
            getAdaptedMoney: () => {
                return 0
            }
        }
    }
}