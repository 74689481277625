import useEvent from "../../../../infra/event/useEvent";
import {EVENT_NAMES} from "../event/events";
import {DiscountStep, DiscountType} from "../../../../domain/service/payment/PaymentEnum";
import usePaymentPrice from "../usePaymentPrice";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {
    getTicketDiscountSelector,
    getTicketTargetValue,
    setInvalidTicketSelector,
    setTicketOptionListSelector,
    setTicketOptionSelector,
    setUsingTicketSelector,
    updateSelectedTicketSelector
} from "../../../state/reservation/benefit/TicketSelector";
import {getDiscountValueDictSelector} from "../../../state/reservation/DiscountSelector";

export default function useTicket(eventManager) {
    const {
        setDiscountV2,
        removeDiscountV2,
        priceDict,
        isSetDiscount,
        paymentPrice,
    } = usePaymentPrice()

    const [ticketOptionList, setTicketOptionList] = useRecoilState(setTicketOptionListSelector)
    const setTicketOption = useSetRecoilState(setTicketOptionSelector)
    const [selectedTicket, updateSelectedTicket] = useRecoilState(updateSelectedTicketSelector)
    const [usingTicket, setUsingTicket] = useRecoilState(setUsingTicketSelector)
    const ticketDiscount = useRecoilValue(getTicketDiscountSelector)
    const discountValueDict = useRecoilValue(getDiscountValueDictSelector)
    const applicableAmount = getTicketTargetValue(paymentPrice)
    const setInvalidTicket = useSetRecoilState(setInvalidTicketSelector)
    useEvent(eventManager, EVENT_NAMES.LOAD_TICKET, (evt) => {
        const userTicketEntity = evt.getPayload()
        setTicketOptionList(userTicketEntity.getTicketOptionList())
    })


    function onApply() {
        if (usingTicket) {
            setDiscountV2(ticketDiscount)
        }
    }

    function onClose() {
        removeDiscountV2(ticketDiscount)
        setUsingTicket(null)
        setTicketOption(null)
    }

    function onSelectTicket(ticketOpt) {
        setTicketOption(ticketOpt)
    }

    function onChangeAmountToUse(e) {
        const value = e.target.value
        updateSelectedTicket({
            amountToUse: value
        })
    }

    /*
    * 이용권 추가 될 때
    * */
    function onApplyAmountToUse() {
        if (!selectedTicket) return
        if (selectedTicket.amountToUse === 0) return
        if (selectedTicket.amountToUse === '') return
        const amountToUse = parseInt(selectedTicket.amountToUse)
        const amount = parseInt(selectedTicket.amount)
        if (amountToUse < 10000) {
            alert("이용권은 10,000원 이상부터 사용 가능합니다.")
            return
        }
        if (amountToUse > amount) {
            alert("이용권은 최대 사용 가능 금액을 초과할 수 없습니다.")
            return
        }
        if (amountToUse > applicableAmount) {
            alert("이용권은 총 결제 금액을 초과할 수 없습니다.")
            return
        }
        updateSelectedTicket({
            useAmount: selectedTicket.amountToUse
        })
        const newUsingTicket = {
            ...selectedTicket,
            useAmount: selectedTicket.amountToUse
        }
        setUsingTicket(newUsingTicket)
    }

    function onCancelUsingTicket() {
        setUsingTicket(null)
    }

    function getSubmitData() {
        let ticketList = []
        if (usingTicket) {
            ticketList.push({
                ...usingTicket.data,
                useAmount: usingTicket.useAmount
            })
        }
        return {
            ticketList
        }
    }

    function onOpen() {
        const otherDiscountCtx = isSetDiscount(DiscountStep.FIRST_STEP, DiscountType.Ticket)
        if (otherDiscountCtx.isSet && !otherDiscountCtx.equal) {
            return window.confirm(otherDiscountCtx.message)
        }
        return true
    }

    function setInvalid() {
        setInvalidTicket()
    }

    return {
        enable: ticketOptionList.length > 0,
        title: `PRIVIA 이용권 (총 ${ticketOptionList.length}장)`,
        modalTitle: "PRIVIA 이용권",
        adaptedMoney: usingTicket ? discountValueDict[DiscountType.Ticket] : 0,
        applicableAmount,
        amountToUse: 0,
        onSelectTicket,
        ticketOptionList,
        selectedTicket, // 선택된 티켓
        usingTicket, // 사용중 티켓
        onChangeAmountToUse,
        onApply,
        onClose,
        onApplyAmountToUse,
        onCancelUsingTicket,
        getSubmitData,
        onOpen,
        setInvalid
    }

}
