// Actions
const SET_REPRESENTATIVE_PRODUCT = "PRODUCT/SET_REPRESENTATIVE_PRODUCT"
const SET_SALE_PRODUCT = "PRODUCT/SET_SALE_PRODUCT"

// 액션 생성함수 정의
export const setRepresentativeProduct = (representativeProduct) => ({ type: SET_REPRESENTATIVE_PRODUCT,representativeProduct });
export const setSaleProduct = (saleProduct) => ({ type: SET_SALE_PRODUCT, saleProduct });

const initialState = {
    representativeProduct : null,
    saleProduct : null
};

//리듀서 작성
const productState = (state = initialState , action) => {
    switch (action.type) {
        case SET_REPRESENTATIVE_PRODUCT :
            return {
                ...state,
                representativeProduct: action.representativeProduct
            }
        case SET_SALE_PRODUCT:
            return {
                ...state,
                saleProduct : action.saleProduct
            }
        default:
            return state;
    }
}

export default productState;
