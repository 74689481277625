import React from "react";
import {shallowEqual, useSelector} from "react-redux";
import {ChildProductCardList} from "../../kyb/app/view/container/product/ChildProductCardList";

function PeriodDay({dayCounts, dayCount}) {
    let str = '';
    if (dayCounts && Array.isArray(dayCounts)) {
        str = dayCounts.map((dayCount, index) => (index === 0 ? '' : '/') + dayCount + '일')
    }
    if (dayCount) {
        str = dayCount + '일';
    }

    return (
        <span className="detail ico-calendar">
            여행기간&nbsp;{str}
        </span>
    )
}

export default function ChangeDeparturePopup() {
    let representativeProduct = useSelector((state) => state.productReducer.representativeProduct, shallowEqual);
    return (
        <>
            <React.Fragment>
                <div className="depart-detail" style={{height: '750px'}}>
                    <section className="lpu-item-sec">
                        <div className="lpu-sub-item a-pb20">
                            <div className="pd-info-area">
                                <span className="vis"><img src={representativeProduct.representImageUrl}
                                                           alt={representativeProduct.representProductName}/></span>
                                <div className="pd-info">
                                    <div
                                        className="pd-title ellipsis2">{representativeProduct.representProductName}</div>
                                    <div className="pd-info-detail">
                                        <span
                                            className="detail ico-depart">{representativeProduct.representProductDivisionName}</span>
                                        <PeriodDay dayCounts={representativeProduct.dayCounts}
                                                   dayCount={representativeProduct.dayCount}/>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <ChildProductCardList isGotoOne={false} parentProduct={representativeProduct}/>

                        {/*<SearchChildList index={0} name={`searchReducer`} childNm={`searchChildData`}*/}
                        {/*                 startDate={moment(saleProduct.departureDay).format("YYYY-MM-DD")}*/}
                        {/*                 detailPage={true}/>*/}
                    </section>
                </div>
            </React.Fragment>
        </>
    )
}

// const ChangeDeparturePopup = () => {
//
//     const dispatch = useDispatch();
//     const serverUrl = process.env.REACT_APP_SERVER_URL;
//
//     let representativeProduct = useSelector((state) => state.productReducer.representativeProduct, shallowEqual);
//     let saleProduct = useSelector((state) => state.productReducer.saleProduct, shallowEqual);
//
//     useEffect(()=>{
//         getOtherDeparture().then(r => console.log(r));
//     },[representativeProduct, saleProduct]);
//
//     const getOtherDeparture = async () => {
//         axios.get(serverUrl + '/api/v1/product/' + saleProduct.representProductCode + '/sale',{
//             headers: getAxiosHeaders()
//         }).catch(function (error) {
//             console.log(error.toJSON());
//         }).then(function (response) {
//             if (response) {
//                 //내 일정 제거
//                 response.data.saleProducts = response.data.saleProducts.filter((item) => item.saleProductCode !== saleProduct.saleProductCode);
//                 dispatch(isSearchChildData(response.data))
//             } else {
//                 dispatch(setErrorMessage('error'));
//             }
//         });
//     }
//
//     return (
//     <React.Fragment>
//         <div className="depart-detail" style={{height: '750px'}}>
//             <section className="lpu-item-sec">
//               <div className="lpu-sub-item a-pb20">
//                 <div className="pd-info-area">
//                   <span className="vis"><img src={representativeProduct.representImageUrl} alt={representativeProduct.representProductName} /></span>
//                   <div className="pd-info">
//                     <div className="pd-title ellipsis2">{representativeProduct.representProductName}</div>
//                     <div className="pd-info-detail">
//                       <span className="detail ico-depart">{representativeProduct.representProductDivisionName}</span>
//                       <span className="detail ico-calendar">여행기간
//                           { representativeProduct.dayCounts && representativeProduct.dayCounts.map((dayCount, index) =>
//                               (index === 0 ? '' : '/') + dayCount + '일')
//                           }
//                       </span>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <SearchChildList index={0} name={`searchReducer`} childNm={`searchChildData`}
//                                startDate={moment(saleProduct.departureDay).format("YYYY-MM-DD")} detailPage={true} />
//             </section>
//       </div>
//     </React.Fragment>
//     );
// };
//
// export default ChangeDeparturePopup;