import {shallowEqual, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, Navigation, Pagination} from "swiper/modules";
import {useEffect, useState} from "react";
import axios from "axios";
import {getAxiosHeaders} from "../util/Axios";


const TopHeaderMenu = ({bannerButtonClickHandle , bannerButtonState}) =>{

    const serverUrl = process.env.REACT_APP_SERVER_URL;
    const [faqBanner , setFaqBanner] = useState([])
    // const isLogin = useSelector((state)=>state.headerReducer.isLogin , shallowEqual);
    const priviaLoginUrl = process.env.REACT_APP_PRIVIA_BASE_URL + '/common/sso/login?returnUrl=' + process.env.REACT_APP_FRONT_URL;
    const priviaLogoutUrl = process.env.REACT_APP_PRIVIA_BASE_URL + '/common/sso/logout';
    const memberNm = useSelector((state)=>state.headerReducer.memberNm , shallowEqual);

    const faqBannerData = async () =>{
        let resData = await axios.get(`${serverUrl}/api/v1/pc/main/topFaq` , {  headers: getAxiosHeaders()}).then(res=>res.data).catch(err=>console.log(err))
        if(resData !== undefined) setFaqBanner(resData)
    }
    useEffect(()=>{
        faqBannerData()
    },[])

    const baseUrl = process.env.REACT_APP_PRIVIA_BASE_URL

    const faqBannerEle = faqBanner && faqBanner.map((item , index)=>{
        return (
            <SwiperSlide key={index} style={{textAlign:"left"}}>
                {
                    item && item.urgentYn === 'Y' ?
                        (
                            <li className="point slick-slide slick-current slick-active" data-slick-index="-1" aria-hidden="true" tabIndex="-1" style={{width : `340px`}}>
                                <strong>중요</strong>
                                <Link to={`${baseUrl}/noticefaq/view?seq=${item.seq}`}>{item.title}</Link>
                            </li>
                        ):
                        (
                            <li className="slick-slide" data-slick-index="-1" aria-hidden="true" tabIndex="-1" style={{width : `340px`}}>
                                <Link to={`${baseUrl}/noticefaq/view?seq=${item.seq}`}>{item.title}</Link>
                            </li>
                        )

                }
            </SwiperSlide>
        )
    })

    return(
        <>
            <div className="w-header-util">
                <div className="ly-inner">
                    <div className="hu-control">
                        <span className="txt">주요 프로모션 및 안내 보기</span>
                        {/*<span className={bannerButtonState ? "b-crt bc-close" : "b-crt bc-close hide"} onClick={bannerButtonClickHandle}><a href="#"><span className="a11y txt" >상단리본 닫기 버튼</span></a></span>*/}
                        <span className={bannerButtonState ? "b-crt bc-open" : "b-crt bc-close "} onClick={bannerButtonClickHandle}><a style={{cursor: "pointer"}}><span className="a11y txt">{bannerButtonState ? "상단리본 열기 버튼" : "상단리본 닫기 버튼" }</span></a></span>
                    </div>
                    <div className="hu-notice">
                        <h2 className="a11y">유틸메뉴 영역</h2>
                        <Swiper
                            className={"list"}
                            direction={'vertical'}
                            spaceBetween={20}
                            slidesPerView={1}
                            autoplay={{ delay: 5000, disableOnInteraction: false }}
                            modules={[Autoplay, Pagination, Navigation]}
                            loop={true}
                        >
                            {faqBannerEle}
                        </Swiper>
                    </div>
                    {
                        memberNm ? (<div className="hu-user privia"><span>{memberNm}님</span></div>) : ''
                    }
                    <ul id="nav-util" className="nav-util">
                        {
                            memberNm ? <li><Link to={priviaLogoutUrl}>로그아웃</Link></li> : <><li><Link to={priviaLoginUrl}>로그인</Link></li><li><Link to={process.env.REACT_APP_MENU_REGISTER_LINK}>회원가입</Link></li></>
                        }
                        <li><Link to={process.env.REACT_APP_MENU_MYPAGE_LINK}>마이페이지</Link></li>
                        {/*<li className="on"><Link to={process.env.MENU}>나의 혜택</Link></li>*/}
                        <li><Link to={process.env.REACT_APP_MENU_COUNSEL_LINK}>고객센터</Link></li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default TopHeaderMenu