import {useEffect, useRef, useState} from "react";
import moment from "moment/moment";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation} from "swiper/modules";
import SliderCalendarCell from "./SliderCalendarCell";
import { shallowEqual, useSelector } from "react-redux";

function getAllDatesOfMonth(year, month) {
    const dates = [];
    const firstDayOfMonth = moment([year, month - 1]);
    const lastDayOfMonth = moment(firstDayOfMonth).endOf('month');
    const currentDay = firstDayOfMonth.clone();
    while (currentDay.isBefore(lastDayOfMonth) || currentDay.isSame(lastDayOfMonth, 'day')) {
        const day = {
            date: currentDay.format('YYYY-MM-DD'),
            dayOfWeek: currentDay.format('dddd')[0],
            day: currentDay.format('D'),
            moment: currentDay.clone()
        }
        dates.push(day);
        currentDay.add(1, 'days');
    }
    return dates;
}

export default function SliderCalendar({
                                           loading,
                                           startDay,
                                           sortPrice,
                                           childProductDayMap,
                                           handleChangeDateCell,
                                       }) {

    const holiday = useSelector((state)=>state.calendarState.holiday , shallowEqual);
    const [today, _] = useState(moment(startDay));
    const [currentDate, setCurrentDate] = useState(moment(startDay));
    const [selectedDate, setSelectedDate] = useState(currentDate);
    const swiperRef = useRef();
    const MOVE_DAY = 6;
    const PREV_VIEW_COUNT = 15;

    useEffect(() => {
        if (loading) return

        const initialDay = moment(startDay)
        setCurrentDate(initialDay)
        let nextMoment = initialDay.clone()
        let days = []
        let maxCount = 0
        let isFind = true
        const MAX_MOVE = 7 // 7개월이나 이동했는데. 데이터가 없으면 끝내자
        do {
            if (maxCount >= MAX_MOVE) {
                isFind = false
                break
            }
            let allDays = getAllDatesOfMonth(nextMoment.year(), nextMoment.month() + 1)
            days = allDays.map((dataCell, index) => {
                return childProductDayMap[dataCell.date]
            }).flatMap(x => x).filter(child => child !== undefined) || []

            // startDay 이후에만 데이터를 가져온다.
            days = days.filter(child => moment(startDay).isSameOrBefore(moment(child['departureDay'])))
            if (days.length > 0) {
                break
            }
            maxCount += 1
            nextMoment = moment(nextMoment).add(1, 'M')
        } while (true)
        if (isFind) {
            const day = days[0]
            const momentDay = moment(day['departureDay'])
            setSelectedDate(momentDay)
            setCurrentDate(momentDay)
            handleDataCell && handleDataCell({
                date: momentDay.format('YYYY-MM-DD'),
                dayOfWeek: momentDay.format('dddd')[0],
                day: momentDay.format('D'),
                moment: momentDay.clone()
            })
        }
    }, [loading]);

    useEffect(() => {
        if (swiperRef.current) {
            const day = currentDate.date()
            swiperRef.current.slideTo(day - 1)
        }
    }, [swiperRef, swiperRef.current, currentDate]);


    function nextMonth(e) {
        e?.stopPropagation()
        const nextMonth = moment(selectedDate).add(1, 'M')
        setSelectedDate(nextMonth)
        swiperRef.current?.slideTo(0);
    }


    function prevMonth(e) {
        e.stopPropagation()
        const prevMonth = moment(selectedDate).subtract(1, 'M')
        setSelectedDate(prevMonth)

        const lastIndex = swiperRef.current.slides.length - 1;
        swiperRef.current?.slideTo(lastIndex - PREV_VIEW_COUNT + 3);
    }
    function clickSwiperNext() {
        swiperRef.current?.slideTo(swiperRef.current.activeIndex + MOVE_DAY);
    }
    function clickSwiperPrev() {
        swiperRef.current?.slideTo(swiperRef.current.activeIndex - MOVE_DAY);
    }
    function handleDataCell(dataCell) {
        dataCell && dataCell.moment && setCurrentDate(dataCell.moment)
        handleChangeDateCell && handleChangeDateCell(dataCell)
    }

    const allDays = getAllDatesOfMonth(selectedDate.year(), selectedDate.month() + 1)

    const days = allDays.map((dataCell, index) => {
        return childProductDayMap[dataCell.date]
    }).flatMap(x => x).filter(child => child !== undefined)

    days.forEach(child => {
        child['isMinPrice'] = false
    })
    const childCells = days.sort(sortPrice('asc'))

    if (childCells[0]) {
        childCells[0]['isMinPrice'] = true
    }

    return (
        <div className="calendar-wrap">
            <div className="month-selection">
                <i className="icon arrow-left" onClick={prevMonth}/>
                <span className="month">{`${selectedDate.year()}년 ${selectedDate.month() + 1} 월`}</span>
                <i className="icon arrow-right" onClick={nextMonth}/>
            </div>
            <div className="week-container">
                <div onClick={clickSwiperNext} className="swiper-button-next" tabIndex="0" role="button"
                     aria-label="Next slide" aria-disabled="false"></div>

                <Swiper className={"cal-week-swiper swiper-container-free-mode swiper-wrapper "}
                        modules={[Navigation]}
                        spaceBetween={0}
                        slidesPerView={PREV_VIEW_COUNT}
                        onBeforeInit={(swiper) => {
                            swiperRef.current = swiper;
                        }}
                        scrollbar={{draggable: true}}
                >
                    {
                        allDays.map((dataCell, index) => {
                            return (
                                <SwiperSlide style={{width: "52.2px"}}>
                                    <SliderCalendarCell
                                        handleClickDataCell={handleDataCell}
                                        key={dataCell.date}
                                        dataCell={dataCell}
                                        childProducts={childProductDayMap[dataCell.date]}
                                        isDisabled={dataCell.moment.isBefore(moment())}
                                        selectedDay={currentDate.format('YYYY-MM-DD')}
                                        holiday={holiday}
                                    />
                                </SwiperSlide>
                            )
                        })
                    }
                </Swiper>
                <div onClick={clickSwiperPrev} className="swiper-button-prev" tabIndex="-1" role="button"
                     aria-label="Previous slide" aria-disabled="true"></div>
            </div>
        </div>
    )
}
