import {atom, selector} from "recoil";
import {DiscountStep, DiscountType, PaymentType} from "../../../domain/service/payment/PaymentEnum";
import {SaleProductAmountTarget} from "../../../domain/model/product/SaleProductEntity";
import {VOUCHER_TYPE} from "../../../domain/model/user/UserVoucherEntity";
import {cloneDeep} from "lodash";
import {initVoucher} from "./benefit/VoucherSelector";
import {initTicket} from "./benefit/TicketSelector";
import {initCoupon} from "./benefit/CouponSelector";
import {UserType} from "../user/UserState";

const defaultCardInicisEnv = {
    mid: process.env.REACT_APP_DEFAULT_MID,
    mKey: process.env.REACT_APP_DEFAULT_MKEY,
    mPointMid: null,
    mPointMKey: null,
}

export const HccOption = {
    value: '04',
    label: '현대카드',
    mid: process.env.REACT_APP_HCC_MID,
    mKey: process.env.REACT_APP_HCC_MKEY,
    mPointMid: process.env.REACT_APP_MPOINT_MID,
    mPointMKey: process.env.REACT_APP_MPOINT_MKEY,
}

export const NullCreditCardOption = {
    value: '카드선택',
    label: '카드선택',
    mid: null,
    mKey: null,
    mPointMid: null,
    mPointMKey: null,
}
export const ALL_CARD_OPTIONS = [
    NullCreditCardOption,
    HccOption,
    {
        value: '14',
        label: '신한(구LG포함) 카드',
        ...defaultCardInicisEnv
    },
    {
        value: '06',
        label: 'KB국민카드',
        ...defaultCardInicisEnv
    },
    {
        value: '12',
        label: '삼성카드',
        ...defaultCardInicisEnv
    },
    {
        value: '03',
        label: '롯데카드',
        ...defaultCardInicisEnv
    },
    {
        value: '11',
        label: '비씨카드',
        ...defaultCardInicisEnv
    },
    {
        value: '38',
        label: '우리카드',
        ...defaultCardInicisEnv
    },
    {
        value: '34',
        label: '하나 SK카드',
        ...defaultCardInicisEnv
    },
    {
        value: '41',
        label: 'NH카드',
        ...defaultCardInicisEnv
    },
    {
        value: '01',
        label: '외환카드',
        ...defaultCardInicisEnv
    },
    {
        value: '43',
        label: '씨티은행카드',
        ...defaultCardInicisEnv
    },
    {
        value: '51',
        label: '수협은행카드',
        ...defaultCardInicisEnv
    },
    {
        value: '33',
        label: '전북은행카드',
        ...defaultCardInicisEnv
    },
    {
        value: '71',
        label: '우체국카드',
        ...defaultCardInicisEnv
    },
    {
        value: '54',
        label: '새마을체크카드',
        ...defaultCardInicisEnv
    },
    {
        value: '95',
        label: '저축은행체크카드',
        ...defaultCardInicisEnv
    },
    {
        value: '52',
        label: '제주은행카드',
        ...defaultCardInicisEnv
    },
    {
        value: '32',
        label: '광주은행카드',
        ...defaultCardInicisEnv
    },
    {
        value: '48',
        label: '신협은행카드',
        ...defaultCardInicisEnv
    },
    {
        value: '35',
        label: 'KDB산업카드',
        ...defaultCardInicisEnv
    },
]
export const CardOptionDict = Object.freeze(ALL_CARD_OPTIONS.reduce((acc, card) => {
    acc[card.value] = card
    return acc
}, {}))

/*
* 결제 회수에 따른 방법
* */
export const PaymentMethod = Object.freeze({
    SINGLE: 'SINGLE',// 단일 결제
    TWO_PART: 'TWO_PART',// 2부분 결제
})

export const PaymentStep = Object.freeze({
    INIT: 0, // 가예약 생성 단계
    FIRST: 1, // 예약금 전액 결제 혹은 2부분 결제 중 첫 번째
    SECOND: 2,// 2부분 결제 중 두 번째
})

export const paymentPriceStateAtom = atom({
    key: 'paymentPriceState',
    default: {
        paymentMethod: PaymentMethod.SINGLE, // 결제 방식 -> 1회, 2회
        paymentStep: PaymentStep.INIT, // 결제 단계
        paymentType: PaymentType.ALL_PAY, // 결제 타입
        addAmount: null, // 추가금
        prePaidPrice: 0, // 선결제 금액.
        readOnlyAmount: false, // 수량 변경 가능 여부
        amountDict: {}, // 가격 정보를 위한 수량 및 가격
        discountDict: {}, // 할인 정보를 위한,
        user: {
            type: UserType.NONE,
        },
        //예약 결제 카드 정보
        reservationPayCard: {
            isHcc: false,
            paid: 0
        },
        // 카드 정보
        creditCard: {
            ...HccOption,
        },
        // mPoint 관련 정보
        mPoint: { // mPoint 정보
            loaded: false, // mPoint 정보 로드 여부
            percent: 10, //  기본 10%로 계산.
            useMPoint: false, // mPoint 활성화 여부
            mPoint: 0, // 사용 중인 mPoint // 이거 없애야함..
            maxMPoint: 0,// 최대 mPoint,
            discount: {
                step: DiscountStep.FOURTH_STEP,
                kind: DiscountType.MPoint,
            }
        },

        // 현대카드 3% 할인 관련
        hcc3: {
            enable: true, // 현대카드 3% 할인 활성화 여부
            option: { // view 모델
                enable: false,
                id: "HCC3",
                label: "현대 카드 3% 할인",
                checked: false,
            },
            discount: { // discount 모델
                enable: true,
                step: DiscountStep.THIRD_STEP, // readOnly
                kind: DiscountType.HCC3Discount,// readOnly,
            }
        },
        // 현대 카드 패밀리 관련
        family: {
            enable: true,
            option: {
                id: "HCCF",
                enable: false,
                label: "현대카드 패밀리 2% 할인",
                checked: false,
            },
            discount: {
                enable: true,
                step: DiscountStep.SECOND_STEP,
                kind: DiscountType.FamilyDiscount,
            }
        },
        // 쿠폰 관련
        coupon: {
            valid: true,
            enable: false,
            optionList: [],
            selected: null, // 이거 필요 없는거같은데?
            discount: {
                enable: true,
                couponList: [], // api에서 가져온 실제 쿠폰 데이터
                selectedCouponDict: {}, // 현재 선택중인 쿠폰 데이터
                step: DiscountStep.FIRST_STEP,
                kind: DiscountType.Coupon,
            }
        },
        // 이용권
        ticket: {
            valid: true,
            enable: false,
            optionList: [],
            selected: null,
            discount: {
                enable: true,
                usingTicket: null,
                step: DiscountStep.FIRST_STEP,
                kind: DiscountType.Ticket,
            }
        },
        voucher: {
            [VOUCHER_TYPE.GREEN]: {
                valid: true,
                enable: false,
                optionList: [],
                discount: {
                    step: DiscountStep.FIRST_STEP,
                    kind: DiscountType.GreenVoucher,
                }
            },
            [VOUCHER_TYPE.RED5]: {
                valid: true,
                enable: false,
                optionList: [],
                discount: {
                    step: DiscountStep.FIRST_STEP,
                    kind: DiscountType.RedVoucher,
                }
            },

            [VOUCHER_TYPE.RED4]: {
                valid: true,
                enable: false,
                optionList: [],
                discount: {
                    step: DiscountStep.FIRST_STEP,
                    kind: DiscountType.RedVoucher4,
                }
            },

            [VOUCHER_TYPE.RED_STRIP]: {
                valid: true,
                enable: false,
                optionList: [],
                discount: {
                    step: DiscountStep.FIRST_STEP,
                    kind: DiscountType.RedVoucherStrip,
                }
            },
            [VOUCHER_TYPE.SUMMIT]: {
                valid: true,
                enable: false,
                optionList: [],
                discount: {
                    step: DiscountStep.FIRST_STEP,
                    kind: DiscountType.SummitVoucher,
                }
            },
        },
    }
})

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const setPaymentPriceState = selector({
    key: 'setPaymentPriceState',
    get: ({get}) => {
        return get(paymentPriceStateAtom);
    },
    set: ({get, set}, newValue) => {
        const old = get(paymentPriceStateAtom);
        const {paymentType, amountDict, prePaidPrice, addAmount, readOnlyAmount, reservationPayCard} = newValue;
        set(
            paymentPriceStateAtom,
            {
                ...old,
                addAmount: addAmount || 0,
                prePaidPrice: prePaidPrice || 0,
                readOnlyAmount: readOnlyAmount || false,
                paymentType,
                amountDict,
                reservationPayCard: reservationPayCard || old.reservationPayCard
            }
        );
    }
})


/*
* 결제 방식 바꾸는 selector
* */
export const setPaymentTypeState = selector({
    key: 'setPaymentTypeState',
    get: ({get}) => {
        return get(paymentPriceStateAtom).paymentType;
    },
    set: ({set, get}, newValue) => {
        let old = cloneDeep(get(paymentPriceStateAtom));
        let family = old.family
        let hcc3 = old.hcc3
        let coupon = old.coupon
        let voucher = old.voucher
        let ticket = old.ticket
        let mPoint = old.mPoint
        let newDiscountDict = old.discountDict
        let newPaymentMethod = PaymentMethod.TWO_PART
        mPoint.useMPoint = false

        coupon = initCoupon(coupon)
        voucher = initVoucher(voucher)
        ticket = initTicket(ticket)

        if (newValue === PaymentType.RESERVATION_PAY) {
            newDiscountDict = {}
            newPaymentMethod = PaymentMethod.SINGLE
            family.option.checked = false
            hcc3.option.checked = false
        }

        if (newValue === PaymentType.ALL_PAY) {
            family.option.enable && (newDiscountDict[family.discount.step] = family.discount.kind)
            family.option.enable && (family.option.checked = true)
            hcc3.option.enable && (newDiscountDict[hcc3.discount.step] = hcc3.discount.kind)
            hcc3.option.enable && (hcc3.option.checked = true)
            newPaymentMethod = PaymentMethod.SINGLE
        }

        const newPayment = {
            ...old,
            family,
            hcc3,
            coupon,
            voucher,
            ticket,
            mPoint,
            paymentType: newValue,
            discountDict: newDiscountDict,
            paymentMethod: newPaymentMethod
        }

        set(paymentPriceStateAtom, newPayment);
    }
})

export const setUpAmountCountSelector = selector({
    key: 'setUpAmountCount',
    get: ({get}) => {
        return get(paymentPriceStateAtom).amountDict;
    },
    set: ({set, get}, {target, count}) => {
        const old = get(paymentPriceStateAtom);
        const newAmountDict = {...old.amountDict}
        newAmountDict[target] = newAmountDict[target].addCountAndNew(count);
        set(
            paymentPriceStateAtom,
            {
                ...old,
                amountDict: newAmountDict,
            }
        );
    }
})

export const getAmountListSelector = selector({
    key: 'getAmountList',
    get: ({get}) => {
        const {amountDict} = get(paymentPriceStateAtom);
        if (Object.keys(amountDict || {}).length === 0) return []

        return [
            SaleProductAmountTarget.ADULT,
            SaleProductAmountTarget.CHILD,
            SaleProductAmountTarget.INFANT
        ].map((target) => amountDict[target])
            .filter(amount => amount)
    }
})

export const getInicisEnvSelector = selector({
    key: 'getInicisEnvSelector',
    get: ({get}) => {
        const {creditCard, mPoint} = get(paymentPriceStateAtom);
        if (!mPoint.useMPoint) {
            return {
                mid: creditCard.mid,
                mKey: creditCard.mKey,
            }
        }
        if (creditCard.value === HccOption.value) {
            return {
                mid: HccOption.mPointMid,
                mKey: HccOption.mPointMKey,
            }
        }
        return {
            mid: creditCard.mid,
            mKey: creditCard.mKey,
        }
    }
})


/*
* 결제 스텝 변경
* */
export const setPaymentStepSelector = selector({
    key: 'setPaymentStepSelector',
    get: ({get}) => {
        return get(paymentPriceStateAtom).paymentStep;
    },
    set: ({set, get}, newValue) => {
        const old = get(paymentPriceStateAtom);
        set(
            paymentPriceStateAtom,
            {
                ...old,
                paymentStep: newValue
            }
        );
    }
})


export const getUsingBenefitSelector = selector({
    key: 'getUsingBenefitSelector',
    get: ({get}) => {
        const {mPoint, hcc3, family} = get(paymentPriceStateAtom);
        return {
            mPoint: mPoint.useMPoint,
            hcc3: hcc3.option.checked,
            family: family.option.checked
        }
    }
})


export const setAmountDictSelector = selector({
    key: 'setAmountDictSelector',
    get: ({get}) => {
        return get(paymentPriceStateAtom).amountDict;
    },
    set: ({set, get}, newValue) => {
        const old = get(paymentPriceStateAtom);
        // 너무 조잡스럽다.. amount dict 잡내가 심각함.
        const keys = Object.keys(old.amountDict)
        if (keys.length > 0) return
        set(
            paymentPriceStateAtom,
            {
                ...old,
                amountDict: newValue
            }
        );
    }
})