import React from "react";
import {Sheet} from "@mui/joy";


export default function SearchKeywordPanel({
                                               isAreaLoading,
                                               keyword,
                                               searchKeyword,
                                               onClickArea,
                                               onClickProduct
                                           }) {
    function onClickAreaBy(e, value) {
        e.preventDefault();
        onClickArea && onClickArea(value);
    }

    function onClickProductBy(product) {
        onClickProduct && onClickProduct(product);
    }

    return (
        <div className="uis-list uis-list-auto">
            <div className="list o-customscrollbar mCustomScrollbar _mCS_1 mCS-autoHide"
                 style={{position: "relative", overflow: "visible"}}>
                <div id="mCSB_1" className="mCustomScrollBox mCS-minimal-dark mCSB_vertical mCSB_outside"
                     style={{maxHeight: "530px"}} tabIndex="0">
                    <div id="mCSB_1_container"
                         className={keyword ? "mCSB_1_container" : "mCSB_container mCS_y_hidden mCS_no_scrollbar_y"}
                         style={{position: "relative", top: 0, left: 0}} dir="ltr">
                        {
                            isAreaLoading && <QuickSearchSkeleton/>
                        }
                        <Sheet sx={{
                            width: "auto",
                            maxHeight: 530,
                            overflow: "auto",
                        }}>
                            {searchKeyword && searchKeyword.keywordAreaList && searchKeyword.keywordAreaList.length > 0 &&
                                <div className="uiri-list-items">
                                    <p className="tit">지역</p>
                                    <ul className="uiri-list-area">
                                        <KeywordAreaList areas={searchKeyword.keywordAreaList}
                                                         keywordChoiceHandle={onClickAreaBy}
                                                         searchKeyword={keyword}/>

                                    </ul>
                                </div>
                            }
                            {searchKeyword && searchKeyword.keywordProductList && searchKeyword.keywordProductList.length > 0 &&
                                <div className="uiri-list-items">
                                    <p className="tit">상품</p>
                                    <ul className="">
                                        <KeywordProductList
                                            products={searchKeyword.keywordProductList}
                                            onClickProduct={onClickProductBy}
                                            searchKeyword={keyword}
                                        />
                                    </ul>
                                </div>
                            }

                            {!searchKeyword && <div className="no-list">검색 결과가 없습니다.</div>}

                        </Sheet>
                    </div>
                </div>
            </div>
        </div>
    )
}
const KeywordAreaList = ({areas, keywordChoiceHandle, searchKeyword}) => (
    <ul>
        {areas.map((value, index) => (
            <KeywordAreaItem key={index} value={value} keywordChoiceHandle={keywordChoiceHandle}
                             searchKeyword={searchKeyword}/>
        ))}
    </ul>
);

const KeywordProductList = ({products, onClickProduct, searchKeyword}) => (
    <ul>
        {products.map((product, index) => (
            <KeywordProductItem key={index} product={product} onClickProduct={onClickProduct}
                                searchKeyword={searchKeyword}/>
        ))}
    </ul>
);
const HighlightText = ({text, keyword}) => {
    return text.split(keyword).map((part, index, array) => (
        <React.Fragment key={index}>
            {part}
            {index < array.length - 1 && <em>{keyword}</em>}
        </React.Fragment>
    ));
};

const KeywordAreaItem = ({value, keywordChoiceHandle, searchKeyword}) => {
    const handleClick = (e) => {
        keywordChoiceHandle(e, value);
    };

    const cityData = value.city ? value.city.split("|").at(1) : '';
    const countryData = value.country.split("|").at(1);

    return (
        <li>
            <a style={{cursor: "pointer"}} onClick={handleClick}>
                <span>
                    {countryData && <HighlightText text={countryData} keyword={searchKeyword}/>},&nbsp;
                    {cityData && <HighlightText text={cityData} keyword={searchKeyword}/>}
                </span>
            </a>
        </li>
    );
};

function KeywordProductItem({product, onClickProduct, searchKeyword}) {
    const handleClick = () => {
        onClickProduct(product);
    };

    return (
        <li>
            <a style={{cursor: "pointer"}} onClick={handleClick}>
                <span className="txt ellipsis">
                    <HighlightText text={product.name} keyword={searchKeyword}/>
                </span>
            </a>
        </li>
    );
};


function QuickSearchSkeleton() {
    return (
        <div className="uis-list-skeleton" style={{display: "block"}}>
            <div className="skel-group" style={{display: "block"}}>
                <div className="skel-tit-bg skel-bg"></div>
                <div className="skel-items">
                    <div className="exp1 skel-bg"></div>
                    <div className="exp1 skel-bg"></div>
                </div>
            </div>
            <div className="skel-group">
                <div className="skel-tit-bg skel-bg"></div>
                <ul className="skel-items">
                    <li>
                        <span className="exp2 skel-bg"></span>
                        <span className="exp3 skel-bg"></span>
                    </li>
                    <li>
                        <span className="exp2 skel-bg"></span>
                        <span className="exp3 skel-bg"></span>
                    </li>
                    <li>
                        <span className="exp2 skel-bg"></span>
                        <span className="exp3 skel-bg"></span>
                    </li>
                    <li>
                        <span className="exp2 skel-bg"></span>
                        <span className="exp3 skel-bg"></span>
                    </li>
                </ul>
            </div>
        </div>
    )
}
