import React from "react";
import {shallowEqual, useSelector} from "react-redux";

const ProductInfoIconBox = () => {

    let saleProduct = useSelector((state) => state.productReducer.saleProduct, shallowEqual);

    return (
                <div className="pd-title-icon-box">
                    <ul className="tab-icon">
                        <li>
                            <div className="icon-keyword-box">
                                <div className="icon-keyword-img">
                                    {saleProduct && (saleProduct.airlineName !== null || saleProduct.airlineName !== ''?
                                        (<img src="//static.priviatravel.com/images/front/mtravel/svg/pkg-pd-info-icon01-1.svg" alt="출발지 아이콘"/>) :
                                        (<img src="//static.priviatravel.com/images/front/mtravel/svg/pkg-pd-info-icon01-2.svg" alt="항공불포함 아이콘"/>))
                                    }
                                </div>
                                {saleProduct && (saleProduct.airlineName !== null || saleProduct.airlineName !== ''?
                                    (<div className="icon-keyword-tit">{saleProduct.departureCityName}출발</div>) :
                                    (<div className="icon-keyword-tit">항공불포함</div>))
                                }
                            </div>
                        </li>
                        {saleProduct && (saleProduct.airlineName !== null || saleProduct.airlineName !== ''? (
                        <li>
                            <div className="icon-keyword-box">
                                <div className="icon-keyword-img">
                                    {saleProduct.airlineName === '선박'?
                                        (<img src="//static.priviatravel.com/images/front/mtravel/svg/pkg-pd-info-icon10-3.svg" alt="선박 아이콘"/>) :
                                        (<img src="//static.priviatravel.com/images/front/mtravel/svg/pkg-pd-info-icon10-1.svg" alt="항공포함 아이콘"/>)
                                    }
                                </div>
                                    <div className="icon-keyword-tit">{saleProduct.airlineName}</div>
                            </div>
                        </li>
                        ) : null)}
                        <li>
                            <div className="icon-keyword-box">
                                <div className="icon-keyword-img">
                                    <img src="//static.priviatravel.com/images/front/mtravel/svg/pkg-pd-info-icon02.svg" alt="일정 아이콘" />
                                </div>
                                {saleProduct &&
                                    (<div className="icon-keyword-tit">{saleProduct.travelNightCount}박{saleProduct.travelDayCount}일</div>)
                                }
                            </div>
                        </li>
                        {/* <li>
                            <div className="icon-keyword-box">
                                <div className="icon-keyword-img">
                                    {saleProduct && saleProduct.saleProductDivision === '프라이빗' ?
                                        (<img src="//static.priviatravel.com/images/front/mtravel/svg/pkg-pd-info-icon03-2.svg" alt="소규모 아이콘"/>) :
                                        (<img src="//static.priviatravel.com/images/front/mtravel/svg/pkg-pd-info-icon03-1.svg" alt="단체 아이콘" />)
                                    }
                                </div>
                                <div className="icon-keyword-tit">
                                    {saleProduct && saleProduct.saleProductDivision === '프라이빗' ?
                                        ('소규모여행') : ('단체여행')
                                    }
                                </div>
                            </div>
                        </li> */}
                        <li>
                            <div className="icon-keyword-box">
                                <div className="icon-keyword-img">
                                    {saleProduct && saleProduct.shoppingCenterVisitCount === 0 ?
                                        (<img src="//static.priviatravel.com/images/front/mtravel/svg/pkg-pd-info-icon04-2.svg" alt=""/>) :
                                        (<img src="//static.priviatravel.com/images/front/mtravel/svg/pkg-pd-info-icon04-1.svg" alt="쇼핑있음 아이콘"/>)
                                    }
                                </div>
                                    {saleProduct && saleProduct.shoppingCenterVisitCount === 0 ?
                                        (<div className="icon-keyword-tit">NO쇼핑</div>) :
                                        (<div className="icon-keyword-tit">쇼핑{saleProduct.shoppingCenterVisitCount}회</div>)
                                    }
                            </div>
                        </li>
                        <li>
                            <div className="icon-keyword-box">
                                <div className="icon-keyword-img">
                                    {saleProduct && saleProduct.isFreedomSchedule ?
                                        (<img src="//static.priviatravel.com/images/front/mtravel/svg/pkg-pd-info-icon05-1.svg" alt="자유일정포함 아이콘"/>) :
                                        (<img src="//static.priviatravel.com/images/front/mtravel/svg/pkg-pd-info-icon05-2.svg" alt="자유일정불포함 아이콘"/>)
                                    }
                                </div>
                                <div className="icon-keyword-tit">
                                    {saleProduct && saleProduct.isFreedomSchedule ?
                                        (<div className="icon-keyword-tit">자유일정포함</div>) :
                                        (<div className="icon-keyword-tit">자유일정불포함</div>)
                                    }
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="icon-keyword-box">
                                <div className="icon-keyword-img">
                                    {saleProduct && saleProduct.isConductorFixed ?
                                        (<img src="//static.priviatravel.com/images/front/mtravel/svg/pkg-pd-info-icon06-1.svg" alt="인솔자있음 아이콘"/>) :
                                        (<img src="//static.priviatravel.com/images/front/mtravel/svg/pkg-pd-info-icon06-2.svg" alt="인솔자없음 아이콘"/>)
                                    }
                                </div>
                                {saleProduct && saleProduct.isConductorFixed ?
                                    (<div className="icon-keyword-tit">인솔자있음</div>) :
                                    (<div className="icon-keyword-tit">인솔자없음</div>)
                                }
                            </div>
                        </li>
                        {/*<li>*/}
                        {/*    <div className="icon-keyword-box">*/}
                        {/*        <div className="icon-keyword-img">*/}
                        {/*            <img src="//static.priviatravel.com/images/front/mtravel/svg/pkg-pd-info-icon07-1.svg" alt="선택관광있음 아이콘" />*/}
                        {/*        </div>*/}
                        {/*        <div className="icon-keyword-tit">선택관광있음</div>*/}
                        {/*    </div>*/}
                        {/*</li>*/}
                        <li>
                            <div className="icon-keyword-box">
                                <div className="icon-keyword-img">
                                    {saleProduct && saleProduct.isGuideFixed ?
                                        (<img src="http://static.priviatravel.com/images/front/mtravel/svg/pkg-pd-info-icon08-1.svg" alt="가이드있음 아이콘"/>) :
                                        (<img src="http://static.priviatravel.com/images/front/mtravel/svg/pkg-pd-info-icon08-2.svg" alt="가이드없음 아이콘"/>)
                                    }
                                </div>
                                {saleProduct && saleProduct.isGuideFixed ?
                                    (<div className="icon-keyword-tit">가이드있음</div>) :
                                    (<div className="icon-keyword-tit">가이드없음</div>)
                                }
                            </div>
                        </li>
                        <li>
                            <div className="icon-keyword-box">
                                <div className="icon-keyword-img">
                                    {saleProduct && saleProduct.minDepartureNumberOfPerson &&
                                        <img src="http://static.priviatravel.com/images/front/mtravel/svg/pkg-pd-info-icon09-1.svg" alt="최소출발인원있음 아이콘" />
                                    }
                                </div>
                                <p class="icon-keyword-tit">최소출발{saleProduct && saleProduct.minDepartureNumberOfPerson}명</p>
                            </div>
                        </li>
                    </ul>
                </div>
    );
};

export default ProductInfoIconBox;