import React, {useEffect} from "react";
import DoubleCalendar from "../../component/calendar/double/DoubleCalendar";
import ProductPanel from "../../component/product-panel";
import useProductPanel from "../../../usecase/product-filter/search-bar/useProductPanel";
import RecentlySearch from "../../component/product-panel/RecentlySearch";
import SimplePanel from "../../component/product-panel/SimplePanel";
import MenuProductTypeList from "../../component/product-panel/MenuProductTypeList";
import usePriviaUrl, {PRIVIA_URL} from "../../../../infra/hooks/usePriviaUrl";
import useScrollStatus from "../../../../infra/hooks/useScrollStatus";


export default function Index({visible}) {
    const SEARCH_PLACE_HOLDER_TEXT = "국가명, 도시명, 상품명, 상품코드"
    const {isUrl, path} = usePriviaUrl()
    const {isScrolling} = useScrollStatus()

    const {
        mode,
        openProductPanel,
        setOpenProductPanel,
        inputString,
        setInputString,
        setContinentAndNation,
        continentList,
        isAreaLoading,
        isAreaLoadError,
        searchByKeyword,
        isLoadingProductByKeyword,
        onClickProduct,
        onClickAreaByKeyword,
        panelMode,
        handleSubmit,
        searchHistory,
        handleSearchHistory,
        toggleProductPanelMode,
        setPanelMode,
        doubleCalendarState,
        onClickProductTypeItem,
        productTypeItemList,
        revertPanelMode,
        changePanelMode,
        queryParam,
    } = useProductPanel()


    /*
    * 경로별 패널 상태 제어
    * */
    useEffect(() => {

        if (isUrl(PRIVIA_URL.HOME)) {
            changePanelMode("detail")
            return
        }
        changePanelMode("simple")
    }, [path])


    useEffect(() => {
        if (visible) return
        setOpenCalendar(false)
        setOpenProductPanel(false)
    }, [visible])

    /*
    * 프로덕트 리스트에서 펼쳐진 상태에서 스크롤이 되면 패널모드를 simple로 변경한다.
    * */
    useEffect(() => {
        if (isUrl(PRIVIA_URL.HOME)) return
        if (!isScrolling) return;
        if (panelMode === "simple") return
        if (visible) return
        setPanelMode("simple")
    }, [isScrolling])

    useEffect(() => {
        if (isUrl(PRIVIA_URL.HOME)) return

        // 제품목록, 상세페이지 스크롤 다운해서 안보이는 영역에 도달하면 패널모드를 simple로 변경한다.
        visible === false && changePanelMode("simple")

        // 제품목록, 상세페이지  스크롤 업했을 때  패널모드를 이전 상태 변경한다.
        visible && revertPanelMode()

    }, [visible]);

    const {
        startAtString,
        endAtString,
        openCalendar,
        setOpenCalendar,
        setPeriodRange,
    } = doubleCalendarState


    function onSubmitButton() {
        const {error, errorMessage} = handleSubmit()
        error && alert(errorMessage)
    }
    function onChangeSearchBar(e) {
        e.stopPropagation()
        setInputString(e.target.value)
        // changeInputString(e.target.value)
    }
    function onClickSearchBar(e) {
        e.stopPropagation()
        setOpenProductPanel(!openProductPanel)
    }

    function onFocus(e) {
        e.stopPropagation()
        setOpenProductPanel(true)
    }

    function onBlurSearchBar(e) {
        e.stopPropagation()
        // setOpenProductPanel(false)
    }

    function onKeyUpSearchBar(e) {
        e.stopPropagation()

    }
    function onClickCalendarShowBtn(e) {
        e.stopPropagation()
        setOpenCalendar(!openCalendar)
    }
    function onSubmitCalendarRange(range) {
        setPeriodRange(range)
        setOpenCalendar(false)
    }

    function onClickContinentPanel(continent, nation) {
        setContinentAndNation(continent, nation)
    }

    const params = {
        search: true
    }


    if (panelMode === "simple") {
        return <SimplePanel
            queryParam={queryParam}
            handleClick={() => toggleProductPanelMode()}
        />
    }

    function isShowComponent() {
        // 보여지는 형태니까 그냥 보여주면 된다.
        if (visible) {
            return {display: "block"}
        }
        // 안보여지는 상태인데, url이 HOME 아니면 보여준다.
        if (!isUrl(PRIVIA_URL.HOME)) {
            return {display: "block"}
        }
        return {display: "none"}
    }



    return (
        <div className={`w-header-search`}
             style={isShowComponent()}
        >
            <div className="ly-inner">
                <h2 className="a11y">검색 영역</h2>
                <div className="hs-search-menu">
                    <div className="hss-menu hss-package on">
                        <h3 className="hss-main"><a style={{cursor: "pointer"}} data-tabmain="package">해외패키지</a></h3>
                        <MenuProductTypeList itemList={productTypeItemList}
                                             onClickItem={onClickProductTypeItem}/>

                    </div>
                </div>

                <div className="hs-search-cont">
                    <div className="hss-inner-cont sc-package isRecently on" id="SC-PACKAGE">
                        <div className="sc-search-box o-allarea on">
                            <div className="w-qsb-cont">
                                <div className="qsb-cont-box">
                                    <div className={`qsb-cont qsb-places ${params.search ? 'act' : ''}`}>
                                        <div className={`qsb-area ${params.search ? 'on' : ''}`} data-panel="mainsel"
                                             data-panel-name="ui-mainsel-package">
                                            <label htmlFor="sword" className="qsb-label">국가명, 도시명, 상품명, 상품코드</label>
                                            <input type="text" autoComplete={"off"}
                                                   value={inputString}
                                                   className="input-base type00 wfull ipu-search"
                                                   placeholder={SEARCH_PLACE_HOLDER_TEXT}
                                                // onFocus={onFocus}
                                                   onClick={onClickSearchBar}
                                                // onBlur={onBlurSearchBar}
                                                   onChange={onChangeSearchBar}
                                                   onKeyUp={onKeyUpSearchBar}/>
                                        </div>
                                    </div>

                                    <div className="qsb-cont qsb-dates">
                                        <a style={{cursor: "pointer"}}
                                           className={`qsb-area ${startAtString ? 'on' : ''}`}
                                           data-panel="calendar"
                                           data-panel-name="ui-date-calendar"
                                           onClick={onClickCalendarShowBtn}>
                                            <span className="qsb-label">출발 날짜</span>
                                            <span className="qsb-input">
                                                <span className="qsb-chk-m" data-month=""></span>
                                                <span className="qsb-d1">{startAtString}</span>
                                                <span className="qsb-d2">{endAtString}</span>
                                            </span>
                                        </a>
                                    </div>

                                    <div className="qsb-cont qsb-btn-search">
                                        <button type="button" className="b-search" onClick={onSubmitButton}>검색
                                        </button>
                                    </div>
                                </div>

                                <DoubleCalendar
                                    show={openCalendar}
                                    onSubmitRange={onSubmitCalendarRange}
                                />

                                <ProductPanel
                                    show={openProductPanel}
                                    mode={mode}
                                    keyword={inputString}
                                    searchByKeyword={searchByKeyword}
                                    isAreaLoading={isAreaLoading}
                                    isKeywordLoading={isLoadingProductByKeyword}
                                    isAreaLoadError={isAreaLoadError}
                                    continentList={continentList}
                                    onClickContinentPanel={onClickContinentPanel}
                                    onClickProduct={onClickProduct}
                                    onClickAreaByKeyword={onClickAreaByKeyword}
                                />

                            </div>
                        </div>

                        <RecentlySearch
                            searchHistory={searchHistory}
                            onClickSearchHistory={handleSearchHistory}
                        />
                        {
                            !isUrl(PRIVIA_URL.HOME) && <button className={"btn-close-hs"}
                                style={{display: "block"}}
                                onClick={() => toggleProductPanelMode()}><span className={"a11y"}>닫기</span></button>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}