import { useEffect, useState } from "react";
import useLoadTravelerList from "../../../loader/useLoadTraveler";
import { addTravelerInfo, deleteTravelerInfo } from "../../../../domain/repository/TravelerRepository";
import { SaleProductAmountTarget } from "../../../../domain/model/product/SaleProductEntity";
import { EVENT_NAMES } from "../event/events";
import useEvent from "../../../../infra/event/useEvent";
import usePaymentPrice from "../usePaymentPrice";
import moment from "moment";
import {
    BirthDayRegx,
    EmailRegx,
    EnglishNameRegx,
    KoreanCellPhoneRegx,
    KoreanNameRegx,
    sexRegx
} from "../../../../infra/const/RegularExpression";
import { autoHyphen, autoUpperCase, removeNonKorean, removeNotNumber } from "../../../../infra/for-adaptation/form-utils"

import { useRecoilState } from "recoil";
import {
    reservationPersonEmailState,
    reservationPersonNameState,
    reservationPersonPhoneState
} from "../../../state/user/ReservationPersonState";

export const AGE_DIVISION = Object.freeze({
    ADULT: "adult",
    CHILD: "children",
    INFANT: "infant",
})

export const REGX = Object.freeze({
    koreanFullName: KoreanNameRegx,
    sex: sexRegx,
    englishFamilyName: EnglishNameRegx,
    englishGivenName: EnglishNameRegx,
    birth: BirthDayRegx,
    phone: KoreanCellPhoneRegx,
    email: EmailRegx,
})

export const ERROR_TYPE = Object.freeze({
    VALID: "VALID",
    REQUIRE: "REQUIRE",
    INVALID: "INVALID"
})

export const ERROR_MESSAGE = Object.freeze({
    koreanFullName: {
        REQUIRE: "한글성명을 입력해 주세요.",
        INVALID: "한글성명을 입력해 주세요.(ex.홍길동)",
    },
    sex: {
        REQUIRE: "성별을 선택해 주세요.",
        INVALID: "성별을 선택해 주세요.",
    },
    englishFamilyName: {
        REQUIRE: "여권상 영문성을 입력해 주세요.",
        INVALID: "여권상 영문성을 입력해 주세요.",
    },
    englishGivenName: {
        REQUIRE: "여권상 영문이름을 입력해 주세요.",
        INVALID: "여권상 영문이름을 입력해 주세요.",
    },
    birth: {
        REQUIRE: "법정 생년월일을 입력해 주세요.",
        INVALID: "생년월일 형식에 맞게 입력해 주세요.(ex.19930516)",
        INVALID_ADULT: "성인 생년월일 오류",
        INVALID_CHILDREN: "아동 생년월일 오류",
        INVALID_INFANT: "유아 생년월일 오류",
    },
    phone: {
        REQUIRE: "휴대폰 번호를 입력해 주세요.",
        INVALID: "휴대폰 번호를 입력해 주세요.(ex 01012345678)",
    },
    email: {
        REQUIRE: "이메일을 입력해 주세요.",
        INVALID: "이메일 형식에 맞게 입력해 주세요.(ex hong@naver.com)"
    },
})

export default function useTravelPerson(eventManager) {
    const {
        amountList,
    } = usePaymentPrice()
    const [user, setUser] = useState(null)
    const [isMember, setIsMember] = useState(false)

    const [departureDay, setDepartureDay] = useState('')
    // form 값
    const [travelerAdultList, setTravelerAdultList] = useState([])
    const [travelerChildrenList, setTravelerChildrenList] = useState([])
    const [travelerInfantList, setTravelerInfantList] = useState([])
    const { getLoadTravelerList, loading, error, travelerListRecoil } = useLoadTravelerList()
    // popup 값
    const [travelerSelectList, setTravelerSelectList] = useState([])
    const [isReservationPerson, setIsReservationPerson] = useState(false)
    const [phone, setPhone] = useRecoilState(reservationPersonPhoneState)
    const [email, setEmail] = useRecoilState(reservationPersonEmailState)
    const [name, _] = useRecoilState(reservationPersonNameState)

    const initErrors = {
        koreanFullName: null,
        sex: null,
        englishFamilyName: null,
        englishGivenName: null,
        birth: null,
        phone: null,
        email: null,
    }

    async function addTravelerInfoData(addTravelerRequest) {
        const response = await addTravelerInfo(addTravelerRequest)
        const result = response.data.SUCCESS
        if (result) {
            getLoadTravelerList()
            alert('여행자가 추가되었습니다.');
        } else {
            alert('여행자 정보 추가에 실패하였습니다.');
        }
        return result
    }

    async function deleteTravelerInfoData(deleteRequest) {
        const response = await deleteTravelerInfo(deleteRequest)
        const result = response.data.data
        if (result === 'OK') {
            getLoadTravelerList()
        } else {
            alert('여행자 정보 삭제에 실패하였습니다.')
        }
        return result
    }

    useEvent(eventManager, EVENT_NAMES.LOAD_USER, (evt) => {
        const user = evt.getPayload()
        setUser(user)
        if (user.isValid()) {
            setIsMember(true)
        }
    })

    useEvent(eventManager, EVENT_NAMES.LOAD_SALE_PRODUCT, (loadSaleProductEvent) => {
        const saleProduct = loadSaleProductEvent.getPayload()
        setDepartureDay(saleProduct.getDepartureDay())
    })

    // 저장된 여행자 목록 로드
    useEffect(() => {
        if (loading) return
        setTravelerSelectList(travelerListRecoil)
    }, [loading]);

    // 예약자와 동일
    useEffect(() => {
        let travelPerson;
        if (isReservationPerson) {
            travelPerson = {
                title: '성인1',
                koreanFullName: name,
                sex: user.data.memberSex,
                englishFamilyName: '',
                englishGivenName: '',
                birth: user.data.memberBirth ? user.data.memberBirth.replaceAll('/-/g', '') : '',
                phone: phone,
                email: email,
                errors: initErrors,
            }
        } else {
            travelPerson = {
                title: '성인1',
                koreanFullName: '',
                sex: '',
                englishFamilyName: '',
                englishGivenName: '',
                birth: '',
                phone: '',
                email: '',
                errors: initErrors,
            }
        }
        const updatedTravelerAdultList = [...travelerAdultList];
        updatedTravelerAdultList[0] = travelPerson;
        setTravelerAdultList(updatedTravelerAdultList);
    }, [isReservationPerson]);

    // 인원수에 따른 여행자 정보 칸
    useEffect(() => {
        const newTravelerAdultList = [...travelerAdultList]
        const newTravelerChildrenList = [...travelerChildrenList]
        const newTravelerInfantList = [...travelerInfantList]

        amountList.forEach((element) => {
            if (element.target === SaleProductAmountTarget.ADULT) {
                if (newTravelerAdultList.length > element.count) {
                    newTravelerAdultList.length = element.count;
                }
                for (let i = 0; i < element.count; i++) {
                    if (newTravelerAdultList[i] === undefined) {
                        newTravelerAdultList.push(
                            {
                                title: "성인" + (i + 1),
                                koreanFullName: "",
                                sex: "",
                                englishFamilyName: "",
                                englishGivenName: "",
                                birth: "",
                                phone: "",
                                email: "",
                                errors: initErrors,
                            }
                        );
                    }
                }
            } else if (element.target === SaleProductAmountTarget.CHILD) {
                if (newTravelerChildrenList.length > element.count) {
                    newTravelerChildrenList.length = element.count;
                }
                for (let i = 0; i < element.count; i++) {
                    if (newTravelerChildrenList[i] === undefined) {
                        newTravelerChildrenList.push(
                            {
                                title: "아동" + (i + 1),
                                koreanFullName: "",
                                sex: "",
                                englishFamilyName: "",
                                englishGivenName: "",
                                birth: "",
                                phone: "",
                                email: "",
                                errors: initErrors,
                            }
                        );
                    }
                }
            } else if (element.target === SaleProductAmountTarget.INFANT) {
                if (newTravelerInfantList.length > element.count) {
                    newTravelerInfantList.length = element.count;
                }
                for (let i = 0; i < element.count; i++) {
                    if (newTravelerInfantList[i] === undefined) {
                        newTravelerInfantList.push(
                            {
                                title: "유아" + (i + 1),
                                koreanFullName: "",
                                sex: "",
                                englishFamilyName: "",
                                englishGivenName: "",
                                birth: "",
                                phone: "",
                                email: "",
                                errors: initErrors,
                            }
                        );
                    }
                }
            }
        });
        setTravelerAdultList(newTravelerAdultList)
        setTravelerChildrenList(newTravelerChildrenList)
        setTravelerInfantList(newTravelerInfantList)
    }, [amountList]);

    function onCheckSyncReservationPerson() {
        setIsReservationPerson(!isReservationPerson)
    };

    function getAgeDivision(title) {
        if (title.includes("성인")) {
            return AGE_DIVISION.ADULT
        } else if (title.includes("아동")) {
            return AGE_DIVISION.CHILD
        } else if (title.includes("유아")) {
            return AGE_DIVISION.INFANT
        }
    }

    function updateTravelerInfoList(ageDivision, index, travelPerson) {
        const updatedList = { adult: [...travelerAdultList], children: [...travelerChildrenList], infant: [...travelerInfantList] };
        updatedList[ageDivision][index] = travelPerson;
        switch (ageDivision) {
            case AGE_DIVISION.ADULT:
                setTravelerAdultList(updatedList[ageDivision]);
                break;
            case AGE_DIVISION.CHILD:
                setTravelerChildrenList(updatedList[ageDivision]);
                break;
            case AGE_DIVISION.INFANT:
                setTravelerInfantList(updatedList[ageDivision]);
                break;
        }
    }

    function travelerInfoChangeHandler(e, travelerErrors, ageDivision, index, travelPerson) {
        let { name, value } = e.target;
        if (name && name.includes("sex")) {
            name = "sex";
            value = e.target.id.includes('m') ? 'M' : 'F'
        } else if (name === 'phone') {
            autoHyphen(e)
            value = e.target.value
        } else if (name === 'birth') {
            removeNotNumber(e)
            value = e.target.value
        } else if (name === 'koreanFullName') {
            removeNonKorean(e)
            value = e.target.value
        } else if (name === 'englishFamilyName' || name === 'englishGivenName') {
            autoUpperCase(e)
            value = e.target.value
        }
        const updatedErrors = validateInput(name, value, travelerErrors, ageDivision);
        const updatedTravelPerson = {
            ...travelPerson,
            [name]: value,
            errors: updatedErrors,
        }
        updateTravelerInfoList(ageDivision, index, updatedTravelPerson)
    }

    function validateInput(name, value, errors, ageDivision) {
        if (ageDivision !== AGE_DIVISION.ADULT && (name === 'phone' || name === 'email')) return { ...errors }
        if (value === '' || value === null || value === undefined) {
            return {
                ...errors,
                [name]: {
                    type: ERROR_TYPE.REQUIRE,
                    message: ERROR_MESSAGE[name].REQUIRE
                }
            };
        }
        const valid = REGX[name] && REGX[name].test(value);
        if (!valid) {
            return {
                ...errors,
                [name]: {
                    type: ERROR_TYPE.INVALID,
                    message: ERROR_MESSAGE[name].INVALID
                }
            };
        }

        if (name === 'birth') {
            let birthDate = moment(value, "YYYYMMDD");
            let departureDate = moment(departureDay, "YYYYMMDD");
            if (ageDivision === AGE_DIVISION.ADULT) {
                let adultBirthLimit = moment(departureDate).subtract(12, 'years');
                if (birthDate.isSameOrAfter(adultBirthLimit)) {
                    return {
                        ...errors,
                        [name]: {
                            type: ERROR_TYPE.INVALID,
                            message: ERROR_MESSAGE[name].INVALID_ADULT
                        }
                    };
                }

            } else if (ageDivision === AGE_DIVISION.CHILD) {
                let childBirthStart = moment(departureDate).subtract(12, 'years');
                let childBirthEnd = moment(departureDate).subtract(2, 'years');
                if (birthDate.isBefore(childBirthStart) || birthDate.isAfter(childBirthEnd)) {
                    return {
                        ...errors,
                        [name]: {
                            type: ERROR_TYPE.INVALID,
                            message: ERROR_MESSAGE[name].INVALID_CHILDREN
                        }
                    };
                }
            } else if (ageDivision === AGE_DIVISION.INFANT) {
                let infantBirthStart = moment(departureDate).subtract(2, 'years');
                if (birthDate.isBefore(infantBirthStart) || birthDate.isAfter(moment())) {
                    return {
                        ...errors,
                        [name]: {
                            type: ERROR_TYPE.INVALID,
                            message: ERROR_MESSAGE[name].INVALID_INFANT
                        }
                    };
                }
            }
        }

        return {
            ...errors,
            [name]: {
                type: ERROR_TYPE.VALID,
                message: '',
            }
        };
    }

    // submitData에 대한 validate 전에 전체 validate
    function preCheck() {
        function initializeErrors(travelPerson) {
            let errors = { ...travelPerson.errors };
            const ageDivision = getAgeDivision(travelPerson.title)
            for (const key in travelPerson) {
                if (travelPerson.hasOwnProperty(key)) {
                    if (key === 'title' || key === 'errors') continue
                    const updatedErrors = validateInput(key, travelPerson[key], errors, ageDivision);
                    errors = updatedErrors;
                }
            }
            return errors;
        }

        const updatedAdultList = travelerAdultList.map(travelPerson => {
            const initializedErrors = initializeErrors(travelPerson);
            return {
                ...travelPerson,
                errors: initializedErrors,
            };
        })
        setTravelerAdultList(updatedAdultList)

        const updatedChildrenList = travelerChildrenList.map(travelPerson => {
            const initializedErrors = initializeErrors(travelPerson);
            return {
                ...travelPerson,
                errors: initializedErrors,
            };
        })
        setTravelerChildrenList(updatedChildrenList)

        const updatedInfantList = travelerInfantList.map(travelPerson => {
            const initializedErrors = initializeErrors(travelPerson);
            return {
                ...travelPerson,
                errors: initializedErrors,
            };
        })
        setTravelerInfantList(updatedInfantList)
        return [...updatedAdultList, ...updatedChildrenList, ...updatedInfantList]
    }

    function validate() {
        const travelerInfoList = preCheck()
        const isValid = travelerInfoList.every(travelPerson => {
            const ageDivision = getAgeDivision(travelPerson.title)

            const errors = travelPerson.errors;
            for (const key in errors) {
                if (errors.hasOwnProperty(key)) {
                    if (ageDivision !== AGE_DIVISION.ADULT && (key === 'phone' || key === 'email')) return true
                    if (errors[key].type !== ERROR_TYPE.VALID) return false;
                }
            }
            return true;
        })
        if (isValid) {
            return {
                valid: true,
                message: ""
            }
        }
        return {
            valid: false,
            message: "여행자 정보를 확인 해주세요."
        }
    }

    function getSubmitData() {

        //일단 테스트 용이성을 위해 무조건 통과 시킴
        /*        return {
                    travelerInformationList: [
                        {
                            profileId: "1",
                            koreanName: '김진영',
                            englishFirstName: "JINYOUNG",
                            englishFamilyName: "KIM",
                            mobileNumber: '010-8394-7815',
                            emailAddress: 'rucy@tixit.kr',
                            sex: 'F',
                            birth: '20020202',
                            nationality: 'KOR',
                            ageDivision: "adult"
                        }
                    ]
                }*/

        const travelerInfoList = [...travelerAdultList, ...travelerChildrenList, ...travelerInfantList]
        const travelerInformationList = travelerInfoList && travelerInfoList.map((travelPerson, index) => {
            return {
                profileId: "1",
                koreanName: travelPerson.koreanFullName,
                englishFamilyName: travelPerson.englishFamilyName.toUpperCase(),
                englishFirstName: travelPerson.englishGivenName.toUpperCase(),
                sex: travelPerson.sex,
                birth: travelPerson.birth,
                nationality: "KOR",
                mobileNumber: travelPerson.phone,
                emailAddress: travelPerson.email,
                ageDivision: getAgeDivision(travelPerson.title)
            }
        })

        console.log('여행자정보 취합 데이터  >>  ', travelerInformationList)
        return {
            travelerInformationList
        }
    }

    return {
        getSubmitData,
        travelerAdultList,
        travelerChildrenList,
        travelerInfantList,
        travelerSelectList,
        onCheckSyncReservationPerson,
        isReservationPerson,
        departureDay,
        addTravelerInfoData,
        deleteTravelerInfoData,
        isMember,
        travelerInfoChangeHandler,
        validate,
        updateTravelerInfoList,
    }
}