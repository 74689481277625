import {useEffect, useState} from "react";
import {findOneProductByCode} from "../../domain/repository/ProductRepository";
import SaleProductEntity from "../../domain/model/product/SaleProductEntity";

export default function useLoadChildProduct(representativeCode, saleCode) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [saleProduct, setSaleProduct] = useState(null);


    useEffect(() => {
        if (!representativeCode) return
        if (!saleCode) return
        setLoading(true)
        findOneProductByCode(representativeCode, saleCode).then((res) => {
            setLoading(false)
            if (!res) {
                setError("Not Found")
                return
            }
            if (res.data === null) {
                setError("Not Found")
                return
            }
            if (!res.data['saleProducts']) {
                setError("Not Found")
                return
            }
            setSaleProduct(new SaleProductEntity(res.data['saleProducts'][0]))
            setLoading(false)
            setError(null)
        }).catch((error) => {
            setError(error)
            setLoading(false)
        })

    }, [representativeCode, saleCode]);



    return {
        loading,
        error,
        saleProduct
    }
}