import {useEffect, useState} from "react";

export default function useMoreThanList({originList, constAddCount, defaultViewCount, forceUpdate = 0}) {
    const [viewCount, setViewCount] = useState(defaultViewCount)
    const [list, setList] = useState(originList)

    useEffect(() => {
        setList(originList)
    }, [forceUpdate]);

    function addViewCount() {
        setViewCount(viewCount + constAddCount)
    }

    function setUpViewCount(count) {
        setViewCount(count)
    }

    return {
        viewCount,
        viewList: [...list.slice(0, viewCount)],
        addViewCount,
        setUpViewCount,
        isViewAll: viewCount >= list.length,
        setOriginList: setList
    }

}