import {selector} from "recoil";
import {CardOptionDict, HccOption, paymentPriceStateAtom} from "../PaymentPriceState";


export const setCardOptionSelector = selector({
    key: 'setCardOptionSelector',
    get: ({get}) => {
        return get(paymentPriceStateAtom).creditCard;
    },
    set: ({set, get}, newValue) => {
        const old = get(paymentPriceStateAtom);
        const newCard = CardOptionDict[newValue];
        if (!newCard) return
        let hcc3 = {...old.hcc3}
        let family = {...old.family}
        const hcc3Opt = {...hcc3.option}
        const familyOpt = {...family.option}
        let newDiscountDict = {...old.discountDict}
        const newMPoint = {...old.mPoint}
        // 현대 카드가 아니면
        if (newCard.value !== HccOption.value) {
            hcc3Opt.checked = false
            familyOpt.checked = false
            newMPoint.useMPoint = false
            newMPoint.mPoint = 0
            newDiscountDict = {}
        }

        set(
            paymentPriceStateAtom,
            {
                ...old,
                creditCard: newCard,
                discountDict: newDiscountDict,
                mPoint: newMPoint,
                hcc3: {
                    ...hcc3,
                    option: hcc3Opt
                },
                family: {
                    ...family,
                    option: familyOpt
                }
            }
        );
    }
})