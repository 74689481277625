import styles from "../../../../../../reservation/page/Reservation.module.css";
import Term6Contents from "../../../../../../reservation/component/Term6Content";
import React, {useState} from "react";
import axios from "axios";
import ThirdPartyInfoContents from "./ThirdPartyInfoContents";
import {Sheet} from "@mui/joy";
import Modal from "@mui/material/Modal";

export default function ThirdPartyInfo({
                                           open,
                                           terms,
                                           handleOpenContents,
                                           termsCtx,
                                           contents,
                                       }) {

    return (
        <>
            <div className="pkg-tit-term-cont">
                <h3 className="title between-box fc closed">
                    <a className={`o-acdi-click ${open ? 'o-ac-on' : ''}`}
                       onClick={handleOpenContents} id="thirdPartyInfoToggle">
                        제 3자 개인정보 제공 동의(필수)
                    </a>
                    <div className="agr-area">
                                    <span className="check-group">
                                        <input type="checkbox" className="hidden_check" id="thirdPartyInfo"
                                               name="thirdPartyInfo" checked={terms.thirdPartyInfo} onChange={termsCtx.onCheck}/>
                                        <label htmlFor="thirdPartyInfo" className="label-check">동의</label>
                                    </span>
                    </div>
                </h3>
            </div>
            <div className={`term-cont-box o-customscrollbar ${styles.term} ${styles.hidden} ${open ? styles.open : ''}`}
                id="tm-6" style={{display: "block"}}>
                <div className="t-content" id="indivInfoThirdFull">
                    <div dangerouslySetInnerHTML={{__html: contents}} />
                </div>
            </div>
        </>
    );
}

const ThirdPartyModal = ({srcData , onClose}) =>{

    const liTagEleData = srcData && srcData.map((item, index) => {
        return (
            <li key={`item-${index}`}>{item}</li>
        )
    })

    return(
        <div id="termPopup" className="modal popup-wrap term-pop" style={{width: 660, visibility: 'visible', position: 'absolute', display: 'block', top: '50%', left: '50%'}} >
            <div className="heading">
                <h2 className="ph-tit">업체 리스트</h2>
                <a href="#" className="btn_close" onClick={onClose}><span className="a11y">닫기</span></a>
            </div>
            <div className="content">
                <div className="inner">
                    <div className="cpn-pop-wrap">
                        <div className="term-shop-box mCustomScrollbar _mCS_5" style={{position: 'relative', overflow: 'visible'}}>
                            <div id="mCSB_5" className="mCustomScrollBox mCS-dark mCSB_vertical mCSB_outside" style={{maxHeight: 293}} tabIndex={0}>
                                <div id="mCSB_5_container" className="mCSB_container" style={{position: 'relative', top: 0, left: 0}} dir="ltr">
                                    <Sheet sx={{width:'auto' , maxHeight:293 , overflow:'auto'}}>
                                        <ul className="term-shop-list">
                                            {liTagEleData}
                                        </ul>
                                    </Sheet>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}